import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  TextField,
  Grid,
  IconButton,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";

const BulkDelete = (props) => {
  const theme = useTheme();

  const { title, bulkdelete, setBulkdelete, selectionModel } = props;
  const user = useSelector((state) => state?.users?.user?.username);

  const deleteItems = () => {
    const tradeIds = selectionModel?.map((item) => item?.tradeId);

    if (tradeIds.length > 0) {
      window.clientSocks.send(
        `{"action":"delete_trades", "data":${JSON.stringify({
          trades: tradeIds,
          user: user,
        })}}`
      );
    }

    setBulkdelete(false);
  };
  const handleClose = () => {
    setBulkdelete(false);
  };
  return (
    <Dialog
      open={bulkdelete}
      onClose={handleClose}
      sx={{ width: "100%", height: "100%" }}
    >
      <DialogContent dividers>
        <Typography
          variant="h6"
          component="div"
          align="center"
          style={{
            flexGrow: 1,
            fontSize: "20px",
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          {title}
        </Typography> 
        <Typography variant="body" component="div"  style={{ flexGrow: 1, fontSize: "13px", textAlign: "center" }}>
          {`Are you sure you want to delete selected trades?`}
        </Typography>
        <Grid
          item
          xs={12}
          margin="20px 1px 20px 1px "
          display="flex"
          gap="10px"
          justifyContent="center"
        >
         
          <Button
            variant="contained"
            size="small"
            sx={{
              width: "40%",
              background: "#B2BEB5",
              color: "#000",
              "&:hover": { background: "#808080" },
            }}
            onClick={() => {
              setBulkdelete(false);
            }}
          >
            CANCEL
          </Button>

          <Button
            variant="contained"
            size="small"
            sx={{
              width: "40%",
              background: theme.palette.red[200],
              "&:hover": { background: theme.palette.red[300] },
            }}
            onClick={() => {
              deleteItems();
            }}
          >
            DELETE
          </Button>

        </Grid>
      </DialogContent>{" "}
    </Dialog>
  );
};

export default BulkDelete;
