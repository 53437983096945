import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Grid,
  useTheme,
} from "@mui/material";

import Papa from "papaparse";
const TradesConfirmation = (props) => {
  const theme = useTheme();
  const { confirm, setConfirm, tradesPopup, setTradesOpenPopup, title,message } = props;
  return (
    <Dialog open={confirm} maxWidth="xs">
      <DialogTitle>
        <Typography
          variant="h6"
          component="div"
          align="center"
          style={{ flexGrow: 1, fontSize: "20px" }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography
          variant="subtitle1"
          component="div"
          textAlign="center"
          style={{ flexGrow: 1 }}
        >
          {message
            ? message
            : " Are you sure you have saved changes and want to close custodians trade table?"}
        </Typography>

        <Grid
          item
          xs={12}
          margin="20px 1px 20px 1px "
          display="flex"
          justifyContent="space-between"
        >
          <Button
            variant="contained"
            sx={{
              fontSize: "12px",
              fontWeight: "bold",
              height: "40px",
              width: "150px",
              mr: 1,
            }}
            onClick={() => {
              setConfirm(false);
              setTradesOpenPopup(false);
            }}
          >
            YES, CLOSE
          </Button>
          <Button
            variant="contained"
            sx={{
              fontSize: "12px",
              fontWeight: "bold",
              height: "40px",
              width: "150px",
              background: "#B2BEB5",
              color: "#000",
              "&:hover": { background: "#808080" },
            }}
            onClick={() => {
              setConfirm(false);
            }}
          >
            NO, CANCEL
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default TradesConfirmation;
