import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Grid,
  CircularProgress,
  Box,
  useTheme,
} from "@mui/material";
import Failedprompt from "./Failedprompt";
import Successprompt from "./Successprompt";
import { useSelector } from "react-redux";
import Popup from "../Popup";
import Response from "../Loader/Response";
const SendTradeForCustodian = (props) => {
  const theme = useTheme();
  const {
    title,
    openTradeForCustodian,
    setOpenTradeForCustodian,
    fileName,
    fileData,
    user,
    custodian,
  } = props;

  const [successprompt, setsuccessprompt] = useState(false);
  const [openSyncPopup, setOpenSyncPopup] = useState(false);
  const [message, setmessage] = useState(false);

  const [failprompt, setfailprompt] = useState(false);
  const { Ftp } = useSelector((state) => state?.todos);

  const SendBoFTrade = () => {
    window.clientSocks.send(
      `{"action":"send_eod", "data":${JSON.stringify({
        fileNames: [{ fileName: fileName, custodian: custodian }],
        user: user,
      })}}`
    );
    setOpenTradeForCustodian(false);
    setOpenSyncPopup(true);

    if (Ftp[0].status === "successful") {
     
      setsuccessprompt(true);
    } else {
      setfailprompt(true);
     
    }
    setmessage(true);
  };

  return (
    <>
      <Dialog
        open={openTradeForCustodian}
        sx={{ width: "100%", height: "100%" }}
      >
        <DialogTitle>
          <Typography
            variant="h6"
            component="div"
            align="center"
            style={{ flexGrow: 1, fontSize: "20px" }}
          >
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="body" component="div" style={{ flexGrow: 1 }}>
            Are you sure you want to Post Trade for this Custodian?
          </Typography>
          <Grid
            item
            xs={12}
            margin="20px 1px 20px 1px "
            display="flex"
            justifyContent="space-between"
          >
            <Button
              variant="contained"
              sx={{
                width: "40%",
              }}
              onClick={() => {
                SendBoFTrade();
              }}
            >
              POST TRADE
            </Button>
            <Button
              variant="contained"
              sx={{
                width: "40%",
                background: "#B2BEB5",
                color: "#000",
                "&:hover": { background: "#808080" },
              }}
              onClick={() => {
                setOpenTradeForCustodian(false);
              }}
            >
              CANCEL TRADE
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
      <Successprompt
        title="Success"
        openTradeForCustodian={successprompt}
        setOpenTradeForCustodian={setsuccessprompt}
      />
      <Failedprompt
        title="Success"
        openTradeForCustodian={failprompt}
        setOpenTradeForCustodian={setfailprompt}
      />
      <Popup openPopup={openSyncPopup} setOpenPopup={setOpenSyncPopup}>
        <Response message={message} />
      </Popup>
    </>
  );
};

export default SendTradeForCustodian;
