import { Grid, TextField, Button, Box } from "@mui/material";
import { useFormik } from "formik";
import { CommissionEditValidation } from "../../Schemas";
import { useSelector } from "react-redux";

function CommissionEdit({ setOpenPopup, TradeForEdit, trade }) {
  const user = useSelector((state) => state?.users?.user?.username);

  const initialValues = {
    commission: TradeForEdit?.commission?.commission,
  };

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: CommissionEditValidation,

    onSubmit: ({ commission }) => {
      window.clientSocks.send(
        `{"action":"modify_trade_commissions", "data": ${JSON.stringify([
          {
            commission: Number(commission),
            trade,
            user,
          },
        ])}}`
      );
      setOpenPopup(false);
    },
  });

  return (
    <Box component="form" autoComplete="off" onSubmit={handleSubmit}>
      <Grid item xs={12} marginBottom="45px">
        <TextField
          fullWidth
          name="commission"
          id="commission"
          label="Commission Per Share"
          value={values.commission}
          onChange={(e) => {
            handleChange(e);
          }}
          variant="standard"
        />
        {errors.commission && touched.commission ? (
          <p className="error">*{errors.commission}</p>
        ) : null}
      </Grid>

      <Grid
        item
        xs={12}
        margin="10px"
        display="flex"
        justifyContent="space-between"
      >
        <Button variant="contained" sx={{ width: "30%" }} type="submit">
          SAVE
        </Button>
        <Button
          variant="contained"
          sx={{
            width: "30%",
            background: "#B2BEB5",
            color: "#000",
            "&:hover": { background: "#808080" },
          }}
          onClick={() => {
            setOpenPopup(false);
          }}
        >
          CANCEL
        </Button>
      </Grid>
    </Box>
  );
}

export default CommissionEdit;
