import * as React from "react";
import Box from "@mui/material/Box";
import "../App.css";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import { useFormik } from "formik";
import { BrokerName } from "../component/Schemas";
import { useSelector } from "react-redux";

const AddBrokerDetails = ({ data, setOpenPopup, setData }) => {
  const brokers = useSelector((state) => state?.todos?.data);
  const { routeList } = useSelector((state) => state?.todos);
  const flatProps =
    routeList?.length > 0
      ? routeList?.map((option) => ({
          ...option,
          title: option.name,
          id: option.routerId,
        }))
      : [];
  const [autocompletedValue, setautoCompletedValue] = React.useState([]);
  const [extraInputsValues, setExtraInputsValues] = React.useState({});
  const initialValues = {
    name: "",
    abbreviation: "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: BrokerName,

      onSubmit: (values) => {
        values.abbreviation = values.abbreviation.toLowerCase();
        if (brokers.find((a) => a.abbreviation === values.abbreviation)) {
          alert(`${values.abbreviation} already exists, try new one.`);
        } else {
          const inputFilteredValues = autocompletedValue.map((val, index) => {
            if (val.routerId === "e55e372946e923059944bc08d654d7e0") {
              return {
                routerId: val.routerId,
                name:
                  extraInputsValues[val.routerId] === "" ||
                  !extraInputsValues[val.routerId]
                    ? values.abbreviation
                    : extraInputsValues[val.routerId],
              };
            } else {
              return {
                routerId: val.routerId,
                text: extraInputsValues[val.routerId],
              };
            }
          });

          window.clientSocks.send(
            `{"action":"new_broker", "data":${JSON.stringify({
              ...values,
              routers: inputFilteredValues,
            })}}`
          );
          // }
          setOpenPopup(false);
        }
      },
    });

  const handleInputChange = (key, val) => {
    setExtraInputsValues((prev) => ({ ...prev, [key]: val }));
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
      onSubmit={handleSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} marginBottom="10px">
          <TextField
            fullWidth
            id="name"
            label="Full Name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="standard"
          />
          {errors.name && touched.name ? (
            <p className="error">*{errors.name}</p>
          ) : null}
        </Grid>

        <Grid item xs={12} margin="5px">
          <TextField
            fullWidth
            id="abbreviation"
            label="Abbreviation"
            value={values.abbreviation}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="standard"
          />
          {errors.abbreviation && touched.abbreviation ? (
            <p className="error">*{errors.abbreviation}</p>
          ) : null}
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            multiple
            sx={{ width: "100%" }}
            id="tags-standard"
            onChange={(e, val) => {
              let key = "routerId";
              var uniqueVal = [
                ...new Map(val.map((item) => [item[key], item])).values(),
              ];

              setautoCompletedValue(uniqueVal);
            }}
            value={autocompletedValue}
            options={flatProps}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Select OMS"
                placeholder="OMS"
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          {autocompletedValue?.length > 0 &&
            autocompletedValue.map((val) => {
              return (
                <TextField
                  variant="standard"
                  value={extraInputsValues[val.routerId]}
                  onChange={(e) =>
                    handleInputChange(val.routerId, e.target.value)
                  }
                  label={val?.abbreviation}
                />
              );
            })}
        </Grid>

        <Grid
          item
          xs={12}
          margin="10px"
          display="flex"
          justifyContent="space-between"
        >
          <Button variant="contained" sx={{ width: "30%" }} type="submit">
            SAVE
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "30%",
              background: "#B2BEB5",
              color: "#000",
              "&:hover": { background: "#808080" },
            }}
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            CANCEL
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
export default AddBrokerDetails;
