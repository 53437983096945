import React, { useState, useEffect } from "react";
import {
  Table,
  TableRow,
  TableBody,
  useTheme,
  Box,
  Typography,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import VantageListHead from "../../../../ListHead/SignalList";
import {
  Tablecell,
  applySortFilter,
  getComparator,
  hashInvestment,
} from "../../../../Methods";
import MarketDataUnavailablePopup from "../../../../Popup/MarketDataUnavailablePopup";
import {
  formatTimeAgo5minutes,
  getStrategiesContainingTicker,
} from "../../../../../utils/helpers";

const SignalsLimitedByLocates = ({
  dummy,
  tableHeader,
  showPopup,
  marketData,
  strategyListToggle,
  ignoreSecurities,
  setIgnoreSecurities,
  setIgnoredata,
}) => {
  const theme = useTheme();
  const [openPopup, setOpenPopup] = useState(false);
  const [data, setData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("ticker");
  const [selectedRow, setSelectedRow] = useState(null);
  const [unvailableStratgies, setUnvailableStratgies] = useState([]);

  const handleRowClick = (row) => {
    if (showPopup && !row.marketData) {
      setSelectedRow(row);
      setOpenPopup(true);
      const unvailableStratgies = getStrategiesContainingTicker(
        strategyListToggle,
        row?.ticker
      );
      setUnvailableStratgies(unvailableStratgies);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleIgnoreClick = () => {
    setIgnoreSecurities((prev) => !prev);
  };

  useEffect(() => {
    const updatedData = dummy?.map(({ ticker, ...rest }) => {
      const matchingMarketData = marketData?.find(
        (md) => md?.ticker?.toLowerCase() === ticker?.symbol?.toLowerCase()
      );
     
      const marketDataAvailable =
        matchingMarketData &&
        formatTimeAgo5minutes(matchingMarketData?.date)?.msg === "true";

      return {
        ticker: ticker.symbol,
        investment: hashInvestment(ticker.investment),
        marketData: marketDataAvailable,
        ignored: !marketDataAvailable && ignoreSecurities,
        date: matchingMarketData?.date || null,
        ...rest,
      };
    });
    const allMarketDataAvailable = updatedData.every((item) => item.marketData);

    if (allMarketDataAvailable) {
      setIgnoreSecurities((prev) => !prev);
    }

    const ignoredDataOnly = updatedData
      .filter((item) => item.ignored)
      .map(({ ignored, ...rest }) => rest);

    setIgnoredata(ignoredDataOnly);

    const filteredUsers = applySortFilter(
      updatedData,
      getComparator(order, orderBy)
    );
    setData(filteredUsers);
  }, [order, orderBy, dummy, marketData, ignoreSecurities]);

  return (
    <>
      <Box
        sx={{
          border: `100px ${theme.palette.lightGrey.tableHeader}`,
          borderRadius: "4px",
          marginTop: "10px",
          width: "80%",
          marginRight: 1,
        }}
      >
        <Typography
          color="primary"
          sx={{
            fontSize: "15px",
            textAlign: "right",
            textDecoration: "none",
            "&:hover": {
              cursor: "pointer",
              textDecoration: "underline",
              textUnderlineOffset: "2px",
            },
          }}
          onClick={handleIgnoreClick}
        >
          {ignoreSecurities
            ? "Show all securities"
            : "Ignore securities that don't have market data"}
        </Typography>

        <Box
          sx={{
            maxHeight: "400px",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "5px",
              height: "3px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#f0f0f0",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#c0c0c0",
              borderRadius: "10px",
              minHeight: "2px",
            },
          }}
        >
          <Table
            size="small"
            stickyHeader
            aria-label="a dense table"
            sx={{
              borderCollapse: "collapse",
              border: "solid 1px #eee",
              marginTop: "-1px",
            }}
          >
            <VantageListHead
              order={order}
              orderBy={orderBy}
              headLabel={tableHeader}
              noStatus={true}
              customizeFont={12}
              rowCount={data.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {data?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:hover": {
                      background: theme.palette.grey[200],
                      cursor: "pointer",
                    },
                  }}
                >
                  <Tablecell
                    align="left"
                    style={{
                      fontSize: "12px",
                      paddingLeft: "5px",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  >
                    {row.ticker}
                  </Tablecell>
                  <Tablecell
                    align="left"
                    style={{
                      fontSize: "12px",
                      paddingLeft: "5px",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  >
                    {row.investment}
                  </Tablecell>

                  <Tablecell
                    align="left"
                    style={{
                      fontSize: "12px",
                      paddingLeft: "5px",
                      borderLeft: "none",
                      borderRight: "none",
                      display: "flex",
                      alignItems: "center",
                      color: row.marketData ? "green" : "red",
                    }}
                    onClick={() => handleRowClick(row)}
                  >
                    {row.marketData ? (
                      <Typography
                        sx={{ fontSize: "12px", color: "green" }}
                        variant="body2"
                      >
                        Available
                      </Typography>
                    ) : (
                      <>
                        <Typography variant="body2" sx={{ fontSize: "12px" }}>
                          Not Available
                        </Typography>
                        <ErrorIcon sx={{ fontSize: "15px", mr: 0.5 }} />{" "}
                        {row.ignored ? (
                          <Typography
                            variant="body2"
                            disabled
                            sx={{ fontSize: "10px", ml: 1 }}
                          >
                            ignored
                          </Typography>
                        ) : null}
                      </>
                    )}
                  </Tablecell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>

      {selectedRow && (
        <MarketDataUnavailablePopup
          open={openPopup}
          onClose={() => setOpenPopup(false)}
          row={selectedRow}
          unvailableStratgies={unvailableStratgies}
        />
      )}
    </>
  );
};

export default SignalsLimitedByLocates;
