import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Grid,
  Box,
  FormControl,
  Autocomplete,
  TextField,
  useTheme,
  IconButton,
} from "@mui/material";
import { aum } from "../dummyData";
import CloseIcon from "@mui/icons-material/Close";

const BenchMarkData = (props) => {
  const theme = useTheme();
  const { title, isViewBenchmarkData, setisViewBenchmarkData, row, setRow } =
    props;
  const [pf, setPf] = useState("");
  const [val1, setVal1] = useState("");
  const [empcol, setEmpcol] = useState("");
  useEffect(() => {
    if (row) {
      setPf(row.pf);
      setVal1(row.val1);
      setEmpcol(row.empcol);
    }
  }, [row, aum]);

  const handleClose = () => {
    setisViewBenchmarkData(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      open={isViewBenchmarkData}
      sx={{
        "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
          minWidth: "300px",
          marginLeft: "200px",
        },
      }}
    >
      <DialogTitle>
        <div style={{ display: "flex" }}>
          <Typography
            variant="h6"
            component="div"
            align="left"
            style={{ flexGrow: 1, fontSize: "20px" }}
          >
            {title}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <Box
          sx={{
            border: `1px solid ${theme.palette.lightGrey.tableHeader}`,
            borderRadius: "4px",
          }}
        >
          <Box sx={{ padding: "10px 20px" }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <Autocomplete
                    disableClearable
                    value={pf}
                    ListboxProps={{
                      sx: { fontSize: 12 },
                    }}
                    sx={{
                      "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
                        fontSize: 12,
                      },
                    }}
                    onChange={(e, newValue) => {
                      setPf(newValue);
                    }}
                    options={aum?.map((val) => val?.pf)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        value={pf}
                        label="Benchmark"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <Autocomplete
                    disableClearable
                    value={val1}
                    ListboxProps={{
                      sx: { fontSize: 12 },
                    }}
                    sx={{
                      "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
                        fontSize: 12,
                      },
                    }}
                    onChange={(e, newValue) => {
                      setVal1(newValue);
                    }}
                    options={aum?.map((val) => val?.val1)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        value={val1}
                        label="Non-market hours ticker"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <Autocomplete
                    disableClearable
                    value={empcol}
                    ListboxProps={{
                      sx: { fontSize: 12 },
                    }}
                    sx={{
                      "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
                        fontSize: 12,
                      },
                    }}
                    onChange={(e, newValue) => {
                      setEmpcol(newValue);
                    }}
                    options={aum?.map((val) => val?.empcol)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        value={empcol}
                        label="Market hours ticker"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            marginTop: "15px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              width: "150px",
            }}
            onClick={() => {
              setisViewBenchmarkData(false);
            }}
          >
            SAVE CHANGES
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "150px",
              background: "#B2BEB5",
              color: "#000",
              "&:hover": { background: "#808080" },
            }}
            onClick={() => {
              setisViewBenchmarkData(false);
            }}
          >
            CLOSE
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default BenchMarkData;
