import * as React from "react";
import Box from "@mui/material/Box";
import "../../App.css";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Button, FormControl, Autocomplete } from "@mui/material";
import { useFormik } from "formik";
import { RolevalidationSchema } from "../Schemas";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";

const AddRole = ({ setOpenPopup }) => {
  const { accountingData, funds, strategies } = useSelector(
    (state) => state.todos
  );
  const initialValues = {
    role: "",
    tradeBlotter: [],
    dailyPerformance: [],
    performanceDashboard: [],
    signals: [],
    rebalancer: [],
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: RolevalidationSchema,
      onSubmit: (values) => {
        setOpenPopup(false);
      },
    });

  const Options = ["View", "Add", "Edit", "Delete"];

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
      onSubmit={handleSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} marginBottom="5px">
          <TextField
            fullWidth
            id="role"
            label="Name"
            value={values.role}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="standard"
          />
          {errors.role && touched.role ? (
            <p className="error">*{errors.role}</p>
          ) : null}
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              multiple
              id="tradeBlotter"
              value={values.tradeBlotter}
              onChange={(event, newValue) => {
                handleChange({
                  target: { id: "tradeBlotter", value: newValue },
                });
              }}
              options={Options}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Trade Blotter"
                />
              )}
            />
            {errors.tradeBlotter && touched.tradeBlotter ? (
              <p className="error">*{errors.tradeBlotter}</p>
            ) : null}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              multiple
              id="dailyPerformance"
              value={values.dailyPerformance}
              onChange={(event, newValue) => {
                handleChange({
                  target: { id: "dailyPerformance", value: newValue },
                });
              }}
              options={Options}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Daily Performance"
                />
              )}
            />
            {errors.dailyPerformance && touched.dailyPerformance ? (
              <p className="error">*{errors.dailyPerformance}</p>
            ) : null}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              multiple
              id="performanceDashboard"
              value={values.performanceDashboard}
              onChange={(event, newValue) => {
                handleChange({
                  target: { id: "performanceDashboard", value: newValue },
                });
              }}
              options={Options}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Performance Dashboard"
                />
              )}
            />
            {errors.performanceDashboard && touched.performanceDashboard ? (
              <p className="error">*{errors.performanceDashboard}</p>
            ) : null}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              multiple
              id="signals"
              value={values.signals}
              onChange={(event, newValue) => {
                handleChange({
                  target: { id: "signals", value: newValue },
                });
              }}
              options={Options}
              renderInput={(params) => (
                <TextField {...params} variant="standard" label="Signals" />
              )}
            />
            {errors.signals && touched.signals ? (
              <p className="error">*{errors.signals}</p>
            ) : null}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              multiple
              id="rebalancer"
              value={values.rebalancer}
              onChange={(event, newValue) => {
                handleChange({
                  target: { id: "rebalancer", value: newValue },
                });
              }}
              options={Options}
              renderInput={(params) => (
                <TextField {...params} variant="standard" label="Rebalancer" />
              )}
            />
            {errors.rebalancer && touched.rebalancer ? (
              <p className="error">*{errors.rebalancer}</p>
            ) : null}
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          margin="10px 10px 10px 0px"
          display="flex"
          justifyContent="space-between"
        >
          <Button variant="contained" sx={{ width: "30%" }} type="submit">
            SAVE ROLE
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "30%",
              background: "#B2BEB5",
              color: "#000",
              "&:hover": { background: "#808080" },
            }}
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            CANCEL
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddRole;
