import { Helmet } from "react-helmet";
import SignalLayout from "../component/Layouts/SignalLayout";
import Topbar from "../component/NavComponents/Topbar";

const Signal = () => {
  return (
    <>
      <Helmet>
        <title>Historical Performace</title>
      </Helmet>
      <Topbar title="Historical Performance" />
      <SignalLayout />
    </>
  );
};
export default Signal;
