import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { rows } from "../../component/dummyData";
import { styled } from "@mui/material";
import CollapsedTable from "../TickerDetails/CollapsedTable";

const Tablecell = styled(TableCell)(({ theme }) => ({
  border: "1px solid #000",
}));


const Ticker = () => {
  return (
    <Box sx={{ width: "100%", marginTop: "100px" }}>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table" sx={{ border: "1px solid #000" }}>
          <TableHead>
            <TableRow>
              <Tablecell />

              <Tablecell align="center">Ticker</Tablecell>
              <Tablecell align="center">Quantity</Tablecell>
              <Tablecell align="center">Last price</Tablecell>
              <Tablecell align="center">Change&nbsp;(7D)</Tablecell>
              <Tablecell align="center">Change&nbsp;(7D)%</Tablecell>
              <Tablecell align="center">Profit</Tablecell>
              <Tablecell align="center">$Exposure</Tablecell>
              <Tablecell align="center">Exposure%</Tablecell>
              <Tablecell align="center">Fr Tgt</Tablecell>
              <Tablecell align="center">Options</Tablecell>
              <Tablecell align="center">Tgt</Tablecell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <CollapsedTable row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default Ticker;
