import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import { Box, ButtonBase, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { columns } from "../Methods";
import { Button, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ConfirmationPopUp from "../Popup/ConfirmationPopUp";
import SendTradeForCustodian from "../Popup/SendTradeForCustodian";
import SendTradeForallCustodian from "../Popup/SendTradeForallCustodian";
import moment from "moment";
import Loader from "../Loader";


const Csv = () => {
  const theme = useTheme();
  const date = moment().format("DD-MM-YYYY");
  const [showLoader, setShowLoader] = useState(true);
  const [filePath, setfilePath] = useState();
  const [fileName, setfileName] = useState(`${date} Bofa`);
  const [openSave, setOpenSave] = useState(false);
  const { file } = useSelector((state) => state?.todos);
  const [openTradeForCustodian, setOpenTradeForCustodian] = useState(false);
  const [openTradeForallCustodian, setOpenTradeForallCustodian] =
    useState(false);
  const user = useSelector((state) => state?.users?.user?.username);
  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);
  const [custodian, setCustodian] = useState();
  const [error, setError] = useState("");
  const [allcustodians, setAllCustodians] = useState([]);
  useEffect(() => {
    const processFiles = async () => {
      if (file && file.filePaths) {
        let bofaCustodianFound = false;

        for (const fileItem of file.filePaths) {
          if (fileItem?.custodian === "bofa") {
            setfilePath(fileItem?.filePath);
            setfileName(fileItem?.fileName);
            setCustodian(fileItem?.custodian);

            try {
              let csvString = await Papa.parse(fileItem?.fileContent);
              setData(csvString?.data);
              setError("");
            } catch (error) {
              console.error("Error parsing CSV:", error);
              setError("Error parsing CSV");
            }

            bofaCustodianFound = true;
            break; // Break the loop once the bofaCustodian is found
          }
        }

        if (bofaCustodianFound) {
          setShowLoader(false);
        } else {
          setAllEmpty();
        }

        const transformed = file.filePaths.map(({ custodian, fileName }) => ({
          custodian,
          fileName,
        }));
        setAllCustodians(transformed);
      } else {
        setAllEmpty();
      }
    };

    processFiles();
  }, [file]);

  useEffect(() => {
    if (data) {
      setRows(alterData());
    }
  }, [data, file]);
  const alterData = () => {
    return data?.map((v, i) => {
      const tempv = {};
      v.forEach((n, j) => {
        tempv[j] = n;
      });
      tempv.id = i;
      return tempv;
    });
  };

  const EditRowsData = (obj) => {
    const updatedRows = rows.map((row) => {
      if (row.id === obj.id) {
        return { ...row, [obj.field]: obj.value };
      }
      return row;
    });

    const newData = updatedRows.map((row) => {
      const newRow = [];
      for (let j = 0; j < 45; j++) {
        newRow.push(row[j]);
      }
      return newRow;
    });
    setData(newData);
    setRows(updatedRows);
  };

  const uppercaseValueFormatter = (params) => {
    return String(params.value).toUpperCase();
  };

  const columnsWithUppercase = columns?.map((column) => ({
    ...column,
    valueFormatter: uppercaseValueFormatter,
  }));
  const setAllEmpty = () => {
    setData([]);
    setCustodian("");
    setfilePath();
    setError("Sorry,no data...");
    setShowLoader(false);
  };
  
  return (
    <div>
      {showLoader ? (
        <div style={{ height: 300, width: "100%" }}>
          <Loader noMin={true} mTrue={true} />
        </div>
      ) : (
        // )
        // : error ? (
        //   <div style={{ height: 300, width: "100%" }}>{error}</div>
        <Box>
          <div style={{ height: 300, width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={columnsWithUppercase}
              pageSize={20}
              rowsPerPageOptions={[20]}
              rowHeight={20}
              sx={{
                "& .MuiDataGrid-cell": {
                  color: "#000",
                  fontSize: "12px",
                  border: "0.1px solid #dadada",
                },
                "& .MuiDataGrid-columnHeaders": {
                  background: theme.palette.grey[700],
                  color: theme.palette.grey[100],
                  fontSize: "12px",
                },
              }}
              onCellEditCommit={(props, event) => {
                EditRowsData(props);
              }}
            />
          </div>

          <Stack direction="row" justifyContent="flex-end" sx={{ mt: 1 }}>
            <Button
              variant="contained"
              sx={{
                background: "#424E5F",
                fontSize: "12px",
                fontWeight: "bold",
                mr: 3,
                "&:hover": { background: "#424E5F" },
              }}
              onClick={() => {
                setOpenTradeForCustodian(true);
              }}
            >
              POST TRADE FOR THIS CUSTODIAN
            </Button>
            <ButtonBase
              variant="contained"
              sx={{
                background: theme.palette.blue[200],
                width: 140,
                padding: 1,
                mr: 3,
                borderRadius: 1,
                "&:hover": { background: theme.palette.blue[300] },
              }}
            >
              <a
                href={filePath}
                target="_blank"
                style={{
                  fontSize: theme.typography.bodyText14.fontSize,
                  fontWeight: "bold",
                  color: "#fff",
                  width:"100%",
                  textDecoration: "none",
                }}
              >
                Download CSV
              </a>
            </ButtonBase>
            <Button
              variant="contained"
              color="success"
              sx={{ fontSize: "12px", fontWeight: "bold" }}
              startIcon={<CheckCircleIcon />}
              onClick={() => {
                setOpenSave(true);
              }}
            >
              SAVE CHANGES
            </Button>
          </Stack>
        </Box>
      )}
      <ConfirmationPopUp
        title="YOUR CHANGES HAVE BEEN SAVED"
        openSave={openSave}
        setOpenSave={setOpenSave}
        fileData={data}
        fileName={fileName}
        user={user}
      />
      <SendTradeForCustodian
        title="POST TRADE FOR THIS CUSTODIAN"
        openTradeForCustodian={openTradeForCustodian}
        setOpenTradeForCustodian={setOpenTradeForCustodian}
        fileData={data}
        custodian={custodian}
        fileName={fileName}
        user={user}
      />

      <SendTradeForallCustodian
        title="POST TRADE FOR ALL CUSTODIAN"
        openTradeForallCustodian={openTradeForallCustodian}
        setOpenTradeForallCustodian={setOpenTradeForallCustodian}
        fileData={data}
        custodian={allcustodians}
        fileName={fileName}
        user={user}
      />
    </div>
  );
};

export default Csv;
