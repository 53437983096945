import React from "react";
import { Box, Tab, Grid } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import OrderEdit from "../component/TradeBlotterDetails/TradeBlotterTable/OrderEdit";
import FillsEdit from "../component/TradeBlotterDetails/TradeBlotterTable/FillsEdit";
import { useEffect, useState } from "react";
import CommissionEdit from "../component/TradeBlotterDetails/TradeBlotterTable/CommissionEdit";

const EditTradeDetails = ({ setOpenPopup, TradeForEdit }) => {
  const [order, setorder] = useState();
  const [value, setValue] = React.useState("1");
  const handleChangeVal = (event, newValue) => {
    setValue(newValue);
  };

  const showOrders = (TradeForEdit) => {
    if (TradeForEdit.orders && TradeForEdit.orders.length > 0) {
      return TradeForEdit.orders.slice(-1).map((i) => setorder(i));
    } else return null;
  };

  useEffect(() => {
    if (TradeForEdit) {
      showOrders(TradeForEdit);
    }
  }, [TradeForEdit]);
  return (
    <Box>
      <Grid container spacing={1}>
        <Box
          sx={{
            width: value == "1" || "2" ? "800px" : "100%",
          }}
        >
          <TabContext value={value}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                color: "#1BA0F4",
                fontSize: "16px",
                fontWeight: "500px",
              }}
            >
              <TabList
                onChange={handleChangeVal}
                aria-label="lab API tabs example"
              >
                <Tab label="Fills" value="1" />
                <Tab label="Commission" value="2" />
                <Tab label="Order" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <FillsEdit
                setOpenPopup={setOpenPopup}
                TradeForEdit={TradeForEdit}
                trade={TradeForEdit?.tradeId}
              />
           
            </TabPanel>
            <TabPanel value="2">
              <CommissionEdit
                setOpenPopup={setOpenPopup}
                TradeForEdit={TradeForEdit}
                trade={TradeForEdit?.tradeId}
              />
           
            </TabPanel>
            <TabPanel value="3" sx={{ width: "100%", padding: 1 }}>
              <OrderEdit setOpenPopup={setOpenPopup} order={order} />
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>
    </Box>
  );
};
export default EditTradeDetails;
