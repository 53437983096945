import { Box, Typography } from "@mui/material";
import Ticker from "../TickerDetails";
import NetPosition from "../Tables/NetPosition";
import Aum from "../Tables/Aum";
import { styled } from "@mui/material";
import CustomizeBox from "../CustomizeBox";

const Heads = styled(Typography)(({ theme }) => ({
  fontSize: "22px",
  fontWeight: "700",
  lineHeight: "2",
}));

const TradeMonitor = () => {
  return (
    <>
      <Box sx={{ m: 4 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <CustomizeBox>
            <Heads>Net position</Heads>
            <NetPosition />
          </CustomizeBox>
          <CustomizeBox>
            <Heads>AUM</Heads>
            <Aum />
          </CustomizeBox>
        </Box>
        <Box sx={{ my: 2 }}>
          <Ticker />
        </Box>
      </Box>
    </>
  );
};
export default TradeMonitor;
