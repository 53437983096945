import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Grid,
  useTheme,
} from "@mui/material";

const DeletePopUp = (props) => {
  const theme = useTheme();
  const {
    title,
    openDeletePopup,
    setDeleteOpenPopup,
    recordForDelete,
    deleteObj,
    item,
  } = props;

  const deleteItem = () => {
    if (deleteObj == "delete_custodian") {
      window.clientSocks.send(
        `{"action":"delete_custodian", "data":{"custodian":${JSON.stringify(
          recordForDelete
        )}}}`
      );
    } else if (deleteObj == "delete_broker") {
      window.clientSocks.send(
        `{"action":"delete_broker", "data":{"broker":${JSON.stringify(
          recordForDelete
        )}}}`
      );
    } else if (deleteObj == "delete_fund") {
      window.clientSocks.send(
        `{"action":"delete_fund", "data":{"fund":${JSON.stringify(
          recordForDelete
        )}}}`
      );
    } else if (deleteObj == "delete_security_map") {
      // window.clientSocks.send(
      //   `{"action":"delete_fund", "data":{"fund":${JSON.stringify(
      //     recordForDelete
      //   )}}}`
      // );
    }
    // else if (deleteObj == "delete_user") {
    //   window.clientSocks.send(
    //     `{"action":"delete_user", "data":{"user":${JSON.stringify(
    //       recordForDelete
    //     )}}}`
    //   );
    // }
    else if (deleteObj == "delete_strategy") {
      window.clientSocks.send(
        `{"action":"delete_strategy", "data":{"strategy":${JSON.stringify(
          recordForDelete
        )}}}`
      );
    } else if (deleteObj == "delete_blacklistedTicker") {
      // window.clientSocks.send(
      //   `{"action":"delete_blacklistedTicker", "data":{"strategy":${JSON.stringify(
      //     recordForDelete
      //   )}}}`
      // );
    }

    setDeleteOpenPopup(false);
  };
  const displayValue =
    recordForDelete?.name || recordForDelete?.ticker_symbol || recordForDelete?.ticker|| "Unknown";
  return (
    <Dialog open={openDeletePopup} sx={{ width: "100%", height: "100%" }}>
      <DialogTitle>
        <Typography
          variant="h6"
          component="div"
          align="center"
          style={{ flexGrow: 1, fontSize: "20px" }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="body" component="div" style={{ flexGrow: 1 }}>
          {`Are you sure you want to delete ${displayValue} ?`}
        </Typography>
        <Grid
          item
          xs={12}
          margin="20px 1px 20px 1px "
          display="flex"
          justifyContent="center"
          columnGap={1}
        >
          <Button
            variant="contained"
            sx={{
              width: "40%",
              background: theme.palette.red[200],
              "&:hover": { background: theme.palette.red[300] },
            }}
            onClick={() => {
              deleteItem();
            }}
          >
            DELETE
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "40%",
              background: "#B2BEB5",
              color: "#000",
              "&:hover": { background: "#808080" },
            }}
            onClick={() => {
              setDeleteOpenPopup(false);
            }}
          >
            CANCEL
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default DeletePopUp;
