import React, { useState, useEffect } from "react";
import {
  Table,
  Card,
  Container,
  TableBody,
  TableContainer,
  IconButton,
  Box,
  useTheme,
  Typography,
} from "@mui/material";
import {
  TABLE_HEAD_PORTFOLIO_SHORT,
  TABLE_HEAD_PORTFOLIO_LONG,
  applySortFilter,
  getComparator,
} from "../../../Methods";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import Grid from "@mui/material/Grid";
import LongTable from "./LongTable";
import ShortTable from "./ShortTable";
import LongPopUp from "../../../Popup/LongPopUp";
import ShortPopUp from "../../../Popup/ShortPopUp";
import VantageListHead from "../../../ListHead/VantageListHead";

const AssetPositions = (props) => {
  const { data, fundVal, StrategeyVal } = props;
  const [long, setlong] = useState([]);
  const [expandLong, setExpandLong] = useState([]);
  const [short, setshort] = useState([]);
  const [expandShort, setExpandShort] = useState([]);
  const [orderByShort, setOrderByShort] = useState("size");
  const [orderByLong, setOrderByLong] = useState("size");
  const [longSortOrder, setLongSortOrder] = useState("desc");
  const [longFilterName, setLongFilterName] = useState("");
  const [shortSortOrder, setShortSortOrder] = useState("desc");
  const [shortFilterName, setShortFilterName] = useState("");
  const [isGridView, setIsGridView] = useState(false);
  const [isGridViewShort, setIsGridViewShort] = useState(false);

  useEffect(() => {
    const filteredData = data;

    const long = filteredData?.filter((i) => i?.deltaAdjustedShareCount > 0);
    const short = filteredData?.filter((i) => i?.deltaAdjustedShareCount <= 0);

    const renamedShort = short
      ?.map((item) => ({
        short_size: item?.size,
        short_current: item?.sharePrice,
        short_deltaadjustedsharecount: item?.deltaAdjustedShareCount,
        short_ch: item?.ch,
        short_pL: parseFloat(item?.pL),
        short_cost: parseFloat(item?.avgCost),
        short_last: item?.last,
        short_tickerSymbol: item?.tickerSymbol,
        short_chpercentage: parseFloat(item?.chPercetage),
        short_pl_avg_cost: parseFloat(
          (item?.last - item?.avgCost) * item?.deltaAdjustedShareCount
        ),
        short_percentage_avg_cost: parseFloat(
          ((item?.pL * item?.avgCost) / item?.avgCost) * 100
        ),
        dataSource: item?.dataSource,
        fetched: item?.fetched,
        valid: item?.valid,
        short_tdg: item?.tdg,
        short_custodian: item?.custodian,
      }))
      ?.sort((a, b) => b?.short_size - a?.short_size);

    const renamedLong = long
      ?.map((item) => ({
        long_size: item?.size,
        long_deltaadjustedsharecount: item?.deltaAdjustedShareCount,
        long_ch: item?.ch,
        long_pL: parseFloat(item?.pL),
        long_cost: parseFloat(item?.avgCost),
        long_last: item?.last,
        long_current: item?.sharePrice,
        long_tickerSymbol: item?.tickerSymbol,
        long_chpercentage: parseFloat(item?.chPercetage),
        long_pl_avg_cost: parseFloat(
          (item?.last - item?.avgCost) * item?.deltaAdjustedShareCount
        ),
        long_percentage_avg_cost: parseFloat(
          ((item?.pL * item?.avgCost) / item?.avgCost) * 100
        ),
        dataSource: item?.dataSource,
        fetched: item?.fetched,
        valid: item?.valid,
        long_tdg: item?.tdg,
        long_custodian: item?.custodian,
      }))
      ?.sort((a, b) => b?.long_size - a?.long_size);

    setExpandLong(renamedLong);
    setExpandShort(renamedShort);

    const filteredUsersLong = applySortFilter(
      renamedLong,
      getComparator(longSortOrder, orderByLong),
      longFilterName
    );

    const filteredUsersShort = applySortFilter(
      renamedShort,
      getComparator(shortSortOrder, orderByShort),
      shortFilterName
    );

    setExpandLong(renamedLong);
    setExpandShort(renamedShort);

    setlong(filteredUsersLong);
    setshort(filteredUsersShort);
  }, [
    data,
    longSortOrder,
    orderByLong,
    orderByShort,
    longFilterName,
    shortSortOrder,
    shortFilterName,
  ]);

  const handleRequestSort = (event, property) => {
    if (property.startsWith("long_")) {
      const isAsc = longSortOrder === "asc";
      setLongSortOrder(isAsc ? "desc" : "asc");
      setOrderByLong(property);
    } else if (property.startsWith("short_")) {
      const isAsc = shortSortOrder === "asc";
      setShortSortOrder(isAsc ? "desc" : "asc");
      setOrderByShort(property);
    }
  };

  const toggleGridView = () => {
    setIsGridView(!isGridView);
  };
  const toggleGridViewShort = () => {
    setIsGridViewShort(!isGridViewShort);
  };
  const theme = useTheme();
  const TABLE_HEAD_PORTFOLIO_SHORT_SLICED = [
    ...TABLE_HEAD_PORTFOLIO_SHORT.slice(0, 8),
    ...TABLE_HEAD_PORTFOLIO_SHORT.slice(10),
  ];
  const TABLE_HEAD_PORTFOLIO_LONG_SLICED = [
    ...TABLE_HEAD_PORTFOLIO_LONG.slice(0, 8),
    ...TABLE_HEAD_PORTFOLIO_LONG.slice(10),
  ];
  return (
    <Container maxWidth="xxl" sx={{ marginLeft: "-40px", mt: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6}>
          <Box
            sx={{
              border: `1px solid ${theme.palette.lightGrey.tableHeader}`,
              borderRadius: "4px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                background: theme.palette.green.longPositions,
                px: 1,
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{ color: theme.palette.lightGrey.textColor }}
              >
                Longs Positions
              </Typography>
              <IconButton
                onClick={toggleGridView}
                aria-label="Toggle GridView"
                sx={{ height: "20px", width: "20px" }}
              >
                <FullscreenIcon sx={{ height: "15px", width: "15px" }} />
              </IconButton>
            </Box>

            <TableContainer style={{ height: "50vh", maxHeight: "50vh" }}>
              <Table
                size="small"
                stickyHeader
                aria-label="a dense table"
                sx={{
                  height: "max-content",
                  maxHeight: "55px",
                  borderCllapse: "collapse",
                }}
              >
                <VantageListHead
                  headLabel={TABLE_HEAD_PORTFOLIO_LONG_SLICED}
                  noStatus={true}
                  title={true}
                  order={longSortOrder}
                  orderBy={orderByLong}
                  onRequestSort={handleRequestSort}
                />

                <TableBody>
                  {long?.map((row, i) => (
                    <LongTable
                      key={i}
                      row={row}
                      fundVal={fundVal}
                      strategyVal={StrategeyVal}
                      visible={false}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <br />
          </Box>
        </Grid>
        <Grid item xs={6} md={6}>
          <Box
            sx={{
              border: `1px solid ${theme.palette.lightGrey.tableHeader}`,
              borderRadius: "4px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                background: theme.palette.red.shortPositions,
                px: 1,
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{ color: theme.palette.lightGrey.textColor }}
              >
                Shorts Positions
              </Typography>
              <IconButton
                onClick={toggleGridViewShort}
                aria-label="Toggle GridView"
                sx={{ height: "20px", width: "20px" }}
              >
                <FullscreenIcon sx={{ height: "15px", width: "15px" }} />
              </IconButton>
            </Box>

            <TableContainer style={{ height: "50vh", maxHeight: "50vh" }}>
              <Table
                size="small"
                stickyHeader
                aria-label="a dense table"
                sx={{
                  height: "max-content",
                  maxHeight: "55px",
                  borderCllapse: "collapse",
                }}
              >
                <VantageListHead
                  headLabel={TABLE_HEAD_PORTFOLIO_SHORT_SLICED}
                  noStatus={true}
                  title={true}
                  order={shortSortOrder}
                  orderBy={orderByShort}
                  onRequestSort={handleRequestSort}
                />

                <TableBody>
                  {short?.map((row, i) => (
                    <ShortTable
                      key={i}
                      row={row}
                      fundVal={fundVal}
                      strategyVal={StrategeyVal}
                      visible={false}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <br />
          </Box>
        </Grid>
      </Grid>
      <LongPopUp
        openLongPopup={isGridView}
        setopenLongPopup={setIsGridView}
        long={expandLong}
        tablehead={TABLE_HEAD_PORTFOLIO_LONG}
        title="Longs Positions"
      />
      <ShortPopUp
        openShortPopup={isGridViewShort}
        setopenShortPopup={setIsGridViewShort}
        short={expandShort}
        tablehead={TABLE_HEAD_PORTFOLIO_SHORT}
        title="Shorts Positions"
      />
    </Container>
  );
};

export default AssetPositions;
