import React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import TableHead from "@mui/material/TableHead";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment/moment";
import {
  Tablecell,
  formatValue,
  calculateAndFormatNoAbsInt,
} from "../../Methods";

const Row = (props) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  const hasAumsData = (row?.strategyAUMs || [])?.length > 0;
  const theme = useTheme();

  return (
    <React.Fragment>
      <TableRow
        sx={{
          "&:hover": {
            background: theme.palette.grey[200],
            cursor: "pointer",
          },
        }}
      >
        <Tablecell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            sx={{ height: "10px", width: "10px", mx: 1 }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Tablecell>

        <Tablecell
          align="left"
          sx={{ paddingLeft: "5px" }}
          component="th"
          scope="row"
        >
          {row?.name}
        </Tablecell>
        <Tablecell align="right" sx={{ paddingRight: "5px", width: "160px" }}>
          {formatValue(row?.aum)}
        </Tablecell>
        <Tablecell align="left" sx={{ paddingLeft: "5px", width: "150px" }}>
          {row?.date ? moment(row?.date).format("MMM DD, YYYY") : "-"}
        </Tablecell>
      </TableRow>
      {hasAumsData && (
        <TableRow>
          <Tablecell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="strategies-aum-table">
                  <TableHead>
                    <TableRow>
                      <Tablecell align="left" sx={{ border: "none" }}>
                        Strategy Name
                      </Tablecell>
                      <Tablecell align="left" sx={{ border: "none" }}>
                        Strategy Aum
                      </Tablecell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(row?.strategyAUMs || [])?.map((strategy) => (
                      <TableRow
                        key={strategy}
                        sx={{
                          "&:hover": {
                            background: theme.palette.grey[200],
                            cursor: "pointer",
                          },
                        }}
                      >
                        <Tablecell align="left" sx={{ borderRight: "none" }}>
                          {strategy?.strategyName}
                        </Tablecell>
                        <Tablecell
                          align="right"
                          sx={{
                            paddingLeft: "5px",
                            width: "150px",
                            borderLeft: "none",
                          }}
                        >
                          {strategy?.strategyAUM < 0
                            ? `(${calculateAndFormatNoAbsInt(
                                Math.abs(strategy?.strategyAUM)
                              )})`
                            : calculateAndFormatNoAbsInt(strategy?.strategyAUM)}
                        </Tablecell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </Tablecell>
        </TableRow>
      )}
      {!hasAumsData && open && (
        <TableRow>
          <TableCell colSpan={6}>
            <Typography variant="subtitle1">No Strategies</Typography>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};
export default Row;
