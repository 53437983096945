import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Stack, Alert } from "@mui/material";
import { ChangeUserPass } from "../../Schemas";
import { useTheme } from "@emotion/react";
import { changePassword } from "../../../utils/restAPI";
import { updateLoading } from "../../../Redux/Reducers/todoReducers";
import { useDispatch, useSelector } from "react-redux";

const ChangeUserPassword = () => {
  const [passReset, setPassReset] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const accessToken = localStorage.getItem("key");
  const { user } = useSelector((state) => state?.users);
  const username = user?.username;
  const dispatch = useDispatch();
  const theme = useTheme();

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: ChangeUserPass,
    onSubmit: async ({ oldPassword, password }) => {
      dispatch(updateLoading(true));
      try {
        const response = await changePassword(
          username,
          accessToken,
          oldPassword,
          password
        );
        dispatch(updateLoading(false));

        if (response && response.success) {
          setPassReset(true);
          setEmailVerified(false);
        } else {
          setPassReset(false);
          setEmailVerified(true);
        }
      } catch (error) {
        setPassReset(false);
        setEmailVerified(true);
      }
    },
  });

  return (
    <Container
      maxWidth="100%"
      sx={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}
    >
      <Stack
        direction="column"
        spacing={3}
        alignItems="right"
        sx={{ ml: 10, width: "50%" }}
      >
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            label="Old Password"
            variant="standard"
            margin="normal"
            size="small"
            id="oldPassword"
            name="oldPassword"
            type="password"
            value={formik.values.oldPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.oldPassword && Boolean(formik.errors.oldPassword)
            }
            helperText={formik.touched.oldPassword && formik.errors.oldPassword}
          />

          <TextField
            fullWidth
            label="New Password"
            variant="standard"
            margin="normal"
            size="small"
            id="password"
            name="password"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />

          <TextField
            fullWidth
            label="Confirm Password"
            variant="standard"
            margin="normal"
            size="small"
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
          />
          {formik.errors.confirmPassword && formik.touched.confirmPassword && (
            <Alert severity="error">{formik.errors.confirmPassword}</Alert>
          )}
          {passReset && (
            <Alert severity="success">Your password has been changed. </Alert>
          )}
          {emailVerified && !passReset && (
            <Alert severity="error" sx={{ my: 1 }}>
              Something Went Wrong..
            </Alert>
          )}

          <Button
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            disabled={formik.isSubmitting}
          >
            Update Password
          </Button>
        </form>
      </Stack>
    </Container>
  );
};

export default ChangeUserPassword;
