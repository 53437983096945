import React from "react";
import Topbar from "../component/NavComponents/Topbar";
import { Helmet } from "react-helmet";
import BlacklistedTickersLayout from "../component/Layouts/BlacklistedTickersLayout";

const BlackistedTickers = () => {
  return (
    <>
      <Helmet>
        <title>Blacklisted Tickers</title>
      </Helmet>
      <Topbar title="Blacklisted Tickers" />
      <BlacklistedTickersLayout />
    </>
  );
};
export default BlackistedTickers;
