import React, { useState, useEffect } from "react";
import { Box, useTheme, Autocomplete, TextField, Grid, FormControl } from "@mui/material";
import { findAum, findStrategy, processData, signalsData } from "../../Methods";
import AssetPositions from "./AssetPositions";

import { useDispatch, useSelector } from "react-redux";
import MonitorScreenCalculation from "../Portfolio/AssetPositions/MonitorScreenCalculation";
import { updateCalculatedMonitorScreen } from "../../../Redux/Actions/todoActions";

const PortfolioTable = ({ fundname, strategyname, setTotals, threshold }) => {
  const dispatch = useDispatch();
  const [fundName, setFundName] = useState([]);
  const [fundVal, setFundVal] = useState("ALL");
  const [StrategyName, setStrategyName] = useState([{ name: "ALL" }]);
  const [StrategeyVal, setStrategyVal] = useState("");
  const [StrategyType, setStrategyType] = useState(["Both"]);
  const [StrategeyTypename, setStrategyTypename] = useState("Both");
  const [disableStrategyButton, setDisableStrategyButton] = useState(true);
  const [filteredValues, setFilteredValues] = useState([]);
  const uniqueNames = [];
  const uniqueNamesSet = new Set();

  const { lots, accountingData, funds, strategies, marketData, signalsPerformance, managers, trades, isStrategist } = useSelector((state) => state?.todos);
  const fund = accountingData[0]?.funds;

  useEffect(() => {
    if (isStrategist) {
      setStrategyName(strategies.map((strategy) => ({ name: strategy.name })));
    } else {
      if (fundname) {
        setFundFilter(fundname);
      }
    }

    if (strategyname) {
      setStrategyVal(strategyname);
    }
  }, [fundname, strategyname]);

  useEffect(() => {
    if (funds) {
      const filteredName = funds?.map((i) => {
        return i.abbreviation;
      });
      filteredName.unshift("ALL");
      setFundName(filteredName);
    }
  }, [funds]);

  const setFundFilter = (newValue) => {
    const fundObj = funds?.find((i) => i.abbreviation == newValue);

    setStrategyTypename("Both");
    if (newValue == "ALL") {
      setDisableStrategyButton(false);
      setStrategyVal("");
      setFundVal(newValue);
    } else {
      setFundVal(newValue);
      setDisableStrategyButton(false);
      setStrategyVal("");
      const foundFund = fund?.find((row) => row?.fund === fundObj?.fundId);

      if (foundFund) {
        const strategyAUMsData = Object.keys(foundFund?.strategyAUMs || {})
          .filter((strategyId) => strategies.some((strategy) => strategy?.strategyId === strategyId))
          .map((strategyId) => {
            return {
              strategyId: strategyId,
              name: findStrategy(strategyId, strategies),
              aum: foundFund?.strategyAUMs[strategyId],
            };
          });

        if (strategyAUMsData?.length > 0) {
          unique(strategyAUMsData);
        } else {
          setStrategyName([]);
        }
      } else {
        setStrategyName([]);
      }
    }
  };

  const unique = (data) => {
    data.forEach((item) => {
      const nameParts = item.name.split("_");
      const baseName = nameParts[0];
      if (!uniqueNamesSet.has(baseName)) {
        const checkType = nameParts.length > 1 ? nameParts[1] : "noLS";
        uniqueNames.push({ name: baseName, checkType });
        uniqueNamesSet.add(baseName);
      }
    });
    uniqueNames.push({ name: "ALL" });
    setStrategyName(uniqueNames);
  };

  const filterData = (fundVal, StrategyVal, StrategeyTypename) => {
    if (fundVal == "ALL" || StrategyVal == "ALL") {
      fundVal = undefined;
      if (!isStrategist) StrategyVal = undefined;
    }

    let fundObject = funds?.find((obj) => obj.abbreviation === fundVal);
    if (fundObject) fundVal = fundObject.fundId;

    const filteredData = MonitorScreenCalculation({
      selectedFund: fundVal,
      selectedStrategy: StrategyVal,
      selectedSide: StrategeyTypename,
      lots: lots,
      accountingData: accountingData,
      funds: funds,
      strategies: strategies,
      marketData: marketData,
      trades: trades,
      isStrategist: isStrategist,
    });
    if (filteredData) dispatch(updateCalculatedMonitorScreen(filteredData));

    return filteredData;
  };

  const mySignals = (fnd, stra, typ) => {
    let matchingStrategies;

    if (fnd && stra) {
      if (stra !== undefined && stra !== "ALL" && stra !== "") {
        if (typ === "Both") {
          matchingStrategies = strategies?.find((strategy) => strategy.name === stra);
        } else if (typ === "Long" || typ === "Short") {
          matchingStrategies = strategies?.find((strategy) => strategy.name.includes(`${stra}_${typ}`));
        }
      }

      if (matchingStrategies) {
        const signalsCalc = signalsData(signalsPerformance, strategies, matchingStrategies.strategyId);

        const positiveValues = signalsCalc.filter((signal) => signal.value >= 0).reduce((sum, signal) => sum + signal.PercentChangeDay * signal.value, 0);

        const negativeValues = signalsCalc.filter((signal) => signal.value < 0).reduce((sum, signal) => sum + signal.PercentChangeDay * signal.value, 0);

        return {
          Long: positiveValues,
          Short: negativeValues,
        };
      }
    }

    return {
      Long: 0,
      Short: 0,
    };
  };

  const myTotals = (fnd, stra, typ) => {
    if (!managers || !(managers.length > 0)) {
      if (fnd && stra) {
        let monitorScreenValuesObj = filterData(fnd, stra, typ);
        const { Long, Short } = mySignals(fnd, stra, typ);
        let aum;
        if (monitorScreenValuesObj) {
          const result = monitorScreenValuesObj.reduce((acc, obj) => {
            if (obj.fund == "All") {
              aum = accountingData[0]?.funds.reduce((total, obj) => total + obj.aum, 0);
            } else {
              aum = findAum(obj.fund, accountingData[0]?.funds);
            }
            if (obj.deltaAdjustedShareCount > 0) {
              acc.positiveAUMTotal = (acc.positiveAUMTotal || 0) + parseFloat(obj.size);
              acc.positivePLTotal = (acc.positivePLTotal || 0) + parseFloat(obj.pL);
            } else if (obj.deltaAdjustedShareCount < 0) {
              acc.negativeAUMTotal = (acc.negativeAUMTotal || 0) + parseFloat(obj.size);
              acc.negativePLTotal = (acc.negativePLTotal || 0) + parseFloat(obj.pL);
            }
            return acc;
          }, {});
          const totalPL = result.positivePLTotal + result.negativePLTotal;
          const totalAUM = result.positiveAUMTotal + result.negativeAUMTotal;
          const totalsPcm = (result.positivePLTotal / ((result.positiveAUMTotal * aum) / 100)) * 100 + (result.negativePLTotal / (((result.negativeAUMTotal * aum) / 100) * -1)) * 100;
          const newArray = [];
          newArray.push({
            name: "LONG",
            totalsAUM: result.positiveAUMTotal,
            totalsPl: result.positivePLTotal,
            totalSignals: Long,
            totalsPcm: isNaN(result.positivePLTotal) || result.positivePLTotal === 0 ? "-" : (result.positivePLTotal / ((result.positiveAUMTotal * aum) / 100)) * 100,
          });
          const calculation = isNaN(result.negativePLTotal) || result.negativePLTotal === 0 ? "-" : (result.negativePLTotal / ((result.negativeAUMTotal * aum) / 100)) * 100 * -1;
          newArray.push({
            name: "SHORT",
            totalsAUM: result.negativeAUMTotal,
            totalsPl: result.negativePLTotal,
            totalsPcm: calculation,
            totalSignals: Short,
          });
          newArray.push({
            name: "TOTALS",
            totalsAUM: isNaN(totalAUM) ? "-" : totalAUM,
            totalsPl: isNaN(totalPL) ? "-" : totalPL,
            totalsPcm: isNaN(totalsPcm) ? "-" : totalsPcm,
            totalSignals: Short + Long,
          });
          setFilteredValues(monitorScreenValuesObj);
          setTotals(newArray);
        }
      } else {
        setFilteredValues([]);
        setTotals([]);
      }
    } else {
      let monitorScreenValuesObj = filterData(fnd, stra, typ);
      const { Long, Short } = mySignals(fnd, stra, typ);
      let aum;
      if (monitorScreenValuesObj) {
        const result = monitorScreenValuesObj.reduce((acc, obj) => {
          if (obj.fund == "All") {
            aum = accountingData[0]?.funds.reduce((total, obj) => total + obj.aum, 0);
          } else {
            aum = findAum(obj.fund, accountingData[0]?.funds);
          }

          if (obj.deltaAdjustedShareCount > 0) {
            acc.positiveAUMTotal = (acc.positiveAUMTotal || 0) + parseFloat(obj.size);
            acc.positivePLTotal = (acc.positivePLTotal || 0) + parseFloat(obj.pL);
          } else if (obj.deltaAdjustedShareCount < 0) {
            acc.negativeAUMTotal = (acc.negativeAUMTotal || 0) + parseFloat(obj.size);
            acc.negativePLTotal = (acc.negativePLTotal || 0) + parseFloat(obj.pL);
          }
          return acc;
        }, {});
        const totalPL = result.positivePLTotal + result.negativePLTotal;
        const totalAUM = result.positiveAUMTotal + result.negativeAUMTotal;
        const totalsPcm = (result.positivePLTotal / ((result.positiveAUMTotal * aum) / 100)) * 100 + (result.negativePLTotal / (((result.negativeAUMTotal * aum) / 100) * -1)) * 100;

        const newArray = [];
        newArray.push({
          name: "LONG",
          totalsAUM: result.positiveAUMTotal,
          totalsPl: result.positivePLTotal,
          totalSignals: Long,
          totalsPcm: isNaN(result.positivePLTotal) || result.positivePLTotal === 0 ? "-" : (result.positivePLTotal / ((result.positiveAUMTotal * aum) / 100)) * 100,
        });
        const calculation = isNaN(result.negativePLTotal) || result.negativePLTotal === 0 ? "-" : (result.negativePLTotal / ((result.negativeAUMTotal * aum) / 100)) * 100 * -1;

        newArray.push({
          name: "SHORT",
          totalsAUM: result.negativeAUMTotal,
          totalsPl: result.negativePLTotal,
          totalsPcm: calculation,
          totalSignals: Short,
        });

        newArray.push({
          name: "TOTALS",
          totalsAUM: isNaN(totalAUM) ? "-" : totalAUM,
          totalsPl: isNaN(totalPL) ? "-" : totalPL,
          totalsPcm: isNaN(totalsPcm) ? "-" : totalsPcm,
          totalSignals: Short + Long,
        });
        setFilteredValues(monitorScreenValuesObj);
        setTotals(newArray);
      }
    }
  };

  useEffect(() => {
    myTotals(fundVal, StrategeyVal, StrategeyTypename);
  }, [fundVal, StrategeyVal, StrategeyTypename, marketData, threshold, signalsPerformance]);
  useEffect(() => {
    let matchingStrategies;

    if (StrategeyVal !== undefined && StrategeyVal !== "ALL" && StrategeyVal !== "") {
      if (StrategeyTypename === "Both") {
        matchingStrategies = strategies?.find((strategy) => strategy.name === StrategeyVal);
      } else if (StrategeyTypename === "Long" || StrategeyTypename === "Short") {
        matchingStrategies = strategies?.find((strategy) => strategy.name.includes(`${StrategeyVal}_${StrategeyTypename}`));
      }
    }

    if (matchingStrategies && matchingStrategies.strategyId) {
      processData(strategies, matchingStrategies.strategyId);
    }
  }, [StrategeyVal, StrategeyTypename]);

  const theme = useTheme();

  return (
    <>
      <Box sx={{ marginLeft: 3, marginTop: 1 }}>
        <Box>
          <Grid container spacing={2}>
            {!isStrategist && (
              <Grid item xs={1.5}>
                <FormControl fullWidth>
                  <Autocomplete
                    disableClearable
                    value={fundVal}
                    ListboxProps={{
                      sx: { fontSize: 12 },
                    }}
                    sx={{
                      "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
                        fontSize: 12,
                      },
                    }}
                    onChange={(e, newValue) => {
                      setFundFilter(newValue);
                      myTotals(newValue);
                    }}
                    options={fundName?.map((val) => val)?.sort()}
                    renderInput={(params) => <TextField {...params} variant="standard" value={fundVal} label="Funds" />}
                  />
                </FormControl>
              </Grid>
            )}
            <Grid item xs={1.5}>
              <FormControl fullWidth>
                <Autocomplete
                  disableClearable
                  value={StrategeyVal}
                  ListboxProps={{
                    sx: { fontSize: 12 },
                  }}
                  sx={{
                    "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
                      fontSize: 12,
                    },
                  }}
                  onChange={(e, newValue) => {
                    myTotals(fundVal, newValue);
                    setStrategyTypename("Both");
                    if (newValue === "ALL") {
                      setStrategyType(["Both"]);
                      setStrategyVal("ALL");
                    } else {
                      setStrategyVal(newValue);
                      const selectedStrategy = StrategyName?.find((strategy) => strategy.name === newValue);
                      if (selectedStrategy && selectedStrategy.checkType === "noLS") {
                        setStrategyType(["Both"]);
                      } else {
                        isStrategist ? setStrategyType(["Both"]) : setStrategyType(["Both", "Long", "Short"]);
                      }
                    }
                  }}
                  disabled={isStrategist ? false : disableStrategyButton || fundVal === "" || !StrategyName || StrategyName?.length < 1}
                  options={StrategyName?.map((val) => val?.name)?.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: "base" }))}
                  renderInput={(params) => <TextField {...params} variant="standard" value={StrategeyVal} label="Strategy" />}
                />
              </FormControl>
            </Grid>
            <Grid item xs={1.5}>
              <FormControl fullWidth>
                <Autocomplete
                  disableClearable
                  value={StrategeyTypename}
                  ListboxProps={{
                    sx: { fontSize: 12 },
                  }}
                  sx={{
                    "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
                      fontSize: 12,
                    },
                  }}
                  onChange={(e, newValue) => {
                    myTotals(fundVal, StrategeyVal, newValue);
                    setStrategyTypename(newValue);
                  }}
                  disabled={isStrategist ? false : disableStrategyButton || fundVal === "" || !StrategyName || StrategyName?.length < 1}
                  options={StrategyType}
                  renderInput={(params) => <TextField {...params} variant="standard" value={StrategeyTypename} label="Side" />}
                />
              </FormControl>
            </Grid>
          </Grid>

          <AssetPositions data={filteredValues} fundVal={fundVal} StrategeyVal={StrategeyVal} />
        </Box>
      </Box>
    </>
  );
};
export default PortfolioTable;
