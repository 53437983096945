import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { goldmanSachs_columns as columns } from "../Methods";
import { Button, ButtonBase, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ConfirmationPopUp from "../Popup/ConfirmationPopUp";
import SendTradeForCustodian from "../Popup/SendTradeForCustodian";
import SendTradeForallCustodian from "../Popup/SendTradeForallCustodian";
import Loader from "../Loader";
import moment from "moment";
const GoldmanSachs = () => {
  const theme = useTheme();
  const date = moment().format("DD-MM-YYYY");
  const [showLoader, setShowLoader] = useState(true);
  const [openSave, setOpenSave] = useState(false);
  const [filePath, setfilePath] = useState("");
  const [fileName, setfileName] = useState(`${date} Gsco`);
  const { file } = useSelector((state) => state?.todos);
  const [openTradeForCustodian, setOpenTradeForCustodian] = useState(false);
  const [openTradeForallCustodian, setOpenTradeForallCustodian] =
    useState(false);
  const user = useSelector((state) => state?.users?.user?.username);
  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);
  const [custodian, setCustodian] = useState();
  const [error, setError] = useState("");
  const [allcustodians, setAllCustodians] = useState([]);
  useEffect(() => {
    const processFiles = async () => {
      if (file && file.filePaths) {
        let gscoCustodianFound = false;
  
        for (const fileItem of file.filePaths) {
          if (fileItem?.custodian === "gsco") {
            setfileName(fileItem?.fileName);
            setCustodian(fileItem?.custodian);
            setfilePath(fileItem?.filePath);
  
            try {
              let csvString = await Papa.parse(fileItem?.fileContent);
              setData(csvString?.data);
              setError("");
            } catch (error) {
              console.error("Error parsing CSV:", error);
              setError("Error parsing CSV");
            }
  
            gscoCustodianFound = true;
            break; // Break the loop once the gscoCustodian is found
          }
        }
  
        if (gscoCustodianFound) {
          setShowLoader(false);
        } else {
          setAllEmpty();
        }
  
        const transformed = file.filePaths.map(({ custodian, fileName }) => ({
          custodian,
          fileName,
        }));
        setAllCustodians(transformed);
      } else {
        setAllEmpty();
      }
    };
  
    processFiles();
  }, [file]);
  

  useEffect(() => {
    if (data) {
      setRows(alterData());
    }
  }, [data,file]);
  const alterData = () => {
    return data?.map((v, i) => {
      const tempv = {};
      v.forEach((n, j) => {
        tempv[j] = n;
      });
      tempv.id = i;
      return tempv;
    });
  };

  const EditRowsData = (obj) => {
    const updatedRows = rows.map((row) => {
      if (row.id === obj.id) {
        return { ...row, [obj.field]: obj.value };
      }
      return row;
    });

    const newData = updatedRows.map((row) => {
      const newRow = [];
      for (let j = 0; j < 17; j++) {
        newRow.push(row[j]);
      }
      return newRow;
    });
    setData(newData);
    setRows(updatedRows);
  };

  const uppercaseValueFormatter = (params) => {
    return String(params.value).toUpperCase();
  };

  const columnsWithUppercase = columns?.map((column) => ({
    ...column,
    valueFormatter: uppercaseValueFormatter,
  }));
  const setAllEmpty = () => {
    setData([]);
    setCustodian("");
    setfilePath("");
    setError("Sorry,no data...");
    setShowLoader(false);
  };

  return (
    <>
      {showLoader ? (
        <div style={{ height: 300, width: "100%" }}>
          <Loader noMin={true} mTrue={true} />
        </div>
      // ) : error ? (
      //   <div style={{ height: 300, width: "100%" }}>{error}</div>
      ) : (
        <Box>
          <div style={{ height: 300, width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={columnsWithUppercase}
              pageSize={20}
              rowsPerPageOptions={[20]}
              rowHeight={20}
              sx={{
                "& .MuiDataGrid-cell": {
                  color: "#000",
                  fontSize: "12px",
                  border: "0.1px solid #dadada",
                },
                "& .MuiDataGrid-columnHeaders": {
                  background: theme.palette.grey[700],
                  color: theme.palette.grey[100],
                  fontSize: "12px",
                },
              }}
              onCellEditCommit={(props, event) => {
                EditRowsData(props);
              }}
            />
          </div>

          <Stack direction="row" justifyContent="flex-end" sx={{ mt: 1 }}>
          <ButtonBase
              variant="contained"
              sx={{
                background: theme.palette.blue[200],
                width: 140,
              
                mr: 3,
                borderRadius: 1,
                "&:hover": { background: theme.palette.blue[300] },
              }}
            >
              <a
                href={filePath}
                target="_blank"
                style={{
                  fontSize: theme.typography.bodyText14.fontSize,
                  fontWeight: "bold",
                  color: "#fff",
                  textDecoration: "none",
                  width:"100%",
                 
                }}
              >
                Download CSV
              </a>
            </ButtonBase>
            <Button
              variant="contained"
              color="success"
              sx={{ fontSize: "12px", fontWeight: "bold" }}
              startIcon={<CheckCircleIcon />}
              onClick={() => {
                setOpenSave(true);
              }}
            >
              SAVE CHANGES
            </Button>
          </Stack>
        </Box>
      )}
      <ConfirmationPopUp
        title="YOUR CHANGES HAVE BEEN SAVED"
        openSave={openSave}
        setOpenSave={setOpenSave}
        fileData={data}
        fileName={fileName}
        user={user}
      />
      <SendTradeForCustodian
        title="POST TRADE FOR THIS CUSTODIAN"
        openTradeForCustodian={openTradeForCustodian}
        setOpenTradeForCustodian={setOpenTradeForCustodian}
        fileData={data}
        custodian={custodian}
        fileName={fileName}
        user={user}
      />

      <SendTradeForallCustodian
        title="POST TRADE FOR ALL CUSTODIAN"
        openTradeForallCustodian={openTradeForallCustodian}
        setOpenTradeForallCustodian={setOpenTradeForallCustodian}
        fileData={data}
        custodian={allcustodians}
        fileName={fileName}
        user={user}
      />
    </>
  );
};

export default GoldmanSachs;
