import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Grid,
  Box,
  useTheme,
  Tabs,
  Tab,
} from "@mui/material";
import RulesForm from "./RulesForm";
import SpecificBlacklist from "./SpecificBlacklist";
import { hashGetBlacklistType, findStrategyById } from "../../Methods";
import { updateLoading } from "../../../Redux/Reducers/todoReducers";
import { useDispatch } from "react-redux";

const Blacklist = (props) => {
  const theme = useTheme();
  const {
    title,
    openBlacklistPopup,
    setBlacklistOpenPopup,
    Strategies,
    recordForBlacklist,
    strategies,
  } = props;

  const [selectedTab, setSelectedTab] = useState(0);
  const [rules, setRules] = useState([]);
  const [securities, setSecurities] = useState([]);
  const dispatch = useDispatch()

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleSave = () => {
    const data = {
      action: "create_blacklist_rule",
      data: {
        fund: recordForBlacklist,
        tickerRules: [],
        strategyRules: [],
      },
    };

    rules.forEach((ruleSet) => {
      const { selectedStrategies, rules } = ruleSet;

      selectedStrategies.forEach((strategyId) => {
        const strategyRuleSet = {
          strategy: findStrategyById(strategyId, strategies),
          rules: [],
        };

        rules.forEach((rule) => {
          const { criteria, operation, value } = rule;
          const ruleObj = {
            type: hashGetBlacklistType(criteria),
          };

          if (operation === ">") {
            ruleObj.maximum = value;
          } else if (operation === "<") {
            ruleObj.minimum = value;
          } else if (operation === "=") {
            ruleObj.maximum = value;
            ruleObj.minimum = value;
          }

          strategyRuleSet.rules.push(ruleObj);
        });

        data.data.strategyRules.push(strategyRuleSet);
      });
    });

    securities.forEach((security) => {
      const { selectedTickers, rules } = security;

      const tickersArray = Array.isArray(selectedTickers)
        ? selectedTickers
        : selectedTickers.split(",").map((ticker) => ticker.trim());

      tickersArray.forEach((ticker) => {
        const tickerRuleSet = {
          ticker: ticker,
          rules: [],
        };

        rules.forEach((rule) => {
          const { criteria, operation, value } = rule;
          const ruleObj = {
            type: hashGetBlacklistType(criteria),
          };

          if (operation === ">") {
            ruleObj.maximum = value;
          } else if (operation === "<") {
            ruleObj.minimum = value;
          } else if (operation === "=") {
            ruleObj.maximum = value;
            ruleObj.minimum = value;
          }

          tickerRuleSet.rules.push(ruleObj);
        });

        data.data.tickerRules.push(tickerRuleSet);
      });
    });

    window.clientSocks.send(JSON.stringify(data));
    setBlacklistOpenPopup(false);
    dispatch(updateLoading(true));
  };

  return (
    <Dialog
      fullWidth
      open={openBlacklistPopup}
      sx={{
        "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
          minWidth: "1000px",
          height: "100vh",
        },
      }}
    >
      <DialogTitle>
        <Typography
          variant="h6"
          component="div"
          align="center"
          style={{ flexGrow: 1, fontSize: "20px" }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ height: "50vh", overflow: "auto" }} dividers>
        <Box sx={{ width: "100%" }}>
          <Tabs value={selectedTab} onChange={handleTabChange}>
            <Tab label="STRATEGY LEVEL RULES" />
            <Tab label="TICKER LEVEL RULES" />
          </Tabs>

          <Box sx={{ maxWidth: "800px", margin: "20px auto" }}>
            {selectedTab === 0 && (
              <RulesForm
                rules={rules}
                setRules={setRules}
                Strategies={Strategies}
              />
            )}

            {selectedTab === 1 && (
              <Box sx={{ marginTop: "20px" }}>
                <SpecificBlacklist
                  securities={securities}
                  setSecurities={setSecurities}
                />
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>
      <Grid
        item
        xs={12}
        margin="20px 10px 20px 10px"
        display="flex"
        justifyContent="space-between"
        columnGap={1}
      >
        <Button variant="contained" sx={{ width: "40%" }} onClick={handleSave}>
          SAVE
        </Button>
        <Button
          variant="contained"
          sx={{
            width: "40%",
            background: "#B2BEB5",
            color: "#000",
            "&:hover": { background: "#808080" },
          }}
          onClick={() => {
            setBlacklistOpenPopup(false);
          }}
        >
          CANCEL
        </Button>
      </Grid>
    </Dialog>
  );
};

export default Blacklist;
