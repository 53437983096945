import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Grid,
  Box,
  styled,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableBody,
  useTheme,
} from "@mui/material";
import {
  TABLE_HEAD_VANTAGE as TABLE_HEAD,
  applySortFilter,
  calculateAndFormat,
  getComparator,
} from "../Methods";
import { useState, useEffect } from "react";
import LongTable from "../Tables/Portfolio/AssetPositions/LongTable";
import VantageListHead from "../ListHead/VantageListHead";
const Tablecell = styled(TableCell)(({ theme }) => ({
  fontSize: "12px",
  padding: "1px 2px 1px 5px",
}));

const FundStrategyPopUp = (props) => {
  const theme = useTheme();
  const { title, openPerformancePopup, setopenPerformancePopup, monitor } =
    props;
  const [filterName, setFilterName] = useState("");
  const [orderBy, setOrderBy] = useState("name");
  const [order, setOrder] = useState("asc");
  const [data, setData] = useState([]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  useEffect(() => {
    if (monitor) {
      const filteredUsersLong = applySortFilter(
        monitor,
        getComparator(order, orderBy),
        filterName
      );

      setData(filteredUsersLong);
    } else {
      setData([]);
    }
  }, [monitor, order, orderBy, data]);
  const handleClose = () => {
    setData([]);
    setopenPerformancePopup(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      onClose={handleClose}
      open={openPerformancePopup}
      sx={{
        "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
          minWidth: "500px",
          marginLeft: "200px",
        },
      }}
    >
      <DialogTitle>
        <Typography
          variant="h6"
          component="div"
          align="left"
          style={{ flexGrow: 1, fontSize: "20px" }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Box
          sx={{
            border: `1px solid ${theme.palette.lightGrey.tableHeader}`,
            borderRadius: "4px",
          }}
        >
          <Grid item xs={6} md={6}>
            <TableContainer>
              <Table
                size="small"
                stickyHeader
                aria-label="a dense table"
                sx={{
                  height: "max-content",
                  borderCllapse: "collapse",
                  border: "solid 1px #eee",
                }}
              >
                <VantageListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  noStatus={true}
                  onRequestSort={handleRequestSort}
                />

                <TableBody>
                  {data?.map((row,i) => (
                    <TableRow rowheight={0} key={i}>
                    <Tablecell
                      align="left"
                      sx={{
                        cursor: "pointer",
                        fontWeight: "500",
                        paddingLeft: "5px",
                      }}
                    >
                      {row.fundName}
                    </Tablecell>
                    <Tablecell
                      align="left"
                     
                      sx={{
                        cursor: "pointer",
                        paddingLeft: "5px",
                        width: "10",
                      }}
                    >
                      {row.strategyName}
                    </Tablecell>
                    <Tablecell align="right" sx={{ paddingRight: "5px" }}>
                      {calculateAndFormat(row?.aum)}
                    </Tablecell>
                    <Tablecell
                      align="right"
                      style={{
                        paddingRight: "5px",
                        color: row?.pL >= 0 ? "green" : "red",
                      }}
                    >
                      {row?.pL >= 0
                        ? <>{calculateAndFormat(row?.pL)}&nbsp;</>
                        : `(${calculateAndFormat(row?.pL * -1)})`}
                    </Tablecell>
                    <Tablecell
                      align="right"
                      style={{
                        paddingRight: "5px",

                        color: row.plLong >= 0 ? "green" : "red",
                      }}
                    >
                      {row?.plLong >= 0
                        ? <>{calculateAndFormat(row?.plLong)}&nbsp;</>
                        : `(${calculateAndFormat(row?.plLong * -1)})`}
                    </Tablecell>
                    <Tablecell
                      align="right"
                      style={{
                        paddingRight: "5px",

                        color: row?.plShort >= 0 ? "green" : "red",
                      }}
                    >
                      {row?.plShort >= 0
                        ? <>{calculateAndFormat(row?.plShort)}&nbsp;</>
                        : `(${calculateAndFormat(row?.plShort * -1)})`}
                    </Tablecell>
                    <Tablecell align="right" sx={{ paddingRight: "5px" }}>
                      {parseFloat(row?.sizeLong).toFixed(2)}%
                    </Tablecell>
                    <Tablecell align="right" sx={{ paddingRight: "5px" }}>
                      {`(${parseFloat(row?.sizeShort).toFixed(2)}%)`}
                 
                    </Tablecell>
                    <Tablecell align="right" sx={{ paddingRight: "5px" }}>
                    {Math.round(row?.longCapital).toLocaleString() }
                    {}
                    </Tablecell>
                    <Tablecell align="right" sx={{ paddingRight: "5px" }}>
                    {Math.round(row?.shortCapital).toLocaleString() }
                    </Tablecell>
                    <Tablecell align="right" style={{ paddingRight: "5px", color:
                      row?.longPerformance >= 0 ? "green" : "red" 
                  }}>
                      {row?.longPerformance >= 0 ?
                      <>{row?.longPerformance.toFixed(2)}%&nbsp;</> :
                      <>({(row?.longPerformance*-1).toFixed(2)}%)</>
                      }
                    </Tablecell>
                    <Tablecell align="right" style={{ paddingRight: "5px", color:
                      row?.shortPerformance >= 0 ? "green" : "red" 
                  }}>
                   {row?.shortPerformance >= 0 ?
                      <>{row?.shortPerformance.toFixed(2)}%&nbsp;</> :
                      <>({(row?.shortPerformance*-1).toFixed(2)}%)</>
                      }
                    </Tablecell>
                    <Tablecell align="right" style={{ paddingRight: "5px",color:
                            parseFloat(row?.plPer).toFixed(2) >= 0  ? "green" : "red"
                  }}>
                      {
                     parseFloat(row?.plPer).toFixed(2) >= 0 ?
                     <>{(row?.plPer).toFixed(2)}%&nbsp;</> :
                     <>({Math.abs((row?.plPer)).toFixed(2)}%)</>
                      }
                    </Tablecell>
                    <Tablecell
                        align="right"
                        style={{
                          paddingRight: "5px",

                          color: row?.pLWeek >= 0 ? "green" : "red",
                        }}
                      >
                        {row?.pLWeek >= 0
                          ? <>{calculateAndFormat(row?.pLWeek)}&nbsp;</>
                          : `(${calculateAndFormat(row?.pLWeek * -1)})`}
                      </Tablecell>
                      <Tablecell
                        align="right"
                        style={{
                          paddingRight: "5px",

                          color: row?.pLMonth >= 0 ? "green" : "red",
                        }}
                      >
                        {row?.pLMonth >= 0
                          ? <>{calculateAndFormat(row?.pLMonth)}&nbsp;</>
                          : `(${calculateAndFormat(row?.pLMonth * -1)})`}
                      </Tablecell>
                      <Tablecell
                        align="right"
                        style={{
                          paddingRight: "5px",

                          color: row?.pLQuarter >= 0 ? "green" : "red",
                        }}
                      >
                        {row?.pLQuarter >= 0
                          ? <>{calculateAndFormat(row?.pLQuarter)}&nbsp;</>
                          : `(${calculateAndFormat(row?.pLQuarter * -1)})`}
                      </Tablecell>
                      <Tablecell
                        align="right"
                        style={{
                          paddingRight: "5px",

                          color: row?.pLYear >= 0 ? "green" : "red",
                        }}
                      >
                        {row?.pLYear >= 0
                          ? <>{calculateAndFormat(row?.pLYear)}&nbsp;</>
                          : `(${calculateAndFormat(row?.pLYear * -1)})`}
                      </Tablecell>
                  </TableRow>
                  ))}
                </TableBody>
              </Table>{" "}
            </TableContainer>
          </Grid>
        </Box>

        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "5px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              width: "10%",
              background: "#B2BEB5",
              color: "#000",
              "&:hover": { background: "#808080" },
            }}
            onClick={() => {
              setopenPerformancePopup(false);
            }}
          >
            CLOSE
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default FundStrategyPopUp;
