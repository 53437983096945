import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  Button,
} from "@mui/material";

const ClearConfirmationPopup = ({ open, onClose, onConfirm, title }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <DialogTitle>
        <Typography
          variant="h6"
          component="div"
          align="center"
          style={{ flexGrow: 1, fontSize: "20px" }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent dividers sx={{ px: 8 }}>
        <Typography
          variant="body"
          component="div"
          style={{ flexGrow: 1, textAlign: "center" }}
        >
          Are you sure you want to clear the data? This action cannot be undone.
        </Typography>
        <Grid
          item
          xs={12}
          margin="20px 1px 20px 1px "
          display="flex"
          justifyContent="space-between"
        >
          <Button
            variant="contained"
            sx={{
              fontSize: "12px",
              fontWeight: "bold",
              height: "40px",
              width: "150px",
              background: "#B2BEB5",
              color: "#000",
              "&:hover": { background: "#808080" },
            }}
            onClick={onClose}
          >
            CANCEL
          </Button>
          <Button
            variant="contained"
            color="error"
            sx={{
              fontSize: "12px",
              fontWeight: "bold",
              height: "40px",
              width: "150px",
            }}
            onClick={onConfirm}
          >
            CLEAR DATA
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ClearConfirmationPopup;
