import { useEffect, useState } from "react";
import {
  Card,
  Table,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  useTheme,
  TableHead,
  IconButton,
  Box,
} from "@mui/material";
import Popup from "../../Popup";
import ListToolbar from "./ListToolbar";
import ListHead from "../../ListHead";
import EditBrokerDetails from "../../../Pages/EditBrokerDetails";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import {
  getComparator,
  TABLE_HEAD_BROKER as TABLE_HEAD,
  Tablecell,
  applySortFilter,
} from "../../Methods";
import DeletePopUp from "../../Popup/DeletePopUp";

const BrokerTable = () => {
  const theme = useTheme();
  const [data, setData] = useState([]);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [recordForEdit, setRecordForEdit] = useState(null);

  const [recordForDelete, setrecordForDelete] = useState(null);

  const [openPopup, setOpenPopup] = useState(false);

  const [openDeletePopup, setDeleteOpenPopup] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(100);

  const brokers = useSelector((state) => state?.todos?.data);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  useEffect(() => {
    const calculatedData = brokers?.map((row) => {
      const { routers } = row;
      const routerNames = routers
        ? Object.values(routers).map((router) =>
            router.name ? router.name : "-"
          )
        : "";
      return {
        ...row,
        routerNames,
      };
    });
    const filteredUsers = applySortFilter(
      calculatedData,
      getComparator(order, orderBy),
      filterName
    );
    setData(filteredUsers);
  }, [order, orderBy, filterName, brokers]);

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const openInDeletePopup = (item) => {
    setrecordForDelete(item);
    setDeleteOpenPopup(true);
  };

  const isNotFound = !data.length && !!filterName;

  return (
    <>
      <Container maxWidth="70%" sx={{ marginTop: 1, marginBottom: 3 }}>
        <Card>
          <ListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            setOrderBy={setOrderBy}
            setOrder={setOrder}
            setData={setData}
            data={data}
          />

          <TableContainer style={{ height: "62vh" }}>
            <br />
            {/* <Table
              size="small"
              sx={{
                height: "max-content",
              }}
            >
              <TableHead style={{ height: "-50px" }}>
                <TableRow
                  style={{
                    borderBottom: "1px solid",
                    border: "2px solid black",
                    borderRadius: "100px",
                    marginTop: "10px",
                  }}
                >
                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: "rgb(36, 46, 60)",
                      color: "white",
                      width: "14%",
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;
                  </TableCell>

                  <TableCell
                    align="center"
                    style={{
                      borderRight: "1px solid white",
                      borderLeft: "1px solid white",
                      backgroundColor: "rgb(36, 46, 60)",
                      color: "white",
                      width: "17.3%",
                    }}
                  >
                    Abbreviation
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: "rgb(36, 46, 60)",
                      color: "white",
                      width: "40.1%",
                      borderRight: "1px solid white",
                    }}
                    colSpan={4}
                  >
                    OMS
                  </TableCell>

                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: "rgb(36, 46, 60)",
                      color: "white",
                      width: "284px",
                    }}
                  ></TableCell>
                </TableRow>
              </TableHead>
            </Table> */}
            <Table
              size="small"
              stickyHeader
              aria-label="a dense table"
              sx={{
                height: "max-content",
              }}
            >
              <ListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={data.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
              />

              <TableBody>
                {data &&
                  data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const {
                        brokerId,
                        name,
                        date,
                        abbreviation,
                        routerNames,
                      } = row;
                      const selectedUser = selected.indexOf(brokerId) !== -1;

                      return (
                        <TableRow
                          key={index}
                          tabIndex={-1}
                          role="checkbox"
                          selected={selectedUser}
                          sx={{
                            "&:hover": {
                              background: theme.palette.grey[200],
                              cursor: "pointer",
                              ".deleteIcon": {
                                visibility: "visible",
                              },
                            },
                          }}
                        >
                          <Tablecell align="left" sx={{ paddingLeft: "5px" }}>
                            {index + 1 + page * rowsPerPage}
                          </Tablecell>

                          <Tablecell align="left" sx={{ paddingLeft: "5px" }}>
                            {name ? name : "-"}
                          </Tablecell>
                          <Tablecell align="left" sx={{ paddingLeft: "5px" }}>
                            {abbreviation ? abbreviation : "-"}
                          </Tablecell>
                          <Tablecell align="left" sx={{ paddingLeft: "5px" }}>
                            {routerNames[0]}
                          </Tablecell>
                          {/* <Tablecell align="left" sx={{ paddingLeft: "5px" }}>
                            None
                          </Tablecell>
                          <Tablecell align="left" sx={{ paddingLeft: "5px" }}>
                            {routerNames[0]}
                          </Tablecell>

                          <Tablecell align="left" sx={{ paddingLeft: "5px" }}>
                            None
                          </Tablecell>
                          <Tablecell align="left" sx={{ paddingLeft: "5px" }}>
                            None
                          </Tablecell>
                          <Tablecell align="left" sx={{ paddingLeft: "5px" }}>
                            None
                          </Tablecell> */}
                          <Tablecell
                            align="left"
                            sx={{ paddingLeft: "5px", width: "150px" }}
                          >
                            {date ? moment(date).format("MMM DD, YYYY") : "-"}
                          </Tablecell>

                          <Tablecell
                            align="center"
                            sx={{
                              paddingLeft: "5px",
                              width: "100px",
                            }}
                          >
                            <Box
                              component="span"
                              sx={{
                                fontSize: "9px",
                                fontWeight: "500",
                                visibility: "hidden",
                              }}
                              className="deleteIcon"
                            >
                              <IconButton
                                sx={{ height: "20px", Minwidth: "10px" }}
                                onClick={() => {
                                  openInPopup(row);
                                }}
                              >
                                <EditIcon
                                  sx={{
                                    color: "#808080",
                                    width: "20px",
                                    height: "15px",
                                  }}
                                />
                              </IconButton>
                              <IconButton
                                sx={{ height: "20px", Minwidth: "10px" }}
                                onClick={() => {
                                  openInDeletePopup(row);
                                }}
                              >
                                <DeleteIcon
                                  sx={{
                                    color: "#808080",
                                    width: "20px",
                                    height: "15px",
                                  }}
                                />
                              </IconButton>
                            </Box>
                          </Tablecell>
                        </TableRow>
                      );
                    })}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[100, 500, 1000]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <DeletePopUp
        title="Delete Column"
        openDeletePopup={openDeletePopup}
        setDeleteOpenPopup={setDeleteOpenPopup}
        recordForDelete={recordForDelete}
        deleteObj="delete_broker"
        item="broker"
      />

      <Popup
        title="Broker Form"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <EditBrokerDetails
          recordForEdit={recordForEdit}
          data={data}
          setData={setData}
          setOpenPopup={setOpenPopup}
        />
      </Popup>
    </>
  );
};
export default BrokerTable;
