import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import { useSelector } from "react-redux";
import { Spinner } from "../spinner";
import CloseIcon from "@mui/icons-material/Close";
import { getLoading } from "../../Redux/Reducers/todoReducers";

export default function Popup(props) {
  const { id, children, openPopup, setOpenPopup, title, showIcon } = props;
  const loading = useSelector(getLoading);
  return (
    <>
      <Dialog
        open={openPopup}
        sx={{ height: "100%" }}
        maxWidth={
          title === "ADD SIGNAL"
            ? "md"
            : title === "Add Account Details"
            ? "xs"
            : "sm"
        }
        onClose={() => setOpenPopup(false)}
      >
        {loading && (
          <Box marginLeft={-50}>
            <Spinner />
          </Box>
        )}
        {title === "Trade Entry Form" ? (
          <DialogTitle
            sx={{
              height: "20px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h6"
              component="div"
              style={{ marginTop: "-11px", fontSize: "18px" }}
            >
              {title}
            </Typography>
            <IconButton
              sx={{ marginTop: "-13px", width: "25px", height: "25px" }}
              onClick={() => {
                setOpenPopup(false);
              }}
            >
              <CloseIcon sx={{ height: "20px", width: "20px" }} />
            </IconButton>
          </DialogTitle>
        ) : !showIcon && title ? (
          <DialogTitle>
            <div style={{ display: "flex" }}>
              <Typography
                variant="h6"
                component="div"
                style={{ flexGrow: 1, fontSize: "20px" }}
              >
                {title}
              </Typography>
              <IconButton
                onClick={() => {
                  setOpenPopup(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
        ) : (
          <></>
        )}
        <DialogContent dividers>{children}</DialogContent>
      </Dialog>
    </>
  );
}
