import React, { useEffect, useState } from "react";
import { Table, TableRow, TableBody, useTheme, Box } from "@mui/material";
import { Tablecell } from "../../../Methods";
const ModifiedSignal = ({ pastedArray }) => {
  const [CombinedArray, setCombinedArray] = useState([]);
  useEffect(() => {
    if (pastedArray && pastedArray.length > 0) {
      const count = pastedArray.length;

      const sumOfMatchedValues = pastedArray.reduce(
        (sum, item) => sum + parseFloat(item.value),
        0
      );

      const positiveValuesSum = pastedArray.reduce(
        (sum, item) =>
          parseFloat(item.value) > 0 ? sum + parseFloat(item.value) : sum,
        0
      );

      const negativeValuesSum = pastedArray.reduce(
        (sum, item) =>
          parseFloat(item.value) < 0 ? sum + parseFloat(item.value) : sum,
        0
      );

      function roundit(number) {
        return number.toFixed(2).replace(/\.?0+$/, "");
      }
      const transformedArray = [
        { name: "Number of Signals in list", value: count },
        { name: "Long", value: roundit(positiveValuesSum) },
        { name: "Short", value: roundit(negativeValuesSum) },
        {
          name: "Gross Sum of Weights",
          value: roundit(positiveValuesSum - negativeValuesSum),
        },
        { name: "Net Sum of Weights", value: roundit(sumOfMatchedValues) },
      ];

      setCombinedArray(transformedArray);
    } else {
      setCombinedArray([]);
    }
  }, [pastedArray]);

  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          border: `100px ${theme.palette.lightGrey.tableHeader}`,
          borderRadius: "4px",
          marginTop: "10px",
          width: "100%",
          marginRight: 1,
        }}
      >
        <Table
          stickyHeader
          aria-label="a dense table"
          sx={{
            height: "max-content",
            borderCllapse: "collapse",
            border: "solid 1px #eee",
          }}
        >
          <TableRow
            sx={{
              "&:hover": {
                background: theme.palette.grey[200],
                cursor: "pointer",
              },
            }}
          >
            <Tablecell
              align="left"
              sx={{
                fontSize: theme.typography.bodyText12.fontSize,
                fontWeight: 900,
                backgroundColor: "#BDDDC099",
                paddingLeft: "20px",
                textAlign: "left",
                py: "5px",
              }}
            >
              Modified Signal Summary
            </Tablecell>
          </TableRow>
        </Table>
        <Table
          size="small"
          stickyHeader
          aria-label="a dense table"
          sx={{
            borderCllapse: "collapse",
            border: "solid 1px #eee",
            marginTop: "-1px",
          }}
        >
          <TableBody>
            {CombinedArray?.map((row, index) => (
              <TableRow
                sx={{
                  "&:hover": {
                    background: theme.palette.grey[200],
                    cursor: "pointer",
                  },
                }}
              >
                <Tablecell
                  align="left"
                  sx={{
                    fontWeight: "900",
                    fontSize: "12px",
                    paddingLeft: "5px",
                    borderRight: "none",
                    borderLeft: "none",
                    borderTop: "none",
                    borderBottom:
                      row.name == "Short"
                        ? `1px solid ${theme.palette.grey[90]}`
                        : "none",
                  }}
                >
                  {row?.name}
                </Tablecell>

                <Tablecell
                  align="right"
                  sx={{
                    fontWeight: "500",
                    fontSize: "12px",
                    paddingLeft: "5px",
                    borderRight: "none",
                    borderLeft: "none",
                    borderTop: "none",
                    borderBottom:
                      row.name == "Short"
                        ? `1px solid ${theme.palette.grey[90]}`
                        : "none",
                  }}
                >
                  {index === 0 ? row.value : `${row.value}%`}
                </Tablecell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};
export default ModifiedSignal;
