import React from "react";
import TradeFundsStrategies from "../component/Layouts/TradeFundsStrategies";
import Topbar from "../component/NavComponents/Topbar";
import { Helmet } from "react-helmet";
const Strategies = () => {
  return (
    <>
      <Helmet>
        <title>Strategies</title>
      </Helmet>
      <Topbar title="Strategies" />
      <TradeFundsStrategies />
    </>
  );
};
export default Strategies;
