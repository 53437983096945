import { useEffect, useState } from "react";
import {
  Card,
  Table,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  useTheme,
  TableHead,
  IconButton,
  TextField,
  Grid,
  InputLabel,
} from "@mui/material";
import Popup from "../../Popup";
import ListToolbar from "./ListToolbar";
import ListHead from "../../ListHead";
import EditBrokerDetails from "../../../Pages/EditBrokerDetails";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import {
  getComparator,
  TABLE_HEAD_WATCHLIST as TABLE_HEAD,
  Tablecell,
  applySortFilter,
} from "../../Methods";
import DeletePopUp from "../../Popup/DeletePopUp";

const WatchListTable = () => {
  const theme = useTheme();
  const [data, setData] = useState([]);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [recordForEdit, setRecordForEdit] = useState(null);

  const [recordForDelete, setrecordForDelete] = useState(null);

  const [openPopup, setOpenPopup] = useState(false);

  const [openDeletePopup, setDeleteOpenPopup] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(100);

  const brokers = useSelector((state) => state?.todos?.data);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  useEffect(() => {
    const filteredUsers = applySortFilter(
      brokers,
      getComparator(order, orderBy),
      filterName
    );
    setData(filteredUsers);
  }, [order, orderBy, filterName, brokers]);

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const openInDeletePopup = (item) => {
    setrecordForDelete(item);
    setDeleteOpenPopup(true);
  };

  const isNotFound = !data.length && !!filterName;

  return (
    <>
      <Container maxWidth="70%" sx={{ marginTop: 1, marginBottom: 3 }}>
        <Card>
          <ListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            setOrderBy={setOrderBy}
            setOrder={setOrder}
            setData={setData}
            data={data}
          />
          <Grid container sx={{ m: 2 }}>
            <Grid item xs={2} mt={1}>
              <InputLabel
                sx={{
                  fontSize: theme.typography.bodyText17,
                  color: theme.palette.grey[900],
                }}
              >
                Ticker
              </InputLabel>
            </Grid>

            <Grid item xs={5}>
              <TextField
                fullWidth
                id="ticker"
                name="ticker"
                inputProps={{
                  style: { fontSize: 12 },
                }}
                variant="standard"
              />
            </Grid>
          </Grid>
          <TableContainer style={{ height: "62vh" }}>
            <Table
              size="small"
              stickyHeader
              aria-label="a dense table"
              sx={{
                height: "max-content",
              }}
            >
              <ListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={data.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                noStatus={true}
              />

              <TableBody>
                {data &&
                  data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const {
                        brokerId,
                        name,
                        creationDate,
                        abbreviation,
                        routers,
                      } = row;
                      const selectedUser = selected.indexOf(brokerId) !== -1;

                      return (
                        <TableRow
                          key={index}
                          tabIndex={-1}
                          role="checkbox"
                          selected={selectedUser}
                          sx={{
                            "&:hover": {
                              background: theme.palette.grey[200],
                              cursor: "pointer",
                            },
                          }}
                        ></TableRow>
                      );
                    })}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[100, 500, 1000]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <DeletePopUp
        title="Delete Column"
        openDeletePopup={openDeletePopup}
        setDeleteOpenPopup={setDeleteOpenPopup}
        recordForDelete={recordForDelete}
        deleteObj="delete_broker"
        item="broker"
      />

      <Popup
        title="Broker Form"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <EditBrokerDetails
          recordForEdit={recordForEdit}
          data={data}
          setData={setData}
          setOpenPopup={setOpenPopup}
        />
      </Popup>
    </>
  );
};
export default WatchListTable;
