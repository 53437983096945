import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { FundName } from "../../component/Schemas";
import {
  InputLabel,
  useTheme,
  FormHelperText,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";

const EditFund = ({ recordForEdit, data, setOpenPopup, setData }) => {
  const dispatch = useDispatch();
  const { funds, custodians } = useSelector((state) => state?.todos);
  const [account, setAccount] = useState({});
  const [accountValueError, setAccountValueError] = useState();
  const [autocompletedValue, setautoCompletedValue] = useState([]);

  useEffect(() => {
    if (
      recordForEdit?.accounts &&
      Object.keys(recordForEdit?.accounts).length
    ) {
      const accountKeys = Object.keys(recordForEdit.accounts);
      setautoCompletedValue(accountKeys);
      const initialAccountValues = accountKeys.reduce((acc, key) => {
        acc[key] = recordForEdit.accounts[key].number;
        return acc;
      }, {});
      setAccount(initialAccountValues);
    }
  }, [recordForEdit]);
  const initialValues = {
    name: recordForEdit.name,
    abbreviation: recordForEdit.abbreviation,
  };

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: FundName,

    onSubmit: (values) => {
      values.abbreviation = values.abbreviation.toLowerCase();
      if (
        funds?.find((a) => {
          if (a.fundId !== values.fundId)
            return a.abbreviation === values.abbreviation;
        })
      ) {
        alert(`${values.abbreviation} already exists, try new one.`);
      } else {
        window.clientSocks.send(
          `{"action":"modify_fund", "data":{"fund":
      ${JSON.stringify({
        ...values,
        fundId: recordForEdit?.fundId,
        creationDate: recordForEdit?.creationDate,
      })}}}`
        );
        setOpenPopup(false);
      }
    },
  });
  const checkTestKeys = () => {
    return custodians?.map((i) => i?.abbreviation);
  };

  const theme = useTheme();
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} margin="5px">
          <TextField
            fullWidth
            disabled
            id="name"
            name="name"
            label="Full Name"
            value={values.name}
            onChange={handleChange}
            variant="standard"
          />
          {errors.name && touched.name ? (
            <p className="error">*{errors.name}</p>
          ) : null}
        </Grid>

        <Grid item xs={12} margin="5px">
          <TextField
            fullWidth
            id="abbreviation"
            name="abbreviation"
            label="Abbreviation"
            value={values.abbreviation}
            onChange={handleChange}
            variant="standard"
          />
          {errors.abbreviation && touched.abbreviation ? (
            <p className="error">*{errors.abbreviation}</p>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <InputLabel variant="standard" sx={{ mt: "-10px" }}>
            Allocate Custodians
          </InputLabel>
          <Grid container spacing={1}>
            <Grid item xs={12} sx={{ border: "1px solid #E0E0E0", mt: 1 }}>
              <Autocomplete
                multiple
                id="tags-standard"
                ListboxProps={{
                  sx: { fontSize: 12 },
                }}
                sx={{
                  "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
                    fontSize: 12,
                  },
                }}
                onClose={(e, val) => {}}
                onChange={(e, option, reason) => {
                  setautoCompletedValue(option);
                  if (reason === "removeOption") {
                    delete account[e.currentTarget.parentElement.innerText];
                  }
                  if (reason === "clear") {
                    setAccount({});
                  }
                }}
                value={autocompletedValue}
                options={checkTestKeys() || []}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    sx={{ mb: 1 }}
                    error={!autocompletedValue.length}
                  />
                )}
              />
              {!autocompletedValue.length ? (
                <p className="error">* Account required</p>
              ) : null}
            </Grid>
            {/* <Grid item xs={6} sx={{ marginTop: "-5px" }}>
              {autocompletedValue &&
                autocompletedValue !== undefined &&
                autocompletedValue.length > 0 &&
                autocompletedValue.map((val) => {
                  return (
                    <React.Fragment key={val}>
                      <br />
                      <Typography
                        sx={{
                          fontSize: "10px",
                          color: theme.palette.grey[300],
                        }}
                      >
                        <span>{`${val}'s sharecount`}</span>
                      </Typography>
                      <TextField
                        variant="standard"
                        type="number"
                        value={account[val] || ""}
                        onChange={(e) => {
                          const newValue =
                            e.target.value !== ""
                              ? parseFloat(e.target.value)
                              : undefined;
                          setAccount({
                            ...account,
                            [val]: newValue,
                          });
                          handleChange(e);
                        }}
                        name="account"
                        id="account"
                        placeholder={`${val}'s sharecount`}
                      />
                    </React.Fragment>
                  );
                })}
              {accountValueError && (
                <FormHelperText error>{accountValueError}</FormHelperText>
              )}
            </Grid> */}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          margin="10px"
          display="flex"
          justifyContent="space-between"
        >
          <Button variant="contained" sx={{ width: "30%" }} type="submit">
            SAVE
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "30%",
              background: "#B2BEB5",
              color: "#000",
              "&:hover": { background: "#808080" },
            }}
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            CANCEL
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
export default EditFund;
