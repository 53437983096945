import Header from "../Header";
import PerformanceDetails from "../PerformanceDetails";
const TradePerformance = () => {
  return (
    <>
     <Header  marginLeftFalse={true}>
        <PerformanceDetails />
     </Header>
    </>
  );
};
export default TradePerformance;