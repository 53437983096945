import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import {
  Button,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  IconButton,
  TableCell,
  useTheme,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import VantageListHead from "../ListHead/SignalList";
import { TABLE_HEAD_FUNDS_ACCOUNTS as TABLE_HEAD } from "../Methods";
import CloseIcon from "@mui/icons-material/Close";

const AccountDetails = ({
  title,
  openPopupAccount,
  setOpenPopupAccount,
  recordForEdit,
  custodians,
  setCustodians,
}) => {
  const theme = useTheme();
  const [data, setData] = useState(recordForEdit ? recordForEdit.accounts : []);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("name");

  useEffect(() => {
    if (recordForEdit) {
      setData(recordForEdit.accounts);
    }
  }, [recordForEdit]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleEditRow = (index, field, value) => {
    const updatedData = [...data];
    updatedData[index] = { ...updatedData[index], [field]: value };
    setData(updatedData);
  };

  const handleDeleteRow = (index) => {
    const updatedData = data.filter((_, i) => i !== index);
    setData(updatedData);
  };

  const handleAddRow = () => {
    const newRow = {
      accountName: "",
      accountNumber: "",
    };
    const updatedData = [newRow, ...data];
    setData(updatedData);
  };

  const handleSubmit = () => {
    const updatedCustodians = custodians.map((custodian) =>
      custodian.name === recordForEdit.name
        ? { ...custodian, accounts: data, value: data.length }
        : custodian
    );
    setCustodians(updatedCustodians);
    setOpenPopupAccount(false);
  };

  const Reset = () => {
    setData([]);
  };

  return (
    <Dialog
      open={openPopupAccount}
      onClose={() => setOpenPopupAccount(false)}
      sx={{ height: "100%" }}
      maxWidth="xs"
    >
      <DialogTitle>
        <div style={{ display: "flex" }}>
          <Typography
            variant="h6"
            component="div"
            style={{ flexGrow: 1, fontSize: "20px" }}
          >
            {title}
          </Typography>
          <IconButton
            onClick={() => {
              setOpenPopupAccount(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} marginBottom="5px">
              <Box
                sx={{
                  border: `100px ${theme.palette.lightGrey.tableHeader}`,
                  borderRadius: "4px",
                  marginTop: "1px",
                  overflow: "hidden",
                  width: "100%",
                  marginRight: 1,
                  height: "100%",
                  mt: 1,
                }}
              >
                <Table
                  size="small"
                  stickyHeader
                  aria-label="a dense table"
                  sx={{
                    height: "max-content",
                    borderCollapse: "collapse",
                    border: "solid 1px #eee",
                  }}
                >
                  <TableRow
                    sx={{
                      "&:hover": {
                        background: theme.palette.grey[200],
                        cursor: "pointer",
                      },
                    }}
                  >
                    <TableCell
                      align="left"
                      sx={{
                        fontSize: theme.typography.bodyText12.fontSize,
                        fontWeight: 900,
                        backgroundColor: "#BDDDC0",
                        paddingLeft: "20px",
                        textAlign: "left",
                        py: "5px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Modify Account List
                      <Button
                        onClick={() => Reset()}
                        sx={{
                          fontSize: theme.typography.bodyText12.fontSize,
                          color: "#000",
                          height: "15px",
                          fontWeight: 900,
                          py: "5px",
                          marginLeft: "auto",
                          "& .MuiButton-label": {
                            fontSize: theme.typography.bodyText12.fontSize,
                            fontWeight: theme.typography.bodyText18.fontWeight,
                          },
                        }}
                      >
                        Clear
                      </Button>
                    </TableCell>
                    <TableCell
                      align="left"
                      onClick={handleAddRow}
                      sx={{
                        fontSize: theme.typography.bodyText12.fontSize,
                        fontWeight: 900,
                        backgroundColor: "#6B8D6E",
                        paddingRight: "5px",
                        textAlign: "right",
                        cursor: "pointer",
                        color: "white",
                        py: "5px",
                      }}
                    >
                      Add Account
                      <IconButton
                        onClick={handleAddRow}
                        aria-label="add"
                        sx={{
                          height: "15px",
                          width: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <AddIcon
                          sx={{ height: "15px", width: "15px", color: "white" }}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </Table>
                <TableContainer sx={{ height: "45vh" }}>
                  <Table
                    size="small"
                    aria-label="a dense table"
                    stickyHeader
                    sx={{
                      height: "max-content",
                      border: "solid 1px #eee",
                      overflowY: "scroll",
                    }}
                  >
                    <VantageListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      onRequestSort={handleRequestSort}
                      noStatus={true}
                      customizeFont={12}
                    />

                    <TableBody>
                      {data?.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            overflow: "auto",
                            background: row?.invalid
                              ? theme.palette.red.shortPositions
                              : row?.notVerified
                              ? "yellow"
                              : "",
                            "&:hover": {
                              background: row?.invalid
                                ? theme.palette.red.shortPositions
                                : theme.palette.grey[200],
                              cursor: "pointer",
                            },
                            "& > .MuiTableCell-root": {
                              paddingTop: "1px",
                              paddingBottom: "0px !important",
                              lineHeight: "1.2",
                              "& .MuiInputBase-input": {
                                padding: "0px 0px !important",
                              },
                              "& .MuiIconButton-root": {
                                padding: "0px",
                              },
                            },
                          }}
                        >
                          <TableCell align="right" sx={{ borderRight: "none" }}>
                            <TextField
                              value={row.accountName}
                              onChange={(e) => {
                                handleEditRow(
                                  index,
                                  "accountName",
                                  e.target.value
                                );
                              }}
                              size="small"
                              variant="standard"
                              InputProps={{
                                disableUnderline: true,
                                style: {
                                  fontSize: "12px",
                                  fontWeight: "500",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                },
                              }}
                            />
                          </TableCell>
                          <TableCell align="left" sx={{ borderRight: "none" }}>
                            <TextField
                              value={row.accountNumber}
                              onChange={(e) => {
                                handleEditRow(
                                  index,
                                  "accountNumber",
                                  e.target.value
                                );
                              }}
                              size="small"
                              variant="standard"
                              InputProps={{
                                disableUnderline: true,
                                style: {
                                  fontSize: "12px",
                                  fontWeight: "500",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                },
                              }}
                            />
                          </TableCell>

                          <TableCell
                            align="left"
                            className="lastTableCell"
                            sx={{
                              borderRight: "none",
                              display: "flex",
                              justifyContent: "space-between",
                              "&:hover": {
                                ".deleteIcon": {
                                  visibility: "visible",
                                },
                              },
                            }}
                          >
                            <Box
                              component="span"
                              sx={{
                                fontSize: "9px",
                                fontWeight: "500",
                                visibility: "hidden",
                              }}
                              className="deleteIcon"
                            >
                              <IconButton
                                onClick={() => handleDeleteRow(index)}
                                aria-label="delete"
                                sx={{
                                  height: "15px",
                                  width: "15px",
                                }}
                              >
                                <DeleteOutlineIcon
                                  sx={{ height: "15px", width: "15px" }}
                                />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              margin="10px"
              display="flex"
              justifyContent="space-between"
            >
              <Button
                variant="contained"
                sx={{ width: "30%" }}
                onClick={handleSubmit}
              >
                SAVE
              </Button>
              <Button
                variant="contained"
                sx={{
                  width: "30%",
                  background: "#B2BEB5",
                  color: "#000",
                  "&:hover": { background: "#808080" },
                }}
                onClick={() => {
                  setOpenPopupAccount(false);
                }}
              >
                CANCEL
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AccountDetails;
