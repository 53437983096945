import { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  TextField,
  Grid,
  IconButton,
  FormControl,
  Autocomplete,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useState } from "react";

const BrokerStrategy = (props) => {
  const theme = useTheme();

  const { title, openPopup, setOpenPopup, recordForEdit } = props;
  const { data } = useSelector((state) => state?.todos);
  const [brokername, setbrokername] = useState("");
  const [brokerObj, setbrokerObj] = useState();

  const user = useSelector((state) => state?.users?.user?.username);

  const handleClose = () => {
    setOpenPopup(false);
  };
  const brokerlist = data?.map((val) => val?.name);
  const filterOptions = (options, state) => {
    if (state.inputValue.length > 0) {
      return options.filter((item) =>
        String(item).toLowerCase().startsWith(state.inputValue.toLowerCase())
      );
    }
    return options;
  };
  const updateOrders = () => {
    setOpenPopup(false);
  };

  return (
    <Dialog
      open={openPopup}
      onClose={handleClose}
      sx={{ width: "100%", height: "100%" }}
    >
      <DialogContent dividers>
        <div>
          <Typography
            variant="h6"
            component="div"
            align="center"
            style={{
              flexGrow: 1,
              fontSize: "20px",
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            {title}
          </Typography>

          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <FormControl sx={{ width: "50%" }}>
                  <Autocomplete
                    disableClearable
                    value={brokername}
                    autoSelect
                    autoHighlight
                    autoComplete
                    ListboxProps={{
                      sx: { fontSize: 12 },
                    }}
                    sx={{
                      marginTop: "30px",
                      "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
                        fontSize: 12,
                      },
                    }}
                    onChange={(e, newValue) => {
                      setbrokername(newValue);

                      const brokerObject = data?.find(
                        (e) => e.name === newValue
                      );

                      if (brokerObject) {
                        setbrokerObj(brokerObject.brokerId);
                      } else {
                        setbrokerObj();
                      }
                    }}
                    onBlur={(e) => {
                      const value = e.target.value;

                      if (!value) {
                        setbrokername("");
                        setbrokerObj();
                      }
                    }}
                    options={brokerlist}
                    filterOptions={(brokerlist, state) =>
                      filterOptions(brokerlist, state)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Select Broker"
                        value={brokername}
                      />
                    )}
                  />
                </FormControl>

                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    updateOrders();
                  }}
                  style={{
                    marginTop: "25px",
                    width: "180px",
                    backgroundColor: "your-button-color",
                    "&:hover": {
                      backgroundColor: "your-hover-color",
                    },
                    "&:active": {
                      backgroundColor: "your-active-color",
                    },
                  }}
                >
                  Update Broker
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default BrokerStrategy;
