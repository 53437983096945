import React, { useState, useEffect, useRef } from "react";
import CustomizeBox from "../CustomizeBox";
import Header from "../Header";
import PortfolioTable from "../Tables/Portfolio/PortfolioTable";
import {
  Box,
  styled,
  Table,
  TableBody,
  TableRow,
  TableCell,
  useTheme,
  Typography,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { formatValueDecimal, StyledTab } from "../Methods";
import PositionsSummaryPopUp from "../Popup/PositionsSummaryPopUp";
import BenchMarkData from "../Popup/BenchMarkData";
import { useDispatch, useSelector } from "react-redux";
import { requestMarketData } from "../../Redux/Actions/todoActions";
import BenchMarkTable from "../VantageSystem/BenchMarkTable";
import PositionPeformance from "../Popup/PositionPerformance";
import Loader from "../Loader";
const Tablecell = styled(TableCell)(({ theme }) => ({
  fontSize: "12px",
  padding: "1px 2px 1px 5px",
}));

const PortfolioLayout = ({ fundName, strategyName }) => {
  const dispatch = useDispatch();
  const { lots, marketData, RawMarketData, trades, isStrategist, strategies, funds } =
    useSelector((state) => state.todos);

  if (isStrategist) {
    if (strategies?.length > 0) {
      strategyName = strategies[0].name;
    }
  }

  const [totals, setTotals] = useState([]);
  const t = localStorage.getItem("threshold") || 60;
  const [threshold, setthreshold] = useState(t);
  const [row, setRow] = useState();
  const [isViewBenchmarkData, setisViewBenchmarkData] = useState(false);
  const [isGridViewSummary, setIsGridViewSummary] = useState(false);
  const [isPositionPerformance, setIsPositionPerformance] = useState(false);
  const [value, setValue] = React.useState(0);
  const [isMarketData, setIsMarketData] = useState(false);
  const [dataFetching, setDataFetching] = useState(false);

  const toggleGridViewSummary = () => {
    setIsGridViewSummary(!isGridViewSummary);
  };

  const longShortLabels = ["", "%AUM", "P(L)", "%P(L)", "Signals PnL"];

  //when FETCH MARKET DATA button is pressed
  const handleMarketData = () => {
    localStorage.setItem("threshold", threshold);
    setIsMarketData(true)
    setDataFetching(false);
    dispatch(
      requestMarketData({ lots: lots, threshold: threshold, trades: trades })
    );
    setTimeout(() => {
      if (!dataFetching) {
        setIsMarketData(false)
      }
    }, 20000);
  };
//
  const timeoutRef = useRef(null);
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {

    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }


    // Clear the previous timeout if it exists
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    setDataFetching(true);
    // Set a new timeout to trigger the function after 20 seconds
    timeoutRef.current = setTimeout(() => {
      setIsMarketData(false)
      dispatch(
        requestMarketData({
          lots: lots,
          threshold: threshold,
          noRequest: true,
          trades: trades,
        })
      );
    }, 20000); // 10 seconds

    // Cleanup the timeout if the component unmounts or RawMarketData changes
    return () => clearTimeout(timeoutRef.current);
  }, [RawMarketData]);




  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const theme = useTheme();

  return (
    <>
      <Header>
        {isMarketData ? (
          <Loader msg={true} marketLoader={true} />
        ) : (
          <>
            <Box sx={{ marginLeft: 3 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginRight: 3,

                  mb: 1,
                }}
              >
                <Button
                  onClick={handleMarketData}
                  variant="outlined"
                  color="primary"
                  sx={{ marginRight: 4, mt: 1 }}
                >
                  Fetch Market Data
                </Button>
                <TextField
                  type="number"
                  variant="standard"
                  value={threshold}
                  onChange={(e) => {
                    const newValue = parseInt(e.target.value);

                    // Check if it's not NaN and not less than 0
                    if (!isNaN(newValue) && newValue >= 0) {
                      setthreshold(newValue);
                    }
                  }}
                  sx={{ width: 100 }}
                  InputProps={{
                    inputProps: {
                      max: 1000000,
                      min: 0,
                      style: { fontSize: 12 }, // Set font size
                    },
                  }}
                  label="Threshold"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: theme.palette.lightGrey.backgroundBody,
                  borderRadius: "25px",
                  maxWidth: "90%",
                }}
              >
                <CustomizeBox>
                  <Box
                    sx={{
                      border: `1px solid ${theme.palette.lightGrey.tableHeader}`,
                      borderRadius: "4px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        background: theme.palette.lightGrey.tableHeader,
                        px: 1,
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ color: theme.palette.lightGrey.textColor }}
                      >
                        {isStrategist
                          ? "Strategy Performance Summary"
                          : "Funds Performance Summary"}
                      </Typography>
                      <IconButton
                        onClick={toggleGridViewSummary}
                        aria-label="Toggle GridView"
                        sx={{ height: "20px", width: "20px" }}
                      >
                        <FullscreenIcon
                          sx={{ height: "15px", width: "15px" }}
                        />
                      </IconButton>
                    </Box>
                    <Table size="small">
                      <TableRow>
                        {longShortLabels?.map((i) => {
                          return (
                            <Tablecell
                              align="center"
                              key={i}
                              sx={{
                                borderRight: `1px solid ${theme.palette.lightGrey.tableHeader}`,
                                color: theme.palette.lightGrey.textColor,
                                fontSize: theme.typography.bodyText12.fontSize,
                                fontWeight:
                                  theme.typography.bodyText12.fontWeight,
                                background:
                                  theme.palette.lightGrey.backgroundBody,
                              }}
                            >
                              {i}
                            </Tablecell>
                          );
                        })}
                      </TableRow>

                      <TableBody>
                        {totals.map((row) => (
                          <TableRow
                            key={row.name}
                            sx={{
                              "&:hover": {
                                background: theme.palette.grey[200],
                                cursor: "pointer",
                              },
                            }}
                          >
                            <Tablecell
                              align="left"
                              sx={{
                                color: theme.palette.lightGrey.textColor,
                                fontSize: theme.typography.bodyText12.fontSize,
                                fontWeight:
                                  theme.typography.bodyText12.fontWeight,
                                borderRight: `1px solid ${theme.palette.lightGrey.tableHeader}`,
                                background:
                                  theme.palette.lightGrey.backgroundBody,
                              }}
                            >
                              {row.name}
                            </Tablecell>

                            <Tablecell
                              align="right"
                              style={{
                                borderRight: `1px solid ${theme.palette.lightGrey.tableHeader}`,
                                background: theme.palette.grey[100],
                              }}
                            >
                              {row.totalsAUM > 0 ? (
                                <>{row.totalsAUM.toFixed(2)}%&nbsp;</>
                              ) : row.totalsAUM < 0 ? (
                                `(${(row.totalsAUM * -1).toFixed(2)}%)`
                              ) : (
                                ""
                              )}
                            </Tablecell>
                            <Tablecell
                              align="right"
                              style={{
                                borderRight: `1px solid ${theme.palette.lightGrey.tableHeader}`,
                                background: theme.palette.grey[100],
                                color: row.totalsPl > 0 ? "green" : "red",
                              }}
                            >
                              {row.totalsPl > 0 ? (
                                <>{formatValueDecimal(row?.totalsPl)}&nbsp;</>
                              ) : row.totalsPl < 0 ? (
                                `(${formatValueDecimal(row?.totalsPl * -1)})`
                              ) : (
                                ""
                              )}
                            </Tablecell>
                            <Tablecell
                              align="right"
                              style={{
                                borderRight: `1px solid ${theme.palette.lightGrey.tableHeader}`,
                                background: theme.palette.grey[100],
                                color: row.totalsPcm > 0 ? "green" : "red",
                              }}
                            >
                              {row.totalsPcm > 0 ? (
                                <>{row.totalsPcm.toFixed(2)}%&nbsp;</>
                              ) : row.totalsPcm < 0 ? (
                                `(${(row.totalsPcm * -1).toFixed(2)}%)`
                              ) : (
                                ""
                              )}
                            </Tablecell>
                            <Tablecell
                              align="right"
                              style={{
                                borderRight: `1px solid ${theme.palette.lightGrey.tableHeader}`,
                                background: theme.palette.grey[100],
                                color: row?.totalSignals > 0 ? "green" : "red",
                              }}
                            >
                              {row.totalSignals > 0 ? (
                                <>
                                  {formatValueDecimal(row?.totalSignals)}&nbsp;
                                </>
                              ) : row.totalSignals < 0 ? (
                                `(${formatValueDecimal(
                                  row?.totalSignals * -1
                                )})`
                              ) : (
                                ""
                              )}
                            </Tablecell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    allowScrollButtonsMobile
                    aria-label="scrollable force tabs example"
                    sx={{
                      [`& .${tabsClasses.scrollButtons}`]: {
                        "&.Mui-disabled": { opacity: 0.3 },
                        borderRadius: "50%",
                        background: "#808080",
                        height: "20px",
                        width: "20px",
                        mt: 2,
                      },
                    }}
                    indicatorColor="white"
                    textColor="inherit"
                  >
                    <StyledTab
                      onClick={() => {
                        setIsPositionPerformance(true);
                      }}
                      label="Positions Performance"
                    />
                  </Tabs>
                </CustomizeBox>
                <CustomizeBox>
                  <BenchMarkTable marketData={marketData} />
                </CustomizeBox>
              </Box>
            </Box>

            <PositionPeformance
              title="POSITIONS PERFORMANCE"
              isPositionPerformance={isPositionPerformance}
              setIsPositionPerformance={setIsPositionPerformance}
            />
            <PortfolioTable
              fundname={fundName}
              strategyname={strategyName}
              setTotals={setTotals}
              threshold={threshold}
            />
            <PositionsSummaryPopUp
              openSummaryPopup={isGridViewSummary}
              setopenSummaryPopup={setIsGridViewSummary}
              totals={totals}
              tablehead={longShortLabels}
              title={
                isStrategist
                  ? "Strategy Performance Summary"
                  : "Funds Performance Summary"
              }
            />
            <BenchMarkData
              isViewBenchmarkData={isViewBenchmarkData}
              setisViewBenchmarkData={setisViewBenchmarkData}
              row={row}
              setRow={setRow}
              title="Benchmark Selection"
            />
          </>
        )}
      </Header>
    </>
  );
};
export default PortfolioLayout;
