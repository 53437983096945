import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Button,
  Box,
  Typography,
  FormControl,
  Autocomplete,
  TextField,
  Divider,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { processData } from "../Methods";
import { useSelector } from "react-redux";
import PerformanceList from "../SignalsInputDetails/SignalsInputAllocation/PerformanceList";
import Popup from "../Popup";
import SmaStrategy from "./SmaStrategy";
import FundAllocation from "./FundAllocation";
import ManageStrategy from "./ManageStrategy";

const SmaPerformance = () => {
  const [name, setName] = useState("Charles Schwab");
  const [account, setAccount] = useState("23232323232");
  const [strategyVal, setstrategyVal] = useState("");
  const [strategyValObj, setstrategyValObj] = useState();
  const [openPopupFundAllocation, setOpenPopupFundAllocation] = useState(false);
  const [openPopupManageStrategy, setOpenPopupManageStrategy] = useState(false);
  const { strategies } = useSelector((state) => state?.todos);
  const { userInfo } = useSelector((state) => state?.users);
  const names = ["Charles Schwab", "Interactive Broker"];
  const accounts = ["23232323232"];

  return (
    <>
      <Container maxWidth="100%" sx={{ marginTop: 5, marginBottom: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                width: "90%",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  background: "#f6f6f6",
                  padding: "20px 20px",
                  borderRadius:2
                }}
              >
                <Box sx={{
                  display: "flex",
                  flexDirection: "row",
                }}>
                <Box>
                  <Box>
                    <Typography sx={{ fontSize: "15px", fontWeight: 400 }}>
                      Brokerage:
                    </Typography>
                  </Box>
                  <Typography sx={{ fontSize: "15px", fontWeight: 400, my: 1 }}>
                    Email:
                  </Typography>
                  <Typography sx={{ fontSize: "15px", fontWeight: 400 }}>
                    Account No:
                  </Typography>
                </Box>

                <Box sx={{marginLeft:4,marginTop:"-10px"}}>
                  <FormControl fullWidth>
                    <Autocomplete
                      disableClearable
                      value={name}
                      onChange={(event, newValue) => {
                        setName(newValue);
                      }}
                      options={names}
                      ListboxProps={{
                        sx: { fontSize: 15 },
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="standard" />
                      )}
                    />
                  </FormControl>

                  <Typography sx={{ fontSize: "15px", fontWeight: 600, my: 0.7 }}>
                    {userInfo?.email}
                  </Typography>
                  <FormControl fullWidth>
                    <Autocomplete
                      disableClearable
                      value={account}
                      onChange={(event, newValue) => {
                        setAccount(newValue);
                      }}
                      options={accounts}
                      ListboxProps={{
                        sx: { fontSize: 15 },
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="standard" />
                      )}
                    />
                  </FormControl>
                </Box>
                </Box>
                <Box>
                  <Button
                    variant="outlined"
                    startIcon={
                      <CircleIcon
                        sx={{ color: "#F2A43A", height: "12px", width: "12px" }}
                      />
                    }
                    sx={{
                      cursor: "context-menu",
                      width: "150px",
                      borderRadius: "20px",
                      color: "#000",
                      background: "#FCEFDD",
                      border: "1px solid #F2A43A",
                      "&:hover": {
                        borderRadius: "20px",
                        color: "#000",
                        background: "#FCEFDD",
                        border: "1px solid #F2A43A",
                      },
                    }}
                  >
                    Pending
                  </Button>{" "}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  mt: 4,
                }}
              >
                <Box>
                  <Typography sx={{ fontSize: "13px", fontWeight: 400 }}>
                    Subscribed Strategy
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontSize: "22px", fontWeight: 900 }}>
                      AI FACTOR
                    </Typography>

                    <Divider
                      orientation="vertical"
                      variant="middle"
                      flexItem
                      sx={{ mx: 0.7, background:"#000" }}
                    />
                    <Typography sx={{ fontSize: "22px", fontWeight: 900 }}>
                      CP FACTOR
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      setOpenPopupManageStrategy(true);
                    }}
                    sx={{ width: "200px", marginRight:2, padding:"10px 2px" }}
                  >
                    Manage Strategies
                  </Button>{" "}
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setOpenPopupFundAllocation(true);
                    }}
                    sx={{ width: "200px",  padding:"10px 2px"  }}
                  >
                    Fund Allocation
                  </Button>{" "}
                </Box>
              </Box>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "40%",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              <PerformanceList
                strategies={strategies}
                strategyValObj={strategyValObj}
                strategyVal={strategyVal}
                showNoSector={true}
              />
            </div>
          </Grid>
        </Grid>

        <Popup
          title="Fund Allocation"
          openPopup={openPopupFundAllocation}
          setOpenPopup={setOpenPopupFundAllocation}
        >
          <FundAllocation
            setOpenPopupFundAllocation={setOpenPopupFundAllocation}
          />
        </Popup>
        <Popup
          openPopup={openPopupManageStrategy}
          setOpenPopup={setOpenPopupManageStrategy}
          title="Manage Strategies"
        >
          <ManageStrategy
            setOpenPopupManageStrategy={setOpenPopupManageStrategy}
          />
        </Popup>
      </Container>
    </>
  );
};

export default SmaPerformance;
