import React, { useEffect, useState } from "react";
import {
  Table,
  TableRow,
  TableBody,
  useTheme,
  Box,
  TextField,
  Typography,
  TableCell,
} from "@mui/material";
import VantageListHead from "../../../../../../ListHead/SignalList";
import {
  Tablecell,
  applySortFilter,
  calculateAndFormat,
  calculateAndFormatNoAbs,
  getComparator,
} from "../../../../../../Methods";

const Locates = ({ tickerTrades, setLocatesData, tableHeader }) => {
  const theme = useTheme();
  const [data, setData] = useState(tickerTrades);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("ticker");

  useEffect(() => {
    setData(tickerTrades);
  }, [tickerTrades]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleAvailableChange = (e, index) => {
    const { value } = e.target;
    const updatedData = data?.map((row, i) =>
      i === index ? { ...row, available: Number(value) } : row
    );
    setData(updatedData);
    setLocatesData(updatedData);
  };

  const handleApprovedClick = () => {
    const updatedData = data?.map((row) => ({
      ...row,
      available: row.shareCount,
    }));

    setData(updatedData);
    setLocatesData(updatedData);
  };

  useEffect(() => {
    const filteredData = applySortFilter(data, getComparator(order, orderBy));
    setData(filteredData);
    setLocatesData(filteredData);
  }, [order, orderBy, tickerTrades]);

  return (
    <>
      <Box
        sx={{
          border: `1px solid ${theme.palette.lightGrey.tableHeader}`,
          borderRadius: "4px",
          marginTop: "2px",
          width: "100%",
          marginRight: 1,
        }}
      >
        <Typography
          color="primary"
          sx={{
            fontSize: "12px",
            textAlign: "right",
            "&:hover": {
              cursor: "pointer",
              textDecoration: "underline",
              textUnderlineOffset: "2px",
            },
          }}
          onClick={handleApprovedClick}
        >
          APPROVE ALL
        </Typography>
        <Box
          sx={{
            maxHeight: "350px",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "5px",
              height: "3px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#f0f0f0",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#c0c0c0",
              borderRadius: "10px",
              minHeight: "2px",
            },
          }}
        >
          <Table
            size="small"
            stickyHeader
            aria-label="a dense table"
            sx={{
              borderCollapse: "collapse",
              border: "solid 1px #eee",
              marginTop: "-1px",
            }}
          >
            <VantageListHead
              order={order}
              orderBy={orderBy}
              headLabel={tableHeader}
              noStatus={true}
              customizeFont={12}
              rowCount={data.length}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {data?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:hover": {
                      background: theme.palette.grey[200],
                      cursor: "pointer",
                    },
                  }}
                >
                  <Tablecell
                    align="left"
                    style={{
                      fontSize: "12px",
                      paddingLeft: "10px",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  >
                    {row?.ticker}
                  </Tablecell>
                  <Tablecell
                    align="right"
                    style={{
                      fontSize: "12px",
                      paddingRight: "10px",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  >
                    {calculateAndFormatNoAbs(row?.shareCount)}
                  </Tablecell>
                  <TableCell
                    align="left"
                    sx={{
                      borderLeft: "none",
                      height: "auto",
                      padding: 0,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "left",
                        width: "100%",
                      }}
                    >
                      <TextField
                        value={row.available}
                        onChange={(e) => handleAvailableChange(e, index)}
                        size="small"
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            fontSize: "12px",
                            width: "10ch",
                            marginRight: "0.5ch",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                          },
                        }}
                        inputProps={{
                          type: "number",
                          step: "any",
                          style: {
                            textAlign: "right",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            MozAppearance: "textfield", // For Firefox
                            WebkitAppearance: "none", // For most other browsers
                            // For hiding the spin buttons specifically in WebKit browsers
                            "&::-webkit-outer-spin-button": {
                              WebkitAppearance: "none",
                              margin: 0,
                            },
                            "&::-webkit-inner-spin-button": {
                              WebkitAppearance: "none",
                              margin: 0,
                            },
                          },
                        }}
                      />
                    </Box>
                  </TableCell>

                  <Tablecell
                    align="left"
                    style={{
                      fontSize: "12px",
                      paddingLeft: "10px",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  >
                    {row?.custodian}
                  </Tablecell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </>
  );
};

export default Locates;
