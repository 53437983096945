import React, { useState } from "react";
import { Button, Stack, Container, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import ProfileInfo from "./ProfileInfo";
import ChangeUserPassword from "../UserPassword/ChangeUserPassword";

const UserProfileDetails = () => {
  const [selectedSection, setSelectedSection] = useState("profile");

  const theme = useTheme();
  return (
    <Container
      maxWidth="100%"
      sx={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}
    >
      <Stack direction="column" spacing={2} alignItems="left" mt={4}>
        <Button
          variant="contained"
          sx={{
            width: "200px",
            background: theme.palette.grey[700],
            color: theme.palette.grey[100],
            "&:hover": { background: theme.palette.grey[800] },
          }}
          onClick={() => setSelectedSection("profile")}
        >
          Profile Information
        </Button>
        <Button
          variant="contained"
          sx={{
            width: "200px",
            background: theme.palette.grey[700],
            color: theme.palette.grey[100],
            "&:hover": { background: theme.palette.grey[800] },
          }}
          onClick={() => setSelectedSection("password")}
        >
          Change Password
        </Button>
      </Stack>

      {selectedSection === "profile" && <ProfileInfo />}

      {selectedSection === "password" && <ChangeUserPassword />}
    </Container>
  );
};

export default UserProfileDetails;
