import React, { useState } from "react";
import {
  Tooltip,
  IconButton,
  InputAdornment,
  Box,
  Menu,
  MenuItem,
  Button,
  useTheme,
  Tabs,
  Tab,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import Popup from "../../Popup";
import { StyledRoot, StyledSearch } from "../../Methods";
import AddSource from "../AddSource";

const ListToolbar = ({
  filterName,
  onFilterName,
  setOrderBy,
  setOrder,
  activeTab,
  onTabChange,
  data,
}) => {
  const [anchorEl, setAnchorEl] = useState("");
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openPopup, setOpenPopup] = useState(false);
  const [activeTabs, setActiveTab] = useState("Bloomberg");
  const theme = useTheme();

  const tabs = ["Bloomberg", "YFinance", "Polygon"];

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    onTabChange(newValue);
  };

  return (
    <>
      <StyledRoot>
        <Box>
          <StyledSearch
            value={filterName}
            onChange={onFilterName}
            placeholder="Search..."
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon
                  sx={{ color: "text.disabled", width: 15, height: 15 }}
                />
              </InputAdornment>
            }
          />

          <Tooltip title="Filter list">
            <IconButton
              id="demo-positioned-button"
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              sx={{ marginLeft: "20px" }}
            >
              <FilterListIcon
                sx={{ width: "20px", height: "20px", color: "#000" }}
              />
            </IconButton>
          </Tooltip>
        </Box>
      </StyledRoot>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "15px",
        }}
      >
        <div>
          <Tabs value={activeTabs} onChange={handleTabChange}>
            {tabs.map((tab, index) => (
              <Tab label={tab} value={tab} key={index} />
            ))}
          </Tabs>
        </div>

        <Button
          text="ADD SOURCE"
          variant="contained"
          sx={{ height: 40, width: 150, fontSize: theme.typography.bodyText17 }}
          onClick={() => {
            setOpenPopup(true);
          }}
        >
          <AddIcon sx={{ width: "20px", height: "20px", mr: 1 }} />
          ADD SOURCE
        </Button>
      </div>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ marginTop: 3 }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={() => {
            setOrderBy("name");
            setOrder("asc");
            handleClose();
          }}
        >
          Full name
        </MenuItem>
      </Menu>

      <Popup
        title="Source Form"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <AddSource setOpenPopup={setOpenPopup} />
      </Popup>
    </>
  );
};

export default ListToolbar;
