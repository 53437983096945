import { Box, Typography } from "@mui/material";

const Header = ({ title, marginLeftFalse, subtitle, children }) => {
  return (
    <Box sx={{ marginTop: 1}}>
      <Box>
        <Typography
          variant="h6"
          component="div"
          fontSize="30px"
          marginLeft={marginLeftFalse ? "20px" : "40px"}
        >
          {title}
        </Typography>

        <Typography
          variant="h6"
          component="div"
          sx={{
            color: "#808080",
            fontWeight: 500,
            marginLeft: "40px",
          }}
          fontSize="12px"
        >
          {subtitle}
        </Typography>
        {children}
      </Box>
    </Box>
  );
};
export default Header;
