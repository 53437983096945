import * as React from "react";
import Box from "@mui/material/Box";
import "../../App.css";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import {
  Button,
  FormControl,
  Autocomplete,
  Divider,
  Typography,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import { UserName } from "../../component/Schemas";
import { useSelector } from "react-redux";

const AddUser = ({ setOpenPopup }) => {
  const initialValues = {
    fname: "",
    lname: "",
    email: "",
    tradeBlotter: [],
    dailyPerformance: [],
    performanceDashboard: [],
    signals: [],
    rebalancer: [],
    role: "Precept",
    userType: "Administrator",
    password: "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: UserName,

      onSubmit: (values) => {
        setOpenPopup(false);
      },
    });
  const roles = ["Precept"];
  const userTypes = ["Administrator", "Strategist"];
  const Options = ["View", "Add", "Edit", "Delete"];
  const theme = useTheme();
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
      onSubmit={handleSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={6} marginBottom="5px">
          <TextField
            fullWidth
            id="fname"
            label="First Name"
            value={values.fname}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="standard"
          />
          {errors.fname && touched.fname ? (
            <p className="error">*{errors.fname}</p>
          ) : null}
        </Grid>

        <Grid item xs={6} marginBottom="5px">
          <TextField
            fullWidth
            id="lname"
            label="Last Name"
            value={values.lname}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="standard"
          />
          {errors.lname && touched.lname ? (
            <p className="error">*{errors.lname}</p>
          ) : null}
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            id="email"
            label="Email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="standard"
          />
          {errors.email && touched.email ? (
            <p className="error">*{errors.email}</p>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Password"
            id="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            type="password"
            variant="standard"
          />
          {errors.password && touched.password ? (
            <p className="error">*{errors.password}</p>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              disableClearable
              value={values.role}
              ListboxProps={{
                sx: { fontSize: 15 },
              }}
              sx={{
                "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
                  fontSize: 15,
                },
              }}
              onChange={handleChange}
              options={roles?.map((val) => val)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  value={values.role}
                  label="Manager / Organization"
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              disableClearable
              value={values.userType}
              ListboxProps={{
                sx: { fontSize: 15 },
              }}
              sx={{
                "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
                  fontSize: 15,
                },
              }}
              onChange={handleChange}
              options={userTypes?.map((val) => val)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  value={values.userType}
                  label="User Type"
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: theme.typography.bodyText17,
              color: theme.palette.red[100],
            }}
          >
            User Permissons
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              multiple
              id="tradeBlotter"
              value={values.tradeBlotter}
              onChange={(event, newValue) => {
                handleChange({
                  target: { id: "tradeBlotter", value: newValue },
                });
              }}
              options={Options}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Trade Blotter"
                />
              )}
            />
            {errors.tradeBlotter && touched.tradeBlotter ? (
              <p className="error">*{errors.tradeBlotter}</p>
            ) : null}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              multiple
              id="dailyPerformance"
              value={values.dailyPerformance}
              onChange={(event, newValue) => {
                handleChange({
                  target: { id: "dailyPerformance", value: newValue },
                });
              }}
              options={Options}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Daily Performance"
                />
              )}
            />
            {errors.dailyPerformance && touched.dailyPerformance ? (
              <p className="error">*{errors.dailyPerformance}</p>
            ) : null}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              multiple
              id="signals"
              value={values.signals}
              onChange={(event, newValue) => {
                handleChange({
                  target: { id: "signals", value: newValue },
                });
              }}
              options={Options}
              renderInput={(params) => (
                <TextField {...params} variant="standard" label="Signals" />
              )}
            />
            {errors.signals && touched.signals ? (
              <p className="error">*{errors.signals}</p>
            ) : null}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              multiple
              id="rebalancer"
              value={values.rebalancer}
              onChange={(event, newValue) => {
                handleChange({
                  target: { id: "rebalancer", value: newValue },
                });
              }}
              options={Options}
              renderInput={(params) => (
                <TextField {...params} variant="standard" label="Rebalancer" />
              )}
            />
            {errors.rebalancer && touched.rebalancer ? (
              <p className="error">*{errors.rebalancer}</p>
            ) : null}
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          margin="10px 10px 10px 0px"
          display="flex"
          justifyContent="flex-end"
        >
          <Button variant="contained" sx={{ width: "30%" }} type="submit">
            ADD USER
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
export default AddUser;
