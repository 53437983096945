import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import { useFormik } from "formik";
import {
  FormControl,
  useTheme,
  Typography,
  IconButton,
  Alert,
  AlertTitle,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { UserSma, UserStrategy } from "../../Schemas";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector } from "react-redux";

const SmaStrategy = ({ setOpenPopupManageStrategy, setSubscribeOpen }) => {
  const [strategyVal, setstrategyVal] = useState("");
  const [strategyValObj, setstrategyValObj] = useState();
  const { strategies } = useSelector((state) => state?.todos);
  const initialValues = {
    strategy: "",
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: UserStrategy,

    onSubmit: (values) => {
      // setOpenPopup(false);
      setOpenPopupManageStrategy(false);
    },
  });

  function strategySelected(id) {
    // processData(strategies, id);
    setstrategyValObj(id);
  }

  const strategyList = strategies
    ?.map((i) => i?.name)
    .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0));

  const theme = useTheme();
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              aria-label="back"
              size="large"
              onClick={() => {
                setSubscribeOpen(false);
              }}
            >
              <ArrowBackIcon fontSize="inherit" />
            </IconButton>
            <Typography
              sx={{ marginLeft: "8px", fontSize: "20px", fontWeight: "bold" }}
            >
              Subscribe Strategy
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              options={strategyList}
              value={strategyVal}
              autoSelect
              onChange={(e, newValue) => {
                setstrategyVal(newValue);
                setFieldValue("strategy", newValue);
                const strObj = strategies?.find((i) => {
                  return i?.name == newValue;
                });
                if (strObj && strObj?.strategyId) {
                  strategySelected(strObj?.strategyId);
                } else {
                  setstrategyValObj();
                }
              }}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Strategy"
                  variant="standard"
                  fullWidth
                />
              )}
            />
          </FormControl>
          {errors.strategy && touched.strategy ? (
            <p className="error">*{errors.strategy}</p>
          ) : null}
        </Grid>

        <Grid
          item
          xs={12}
          style={{ background: "#f6f6f6", padding: "10px 10px 15px 10px", marginTop: "10px" }}
        >
          <Grid container style={{ marginTop: "10px" }}>
            <Typography
              sx={{ marginLeft: "5px", fontSize: "18px", fontWeight: "bold" }}
            >
              Strategy Description
            </Typography>
          </Grid>
          <Grid sx={{ marginTop: "6px" }}>
            <Typography variant="body2" style={{ marginLeft: "8px" }}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </Typography>
          </Grid>
        </Grid>

        <Grid sx={{ marginTop: "10px" }}>
            <Alert severity="warning">
              <AlertTitle>Disclaimer</AlertTitle>
              While we design our strategies with the aim of maximizing profits, it's important to understand that investing involves inherent risks, including the possibility of losses. Please consider your investment objectives and risk tolerance carefully before making any investment decisions.
            </Alert>
          </Grid>

        <Grid
          item
          xs={12}
          margin="10px"
          display="flex"
          flexDirection={"column"}
          justifyContent="space-between"
        >
          <Button
            variant="contained"
            sx={{ width: "100%", mb: 2, fontSize: "17px" }}
            type="submit"
          >
            SUBSCRIBE STRATEGY
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
export default SmaStrategy;
