import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  IconButton,
  Typography,
  useTheme,
  TextField,
} from "@mui/material";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Card, TableContainer, Button, TableHead } from "@mui/material";
import { styled } from "@mui/material";
import {
  requestMarketData,
  updateCalculatedMonitorScreen,
} from "../../Redux/Actions/todoActions";
import CustomizeBox from "../CustomizeBox";
import {
  TABLE_HEAD_VANTAGE as TABLE_HEAD,
  applySortFilter,
  calculateAndFormat,
  getComparator,
  StyledTab,
} from "../Methods";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MonitorScreenCalculation from "../Tables/Portfolio/AssetPositions/MonitorScreenCalculation";
import VantageListHead from "../ListHead/VantageListHead";
import PerformanceFundPopUp from "../Popup/PerformanceFundPopUp";
import FundStrategyPopUp from "../Popup/FundStrategyPopUp";
import { StrategiesDashboard } from "../Layouts/dashboardCalculations";
import PositionPeformance from "../Popup/PositionPerformance";
import BenchMarkTable from "./BenchMarkTable";
import Switch from "@mui/material/Switch";
import Loader from "../Loader";

const label = { inputProps: { "aria-label": "Switch demo" } };

const Tablecell = styled(TableCell)(({ theme }) => ({
  fontSize: "12px",
  padding: "1px 2px 1px 5px",
}));

const VantageSystem = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = localStorage.getItem("threshold") || 60;
  const [threshold, setthreshold] = useState(t);
  const [monitorScreenValuesObj, setMonitorScreenValuesObj] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("aum");
  const [filterName, setFilterName] = useState("");
  const [accFund, setaccFund] = useState([]);
  const [monitor, setMonitor] = useState([]);
  const [isGridViewPositions, setIsGridViewPositions] = useState(false);
  const [isGridViewPerformance, setIsGridViewPerformance] = useState(false);
  const [isPositionPerformance, setIsPositionPerformance] = useState(false);
  const [checked, setChecked] = useState(true);
  const [value, setValue] = React.useState(0);
  const [isMarketData, setIsMarketData] = useState(false);
  const [dataFetching, setDataFetching] = useState(false);

  const resultArray = StrategiesDashboard();

  const {
    lots,
    accountingData,
    funds,
    strategies,
    marketData,
    RawMarketData,
    signalsPerformance,
    managers,
    trades,
    isStrategist,
  } = useSelector((state) => state?.todos);
  useEffect(() => {
    const filteredData = MonitorScreenCalculation({
      selectedFund: undefined,
      selectedStrategy: undefined,
      selectedSide: undefined,
      lots: lots,
      accountingData: accountingData,
      funds: funds,
      strategies: strategies,
      marketData: marketData,
      trades: trades,
      isStrategist: isStrategist,
    });
    if (filteredData) dispatch(updateCalculatedMonitorScreen(filteredData));
  }, []);

  const timeoutRef = useRef(null);
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {

    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }

    // Clear the previous timeout if it exists
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    setDataFetching(true);
    // Set a new timeout to trigger the function after 20 seconds
    timeoutRef.current = setTimeout(() => {
      setIsMarketData(false)
      dispatch(
        requestMarketData({
          lots: lots,
          threshold: threshold,
          noRequest: true,
          trades: trades,
        })
      );
    }, 20000); // 10 seconds

    // Cleanup the timeout if the component unmounts or RawMarketData changes
    return () => clearTimeout(timeoutRef.current);
  }, [RawMarketData]);





  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeCheck = (event) => {
    setChecked(event.target.checked);
  };

  const calculatedValues = MonitorScreenCalculation({
    lots: lots,
    accountingData: accountingData,
    funds: funds,
    strategies: strategies,
    marketData: marketData,
  });

  const handleCellClick = (fundName) => {
    navigate(`/portfolio/${fundName}`);
  };
  const handleCellClickStrategy = (fundName, strategyName) => {
    navigate(`/portfolio/${fundName}/${strategyName}`);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  useEffect(() => {
    setMonitor(resultArray);
    const filteredUsers = applySortFilter(
      resultArray,
      getComparator(order, orderBy),
      filterName
    );
    setMonitorScreenValuesObj(filteredUsers);
  }, [order, orderBy, filterName, calculatedValues]);

  useEffect(() => {
    if (funds) {
      const findFunds = funds?.filter(
        (i) =>
          i.abbreviation === "wsf" ||
          i.abbreviation === "pmp" ||
          i.abbreviation === "top"
      );
      const transformedFunds = findFunds?.map((fund) => {
        const calc = MonitorScreenCalculation({
          selectedFund: fund.fundId,
          lots: lots,
          accountingData: accountingData,
          funds: funds,
          strategies: strategies,
          marketData: marketData,
        });

        let totalPL = calc.reduce(
          (sum, currentObj) => sum + parseFloat(currentObj.pL),
          0
        );

        return {
          name: fund.name,
          aum: findFundAum(fund.fundId, accountingData),
          size: totalPL,
          pf:
            (totalPL / parseFloat(findFundAum(fund.fundId, accountingData))) *
            100,
        };
      });
      const totalAum = transformedFunds.reduce((total, fund) => {
        return fund.aum ? total + fund.aum : total;
      }, 0);
      const size = transformedFunds.reduce((total, fund) => {
        return fund.size ? total + fund.size : total;
      }, 0);
      const pf = transformedFunds.reduce((total, fund) => {
        return fund.pf ? total + fund.pf : total;
      }, 0);

      transformedFunds.push({
        name: "Totals",
        aum: totalAum,
        size: size,
        pf: pf,
      });

      setaccFund(transformedFunds);
    }
  }, [funds, accountingData, marketData]);

  const findFundAum = (key, array) => {
    if (key && array && array[0]?.funds) {
      const findAum = array[0]?.funds?.find((i) => i.fund === key);
      return findAum?.aum;
    }
  };
  const toggleGridViewPositions = () => {
    setIsGridViewPositions(!isGridViewPositions);
  };

  const toggleGridViewPerformance = () => {
    setIsGridViewPerformance(!isGridViewPerformance);
  };

  const positions = ["Fund", "AUM", "P(L)", "P(L)%"];

  

  const handleMarketData = () => {
    localStorage.setItem("threshold", threshold);
    setIsMarketData(true)
    setDataFetching(false);
    dispatch(
      requestMarketData({ lots: lots, threshold: threshold, trades: trades })
    );
    setTimeout(() => {
      if (!dataFetching) {
        setIsMarketData(false)
      }
    }, 20000);
  };

  const theme = useTheme();
  return isMarketData ? (
    <Loader msg={true} marketLoader={true} />
  ) : (
    <>
      <Box sx={{ marginLeft: 3, height: "85vh" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginRight: 3,

            mb: 1,
          }}
        >
          <Button
            onClick={handleMarketData}
            variant="outlined"
            color="primary"
            sx={{ marginRight: 4, mt: 1 }}
          >
            Fetch Market Data
          </Button>
          <TextField
            type="number"
            variant="standard"
            value={threshold}
            onChange={(e) => {
              const newValue = parseInt(e.target.value);

              if (!isNaN(newValue) && newValue >= 0) {
                setthreshold(newValue);
              }
            }}
            sx={{ width: 100 }}
            InputProps={{
              inputProps: {
                max: 1000000,
                min: 0,
                style: { fontSize: 12 }, // Set font size
              },
            }}
            label="Threshold"
          />
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: theme.palette.lightGrey.backgroundBody,
              borderRadius: "25px",
              maxWidth: "90%",
            }}
          >
            <CustomizeBox>
              <Box
                sx={{
                  border: `1px solid ${theme.palette.lightGrey.tableHeader}`,
                  borderRadius: "4px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    background: theme.palette.lightGrey.tableHeader,
                    px: 1,
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{ color: theme.palette.lightGrey.textColor }}
                  >
                    Longs and Shorts Summary
                  </Typography>
                  <IconButton
                    onClick={toggleGridViewPositions}
                    aria-label="Toggle GridView"
                    sx={{ height: "20px", width: "20px" }}
                  >
                    <FullscreenIcon sx={{ height: "15px", width: "15px" }} />
                  </IconButton>
                </Box>
                <Table aria-label="a dense table" size="small">
                  <TableRow>
                    {positions?.map((i, index) => {
                      return (
                        <Tablecell
                          align="center"
                          key={index}
                          sx={{
                            borderRight: `1px solid ${theme.palette.lightGrey.tableHeader}`,
                            color: theme.palette.lightGrey.textColor,
                            fontSize: theme.typography.bodyText12.fontSize,
                            fontWeight: theme.typography.bodyText12.fontWeight,
                          }}
                        >
                          {i}
                        </Tablecell>
                      );
                    })}
                  </TableRow>
                  <TableBody>
                    {accFund?.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          background: theme.palette.grey[100],
                          "&:hover": {
                            background: theme.palette.grey[200],
                            cursor: "pointer",
                          },
                        }}
                      >
                        <Tablecell
                          align="left"
                          sx={{
                            borderRight: `1px solid ${theme.palette.lightGrey.tableHeader}`,
                          }}
                        >
                          {row?.name}
                        </Tablecell>
                        <Tablecell
                          sx={{
                            borderRight: `1px solid ${theme.palette.lightGrey.tableHeader}`,
                          }}
                          align="right"
                        >
                          {calculateAndFormat(row?.aum)}
                        </Tablecell>
                        <Tablecell
                          style={{
                            borderRight: `1px solid ${theme.palette.lightGrey.tableHeader}`,
                            color: row?.size >= 0 ? "green" : "red",
                          }}
                          align="right"
                        >
                          {row?.size >= 0 ? (
                            <>{calculateAndFormat(row?.size)}&nbsp;</>
                          ) : (
                            "(" + calculateAndFormat(row?.size * -1) + ")"
                          )}
                        </Tablecell>
                        <Tablecell
                          style={{
                            borderRight: `1px solid ${theme.palette.lightGrey.tableHeader}`,
                            color: row?.pf >= 0 ? "green" : "red",
                          }}
                          align="right"
                        >
                          {row?.pf >= 0 ? (
                            <>{row?.pf.toFixed(2) + "%"}&nbsp;</>
                          ) : (
                            "(" + (row?.pf * -1).toFixed(2) + "%)"
                          )}
                        </Tablecell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
              <Tabs
                value={value}
                onChange={handleChange}
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    "&.Mui-disabled": { opacity: 0.3 },
                    borderRadius: "50%",
                    background: "#808080",
                    height: "20px",
                    width: "20px",
                    mt: 2,
                  },
                }}
                indicatorColor="white"
                textColor="inherit"
              >
                <StyledTab
                  onClick={() => {
                    setIsPositionPerformance(true);
                  }}
                  label="Positions Performance"
                />
              </Tabs>
            </CustomizeBox>
            <CustomizeBox>
              <BenchMarkTable marketData={marketData} />
            </CustomizeBox>
          </Box>
          <Box sx={{ my: 1, paddingRight: 2, maxWidth: "90%" }}>
            <Box
              sx={{
                border: `1px solid ${theme.palette.lightGrey.tableHeader}`,
                borderRadius: "4px",
              }}
            >
              <TableContainer>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    background: theme.palette.lightGrey.tableHeader,
                    px: 1,
                  }}
                >
                  <Box>
                    <Typography
                      variant="subtitle2"
                      sx={{ color: theme.palette.lightGrey.textColor }}
                    >
                      Funds and Strategies Performance
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{ color: theme.palette.lightGrey.textColor }}
                    >
                      Hide strategies with zero AUM
                    </Typography>
                    <Switch
                      size="small"
                      checked={checked}
                      onChange={handleChangeCheck}
                      inputProps={{ "aria-label": "controlled" }}
                    />

                    <IconButton
                      onClick={toggleGridViewPerformance}
                      aria-label="Toggle GridView"
                      sx={{ height: "20px", width: "20px" }}
                    >
                      <FullscreenIcon sx={{ height: "15px", width: "15px" }} />
                    </IconButton>
                  </Box>
                </Box>

                <Table
                  size="small"
                  stickyHeader
                  aria-label="a dense table"
                  sx={{
                    height: "max-content",
                    borderCllapse: "collapse",
                    border: "solid 1px #eee",
                  }}
                >
                  <VantageListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    noStatus={true}
                    onRequestSort={handleRequestSort}
                  />

                  <TableBody>
                    {monitorScreenValuesObj?.map((row, i) =>
                      (checked && parseFloat(row?.aum) > 10) || !checked ? (
                        <TableRow rowheight={0} key={i}>
                          <Tablecell
                            align="left"
                            onClick={() => handleCellClick(row.fundName)}
                            sx={{
                              cursor: "pointer",
                              fontWeight: "500",
                              paddingLeft: "5px",
                            }}
                          >
                            {row.fundName}
                          </Tablecell>
                          <Tablecell
                            align="left"
                            onClick={() =>
                              handleCellClickStrategy(
                                row.fundName,
                                row.strategyName
                              )
                            }
                            sx={{
                              cursor: "pointer",
                              paddingLeft: "5px",
                              width: "10",
                            }}
                          >
                            {row.strategyName}
                          </Tablecell>
                          <Tablecell align="right" sx={{ paddingRight: "5px" }}>
                            {calculateAndFormat(row?.aum)}
                          </Tablecell>
                          <Tablecell
                            align="right"
                            style={{
                              paddingRight: "5px",
                              color: row?.pL >= 0 ? "green" : "red",
                            }}
                          >
                            {row?.pL >= 0 ? (
                              <>{calculateAndFormat(row?.pL)}&nbsp;</>
                            ) : (
                              `(${calculateAndFormat(row?.pL * -1)})`
                            )}
                          </Tablecell>
                          <Tablecell
                            align="right"
                            style={{
                              paddingRight: "5px",

                              color: row.plLong >= 0 ? "green" : "red",
                            }}
                          >
                            {row?.plLong >= 0 ? (
                              <>{calculateAndFormat(row?.plLong)}&nbsp;</>
                            ) : (
                              `(${calculateAndFormat(row?.plLong * -1)})`
                            )}
                          </Tablecell>
                          <Tablecell
                            align="right"
                            style={{
                              paddingRight: "5px",

                              color: row?.plShort >= 0 ? "green" : "red",
                            }}
                          >
                            {row?.plShort >= 0 ? (
                              <>{calculateAndFormat(row?.plShort)}&nbsp;</>
                            ) : (
                              `(${calculateAndFormat(row?.plShort * -1)})`
                            )}
                          </Tablecell>
                          <Tablecell align="right" sx={{ paddingRight: "5px" }}>
                            {parseFloat(row?.sizeLong).toFixed(2)}%
                          </Tablecell>
                          <Tablecell align="right" sx={{ paddingRight: "5px" }}>
                            {`(${parseFloat(Math.abs(row?.sizeShort)).toFixed(
                              2
                            )}%)`}
                          </Tablecell>
                          <Tablecell align="right" sx={{ paddingRight: "5px" }}>
                            {Math.round(row?.longCapital).toLocaleString()}
                            {}
                          </Tablecell>
                          <Tablecell align="right" sx={{ paddingRight: "5px" }}>
                            {Math.abs(
                              Math.round(row?.shortCapital)
                            ).toLocaleString()}
                          </Tablecell>
                          <Tablecell
                            align="right"
                            style={{
                              paddingRight: "5px",
                              color:
                                row?.longPerformance >= 0 ? "green" : "red",
                            }}
                          >
                            {row?.longPerformance >= 0 ? (
                              <>{row?.longPerformance.toFixed(2)}%&nbsp;</>
                            ) : (
                              <>({(row?.longPerformance * -1).toFixed(2)}%)</>
                            )}
                          </Tablecell>
                          <Tablecell
                            align="right"
                            style={{
                              paddingRight: "5px",
                              color:
                                row?.shortPerformance >= 0 ? "green" : "red",
                            }}
                          >
                            {row?.shortPerformance >= 0 ? (
                              <>{row?.shortPerformance.toFixed(2)}%&nbsp;</>
                            ) : (
                              <>({(row?.shortPerformance * -1).toFixed(2)}%)</>
                            )}
                          </Tablecell>
                          <Tablecell
                            align="right"
                            style={{
                              paddingRight: "5px",
                              color: row?.plPer >= 0 ? "green" : "red",
                            }}
                          >
                            {row?.plPer >= 0 ? (
                              <>{(row?.plPer).toFixed(2)}%&nbsp;</>
                            ) : (
                              <>({Math.abs(row?.plPer).toFixed(2)}%)</>
                            )}
                          </Tablecell>
                          <Tablecell
                            align="right"
                            style={{
                              paddingRight: "5px",

                              color: row?.pLWeek >= 0 ? "green" : "red",
                            }}
                          >
                            {row?.pLWeek >= 0 ? (
                              <>{calculateAndFormat(row?.pLWeek)}&nbsp;</>
                            ) : (
                              `(${calculateAndFormat(row?.pLWeek * -1)})`
                            )}
                          </Tablecell>
                          <Tablecell
                            align="right"
                            style={{
                              paddingRight: "5px",

                              color: row?.pLMonth >= 0 ? "green" : "red",
                            }}
                          >
                            {row?.pLMonth >= 0 ? (
                              <>{calculateAndFormat(row?.pLMonth)}&nbsp;</>
                            ) : (
                              `(${calculateAndFormat(row?.pLMonth * -1)})`
                            )}
                          </Tablecell>
                          <Tablecell
                            align="right"
                            style={{
                              paddingRight: "5px",

                              color: row?.pLQuarter >= 0 ? "green" : "red",
                            }}
                          >
                            {row?.pLQuarter >= 0 ? (
                              <>{calculateAndFormat(row?.pLQuarter)}&nbsp;</>
                            ) : (
                              `(${calculateAndFormat(row?.pLQuarter * -1)})`
                            )}
                          </Tablecell>
                          <Tablecell
                            align="right"
                            style={{
                              paddingRight: "5px",

                              color: row?.pLYear >= 0 ? "green" : "red",
                            }}
                          >
                            {row?.pLYear >= 0 ? (
                              <>{calculateAndFormat(row?.pLYear)}&nbsp;</>
                            ) : (
                              `(${calculateAndFormat(row?.pLYear * -1)})`
                            )}
                          </Tablecell>
                        </TableRow>
                      ) : null
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>

        <PositionPeformance
          title="POSITIONS PERFORMANCE"
          isPositionPerformance={isPositionPerformance}
          setIsPositionPerformance={setIsPositionPerformance}
        />

        <PerformanceFundPopUp
          openPositionsPopup={isGridViewPositions}
          setopenPositionsPopup={setIsGridViewPositions}
          accFund={accFund}
          tablehead={positions}
          title="Longs and Shorts Summary"
        />
        <FundStrategyPopUp
          openPerformancePopup={isGridViewPerformance}
          setopenPerformancePopup={setIsGridViewPerformance}
          monitor={monitor}
          title="Funds and Strategies Performance"
        />
      </Box>
    </>
  );
};
export default VantageSystem;
