import React from "react";
import { useEffect } from "react";
import VantageDashboardLayout from "../component/Layouts/VantageDashboardLayout";
import Topbar from "../component/NavComponents/Topbar";
import { requestMarketData } from "../Redux/Actions/todoActions";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";

const VantageDashboard = () => {
  const dispatch = useDispatch();
  const { lots } = useSelector((state) => state?.todos);
  const threshold = localStorage.getItem("threshold");
  useEffect(() => {
    if (lots.length > 0) {
      dispatch(requestMarketData({ lots: lots, threshold: threshold }));
    }
  }, [lots]);

  return (
    <>
      <Helmet>
        <title>Vantage Portal</title>
      </Helmet>
      <Topbar title="Performace Dashboard" />
      <VantageDashboardLayout />
    </>
  );
};
export default VantageDashboard;
