import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Grid,
  Box,
  Table,
  TableRow,
  TableBody,
  useTheme,
  styled,
  TableCell,
} from "@mui/material";
import { formatValue } from "../Methods";

const Tablecell = styled(TableCell)(({ theme }) => ({
  fontSize: "12px",
  padding: "1px 2px 1px 5px",
}));
const BenchmarkPopUp = (props) => {
  const theme = useTheme();
  const {
    title,
    openBenchmarkPopup,
    setopenBenchmarkPopup,
    benchmarksWithMarketData,
    tablehead,
  } = props;

  const handleClose = () => {
    setopenBenchmarkPopup(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      open={openBenchmarkPopup}
      sx={{
        "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
          minWidth: "300px",
          marginLeft: "200px",
        },
      }}
    >
      <DialogTitle>
        <Typography
          variant="h6"
          component="div"
          align="left"
          style={{ flexGrow: 1, fontSize: "20px" }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Box
          sx={{
            border: `1px solid ${theme.palette.lightGrey.tableHeader}`,
            borderRadius: "4px",
          }}
        >
          <Grid item xs={6} md={6}>
            <Table size="small">
              <TableRow>
                {tablehead?.map((i, index) => {
                  return (
                    <Tablecell
                      key={index}
                      colSpan={2}
                      align="center"
                      sx={{
                        borderRight: `1px solid ${theme.palette.lightGrey.tableHeader}`,
                        color: theme.palette.lightGrey.textColor,
                        fontSize: theme.typography.bodyText12.fontSize,
                        fontWeight: theme.typography.bodyText12.fontWeight,
                      }}
                    >
                      {i}
                    </Tablecell>
                  );
                })}
              </TableRow>

              <TableBody>
                {benchmarksWithMarketData?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:hover": {
                        background: theme.palette.grey[200],
                        cursor: "pointer",
                      },
                    }}
                  >
                    <Tablecell
                      colSpan={2}
                      align="left"
                      sx={{
                        color: theme.palette.lightGrey.textColor,
                        fontSize: theme.typography.bodyText12.fontSize,
                        fontWeight: theme.typography.bodyText12.fontWeight,
                        borderRight: `1px solid ${theme.palette.lightGrey.tableHeader}`,
                      }}
                    >
                      {row?.name}
                    </Tablecell>

                    <Tablecell
                      align="left"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      {row.symbol1}
                    </Tablecell>

                    <Tablecell
                      align="right"
                      style={{
                        color: row.val1 >= 0 ? "green" : "red",
                      }}
                    >
                      {row.val1 >= 0 ? (
                        <>{formatValue(row.val1)}%&nbsp;</>
                      ) : (
                        `(${formatValue(row.val1)}%)`
                      )}
                    </Tablecell>

                    <Tablecell
                      align="left"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      {row.symbol2}
                    </Tablecell>

                    <Tablecell
                      align="right"
                      style={{
                        color: row.val2 >= 0 ? "green" : "red",
                      }}
                    >
                      {row.val2 >= 0 ? (
                        <>{row.val2}%&nbsp;</>
                      ) : (
                        `(${row.val2}%)`
                      )}
                    </Tablecell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Box>

        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "5px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              width: "10%",
              background: "#B2BEB5",
              color: "#000",
              "&:hover": { background: "#808080" },
            }}
            onClick={() => {
              setopenBenchmarkPopup(false);
            }}
          >
            CLOSE
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default BenchmarkPopUp;
