import Header from "../Header";
import CustodiansTable from "../CustodianDetails/CustodiansTable";
const TradeCustodians = () => {
  return (
    <>
      <Header>
        <CustodiansTable />
      </Header>
    </>
  );
};
export default TradeCustodians;
