import {
  Box,
  TableRow,
  TableCell,
  TableHead,
  useTheme,
  Typography,
} from "@mui/material";

const ListHead = ({
  order,
  orderBy,
  headLabel,
  onRequestSort,
  hideColumn,
  noStatus,
  title,
}) => {
  const theme = useTheme();

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const adjustedHeadLabel = hideColumn ? headLabel.slice(1) : headLabel;

  return (
    <TableHead>
      <TableRow sx={{ background: theme.palette.grey[700] }}>
        {!noStatus ? (
          <TableCell
            padding="checkbox"
            align="center"
            sx={{
              color: theme.palette.grey[100],
              background: "inherit",
              fontSize: "12px",
            }}
          >
            #
          </TableCell>
        ) : null}

        {adjustedHeadLabel?.map((headCell) => {
          if (hideColumn) {
            if (headCell.isActive) {
              return (
                <TableCell
                  key={headCell.id}
                  align="center"
                  sortDirection={orderBy === headCell.id ? order : false}
                  sx={{
                    color: theme.palette.grey[100],
                    background: "inherit",
                    fontSize: "11px",

                    padding: headCell.id === "tradefills" ? "0px" : "2px",
                    paddingLeft: headCell.id === "Actions" ? "10px" : "0px",
                    "&:hover": {
                      background: theme.palette.grey[600],
                      cursor: "pointer",
                    },
                  }}
                  onClick={createSortHandler(headCell.id)}
                >
                  <Box
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#fff !important",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      {headCell.label}
                    </Typography>
                  </Box>
                </TableCell>
              );
            }
          } else {
            return (
              <TableCell
                key={headCell.id}
                title={title ? headCell.title : ""}
                align="center"
                sortDirection={orderBy === headCell.id ? order : false}
                sx={{
                  color: theme.palette.grey[100],
                  background: "inherit",
                  fontSize: "11px",

                  borderLeft: "0.1px solid #dadada",
                  borderRight: "0.1px solid #dadada",
                  alignItems: "center",
                  "&:hover": {
                    background: theme.palette.grey[600],
                    cursor: "pointer",
                  },
                }}
              >
                <Box
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff !important",
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                  >
                    {headCell.label}
                  </Typography>
                </Box>
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};

export default ListHead;
