import { Box } from "@mui/material";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material";
import { net as rows } from "../../dummyData";

const Tablecell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
}));

const NetPosition = () => {
  return (
    <Box sx={{ border: "1px solid black" }}>
      <Table aria-label="a dense table" size="small">
        <TableHead sx={{ background: "#C4C4C4" }}>
          <TableRow>
            <Tablecell></Tablecell>
            <Tablecell>%AUM</Tablecell>
            <Tablecell></Tablecell>
            <Tablecell>PCM</Tablecell>
            <Tablecell>trades</Tablecell>
            <Tablecell>Cmn Stk</Tablecell>
            <Tablecell>options</Tablecell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": { background: "#DCFFD3" },
              }}
            >
              <Tablecell component="th" scope="row">
                {row.key}
              </Tablecell>
              <TableCell>{row.aum ? row.aum : " -"}</TableCell>
              <TableCell>{row.aumDecimal ? row.aumDecimal : "-"}</TableCell>
              <TableCell>{row.pcm ? row.pcm : "-"}</TableCell>
              <TableCell>{row.trades ? row.trades : "-"}</TableCell>
              <TableCell>{row.cmn ? row.cmn : "-"}</TableCell>
              <TableCell>{row.options ? row.options : "-"}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
export default NetPosition;
