import React from "react";
import TradePerformance from "../component/Layouts/TradePerformance";
import { useParams } from "react-router-dom";
import Topbar from "../component/NavComponents/Topbar";
import { Helmet } from "react-helmet";

const DailyPerformance = () => {
  return (
    <>
      <Helmet>
        <title>Daily Performance</title>
      </Helmet>
      <Topbar title="Daily Performance" />
      <TradePerformance />
    </>
  );
};
export default DailyPerformance;
