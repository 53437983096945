import Header from "../Header";
import RebalancerAllocation from "../RebalancerDetails/RebalancerAllocation";
const RebalancerLayout = () => {
  return (
    <>
      <Header>
        <RebalancerAllocation />
      </Header>
    </>
  );
};
export default RebalancerLayout;
