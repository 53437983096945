import * as React from "react";
import Box from "@mui/material/Box";
import "../../App.css";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import { useFormik } from "formik";
import { CustodianName } from "../../component/Schemas";
import { useSelector } from "react-redux";

const AddCustodian = ({ data, setOpenPopup, setData }) => {
  const { custodians } = useSelector((state) => state?.todos);
  const initialValues = {
    name: "",
    abbreviation: "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: CustodianName,

      onSubmit: (values) => {
        values.abbreviation = values.abbreviation.toLowerCase();
        if (custodians.find((a) => a.abbreviation === values.abbreviation)) {
          alert(`${values.abbreviation} already exists, try new one.`);
        } else {
          window.clientSocks.send(
            `{"action":"new_custodian", "data":${JSON.stringify({
              ...values,
            })}}`
          );
          setOpenPopup(false);
        }
      },
    });
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
      onSubmit={handleSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} marginBottom="5px">
          <TextField
            fullWidth
            id="name"
            label="Full Name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="standard"
          />
          {errors.name && touched.name ? (
            <p className="error">*{errors.name}</p>
          ) : null}
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            id="abbreviation"
            label="Abbreviation"
            value={values.abbreviation}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="standard"
          />
          {errors.abbreviation && touched.abbreviation ? (
            <p className="error">*{errors.abbreviation}</p>
          ) : null}
        </Grid>

        <Grid
          item
          xs={12}
          margin="10px"
          display="flex"
          justifyContent="space-between"
        >
          <Button variant="contained" sx={{ width: "30%" }} type="submit">
            SAVE
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "30%",
              background: "#B2BEB5",
              color: "#000",
              "&:hover": { background: "#808080" },
            }}
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            CANCEL
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
export default AddCustodian;
