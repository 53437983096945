import Header from "../Header";
import ManagementTable from "../RolesManagementDetails/ManagementTable";

const RolesManagementLayout = () => {
  return (
    <>
      <Header>
        <ManagementTable />
      </Header>
    </>
  );
};
export default RolesManagementLayout;
