import React, { useState, useEffect } from "react";
import TableCom from "./TableCom";
import {
  Box,
  FormControl,
  FormControlLabel,
  RadioGroup,
  NativeSelect,
  InputLabel,
  Radio,
  Grid,
  TextField,
  Button,
  FormHelperText,
  Autocomplete,
  Typography,
  useTheme,
} from "@mui/material";
import "../../../App.css";
import { useFormik } from "formik";
import { TradeName } from "../../Schemas";
import { useSelector, useDispatch } from "react-redux";
import { YahooFinance } from "../../../utils/YahooFinance";
import {
  order_Type,
  putsCalls,
  investArr,
  tradeArr,
  tradeArr2,
  durationEOD,
  EnumDurationTrades,
  hashTradeAction,
  hashTradePosition,
  hashTradeType,
  hashType,
  dataFormat,
  TradeInvestment,
  hashInvestment,
  BoxHold,
  convertToDateString,
  findStrategy,
} from "../../Methods";

import TableEqty from "./TableEqty";
import TradeDivider from "../../TradeDivider";
import dayjs from "dayjs";
import { setStrikePriceAndExpirationDate } from "../../../utils/yfinance";
import { updateLoading } from "../../../Redux/Reducers/todoReducers";
import { toast } from "react-toastify";

const OrderEdit = ({ setOpenPopup, order }) => {
  const {
    funds,
    data,
    lots,
    custodians,
    strategies,
    accountingData,
    routeList,
    globalContract,
    calculateAndFormatNoAbsInt,
  } = useSelector((state) => state?.todos);
  const { username } = useSelector((state) => state?.users?.user);
  const [investmenttype, setinvestmenttype] = useState(
    hashInvestment(order?.ticker?.investment)
  );

  const [tempDate, setDate] = useState(
    convertToDateString(order?.ticker?.expirationDate / 1000)
  );
  const [tempkillDate, setkillDate] = useState("");
  const [tempLots, setTempLots] = useState([]);
  const [AvgShow, setAvgShow] = useState(true);
  const [tempTarget, settempTarget] = useState();
  const [defaultRouter, setdefaultRouter] = useState("Default");
  const [tradeId, setTradeId] = useState(order?.tradeId);
  const [marketPrice, setMarketPrice] = useState();
  const [fundAum, setFundAum] = useState();
  const [Routing, setRouting] = useState(order.routing);
  const [strategy, setStrategy] = useState([]);
  const [selectFund, setSelectFund] = useState();
  const [autocompletedValue, setautoCompletedValue] = useState([]);
  const [totalPrice, setPrice] = useState();
  const [contractCheck, setContract] = useState();
  const [globalOpts, setGlobalOptions] = useState([]);
  const [contractError, setError] = useState();
  const [optionLots, setLots] = useState([]);
  const [equityLots, setEquity] = useState([]);
  const [account, setAccount] = useState({});
  const [accountValueError, setAccountValueError] = useState();
  const [InvestmentError, setInvestmentError] = useState();
  const [tickervalue, settickerValue] = React.useState("");
  const [optionsData, setOptionsData] = useState({});
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [fundStrategy, setfundStrategy] = useState("Default Strategy");
  const [brokername, setbrokername] = useState("Select Broker");
  const [fund, setfund] = useState("Funds");
  const dispatch = useDispatch();
  const theme = useTheme();

  //sumOf shareCounts from distribution
  const distribute = Object.entries(order?.distribution || {}).reduce(
    (acc, [key, value]) => {
      const [account, fund, _] = key.split("#");
      const strategySplit = key.split("#0#");
      const strategy = strategySplit.length > 1 ? strategySplit[1] : "";

      if (!acc[fund]) {
        acc[fund] = [];
      }

      acc[fund].push({
        shareCount: Number(value),
        account: account,
        strategy: findStrategy(strategy, strategies),
      });

      return acc;
    },
    {}
  );
  
  const firstDistributionKey = Object.keys(distribute)[0];
  const firstDistribution = distribute[firstDistributionKey];
  const orderStrategy = firstDistribution[0]?.strategy;
  const sumOfShareCount = firstDistribution.reduce(
    (sum, item) => sum + item.shareCount,
    0
  );

  useEffect(() => {
    const brokerName = data?.find((i) => {
      return i.brokerId == order?.broker;
    });
    setbrokername(brokerName?.name);

    const strategyForFund = strategies?.find((i) => {
      return i.strategyId == orderStrategy;
    });
    strategyForFund
      ? setfundStrategy(strategyForFund?.name)
      : setfundStrategy("Default Strategy");
  }, [order]);
  const findAum = (key) => {
    const aum = accountingData[0]?.funds?.find((i) => {
      return i.fund == key;
    });
    setFundAum(aum?.aum);
    return aum?.aum;
  };

  const findCus = (cust) => {
    findAum(cust.fundId);
    setSelectFund(cust);
    if (cust?.accounts && Object.keys(cust?.accounts).length) {
      const defaultKey = Object.keys(cust?.accounts).find(
        (key) => key === "default"
      );

      if (defaultKey) {
        setautoCompletedValue([defaultKey]);
        setAccount({
          [defaultKey]: values?.amount,
        });
      }
    }

    if (cust?.fundId) {
      const foundFund = accountingData[0]?.funds?.find(
        (row) => row?.fund === cust?.fundId
      );

      if (foundFund) {
        const strategyAUMsData = Object.keys(foundFund?.strategyAUMs || {})
          .filter((strategyId) =>
            strategies.some((strategy) => strategy?.strategyId === strategyId)
          )
          .map((strategyId) => {
            return {
              strategyId: strategyId,
              name: findStrategy(strategyId, strategies),
              aum: foundFund?.strategyAUMs[strategyId],
            };
          });

        if (strategyAUMsData?.length > 0) {
          setStrategy(strategyAUMsData);
        } else {
          setStrategy([]);
        }
      }

      // setStrategy(cust.strategies);
    }
    if (!cust?.accounts || !Object.keys(cust?.accounts).length) {
      setautoCompletedValue([]);
      setAccount({});
    }
  };

  useEffect(() => {
    if (
      distribute!= undefined &&
      Object.keys(distribute).length
    ) {
      const accountObject = firstDistribution?.reduce((acc, item) => {
        acc[item.account] = item.shareCount;
        return acc;
      }, {});

      let fundItem = funds?.find((i) => i.fundId === firstDistributionKey);
      if (fundItem) {
        findAum(fundItem.fundId);
        setfund(fundItem.name);
        setSelectFund(fundItem);

        if (fundItem?.accounts && Object.keys(fundItem?.accounts).length) {
          setautoCompletedValue(Object.keys(accountObject));
          setAccount(accountObject);
        }
        if (fundItem?.fundId) {
          const foundFund = accountingData[0]?.funds?.find(
            (row) => row?.fund === fundItem?.fundId
          );

          if (foundFund) {
            const strategyAUMsData = Object.keys(foundFund?.strategyAUMs || {})
              .filter((strategyId) =>
                strategies.some(
                  (strategy) => strategy?.strategyId === strategyId
                )
              )
              .map((strategyId) => {
                return {
                  strategyId: strategyId,
                  name: findStrategy(strategyId, strategies),
                  aum: foundFund?.strategyAUMs[strategyId],
                };
              });

            if (strategyAUMsData?.length > 0) {
              setStrategy(strategyAUMsData);
            } else {
              setStrategy([]);
            }
          }
        }
        // if (
        //   fundItem?.strategies &&
        //   fundItem?.strategies.length > 0 &&
        //   strategies
        // ) {
        //   setStrategy(fundItem.strategies);
        // }

        if (!fundItem?.accounts || !Object.keys(fundItem?.accounts).length) {
          setautoCompletedValue([]);
          setAccount({});
        }
      }
    }
  }, [order]);

  useEffect(() => {
    if (globalContract) {
      setPrice(globalContract?.prices);
      setContract(globalContract?.sharecounts);
      setTempLots(globalContract?.myLots);
    }
  }, [globalContract]);

  const initialValues = {
    fund: firstDistributionKey.length > 0 ? firstDistributionKey : "",
    strategy: orderStrategy ? orderStrategy : "",
    investment: order?.ticker?.investment,
    broker: order?.broker,
    Tradetype: hashTradeType(order?.action, order?.position),
    ticker: order?.ticker?.symbol,
    targetAmount: "",
    amount: sumOfShareCount,
    instructions: order?.instructions,
    avgPosition: "",
    strikePrice: order?.ticker?.strikePrice,
    putCalls: order?.ticker?.option,
    expiration: order?.ticker?.expirationDate
      ? dayjs(order.ticker.expirationDate / 1000)
      : null,
    type: order?.type,
    typePrice: order?.typePrice,
    duration: 0,
    killDate: "",
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: TradeName,

    onSubmit: (values) => {
      const filteredValues = values;
      if (
        !data?.data?.managers &&
        !data?.data?.managers.length > 0 &&
        filteredValues.strategy == ""
      ) {
        toast.error("Sorry, Trade can not be made without Strategy");
        return;
      }
      if (
        filteredValues.investment != TradeInvestment.Equity &&
        filteredValues.investment != TradeInvestment.Options
      ) {
        setInvestmentError("Trades can be made for Equity and Option.");
        return;
      }
      if (autocompletedValue.length === 0) {
        setAccountValueError("");
        return;
      }
      var filteredAccount;
      if (values.fund && values.fund !== "") {
        let custodianAccountSum = 0;
        filteredAccount = Object.keys(account).reduce((acc, key) => {
          if (
            account[key] !== "" &&
            account[key] !== undefined &&
            account[key] !== "undefined" &&
            account[key] !== 0
          ) {
            acc[key] = account[key];
          }
          return acc;
        }, {});

        if (Object.values(filteredAccount).length) {
          custodianAccountSum = Object.values(filteredAccount).reduce(
            (a, b) => Number(a) + Number(b)
          );

          if (Number(filteredValues.amount) != Number(custodianAccountSum)) {
            setAccountValueError("Amount must be equal to sharecount");
            return;
          }
        } else {
          setAccountValueError("Account cannot be empty");
          return;
        }
      }

      const EqytQuery = {
        distribution: {
          [filteredValues.fund]: Object.entries(filteredAccount).map(
            ([key, value]) => ({
              shareCount: Number(value),
              account: `${key}`,
              ...(filteredValues.strategy
                ? { strategy: filteredValues.strategy }
                : {}),
            })
          ),
        },
        action: hashTradeAction(Number(filteredValues.Tradetype)),
        position: hashTradePosition(Number(filteredValues.Tradetype)),
        broker: filteredValues.broker,
        type: Number(filteredValues.type),
        typePrice: Number(filteredValues.typePrice),
        instructions: filteredValues.instructions,
        routing: Routing,
        ticker: {
          symbol: filteredValues.ticker,
          investment: filteredValues.investment,
          exchange: 4,
          currency: 7,
          underlying: {
            symbol: filteredValues.ticker,
            investment: 4,
            exchange: 4,
            currency: 7,
          },
        },
        user: username,
      };
      if (
        (filteredValues.Tradetype == 1 || filteredValues.Tradetype == 3) &&
        filteredValues.investment == TradeInvestment.Equity &&
        Number(filteredValues.ticker) >= 0 &&
        !contractError
      ) {
        const obj = equityLots[Number(filteredValues.ticker)];
        EqytQuery.ticker.symbol = obj?.ticker?.symbol;
        delete EqytQuery.ticker.underlying;
        window.clientSocks.send(
          `{"action":"modify_orders", "data":[{"order":${JSON.stringify(
            EqytQuery
          )},
              "tradeid":${JSON.stringify(tradeId)}
            }]}`
        );
        setOpenPopup(false);
      } else if (
        (filteredValues.Tradetype == 0 || filteredValues.Tradetype == 2) &&
        filteredValues.investment == TradeInvestment.Equity &&
        filteredValues.ticker != undefined
      ) {
        delete EqytQuery.ticker.underlying;
        window.clientSocks.send(
          `{"action":"modify_orders", "data":[{"order": ${JSON.stringify(
            EqytQuery
          )},
              "tradeid":${JSON.stringify(tradeId)}
          }]}`
        );
        setOpenPopup(false);
      } else if (
        (filteredValues.Tradetype == 1 || filteredValues.Tradetype == 3) &&
        filteredValues.investment == TradeInvestment.Options &&
        Number(filteredValues.ticker) >= 0 &&
        !contractError
      ) {
        const { symbol, option, strikePrice, expirationDate } =
          optionLots[Number(filteredValues.ticker)];
        EqytQuery.ticker.symbol = symbol;
        EqytQuery.ticker.strikeprice = strikePrice;
        EqytQuery.ticker.expirationdate = expirationDate * 1000;
        EqytQuery.ticker.option = option;
        EqytQuery.ticker.underlying.symbol = symbol;
        window.clientSocks.send(
          `{"action":"modify_orders", "data":[{"order": ${JSON.stringify(
            EqytQuery
          )},
              "tradeid":${JSON.stringify(tradeId)}
          }]}`
        );

        setOpenPopup(false);
      } else if (
        (filteredValues.Tradetype == 0 || filteredValues.Tradetype == 2) &&
        filteredValues.investment == TradeInvestment.Options &&
        filteredValues.ticker != undefined
      ) {
        EqytQuery.ticker.symbol = filteredValues.ticker;
        EqytQuery.ticker.strikeprice = Number(filteredValues.strikePrice);
        EqytQuery.ticker.expirationdate = Number(
          filteredValues.expiration * 1000
        );
        EqytQuery.ticker.option = Number(filteredValues.putCalls);
        EqytQuery.ticker.underlying.symbol = filteredValues.ticker;

        window.clientSocks.send(
          `{"action":"modify_orders", "data":[{"order": ${JSON.stringify(
            EqytQuery
          )},
              "tradeid":${JSON.stringify(tradeId)}
          }]}`
        );
        setOpenPopup(false);
      }
    },
  });
  useEffect(() => {
    if (lots) {
      let tickerOption = [];
      if (values.fund) {
        if (values.strategy) {
          tickerOption = lots.filter(
            (obj) =>
              obj?.ticker?.investment == TradeInvestment.Options &&
              obj.fund == values.fund &&
              obj.strategy == values.strategy
          );
        } else {
          tickerOption = lots.filter(
            (obj) =>
              obj?.ticker?.investment == TradeInvestment.Options &&
              obj.fund == values.fund
          );
        }
      } else {
        tickerOption = lots.filter(
          (obj) => obj?.ticker?.investment == TradeInvestment.Options
        );
      }

      const newlots = {};
      const negLots = [];
      const posLots = [];

      tickerOption.forEach((options) => {
        const { symbol, option, strikePrice, expirationDate } = options.ticker;
        const key = `${(symbol, option, strikePrice, expirationDate)}_${
          options?.shareCount >= 0
        }`;
        if (newlots[key]) return;
        if (options?.shareCount < 0) negLots.push(options.ticker);
        if (options?.shareCount > 0) posLots.push(options.ticker);
      });
      const sortedPos = posLots.sort((a, b) => a.symbol > b.symbol);
      const sortedNeg = negLots.sort((a, b) => a.symbol > b.symbol);
      if (
        optionsDropdown[values.Tradetype].key == "Buy to open" ||
        optionsDropdown[values.Tradetype].key == "Sell to close"
      ) {
        setLots(sortedPos);
      } else if (
        optionsDropdown[values.Tradetype].key == "Sell to open" ||
        optionsDropdown[values.Tradetype].key == "Buy to close"
      ) {
        setLots(sortedNeg);
      } else {
        setLots([]);
      }
    }
  }, [lots, values.strategy, values.fund, values.Tradetype]);

  useEffect(() => {
    if (lots) {
      let tickerEquity = [];
      if (values.fund) {
        if (values.strategy) {
          tickerEquity = lots.filter(
            (obj) =>
              obj?.ticker?.investment == TradeInvestment.Equity &&
              obj.fund == values.fund &&
              obj.strategy == values.strategy
          );
        } else {
          tickerEquity = lots.filter(
            (obj) =>
              obj?.ticker?.investment == TradeInvestment.Equity &&
              obj.fund == values.fund
          );
        }
      } else {
        tickerEquity = lots.filter(
          (obj) => obj?.ticker?.investment == TradeInvestment.Equity
        );
      }
      const newlots = {};
      const negEqytLots = [];
      const posEqytLots = [];
      tickerEquity.forEach((equity) => {
        const { symbol } = equity.ticker;
        const key = `${symbol}_${equity?.shareCount >= 0}`;
        if (newlots[key]) return;
        newlots[key] = true;
        if (equity?.shareCount < 0) negEqytLots.push(equity);
        if (equity?.shareCount > 0) posEqytLots.push(equity);
      });
      const sortedEqytPos = posEqytLots.sort(
        (a, b) => a?.ticker?.symbol > b?.ticker?.symbol
      );
      const sortedEqytNeg = negEqytLots.sort(
        (a, b) => a?.ticker?.symbol > b?.ticker?.symbol
      );

      if (
        optionsDropdown[values.Tradetype].key == "Buy" ||
        optionsDropdown[values.Tradetype].key == "Sell"
      ) {
        setEquity(sortedEqytPos);
      } else if (
        optionsDropdown[values.Tradetype].key == "Short" ||
        optionsDropdown[values.Tradetype].key == "Cover"
      ) {
        setEquity(sortedEqytNeg);
      } else {
        setEquity([]);
      }
    }
  }, [lots, values.strategy, values.fund, values.Tradetype]);

  useEffect(() => {
    if (lots) {
      let option = "";
      if (values.strategy) {
        const strategyFilter = lots?.filter(
          (i) => i.strategy == values.strategy
        );
        option = strategyFilter?.map((i) => i.ticker.symbol);
      } else {
        option = lots?.map((i) => i.ticker.symbol);
      }

      const sorted = option.sort((a, b) => {
        return a > b;
      });
      const uniqueSymbols = [...new Set(sorted?.map((val) => val))];

      setGlobalOptions(uniqueSymbols.sort());
    }
  }, [lots, values.strategy]);

  const myfunct = () => {
    if (
      values.Tradetype == 1 ||
      (values.Tradetype == 3 && values.investment == TradeInvestment.Equity)
    ) {
      equityLots?.forEach((u, index) => {
        if (u?.ticker?.symbol == order?.ticker?.symbol) {
          setFieldValue("ticker", index);
          settickerValue(u?.ticker?.symbol);
          FindContracts(u, values.Tradetype);
        }
      });
    }

    if (
      values.Tradetype == 1 ||
      (values.Tradetype == 3 && values.investment == TradeInvestment.Options)
    ) {
      optionLots?.forEach((u, index) => {
        const { expirationDate, option, symbol, strikePrice } = order?.ticker;
        if (
          u?.expirationDate * 1000 + u?.option + u?.symbol + u?.strikePrice ==
          expirationDate + option + symbol + strikePrice
        ) {
          settickerValue(
            dataFormat(u?.symbol, u?.expirationDate, u?.strikePrice, u?.option)
          );
          setFieldValue(
            "ticker",
            dataFormat(u?.symbol, u?.expirationDate, u?.strikePrice, u?.option)
          );
          FindContracts(u, values.Tradetype);
        }
      });
    }
  };
  const findLot = (symbol) => {
    var totals = 0;
    const ticker = lots
      ?.filter((e) => e.ticker.symbol === symbol)
      ?.map((i) => (totals += i?.shareCount));
    totals < 0 ? setContract(Math.abs(totals)) : setContract(totals);
  };

  const FindContracts = (obj, Trade) => {
    if (lots && obj && Object.keys(obj).length > 0 && Trade) {
      var totals = contractCheck;
      var price = 0;
      var tickers = [];
      if (Trade == 1) {
        tickers = lots?.filter((i) => {
          return (
            i?.ticker?.investment == obj?.ticker?.investment && i.shareCount > 0
          );
        });
      } else if (Trade == 3) {
        tickers = lots?.filter((i) => {
          return (
            i?.ticker?.investment == obj?.ticker?.investment && i.shareCount < 0
          );
        });
      }

      if (obj?.ticker?.investment == TradeInvestment.Equity) {
        const newlots = obj?.ticker?.symbol;
        tickers.forEach((equity) => {
          const { symbol } = equity?.ticker;
          if (newlots == symbol) {
            price = equity?.sharePrice;
          }
        });
        if (totals != "" && totals != undefined && values.amount) {
          const avgCal = (values.amount / totals) * 100;
          const trgamt = (values.amount * marketPrice * 100) / fundAum;
          isNaN(avgCal)
            ? setFieldValue("avgPosition", 0)
            : setFieldValue("avgPosition", Math.round(avgCal));
          isNaN(trgamt)
            ? setFieldValue("targetAmount", 0)
            : setFieldValue("targetAmount", trgamt?.toFixed(2));
        }
      }
      if (obj?.ticker?.investment == TradeInvestment.Options) {
        const newlots =
          obj?.symbol + obj?.option + obj?.strikePrice + obj?.expirationDate;
        tickers.forEach((options) => {
          const { symbol, option, strikePrice, expirationDate } =
            options.ticker;
          const textKey = symbol + option + strikePrice + expirationDate;
          if (newlots == textKey) {
            price = options?.sharePrice;
          }
        });
      }
      setPrice(price);
      values.type == 0 && price
        ? setFieldValue("typePrice", price)
        : setFieldValue("typePrice", 0);
    }
  };

  useEffect(() => {
    if (
      order?.ticker?.investment == TradeInvestment.Options &&
      (values.Tradetype == 0 || values.Tradetype == 2) &&
      order?.ticker.symbol != undefined
    ) {
      const fetchRes = async () => {
        var res = await setStrikePriceAndExpirationDate(values.ticker);
        if (res.strikePrice && res.expirationDate) {
          setOptionsData(res);
        }
      };
      fetchRes();
    }
  }, [order]);

  const optionsDropdown =
    values.investment == TradeInvestment.Options ? tradeArr2 : tradeArr;

  const checkTestKeys = () => {
    if (selectFund && selectFund.accounts) {
      return Object.keys(selectFund?.accounts);
    }
  };
  const showCustodian = (acc) => {
    if (selectFund && selectFund.accounts) {
      const custodian = selectFund?.accounts[acc]?.custodian;
      const findCust = custodians?.find((i) => {
        return i.custodianId == custodian;
      });
      return findCust?.name;
    }
  };
  useEffect(() => {
    myfunct();
  }, [order, equityLots, optionLots]);

  useEffect(() => {
    var totals = 0;
    lots
      ?.filter((e) => e.ticker.symbol === order?.ticker?.symbol)
      ?.map((i) => (totals += i?.shareCount));
    const avg = Math.abs((order.amount / totals) * 100);
    if (avg != "Infinity") {
      setFieldValue("avgPosition", Math.round(avg));
    }

    if (
      order.amount &&
      order.ticker.symbol &&
      Object.keys(order.distribution)
    ) {
      const aum = findAum(Object.keys(order.distribution)[0]);
      YahooFinance(order.ticker.symbol)
        .then((result) => {
          if (result?.data.body[0]?.regularMarketPrice) {
            setMarketPrice(result?.data.body[0]?.regularMarketPrice);
            setAvgShow(true);

            const trgamt =
              ((result?.data.GlobalQuote?.price * order.amount) / aum) * 100;
            setFieldValue("targetAmount", trgamt?.toFixed(2));
            // if (contractCheck && marketPrice && fundAum) {
            //   const trgamt = ((contractCheck * marketPrice) / fundAum) * 100;

            //   setFieldValue("targetAmount", isNaN(trgamt) ? 0 : trgamt.toFixed(2));
            //   settempTarget(trgamt);
            // }
            //calculation for target for sell/cover
          } else {
            setMarketPrice();
            setAvgShow(false);
          }
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    }
  }, [order]);
  //Calculate target for Sell Cover
  useEffect(() => {
    if (
      (optionsDropdown[values.Tradetype].key == "Sell" ||
        optionsDropdown[values.Tradetype].key == "Cover") &&
      contractCheck &&
      marketPrice &&
      fundAum
    ) {
      setFieldValue("avgPosition", 0);
      setFieldValue("amount", 0);
      const trgamt = ((contractCheck * marketPrice) / fundAum) * 100;
      setFieldValue("targetAmount", isNaN(trgamt) ? 0 : trgamt.toFixed(2));
      settempTarget(trgamt);
    } else {
      settempTarget("");
    }
  }, [values.Tradetype, contractCheck, marketPrice, fundAum]);

  const highlyMatched = (event, value) => {
    const input = event.target;
    const inputValue = input.value;
    const firstOption = globalOpts[0];
    if (inputValue && firstOption && firstOption.startsWith(inputValue)) {
      const matchedPart = firstOption.slice(inputValue.length);
      const coloredValue = inputValue + matchedPart;
      input.style.color = theme.palette.primary.light;
      input.value = coloredValue;
    } else {
      input.style.backgroundColor = "";
      input.style.color = "";
    }
  };
  const filterOptions = (options, state) => {
    if (state.inputValue.length > 0) {
      return options.filter((item) =>
        String(item).toLowerCase().startsWith(state.inputValue.toLowerCase())
      );
    }
    return options;
  };
  const handleKeyDown = (event) => {
    if (event.key === "Tab" && values.ticker && highlightedIndex > -1) {
      event.preventDefault();
    }
  };

  const handleHighlightChange = (event, index) => {
    setHighlightedIndex(index);
  };
  const EquityTickerlist = equityLots
    ?.map((val) => val?.ticker?.symbol)
    ?.sort();

  const brokerlist = data?.map((val) => val.name)?.sort();
  const fundslist = funds?.map((val) => val.name)?.sort();
  const strategylist = strategy?.map((val) => val?.name)?.sort();
  const routelist = routeList?.map((val) => val?.abbreviation)?.sort();
  const investmentlist = investArr?.map((val) => val)?.sort();
  const optionTickerlist = optionLots
    ?.map((val) =>
      dataFormat(
        val?.symbol,
        val?.expirationDate,
        val?.strikePrice,
        val?.option
      )
    )
    ?.sort();

  const calculateCostPerCustodian = (sharecount, tempLots) => {
    if (sharecount && tempLots && tempLots.length > 0 && values.fund) {
      const defaultFund = funds?.find((e) => e.fundId === values.fund);

      if (tempLots.length === 1) {
        const lot = tempLots[0];
        const tempCustId = lot?.custodian;
        const matchedCustodian = Object.keys(defaultFund?.accounts || {}).find(
          (key) => defaultFund.accounts[key].custodian === tempCustId
        );
        if (matchedCustodian) {
          setautoCompletedValue([matchedCustodian]);
          setAccount({
            [matchedCustodian]: sharecount,
          });
        }
      } else {
        const sortedLots = [...tempLots].sort(
          (a, b) => b.sharePrice - a.sharePrice
        );
        let remainingSharecount = sharecount;
        const matchedCostCustodian = sortedLots[0]?.custodian;
        const highestCostCustodian = Object.keys(
          defaultFund?.accounts || {}
        ).find(
          (key) => defaultFund.accounts[key].custodian === matchedCostCustodian
        );

        if (highestCostCustodian) {
          const allocation = Math.min(remainingSharecount, sharecount);
          setAccount({
            [highestCostCustodian]: allocation,
          });
          setautoCompletedValue([highestCostCustodian]);
          remainingSharecount -= allocation;
        }

        if (remainingSharecount > 0) {
          const additionalAutoCompletedValues = sortedLots
            .slice(1) // Exclude the custodian with the highest cost
            .map((lot) => lot.custodian);

          setautoCompletedValue([
            highestCostCustodian,
            ...additionalAutoCompletedValues,
          ]);
        }
      }
    } else {
      setautoCompletedValue([]);
      setAccount({});
    }
  };

  const emptyFields = (noTemp) => {
    if (noTemp) {
      settempTarget("");
      setMarketPrice("");
      setContract("");
      setautoCompletedValue([]);
      setAccount({});
    }

    setFieldValue("ticker", "");
    settickerValue("");
    setFieldValue("amount", "");
    setFieldValue("avgPosition", "");
    setFieldValue("targetAmount", "");
    setFieldValue("broker", "");
    setbrokername("Select Broker");
  };
  return (
    <>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
        onSubmit={handleSubmit}
      >
        <Grid container spacing={1}>
          <Box
            sx={{
              width: "100%",
              background: "rgb(242 242 242)",
              borderRadius: "4px",
              margin: "0px 5px",
              padding: "0px 5px 5px 5px",
            }}
          >
            <Grid container>
              <Grid item xs={3} mt={1}>
                <InputLabel sx={{ fontSize: "14px" }}>Fund</InputLabel>
              </Grid>
              <Grid item xs={9}>
                <FormControl fullWidth>
                  <Autocomplete
                    disableClearable
                    value={fund}
                    ListboxProps={{
                      sx: { fontSize: 12 },
                    }}
                    sx={{
                      "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
                        fontSize: 12,
                      },
                    }}
                    onChange={(e, newValue) => {
                      setfund(newValue);
                      emptyFields(true);
                      var item = funds?.find((e) => {
                        return e.name == newValue;
                      });

                      if (item) {
                        findAum(item.fundId);
                        setFieldValue("fund", item?.fundId);
                        setFieldValue("strategy", "");
                        setfundStrategy("Default Strategy");
                        setStrategy([]);
                        setfundStrategy("");
                        setautoCompletedValue([]);
                        setAccount({});
                        findCus(item);
                      }
                    }}
                    onBlur={handleBlur}
                    id="fund"
                    name="fund"
                    options={fundslist}
                    filterOptions={(fundslist, state) =>
                      filterOptions(fundslist, state)
                    }
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" value={fund} />
                    )}
                  />
                </FormControl>
              </Grid>
              {strategy && strategy.length > 0 ? (
                <>
                  <Grid item xs={3} mt={1}>
                    <InputLabel sx={{ fontSize: "14px" }}>Strategy</InputLabel>
                  </Grid>
                  <Grid item xs={9}>
                    <FormControl fullWidth>
                      <Autocomplete
                        disableClearable
                        autoSelect
                        autoHighlight
                        autoComplete
                        disabled={values.fund ? false : true}
                        value={fundStrategy}
                        ListboxProps={{
                          sx: { fontSize: 12 },
                        }}
                        sx={{
                          "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
                            fontSize: 12,
                          },
                        }}
                        onChange={(e, newValue) => {
                          setfundStrategy(newValue);
                          emptyFields(true);
                          const strategyFund = strategies?.find((i) => {
                            return i.name == newValue;
                          });
                          setFieldValue("strategy", strategyFund?.strategyId);
                        }}
                        onBlur={(e) => {
                          if (!e.target.value) {
                            setfundStrategy("Default Strategy");
                            setFieldValue("strategy", "");
                          }
                        }}
                        options={strategylist}
                        filterOptions={(strategylist, state) =>
                          filterOptions(strategylist, state)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id="strategy"
                            name="strategy"
                            variant="standard"
                            value={fundStrategy}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </>
              ) : null}
            </Grid>
          </Box>
          <Box
            sx={{
              width: "100%",
              padding: "0px 5px",
              margin: "0px 5px",
            }}
          >
            <Grid container>
              <Grid item xs={3} mt={1}>
                <InputLabel sx={{ fontSize: "14px" }}>Asset Type</InputLabel>
              </Grid>
              <Grid item xs={9}>
                <FormControl fullWidth>
                  <Autocomplete
                    disableClearable
                    autoSelect
                    autoHighlight
                    autoComplete
                    value={investmenttype}
                    ListboxProps={{
                      sx: { fontSize: 12 },
                    }}
                    sx={{
                      "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
                        fontSize: 12,
                      },
                    }}
                    onChange={(e, newValue) => {
                      const tickerindex = investArr.findIndex(
                        (e) => e === newValue
                      );
                      setinvestmenttype(newValue);
                      emptyFields(true);
                      setFieldValue("investment", tickerindex);
                      setFieldValue("Tradetype", 0);
                      if (
                        tickerindex !== TradeInvestment.Equity &&
                        tickerindex !== TradeInvestment.Options
                      ) {
                        setInvestmentError(
                          "Trades can be made for Equity and Option."
                        );
                      } else {
                        setInvestmentError("");
                      }
                    }}
                    onBlur={handleBlur}
                    id="investment"
                    name="investment"
                    options={investmentlist}
                    filterOptions={(investmentlist, state) =>
                      filterOptions(investmentlist, state)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        value={investmenttype}
                      />
                    )}
                  />
                </FormControl>

                {InvestmentError && (
                  <FormHelperText error>{InvestmentError}</FormHelperText>
                )}
              </Grid>
            </Grid>
          </Box>

          {values.investment == TradeInvestment.Options ? (
            <>
              <BoxHold>
                <Grid container>
                  <Grid item xs={3} mt={1}>
                    <InputLabel sx={{ fontSize: "14px" }}>Action</InputLabel>
                  </Grid>
                  <Grid item xs={9}>
                    <FormControl fullWidth>
                      <NativeSelect
                        value={values.Tradetype}
                        onChange={(e) => {
                          setFieldValue("ticker", "");
                          settickerValue("");
                          setFieldValue("strikePrice", "");
                          setFieldValue("expiration", "");
                          setFieldValue("putCalls", "");
                          setFieldValue("amount", "");
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        autoSelect
                        sx={{ fontSize: "12px" }}
                        id="Tradetype"
                        name="Tradetype"
                      >
                        {tradeArr2?.map((val) => {
                          return <option value={val.value}>{val.key}</option>;
                        })}
                      </NativeSelect>
                    </FormControl>
                  </Grid>

                  {optionsDropdown[values.Tradetype].key == "Buy to open" ||
                  optionsDropdown[values.Tradetype].key == "Sell to open" ? (
                    <>
                      <Grid item xs={3} mt={1}>
                        <InputLabel sx={{ fontSize: "14px" }}>
                          Ticker
                        </InputLabel>
                      </Grid>
                      <Grid item xs={9}>
                        <Autocomplete
                          id="ticker"
                          name="ticker"
                          disableClearable
                          autoSelect
                          autoHighlight
                          autoComplete
                          freeSolo
                          ListboxProps={{
                            sx: { fontSize: 12 },
                          }}
                          sx={{
                            "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
                              fontSize: 12,
                            },
                          }}
                          value={values.ticker}
                          onChange={async (e, newVal) => {
                            setFieldValue("ticker", newVal);
                            setFieldValue("strikePrice", "");
                            setFieldValue("expiration", "");
                            setFieldValue("putCalls", "");

                            dispatch(updateLoading(true));
                            if (newVal)
                              var res = await setStrikePriceAndExpirationDate(
                                newVal
                              );
                            dispatch(updateLoading(false));
                            if (res.strikePrice && res.expirationDate) {
                              setOptionsData(res);
                            } else if (
                              !res.strikePrice &&
                              !res.expirationDate
                            ) {
                              alert(
                                `${newVal} does not exist. please type another ticker.`
                              );

                              setOptionsData([]);
                            }
                          }}
                          onBlur={handleBlur}
                          options={globalOpts}
                          filterOptions={(globalOpts, state) =>
                            filterOptions(globalOpts, state)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              onChange={highlyMatched}
                              value={values.ticker}
                            />
                          )}
                        />
                        {errors.ticker && !values.ticker && touched.ticker ? (
                          <p className="error">*{errors.ticker}</p>
                        ) : null}
                      </Grid>
                      <Grid item xs={3} mt={1}>
                        <InputLabel sx={{ fontSize: "14px" }}>
                          Expiration
                        </InputLabel>
                      </Grid>
                      <Grid item xs={9}>
                        <FormControl fullWidth>
                          <TextField
                            id="date"
                            name="date"
                            type="date"
                            variant="standard"
                            value={tempDate}
                            inputProps={{ style: { fontSize: 12 } }}
                            onChange={(e) => {
                              const inputDate = e.target.value;
                              setDate(inputDate);
                              if (inputDate) {
                                const parsedDate = new Date(inputDate);
                                const formattedDate = parsedDate
                                  .toISOString()
                                  .slice(0, 10);
                                const unixTimestamp =
                                  Date.parse(formattedDate) / 1000;
                                setFieldValue("expiration", unixTimestamp);
                              } else {
                                setFieldValue("expiration", "");
                              }
                              //handleChange(e);
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disableClearable
                          />
                          {/* <NativeSelect
                            id="expiration"
                            name="expiration"
                            value={values.expiration}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autoSelect
                            variant="standard"
                            sx={{ fontSize: "12px" }}
                          >
                            <option value="" selected>
                              Select Expiration Date
                            </option>
                            {optionsData?.expirationDate?.map((item, i) => {
                              const formattedDate = unixTimestampToDate(item);
                              return (
                                <option value={item}>{formattedDate}</option>
                              );
                            })}
                          </NativeSelect> */}
                          {errors.expiration && touched.expiration ? (
                            <p className="error">*{errors.expiration}</p>
                          ) : null}
                        </FormControl>
                      </Grid>

                      <Grid item xs={3} mt={1}>
                        <InputLabel sx={{ fontSize: "14px" }}>
                          StrikePrice
                        </InputLabel>
                      </Grid>
                      <Grid item xs={9}>
                        <FormControl fullWidth>
                          {/* <NativeSelect
                            id="strikePrice"
                            name="strikePrice"
                            value={values.strikePrice}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autoSelect
                            variant="standard"
                            sx={{ fontSize: "12px" }}
                          >
                            <option value="" selected>
                              Select Strike Price
                            </option>
                            {optionsData?.strikePrice?.map((item, i) => {
                              return <option value={item}>{item}</option>;
                            })}
                          </NativeSelect> */}
                          <Autocomplete
                            id="strikePrice"
                            name="strikePrice"
                            disableClearable
                            autoSelect
                            autoHighlight
                            autoComplete
                            freeSolo
                            ListboxProps={{
                              sx: { fontSize: 12 },
                            }}
                            sx={{
                              "& .MuiAutocomplete-input, & .MuiInputLabel-root":
                                {
                                  fontSize: 12,
                                },
                            }}
                            value={values.strikePrice}
                            onChange={(e, newVal) => {
                              setFieldValue("strikePrice", newVal);
                            }}
                            onBlur={handleBlur}
                            options={
                              optionsData?.strikePrice?.map((item) => item) ||
                              []
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                onChange={highlyMatched}
                                value={values.strikePrice}
                              />
                            )}
                          />
                          {errors.strikePrice != "" && touched.strikePrice ? (
                            <p className="error">{errors.strikePrice}</p>
                          ) : null}
                        </FormControl>
                      </Grid>

                      <Grid item xs={3} mt={1}>
                        <InputLabel sx={{ fontSize: "14px" }}>
                          Puts/Calls
                        </InputLabel>
                      </Grid>
                      <Grid item xs={9}>
                        <FormControl fullWidth>
                          <NativeSelect
                            id="putCalls"
                            name="putCalls"
                            value={values.putCalls}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autoSelect
                            variant="standard"
                            sx={{ fontSize: "12px" }}
                          >
                            <option value="" selected>
                              Puts or Calls
                            </option>
                            {putsCalls.map((item, i) => {
                              return <option value={i}>{item}</option>;
                            })}
                          </NativeSelect>
                          {errors.putCalls && touched.putCalls ? (
                            <p className="error">*{errors.putCalls}</p>
                          ) : null}
                        </FormControl>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={3} mt={1}>
                        <InputLabel sx={{ fontSize: "14px" }}>
                          Ticker
                        </InputLabel>
                      </Grid>
                      <Grid item xs={9}>
                        <FormControl fullWidth>
                          <Autocomplete
                            disableCloseOnSelec
                            autoSelect
                            autoHighlight
                            autoComplete
                            value={tickervalue}
                            ListboxProps={{
                              sx: { fontSize: 12 },
                            }}
                            sx={{
                              "& .MuiAutocomplete-input, & .MuiInputLabel-root":
                                {
                                  fontSize: 12,
                                },
                            }}
                            onChange={(e, newValue) => {
                              setFieldValue("amount", "");
                              setError();
                              settickerValue(newValue);
                              if (newValue != undefined || newValue != "") {
                                const tickerindex = optionLots.findIndex(
                                  (e) => e.symbol === newValue.split(" ")[0]
                                );
                                setFieldValue("ticker", tickerindex);
                                const option = optionLots[tickerindex];
                                FindContracts(option, values.Tradetype);
                                setFieldValue(
                                  "strikePrice",
                                  newValue?.strikePrice
                                );
                                setFieldValue(
                                  "expiration",
                                  dayjs(newValue?.expirationDate)
                                );
                                setFieldValue("putCalls", newValue?.option);
                              }
                            }}
                            onBlur={handleBlur}
                            options={optionTickerlist}
                            filterOptions={(optionTickerlist, state) =>
                              filterOptions(optionTickerlist, state)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                value={tickervalue}
                              />
                            )}
                          />
                        </FormControl>
                        {errors.ticker && !values.ticker && touched.ticker ? (
                          <p className="error">*{errors.ticker}</p>
                        ) : null}
                      </Grid>
                      <Grid xs={12}>
                        {values.ticker != undefined &&
                        (values.ticker || values.ticker === 0) &&
                        optionsDropdown[values.Tradetype].key ? (
                          <TableCom
                            tickers={
                              Number(values.ticker) >= 0
                                ? optionLots[Number(values.ticker)]
                                : {}
                            }
                            fund={values.fund}
                            strategy={values.strategy}
                            check={optionsDropdown[values.Tradetype].key}
                          />
                        ) : null}
                      </Grid>
                    </>
                  )}
                </Grid>
              </BoxHold>
              <Box
                sx={{
                  width: "100%",
                  padding: "0px 5px",
                  margin: "0px 5px",
                }}
              >
                <Grid container>
                  <Grid item xs={3} mt={1}>
                    <InputLabel sx={{ fontSize: "14px" }}>Contracts</InputLabel>
                  </Grid>

                  <Grid item xs={9}>
                    <TextField
                      fullWidth
                      id="amount"
                      name="amount"
                      inputProps={{
                        style: { fontSize: 12 },
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                      }}
                      value={values.amount}
                      onChange={(e) => {
                        const sanitizedValue = e.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                        setFieldValue("amount", sanitizedValue);

                        if (
                          contractCheck !== undefined &&
                          sanitizedValue !== undefined &&
                          sanitizedValue > contractCheck &&
                          (optionsDropdown[values.Tradetype].key ===
                            "Sell to close" ||
                            optionsDropdown[values.Tradetype].key ===
                              "Buy to close")
                        ) {
                          investmenttype == "Equity"
                            ? setError(
                                `Sharecount should be less than or equal to  ${calculateAndFormatNoAbsInt(
                                  contractCheck
                                )}`
                              )
                            : setError(
                                `Contracts should be less than or equal to  ${calculateAndFormatNoAbsInt(
                                  contractCheck
                                )}`
                              );
                        } else {
                          setError();
                        }

                        handleChange({
                          ...e,
                          target: {
                            ...e.target,
                            value: sanitizedValue,
                          },
                        });
                      }}
                      onBlur={handleBlur}
                      variant="standard"
                    />

                    {errors.amount && touched.amount ? (
                      <p className="error">*{errors.amount}</p>
                    ) : null}

                    {contractError && (
                      <FormHelperText error>{contractError}</FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : (
            <>
              <BoxHold>
                <Grid container>
                  <Grid item xs={3} mt={1}>
                    <InputLabel sx={{ fontSize: "14px" }}>Action</InputLabel>
                  </Grid>
                  <Grid item xs={9}>
                    <FormControl fullWidth>
                      <NativeSelect
                        value={values.Tradetype}
                        sx={{ fontSize: "12px" }}
                        onChange={(e) => {
                          setAvgShow(true);
                          emptyFields(true);
                          setError();
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        autoSelect
                        id="Tradetype"
                        name="Tradetype"
                      >
                        {tradeArr?.map((val) => {
                          return <option value={val.value}>{val.key}</option>;
                        })}
                      </NativeSelect>
                    </FormControl>
                  </Grid>
                  {optionsDropdown[values.Tradetype].key == "Sell" ||
                  optionsDropdown[values.Tradetype].key == "Cover" ? (
                    <>
                      <Grid item xs={3} mt={1}>
                        <InputLabel sx={{ fontSize: "14px" }}>
                          Ticker
                        </InputLabel>
                      </Grid>
                      <Grid item xs={9}>
                        <FormControl fullWidth>
                          <Autocomplete
                            disableClearable
                            autoSelect
                            autoHighlight
                            autoComplete
                            ListboxProps={{
                              sx: { fontSize: 12 },
                            }}
                            sx={{
                              "& .MuiAutocomplete-input, & .MuiInputLabel-root":
                                {
                                  fontSize: 12,
                                },
                            }}
                            value={tickervalue}
                            onChange={(e, newValue) => {
                              settickerValue(newValue);

                              settempTarget("");
                              setMarketPrice();
                              setContract();
                              setFieldValue("avgPosition", "");
                              setFieldValue("targetAmount", "");
                              setFieldValue("amount", "");
                              setautoCompletedValue([]);
                              setAccount({});
                              setError();

                              if (newValue != undefined || newValue != "") {
                                const tickerindex = equityLots.findIndex(
                                  (e) => e.ticker.symbol == newValue
                                );
                                setFieldValue("ticker", tickerindex);
                                const equity = equityLots[tickerindex];
                                FindContracts(equity, values.Tradetype);
                                dispatch(updateLoading(true));
                                YahooFinance(newValue)
                                  .then((result) => {
                                    dispatch(updateLoading(false));
                                    if (
                                      result?.data.body[0]?.regularMarketPrice
                                    ) {
                                      setMarketPrice(
                                        result?.data.body[0]?.regularMarketPrice
                                      );
                                    } else {
                                      setMarketPrice();
                                    }
                                  })
                                  .catch((error) => {
                                    dispatch(updateLoading(false));
                                    console.log("Error:", error);
                                  });
                              }
                            }}
                            onBlur={handleBlur}
                            options={EquityTickerlist}
                            filterOptions={(EquityTickerlist, state) =>
                              filterOptions(EquityTickerlist, state)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                value={tickervalue}
                              />
                            )}
                          />
                        </FormControl>
                        {errors.ticker && !values.ticker && touched.ticker ? (
                          <p className="error">*{errors.ticker}</p>
                        ) : null}
                      </Grid>
                      <Grid xs={12}>
                        {values.ticker != undefined &&
                        (values.ticker || values.ticker === 0) &&
                        optionsDropdown[values.Tradetype].key ? (
                          <TableEqty
                            tickers={
                              Number(values.ticker) >= 0
                                ? equityLots[Number(values.ticker)]
                                : {}
                            }
                            fund={values.fund}
                            strategy={values.strategy}
                            check={optionsDropdown[values.Tradetype].key}
                            marketPrice={marketPrice}
                          />
                        ) : null}
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={3} mt={1}>
                        <InputLabel sx={{ fontSize: "14px" }}>
                          Ticker
                        </InputLabel>
                      </Grid>
                      <Grid item xs={9}>
                        <Autocomplete
                          id="ticker"
                          name="ticker"
                          disableClearable
                          autoSelect
                          autoHighlight
                          autoComplete
                          freeSolo
                          value={values.ticker}
                          ListboxProps={{
                            sx: { fontSize: 12 },
                          }}
                          sx={{
                            "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
                              fontSize: 12,
                            },
                          }}
                          onChange={async (e, newVal) => {
                            findLot(newVal);
                            setError();
                            setFieldValue("ticker", newVal);
                            settempTarget("");
                            setFieldValue("avgPosition", "");
                            setFieldValue("targetAmount", "");
                            setFieldValue("amount", "");
                            dispatch(updateLoading(true));
                            if (newVal) {
                              YahooFinance(newVal)
                                .then((result) => {
                                  dispatch(updateLoading(false));
                                  if (
                                    result?.data.body[0]?.regularMarketPrice
                                  ) {
                                    setMarketPrice(
                                      result?.data.body[0]?.regularMarketPrice
                                    );
                                    setAvgShow(true);
                                  } else {
                                    alert(`${newVal} doesnt not exist`);
                                    setAvgShow(false);
                                    setMarketPrice();
                                  }
                                })
                                .catch((error) => {
                                  dispatch(updateLoading(false));
                                  console.log("Error:", error);
                                });
                            }
                          }}
                          onBlur={handleBlur}
                          options={globalOpts}
                          filterOptions={(globalOpts, state) =>
                            filterOptions(globalOpts, state)
                          }
                          onHighlightChange={handleHighlightChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              onChange={highlyMatched}
                              value={values.ticker}
                              onKeyDown={handleKeyDown}
                            />
                          )}
                        />
                        {errors.ticker && !values.ticker && touched.ticker ? (
                          <p className="error">*{errors.ticker}</p>
                        ) : null}
                      </Grid>
                    </>
                  )}
                </Grid>
              </BoxHold>
              <BoxHold>
                <Grid container>
                  <Grid item xs={3} mt={1}>
                    <InputLabel sx={{ fontSize: "14px" }}>
                      Target % of AUM
                    </InputLabel>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      fullWidth
                      id="targetAmount"
                      name="targetAmount"
                      inputProps={{ style: { fontSize: 12 } }}
                      value={values.targetAmount}
                      onChange={(e) => {
                        const sanitizedValue = e.target.value.replace(
                          /[^0-9.]/g,
                          ""
                        );

                        const parts = sanitizedValue.split(".");
                        let integerValue = parts[0];
                        let decimalValue = parts[1];

                        if (decimalValue && decimalValue.length > 2) {
                          decimalValue = decimalValue.substring(0, 2);
                        }

                        let finalValue = integerValue;
                        if (decimalValue !== undefined) {
                          finalValue += "." + decimalValue;
                        }

                        setFieldValue("targetAmount", finalValue);

                        if (marketPrice && finalValue && fundAum) {
                          const calAmount =
                            (fundAum * finalValue) / marketPrice / 100;
                          setFieldValue(
                            "amount",
                            isNaN(calAmount) ? 0 : Math.round(calAmount)
                          );
                          if (calAmount === 0) {
                            setError(
                              "Calculated amount is 0. Please enter a valid value."
                            );
                          } else {
                            setError();
                          }

                          const avgPos = (calAmount / contractCheck) * 100;
                          setFieldValue(
                            "avgPosition",
                            isNaN(avgPos) ? 0 : Math.round(avgPos)
                          );

                          if (
                            optionsDropdown[values.Tradetype].key === "Sell" ||
                            optionsDropdown[values.Tradetype].key === "Cover"
                          ) {
                            if (finalValue > tempTarget) {
                              setFieldValue("amount", 0);
                              setFieldValue("avgPosition", 0);
                              setError(
                                `Sharecount should be less than or equal to  ${calculateAndFormatNoAbsInt(
                                  contractCheck
                                )}`
                              );
                            } else {
                              const calAmount =
                                ((tempTarget - finalValue) * fundAum) /
                                100 /
                                marketPrice;
                              const calPosition =
                                (((tempTarget - finalValue) * fundAum) /
                                  100 /
                                  marketPrice /
                                  contractCheck) *
                                100;
                              const cal = Math.round(calAmount);
                              setFieldValue(
                                "avgPosition",
                                isNaN(calPosition) ? 0 : Math.round(calPosition)
                              );
                              setFieldValue(
                                "amount",
                                isNaN(cal) ? 0 : Math.round(cal)
                              );

                              setError();
                            }
                          }
                        }

                        handleChange({
                          ...e,
                          target: {
                            ...e.target,
                            value: finalValue,
                          },
                        });
                      }}
                      onBlur={handleBlur}
                      variant="standard"
                    />
                  </Grid>
                  {AvgShow ? (
                    <>
                      <Grid item xs={3} mt={1}>
                        <InputLabel sx={{ fontSize: "14px" }}>
                          Change in Position Size
                        </InputLabel>
                      </Grid>
                      <Grid item xs={9}>
                        <TextField
                          fullWidth
                          id="avgPosition"
                          name="avgPosition"
                          inputProps={{
                            style: { fontSize: 12 },
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          value={values.avgPosition}
                          onChange={(e) => {
                            let sanitizedValue = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );

                            sanitizedValue = Math.min(100, sanitizedValue);

                            setFieldValue("avgPosition", sanitizedValue);

                            if (
                              contractCheck !== "" &&
                              contractCheck !== undefined &&
                              sanitizedValue !== "" &&
                              sanitizedValue !== undefined
                            ) {
                              const avgPos =
                                (contractCheck / 100) * sanitizedValue;
                              const trgamt =
                                ((avgPos * marketPrice) / fundAum) * 100;

                              setFieldValue(
                                "amount",
                                isNaN(avgPos) ? 0 : Math.round(avgPos)
                              );
                              if (avgPos === 0) {
                                setError(
                                  "Calculated amount is 0. Please enter a valid value."
                                );
                              } else {
                                setError();
                              }
                              setFieldValue(
                                "targetAmount",
                                isNaN(trgamt) ? 0 : trgamt?.toFixed(2)
                              );

                              if (
                                optionsDropdown[values.Tradetype].key ===
                                  "Sell" ||
                                optionsDropdown[values.Tradetype].key ===
                                  "Cover"
                              ) {
                                const calAmount =
                                  (sanitizedValue * contractCheck) / 100;
                                const trgamt =
                                  (sanitizedValue *
                                    contractCheck *
                                    marketPrice *
                                    100) /
                                  (fundAum * 100);
                                const cal = Math.round(calAmount);
                                calculateCostPerCustodian(cal, tempLots);
                                setFieldValue("amount", isNaN(cal) ? 0 : cal);

                                setFieldValue(
                                  "targetAmount",
                                  isNaN(trgamt) ? 0 : trgamt?.toFixed(2)
                                );
                                if (cal === 0) {
                                  setError(
                                    `Sharecount should be less than or equal to  ${calculateAndFormatNoAbsInt(
                                      contractCheck
                                    )}`
                                  );
                                } else {
                                  setError();
                                }
                              }
                            }

                            handleChange({
                              ...e,
                              target: {
                                ...e.target,
                                value: sanitizedValue,
                              },
                            });
                          }}
                          onBlur={handleBlur}
                          variant="standard"
                        />
                      </Grid>
                    </>
                  ) : null}

                  <Grid item xs={3} mt={1}>
                    <InputLabel sx={{ fontSize: "14px" }}>
                      ShareCount
                    </InputLabel>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      fullWidth
                      id="amount"
                      name="amount"
                      inputProps={{
                        style: { fontSize: 12 },
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                      }}
                      value={values.amount}
                      onChange={(e) => {
                        const sanitizedValue = e.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );

                        setFieldValue("amount", sanitizedValue);

                        if (contractCheck && sanitizedValue) {
                          const avgPos = (sanitizedValue / contractCheck) * 100;
                          const trgamt =
                            ((marketPrice * sanitizedValue) / fundAum) * 100;

                          setFieldValue(
                            "avgPosition",
                            isNaN(avgPos) ? 0 : Math.round(avgPos)
                          );
                          setFieldValue(
                            "targetAmount",
                            isNaN(trgamt) ? 0 : trgamt.toFixed(2)
                          );
                          if (sanitizedValue == 0) {
                            setError(
                              "Calculated amount is 0. Please enter a valid value."
                            );
                          } else {
                            setError();
                          }
                          if (
                            optionsDropdown[values.Tradetype].key === "Sell" ||
                            optionsDropdown[values.Tradetype].key === "Cover"
                          ) {
                            calculateCostPerCustodian(sanitizedValue, tempLots);
                            const test =
                              tempTarget -
                              ((marketPrice * sanitizedValue) / fundAum) * 100;
                            const calPosition =
                              (sanitizedValue / contractCheck) * 100;

                            setFieldValue(
                              "targetAmount",
                              isNaN(test) ? 0 : test?.toFixed(2)
                            );
                            setFieldValue(
                              "avgPosition",
                              isNaN(calPosition) ? 0 : Math.round(calPosition)
                            );

                            if (
                              sanitizedValue > contractCheck ||
                              sanitizedValue == 0
                            ) {
                              investmenttype === "Equity"
                                ? setError(
                                    `Sharecount should be less than or equal to  ${calculateAndFormatNoAbsInt(
                                      contractCheck
                                    )}`
                                  )
                                : setError(
                                    `Sharecount should be less than or equal to  ${calculateAndFormatNoAbsInt(
                                      contractCheck
                                    )}`
                                  );
                            }
                          }
                        } else {
                          setError();
                        }

                        handleChange({
                          ...e,
                          target: {
                            ...e.target,
                            value: sanitizedValue,
                          },
                        });
                      }}
                      onBlur={handleBlur}
                      variant="standard"
                    />

                    {errors.amount && touched.amount ? (
                      <p className="error">*{errors.amount}</p>
                    ) : null}
                    {contractError && (
                      <FormHelperText error>{contractError}</FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </BoxHold>
            </>
          )}

          <BoxHold>
            <Grid container>
              <Grid item xs={3} mt={1}>
                <InputLabel sx={{ fontSize: "14px" }}>Broker</InputLabel>
              </Grid>
              <Grid item xs={9}>
                <FormControl fullWidth>
                  <Autocomplete
                    disableClearable
                    autoSelect
                    autoHighlight
                    autoComplete
                    value={brokername}
                    ListboxProps={{
                      sx: { fontSize: 12 },
                    }}
                    sx={{
                      "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
                        fontSize: 12,
                      },
                    }}
                    onChange={(e, newValue) => {
                      setbrokername(newValue);
                      const brokersId = data?.find((e) => {
                        return e.name == newValue;
                      });
                      if (brokersId) {
                        setFieldValue("broker", brokersId?.brokerId);
                      }
                      //handleChange(e);
                    }}
                    onBlur={handleBlur}
                    options={brokerlist}
                    filterOptions={(brokerlist, state) =>
                      filterOptions(brokerlist, state)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        value={brokername}
                      />
                    )}
                  />
                  {errors.broker && touched.broker ? (
                    <p className="error">*{errors.broker}</p>
                  ) : null}
                </FormControl>
              </Grid>
              <Grid item xs={3} mt={1}>
                <InputLabel sx={{ fontSize: "14px" }}>Order Type</InputLabel>
              </Grid>
              <Grid item xs={9}>
                <FormControl fullWidth>
                  <NativeSelect
                    value={values.type}
                    autoSelect
                    sx={{ fontSize: "12px" }}
                    onChange={(e) => {
                      if (e.target.value == 0 && totalPrice) {
                        setFieldValue("typePrice", totalPrice);
                      } else {
                        setFieldValue("typePrice", 0);
                      }

                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    id="type"
                    name="type"
                  >
                    {order_Type?.map((val, i) => {
                      return <option value={i}>{val}</option>;
                    })}
                  </NativeSelect>
                </FormControl>
              </Grid>

              {values.type == 0 || values.type == 2 ? (
                <>
                  <Grid item xs={3} mt={1}>
                    <InputLabel sx={{ fontSize: "14px" }}>{`${hashType(
                      Number(values.type)
                    )} Price `}</InputLabel>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      fullWidth
                      id="typePrice"
                      name="typePrice"
                      value={values.typePrice}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="standard"
                    />
                    {errors.typePrice && touched.typePrice ? (
                      <p className="error">*{errors.typePrice}</p>
                    ) : null}
                  </Grid>
                </>
              ) : null}
              <Grid item xs={3} mt={1}>
                <InputLabel sx={{ fontSize: "14px" }}>Duration</InputLabel>
              </Grid>
              <Grid item xs={9}>
                <FormControl fullWidth>
                  <NativeSelect
                    value={values.duration}
                    autoSelect
                    sx={{ fontSize: "12px" }}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    id="duration"
                    name="duration"
                  >
                    {durationEOD?.map((val, i) => {
                      return <option value={i}>{val}</option>;
                    })}
                  </NativeSelect>
                </FormControl>
              </Grid>
              {values.duration == EnumDurationTrades.CUSTOM ? (
                <>
                  <Grid item xs={3} mt={1}>
                    <InputLabel sx={{ fontSize: "14px" }}>Kill Date</InputLabel>
                  </Grid>
                  <Grid item xs={9}>
                    <FormControl fullWidth>
                      <TextField
                        id="killDate"
                        name="killDate"
                        type="date"
                        variant="standard"
                        value={tempkillDate}
                        inputProps={{ style: { fontSize: 12 } }}
                        onChange={(e) => {
                          const inputDate = e.target.value;
                          setkillDate(inputDate);
                          if (inputDate) {
                            const parsedDate = new Date(inputDate);
                            const formattedDate = parsedDate
                              .toISOString()
                              .slice(0, 10);
                            const unixTimestamp =
                              Date.parse(formattedDate) / 1000;
                            setFieldValue("killDate", unixTimestamp);
                          } else {
                            setFieldValue("killDate", "");
                          }
                          //handleChange(e);
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                  </Grid>
                </>
              ) : null}
              <Grid item xs={3} mt={1}>
                <InputLabel sx={{ fontSize: "14px" }}>Instructions</InputLabel>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  fullWidth
                  id="instructions"
                  name="instructions"
                  inputProps={{ style: { fontSize: 12 } }}
                  value={values.instructions}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="standard"
                />
              </Grid>

              <Grid item xs={3} mt={1}>
                <InputLabel sx={{ fontSize: "14px" }}> Routing</InputLabel>
              </Grid>
              <Grid item xs={9}>
                <FormControl>
                  <RadioGroup
                    row
                    defaultValue={order.routing}
                    onChange={(e) => {
                      setRouting(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value={0}
                      control={<Radio />}
                      label="Auto"
                    />
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="Manual"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={3} mt={1}>
                <InputLabel sx={{ fontSize: "14px" }}>Router</InputLabel>
              </Grid>
              <Grid item xs={9}>
                <FormControl fullWidth>
                  <Autocomplete
                    disableClearable
                    autoSelect
                    autoHighlight
                    autoComplete
                    ListboxProps={{
                      sx: { fontSize: 12 },
                    }}
                    sx={{
                      "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
                        fontSize: 12,
                      },
                    }}
                    value={defaultRouter}
                    onChange={(e, newValue) => {
                      setdefaultRouter(newValue);
                      // setFieldValue("ticker", "");
                      //settickerValue("");
                      // const RouterObj = strategies?.find((i) => {
                      //   return i?.name == newValue;
                      // });
                      // setFieldValue("strategy", strObj?.strategyId);
                    }}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        setdefaultRouter("Default");
                        //setFieldValue("strategy", "");
                      }
                    }}
                    options={routelist}
                    filterOptions={(routelist, state) =>
                      filterOptions(routelist, state)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="router"
                        name="router"
                        variant="standard"
                        value={defaultRouter}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </BoxHold>
          <TradeDivider Routing={Routing} />
          <Grid item xs={12}>
            <InputLabel variant="standard" sx={{ mt: "-10px" }}>
              Allocate this Trade
            </InputLabel>
            <Grid container spacing={1}>
              <Grid item xs={6} sx={{ border: "1px solid #E0E0E0", mt: 1 }}>
                <Autocomplete
                  multiple
                  disabled={values.fund ? false : true}
                  id="tags-standard"
                  ListboxProps={{
                    sx: { fontSize: 12 },
                  }}
                  sx={{
                    "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
                      fontSize: 12,
                    },
                  }}
                  onClose={(e, val) => {}}
                  onChange={(e, option, reason) => {
                    setautoCompletedValue(option);
                    if (reason === "removeOption") {
                      delete account[e.currentTarget.parentElement.innerText];
                    }
                    if (reason === "clear") {
                      setAccount({});
                    }
                  }}
                  value={autocompletedValue}
                  options={checkTestKeys() || []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      sx={{ mb: 1 }}
                      error={!autocompletedValue.length}
                    />
                  )}
                />
                {!autocompletedValue.length ? (
                  <p className="error">* Account required</p>
                ) : null}
              </Grid>
              <Grid item xs={6} sx={{ marginTop: "-5px" }}>
                {autocompletedValue &&
                  autocompletedValue !== undefined &&
                  autocompletedValue.length > 0 &&
                  autocompletedValue.map((val) => {
                    return (
                      <React.Fragment key={val}>
                        <br />
                        <Typography
                          sx={{
                            fontSize: "10px",
                            color: theme.palette.grey[300],
                          }}
                        >
                          <span style={{ fontWeight: 700 }}>
                            {showCustodian(val)}
                          </span>{" "}
                          - <span>{`${val}'s sharecount`}</span>
                        </Typography>
                        <TextField
                          disabled={!values.fund}
                          variant="standard"
                          type="number"
                          value={account[val] || ""}
                          onChange={(e) => {
                            const newValue =
                              e.target.value !== ""
                                ? parseFloat(e.target.value)
                                : undefined;
                            setAccount({
                              ...account,
                              [val]: newValue,
                            });
                            handleChange(e);
                          }}
                          name="account"
                          id="account"
                          placeholder={`${val}'s sharecount`}
                        />
                      </React.Fragment>
                    );
                  })}
                {accountValueError && (
                  <FormHelperText error>{accountValueError}</FormHelperText>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            mx="10px"
            display="flex"
            justifyContent="space-between"
          >
            <Button variant="contained" sx={{ width: "30%" }} type="submit">
              SAVE
            </Button>
            <Button
              variant="contained"
              sx={{
                width: "30%",
                background: "#B2BEB5",
                color: "#000",
                "&:hover": { background: "#808080" },
              }}
              onClick={() => {
                setOpenPopup(false);
              }}
            >
              CANCEL
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default OrderEdit;
