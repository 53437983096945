import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  useTheme,
  IconButton,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Tabs from "@mui/material/Tabs";
import { useState } from "react";
import ConfirmRegenerate from "./ConfirmRegenerate";
import FundCount from "../FundsDetails/FundCount";
import { StyledTab } from "../Methods";
import TradesConfirmation from "./TradesConfirmation";

const SendTradesForAccounting = (props) => {
  const theme = useTheme();
  const { id, children, openAccount, setOpenAccount, title } = props;
  const [openRegenerate, setOpenRegenerate] = useState(false);
  const [value, setValue] = React.useState(0);
  const [tabcustodains, setTabcustodians] = useState(true);
  const [confirm, setConfirm] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={openAccount}
        sx={{
          "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
            minWidth: "1000px !important",
            marginLeft: "200px",
          },
        }}
        onClose={() => {
          setConfirm(true);
        }}
      >
        <Box>
          <DialogTitle sx={{ py: 1 }}>
            <div style={{ display: "flex" }}>
              <Typography
                variant="h6"
                component="div"
                style={{ flexGrow: 1, fontSize: "20px" }}
              >
                {title}
              </Typography>
              <IconButton
                onClick={() => {
                  setConfirm(true);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <Divider />
          <Box
            sx={{
              px: 3,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="scrollable force tabs example"
              sx={{ border: "none" }}
            >
              <StyledTab
                onClick={() => {
                  setTabcustodians(false);
                }}
                label="FundCount"
              />
            </Tabs>
          </Box>
          <DialogContent sx={{ py: 1 }}>
            {value == 0 ? <FundCount /> : <FundCount />}
          </DialogContent>
        </Box>
      </Dialog>
      <TradesConfirmation
        confirm={confirm}
        setConfirm={setConfirm}
        tradesPopup={openAccount}
        setTradesOpenPopup={setOpenAccount}
        title="Please Confirm"
        message="Are you sure you want to close the dialogue?"
      />
      <ConfirmRegenerate
        title="REGENERATE FILES FOR CUSTODIANS?"
        openRegenerate={openRegenerate}
        setOpenRegenerate={setOpenRegenerate}
      />
    </>
  );
};

export default SendTradesForAccounting;
