import React from "react";
import { Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Grid,
  useTheme,} from "@mui/material";

import Papa from "papaparse";
const ConfirmationPopUp = (props) => {
  const SendBoFTrade = () => {
    let fileContent = Papa.unparse(fileData);
    window.clientSocks.send(
      `{"action":"save_eod", "data":${JSON.stringify({
        fileName,
        fileContent,
        user,
      })}}`
    );
    setOpenSave(false);
  };
  const theme = useTheme();
  const { title, openSave, setOpenSave, fileName, fileData, user } = props;
  return (
    <Dialog
      open={openSave}
      sx={{ width: "100%", height: "100%" }}
    
    >
    <DialogTitle>
        <Typography
          variant="h6"
          component="div"
          align="center"
          style={{ flexGrow: 1, fontSize: "20px" }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="body" component="div" style={{ flexGrow: 1 }}>
          Are you sure you want to save these changes?
        </Typography>
        <Grid
          item
          xs={12}
          margin="20px 1px 20px 1px "
          display="flex"
          justifyContent="space-between"
        >
          <Button
            variant="contained"
            color="success"
            sx={{  fontSize:"12px",
            fontWeight:"bold", height:"40px", width:"150px"}}
           
            onClick={() => {
              SendBoFTrade();
            }}
          >
            SAVE CHANGES
          </Button>
          <Button
            variant="contained"
            sx={{
              fontSize:"12px",
              fontWeight:"bold",
              height:"40px", width:"150px",
              background: "#B2BEB5",
              color: "#000",
              "&:hover": { background: "#808080" },
            }}
            onClick={() => {
              setOpenSave(false);
            }}
          >
            CANCEL CHANGES
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationPopUp;
