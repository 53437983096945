import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import {
  Button,
  IconButton,
  InputLabel,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import { FundName } from "../../component/Schemas";
import { useSelector } from "react-redux";
import { useState } from "react";
import ListHead from "../ListHead";
import { TABLE_HEAD_FUNDS_CUSTODIANS, Tablecell, findCustId } from "../Methods";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import Popup from "../Popup";
import AccountDetails from "./AccountDetails";

const AddFund = ({ setOpenPopup, openPopup }) => {
  const { funds, custodians, data, managers } = useSelector(
    (state) => state?.todos
  );
  const [autocompletedValue, setAutoCompletedValue] = useState([]);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopupAccount, setOpenPopupAccount] = useState(false);

  const initialValues = {
    name: "",
    abbreviation: "",
    brokers: [],
    custodians: [],
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: FundName,
    onSubmit: (values) => {
      values.abbreviation = values.abbreviation.toLowerCase();
      if (funds?.find((a) => a.abbreviation === values.abbreviation)) {
        alert(`${values.abbreviation} already exists, try new one.`);
        return;
      }
      const accounts = {};

      values.custodians.forEach((custodian) => {
        custodian.accounts.forEach((account) => {
          accounts[account.accountName] = {
            custodian: findCustId(custodian.name, custodians),
            number: account.accountNumber.toString(),
          };
        });
      });

      const formattedData = {
        action: "add_fund",
        data: {
          abbreviation: values.abbreviation,
          brokers: values.brokers,
          accounts: accounts,
          managerId: managers[0]?.sync?.managerId,
          name: values.name,
        },
      };

      window.clientSocks.send(JSON.stringify(formattedData));
      setOpenPopup(false);
    },
  });

  const checkTestKeys = () => {
    return custodians?.map((i) => i?.abbreviation);
  };
  const checkTestKeysForBrokers = () => {
    return data?.map((i) => i?.name);
  };

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopupAccount(true);
  };
  const theme = useTheme();

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
      onSubmit={handleSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} marginBottom="5px">
          <TextField
            fullWidth
            id="name"
            label="Full Name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="standard"
          />
          {errors.name && touched.name ? (
            <p className="error">*{errors.name}</p>
          ) : null}
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            id="abbreviation"
            label="Abbreviation"
            value={values.abbreviation}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="standard"
          />
          {errors.abbreviation && touched.abbreviation ? (
            <p className="error">*{errors.abbreviation}</p>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <InputLabel variant="standard" sx={{ mt: "-10px" }}>
            Allocate Brokers
          </InputLabel>
          <Grid container spacing={1}>
            <Grid item xs={12} sx={{ border: "1px solid #E0E0E0", mt: 1 }}>
              <Autocomplete
                multiple
                id="brokers"
                ListboxProps={{
                  sx: { fontSize: 12 },
                }}
                sx={{
                  "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
                    fontSize: 12,
                  },
                }}
                onChange={(e, value) =>
                  handleChange({ target: { name: "brokers", value } })
                }
                value={values.brokers}
                options={checkTestKeysForBrokers() || []}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    sx={{ mb: 1 }}
                    label="Brokerage"
                    error={!!errors.brokers && touched.brokers}
                    helperText={
                      errors.brokers && touched.brokers ? errors.brokers : ""
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <InputLabel variant="standard" sx={{ mt: "-10px" }}>
            Allocate Custodians
          </InputLabel>
          <Grid container spacing={1}>
            <Grid item xs={12} sx={{ border: "1px solid #E0E0E0", mt: 1 }}>
              <Autocomplete
                multiple
                id="custodians"
                ListboxProps={{
                  sx: { fontSize: 12 },
                }}
                sx={{
                  "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
                    fontSize: 12,
                  },
                }}
                onChange={(e, value) => {
                  const existingCustodians = values.custodians;
                  const existingCustodiansMap = new Map(
                    existingCustodians.map((custodian) => [
                      custodian.name,
                      custodian,
                    ])
                  );
                  const mergedCustodians = value.map((name) => {
                    if (existingCustodiansMap.has(name)) {
                      return existingCustodiansMap.get(name);
                    } else {
                      return {
                        name,
                        value: 0,
                        accounts: [],
                      };
                    }
                  });

                  setFieldValue("custodians", mergedCustodians);
                }}
                value={values.custodians.map((item) => item.name)}
                options={checkTestKeys() || []}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    sx={{ mb: 1 }}
                    label="Custodians"
                    error={!!errors.custodians && touched.custodians}
                    helperText={
                      errors.custodians && touched.custodians
                        ? errors.custodians
                        : ""
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: "-5px" }}>
              {values.custodians?.length > 0 ? (
                <React.Fragment>
                  <br />
                  <TableContainer>
                    <Table
                      size="small"
                      stickyHeader
                      aria-label="a dense table"
                      sx={{
                        height: "max-content",
                        maxHeight: "55px",
                        borderCllapse: "collapse",
                      }}
                    >
                      <ListHead
                        headLabel={TABLE_HEAD_FUNDS_CUSTODIANS}
                        noStatus={true}
                      />

                      <TableBody>
                        {values.custodians?.map((val, index) => {
                          return (
                            <TableRow
                              key={index}
                              tabIndex={1}
                              role="checkbox"
                              sx={{
                                "&:hover": {
                                  cursor: "pointer",
                                  ".deleteIcon": {
                                    visibility: "visible",
                                  },
                                },
                              }}
                            >
                              <Tablecell align="left">{val?.name}</Tablecell>
                              <Tablecell align="right">{val?.value}</Tablecell>

                              <Tablecell
                                align="center"
                                sx={{
                                  paddingLeft: "5px",
                                  width: "150px",
                                }}
                              >
                                <Box
                                  component="span"
                                  sx={{
                                    fontSize: "9px",
                                    fontWeight: "500",
                                    visibility: "hidden",
                                  }}
                                  className="deleteIcon"
                                >
                                  <IconButton
                                    onClick={() => openInPopup(val)}
                                    aria-label="delete"
                                    sx={{
                                      height: "15px",
                                      width: "15px",
                                    }}
                                  >
                                    <ManageAccountsIcon
                                      sx={{ height: "15px", width: "15px" }}
                                    />
                                  </IconButton>
                                </Box>
                              </Tablecell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                    <br />
                  </TableContainer>
                </React.Fragment>
              ) : null}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          margin="10px"
          display="flex"
          justifyContent="space-between"
        >
          <Button variant="contained" sx={{ width: "30%" }} type="submit">
            SAVE
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "30%",
              background: "#B2BEB5",
              color: "#000",
              "&:hover": { background: "#808080" },
            }}
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            CANCEL
          </Button>
        </Grid>
      </Grid>

      <AccountDetails
        title="Add Account Details"
        openPopupAccount={openPopupAccount}
        setOpenPopupAccount={setOpenPopupAccount}
        recordForEdit={recordForEdit}
        custodians={values.custodians}
        setCustodians={(newCustodians) =>
          setFieldValue("custodians", newCustodians)
        }
      />
    </Box>
  );
};

export default AddFund;
