import { createSlice } from "@reduxjs/toolkit";
import { getUser, getUserInfo, removeUser, getApiInfo } from "../Actions/UserActions";

const initialState = {
  error: "",
  loading: false,
  user: null,
  userInfo: {},
  apiInfo: {}
};
const usersReducer = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state, action) => {
      localStorage.setItem("user", JSON.stringify(action.payload));
      state.user = action.payload;
      state.loading = false;
    });
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      state.userInfo = action.payload;
      state.loading = false;
    });
    builder.addCase(getApiInfo.fulfilled, (state, action) => {
      state.apiInfo = action.payload;
      state.loading = false;
    });


    builder.addCase(removeUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(removeUser.fulfilled, (state) => {
      state.user = null;
      state.userInfo = {};
      state.loading = false;
    });
    builder.addCase(removeUser.rejected, (state) => {
      state.loading = false;
    });
  },
});
export const userReducer = usersReducer.reducer;
export const getUserState = (state) => state?.users?.user;
