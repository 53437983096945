"use strict";
const axios = require("axios");

export const sync = async (query) => {
  const params = query.reduce((acc, item) => {
    const formattedItem = item.toLowerCase().replace(/\s+/g, '');
    acc[formattedItem] = 1;
    return acc;
  }, {});

  const options = {
    method: "GET",
    url: "https://52.5.145.163.nip.io:8080/sync",
    headers: {},
    params: params, 
  };

  try {
    const response = await axios.request(options);
    return response.data; 
  } catch (error) {
    console.error("Error during sync request:", error);
    throw new Error("Failed to sync data");
  }
};
