import React, { useState } from "react";
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  useTheme,
  Button,
  TextField,
  Box,
  IconButton,
  Grid,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import VantageListHead from "../../ListHead/SignalList";
import { signals_Data } from "../../dummyData";
import { TABLE_HEAD_Locates as TABLE_HEAD } from "../../Methods";
import "../../../Style/Signals.css";
import { useDispatch } from "react-redux";
import { ButtonAction } from "../../../Redux/Actions/todoActions";
const ManualLocates = () => {
  const theme = useTheme();
  const [editableData, setEditableData] = useState(signals_Data);
  const dispatch = useDispatch();
  const handleEditRow = (index, field, value) => {
    const updatedData = [...editableData];
    updatedData[index] = { ...updatedData[index], [field]: value };
    setEditableData(updatedData);
    dispatch(ButtonAction(true));
  };

  const handleDeleteRow = (index) => {
    const updatedData = editableData.filter((_, i) => i !== index);
    setEditableData(updatedData);
  };

  const handleAddRow = () => {
    const newRow = {
      name: "",
      original: 0,
      new: 0,
      type: "Manual",
    };

    setEditableData([newRow, ...editableData]);
  };

  const [tableContainerHeight, setTableContainerHeight] = useState("auto");
  const updateTableContainerHeight = (data) => {
    if (data.length > 17) {
      setTableContainerHeight("30vh");
    } else {
      setTableContainerHeight("auto");
    }
  };

  React.useEffect(() => {
    updateTableContainerHeight(editableData);
  }, [editableData]);

  return (
    <>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddRow}
            sx={{ my: 1 }}
          >
            Add Record
          </Button>
        </Grid>
      </Grid>
      <Box
        sx={{
          border: `1px solid ${theme.palette.lightGrey.tableHeader}`,
          borderRadius: "4px",
          overflow: "hidden",
          marginBottom: "20px",
          width: "500px",
        }}
      >
        <Table
          size="small"
          stickyHeader
          aria-label="a dense table"
          sx={{
            height: "max-content",
            borderCollapse: "collapse",
            border: "solid 1px #eee",
          }}
        ></Table>
        <Box
          sx={{
            maxHeight: tableContainerHeight,
            overflowY: tableContainerHeight === "auto" ? "visible" : "scroll",
          }}
        >
          <Table
            size="small"
            aria-label="a dense table"
            sx={{
              height: "max-content",
              borderCollapse: "collapse",
              border: "solid 1px #eee",
              marginTop: "-1px",
              maxHeight: tableContainerHeight,
              overflowY: tableContainerHeight === "auto" ? "visible" : "scroll",
            }}
          >
            <VantageListHead
              headLabel={TABLE_HEAD}
              noStatus={true}
              customizeFont={12}
            />
            <TableBody>
              {editableData.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    overflow: "auto",
                    "&:hover": {
                      background: theme.palette.grey[200],
                      cursor: "pointer",
                    },
                    "& > .MuiTableCell-root": {
                      paddingTop: "1px",
                      paddingBottom: "0px !important",
                      lineHeight: "1.2",
                      "& .MuiInputBase-input": {
                        padding: "0px 0px !important",
                      },
                      "& .MuiIconButton-root": {
                        padding: "0px",
                      },
                    },
                  }}
                >
                  <TableCell align="right" sx={{ borderRight: "none" }}>
                    <TextField
                      value={row.name}
                      onChange={(e) =>
                        handleEditRow(index, "name", e.target.value)
                      }
                      size="small"
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          fontSize: "12px",
                          fontWeight: "500",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell align="right" sx={{ borderRight: "none" }}>
                    <TextField
                      value={row.original}
                      onChange={(e) =>
                        handleEditRow(index, "original", e.target.value)
                      }
                      size="small"
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          fontSize: "12px",
                          fontWeight: "500",
                          color: row.original >= 0 ? "green" : "red",
                          width: "5ch",
                          marginRight: "0.5ch",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        },
                      }}
                      inputProps={{
                        type: "number",
                        step: "any",
                        style: {
                          textAlign: "right",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                          MozAppearance: "textfield",
                          WebkitAppearance: "none",
                          "&::-webkit-outer-spin-button": {
                            WebkitAppearance: "none",
                            margin: 0,
                          },
                          "&::-webkit-inner-spin-button": {
                            WebkitAppearance: "none",
                            margin: 0,
                          },
                        },
                      }}
                    />
                    <Box
                      component="span"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        color: row.original >= 0 ? "green" : "red",
                      }}
                    >
                      %
                    </Box>
                  </TableCell>
                  <TableCell align="right" sx={{ borderRight: "none" }}>
                    <TextField
                      value={row.new}
                      onChange={(e) =>
                        handleEditRow(index, "new", e.target.value)
                      }
                      size="small"
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          fontSize: "12px",
                          fontWeight: "500",
                          color: row.new >= 0 ? "green" : "red",
                          width: "5ch",
                          marginRight: "0.5ch",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        },
                      }}
                      inputProps={{
                        type: "number",
                        step: "any",
                        style: {
                          textAlign: "right",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                          MozAppearance: "textfield",
                          WebkitAppearance: "none",

                          "&::-webkit-outer-spin-button": {
                            WebkitAppearance: "none",
                            margin: 0,
                          },
                          "&::-webkit-inner-spin-button": {
                            WebkitAppearance: "none",
                            margin: 0,
                          },
                        },
                      }}
                    />
                    <Box
                      component="span"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        color: row.new >= 0 ? "green" : "red",
                      }}
                    >
                      %
                    </Box>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ borderLeft: "none", height: "-50px" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "left",
                      }}
                    >
                      <TextField
                        value={row.type}
                        onChange={(e) =>
                          handleEditRow(index, "type", e.target.value)
                        }
                        size="small"
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            fontSize: "12px",
                            fontWeight: "500",

                            marginRight: "0.5ch",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                          },
                        }}
                        inputProps={{
                          step: "any",
                          style: {
                            textAlign: "right",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            MozAppearance: "textfield",
                            WebkitAppearance: "none",

                            "&::-webkit-outer-spin-button": {
                              WebkitAppearance: "none",
                              margin: 0,
                            },
                            "&::-webkit-inner-spin-button": {
                              WebkitAppearance: "none",
                              margin: 0,
                            },
                          },
                        }}
                      />

                      <IconButton
                        onClick={() => handleDeleteRow(index)}
                        aria-label="delete"
                        sx={{
                          height: "15px",
                          width: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <DeleteOutlineIcon
                          sx={{ height: "15px", width: "15px" }}
                        />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
      <Grid
        item
        xs={12}
        margin="10px"
        display="flex"
        justifyContent="space-between"
      >
        <Button variant="contained" sx={{ width: "30%" }} type="submit">
          SAVE
        </Button>
        <Button
          variant="contained"
          sx={{
            width: "30%",
            background: "#B2BEB5",
            color: "#000",
            "&:hover": { background: "#808080" },
          }}
        >
          CANCEL
        </Button>
      </Grid>
    </>
  );
};

export default ManualLocates;
