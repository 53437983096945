import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  Grid,
  TableContainer,
  Table,
  useTheme,
  TableRow,
  NativeSelect,
} from "@mui/material";

import {
  Tablecell,
  calculateAndFormatNoAbsInt,
  getParentAccountName,
} from "../../../Methods";
import { useSelector } from "react-redux";

const ClosePositionLong = (props) => {
  const { data } = useSelector((state) => state.todos);
  const { user } = useSelector((state) => state.users);

  const { title, closePos, setClosePos, row, myFund, myStrategy } = props;
  const { custodians } = useSelector((state) => state.todos);
  const [custVal, setCustVal] = useState(row?.long_custodian);
  const [brokerVal, setBrokerVal] = useState(data[0]?.brokerId);

  const handleClose = () => {
    setClosePos(false);
  };
  const handleSubmit = () => {
    const acc = getParentAccountName(myFund?.accounts, custVal);
    const key = `${acc}#${myFund?.fundId}#0#${myStrategy?.strategyId}`;
    const distribution = {
      [key]: row?.long_deltaadjustedsharecount,
    };
    const EqytQuery = {
      distribution: distribution,
      action: 3,
      position: 0,
      broker: brokerVal,
      type: 1,
      typePrice: 1,
      instructions: "best ways",
      routing: 1,
      ticker: {
        symbol: row?.long_tickerSymbol,
        investment: 4,
        exchange: 4,
        currency: 7,
      },
      user: user?.username,
    };

    window.clientSocks.send(
      `{"action":"new_order", "data":{"orders": [${JSON.stringify(
        EqytQuery
      )}]}}`
    );

    setClosePos(false);
  };

  const theme = useTheme();
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={closePos}
      onClose={handleClose}
      sx={{
        "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
          minWidth: "500px",
          marginLeft: "200px",
        },
      }}
    >
      <DialogContent dividers>
        <Typography
          variant="h6"
          component="div"
          align="left"
          style={{ flexGrow: 1, fontSize: "20px" }}
        >
          {title}
        </Typography>

        <TableContainer sx={{ marginTop: "20px", marginBottom: "20px" }}>
          <Table>
            <TableRow>
              <Tablecell sx={{ paddingLeft: "5px", fontSize: "13px" }}>
                Ticker
              </Tablecell>
              <Tablecell sx={{ paddingLeft: "5px", fontSize: "13px" }}>
                {" "}
                {row?.long_tickerSymbol}
              </Tablecell>
            </TableRow>
            <TableRow>
              <Tablecell sx={{ paddingLeft: "5px", fontSize: "13px" }}>
                Share Count
              </Tablecell>
              <Tablecell sx={{ paddingLeft: "5px", fontSize: "13px" }}>
                {" "}
                {row?.long_deltaadjustedsharecount
                  ? `${calculateAndFormatNoAbsInt(
                      row?.long_deltaadjustedsharecount
                    )}`
                  : ""}
              </Tablecell>
            </TableRow>
            <TableRow>
              <Tablecell sx={{ paddingLeft: "5px", fontSize: "13px" }}>
                Cost
              </Tablecell>
              <Tablecell sx={{ paddingLeft: "5px", fontSize: "13px" }}>
                {" "}
                {calculateAndFormatNoAbsInt(row?.long_cost)}
              </Tablecell>
            </TableRow>
            <TableRow>
              <Tablecell sx={{ paddingLeft: "5px", fontSize: "13px" }}>
                Custodian
              </Tablecell>
              <Tablecell
                sx={{
                  paddingLeft: "5px",
                  fontSize: "13px",
                  "& .MuiNativeSelect-icon": { display: "none" },
                }}
              >
                <NativeSelect
                  value={custVal}
                  onChange={(e) => setCustVal(e.target.value)}
                  autoSelect
                  disableUnderline
                  sx={{ fontSize: "12px" }}
                  id="custodian"
                  name="custodian"
                >
                  {custodians?.map((val) => {
                    return (
                      <option value={val.custodianId} key={val.custodianId}>
                        {val.abbreviation}
                      </option>
                    );
                  })}
                </NativeSelect>
              </Tablecell>
            </TableRow>
            <TableRow>
              <Tablecell sx={{ paddingLeft: "5px", fontSize: "13px" }}>
                Broker
              </Tablecell>
              <Tablecell
                sx={{
                  paddingLeft: "5px",
                  fontSize: "13px",
                  "& .MuiNativeSelect-icon": { display: "none" },
                }}
              >
                <NativeSelect
                  value={brokerVal}
                  onChange={(e) => setBrokerVal(e.target.value)}
                  autoSelect
                  disableUnderline
                  sx={{ fontSize: "12px" }}
                  id="broker"
                  name="broker"
                >
                  {data?.map((val) => {
                    return (
                      <option value={val.brokerId} key={val?.brokerId}>
                        {val.abbreviation}
                      </option>
                    );
                  })}
                </NativeSelect>
              </Tablecell>
            </TableRow>
          </Table>
        </TableContainer>

        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "5px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              width: "20%",
              ml: 2,
            }}
            onClick={handleSubmit}
          >
            SUBMIT
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ClosePositionLong;
