import React, { useEffect, useState } from "react";
import {
  Table,
  TableRow,
  TableBody,
  useTheme,
  TableCell,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import {
  Tablecell,
  calculateAndFormatNoAbs,
  twoDecimal,
} from "../../../Methods";
import Checkbox from "@mui/material/Checkbox";
const TargetAllocation = ({
  matchedAllocations,
  setMatchedAllocations,
  updateTotals,
  fundAum,
  setPieDataTarget,
}) => {
  const theme = useTheme();
  const [editableData, setEditableData] = useState();

  useEffect(() => {
    if (matchedAllocations) {
      setEditableData(matchedAllocations);
    }
  }, [matchedAllocations]);

  const handleEditRow = (index, field, value) => {
    setEditableData((prevData) => {
      if (!prevData) return prevData;
      const updatedData = [...prevData];

      let targetValue = 0;
      if (typeof value === "string") {
        targetValue = parseFloat(value.replace(/,/g, "")) || 0;
      } else if (typeof value === "number") {
        targetValue = value;
      }

      if (field === "allocation") {
        const totalAllocationValue = (targetValue / fundAum) * 100;
        updatedData[index] = {
          ...updatedData[index],
          [field]: value,
          totalAllocation: twoDecimal(totalAllocationValue),
        };
      } else if (field === "totalAllocation") {
        const newAllocationValue = (targetValue * fundAum) / 100;
        updatedData[index] = {
          ...updatedData[index],
          [field]: value,
          allocation: twoDecimal(newAllocationValue),
        };
      } else {
        updatedData[index] = { ...updatedData[index], [field]: value };
      }
      return updatedData;
    });

    setMatchedAllocations((prevAllocations) => {
      if (!prevAllocations) return prevAllocations;
      const updatedAllocations = [...prevAllocations];

      let targetValue = 0;
      if (typeof value === "string") {
        targetValue = parseFloat(value.replace(/,/g, "")) || 0;
      } else if (typeof value === "number") {
        targetValue = value;
      }

      if (field === "allocation") {
        const totalAllocationValue = (targetValue / fundAum) * 100;
        updatedAllocations[index] = {
          ...updatedAllocations[index],
          [field]: value,
          totalAllocation: twoDecimal(totalAllocationValue),
        };
      } else if (field === "totalAllocation") {
        const newAllocationValue = (targetValue * fundAum) / 100;
        updatedAllocations[index] = {
          ...updatedAllocations[index],
          [field]: value,
          allocation: twoDecimal(newAllocationValue),
        };
      } else {
        updatedAllocations[index] = {
          ...updatedAllocations[index],
          [field]: value,
        };
      }

      updateTotals(updatedAllocations);

      const pieTarget = updatedAllocations.map((strategy) => ({
        name: strategy.name,
        value: Number(strategy.totalAllocation) || 0,
      }));
      setPieDataTarget(pieTarget);
      return updatedAllocations;
    });
  };

  return (
    <>
      <Box
        sx={{
          border: `1px ${theme.palette.lightGrey.tableHeader}`,
          borderRadius: "4px",
          marginTop: "10px",
          width: "70%",
          height: "120px",
          marginRight: 1,
        }}
      >
        <Table
          stickyHeader
          aria-label="a dense table"
          sx={{
            height: "max-content",
            borderCllapse: "collapse",
            border: "solid 1px #eee",
          }}
        >
          <TableRow
            sx={{
              "&:hover": {
                background: theme.palette.grey[200],
                cursor: "pointer",
              },
            }}
          >
            <Tablecell
              align="left"
              colSpan={5}
              sx={{
                fontSize: theme.typography.bodyText12.fontSize,
                fontWeight: 900,
                backgroundColor: "#E4C0C6",
                paddingLeft: "20px",
                textAlign: "left",
              }}
            >
              Target
            </Tablecell>
          </TableRow>
          <TableRow
            sx={{
              backgroundColor: "#B5D0D9",
            }}
          >
            <Tablecell
              sx={{
                fontSize: theme.typography.bodyText12.fontSize,
                fontWeight: theme.typography.bodyText12.fontWeight,
                backgroundColor: "#F2F2F2",
                textAlign: "center",
                width: "100px",
              }}
            >
              Use Existing
            </Tablecell>
            <Tablecell
              colSpan={2}
              sx={{
                fontSize: theme.typography.bodyText12.fontSize,
                fontWeight: theme.typography.bodyText12.fontWeight,
                backgroundColor: "#F2F2F2",
                textAlign: "center",
                width: "100px",
              }}
            >
              Allocation
            </Tablecell>
            <Tablecell
              sx={{
                fontSize: theme.typography.bodyText12.fontSize,
                fontWeight: theme.typography.bodyText12.fontWeight,
                backgroundColor: "#F2F2F2",
                textAlign: "center",
                width: "100px",
              }}
            >
              Percent Long
            </Tablecell>
            <Tablecell
              sx={{
                fontSize: theme.typography.bodyText12.fontSize,
                fontWeight: theme.typography.bodyText12.fontWeight,
                backgroundColor: "#F2F2F2",
                textAlign: "center",
                width: "100px",
              }}
            >
              Percent Short
            </Tablecell>
          </TableRow>
        </Table>
        <Table
          size="small"
          stickyHeader
          aria-label="a dense table"
          sx={{
            borderCllapse: "collapse",
            border: "solid 1px #eee",
            marginTop: "-1px",
          }}
        >
          <TableBody>
            {editableData?.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  overflow: "auto",
                  "&:hover": {
                    background: theme.palette.grey[200],
                    cursor: "pointer",
                  },
                  "& > .MuiTableCell-root": {
                    paddingTop: "0px",
                    paddingBottom: "0px !important",
                    lineHeight: "1.2",
                    "& .MuiInputBase-input": {
                      padding: "0px 0px !important",
                    },
                    "& .MuiIconButton-root": {
                      padding: "0px",
                    },
                  },
                }}
              >
                <TableCell
                  align="center"
                  sx={{
                    borderLeft: "none",
                    height: "-50px",
                    width: "100%",
                    "& .MuiCheckbox-root": { padding: "0px" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      defaultChecked
                      checked={row?.ignoreWhenRebalancing}
                      size="small"
                      onChange={(e) =>
                        handleEditRow(
                          index,
                          "ignoreWhenRebalancing",
                          e.target.checked
                        )
                      }
                      sx={{
                        "& .MuiSvgIcon-root": { fontSize: 10 },
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: "12px",
                        marginLeft: "5px",
                        textTransform: "uppercase",
                        border: "none",
                      }}
                    >
                      {String(row?.ignoreWhenRebalancing)}
                    </Typography>
                  </Box>
                </TableCell>

                <TableCell
                  align="right"
                  sx={{ borderLeft: "none", height: "-50px", width: "100%" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "120%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                      }}
                    >
                      <Box
                        sx={{
                          fontSize: "11px",
                          width: "10px",
                          height: "10px",
                          mt: "3.5px",
                        }}
                      >
                        $
                      </Box>
                      <TextField
                        value={row?.allocation}
                        onChange={(e) => {
                          const value = e.target.value;
                          const regex = /^[0-9,]*$/;
                          if (regex.test(value)) {
                            handleEditRow(index, "allocation", value);
                          }
                        }}
                        size="small"
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            fontSize: "12px",
                            width: "80px",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                          },
                        }}
                        inputProps={{
                          step: "any",
                          style: {
                            textAlign: "left",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            MozAppearance: "textfield", // For Firefox
                            WebkitAppearance: "none", // For most other browsers
                            "&::-webkit-outer-spin-button": {
                              WebkitAppearance: "none",
                              margin: 0,
                            },
                            "&::-webkit-inner-spin-button": {
                              WebkitAppearance: "none",
                              margin: 0,
                            },
                          },
                        }}
                      />
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                      }}
                    >
                      <TextField
                        value={row?.totalAllocation}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (!isNaN(value)) {
                            handleEditRow(index, "totalAllocation", value);
                          }
                        }}
                        size="small"
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            fontSize: "12px",
                            width: "40px",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                          },
                        }}
                        inputProps={{
                          step: "any",
                          style: {
                            textAlign: "right",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            MozAppearance: "textfield", // For Firefox
                            WebkitAppearance: "none", // For most other browsers
                            "&::-webkit-outer-spin-button": {
                              WebkitAppearance: "none",
                              margin: 0,
                            },
                            "&::-webkit-inner-spin-button": {
                              WebkitAppearance: "none",
                              margin: 0,
                            },
                          },
                        }}
                      />
                      <Box
                        sx={{
                          fontSize: "11px",
                          width: "12px",
                          height: "15px",
                          mt: "3px",
                          mr: 1,
                        }}
                      >
                        %
                      </Box>
                    </Box>
                  </Box>
                </TableCell>

                <TableCell
                  align="left"
                  sx={{ borderLeft: "none", height: "-50px" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "left",
                    }}
                  >
                    <TextField
                      value={row?.percentLong}
                      onChange={(e) =>
                        handleEditRow(index, "percentLong", e.target.value)
                      }
                      size="small"
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          fontSize: "12px",
                          width: "10ch",
                          marginRight: "0.5ch",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        },
                      }}
                      inputProps={{
                        type: "number",
                        step: "any",
                        style: {
                          textAlign: "right",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                          MozAppearance: "textfield", // For Firefox
                          WebkitAppearance: "none", // For most other browsers
                          // For hiding the spin buttons specifically in WebKit browsers
                          "&::-webkit-outer-spin-button": {
                            WebkitAppearance: "none",
                            margin: 0,
                          },
                          "&::-webkit-inner-spin-button": {
                            WebkitAppearance: "none",
                            margin: 0,
                          },
                        },
                      }}
                    />

                    <Box
                      sx={{
                        fontSize: "11px",
                        width: "10px",
                        height: "10px",
                        mt: "2px",
                      }}
                    >
                      %
                    </Box>
                  </Box>
                </TableCell>

                <TableCell
                  align="left"
                  sx={{
                    borderLeft: "none",
                    height: "auto",
                    width: "100%",
                    padding: 0,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "left",
                      width: "100%",
                    }}
                  >
                    <TextField
                      value={row?.percentShort}
                      onChange={(e) =>
                        handleEditRow(index, "percentShort", e.target.value)
                      }
                      size="small"
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          fontSize: "12px",
                          width: "10ch",
                          marginRight: "0.5ch",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        },
                      }}
                      inputProps={{
                        type: "number",
                        step: "any",
                        style: {
                          textAlign: "right",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                          MozAppearance: "textfield", // For Firefox
                          WebkitAppearance: "none", // For most other browsers
                          // For hiding the spin buttons specifically in WebKit browsers
                          "&::-webkit-outer-spin-button": {
                            WebkitAppearance: "none",
                            margin: 0,
                          },
                          "&::-webkit-inner-spin-button": {
                            WebkitAppearance: "none",
                            margin: 0,
                          },
                        },
                      }}
                    />

                    <Box
                      sx={{
                        fontSize: "11px",
                        width: "10px",
                        height: "10px",
                        mt: "2px",
                      }}
                    >
                      %
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};
export default TargetAllocation;
