import * as Yup from "yup";
import { TradeInvestment } from "../Methods";
const nameRegex = /^[A-Za-z]+$/;

export const UserSignUp = Yup.object({
  name: Yup.string()
    .min(2, "First Name must be at least 2 characters long.")
    .max(32, "First Name must be at most 32 characters long.")
    .matches(nameRegex, "First Name must contain only alphabetic characters.")
    .required("Please enter your First Name."),
  lname: Yup.string()
    .min(2, "Last Name must be at least 2 characters long.")
    .max(32, "Last Name must be at most 32 characters long.")
    .matches(nameRegex, "Last Name must contain only alphabetic characters.")
    .required("Please enter your Last Name."),
  email: Yup.string().email().required("Please enter your email."),
  pass: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
      "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and be at least 8 characters long"
    ),
  confirmPass: Yup.string()
    .oneOf([Yup.ref("pass"), null], "Passwords must match")
    .required("Confirm Password is required"),
  phone: Yup.string().required("Please enter your phone number."),
});

export const LogIn = Yup.object({
  username: Yup.string().required("Email is required"),
  password: Yup.string().min(4).max(32).required("Please enter password."),
});
export const ChangeUserPass = Yup.object({
  oldPassword: Yup.string().required("Old Password is required"),
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
      "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and be at least 8 characters long"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

export const UserProfileInformation = Yup.object({
  name: Yup.string()
    .min(2, "First Name must be at least 2 characters long.")
    .max(32, "First Name must be at most 32 characters long.")
    .matches(nameRegex, "First Name must contain only alphabetic characters.")
    .required("Please enter your First Name."),
  lname: Yup.string()
    .min(2, "Last Name must be at least 2 characters long.")
    .max(32, "Last Name must be at most 32 characters long.")
    .matches(nameRegex, "Last Name must contain only alphabetic characters.")
    .required("Please enter your Last Name."),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  phone: Yup.string().required("Please enter your phone number."),
});
export const ResetPassword = Yup.object({
  code: Yup.number().min(6).required("code is required"),
  password: Yup.string()
    .required("Password is required")
    .min(4, "Password must be at least 8 characters"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});
export const RolevalidationSchema = Yup.object({
  role: Yup.string().required("Role is required"),

  tradeBlotter: Yup.array(),
  dailyPerformance: Yup.array(),
  performanceDashboard: Yup.array(),
  signals: Yup.array(),
  rebalancer: Yup.array(),
});
export const UserSma = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  accounts: Yup.string().min(2).required("Account number is required"),
});
export const UserStrategy = Yup.object({
  strategy: Yup.string().required("Strategy is required"),
});
export const UserName = Yup.object({
  fname: Yup.string().min(2).max(30).required("First name is required"),
  lname: Yup.string().min(2).max(30).required("Last name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().min(4).max(32).required("Please enter a password."),
  role: Yup.string().required("Role is required"),
  userType: Yup.string().required("Type is required"),
  tradeBlotter: Yup.array(),
  dailyPerformance: Yup.array(),
  signals: Yup.array(),
  rebalancer: Yup.array(),
});
export const BrokerName = Yup.object({
  name: Yup.string().min(1).max(30).required("broker name is required"),
  abbreviation: Yup.string()
    .min(1)
    .max(30)
    .required("abbreviation is required"),
});
export const CustodianName = Yup.object({
  name: Yup.string().min(1).max(30).required("custodian name is required"),
  abbreviation: Yup.string()
    .min(1)
    .max(30)
    .required("abbreviation is required"),
});
export const SecurityMap = Yup.object({
  name: Yup.string().min(1).max(30).required("Ticker name is required"),
  selection: Yup.array()
    .of(
      Yup.object({
        name: Yup.string().required("Name is required"),
        symbol: Yup.string()
          .required("Symbol is required")
          .matches(/^[A-Za-z0-9]+$/, "Invalid symbol"),
      })
    )
    .required("Selection is required")
    .min(1, "At least one selection is required"),
});
export const BlacklistedTickers = Yup.object({
  name: Yup.string().min(1).max(30).required("Ticker name is required"),
  funds: Yup.array().min(1, "At least one fund is required"),
  managers: Yup.array().min(1, "At least one manager is required"),
});
export const StrategyName = Yup.object({
  name: Yup.string().min(1).max(30).required("strategy name is required"),
  description: Yup.string().min(5),
});
export const FundName = Yup.object({
  name: Yup.string().min(1).max(30).required("fund name is required"),
  abbreviation: Yup.string()
    .min(1)
    .max(30)
    .required("abbreviation is required"),
  brokers: Yup.array().min(1, "At least one broker is required"),
  custodians: Yup.array().min(1, "At least one custodian is required"),
});

export const Source = Yup.object({
  source: Yup.string().min(3).max(30).required("Source name is required"),
});
export const FillsEditValidation = Yup.object({
  shareCount: Yup.number().required("required"),
  sharePrice: Yup.number().required("required"),
});
export const CommissionEditValidation = Yup.object({
  commission: Yup.number().required("required"),
});

export const TradeName = Yup.object({
  typePrice: Yup.number().when("type", {
    is: (orderType) => orderType == 0 || orderType == 2,
    then: Yup.number().required("TypePrice is required"),
  }),
  broker: Yup.string().required("Choose a broker name"),
  // strategy: Yup.string().required("Choose a strategy name"),

  // targetAmount:Yup.number().when("investment", {
  //   is: (invest)=> invest != 8,
  //   then: Yup.number().required("target amount is required"),
  // }),
  ticker: Yup.string().required("Ticker is required"),

  amount: Yup.number().required("Amount is required"),
  instructions: Yup.string().optional("Instructions are required"),
  // securityLots: Yup.string().when("Tradetype", {
  //   is: (avgtype) => avgtype === 1 || avgtype === 3,
  //   then: Yup.string().required("security lot is required"),
  // }),
  // avgPosition: Yup.string().when("Tradetype", {
  //   is: (avgtype) => avgtype === 1 || avgtype === 3,
  //   then: Yup.string().required("avgposition is required"),
  // }),
  expiration: Yup.date()
    .nullable()
    .when("investment", {
      is: (invest) => invest == TradeInvestment.Options,
      then: Yup.date().nullable().required("Select the date please.."),
    }),
  putCalls: Yup.string().when("investment", {
    is: (invest) => invest == TradeInvestment.Options,
    then: Yup.string().required("Puts/Calls is required"),
  }),
  strikePrice: Yup.number().when("investment", {
    is: (invest) => invest == TradeInvestment.Options,
    then: Yup.number().required("Strike price is required"),
  }),
});

// ticker: Yup.string().when(["Tradetype","investment"], {
//   //     is: (avgtype,invest) => invest==4 ? avgtype === 0 || avgtype === 2: invest==8,
//   //     then: Yup.string().required("ticker is required"),
//   //   }),
