import { combineReducers } from "@reduxjs/toolkit";
import { dataReducer } from "./todoReducers";
import { userReducer } from "./userReducer";
import { removeUser } from "../Actions/UserActions";

const appReducer = combineReducers({
  todos: dataReducer,
  users: userReducer,
});

const rootReducer = (state, action) => {
  if (action.type === removeUser.fulfilled.type) {
    localStorage.clear();
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
