import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Box, Link } from "@mui/material";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { forgotPassword } from "../../../utils/restAPI";
import { useDispatch } from "react-redux";
import { updateLoading } from "../../../Redux/Reducers/todoReducers";

const ForgotUserPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [emailVerified, setEmailVerified] = useState(false);
  const [apiError, setApiError] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
    }),
    onSubmit: async ({ email }) => {
      dispatch(updateLoading(true));

      try {
        const response = await forgotPassword(email);
        dispatch(updateLoading(false));

        if (response && response.success) {
          navigate("/resetPass", { state: { fromButton: true, email: email } });
          setEmailVerified(false);
          setApiError(false);
        } else {
          setEmailVerified(true);
          setApiError(true);
        }
      } catch (error) {
        setApiError(true);
        setEmailVerified(true);
      }
    },
  });

  const handleBackToLoginClick = () => {
    navigate("/login");
  };
  const theme = useTheme();
  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          marginTop: 10,
          p: 2,
        }}
      >
        <Typography
          component="h1"
          variant="h4"
          fontWeight={900}
          align="center"
          gutterBottom
          color={theme.palette.primary.main}
        >
          FORGOT PASSWORD
        </Typography>
        <Typography variant="body1" align="center" paragraph>
          Enter your email, and we will send you instructions to reset your
          password.
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            margin="normal"
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />

          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            sx={{ mt: 2 }}
            disabled={formik.isSubmitting}
          >
            Continue
          </Button>
        </form>
        {emailVerified && (
          <Alert severity="error" sx={{ my: 1 }}>
            Something Went Wrong..
          </Alert>
        )}
        <Typography variant="body2" align="center" mt={2}>
          <Link
            onClick={handleBackToLoginClick}
            color="primary"
            sx={{ cursor: "pointer" }}
          >
            Back to login
          </Link>
        </Typography>
      </Box>
    </Container>
  );
};

export default ForgotUserPassword;
