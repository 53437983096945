
import { Box } from "@mui/material"


const CustomizeBox = ({children}) =>{
    return(
<>
<Box sx={{  width: "50%", marginRight: 5 , padding:"20px"}}>
    {children}
</Box>
</>

    )
 }
 export default CustomizeBox;