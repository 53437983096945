import React, { useEffect, useState } from "react";
import { Container, Grid, Button, Box, Select, MenuItem } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  TABLE_HEAD_FUND_REBALANCE_EXISTING,
  findStrategy,
} from "../../../../../Methods";
import Approved from "./components/Approved";
import Adjusted from "./components/Adjusted";
import Rejected from "./components/Rejected";
import { useDispatch, useSelector } from "react-redux";
import { stageThreeOfRebalance } from "../../../../../../Redux/Actions/todoActions";
import { updateLoading } from "../../../../../../Redux/Reducers/todoReducers";

const FundRebalanceStep5 = ({ sleeve, setCurrentStep, onPrevious }) => {
  const dispatch = useDispatch();
  const [approved, setApproved] = useState([]);
  const [rejected, setRejected] = useState([]);
  const [partial, setPartial] = useState([]);
  const { strategies, tickerTrades, sleeveDataStageOne } = useSelector(
    (state) => state.todos
  );
  const [selectedStrategy, setSelectedStrategy] = useState("");

  useEffect(() => {
    if (sleeve) {
      const strategyIds = Object.keys(tickerTrades);
      const defaultStrategyId = strategyIds[0];
      setSelectedStrategy(defaultStrategyId);
    } else {
      setSelectedStrategy("");
    }
  }, [sleeve, tickerTrades]);

  useEffect(() => {
    if (selectedStrategy && tickerTrades[selectedStrategy]) {
      const strategyTickerTrades = tickerTrades[selectedStrategy];
      const approvedData = [];
      const rejectedData = [];
      const partialData = [];

      strategyTickerTrades.forEach((trade) => {
        const { ticker, requiredLocates, availableLocates } = trade;

        if (availableLocates > 0 && availableLocates >= requiredLocates) {
          approvedData.push({ ticker: ticker?.symbol, requiredLocates });
        } else if (availableLocates === 0) {
          rejectedData.push({ ticker: ticker?.symbol, requiredLocates });
        } else if (availableLocates < requiredLocates && requiredLocates > 0) {
          partialData.push({ ticker: ticker?.symbol, requiredLocates });
        }
      });

      setApproved(approvedData);
      setRejected(rejectedData);
      setPartial(partialData);
    }
  }, [selectedStrategy, tickerTrades]);

  const handleStrategyChange = (e) => {
    const newStrategyId = e.target.value;
    setSelectedStrategy(newStrategyId);
  };
  const onNext = () => {
    const rebalancerIds = Object.values(sleeveDataStageOne)?.map(
      (strategy) => strategy?.rebalancer?.rebalancerId
    );
    const updatedTickerTrades = Object.keys(tickerTrades).reduce(
      (acc, strategyId) => {
        acc[strategyId] = tickerTrades[strategyId].map((trade) => {
          return {
            ticker: trade?.ticker,
            availableLocates: trade.availableLocates,
            requiredLocates: trade.requiredLocates,
          };
        });
        return acc;
      },
      {}
    );

     dispatch(updateLoading(true));
    const socketRequest = {
      action: "create_rebalancer_orders",
      data: {
        rebalancerIds:rebalancerIds,
        execute: 0,
        locates: updatedTickerTrades,
      },
    };
    window.clientSocks.send(JSON.stringify(socketRequest));
    setCurrentStep((prev) => prev + 1);
  };

  return (
    <Container disableGutters maxWidth="100%" sx={{ marginTop: 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box
              sx={{
                marginBottom: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {sleeve && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    ml: 10,
                  }}
                >
                  <Select
                    value={selectedStrategy}
                    onChange={handleStrategyChange}
                    sx={{
                      width: "100px",
                      border: "none",
                      fontSize: "12px",
                      "& fieldset": { border: "none" },
                      "&:hover fieldset": { border: "none" },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                  >
                    {Object.keys(tickerTrades)?.map((strategyId) => (
                      <MenuItem key={strategyId} value={strategyId}>
                        {findStrategy(strategyId, strategies)}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              )}
            </Box>

            <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                color="grey"
                startIcon={<ArrowBackIcon />}
                onClick={onPrevious}
                sx={{ width: "150px", height: "40px" }}
              >
                BACK
              </Button>
              <Button
                variant="contained"
                endIcon={<ArrowForwardIcon />}
                onClick={onNext}
                sx={{ width: "150px", height: "40px" }}
              >
                Next
              </Button>
            </Box>
          </div>
        </Grid>

        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "70%",
              justifyContent: "space-between",
            }}
          >
            <Approved
              approved={approved}
              tableHeader={TABLE_HEAD_FUND_REBALANCE_EXISTING}
            />

            <Rejected
              rejected={rejected}
              tableHeader={TABLE_HEAD_FUND_REBALANCE_EXISTING}
            />

            <Adjusted
              adjusted={partial}
              tableHeader={TABLE_HEAD_FUND_REBALANCE_EXISTING}
            />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FundRebalanceStep5;
