import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { TABLE_HEAD_COLLAPSED as TABLE_HEAD } from "../../Methods";
import { styled } from "@mui/material";
import { RsvpTwoTone } from "@mui/icons-material";

export const Tablecell = styled(TableCell)(({ theme }) => ({
  color: "#000",
  fontSize: "12px",
  padding: "0px",
  borderRight: "0.1px solid #dadada",
  borderLeft: "0.1px solid #dadada",
}));

const CollapsedTable = (props) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow>
        <Tablecell align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            sx={{ height: "5px", width: "5px" }}
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Tablecell>
        <Tablecell align="left">{row.ticker}</Tablecell>
        <Tablecell align="left">{row.quantity}</Tablecell>
        <Tablecell align="left">{row.price}</Tablecell>
        <Tablecell align="left">{row.change}</Tablecell>
        <Tablecell align="left">{row.deltaChange}%</Tablecell>
        <Tablecell align="left">{row.profit}</Tablecell>
        <Tablecell
          align="left"
          sx={{
            background: row.exposure >= 60000 ? "#DCFFD3" : "#FCD1D1",
          }}
        >
          {row.exposure}
        </Tablecell>
        <Tablecell align="left">{row.deltaExposure}%</Tablecell>
        <Tablecell align="left">{row.frTgt ? row.frTgt : "-"}</Tablecell>
        <Tablecell align="left">{row.options ? row.options : "-"}</Tablecell>
        <Tablecell align="left">{row.tgt ? RsvpTwoTone.tgt : "-"}</Tablecell>
      </TableRow>
      <TableRow>
        <Tablecell style={{ paddingTop: 0, paddingBottom: 0 }} colSpan={11}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              sx={{
                marginLeft: "35px",
                width: "100%",
                background: "#E0E0E0",
              }}
            >
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD?.map((i) => {
                      return(
                      <Tablecell>{i.label}</Tablecell>)
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow key={historyRow.date} >
                      <Tablecell align="left">{historyRow.asset}</Tablecell>
                      <Tablecell align="left">{historyRow.date}</Tablecell>
                      <Tablecell align="left">{historyRow.days}</Tablecell>
                      <Tablecell
                      align="left"
                        sx={{
                          background:
                            historyRow.duration === "Long"
                              ? "#DCFFD3"
                              : "#FCD1D1",
                        }}
                      >
                        {historyRow.duration}
                      </Tablecell>
                      <Tablecell
                      align="left"
                        sx={{
                          background:
                            historyRow.duration === "Long"
                              ? "#DCFFD3"
                              : "#FCD1D1",
                        }}
                      >
                        {historyRow.position}
                      </Tablecell>
                      <Tablecell align="left">{historyRow.gross}</Tablecell>
                      <Tablecell align="left">{historyRow.market}</Tablecell>
                      <Tablecell
                      align="left"
                        sx={{
                          background:
                            historyRow.loss === 1200 ? "#FCD1D1" : "#DCFFD3",
                        }}
                      >
                        {historyRow.loss}
                      </Tablecell>
                      <Tablecell align="left">-</Tablecell>
                      <Tablecell align="left">-</Tablecell>
                      <Tablecell align="left">-</Tablecell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </Tablecell>
      </TableRow>
    </React.Fragment>
  );
};

export default CollapsedTable;
