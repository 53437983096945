import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Typography, useTheme } from "@mui/material";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { setDefaultFund } from "../../Redux/Actions/todoActions";

const Settings = () => {
  const [fundName, setFundName] = useState("");
  const [strategyName, setStrategyName] = useState("");
  const [strategy, setStrategy] = useState([]);
  const [strategyObj, setStrategyObj] = useState();
  const [fundObj, setFundObj] = useState("");

  const { funds, strategies, UserPreference } = useSelector(
    (state) => state?.todos
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (funds && UserPreference) {
      const preceptFund = funds.find((i) => {
        return i.fundId == UserPreference?.defaults?.defaultFund;
      });

      setFundObj(preceptFund?.fundId);
      setFundName(preceptFund?.name);
      preceptFund?.strategies
        ? setStrategy(preceptFund?.strategies)
        : setStrategy([]);

      if (
        UserPreference &&
        UserPreference.defaults &&
        UserPreference.defaults.defaultStrategy
      ) {
        const preceptStrategy = strategies?.find((i) => {
          return i.strategyId == UserPreference?.defaults?.defaultStrategy;
        });

        setStrategyName(preceptStrategy?.name);
        setStrategyObj(preceptStrategy?.strategyId);
      } else {
        setStrategyName("");
        setStrategyObj();
      }
    }
  }, [funds, UserPreference, strategies]);
  const Save = () => {
    const { date, name, permissions, type, userId } = UserPreference;
    dispatch(
      setDefaultFund({ defaultFund: fundObj, defaultFundStrategy: strategyObj })
    );

    const user = {
      creationDate: date,
      name: name,
      permissions: permissions ? permissions : 15,
      type: type,
      defaults: {
        defaultFund: fundObj,
        defaultStrategy: strategyObj,
      },
      userId: userId,
    };

    window.clientSocks.send(
      `{"action": "modify_user",
          "data": { "user":${JSON.stringify(user)}           
              
          }
      }`
    );

    toast.success("User Preference has Updated");
  };

  const theme = useTheme();
  return (
    <>
      <Box sx={{ width: "80%", m: 3 }}>
        <Typography variant="h4" fontWeight="bold">
          Global Settings
        </Typography>
        <Box
          display="flex"
          justifyContent=""
          flexDirextion="column"
          width="100%"
          sx={{ mt: 5 }}
        >
          <Box sx={{ mr: 5 }}>
            <Typography variant="h5" fontSize={"20px"} fontWeight="bold">
              Fees
            </Typography>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                margin="normal"
                id="input-with-icon-textfield"
                label="SEC FEE"
                placeholder="0"
                value={0.000008}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                margin="normal"
                id="input-with-icon-textfield"
                label="ORF Option Trade Fee"
                placeholder="0"
                value={0.03025}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                margin="normal"
                id="input-with-icon-textfield"
                label="OCC Option Trade Fee"
                placeholder="0"
                value={0.02}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                margin="normal"
                id="input-with-icon-textfield"
                label="OCC Max Fee Quantity"
                placeholder="0"
                value={2750}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
            </div>
          </Box>
          <Box>
            <Typography variant="h5" fontSize={"20px"} fontWeight="bold">
              {" "}
              Settlement Days
            </Typography>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                margin="normal"
                id="standard-number"
                label="Equity"
                placeholder="0"
                type="text"
                value={"(T+2)"}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
              />
              <TextField
                margin="normal"
                id="standard-number"
                label="Option"
                placeholder="0"
                type="text"
                value={"(T+1)"}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
              />
            </div>
          </Box>
          {/* <Box sx={{ width: "25%" }}>
            <Typography variant="h5" fontSize={"20px"} fontWeight="bold">
              {" "}
              Default Fund
            </Typography>

            <Grid item xs={12} sx={{ mt: 1 }}>
              <FormControl fullWidth>
                <Autocomplete
                  disableClearable
                  value={fundName}
                  onChange={(e, newValue) => {
                    setFundName(newValue);
                    var item = funds?.find((e) => {
                      return e.name == newValue;
                    });

                    if (item) {
                      setFundObj(item?.fundId);
                      setDefaultFund(item);
                      setStrategyName("");
                      setStrategyObj();
                      setStrategy(item?.strategies);
                    }
                  }}
                  options={
                    funds &&
                    funds?.map((val) => {
                      return val?.name;
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      Label="Fund Name"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                      }}
                      variant="standard"
                      value={fundName}
                      label="Fund Name"
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ mt: 1 }}>
              <FormControl fullWidth>
                <Autocomplete
                  disableClearable
                  value={strategyName}
                  placeholder="Default Strategy"
                  onChange={(e, newValue) => {
                    setStrategyName(newValue);
                    var item = strategies?.find((e) => {
                      return e.name == newValue;
                    });

                    if (item) {
                      setStrategyObj(item.strategyId);
                    }
                  }}
                  options={
                    strategy &&
                    strategy?.map((val) => {
                      return val?.name;
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      Label="Default Strategy"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                      }}
                      variant="standard"
                      value={strategyName}
                      label="Default Strategy"
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Button
              sx={{ ml: 21 }}
              onClick={() => {
                Save();
              }}
            >
              Save
            </Button>
          </Box> */}
        </Box>
      </Box>
    </>
  );
};
export default Settings;
