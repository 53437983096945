import React from "react";
import TradeBlotters from "../component/Layouts/TradeBlotters";
import Topbar from "../component/NavComponents/Topbar";
import Footer from "../component/Footer";
import TradeBlottersV2 from "../component/Layouts/TradeBlottersV2";
import { Helmet } from "react-helmet";

import { LicenseInfo } from '@mui/x-license';
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY);

const TradeBlotterV2 = () => {
  return (
    <>
      <Helmet>
        <title>Trade Blotter</title>
      </Helmet>
      <Topbar title="Trade Blotter" />
      <TradeBlottersV2 />
      <Footer />
    </>
  );
};
export default TradeBlotterV2;
