import React from "react";
import Topbar from "../component/NavComponents/Topbar";
import FundAllocatorLayout from "../component/Layouts/FundAllocatorLayout";
import { Helmet } from "react-helmet";
const FundAllocator = () => {
  return (
    <>
      <Helmet>
        <title>Fund Allocator</title>
      </Helmet>
      <Topbar title="Fund Allocator" />
      <FundAllocatorLayout />
    </>
  );
};
export default FundAllocator;
