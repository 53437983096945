
import Header from "../Header";
import MarketTable from "../MarketDetails/MarketTable";
const  MarketDataLayout = () => {
  return (
    <>
      <Header>
        <MarketTable />
      </Header>
    </>
  );
};
export default MarketDataLayout;
