import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  useTheme,
} from "@mui/material";
import ListToolbar from "./ListToolbar";
import ListHead from "../../ListHead";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import {
  getComparator,
  TABLE_HEAD_TICKER as TABLE_HEAD,
  Tablecell,
  applySortFilter,
} from "../../Methods";

const TickersTable = () => {
  const theme = useTheme();
  const [data, setData] = useState([]);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("desc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("creationDate");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(100);

  const funds = useSelector((state) => state?.todos?.funds);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  useEffect(() => {
    const filteredUsers = applySortFilter(
      funds,
      getComparator(order, orderBy),
      filterName
    );
    setData(filteredUsers);
  }, [order, orderBy, filterName, funds]);

  const isNotFound = !data.length && !!filterName;

  return (
    <>
      <Container maxWidth="70%" sx={{ marginTop: 1, marginBottom: 3 }}>
        <Card>
          <ListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            setOrderBy={setOrderBy}
            setOrder={setOrder}
            setData={setData}
            data={data}
          />
          <TableContainer style={{ height: "62vh" }}>
            <Table
              size="small"
              stickyHeader
              aria-label="a dense table"
              sx={{
                height: "max-content",
              }}
            >
              <ListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={data.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
              />

              <TableBody>
                {data &&
                  data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const { fundId, abbreviation, date, name } = row;
                      const selectedUser = selected.indexOf(fundId) !== -1;

                      return (
                        <TableRow
                          key={index}
                          tabIndex={-1}
                          role="checkbox"
                          selected={selectedUser}
                          sx={{
                            "&:hover": {
                              background: theme.palette.grey[200],
                              cursor: "pointer",
                            },
                          }}
                        >
                          <Tablecell align="left" sx={{ paddingLeft: "10px" }}>
                            {index + 1 + page * rowsPerPage}
                          </Tablecell>
                          <Tablecell align="left">
                            {date ? moment(date).format("llll") : "-"}
                          </Tablecell>
                          <Tablecell align="left">
                            {name ? name : "-"}
                          </Tablecell>
                          <Tablecell align="left" sx={{ paddingLeft: "20px" }}>
                            {abbreviation ? abbreviation : "-"}
                          </Tablecell>
                        </TableRow>
                      );
                    })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[100, 500, 1000]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      {/* <Popup
          title="Broker Form"
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
        >
          <EditBrokerDetails
            recordForEdit={recordForEdit}
            data={data}
            setData={setData}
            setOpenPopup={setOpenPopup}
          />
        </Popup> */}
    </>
  );
};
export default TickersTable;
