// LocatesNotFoundPopup.js
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

const LocatesNotFoundPopup = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle align="center" sx={{ fontWeight: "bold", fontSize: "25px" }}>
        Locates Not Found
      </DialogTitle>
      <DialogContent>
        <Typography align="center">
          <br />
          The difference between <strong> requested locates (39)</strong> and
          <strong> available locates (35.1)</strong> is <strong>10%</strong>.
          Spreading missing weight over available locates.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={onClose}
          color="grey"
          variant="contained"
          sx={{ width: "200px" }}
        >
          Cancel
        </Button>
        <Button
          onClick={onClose}
          variant="contained"
          sx={{ width: "200px" }}
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LocatesNotFoundPopup;
