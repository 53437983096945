import BrokerTable from "../BrokerDetails/BrokerTable";
import Header from "../Header";
const TradeBroker = () => {
  return (
    <>
    <Header>
    <BrokerTable/>
    </Header>
     
    </>
  );
};
export default TradeBroker;
