import React, { useEffect, useState } from "react";
import {
  Table,
  TableRow,
  TableBody,
  useTheme,
  TableContainer,
} from "@mui/material";
import {
  Tablecell,
  TABLE_HEAD_SIGNALS_PERFORMANCE as TABLE_HEAD,
  applySortFilter,
  getComparator,
  formatValue,
} from "../../../../Methods";
import VantageListHead from "../../../../ListHead/SignalList";
import { formatTimeAgo } from "../../../../../utils/helpers";

const Performance = ({ performance }) => {
  const [data, setData] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("PercentChangeDay");

  useEffect(() => {
    if (performance && performance.length > 0) {
      const filteredUsers = applySortFilter(
        performance,
        getComparator(order, orderBy)
      );
      setData(filteredUsers);
      return;
    }

    setData([]);
  }, [performance, order, orderBy]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const theme = useTheme();

  return (
    <>
      <TableContainer sx={{ height: "45vh" }}>
        <Table
          size="small"
          stickyHeader
          aria-label="a dense table"
          sx={{
            borderCllapse: "collapse",
            border: "solid 1px #eee",
            marginTop: "-1px",
          }}
        >
          <VantageListHead
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            onRequestSort={handleRequestSort}
            noStatus={true}
            customizeFont={12}
          />
          <TableBody>
            {data?.map((row) => (
              <TableRow
                sx={{
                  background: row?.notRecent ? "#ffffcc" : "",

                  "&:hover": {
                    background: theme.palette.grey[200],
                    cursor: "pointer",
                  },
                }}
              >
                <Tablecell
                  align="left"
                  sx={{
                    fontSize: "12px",
                    paddingLeft: "5px",
                    borderRight: "none",
                  }}
                >
                  {row?.symbol}
                </Tablecell>

                <Tablecell
                  title={formatTimeAgo(row?.expiration)?.msg}
                  align="right"
                  style={{
                    fontSize: "12px",
                    paddingLeft: "5px",
                    borderLeft: "none",
                    borderRight: "none",
                    color:
                      row?.value >= 0
                        ? row?.PercentChangeDay < 0
                          ? "red"
                          : "green"
                        : row?.PercentChangeDay < 0
                        ? "green"
                        : "red",
                  }}
                >
                  {row?.PercentChangeDay < 0 ? (
                    `(${Math.abs(row?.PercentChangeDay).toFixed(2)}%)`
                  ) : (
                    <>{row?.PercentChangeDay.toFixed(2)}%&nbsp;</>
                  )}
                </Tablecell>
                <Tablecell
                  title={formatTimeAgo(row?.expiration)?.msg}
                  align="right"
                  style={{
                    fontSize: "12px",
                    paddingLeft: "5px",
                    borderLeft: "none",
                    borderRight: "none",
                    color:
                      row?.value >= 0
                        ? row?.PercentChangeWeek < 0
                          ? "red"
                          : "green"
                        : row?.PercentChangeWeek < 0
                        ? "green"
                        : "red",
                  }}
                >
                  {row?.PercentChangeWeek < 0 ? (
                    `(${Math.abs(row?.PercentChangeWeek).toFixed(2)}%)`
                  ) : (
                    <>{row?.PercentChangeWeek.toFixed(2)}%&nbsp;</>
                  )}
                </Tablecell>
                <Tablecell
                  title={formatTimeAgo(row?.expiration)?.msg}
                  align="right"
                  style={{
                    fontSize: "12px",
                    paddingLeft: "5px",
                    borderLeft: "none",
                    borderRight: "none",
                    color:
                      row?.value >= 0
                        ? row?.PercentChangeMonth < 0
                          ? "red"
                          : "green"
                        : row?.PercentChangeMonth < 0
                        ? "green"
                        : "red",
                  }}
                >
                  {row?.PercentChangeMonth < 0 ? (
                    `(${Math.abs(row?.PercentChangeMonth).toFixed(2)}%)`
                  ) : (
                    <>{row?.PercentChangeMonth.toFixed(2)}%&nbsp;</>
                  )}
                </Tablecell>
                <Tablecell
                  title={formatTimeAgo(row?.expiration)?.msg}
                  align="right"
                  style={{
                    fontSize: "12px",
                    paddingLeft: "5px",
                    borderLeft: "none",
                    borderRight: "none",
                    color:
                      row?.value >= 0
                        ? row?.PercentChangeQuarter < 0
                          ? "red"
                          : "green"
                        : row?.PercentChangeQuarter < 0
                        ? "green"
                        : "red",
                  }}
                >
                  {row?.PercentChangeQuarter < 0 ? (
                    `(${Math.abs(row?.PercentChangeQuarter).toFixed(2)}%)`
                  ) : (
                    <>{row?.PercentChangeQuarter.toFixed(2)}%&nbsp;</>
                  )}
                </Tablecell>
                <Tablecell
                  title={formatValue(row?.marketCap)}
                  align="right"
                  style={{
                    fontSize: "12px",
                    paddingLeft: "5px",
                    borderLeft: "none",
                    borderRight: "none",
                    color: "black",
                  }}
                >
                  {row?.marketCap > 0
                    ? `${formatValue(row?.marketCap / 1000000,true)}M`
                    : row?.marketCap}
                </Tablecell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default Performance;
