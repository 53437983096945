import React, { useEffect, useState } from "react";
import {
  Table,
  TableRow,
  TableBody,
  useTheme,
  Box,
  TableCell,
  Typography,
} from "@mui/material";
import VantageListHead from "../../../ListHead/SignalList";
import {
  Tablecell,
  Existing_List as TABLE_HEAD,
  formatValue,
  calculateAndFormatNoAbs,
  twoDecimal,
} from "../../../Methods";
const ExistingAllocation = ({ existing }) => {
  const theme = useTheme();
  const [editableData, setEditableData] = useState();

  useEffect(() => {
    const formattedData = existing?.map((item) => ({
      ...item,
      allocation: calculateAndFormatNoAbs(item?.aum),
      existingPercent: twoDecimal(item?.existingPercent),
      short: twoDecimal(item?.short),
      long: twoDecimal(item?.long),
    }));

    setEditableData(formattedData);
  }, [existing]);

  return (
    <>
      <Box
        sx={{
          border: `1px ${theme.palette.lightGrey.tableHeader}`,
          borderRadius: "4px",
          marginTop: "10px",
          width: "70%",
          height: "120px",
          marginRight: 1,
        }}
      >
        <Table
          stickyHeader
          aria-label="a dense table"
          sx={{
            height: "max-content",
            borderCllapse: "collapse",
            // border: "solid 1px #eee",
          }}
        >
          <TableRow
            sx={{
              "&:hover": {
                background: theme.palette.grey[200],
                cursor: "pointer",
              },
            }}
          >
            <Tablecell
              align="left"
              sx={{
                fontSize: theme.typography.bodyText12.fontSize,
                fontWeight: 900,
                backgroundColor: "#B5D0D9",
                paddingLeft: "20px",
                textAlign: "left",
              }}
            >
              Existing
            </Tablecell>
          </TableRow>
        </Table>
        <Table
          size="small"
          stickyHeader
          aria-label="a dense table"
          sx={{
            borderCllapse: "collapse",
            border: "solid 1px #eee",
            marginTop: "-1px",
          }}
        >
          <TableRow
            sx={{
              backgroundColor: "#B5D0D9",
            }}
          >
            <Tablecell
              sx={{
                fontSize: theme.typography.bodyText12.fontSize,
                fontWeight: theme.typography.bodyText12.fontWeight,
                backgroundColor: "#F2F2F2",
                textAlign: "center",
                width: "100px",
                height: "10px",
              }}
            >
              Allocation
            </Tablecell>
            <Tablecell
              sx={{
                fontSize: theme.typography.bodyText12.fontSize,
                fontWeight: theme.typography.bodyText12.fontWeight,
                backgroundColor: "#F2F2F2",
                textAlign: "center",
                width: "100px",
              }}
            >
              Percent Long
            </Tablecell>
            <Tablecell
              sx={{
                fontSize: theme.typography.bodyText12.fontSize,
                fontWeight: theme.typography.bodyText12.fontWeight,
                backgroundColor: "#F2F2F2",
                textAlign: "center",
                width: "100px",
              }}
            >
              Percent Short
            </Tablecell>
          </TableRow>

          <TableBody>
            {editableData?.map((row, index) => (
              <TableRow
                sx={{
                  "&:hover": {
                    background: theme.palette.grey[200],
                    cursor: "pointer",
                  },
                }}
              >
                <TableCell
                  align="right"
                  sx={{
                    color: "#000",
                    fontSize: "12px",
                    padding: "0px",
                    border: "0.1px solid #dadada",
                    fontWeight: "600",
                    paddingLeft: "5px",
                    borderLeft: "none",
                    borderRight: "none",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>{row?.existingPercent}%</span>
                  <span>${row?.allocation}</span>{" "}
                </TableCell>

                <Tablecell
                  align="right"
                  sx={{
                    fontWeight: "600",
                    fontSize: "12px",
                    paddingLeft: "5px",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                >
                  {row?.long}%
                </Tablecell>
                <Tablecell
                  align="right"
                  style={{
                    fontWeight: "600",
                    fontSize: "12px",
                    paddingLeft: "5px",
                    borderLeft: "none",
                  }}
                >
                  {" "}
                  {row?.short}%
                </Tablecell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};
export default ExistingAllocation;
