import React, { useState } from "react";
import { useEffect } from "react";
import Topbar from "../component/NavComponents/Topbar";
import RebalancerLayout from "../component/Layouts/RebalancerLayout";
import { addUser } from "../utils/restAPI";
import { Helmet } from "react-helmet";
const Rebalancer = () => {
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    if (counter == 0) {
      console.log(
        "aaa",
        addUser({
          email: "amir@novoassociates.com",
          firstname: "Amir",
          lastname: "Shaikh",
          phone: 4323423424,
        })
      );
      setCounter(1);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Rebalancer</title>
      </Helmet>
      <Topbar title="Rebalancer" />
      <RebalancerLayout />
    </>
  );
};
export default Rebalancer;
