import React, { useEffect, useCallback } from "react";
import "../App.css";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { UserSignUp } from "../component/Schemas";
import { useFormik } from "formik";
import theme from "../Theme";
import { useDispatch } from "react-redux";
import { updateLoading } from "../Redux/Reducers/todoReducers";
import { getUser } from "../Redux/Actions/UserActions";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useNavigate } from "react-router-dom";
import { Button, IconButton, InputAdornment } from "@mui/material";
import { addUser, checkEmail } from "../utils/restAPI";
import { debounce } from "lodash";
import { useState } from "react";
import { Alert } from "@mui/material";
import EmailVerification from "../component/UserPassword/EmailVerification";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import MuiPhoneNumber from "material-ui-phone-number";

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showVerification, setShowVerification] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [isEmailAvailable, setIsEmailAvailable] = useState(true);
  const [emailVerified, setEmailVerified] = useState(false);
  const [emailnotVerified, setEmailnotVerified] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [organizationName, setOrganizationName] = useState(
    "Individual Contractor"
  );

  const initialValues = {
    name: "",
    lname: "",
    email: "",
    pass: "",
    confirmPass: "",
    phone: "",
  };
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: UserSignUp,
    onSubmit: async ({ name, lname, email, pass, phone }) => {
      dispatch(updateLoading(true));

      try {
        const response = await addUser({
          firstname: name,
          lastname: lname,
          email: email,
          phone: String(phone),
          password: pass,
        });

        dispatch(updateLoading(false));

        if (response && response.success) {
          setEmailVerified(true);
          setEmailnotVerified(false);
          setEmail(email);
          setShowVerification(true);
        } else {
          setEmailnotVerified(true);
          setEmailVerified(false);
        }
      } catch (error) {
        setEmailVerified(false);
        setEmailnotVerified(true);
      }
    },
  });

  const handleSignInClick = () => {
    navigate("/login");
  };

  const checkOrganization = async (userEmail) => {
    if (userEmail) {
      try {
        const response = await checkEmail(userEmail);
        if (response.organization && response.organization.name) {
          setOrganizationName(response.organization.name);
        } else {
          setOrganizationName("Individual Contractor");
        }
        if (response.available) {
          setIsEmailAvailable(true);

          setApiError(false);
        } else {
          setIsEmailAvailable(false);
          if (response.error) {
            setApiError(true);
          } else {
            setApiError(false);
          }
        }
      } catch (error) {
        console.error("Error checking email:", error);
      }
    }
  };
  const debouncedCheckOrganization = useCallback(
    debounce(checkOrganization, 1000),
    []
  );
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container component="main" maxWidth="sm" sx={{ my: 2 }}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          p: 2,
        }}
      >
        {showVerification ? (
          <>
            <EmailVerification email={email} />
          </>
        ) : (
          <>
            <Typography
              component="h1"
              variant="h4"
              fontWeight={900}
              color={theme.palette.primary.main}
            >
              Sign Up
            </Typography>
            <Typography variant="body1">
              Please fill in this form to create an account.
            </Typography>

            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3 }}
            >
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item xs={5.8}>
                    <TextField
                      required
                      fullWidth
                      id="name"
                      type="text"
                      label="First Name"
                      name="name"
                      value={values.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {errors.name && touched.name ? (
                      <p className="error">*{errors.name}</p>
                    ) : null}
                  </Grid>
                  <Grid item xs={5.8}>
                    <TextField
                      required
                      fullWidth
                      id="lname"
                      type="text"
                      label="Last Name"
                      name="lname"
                      value={values.lname}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {errors.lname && touched.lname ? (
                      <p className="error">*{errors.lname}</p>
                    ) : null}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <MuiPhoneNumber
                    defaultCountry={"us"}
                    variant="outlined"
                    id="phone"
                    type="tel"
                    label="Phone"
                    name="phone"
                    onChange={(val) => {
                      const formattedNumber = `+${val.replace(/\D/g, "")}`;
                      setFieldValue("phone", formattedNumber);
                    }}
                    fullWidth
                  />

                  {errors.phone && touched.phone ? (
                    <p className="error">*{errors.phone}</p>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    type="email"
                    label="Email"
                    name="email"
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={(event) => {
                      const newEmail = event.target.value;
                      handleChange(event);

                      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
                        newEmail
                      );

                      if (isValidEmail) {
                        debouncedCheckOrganization(newEmail);
                      } else {
                        setOrganizationName("Individual Contractor");
                      }
                    }}
                    autoComplete="email"
                  />
                  {errors.email && touched.email ? (
                    <p className="error">*{errors.email}</p>
                  ) : null}
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item xs={5.8}>
                    <TextField
                      value={values.pass}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      fullWidth
                      type={showPassword ? "text" : "password"}
                      name="pass"
                      label="Password"
                      id="pass"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={togglePasswordVisibility}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="new-password"
                    />
                    {errors.pass && touched.pass ? (
                      <p className="error">*{errors.pass}</p>
                    ) : null}
                  </Grid>
                  <Grid item xs={5.8}>
                    <TextField
                      value={values.confirmPass}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      fullWidth
                      name="confirmPass"
                      label="Confirm Password"
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={togglePasswordVisibility}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {errors.confirmPass && touched.confirmPass ? (
                      <p className="error">*{errors.confirmPass}</p>
                    ) : null}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {organizationName != "Individual Contractor" ? (
                    <>
                      <InfoOutlinedIcon
                        color="disabled"
                        sx={{ marginRight: 1 }}
                      />
                      <Typography variant="body2" sx={{ color: "#777" }}>
                        You are signing up as{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {organizationName}
                        </span>
                      </Typography>
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={!isEmailAvailable}
                  sx={{
                    mt: 2,
                    width: "100%",
                    p: 1,
                  }}
                >
                  Sign Up
                </Button>
              </Grid>
              {emailVerified && (
                <Alert severity="success" sx={{ my: 1 }}>
                  Account successfully created, please check and verify your
                  email
                </Alert>
              )}
              {emailnotVerified && (
                <Alert severity="error" sx={{ my: 1 }}>
                  Sign-up failed. Please check your details and try again.
                </Alert>
              )}
              {!isEmailAvailable && (
                <Alert severity="error" sx={{ my: 1 }}>
                  {apiError
                    ? "Something Went Wrong"
                    : "Email is already registered."}
                </Alert>
              )}

              <Grid
                item
                xs
                sx={{ display: "flex", justifyContent: "center", mt: 2 }}
              >
                <Typography variant="h5">
                  Already have an account?{" "}
                  <Link
                    color="primary"
                    onClick={handleSignInClick}
                    sx={{ cursor: "pointer" }}
                  >
                    Login
                  </Link>
                </Typography>
              </Grid>
            </Box>
          </>
        )}
      </Box>
    </Container>
  );
};
export default SignUp;
