import Footer from "../Footer";
import Header from "../Header";
import TradeBlotterTable from "../TradeBlotterDetails/TradeBlotterTable";

const TradeBlotters = () => {
  return (
    <>
      <Header>
        <TradeBlotterTable />
      </Header>
    </>
  );
};
export default TradeBlotters;
