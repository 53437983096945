import Header from "../Header";
import FundsTable from "../FundsDetails/FundsTable";
const TradeFunds = () => {
  return (
    <>
     <Header >
        <FundsTable/>
     </Header>
    </>
  );
};
export default TradeFunds;
