import { Helmet } from "react-helmet";
import SmaIntegrationLayout from "../component/Layouts/SmaIntegrationLayout";
import Topbar from "../component/NavComponents/Topbar";

const SmaIntegration = () => {
  return (
    <>
      <Helmet>
        <title>SMA Integration</title>
      </Helmet>
      <Topbar title="SMA Integration" />
      <SmaIntegrationLayout />
    </>
  );
};
export default SmaIntegration;
