import React, { useEffect, useState } from "react";
import {
  Table,
  TableRow,
  TableBody,
  useTheme,
  Box,
  IconButton,
  TableContainer,
} from "@mui/material";
import {
  Tablecell,
  TABLE_HEAD_SIGNALS_SUMMARIES as TABLE_HEAD,
  applySortFilter,
  getComparator,
} from "../../Methods";
import VantageListHead from "../../ListHead/SignalList";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const SignalSummaryTwo = ({ strategyValObj, strategies, strategyVal }) => {
  const [data, setData] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("value");

  useEffect(() => {
    if (strategyValObj && strategies && strategyVal) {
      const strategy = strategies.find(
        (strategy) => strategy.strategyId === strategyValObj
      );

      if (strategy && strategy.signals) {
        const { weights_keys, weights_values } = strategy.signals;
        const keys = Object.keys(weights_keys);
        const combinedArray = keys.map((key) => {
          const symbol = weights_keys[key]?.symbol;
          const stock = weights_keys[key]?.stock;
          const name = stock ? `${symbol}/${stock}` : symbol;

          return {
            key,
            name,
            value: weights_values[key] * 100,
            perf: weights_values[key] * 100,
            stock: stock,
          };
        });

        const filteredUsers = applySortFilter(
          combinedArray,
          getComparator(order, orderBy)
        );

        setData(filteredUsers);
      } else {
        setData([]);
      }
    } else {
      setData([]);
    }
  }, [strategies, strategyValObj, strategyVal, order, orderBy]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleAddRow = () => {
    navigator.clipboard.writeText(JSON.stringify(data));
  };
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          border: `100px ${theme.palette.lightGrey.tableHeader}`,
          borderRadius: "4px",
          marginTop: "10px",
          width: "80%",
          marginRight: 1,
          mt: 4,
        }}
      >
        <Table
          stickyHeader
          aria-label="a dense table"
          sx={{
            height: "max-content",
            borderCllapse: "collapse",
            border: "solid 1px #eee",
            overflow: "scroll",
          }}
        >
          <TableRow
            sx={{
              "&:hover": {
                background: theme.palette.grey[200],
                cursor: "pointer",
              },
            }}
          >
            <Tablecell
              align="left"
              sx={{
                fontSize: theme.typography.bodyText12.fontSize,
                fontWeight: 900,
                backgroundColor: "#BDDDDD",
                paddingLeft: "20px",
                textAlign: "left",
                py: "5px",
                borderRight: "none",
              }}
            >
              Signal 02
            </Tablecell>
            <Tablecell
              align="left"
              sx={{
                fontSize: theme.typography.bodyText12.fontSize,
                fontWeight: 900,
                backgroundColor: "#BDDDDD",
                paddingRight: "5px",
                textAlign: "right",
                py: "5px",
                borderLeft: "none",
              }}
            >
              07/5/2024-07/5/2024
            </Tablecell>
          </TableRow>
        </Table>
        <TableContainer sx={{ height: "45vh" }}>
          <Table
            size="small"
            stickyHeader
            aria-label="a dense table"
            sx={{
              borderCllapse: "collapse",
              border: "solid 1px #eee",
              marginTop: "-1px",
            }}
          >
            <VantageListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              onRequestSort={handleRequestSort}
              noStatus={true}
              customizeFont={12}
            />
            <TableBody>
              {data?.map((row, index) => (
                <TableRow
                  sx={{
                    "&:hover": {
                      background: theme.palette.grey[200],
                      cursor: "pointer",
                    },
                  }}
                >
                  <Tablecell
                    align="left"
                    sx={{
                      fontSize: "12px",
                      paddingLeft: "5px",
                      borderRight: "none",
                    }}
                  >
                    {row?.name}
                  </Tablecell>
                  <Tablecell
                    align="right"
                    sx={{
                      fontSize: "12px",
                      paddingLeft: "5px",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  >
                    {row?.perf?.toFixed(2)}%
                  </Tablecell>
                  <Tablecell
                    align="right"
                    sx={{
                      fontSize: "12px",
                      paddingLeft: "5px",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  >
                    {row?.value?.toFixed(2)}%
                  </Tablecell>
                  <Tablecell
                    align="left"
                    sx={{
                      fontSize: "12px",
                      paddingLeft: "10px",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  >
                    {row?.value >= 0 ? "L" : "S"}
                  </Tablecell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};
export default SignalSummaryTwo;
