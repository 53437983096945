import Header from "../Header";
import TradeBlotterv2Table from "../TradeBlotterDetails/TradeBlotterv2Table";

const TradeBlottersV2 = () => {
  return (
    <>
      <Header>
        <TradeBlotterv2Table/>
      </Header>
    </>
  );
};
export default TradeBlottersV2;
