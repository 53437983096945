import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  TableRow,
  TableBody,
  Container,
  TableContainer,
  TablePagination,
  useTheme,
} from "@mui/material";

import ListToolbar from "./ListToolbar";
import ListHead from "../ListHead";
import { useSelector } from "react-redux";
import {
  getComparator,
  TABLE_HEAD_PERFORMANCE as TABLE_HEAD,
  Tablecell,
  applySortFilter,
  findName,
} from "../Methods";

const PerformanceDetails = () => {
  const { strategies } = useSelector((state) => state.todos);
  const [data, setData] = useState([]);
  const [dataFromChild, setDataFromChild] = useState();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("desc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("creationDate");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(100);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  useEffect(() => {
    const filteredUsers = applySortFilter(
      dataFromChild,
      getComparator(order, orderBy),
      filterName
    );
    setData(filteredUsers);
  }, [order, orderBy, filterName, dataFromChild]);

  const handleDataFromChild = (data) => {
    setDataFromChild(data);
  };


  const theme = useTheme();
  return (
    <>
      <Container maxWidth="70%" sx={{ marginTop: 1, marginBottom: 3 }}>
        <Card>
          <ListToolbar
            sendDataToParent={handleDataFromChild}
            
          />
          <TableContainer style={{ height: "100%", marginTop: 5 }}>
            <Table
              size="small"
              stickyHeader
              aria-label="a dense table"
              sx={{
                height: "max-content",
              }}
            >
              <ListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={data.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                noStatus={true}
              />
              <TableBody>
                {dataFromChild &&
                  Object.keys(dataFromChild)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((key, index) => (
                      <TableRow key={key}>
                        <Tablecell> {index + 1 + page * rowsPerPage}</Tablecell>
                        <Tablecell>{findName(key,strategies)}</Tablecell>
                        <Tablecell>{dataFromChild[key]}</Tablecell>
                        <Tablecell>15%</Tablecell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[100, 500, 1000]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  );
};
export default PerformanceDetails;
