import React, { useEffect, useState } from "react";
import { useTheme, Box, Tab, Tabs } from "@mui/material";
import { signalsData } from "../../../Methods";
import { useSelector } from "react-redux";
import MarketCap from "./MarketCap";
import SectorCap from "./SectorCap";
import Performance from "./Performance";

const PerformanceList = ({ strategyValObj, strategies, showNoSector }) => {
  const [data, setData] = useState([]);
  const [marketCapData, setMarketCapData] = useState([]);
  const [sectorData, setSectorData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const { signalsPerformance } = useSelector((state) => state.todos);
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  useEffect(() => {
    if (signalsPerformance && signalsPerformance.length > 0) {
      const filteredUsers = signalsData(
        signalsPerformance,
        strategies,
        strategyValObj
      );

      setData(filteredUsers);

      if (filteredUsers) {
        const sectorGroups = filteredUsers.reduce((groups, user) => {
          const sector = user?.sector?.toLowerCase();
          const value = user?.value;
          groups[sector] = groups[sector] || { long: 0, short: 0 };
          if (value > 0) {
            groups[sector].long += value;
          } else {
            groups[sector].short += value;
          }

          return groups;
        }, {});

        const resultArray = Object.entries(sectorGroups).map(
          ([sector, { long, short }]) => ({
            sector,
            long,
            short,
            bias: long + short,
          })
        );

        setSectorData(resultArray);

        const sortedMarketData = [...filteredUsers].sort(
          (a, b) => a.marketCap - b.marketCap
        );

        if (sortedMarketData.length >= 2) {
          const increment =
            (sortedMarketData[sortedMarketData.length - 1].marketCap -
              sortedMarketData[0].marketCap) /
            5;

          const marketData = [];

          for (let index = 0; index < 5; index++) {
            const myCap =
              index === 0
                ? sortedMarketData[0].marketCap
                : marketData[index - 1].myCap + increment;

            const cut = `Cut ${index + 1}`;

            const filteredObjects = sortedMarketData.filter(
              (obj) =>
                obj.marketCap >= myCap && obj.marketCap < myCap + increment
            );

            let long, short;

            if (index === 4) {
              const highestMarketCap =
                sortedMarketData[sortedMarketData.length - 1].marketCap;

              const lastFilteredObjects = sortedMarketData.filter(
                (obj) =>
                  obj.marketCap >= myCap && obj.marketCap <= highestMarketCap
              );

              long = lastFilteredObjects.reduce(
                (acc, obj) => (obj.value > 0 ? acc + obj.value : acc),
                0
              );
              short = lastFilteredObjects.reduce(
                (acc, obj) => (obj.value < 0 ? acc + obj.value : acc),
                0
              );
            } else {
              long = filteredObjects.reduce(
                (acc, obj) => (obj.value > 0 ? acc + obj.value : acc),
                0
              );
              short = filteredObjects.reduce(
                (acc, obj) => (obj.value < 0 ? acc + obj.value : acc),
                0
              );
            }

            marketData.push({
              myCap,
              cut,
              long,
              short,
              bias: long + short,
            });
          }

          setMarketCapData(marketData);
        }
      } else {
        setSectorData([]);
        setMarketCapData([]);
      }
    } else {
      setData([]);
      setSectorData([]);
      setMarketCapData([]);
    }
  }, [signalsPerformance, strategies, strategyValObj]);

  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          border: `100px ${theme.palette.lightGrey.tableHeader}`,
          borderRadius: "4px",
          marginTop: "10px",
          width: "100%",
          marginRight: 1,
          mt: 8,
        }}
      >
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            minHeight: 0,
            height: "30px",
            padding: "0 20px",
            backgroundColor: "#92cbdf",
          }}
        >
          <Tab
            sx={{
              fontSize: theme.typography.bodyText12.fontSize,
              minHeight: 0,
              padding: "6px 12px",
              color: "#000",
              fontWeight: 900,
              marginRight: !showNoSector ? "auto" : "inherit",
              textTransform: "capitalize",
            }}
            label="Performance"
            value={0}
          />
          <Tab
            sx={{
              fontSize: "12px",
              minHeight: 0,
              padding: "6px 12px",
              color: "#000",
              fontSize: theme.typography.bodyText12.fontSize,
              fontWeight: 900,
              marginLeft: !showNoSector ? "auto" : "inherit",
              textTransform: "capitalize",
            }}
            label="MarketCap Distribution"
            value={1}
          />
          {!showNoSector ? (
            <Tab
              sx={{
                fontSize: "12px",
                minHeight: 0,
                padding: "6px 12px",
                color: "#000",
                fontSize: theme.typography.bodyText12.fontSize,
                fontWeight: 900,
                marginLeft: "auto",
                textTransform: "capitalize",
              }}
              label="Sector Distribution"
              value={2}
            />
          ) : null}
        </Tabs>

        {activeTab === 0 ? (
          <Performance performance={data} />
        ) : activeTab === 1 ? (
          <MarketCap marketCap={marketCapData} />
        ) : !showNoSector ? (
          <SectorCap sectorData={sectorData} />
        ) : null}
      </Box>
    </>
  );
};
export default PerformanceList;
