export const calculateNotionalShareCountDelta=(optionContract, contractUnit)=> {
    return (optionContract * contractUnit);
}; 

export const calculateImpactOptionContracts = (nationalShareCountDelte, delta)=> {
    return (nationalShareCountDelte * delta);
}

export const calcualteDeltaAdjustedShareCount = (equityShareCount, impactOptionsContract) => {
    return (equityShareCount + impactOptionsContract);
}

export const calculateSize = (shareCountDetla,sharePrice, fundAum) => {
    return (((shareCountDetla*sharePrice)/fundAum)*100);
}

export const calculateCh = (currentPrice,LastPrice) => {
    return currentPrice - LastPrice
}

export const caluclatePL=(ch, notionalShareCountDelta)=>{
    return (ch*notionalShareCountDelta);
}

export const caluclateCHPercentage=(current,last)=>{
    if(last == 0) return 0
    return (((current-last)/last)*100);
}