import { Helmet } from "react-helmet";
import MarketDataLayout from "../component/Layouts/MarketDataLayout";
import Topbar from "../component/NavComponents/Topbar";

const MarketData = () => {
  return (
    <>
      <Helmet>
        <title>Market Data Mapping</title>
      </Helmet>
      <Topbar title="Market Data Mapping" />
      <MarketDataLayout />
    </>
  );
};
export default MarketData;
