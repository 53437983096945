import moment from "moment/moment";
import CircleIcon from "@mui/icons-material/Circle";
import { calculateAndFormat, formatValue } from ".";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

//Trades Table Columns

export const TradesTable = (
  theme,
  initial,
  openInDeletePopup,
  openTradeInPopup
) => [
  {
    field: "actions",
    headerName: "Actions",
    width: 60,
    minWidth: 10,
    sortable: false,
    disableColumnMenu: true,
    filterable: false,
    renderCell: (params) => (
      <>
        {!initial ? (
          <div></div>
        ) : (
          <>
            <div style={{ paddingRight: 2 }}>
              <IconButton
                sx={{ height: "10px", width: "10px", padding: "10px" }}
                onClick={() => {
                  openInDeletePopup(params?.row?.tradeObject);
                }}
              >
                <DeleteIcon
                  sx={{
                    color: "#808080",
                    width: "15px !important",
                    height: "15px !important",
                  }}
                />
              </IconButton>
              <IconButton
                sx={{ height: "10px", width: "10px", padding: "10px" }}
                onClick={() => {
                  openTradeInPopup(params?.row?.tradeObject);
                }}
              >
                <EditIcon
                  sx={{
                    color: "#808080",
                    width: "15px !important",
                    height: "15px !important",
                  }}
                />
              </IconButton>
            </div>
          </>
        )}
      </>
    ),
  },
  {
    field: "parent",
    width: 60,
    disableColumnMenu: true,
    minWidth: 30,
    headerName: <div style={{ textAlign: "center" }}>Parent</div>,
    alignLeft: true,
    renderCell: (params) => (
      <div style={{ textAlign: "right", paddingRight: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: "fundStatus",
    headerName: "Status",
    disableColumnMenu: true,
    sortable: false,
    width: 60,
    alignCenter: true,
    isActive: true,
    renderCell: (params) => (
      <>
        <CircleIcon
          style={{
            color: params ? "green" : "red",
            width: "10px",
            height: "10px",
          }}
        />
      </>
    ),
  },

  {
    field: "id",
    width: 40,
    disableColumnMenu: true,
    minWidth: 30,
    headerName: <div style={{ textAlign: "center" }}>#</div>,
    alignLeft: true,
    renderCell: (params) => (
      <div style={{ textAlign: "right", paddingRight: "5px" }}>
        {params?.value}
      </div>
    ),
  },

  {
    field: "TradeTime",
    width: 90,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Trade Time</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params ? moment(params?.value).format("hh:mm:ss") : ""}
      </div>
    ),
  },

  {
    field: "TradeType",
    disableColumnMenu: true,
    width: 88,
    headerName: <div style={{ textAlign: "center" }}>Trade Type</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: "Pcm",
    width: 70,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Ticker</div>,
    renderCell: (params) => (
      <div
        style={{
          textAlign: "left",
          paddingLeft: "5px",
          paddingLeft: "8px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params?.value}
      </div>
    ),
  },
  {
    field: "ordered",
    width: 75,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Ordered</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "right", width: "100%" }}>
        {calculateAndFormat(params?.value)}
      </div>
    ),
  },
  {
    field: "working",
    width: 75,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Working</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "right", width: "100%" }}>
        {calculateAndFormat(params?.value)}
      </div>
    ),
  },
  {
    field: "tradefills",
    width: 10,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>A/M</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: "completed",
    width: 85,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Completed</div>,
    renderCell: (params) => (
      <div
        style={{
          textAlign: "right",
          width: "100%",
          color: params?.value ? theme.palette.primary.dark : "#000",
        }}
      >
        {params?.value}
      </div>
    ),
  },
  {
    field: "price",
    width: 90,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Price/Share</div>,
    renderCell: (params) => (
      <div
        style={{
          textAlign: "right",
          width: "100%",
          color: params?.value ? theme.palette.primary.dark : "#000",
        }}
      >
        {formatValue(params?.value)}
      </div>
    ),
  },
  {
    field: "comm",
    width: 100,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Comm/Share</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "right", width: "100%" }}>
        {formatValue(params?.value)}
      </div>
    ),
  },
  {
    field: "brokerId",
    width: 80,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Broker ID</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: "Ins",
    width: 90,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Instructions</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value?.slice(0, 10)}
      </div>
    ),
  },

  {
    field: "ordertype",
    width: 90,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Order type</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: "routing",
    width: 100,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Order Routed</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },

  {
    field: "currency",
    width: 80,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Currency</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: "prime",
    width: 95,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Prime Broker</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: "InvestmentType",
    width: 120,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Investment Type</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: "PositionType",
    width: 100,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Position Type</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: "client",
    width: 90,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Client ID</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: "strategy",
    width: 80,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Strategy</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: "expiration",
    width: 90,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Expiration</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: "strikeprice",
    width: 90,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Strike Price</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "right", paddingRight: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: "putsorcall",
    width: 100,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Puts or calls</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: "sec",
    width: 75,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>SEC Fee</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "right", width: "100%" }}>{params?.value}</div>
    ),
  },
  {
    field: "occ",
    width: 80,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>OCC Fee</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "right", width: "100%" }}>{params?.value}</div>
    ),
  },
  {
    field: "orf",
    width: 80,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>ORF Fee</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "right", width: "100%" }}>{params?.value}</div>
    ),
  },
];
//Deleted Trades Table columns
export const DeletedTradescolumns = (theme) => [
  {
    field: "id",
    width: 40,
    disableColumnMenu: true,
    minWidth: 30,
    headerName: <div style={{ textAlign: "center" }}>#</div>,
    alignLeft: true,
    renderCell: (params) => (
      <div style={{ textAlign: "right", paddingRight: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: "TradeTime",
    width: 90,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Trade Time</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params ? moment(params?.value).format("hh:mm:ss") : ""}
      </div>
    ),
  },

  {
    field: "TradeType",
    disableColumnMenu: true,
    width: 88,
    headerName: <div style={{ textAlign: "center" }}>Trade Type</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: "Pcm",
    width: 70,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Ticker</div>,
    renderCell: (params) => (
      <div
        style={{
          textAlign: "left",
          paddingLeft: "5px",
          paddingLeft: "8px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params?.value}
      </div>
    ),
  },
  {
    field: "ordered",
    width: 75,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Ordered</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "right", width: "100%" }}>
        {calculateAndFormat(params?.value)}
      </div>
    ),
  },
  {
    field: "working",
    width: 75,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Working</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "right", width: "100%" }}>
        {calculateAndFormat(params?.value)}
      </div>
    ),
  },
  {
    field: "tradefills",
    width: 10,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>A/M</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: "completed",
    width: 85,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Completed</div>,
    renderCell: (params) => (
      <div
        style={{
          textAlign: "right",
          width: "100%",
          color: params?.value ? theme.palette.primary.dark : "#000",
        }}
      >
        {params?.value}
      </div>
    ),
  },
  {
    field: "price",
    width: 90,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Price/Share</div>,
    renderCell: (params) => (
      <div
        style={{
          textAlign: "right",
          width: "100%",
          color: params?.value ? theme.palette.primary.dark : "#000",
        }}
      >
        {formatValue(params?.value)}
      </div>
    ),
  },
  {
    field: "comm",
    width: 100,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Comm/Share</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "right", width: "100%" }}>
        {formatValue(params?.value)}
      </div>
    ),
  },
  {
    field: "brokerId",
    width: 80,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Broker ID</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: "Ins",
    width: 90,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Instructions</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value?.slice(0, 10)}
      </div>
    ),
  },

  {
    field: "ordertype",
    width: 90,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Order type</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: "routing",
    width: 100,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Order Routed</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },

  {
    field: "currency",
    width: 80,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Currency</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: "prime",
    width: 95,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Prime Broker</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: "InvestmentType",
    width: 120,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Investment Type</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: "PositionType",
    width: 100,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Position Type</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: "client",
    width: 90,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Client ID</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: "strategy",
    width: 80,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Strategy</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: "expiration",
    width: 90,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Expiration</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: "strikeprice",
    width: 90,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Strike Price</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "right", paddingRight: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: "putsorcall",
    width: 100,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>Puts or calls</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: "sec",
    width: 75,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>SEC Fee</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "right", width: "100%" }}>{params?.value}</div>
    ),
  },
  {
    field: "occ",
    width: 80,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>OCC Fee</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "right", width: "100%" }}>{params?.value}</div>
    ),
  },
  {
    field: "orf",
    width: 80,
    disableColumnMenu: true,
    headerName: <div style={{ textAlign: "center" }}>ORF Fee</div>,
    renderCell: (params) => (
      <div style={{ textAlign: "right", width: "100%" }}>{params?.value}</div>
    ),
  },
];
//Child Trades Table Columns

export const childTradesColumns = (theme, openInDeletePopup) => [
  {
    field: "actions",
    headerName: "Actions",
    width: 60,
    minWidth: 10,
    sortable: false,
    disableColumnMenu: true,
    filterable: false,
    renderCell: (params) => (
      <>
        <div style={{ paddingRight: 2 }}>
          <IconButton
            sx={{ height: "10px", width: "10px", padding: "10px" }}
            onClick={() => {
              openInDeletePopup(params?.row);
            }}
          >
            <DeleteIcon
              sx={{
                color: "#808080",
                width: "15px !important",
                height: "15px !important",
              }}
            />
          </IconButton>
        </div>
      </>
    ),
  },
  {
    field: "id",
    headerName: "#",
    width: 30,
    disableColumnMenu: true,
  },
  {
    field: "child",
    headerName: "Child",
    width: 30,
    disableColumnMenu: true,
  },
  {
    field: "shareCount",
    headerName: "ShareCount Total",
    width: 120,
    disableColumnMenu: true,

    renderCell: (params) => (
      <div
        style={{
          textAlign: "center",
          width: "100%",
        }}
      >
        {calculateAndFormat(params?.value)}
      </div>
    ),
  },
  {
    field: "ordered",
    headerName: "Ordered",
    width: 100,
    disableColumnMenu: true,
  },
  {
    field: "working",
    headerName: "Working",
    width: 100,
    disableColumnMenu: true,
  },
  {
    field: "completed",
    headerName: "Completed",
    width: 100,
    disableColumnMenu: true,
  },
  {
    field: "price",
    headerName: "Price",
    width: 100,
    disableColumnMenu: true,
  },
  {
    field: "account",
    headerName: "Custodian",
    width: 100,
    disableColumnMenu: true,
  },
  {
    field: "broker",
    headerName: "Broker",
    width: 100,
    disableColumnMenu: true,
  },
  {
    field: "strategy",
    headerName: "Strategy",
    width: 100,
    disableColumnMenu: true,
  },
];
