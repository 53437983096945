
import Header from "../Header";
import SignalDetails from "../SignalDetails";
const SignalLayout = () => {
  return (
    <>
     <Header >
        <SignalDetails/>
     </Header>
    </>
  );
};
export default SignalLayout;
