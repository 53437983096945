import { Box, Typography } from "@mui/material";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material";
import { aum as rows } from "../../dummyData";

const Tablecell = styled(TableCell)(({ theme }) => ({
  border: "1px solid #000",
}));
const Cell = styled(TableCell)(({ theme }) => ({
  border: "1px solid #000",
  fontWeight: "bold",
}));

const Aum = () => {
  return (
    <Box>
      <Table size="small">
        <TableHead sx={{ background: "#DCFFD3" }}>
          <TableRow>
            <Cell align="center">Pf</Cell>
            <Cell align="center">$105,274k</Cell>
            <Cell align="center"></Cell>
            <Cell align="center"></Cell>
            <Cell align="center">(70,417)</Cell>
            <Cell align="center">(0.07%)</Cell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow>
              <Cell
                align="center"
                component="th"
                scope="row"
                sx={{ background: "#FCD1D1" }}
              >
                {row.pf}
              </Cell>
              <Tablecell align="center" sx={{ background: "#FCD1D1" }}>
                {row.val1}
              </Tablecell>
              <Tablecell sx={{ background: "#FCD1D1" }}>{row.val2}</Tablecell>
              <Cell align="center" sx={{ background: "#F4F2D9" }}>
                {row.str}
              </Cell>
              <Tablecell align="center" sx={{ background: "#F4F2D9" }}>
                {row.empcol ? row.empcol : " -"}
              </Tablecell>
              <Tablecell align="center" sx={{ background: "#F4F2D9" }}>
                {row.percent} %
              </Tablecell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
export default Aum;
