import React, { useState, useEffect } from "react";
import {
  Table,
  TableRow,
  TableBody,
  useTheme,
  Box,
  NativeSelect,
} from "@mui/material";
import { useSelector } from "react-redux";
import BrokerStrategy from "../../../Popup/BrokerStrategy";
import { Tablecell } from "../../../Methods";

const Preference = ({
  custodians,
  matchedAllocations,
  setMatchedAllocations,
  sortedData,
}) => {
  const theme = useTheme();
  const [editableData, setEditableData] = useState(matchedAllocations);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    setEditableData(matchedAllocations);
  }, [matchedAllocations]);

  const handleEditRow = (index, field, value) => {
    setEditableData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        [field]: value,
      };

      setMatchedAllocations((prevAllocations) => {
        const updatedAllocations = [...prevAllocations];
        updatedAllocations[index] = {
          ...updatedAllocations[index],
          [field]: value,
        };
        return updatedAllocations;
      });

      return updatedData;
    });
  };

  return (
    <>
      <Box
        sx={{
          border: `1px ${theme.palette.lightGrey.tableHeader}`,
          borderRadius: "4px",
          marginTop: "10px",
          width: "50%",
          height: "120px",
          marginRight: 1,
        }}
      >
        <Table
          stickyHeader
          aria-label="a dense table"
          sx={{
            height: "max-content",
            borderCllapse: "collapse",
            border: "solid 1px #eee",
          }}
        >
          <TableRow
            sx={{
              "&:hover": {
                background: theme.palette.grey[200],
                cursor: "pointer",
              },
            }}
          >
            <Tablecell
              align="left"
              colSpan={2}
              sx={{
                fontSize: theme.typography.bodyText12.fontSize,
                fontWeight: 900,
                backgroundColor: "#E4C0C6",
                paddingLeft: "20px",
                textAlign: "left",
                width: "100px",
              }}
            >
              Preferences
            </Tablecell>
          </TableRow>
          <TableRow
            sx={{
              backgroundColor: "#B5D0D9",
            }}
          >
            <Tablecell
              sx={{
                fontSize: theme.typography.bodyText12.fontSize,
                fontWeight: theme.typography.bodyText12.fontWeight,
                backgroundColor: "#F2F2F2",
                textAlign: "center",
                width: "80px",
              }}
            >
              Preferred Broker
            </Tablecell>
            <Tablecell
              sx={{
                fontSize: theme.typography.bodyText12.fontSize,
                fontWeight: theme.typography.bodyText12.fontWeight,
                backgroundColor: "#F2F2F2",
                textAlign: "center",
                width: "80px",
              }}
            >
              Preferred Account
            </Tablecell>
          </TableRow>
        </Table>
        <Table
          size="small"
          stickyHeader
          aria-label="a dense table"
          sx={{
            borderCllapse: "collapse",
            border: "solid 1px #eee",
            marginTop: "-1px",
          }}
        >
          <TableBody>
            {editableData?.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  overflow: "auto",
                  "&:hover": {
                    background: theme.palette.grey[200],
                    cursor: "pointer",
                  },
                  "& > .MuiTableCell-root": {
                    borderTop: "0px",
                    paddingTop: "-61px",
                    paddingBottom: "0px !important",
                    lineHeight: "0.9",
                    "& .MuiInputBase-input": {
                      padding: "0px 0px !important",
                    },
                    "& .MuiIconButton-root": {
                      padding: "0px",
                    },
                  },
                }}
              >
                <Tablecell
                  align="left"
                  sx={{
                    fontWeight: "500",
                    fontSize: "12px",
                    paddingLeft: "5px",
                    borderRight: "none",
                    width: "100px",
                    "& .MuiNativeSelect-icon": { display: "none" },
                  }}
                >
                  <NativeSelect
                    value={row.broker}
                    onChange={(e) =>
                      handleEditRow(index, "broker", e.target.value)
                    }
                    autoSelect
                    disableUnderline
                    sx={{ fontSize: "12px" }}
                    id={`broker-${index}`}
                    name={`broker-${index}`}
                  >
                    {sortedData?.map((val) => (
                      <option key={val.brokerId} value={val.brokerId}>
                        {val.abbreviation}
                      </option>
                    ))}
                  </NativeSelect>
                </Tablecell>
                <Tablecell
                  align="left"
                  sx={{
                    fontWeight: "500",
                    fontSize: "12px",
                    paddingLeft: "5px",
                    borderRight: "none",
                    width: "100px",
                    "& .MuiNativeSelect-icon": { display: "none" },
                  }}
                >
                  <NativeSelect
                    value={row.account}
                    onChange={(e) =>
                      handleEditRow(index, "account", e.target.value)
                    }
                    autoSelect
                    disableUnderline
                    sx={{ fontSize: "12px" }}
                    id={`custodian-${index}`}
                    name={`custodian-${index}`}
                  >
                    {custodians?.map((val) => (
                      <option key={val.custodian} value={val.name}>
                        {val.name}
                      </option>
                    ))}
                  </NativeSelect>
                </Tablecell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <BrokerStrategy
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        setRecordForEdit={setRecordForEdit}
        recordForEdit={recordForEdit}
        title="Broker Strategy"
      />
    </>
  );
};

export default Preference;
