import React, { useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Typography, Card, CardContent } from "@mui/material";
import SmaStrategy from "../SmaStrategy";

const ManageStrategy = ({ setOpenPopupManageStrategy }) => {
  const [subscribeOpen, setSubscribeOpen] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {!subscribeOpen ? (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Card sx={{ maxWidth: "100%", background: "#f9f9f9" }}>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 1,
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h4"
                      component="div"
                      sx={{ fontWeight: "bold", fontSize:"20px" }}
                    >
                      AI FACTOR
                    </Typography>
                    <Button size="small" variant="outlined">
                      UNSUBSCRIBE
                    </Button>
                  </Box>

                  <Typography variant="body2" color="text.secondary" sx={{maxWidth:"80%",marginTop:"-10px"}}>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ fontWeight: "bold", mt: 1 }}
                  >
                    Fund Allocation:  20%
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card sx={{ maxWidth: "100%", background: "#f9f9f9" }}>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 1,
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h4"
                      component="div"
                      sx={{ fontWeight: "bold", fontSize:"20px" }}
                    >
                      CP FACTOR
                    </Typography>
                    <Button size="small" variant="outlined">
                      UNSUBSCRIBE
                    </Button>
                  </Box>

                  <Typography variant="body2" color="text.secondary" sx={{maxWidth:"80%",marginTop:"-10px"}}>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ fontWeight: "bold", mt: 1 }}
                  >
                    Fund Allocation:  80%
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ my: 1 }}
              display="flex"
              flexDirection={"column"}
              justifyContent="space-between"
            >
              <Button
                variant="contained"
                sx={{ width: "100%", mb: 2, fontSize: "17px" }}
                onClick={() => {
                  setSubscribeOpen(true);
                }}
              >
                SUBSRIBE MORE STRATEGIES
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <SmaStrategy
          setOpenPopupManageStrategy={setOpenPopupManageStrategy}
          setSubscribeOpen={setSubscribeOpen}
        />
      )}
    </Box>
  );
};
export default ManageStrategy;
