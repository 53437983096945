import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { InputLabel, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { StrategyName } from "../Schemas";

const EditStrategy = ({ recordForEdit, setOpenPopup }) => {
  const { strategies, managers } = useSelector((state) => state?.todos);
  const [formats, setFormats] = React.useState(() => ["personal"]);

  const handleFormat = (e) => {
    setFormats([e.target.value]);
  };

  const initialValues = {
    name: recordForEdit.name,
    desc: recordForEdit?.desc,
  };

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: StrategyName,

    onSubmit: (values) => {
      
      if (
        strategies?.find((a) => {
          if (a.strategyId !== values.strategyIdId) return a.name?.toLowerCase() === values.name.toLowerCase();
        })
      ) {
        alert(`${values.name} can not be modified.`);
      } else {
        window.clientSocks.send(
          `{"action":"modify_strategy", "data":{"strategy":
      ${JSON.stringify({
        ...values,
        strategyId: recordForEdit?.strategyId,
        creationDate: recordForEdit?.creationDate,
      })}}}`
        );
        setOpenPopup(false);
      }
    },
  });

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} margin="5px">
          <TextField fullWidth disabled id="name" name="name" label="Full Name" value={values.name} onChange={handleChange} variant="standard" />
          {errors.name && touched.name ? <p className="error">*{errors.name}</p> : null}
        </Grid>
        <Grid item xs={12} margin="5px">
          <TextField fullWidth id="desc" name="desc" label="Description" value={values.desc} onChange={handleChange} variant="standard" />
          {errors.desc && touched.desc ? <p className="error">*{errors.desc}</p> : null}
        </Grid>
        {managers && managers.length > 0 ? (
          <Grid item xs={12} marginTop="5px" display="flex" justifyContent="space-between">
            {" "}
            <Grid container spacing={2}>
              <Grid item xs={2} marginTop="5px">
                <InputLabel>Owner</InputLabel>
              </Grid>
              <Grid item xs={10} marginBottom="5px">
                <ToggleButtonGroup value={formats} onChange={handleFormat} aria-label="text formatting" sx={{ width: "50%" }}>
                  <ToggleButton value="personal" aria-label="personal" color="primary" sx={{ borderRadius: "20px", width: "100%" }}>
                    Personal
                  </ToggleButton>
                  <ToggleButton value="manager" aria-label="manager" color="primary" sx={{ borderRadius: "20px", width: "100%" }}>
                    Manager
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        <Grid item xs={12} margin="10px" display="flex" justifyContent="space-between">
          <Button variant="contained" sx={{ width: "30%" }} type="submit">
            SAVE
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "30%",
              background: "#B2BEB5",
              color: "#000",
              "&:hover": { background: "#808080" },
            }}
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            CANCEL
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
export default EditStrategy;
