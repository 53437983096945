import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Grid,
  useTheme,
} from "@mui/material";

const TradesDelete = (props) => {
  const theme = useTheme();

  const { title, openDeletePopup, setDeleteOpenPopup, recordForDelete, user } =
    props;

  const deleteItem = () => {
    window.clientSocks.send(
      `{"action":"delete_trades", "data":${JSON.stringify({
        trades: [recordForDelete.tradeId],
        user: user,
      })}}`
    );

    setDeleteOpenPopup(false);
  };
  return (
    <Dialog open={openDeletePopup} sx={{ width: "100%", height: "100%" }}>
      <DialogTitle>
        <Typography
          variant="h6"
          component="div"
          align="center"
          style={{ flexGrow: 1, fontSize: "20px" }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="body" component="div" style={{ flexGrow: 1 }}>
          {`Are you sure you want to delete this trade ?`}
        </Typography>
        <Grid
          item
          xs={12}
          margin="20px 1px 20px 1px "
          display="flex"
          justifyContent="space-between"
        >
          <Button
            variant="contained"
            sx={{
              width: "40%",
              background: theme.palette.red[200],
              "&:hover": { background: theme.palette.red[300] },
            }}
            onClick={() => {
              deleteItem();
            }}
          >
            DELETE
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "40%",
              background: "#B2BEB5",
              color: "#000",
              "&:hover": { background: "#808080" },
            }}
            onClick={() => {
              setDeleteOpenPopup(false);
            }}
          >
            CANCEL
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default TradesDelete;
