import React, { useState } from "react";
import {
  Table,
  TableRow,
  TableBody,
  useTheme,
  IconButton,
  Box,
  TableCell,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import VantageListHead from "../../../ListHead/SignalList";
import {
  Tablecell,
  strategy_List as TABLE_HEAD,
  formatValue,
} from "../../../Methods";

const Strategy = ({
  matchedAllocations,
  StrategiesLowAum,
  setStrategyCombined,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleAddStrategy = (item) => {
    handleClose();
    setStrategyCombined((prev) => [...prev, item]);
  };

  return (
    <>
      <Box
        sx={{
          border: `1px ${theme.palette.lightGrey.tableHeader}`,
          borderRadius: "4px",
          marginTop: "10px",
          width: "33%",
          height: "120px",
          marginRight: 1,
        }}
      >
        <Table
          stickyHeader
          aria-label="a dense table"
          sx={{
            height: "max-content",
            borderCollapse: "collapse",
            border: "solid 1px #eee",
          }}
        >
          <TableRow
            sx={{
              "&:hover": {
                background: theme.palette.grey[200],
                cursor: "pointer",
              },
            }}
          >
            <Tablecell
              align="left"
              sx={{
                fontSize: theme.typography.bodyText12.fontSize,
                fontWeight: 900,
                backgroundColor: "#A67272",
                color: "#fff",
                paddingLeft: "20px",
                textAlign: "left",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              Strategies
              <IconButton
                onClick={handleClick}
                aria-label="add"
                sx={{
                  height: "16px",
                  width: "16px",
                  marginRight: "10px",
                }}
              >
                <AddIcon
                  sx={{
                    height: "20px",
                    width: "20px",
                    color: "white",
                  }}
                />
              </IconButton>
            </Tablecell>
          </TableRow>
          <TableRow
            sx={{
              backgroundColor: "#B5D0D9",
            }}
          >
            <Tablecell
              sx={{
                fontSize: theme.typography.bodyText12.fontSize,
                fontWeight: theme.typography.bodyText12.fontWeight,
                backgroundColor: "#F2F2F2",
                textAlign: "center",
                width: "100px",
                height: "10px",
              }}
            >
              Strategy
            </Tablecell>
          </TableRow>
        </Table>
        <Table
          size="small"
          stickyHeader
          aria-label="a dense table"
          sx={{
            borderCollapse: "collapse",
            border: "solid 1px #eee",
            marginTop: "-1px",
          }}
        >
          <TableBody>
            {matchedAllocations?.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:hover": {
                    background: theme.palette.grey[200],
                    cursor: "pointer",
                  },
                }}
              >
                <TableCell
                  align="left"
                  sx={{
                    color: "#000",
                    fontSize: "12px",
                    padding: "0px",
                    border: "0.1px solid #dadada",
                    paddingLeft: "5px",
                    borderLeft: "none",
                    borderRight: "none",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {row?.name}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {StrategiesLowAum?.map((strategy) => (
            <MenuItem
              key={strategy?.strategyId}
              onClick={() => handleAddStrategy(strategy)}
            >
              {strategy?.name}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </>
  );
};

export default Strategy;
