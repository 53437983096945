import React, { useState, useEffect } from "react";
import ListHead from "../../ListHead";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment/moment";
import {
  TABLE_HEAD_OPTION as TABLE_HEAD,
  Tablecell,
  TradeInvestment,
  calculateAndFormatNoAbsInt,
  hashOption,
} from "../../Methods";
import {
  Table,
  TableBody,
  TableContainer,
  TableRow,
  useTheme,
  Box,
} from "@mui/material";
import { setGlobalContract } from "../../../Redux/Actions/todoActions";
const TableCom = ({ tickers, check, fund, strategy }) => {
  const dispatch = useDispatch();

  const [sameLots, setSameLots] = useState([]);
  const { lots, custodians } = useSelector((state) => state?.todos);
  var totalstrikePrice = 0,
    totalContracts = 0,
    count = 0;

  useEffect(() => {
    if (lots) {
      let tickerOption = [];

      if (check === "Sell to close") {
        tickerOption = lots?.filter((i) => {
          return (
            i?.ticker?.investment === TradeInvestment.Options &&
            i.shareCount > 0 &&
            (!fund || i.fund === fund) &&
            (!strategy || i.strategy === strategy)
          );
        });
      } else if (check === "Buy to close") {
        tickerOption = lots?.filter((i) => {
          return (
            i?.ticker?.investment === TradeInvestment.Options &&
            i.shareCount < 0 &&
            (!fund || i.fund === fund) &&
            (!strategy || i.strategy === strategy)
          );
        });
      }

      const newLots =
        tickers?.symbol +
        tickers?.option +
        tickers?.strikePrice +
        tickers?.expirationDate;
      const temp = [];
      tickerOption.forEach((options) => {
        const { symbol, option, strikePrice, expirationDate } = options.ticker;

        const textKey = symbol + option + strikePrice + expirationDate;

        if (newLots === textKey) {
          temp.push(options);
        }
      });

      const sorted = temp.sort((a, b) => {
        return a.symbol > b.symbol;
      });
      setSameLots(sorted);
    }
  }, [lots, tickers, check, fund, strategy]);

  useEffect(() => {
    dispatch(
      setGlobalContract({
        sharecounts: Math.abs(totalContracts),
        prices: 0,
      })
    );
  }, [totalContracts, dispatch, sameLots]);
  const theme = useTheme();

  return (
    <>
      <Box>
        <TableContainer>
          <Table
            size="small"
            stickyHeader
            aria-label="a dense table"
            sx={{
              height: "max-content",
              maxHeight: "55px",
              borderCllapse: "collapse",
            }}
          >
            <ListHead headLabel={TABLE_HEAD} noStatus={true} />

            <TableBody>
              {sameLots?.map((val, index) => {
                totalstrikePrice = totalstrikePrice + val?.ticker?.strikePrice;
                count = count + 1;
                totalContracts = totalContracts + val?.shareCount;

                const matchingCustodian = custodians.find((obj) => {
                  return obj.custodianId == val.custodian;
                });

                return (
                  <>
                    <TableRow key={index} tabIndex={1} role="checkbox">
                      <Tablecell align="left">
                        {moment(val?.ticker?.expirationDate).format(
                          "MMM DD, YYYY"
                        )}
                      </Tablecell>
                      <Tablecell align="center">
                        {matchingCustodian?.name}
                      </Tablecell>
                      <Tablecell align="center">
                        {val?.ticker?.symbol}
                      </Tablecell>
                      <Tablecell align="center">
                        {hashOption(val?.ticker?.option)}
                      </Tablecell>
                      <Tablecell align="center">
                        {val?.ticker?.strikePrice}{" "}
                      </Tablecell>
                      <Tablecell align="center">
                        {moment(val?.ticker?.expirationDate).format("MM/DD/YY")}
                      </Tablecell>
                      <Tablecell align="center">
                        {calculateAndFormatNoAbsInt(val?.shareCount)}
                      </Tablecell>
                    </TableRow>
                  </>
                );
              })}
              <TableRow tabIndex={1} role="checkbox">
                <Tablecell sx={{ border: "none" }}></Tablecell>

                <Tablecell align="center" sx={{ border: "none" }}></Tablecell>

                <Tablecell align="center" sx={{ border: "none" }}></Tablecell>
                <Tablecell align="center" sx={{ border: "none" }}></Tablecell>

                <Tablecell
                  align="center"
                  sx={{
                    color: theme.palette.primary.light,
                    fontWeight: "bold",
                    border: "none",
                  }}
                >
                  {calculateAndFormatNoAbsInt(totalstrikePrice)}
                </Tablecell>
                <Tablecell align="center" sx={{ border: "none" }}></Tablecell>
                <Tablecell
                  align="center"
                  sx={{
                    color: theme.palette.primary.light,
                    fontWeight: "bold",
                    border: "none",
                  }}
                >
                  {calculateAndFormatNoAbsInt(totalContracts)}
                </Tablecell>
              </TableRow>
            </TableBody>
          </Table>
          <br />
        </TableContainer>
      </Box>
    </>
  );
};
export default TableCom;
