import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Container,
  TableContainer,
  TablePagination,
  useTheme,
} from "@mui/material";

import ListToolbar from "./ListToolbar";
import ListHead from "../../ListHead";
import { useSelector } from "react-redux";
import {
  getComparator,
  applySortFilter,
  TABLE_HEAD_ROUTER as TABLE_HEAD,
  findFund,
  findStrategy,
} from "../../Methods";
import Row from "./Row";

const AccountingTable = () => {
  const theme = useTheme();
  const [data, setData] = useState([]);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(100);

  const { accountingData, strategies, funds } = useSelector(
    (state) => state?.todos
  );
  const fund = accountingData[0]?.funds;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  useEffect(() => {
    const filteredUsers = applySortFilter(
      fund?.map((row) => {
        const foundFund = findFund(row?.fund, funds);
        const strategyAUMsData = Object.keys(row?.strategyAUMs || {}).map(
          (strategyId) => {
            return {
              strategyName: findStrategy(strategyId, strategies),
              strategyAUM: row?.strategyAUMs[strategyId],
            };
          }
        );
        const filteredStrategyAUMsData = strategyAUMsData?.filter(
          (item) => item?.strategyName && item?.strategyAUM !== undefined
        );
        const sortedStrategyAUMsData = filteredStrategyAUMsData
          ?.slice()
          ?.sort((a, b) => a?.strategyName.localeCompare(b?.strategyName));

        return {
          ...row,
          name: foundFund,
          strategyAUMs: sortedStrategyAUMsData,
        };
      }),
      getComparator(order, orderBy),
      filterName
    );

    setData(filteredUsers);
  }, [order, orderBy, filterName, fund, funds, strategies]);
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
  const isNotFound = !data.length && !!filterName;
  return (
    <Container maxWidth="70%" sx={{ marginTop: 1, marginBottom: 3 }}>
      <Card>
        <ListToolbar
          filterName={filterName}
          onFilterName={handleFilterByName}
          setOrderBy={setOrderBy}
          setOrder={setOrder}
          setData={setData}
          data={data}
        />

        <TableContainer style={{ height: "62vh" }} omponent={Paper}>
          <Table
            size="small"
            stickyHeader
            aria-label="collapsible table"
            sx={{
              height: "max-content",
            }}
          >
            <ListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={data.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {data?.map((row) => (
                <Row key={row?.fund} row={row} />
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {isNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <Paper
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="h6" paragraph>
                        Not found
                      </Typography>

                      <Typography variant="body2">
                        No results found for &nbsp;
                        <strong>&quot;{filterName}&quot;</strong>.
                        <br /> Try checking for typos or using complete words.
                      </Typography>
                    </Paper>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[100, 500, 1000]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Container>
  );
};
export default AccountingTable;
