import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  FormControl,
  Autocomplete,
  useTheme,
  Typography,
} from "@mui/material";

import { useSelector } from "react-redux";

const ListToolbar = ({ sendDataToParent }) => {
  const [fundName, setFundName] = useState([]);
  const [fundVal, setFundVal] = useState("");
  const [fundAum, setFundAum] = useState("000000");

  const { funds, accountingData } = useSelector((state) => state?.todos);

  useEffect(() => {
    if (funds) {
      const filteredName = funds?.map((i) => {
        return i.name;
      });
      setFundName(filteredName);
    }
  }, [funds]);

  const setUpAum = (newValue) => {
    const filteredName = funds?.find((i) => {
      return i.name == newValue;
    });
    let foundObject = null;
    accountingData?.some((i) => {
      foundObject = i?.funds?.find((obj) => obj?.fund == filteredName?.fundId);
      return foundObject !== undefined;
    });

    foundObject.aum
      ? setFundAum(foundObject?.aum?.toFixed(2))
      : setFundAum("000000");
    sendDataToParent(foundObject?.strategyAUMs);
  };

  const theme = useTheme();
  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <Autocomplete
                disableClearable
                value={fundVal}
                onChange={(e, newValue) => {
                  setFundVal(newValue);
                  setUpAum(newValue);
                }}
                options={fundName?.map((val) => val)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    Label="Fund Name"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                    variant="standard"
                    value={fundVal}
                    label="Fund Name"
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={2}>
            <FormControl fullWidth>
              <Box
                sx={{
                  height: 45,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  borderRadius: 2,
                  border: "1px solid #000",
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.primary.main,
                    fontSize: theme.typography.bodyText12,
                    mt: 1.2,
                  }}
                >
                  Total Aum
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.primary.contrastTextDark,
                    fontSize: theme.typography.bodyTextB12,
                    mt: 1,
                  }}
                >
                  {fundAum}
                </Typography>
              </Box>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <Box
                sx={{
                  height: 45,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  borderRadius: 2,
                  border: "1px solid #000",
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.green.EmeraldGreen,
                    fontSize: theme.typography.bodyText12,
                    mt: 1.2,
                  }}
                >
                  Fund PnL
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.primary.contrastTextDark,
                    fontSize: theme.typography.bodyTextB12,
                    mt: 1,
                  }}
                >
                  000000
                </Typography>
              </Box>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ListToolbar;
