import Header from "../Header";
import SecurityMasterTable from "../SecurityMasterDetails/SecurityMasterTable";
const SecurityMasterLayout = () => {
  return (
    <>
      <Header>
        <SecurityMasterTable />
      </Header>
    </>
  );
};
export default SecurityMasterLayout;
