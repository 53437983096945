import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Grid,
  useTheme,
} from "@mui/material";

const Failedprompt = (props) => {
  const theme = useTheme();
  const {
    openTradeForCustodian,
    setOpenTradeForCustodian,
    title
  } = props;

  const SendBoFTrade = () => {
    setOpenTradeForCustodian(false);
  };
  return (
    <Dialog open={openTradeForCustodian} sx={{ width: "100%", height: "100%" }}>
      <DialogTitle>
        <Typography
          variant="h6"
          component="div"
          align="center"
          style={{ flexGrow: 1, fontSize: "20px", color:"green" }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="body" component="div" style={{ flexGrow: 1 }}>
          Request Failed!
        </Typography>
        <Grid
          item
          xs={12}
          margin="20px 1px 20px 1px "
          display="flex"
          justifyContent="center"
        >
          <Button
            variant="contained"
            sx={{
              width: "80%",
              marginTop:"20px",
              backgroundColor:"red"
            }}
            onClick={() => {
              SendBoFTrade();
            }}
          >
            Ok!
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default Failedprompt;
