import React from "react";
import TradeRouters from "../component/Layouts/TradeRouters";
import Topbar from "../component/NavComponents/Topbar";
import { Helmet } from "react-helmet";
const Routers = () => {
  return (
    <>
      <Helmet>
        <title>OMS</title>
      </Helmet>
      <Topbar title="OMS" />
      <TradeRouters />
    </>
  );
};
export default Routers;
