import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Grid,
  useTheme,
} from "@mui/material";

const ConfirmRegenerate = (props) => {
  const SendBoFTrade = () => {
    window.clientSocks.send('{"action": "create_eod","data":{"eod_type":"custodian"}}');
    setOpenRegenerate(false);
  };
  const theme = useTheme();
  const { title, openRegenerate, setOpenRegenerate } = props;
  return (
    <Dialog open={openRegenerate} sx={{ width: "100%", height: "100%" }}>
      <DialogTitle>
        <Typography
          variant="h6"
          component="div"
          align="center"
          style={{ flexGrow: 1, fontSize: "20px" }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="body" component="div" style={{ flexGrow: 1 }}>
          Are you sure you want to Regenerate Trades?
        </Typography>
        <Grid
          item
          xs={12}
          margin="20px 1px 20px 1px "
          display="flex"
          justifyContent="space-between"
        >
          <Button
            variant="contained"
            sx={{
              fontSize: "12px",
              fontWeight: "bold",
              height: "40px",
              width: "150px",
            }}
            onClick={() => {
              SendBoFTrade();
            }}
          >
            YES, GENERATE
          </Button>
          <Button
            variant="contained"
            sx={{
              fontSize: "12px",
              fontWeight: "bold",
              height: "40px",
              width: "150px",
              background: "#B2BEB5",
              color: "#000",
              "&:hover": { background: "#808080" },
            }}
            onClick={() => {
              setOpenRegenerate(false);
            }}
          >
            NO, CANCEL
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmRegenerate;
