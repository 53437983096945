import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Box,
  Divider,
} from "@mui/material";

const MarketDataUnavailablePopup = ({
  open,
  onClose,
  row,
  unvailableStratgies,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogContent>
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{ fontWeight: "bold" }}
        >
          Market Data Not Available for {row?.ticker}
        </Typography>
        <Typography variant="body2" paragraph>
          Remove it from the signals list of the following strategies and fetch
          the market data again.
        </Typography>

        {unvailableStratgies?.map((strategy, index) => (
          <Box key={index} sx={{ mb: 3 }}>
            <Typography variant="body2" sx={{}}>
              {strategy}
            </Typography>
            <Divider sx={{ borderBottomWidth: 1, borderColor: "grey.400" }} />
          </Box>
        ))}

        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button variant="contained" color="primary" onClick={onClose}>
            Close
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default MarketDataUnavailablePopup;
