const axios = require('axios');

export function fetchDeltasForTickers(tickers) {
    // // Split the string into an array of tickers
    // let tickerArray = tickers.split(',');

    // // This will contain all the ticker-delta objects
    // let tickerDeltas = [];
    
    // for(let ticker of tickerArray) {
    //     let deltaObj = await fetchDelta(ticker);
        
    //     // Ignore if deltaObj is not an object (it might be null)
    //     if(typeof deltaObj === 'object') {
    //         tickerDeltas.push(deltaObj);
    //     }
    // }
    
    // return tickerDeltas;

    return [
        {
          "VRDN": 0.40855546306933943
        },
        {
          "KALV": 0.9500260186819462
        },
        {
          "IONQ": 0.9793442567383062
        },
        {
          "FATE": -0.9090217522250046
        },
        {
          "RUN": 0.9743437126006397
        },
        {
          "DLTR": 0.9349603748248495
        },
        {
          "ARIS": 0.9650036204860413
        },
        {
          "NOVA": 0.9887785287395263
        },
        {
          "CBZ": 0.9629925130584499
        },
        {
          "CSR": 0.9739447655531567
        },
        {
          "HA": 0.9581572043079872
        },
        {
          "ORLY": 0.9976171603218548
        },
        {
          "ARI": 0.9817174066493403
        },
        {
          "GBIO": 0.8954218335871682
        },
        {
          "MDGL": 0.948068018229155
        },
        {
          "TDUP": 0.5165754243337404
        },
        {
          "MRNA": 0.9513240008864751
        },
        {
          "LSCC": 0.985833703037701
        },
        {
          "PDSB": 0.5247901314630249
        },
        {
          "ARWR": 0.9159671079377117
        },
        {
          "HPP": 0.9245216180660167
        },
        {
          "GLT": 0.2776531875082985
        },
        {
          "MASS": 0.9385119342774494
        },
        {
          "GOOG": 0.9859938517902692
        },
        {
          "SCVL": 0.9202962043506246
        },
        {
          "CDNA": 0.21433922146320422
        },
        {
          "AAON": 0.9242934087664882
        },
        {
          "ANET": 0.9428116160827447
        },
        {
          "SSP": 0.5716572579490606
        },
        {
          "PACK": 0.48717175051579054
        },
        {
          "IBKR": 0.9707154603845753
        },
        {
          "YMAB": 0.9791083973117616
        },
        {
          "MAX": 0.1936379771267884
        },
        {
          "CENX": 0.970525552025778
        },
        {
          "RVMD": 0.9721095757871417
        },
        {
          "CHRS": 0.371384533911798
        },
        {
          "LW": 0.98581837532634
        },
        {
          "MGNX": 0.405209997140854
        },
        {
          "RAPT": 0.9833576729735011
        },
        {
          "RPT": 0.5418851888404637
        },
        {
          "VNO": 0.9864690746855118
        },
        {
          "APPS": 0.986025589987011
        },
        {
          "HEI": 0.983111105876449
        },
        {
          "PTON": 0.9843924972479549
        },
        {
          "TDS": 0.973881054680506
        },
        {
          "PACB": 0.9254211266078837
        },
        {
          "QTRX": 0.961856457779309
        },
        {
          "WOR": 0.9843243123383975
        },
        {
          "RWT": 0.975004459922487
        },
        {
          "SOFI": 0.9911916527102945
        },
        {
          "APLD": 0.9236961394579344
        },
        {
          "KO": 0.97315698924025
        },
        {
          "ASPN": 0.9813967460883685
        },
        {
          "INBX": 0.9804622009488828
        },
        {
          "NEXT": 0.9939031828544386
        },
        {
          "BRZE": 0.9870867309302866
        },
        {
          "WM": 0.9765738720755549
        },
        {
          "HIPO": 0.9596694096532523
        },
        {
          "GMRE": 0.9706495637575462
        },
        {
          "WW": 0.9956362923166976
        },
        {
          "DX": 0.9665542410089097
        },
        {
          "CLF": 0.9614497357278243
        },
        {
          "RGNX": 0.9852932729768443
        },
        {
          "PENN": 0.9452994968762224
        },
        {
          "SLG": 0.9015073580947909
        },
        {
          "COGT": 0.9872040593177928
        },
        {
          "VSCO": 0.9284700994297055
        },
        {
          "MRNS": 0.980542150015585
        },
        {
          "CIO": 0.08330296510141236
        },
        {
          "SBUX": 0.9597483076757857
        },
        {
          "ENTA": 0.4944803712226547
        },
        {
          "RCKT": 0.9657391222763168
        },
        {
          "IOT": 0.9903586669647543
        },
        {
          "FG": 0.9339653907790384
        },
        {
          "OPEN": 0.9661075930920772
        },
        {
          "PTGX": 0.975036634172529
        },
        {
          "PSFE": 0.9660868421471648
        },
        {
          "AME": 0.9853392924436818
        },
        {
          "HR": 0.9714274033142629
        },
        {
          "THRX": 0.40263948694447027
        },
        {
          "SPR": 0.9559355876131505
        },
        {
          "DBRG": -0.007947798840721275
        },
        {
          "UPS": 0.9657764815527566
        },
        {
          "UAL": 0.863369096415901
        },
        {
          "NTNX": 0.9819553536631654
        },
        {
          "OMGA": 0.5055336730108219
        },
        {
          "EA": 0.9533248490375585
        },
        {
          "COST": 0.9863547543642741
        },
        {
          "SIX": 0.9846904818079243
        },
        {
          "IPAR": 0.9874841107352705
        },
        {
          "SNAP": 0.9801435542806043
        },
        {
          "WDAY": 0.9832453641054727
        },
        {
          "TXN": 0.9579731466198986
        },
        {
          "WOLF": 0.8965155856337697
        },
        {
          "EHTH": 0.9808208324625931
        },
        {
          "GH": 0.8858434827028873
        },
        {
          "HCP": 0.9851758406466338
        },
        {
          "GRPH": 0.4406081241931134
        },
        {
          "DHC": 0.9687969080782223
        },
        {
          "ANAB": 0.9787088121873585
        },
        {
          "KRTX": 0.9918102846645647
        },
        {
          "MMSI": 0.9651238675848688
        },
        {
          "ALXO": 0.9431782529612265
        },
        {
          "MORF": 0.9071964060172837
        },
        {
          "SPY": 0.9409796488105832
        },
        {
          "CIM": 0.9763008463633583
        },
        {
          "FOLD": 0.941861266685906
        },
        {
          "CRBU": 0.9884988065777173
        },
        {
          "MMC": 0.9851925652804361
        },
        {
          "RMBS": 0.9796415127028926
        },
        {
          "NUVL": 0.9489841941658675
        },
        {
          "PEP": 0.987496864297413
        },
        {
          "BW": 0.3829752207627611
        },
        {
          "LECO": 0.9775272190687014
        },
        {
          "ALEC": 0.22750068777299653
        },
        {
          "VC": 0.9895539756417282
        },
        {
          "LUNG": 0.9761884497505141
        },
        {
          "SAM": 0.9936738075229408
        },
        {
          "DOMO": 0.8815641820330018
        },
        {
          "DYN": 0.9438019991345863
        },
        {
          "CDRE": 0.9818775005921987
        },
        {
          "MSFT": 0.966430204772082
        },
        {
          "W": 0.9128508023526454
        },
        {
          "KALU": 0.9446330019383884
        },
        {
          "PRTA": 0.9375622242421359
        },
        {
          "PGRE": 0.8884749406923261
        },
        {
          "MBI": 0.9760910829996722
        },
        {
          "MNST": 0.5690904290533716
        },
        {
          "NOTE": 0.22562190483802722
        },
        {
          "BGFV": 0.3795590768220135
        },
        {
          "IVR": 0.9833770936800128
        },
        {
          "ACRS": 0.9353347856785664
        },
        {
          "ORC": 0.9498135213651058
        },
        {
          "SWTX": 0.9166775022808191
        },
        {
          "DNLI": 0.9255434501213773
        },
        {
          "STZ": 0.9857120267727162
        },
        {
          "KEYS": 0.9974386173860188
        },
        {
          "TSE": 0.8885078553222886
        },
        {
          "DSP": 0.30426518222390675
        },
        {
          "TXRH": 0.9785052181067657
        },
        {
          "AFRM": 0.9696874058417442
        },
        {
          "RPAY": 0.21821247393470045
        },
        {
          "ANSS": 0.9636960273334774
        },
        {
          "CWEN": 0.9389046771878384
        },
        {
          "MCB": 0.9386994713038422
        },
        {
          "MXCT": 0.8849193628802605
        },
        {
          "NRIX": 0.9781494689338586
        },
        {
          "RSG": 0.9977389865641085
        },
        {
          "SEER": -0.8714160805754009
        },
        {
          "ZTS": 0.9761578936901456
        },
        {
          "FEAM": 0.5464651389996059
        },
        {
          "OKTA": 0.9562035962409113
        },
        {
          "PGR": 0.9898635918534763
        },
        {
          "ARVN": 0.9780509791650523
        },
        {
          "GOCO": -0.021689864051967326
        },
        {
          "MCD": 0.9801092984357122
        },
        {
          "AXL": 0.6011578985172591
        },
        {
          "PHAT": 0.9663634271228175
        },
        {
          "GCO": 0.9948070449713682
        },
        {
          "GRWG": 0.9580253036668666
        },
        {
          "STX": 0.9941896378065916
        },
        {
          "PLUG": 0.9688451637860834
        },
        {
          "FDMT": 0.9979791118390635
        },
        {
          "NVDA": 0.977836646552092
        },
        {
          "NYMT": -0.046741893142001154
        },
        {
          "COIN": 0.9672534577410057
        },
        {
          "IPI": 0.8861087531387379
        },
        {
          "CARM": 0.3313981868415831
        },
        {
          "ON": 0.9255590791859488
        },
        {
          "BBIO": 0.9781362403811874
        },
        {
          "HSY": 0.9800898364734666
        },
        {
          "WMT": 0.9743752698173475
        },
        {
          "BDC": 0.9865207757573096
        },
        {
          "TVTX": 0.62380439638501
        },
        {
          "WULF": 0.9002659036540803
        },
        {
          "CPSS": 0.9532720933328203
        },
        {
          "CABA": 0.9789345049320824
        },
        {
          "TNYA": 0.7689474191193342
        },
        {
          "ALLO": 0.9044624762089761
        },
        {
          "ALGM": 0.9288853672350414
        },
        {
          "CMG": 0.9776592673549064
        },
        {
          "IHRT": 0.6531789064672631
        },
        {
          "LWLG": 0.5421927856115857
        },
        {
          "WBD": 0.9667857517603928
        },
        {
          "AAL": 0.9523234279013819
        },
        {
          "EVER": 0.29776778993309766
        },
        {
          "ALT": 0.9580767568452038
        },
        {
          "IVAC": 0.3086364348612751
        },
        {
          "MEG": 0.9336775778361676
        },
        {
          "HFFG": 0.9417039717766884
        },
        {
          "CAT": 0.9545147052296884
        },
        {
          "EVLV": 0.9941870342258692
        },
        {
          "J": 0.9834177780834268
        },
        {
          "SNDX": 0.9786212106769135
        },
        {
          "CZR": 0.943722490233791
        },
        {
          "GRNT": 0.1050392284314905
        },
        {
          "CRNX": 0.9856101098585799
        },
        {
          "DC": 0.7689217724486841
        },
        {
          "FUBO": 0.9667950627440927
        },
        {
          "BJRI": 0.9322888304607938
        },
        {
          "IRBT": 0.9409255560352937
        },
        {
          "RIVN": 0.8716236437998217
        },
        {
          "LFST": 0.880531235302268
        },
        {
          "SGHT": 0.8888697457884663
        },
        {
          "MU": 0.9777290622020867
        },
        {
          "CL": 0.9767191254340377
        },
        {
          "ATEC": 0.9607777503064091
        },
        {
          "A": 0.9674002895102655
        },
        {
          "TMDX": 0.9566387173015265
        },
        {
          "VRE": 0.9751462776928269
        },
        {
          "GOOD": 0.8655974458341492
        },
        {
          "CCBG": 0.9551873753564211
        },
        {
          "HOOD": 0.9863094803360987
        },
        {
          "VRTX": 0.9682231570874111
        },
        {
          "KYMR": 0.9708589137661832
        },
        {
          "DEI": 0.9800487580146492
        },
        {
          "CLPR": 0.9114921116087361
        },
        {
          "BPMC": 0.9928169194988316
        },
        {
          "IMVT": 0.8373529099857882
        },
        {
          "MRSN": 0.27593978537750774
        },
        {
          "YUM": 0.9453022477862763
        },
        {
          "CUTR": 0.9947629833478185
        },
        {
          "AVGO": 0.9623047038503795
        },
        {
          "GGG": 0.9873124710200879
        },
        {
          "CNSL": 0.9770542271501403
        },
        {
          "WLY": 0.9227348113888006
        },
        {
          "FYBR": 0.9985192881053965
        },
        {
          "SFIX": 0.9725270328118516
        },
        {
          "WOOF": 0.1446177507097525
        },
        {
          "UNP": 0.972567406100687
        },
        {
          "CDLX": 0.9860720118538759
        },
        {
          "XPER": 0.2922352718665332
        },
        {
          "WISH": 0.9590143273987848
        },
        {
          "NVCR": 0.9762962251895528
        },
        {
          "IQV": 0.9992559828417764
        },
        {
          "FFIC": 0.9779800717510755
        },
        {
          "ZIMV": 0.9354260667030757
        },
        {
          "GDYN": 0.9849912010551122
        },
        {
          "CSTL": 0.909406553004557
        },
        {
          "SHAK": 0.9947095662060758
        },
        {
          "AMPS": 0.3429194804444427
        },
        {
          "AA": 0.9595970605039358
        },
        {
          "RARE": 0.9192521784713176
        },
        {
          "SJM": 0.9880917323536701
        },
        {
          "EQRX": 0.28795754628284237
        },
        {
          "BWXT": 0.973327228738989
        },
        {
          "BXP": 0.9472956136438461
        },
        {
          "DCI": 0.9381449258238208
        },
        {
          "EGBN": 0.4577174817396568
        },
        {
          "VIR": 0.9024758705697106
        },
        {
          "PAYX": 0.9784039311507119
        },
        {
          "PLRX": 0.9773806449794221
        },
        {
          "HUBB": 0.9950397411305422
        },
        {
          "NDSN": 0.9820143830819783
        },
        {
          "AZPN": 0.9972109645470514
        },
        {
          "TBPH": 0.978553957377319
        },
        {
          "CPS": 0.9846087910889243
        },
        {
          "BCRX": 0.9392439125327045
        },
        {
          "ALVR": 0.3776762962047618
        },
        {
          "INSM": 0.9248852003249415
        },
        {
          "MDB": 0.9278170893406678
        },
        {
          "RIOT": 0.9678136874337349
        },
        {
          "MSOS": 0.9634871210210091
        },
        {
          "RDFN": 0.9918409087591715
        },
        {
          "ABT": 0.9621834722125573
        },
        {
          "MA": 0.9651851340175894
        },
        {
          "G": 0.965428112348637
        },
        {
          "IPG": 0.9745438430948608
        },
        {
          "KROS": 0.9173805297643259
        },
        {
          "SIGA": 0.7242370873455835
        },
        {
          "GPMT": 0.22912408562210562
        },
        {
          "FMC": 0.9625223565505769
        },
        {
          "OCUL": 0.9591280527091121
        },
        {
          "LRMR": 0.7385187292563614
        },
        {
          "INSW": 0.968609916918704
        },
        {
          "BIGC": 0.42715153487595126
        },
        {
          "SFBS": 0.9576952716331845
        },
        {
          "TCX": 0.9812031522935271
        },
        {
          "NATI": 0.9642023816094897
        },
        {
          "RBLX": 0.9168214799821647
        },
        {
          "AURA": 0.7318763486005719
        },
        {
          "WRLD": 0.9928701810691302
        },
        {
          "OPI": 0.7984268109966359
        },
        {
          "GEVO": 0.6221664749376652
        },
        {
          "CLDX": 0.9102203192281507
        },
        {
          "TT": 0.9792755480582526
        },
        {
          "HOUS": 0.9867418053611325
        },
        {
          "UNIT": 0.9639454973334401
        },
        {
          "PDM": 0.9111433436983152
        },
        {
          "TPR": 0.9493080684251579
        },
        {
          "ARQT": 0.8735186984998056
        },
        {
          "KBR": 0.9499592110844485
        },
        {
          "HON": 0.9730688949316852
        },
        {
          "ELAN": 0.38393369576564207
        },
        {
          "IEX": 0.9849957483453632
        },
        {
          "QURE": 0.9883700052824915
        },
        {
          "DBI": 0.9855809597008851
        },
        {
          "EAF": 0.9476029871100343
        },
        {
          "FLYW": 0.9668863927292379
        },
        {
          "NEWT": 0.8548010466040652
        },
        {
          "MATW": 0.9630835891147186
        },
        {
          "EGLE": 0.9770045962228787
        },
        {
          "FFWM": 0.1365094972321676
        },
        {
          "CLSK": 0.8936136317103829
        },
        {
          "BDN": 0.9504505825725766
        },
        {
          "TXG": 0.9417345442425364
        },
        {
          "DY": 0.96487729671245
        },
        {
          "EZA": 0.9754740942942587
        },
        {
          "PCOR": 0.5709041536722101
        },
        {
          "CIEN": 0.9619816959788646
        },
        {
          "MQ": 0.9736332850182479
        },
        {
          "TNDM": 0.9100870087317896
        },
        {
          "OCN": 0.8945058451688132
        },
        {
          "ANGO": 0.945227400006102
        },
        {
          "ICVX": 0.9321791582829787
        },
        {
          "WAB": 0.9861342156337468
        },
        {
          "KMX": 0.9887730686533628
        },
        {
          "JBGS": 0.8786400079263433
        },
        {
          "LICY": 0.9431225394628064
        },
        {
          "AMD": 0.9841528009411196
        },
        {
          "EOLS": 0.9568910528453407
        },
        {
          "CWH": 0.9921138350255759
        },
        {
          "SAGE": 0.9538144866402937
        },
        {
          "VRSK": 0.9878627138434559
        },
        {
          "AMPX": 0.8467616674426861
        },
        {
          "PG": 0.9778454348885451
        },
        {
          "AMZN": 0.948571974898939
        },
        {
          "CXM": 0.9899360034299045
        },
        {
          "HLT": 0.9895676081098885
        },
        {
          "ANIK": 0.9804413815360323
        },
        {
          "BLFS": 0.998246396622002
        },
        {
          "URGN": 0.9465365430993781
        },
        {
          "BASE": 0.9567089553967133
        },
        {
          "SPHR": 0.9796225619164833
        },
        {
          "MYGN": 0.9750096822583066
        },
        {
          "XEL": 0.9905620137247991
        },
        {
          "CHD": 0.9828259890334895
        },
        {
          "IMGN": 0.9912384955992118
        },
        {
          "AMRK": 0.665283419526008
        },
        {
          "OSUR": 0.8043423056704351
        },
        {
          "EXTR": 0.5868823524857331
        },
        {
          "NCNO": 0.9517966994017206
        },
        {
          "CDNS": 0.9659605987575705
        },
        {
          "ATOM": 0.2550074871175506
        },
        {
          "STEM": 0.9855212390228402
        },
        {
          "GLUE": 0.6653864368509266
        },
        {
          "WAL": 0.9857127644987381
        },
        {
          "SITM": 0.9579091761436899
        },
        {
          "CMTG": 0.9792775215221616
        },
        {
          "SABR": 0.979642681468697
        },
        {
          "SNPS": 0.9777623289355272
        },
        {
          "OMC": 0.9788723401320727
        },
        {
          "NBIX": 0.9729468136444624
        },
        {
          "VERA": 0.9526314039187702
        },
        {
          "GME": 0.9942007913532664
        },
        {
          "USM": 0.9961287325202057
        },
        {
          "MRK": 0.9674736309494095
        },
        {
          "ANNX": 0.3623051651060415
        },
        {
          "HWM": 0.9865957462175742
        },
        {
          "ITW": 0.9608542353153681
        },
        {
          "BKD": 0.9862419558866498
        },
        {
          "RNA": 0.11681826473317679
        },
        {
          "ACIC": 0.9554932833557772
        },
        {
          "SNOW": 0.957824786544055
        },
        {
          "MCBC": 0.9941100061893098
        },
        {
          "NREF": 0.9898843092902293
        },
        {
          "HQY": 0.9925728377597585
        },
        {
          "VKTX": 0.9871060038625786
        },
        {
          "TJX": 0.9606661216557836
        },
        {
          "ENS": 0.976787959869478
        },
        {
          "S": 0.9837147396092513
        },
        {
          "RXRX": 0.34401656543008324
        },
        {
          "EVA": 0.8283088368780254
        },
        {
          "IIPR": 0.5730759221821116
        },
        {
          "BLX": 0.9311739759747328
        },
        {
          "VTR": 0.9810273024433865
        },
        {
          "IR": 0.9691588456638356
        },
        {
          "GIS": 0.9565909330385254
        },
        {
          "MRVL": 0.9096090903416697
        },
        {
          "TPC": 0.9866653358249249
        },
        {
          "CLH": 0.9989453986284524
        },
        {
          "CRMT": 0.8024350021037598
        },
        {
          "HMST": 0.9472000846539994
        },
        {
          "AAPL": 0.9796462075086215
        },
        {
          "CROX": 0.9103036140318482
        },
        {
          "LE": 0.9280496249064151
        },
        {
          "EWTX": 0.46844317287160075
        },
        {
          "NEO": 0.910243531046143
        },
        {
          "CRDO": 0.9697785715926022
        },
        {
          "WLDN": 0.8496214205097914
        },
        {
          "V": 0.9643211657251435
        },
        {
          "HCA": 0.9690159466524209
        },
        {
          "SRRK": 0.9607009764356376
        },
        {
          "SPCE": 0.8831420674763338
        },
        {
          "STWD": 0.970106336730274
        },
        {
          "DG": 0.9753945861471358
        },
        {
          "HROW": 0.9720028222129722
        },
        {
          "LITE": 0.9467892337292221
        },
        {
          "MATV": 0.8487345053891475
        },
        {
          "PATH": 0.9849136307819794
        },
        {
          "XAIR": 0.41092349525696825
        },
        {
          "EMR": 0.9911152863500313
        },
        {
          "REVG": 0.99179954385374
        },
        {
          "QQQ": 0.9156510072865318
        },
        {
          "LEU": 0.9699527433927484
        },
        {
          "CVGW": 0.962442615090152
        },
        {
          "SGEN": 0.9981027485902627
        },
        {
          "MDLZ": 0.971868639472272
        },
        {
          "RMD": 0.995774360076093
        },
        {
          "SMAR": 0.9441057329807556
        },
        {
          "BMI": 0.9881684697312271
        },
        {
          "CRGY": 0.7740861918685851
        },
        {
          "MAC": 0.9904567090727991
        },
        {
          "WGO": 0.9804170791226998
        },
        {
          "PACW": 0.9785234547209791
        },
        {
          "HUMA": 0.10725933644251358
        },
        {
          "TROX": 0.9555493134363983
        },
        {
          "VERI": 0.41516677912139266
        },
        {
          "CBRL": 0.9185586033290877
        },
        {
          "TGT": 0.9587136968602272
        },
        {
          "ITOS": 0.6465671302519291
        },
        {
          "CAR": 0.9698967187916114
        },
        {
          "DOV": 0.9898077850751953
        },
        {
          "UEC": 0.9901622775485948
        },
        {
          "AIV": 0.09051235597224586
        },
        {
          "IBTX": 0.9412918787061382
        },
        {
          "ZION": 0.9767190860335114
        },
        {
          "RXT": 0.8934035838329832
        },
        {
          "SANA": 0.8305975088398487
        },
        {
          "MET": 0.9512195867271607
        },
        {
          "VSAT": 0.6170279432347178
        },
        {
          "REPL": 0.9799920272576144
        },
        {
          "KWEB": 0.9159111231684793
        },
        {
          "CARS": 0.9806051975622117
        },
        {
          "SIG": 0.9981476154473294
        },
        {
          "ACM": 0.9644606151249431
        },
        {
          "PD": 0.43100360468330756
        },
        {
          "TLYS": 0.9545235820302342
        },
        {
          "T": 0.98864644529476
        },
        {
          "BKE": 0.9425234531994874
        },
        {
          "INGR": 0.9894365060511762
        },
        {
          "GTN": 0.799438056770224
        },
        {
          "PAR": 0.5885386363053207
        },
        {
          "GRND": 0.1081936445008857
        },
        {
          "MRTX": 0.9578451446543671
        },
        {
          "IRT": 0.97358744768982
        },
        {
          "ASAN": 0.9797282856482634
        },
        {
          "OSTK": 0.9872613657844096
        },
        {
          "OMER": 0.9218747763137728
        },
        {
          "INFN": 0.3465711003430848
        },
        {
          "RILY": 0.9408796410080184
        },
        {
          "AGM": 0.9946303779771598
        },
        {
          "SAVE": 0.9755598135657578
        },
        {
          "WD": 0.9329727492914871
        },
        {
          "AGEN": 0.6475538737740081
        },
        {
          "CRMD": 0.9488734635571612
        },
        {
          "SGML": 0.9693663403608543
        },
        {
          "SDGR": 0.9597882425291153
        },
        {
          "HASI": 0.921414015009228
        },
        {
          "NTLA": 0.9604172982329553
        },
        {
          "JKHY": 0.9493667270408774
        },
        {
          "PRLD": 0.7744858901924585
        },
        {
          "JBLU": 0.9840771342459718
        },
        {
          "EXAS": 0.9455425207642795
        },
        {
          "VERV": 0.9682996018318689
        },
        {
          "MDXG": 0.9384640765792759
        },
        {
          "GERN": 0.9465516099238235
        },
        {
          "CCO": 0.7272451750396067
        },
        {
          "ERAS": 0.45234698400135775
        },
        {
          "ZUO": 0.9217681925696013
        },
        {
          "ARGO": 0.918000396912268
        },
        {
          "AZO": 0.9952009196295224
        },
        {
          "SHEN": 0.9815995725750178
        },
        {
          "PPG": 0.990302029316077
        },
        {
          "EOSE": 0.9590370494437943
        },
        {
          "EWQ": 0.9753757080500817
        },
        {
          "IWM": 0.9199044374130926
        },
        {
          "ESTC": 0.9664521546014551
        },
        {
          "INVH": 0.9920058033314688
        },
        {
          "PLBY": 0.20169057169737986
        },
        {
          "ABR": 0.9884704712137882
        },
        {
          "LILM": 0.2024304310353964
        },
        {
          "PRO": 0.976598278090136
        },
        {
          "TBLA": 0.09685711600269156
        },
        {
          "BOWL": 0.9805741883935506
        },
        {
          "RDW": 0.8220759645037308
        },
        {
          "FHN": 0.9743696162853166
        },
        {
          "NVRI": 0.977371628319363
        },
        {
          "AKR": 0.9760744889476157
        },
        {
          "PCT": 0.9751908192483801
        },
        {
          "RELY": 0.9853988984743186
        },
        {
          "RPHM": 0.6877216937349889
        },
        {
          "NVGS": 0.9707230395724613
        },
        {
          "ACMR": 0.9864352559118569
        },
        {
          "CATC": 0.9643054531500284
        },
        {
          "BWB": 0.9257499129922214
        },
        {
          "HUN": 0.9998393592112519
        },
        {
          "CRK": 0.802510999260831
        },
        {
          "HPK": 0.5437440741253605
        },
        {
          "TUSK": 0.9577325255993637
        },
        {
          "QNST": 0.978909819635215
        },
        {
          "GNW": 0.9956438487622175
        },
        {
          "KLXE": 0.9782397727146894
        },
        {
          "RAMP": 0.9961730816583508
        },
        {
          "UPLD": 0.21584307415365125
        },
        {
          "TEX": 0.9469296678615433
        }
      ]
}


const fetchDelta = async (ticker) => {

    function findDelta(jsonResponse) {
        for(let obj of jsonResponse.results) {
            if(obj.greeks && 'delta' in obj.greeks && obj.underlying_asset && 'ticker' in obj.underlying_asset) {
                let tickerDeltaObj = {};
                // Form the object {ticker:delta}
                tickerDeltaObj[obj.underlying_asset.ticker] = obj.greeks.delta;
                return tickerDeltaObj;
            }
        }
        return 0.5; 
      }

    const request = {
        method: 'GET',
        url: 'https://api.polygon.io/v3/snapshot/options/'+ticker+'?apiKey=qUetFLpnNrWmmjViK1__yb8tkPELYAlD'
    };
    


    try {
        const response = await axios.request(request);
        let delta = findDelta(response.data);
        return delta
    } catch (error) {
       
    }    
}





