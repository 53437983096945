import { Helmet } from "react-helmet";
import TradeBroker from "../component/Layouts/TradeBroker";
import Topbar from "../component/NavComponents/Topbar";

const Broker = () => {
  return (
    <>
      <Helmet>
        <title>Brokers</title>
      </Helmet>
      <Topbar title="Brokers" />
      <TradeBroker />
    </>
  );
};
export default Broker;
