import React from "react";
import TradeWatchList from "../component/Layouts/TradeWatchList";
import Topbar from "../component/NavComponents/Topbar";
import { Helmet } from "react-helmet";
const WatchList = () => {
  return (
    <>
      <Helmet>
        <title>WatchList</title>
      </Helmet>
      <Topbar title="Watch List" />
      <TradeWatchList />
    </>
  );
};
export default WatchList;
