import Footer from "../Footer";
import Header from "../Header";
import VantageSystem from "../VantageSystem";
const VantageDashboardLayout = () => {
  return (
    <>
      <Header>
        <VantageSystem />
      </Header>
      
    </>
  );
};
export default VantageDashboardLayout;
