import React from "react";
import ForgotUserPassword from "../component/UserPassword/ForgotUserPassword";

const ForgotPassword = () => {
  return (
    <>
      <ForgotUserPassword />
    </>
  );
};

export default ForgotPassword;
