import React, { useState, useEffect } from "react";
import {
  Box,
  styled,
  Table,
  TableBody,
  TableRow,
  TableCell,
  useTheme,
  Typography,
  IconButton,
} from "@mui/material";

import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { formatValue, HtmlTooltip } from "../Methods";
import { formatTimeAgo } from "../../utils/helpers";
import BenchmarkPopUp from "../Popup/BenchmarkPopUp";

const Tablecell = styled(TableCell)(({ theme }) => ({
  fontSize: "12px",
  padding: "1px 2px 1px 5px",
}));

const BenchMarkTable = ({ marketData }) => {
  const [benchmarksWithMarketData, setBenchmarksWithMarketData] = useState([]);
  const [isGridViewBenchmark, setIsGridViewBenchmark] = useState(false);
  const benchmark = ["", "Non-Market Hours", "Market Hours"];

  useEffect(() => {
    const benchmarks = [
      { name: "NASDAQ", symbol: "nqa", symbol2: "qqq" },
      { name: "S&P 500", symbol: "esa", symbol2: "spy" },
      { name: "RUSSELL 2000", symbol: "rtya", symbol2: "iwm" },
      { name: "DOW JONES", symbol: "dma", symbol2: "dia" },
      { name: "RUSSELL 3000", symbol: "rus", symbol2: "iky" },
    ];

    const updated = benchmarks.map((benchmark) => {
      const matchingData = marketData?.find(
        (data) => data?.ticker === benchmark.symbol
      );
      const matchingData2 = marketData?.find(
        (data) => data?.ticker === benchmark.symbol2
      );
      const toReturn = {
        name: benchmark.name,
        symbol1: benchmark.symbol,
        val1: 0,
        date1: "N/A",
        symbol2: benchmark.symbol2,
        val2: 0,
        date2: "N/A",
      };
      if (matchingData) {
        const { last, current, date } = matchingData;
        const chPer = (((current - last) / last) * 100).toFixed(2);
        toReturn.val1 = chPer;
        if (date) toReturn.date1 = date;
      }
      if (matchingData2) {
        const { last, current, date } = matchingData2;
        const chPer = (((current - last) / last) * 100).toFixed(2);
        toReturn.val2 = chPer;
        toReturn.date2 = date;
      }
      return toReturn;
    });
    setBenchmarksWithMarketData(updated);
  }, [marketData]);
  const toggleGridViewBenchmark = () => {
    setIsGridViewBenchmark(!isGridViewBenchmark);
  };

  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          border: `1px solid ${theme.palette.lightGrey.tableHeader}`,
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            background: theme.palette.lightGrey.tableHeader,
            px: 1,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{ color: theme.palette.lightGrey.textColor }}
          >
            Benchmark Intraday Performance
          </Typography>
          <IconButton
            onClick={toggleGridViewBenchmark}
            aria-label="Toggle GridView"
            sx={{ height: "20px", width: "20px" }}
          >
            <FullscreenIcon sx={{ height: "15px", width: "15px" }} />
          </IconButton>
        </Box>
        <Table size="small">
          <TableRow>
            {benchmark?.map((i, index) => {
              return (
                <Tablecell
                  key={index}
                  colSpan={2}
                  align="center"
                  sx={{
                    borderRight: `1px solid ${theme.palette.lightGrey.tableHeader}`,
                    color: theme.palette.lightGrey.textColor,
                    fontSize: theme.typography.bodyText12.fontSize,
                    fontWeight: theme.typography.bodyText12.fontWeight,
                  }}
                >
                  {i}
                </Tablecell>
              );
            })}
          </TableRow>

          <TableBody>
            {benchmarksWithMarketData.map((row) => (
              <TableRow
                key={row.name}
                sx={{
                  "&:hover": {
                    background: theme.palette.grey[200],
                    cursor: "pointer",
                  },
                }}
              >
                <Tablecell
                  colSpan={2}
                  align="left"
                  onClick={() => {
                    //   handleBenchMark(row);
                  }}
                  sx={{
                    color: theme.palette.lightGrey.textColor,
                    fontSize: theme.typography.bodyText12.fontSize,
                    fontWeight: theme.typography.bodyText12.fontWeight,
                    borderRight: `1px solid ${theme.palette.lightGrey.tableHeader}`,
                  }}
                >
                  {row?.name}
                </Tablecell>

                <Tablecell
                  align="left"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  {row.symbol1}
                </Tablecell>
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit" sx={{ fontSize: "12px" }}>
                        Market:<b> Bloomberg </b>
                      </Typography>

                      <Typography color="inherit" sx={{ fontSize: "12px" }}>
                        Updated: <b>{formatTimeAgo(row?.date1).msg}</b>
                      </Typography>
                    </React.Fragment>
                  }
                >
                  <Tablecell
                    align="right"
                    style={{
                      color: row.val1 >= 0 ? "green" : "red",
                    }}
                  >
                    {row.val1 >= 0 ? (
                      <>{formatValue(row.val1)}%&nbsp;</>
                    ) : (
                      `(${formatValue(row.val1)}%)`
                    )}
                  </Tablecell>
                </HtmlTooltip>
                <Tablecell
                  align="left"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  {row.symbol2}
                </Tablecell>
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit" sx={{ fontSize: "12px" }}>
                        Market:<b> Bloomberg </b>
                      </Typography>

                      <Typography color="inherit" sx={{ fontSize: "12px" }}>
                        Updated: <b>{formatTimeAgo(row?.date2).msg}</b>
                      </Typography>
                    </React.Fragment>
                  }
                >
                  <Tablecell
                    align="right"
                    style={{
                      color: row.val2 >= 0 ? "green" : "red",
                    }}
                  >
                    {row.val2 >= 0 ? (
                      <>{row.val2}%&nbsp;</>
                    ) : (
                      `(${row.val2 * -1}%)`
                    )}
                  </Tablecell>
                </HtmlTooltip>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <BenchmarkPopUp
        openBenchmarkPopup={isGridViewBenchmark}
        setopenBenchmarkPopup={setIsGridViewBenchmark}
        benchmarksWithMarketData={benchmarksWithMarketData}
        tablehead={benchmark}
        title="Benchmark Intraday Performance"
      />
    </>
  );
};
export default BenchMarkTable;
