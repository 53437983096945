import React, { useState, useEffect } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Paper, useTheme } from "@mui/material";
import { childTradesColumns } from "../../Methods/columnHeader";

const DetailPanelContent = ({ row: rowProp }) => {
  const openInDeletePopup = (item) => {
    const updatedDistributions = rowProp?.tradeObject?.orders?.slice(-1)[0];
    const tradeId = updatedDistributions?.tradeId;

    const updatedDistribution = Object.fromEntries(
      Object.entries(updatedDistributions?.distribution || {}).filter(
        ([key, value]) => {
          const [account] = key.split("#");
          return account !== item.account;
        }
      )
    );

    const updatedRowProp = {
      ...updatedDistributions,
      distribution: updatedDistribution,
    };

    window.clientSocks.send(
      `{"action":"modify_orders", "data":[{"order":${JSON.stringify(
        updatedRowProp
      )},
          "tradeid":${JSON.stringify(tradeId)}
        }]}`
    );
  };

  const theme = useTheme();

  return (
    <>
      {rowProp?.distributions && rowProp?.distributions.length > 0 ? (
        <div
          style={{ py: 1, height: "100px", boxSizing: "border-box" }}
          direction="column"
        >
          <Paper sx={{ flex: 1, mx: "auto", width: "100%" }}>
            <DataGridPro
              density="compact"
              rowHeight={20}
              sx={{
                margin: 0,
              }}
              columns={childTradesColumns(theme, openInDeletePopup)}
              rows={rowProp?.distributions}
            />
          </Paper>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
export default DetailPanelContent;
