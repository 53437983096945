import Header from "../Header";
import WatchListTable from "../WatchListDetails/WatchListTable";
const FundAllocatorLayout = () => {
  return (
    <>
      <Header>
        <WatchListTable/>
      </Header>
    </>
  );
};
export default FundAllocatorLayout;
