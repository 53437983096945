import React, { useEffect } from "react";
import Topbar from "../component/NavComponents/Topbar";
import { Helmet } from "react-helmet";
import NotificationLayout from "../component/Layouts/NotificationLayout";
import { requestMarketData } from "../Redux/Actions/todoActions";
import { useDispatch, useSelector } from "react-redux";
const Notification = () => {
  const { lots } = useSelector((state) => state?.todos);
  const dispatch = useDispatch();
  // useEffect(() => {
  //   if (lots.length > 0) {
  //     dispatch(requestMarketData({ lots: lots, threshold: 15 }));
  //   }
  // }, [lots]);

  return (
    <>
      <Helmet>
        <title>Notifications</title>
      </Helmet>
      <Topbar title="Notifications" />
      <NotificationLayout />
    </>
  );
};
export default Notification;
