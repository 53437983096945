import { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  TextField,
  Grid,
  IconButton,
  FormControl,
  Autocomplete,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useState } from "react";

const BulkEdit = (props) => {
  const theme = useTheme();

  const { title, bulkEdit, setBulkEdit, selectionModel } = props;
  const { data, routeList } = useSelector((state) => state?.todos);
  const [brokername, setbrokername] = useState("");
  const [routername, setroutername] = useState("");
  const [instructions, setinstructions] = useState("");
  const [commission, setcommission] = useState();
  const [brokerObj, setbrokerObj] = useState();
  const [routerObj, setrouterObj] = useState();

  const user = useSelector((state) => state?.users?.user?.username);

  const handleClose = () => {
    setBulkEdit(false);
  };
  const brokerlist = data?.map((val) => val?.name);
  const routerlist = routeList?.map((val) => val?.abbreviation);
  const filterOptions = (options, state) => {
    if (state.inputValue.length > 0) {
      return options.filter((item) =>
        String(item).toLowerCase().startsWith(state.inputValue.toLowerCase())
      );
    }
    return options;
  };
  useEffect(() => {
    setbrokername("");
    setinstructions("");
    setcommission(null);
    setbrokerObj(null);
  }, [bulkEdit, selectionModel]);

  const updateOrders = (modificationType) => {
    if (!selectionModel) {
      return;
    }

    const updatedData = selectionModel.map((obj) => {
      const { tradeObject, tradeId } = obj;

      if (tradeObject && tradeObject.orders && tradeObject.orders.length > 0) {
        const lastOrder = tradeObject.orders[tradeObject.orders.length - 1];
        const { ...restOrder } = lastOrder;

        return {
          tradeid: tradeId,
          order: {
            ...restOrder,
            [modificationType]:
              modificationType === "broker" && !brokerObj
                ? null
                : modificationType === "broker"
                ? brokerObj
                : modificationType === "instructions"
                ? instructions
                : modificationType === "commission"
                ? commission
                : null,
          },
        };
      } else {
        return null;
      }
    });

    const filteredData = updatedData.filter((data) => data !== null);

    if (filteredData.length > 0) {
      try {
        if (
          modificationType === "broker" &&
          (!brokerObj || brokername === "Select Broker")
        ) {
          alert("Broker is required");
        } else if (
          modificationType === "instructions" &&
          !instructions.trim()
        ) {
          alert("Instructions is required");
        } else if (
          modificationType === "commission" &&
          (!commission || isNaN(Number(commission)))
        ) {
          alert("Commission is required and should be a number");
        } else {
          if (modificationType === "commission") {
            const commissionData = filteredData.map(({ tradeid }) => ({
              commission: Number(commission),
              trade: tradeid,
              user: user,
            }));
            const stringifiedData = JSON.stringify(commissionData);
            window.clientSocks.send(
              `{"action":"modify_trade_commissions", "data":${stringifiedData}}`
            );
          } else {
            const stringifiedData = JSON.stringify(filteredData);
            window.clientSocks.send(
              `{"action":"modify_orders", "data":${stringifiedData}}`
            );
          }

          setBulkEdit(false);
        }
      } catch (error) {
        console.error("Error while sending modified orders:", error);
      }
    }
  };

  return (
    <Dialog
      open={bulkEdit}
      onClose={handleClose}
      sx={{ width: "100%", height: "100%" }}
    >
      <DialogContent dividers>
        <div>
          <Typography
            variant="h6"
            component="div"
            align="center"
            style={{
              flexGrow: 1,
              fontSize: "20px",
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            {title}
          </Typography>
          <Typography
            style={{ flexGrow: 1, fontSize: "13px", textAlign: "center" }}
          >
            Select the property you want to update.
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <FormControl sx={{ width: "50%" }}>
                  <Autocomplete
                    disableClearable
                    value={brokername}
                    autoSelect
                    autoHighlight
                    autoComplete
                    ListboxProps={{
                      sx: { fontSize: 12 },
                    }}
                    sx={{
                      marginTop: "30px",
                      "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
                        fontSize: 12,
                      },
                    }}
                    onChange={(e, newValue) => {
                      setbrokername(newValue);

                      const brokerObject = data?.find(
                        (e) => e.name === newValue
                      );

                      if (brokerObject) {
                        setbrokerObj(brokerObject.brokerId);
                      } else {
                        setbrokerObj();
                      }
                    }}
                    onBlur={(e) => {
                      const value = e.target.value;

                      if (!value) {
                        setbrokername("");
                        setbrokerObj();
                      }
                    }}
                    options={brokerlist}
                    filterOptions={(brokerlist, state) =>
                      filterOptions(brokerlist, state)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Select Broker"
                        value={brokername}
                      />
                    )}
                  />
                </FormControl>

                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    updateOrders("broker");
                  }}
                  style={{
                    marginTop: "25px",
                    width: "180px",
                    backgroundColor: "your-button-color",
                    "&:hover": {
                      backgroundColor: "your-hover-color",
                    },
                    "&:active": {
                      backgroundColor: "your-active-color",
                    },
                  }}
                >
                  Update Broker
                </Button>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <FormControl sx={{ width: "50%" }}>
                  <Autocomplete
                    disableClearable
                    value={routername}
                    autoSelect
                    autoHighlight
                    autoComplete
                    ListboxProps={{
                      sx: { fontSize: 12 },
                    }}
                    sx={{
                      marginTop: "20px",
                      "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
                        fontSize: 12,
                      },
                    }}
                    onChange={(e, newValue) => {
                      setroutername(newValue);

                      const routerObject = routeList?.find(
                        (e) => e.abbreviation === newValue
                      );

                      if (routerObject) {
                        setrouterObj(routerObject.routerId);
                      } else {
                        setrouterObj();
                      }
                    }}
                    onBlur={(e) => {
                      const value = e.target.value;

                      if (!value) {
                        setbrokername("");
                        setbrokerObj();
                      }
                    }}
                    options={routerlist}
                    filterOptions={(routerlist, state) =>
                      filterOptions(routerlist, state)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Select Router"
                        value={routername}
                      />
                    )}
                  />
                </FormControl>

                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    // updateOrders("router");
                  }}
                  style={{
                    marginTop: "15px",
                    width: "180px",
                    backgroundColor: "#ED6C02",
                    "&:hover": {
                      backgroundColor: "#D16206 !important",
                    },
                    "&:active": {
                      backgroundColor: "#D16206 !important",
                    },
                  }}
                >
                  Update Router
                </Button>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <FormControl sx={{ width: "50%", marginTop: "10px" }}>
                  <TextField
                    fullWidth
                    id="instructions"
                    placeholder="Order Instructions"
                    inputProps={{ style: { fontSize: 12 } }}
                    name="instructions"
                    value={instructions}
                    onChange={(e) => {
                      setinstructions(e.target.value);
                    }}
                    variant="standard"
                  />
                </FormControl>
                <Button
                  onClick={() => {
                    updateOrders("instructions");
                  }}
                  variant="contained"
                  size="small"
                  style={{
                    marginTop: "10px",
                    width: "180px",
                    backgroundColor: "#9C27B0",
                    "&:hover": {
                      backgroundColor: "#9C27B0",
                    },
                    "&:active": {
                      backgroundColor: "#9C27B0",
                    },
                  }}
                >
                  Update Instructions
                </Button>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "20px",
                  marginBottom: 20,
                }}
              >
                <FormControl sx={{ width: "50%", marginTop: "10px" }}>
                  <TextField
                    fullWidth
                    id="commission"
                    inputProps={{ style: { fontSize: 12 } }}
                    name="commission"
                    placeholder="Commission Per Share"
                    value={commission}
                    onChange={(e) => {
                      setcommission(e.target.value);
                    }}
                    variant="standard"
                  />
                </FormControl>
                <Button
                  onClick={() => {
                    updateOrders("commission");
                  }}
                  variant="contained"
                  size="small"
                  style={{
                    marginTop: "10px",
                    width: "180px",
                    backgroundColor: "#2E7D32",
                    "&:hover": {
                      backgroundColor: "#2E7D32",
                    },
                    "&:active": {
                      backgroundColor: "#2E7D32",
                    },
                  }}
                >
                  Update Commission
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default BulkEdit;
