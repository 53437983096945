import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  IconButton,
  useTheme,
  TablePagination,
} from "@mui/material";

import ListToolbar from "./ListToolbar";
import ListHead from "../../ListHead";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment/moment";
import {
  getComparator,
  TABLE_HEAD_MARKET_DATA as TABLE_HEAD,
  Tablecell,
  applySortFilter,
  formatValue,
} from "../../Methods";

import Popup from "../../Popup";
import DeletePopUp from "../../Popup/DeletePopUp";
import EditFund from "../../FundsDetails/EditFund";

const MarketTable = () => {
  const theme = useTheme();
  const [data, setData] = useState([]);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("desc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("creationDate");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [activeTab, setActiveTab] = useState(0);

  const { funds } = useSelector((state) => state?.todos);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [recordForDelete, setrecordForDelete] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [openDeletePopup, setDeleteOpenPopup] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  useEffect(() => {
    const filteredUsers = applySortFilter(
      funds,
      getComparator(order, orderBy),
      filterName
    );
    // setData(filteredUsers);
    setData([]);
  }, [order, orderBy, filterName, funds]);

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const openInDeletePopup = (item) => {
    setrecordForDelete(item);
    setDeleteOpenPopup(true);
  };
  const handleTabChange = (newValue) => {
    setActiveTab(newValue);
  };
  const isNotFound = !data.length && !!filterName;
  return (
    <>
      <Container maxWidth="70%" sx={{ marginTop: 1, marginBottom: 3 }}>
        <Card>
          <ListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            setOrderBy={setOrderBy}
            setOrder={setOrder}
            activeTab={activeTab}
            onTabChange={handleTabChange}
            data={data[activeTab]}
          />

          <TableContainer style={{ height: "62vh" }}>
            <Table
              size="small"
              stickyHeader
              aria-label="a dense table"
              sx={{
                height: "max-content",
              }}
            >
              <ListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={data.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
              />

              <TableBody>
                {data &&
                  data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const { fundId, abbreviation, name, date, SelectedAum } =
                        row;

                      const selectedUser = selected.indexOf(fundId) !== -1;

                      return (
                        <TableRow
                          key={index}
                          tabIndex={-1}
                          role="checkbox"
                          selected={selectedUser}
                          sx={{
                            "&:hover": {
                              background: theme.palette.grey[200],
                              cursor: "pointer",
                            },
                          }}
                        >
                          <Tablecell align="left" sx={{ paddingLeft: "5px" }}>
                            {index + 1 + page * rowsPerPage}
                          </Tablecell>
                          <Tablecell align="left" sx={{ paddingLeft: "5px" }}>
                            Sameera
                          </Tablecell>
                          <Tablecell
                            align="left"
                            sx={{ paddingLeft: "5px" }}
                          ></Tablecell>
                          <Tablecell align="left" sx={{ paddingLeft: "5px" }}>
                            Aslam
                          </Tablecell>
                          <Tablecell align="left" sx={{ paddingLeft: "5px" }}>
                            sameera@gmail.com
                          </Tablecell>

                          <Tablecell
                            align="left"
                            sx={{ paddingLeft: "5px", width: "150px" }}
                          >
                            {date ? moment(date).format("MMM DD, YYYY") : "-"}
                          </Tablecell>
                          <Tablecell
                            align="center"
                            sx={{ paddingLeft: "5px", width: "100px" }}
                          >
                            <IconButton
                              sx={{ height: "20px", Minwidth: "10px" }}
                              onClick={() => {
                                openInPopup(row);
                              }}
                            >
                              <EditIcon
                                sx={{
                                  color: "#808080",
                                  width: "20px",
                                  height: "15px",
                                }}
                              />
                            </IconButton>
                            <IconButton
                              sx={{ height: "20px", Minwidth: "10px" }}
                              onClick={() => {
                                openInDeletePopup(row);
                              }}
                            >
                              <DeleteIcon
                                sx={{
                                  color: "#808080",
                                  width: "20px",
                                  height: "15px",
                                }}
                              />
                            </IconButton>
                          </Tablecell>
                        </TableRow>
                      );
                    })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[100, 500, 1000]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <DeletePopUp
        title="DELETE FUND"
        openDeletePopup={openDeletePopup}
        setDeleteOpenPopup={setDeleteOpenPopup}
        recordForDelete={recordForDelete}
        deleteObj="delete_fund"
        item="fund"
      />

      <Popup
        title="MODIFY FUND"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <EditFund
          recordForEdit={recordForEdit}
          data={data}
          setData={setData}
          setOpenPopup={setOpenPopup}
        />
      </Popup>
    </>
  );
};
export default MarketTable;
