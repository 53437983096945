import React from "react";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { useSelector } from "react-redux";
import { InputLabel } from "@mui/material";

const Footer = () => {
  const { fees } = useSelector((state) => state.todos);

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      sx={{ mt: 2, width: "95%" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          ml: 3,
        }}
      >
        <InputLabel sx={{ fontSize: "12px", mt: 1, mr: 2 }}>SEC FEE</InputLabel>
        <TextField
          disabled
          value={fees?.sec}
          inputProps={{ style: { fontSize: 12 } }}
          InputProps={{ readOnly: true }}
          variant="standard"
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <InputLabel sx={{ fontSize: "12px", mt: 1, mr: 2 }}>OCC FEE</InputLabel>
        <TextField
          disabled
          value={fees?.occ}
          inputProps={{ style: { fontSize: 12 } }}
          InputProps={{ readOnly: true }}
          variant="standard"
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <InputLabel sx={{ fontSize: "12px", mt: 1, mr: 2 }}>ORF FEE</InputLabel>
        <TextField
          disabled
          value={fees?.orf}
          inputProps={{ style: { fontSize: 12 } }}
          InputProps={{ readOnly: true }}
          variant="standard"
        />
      </Box>
    </Box>
  );
};

export default Footer;
