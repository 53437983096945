import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Grid,
  useTheme,
} from "@mui/material";
import {
  hashCurrencyEnumString,
  hashExchangeEnumReverse,
  hashInvestmentReverse,
  inputChecker,
} from "../Methods";
import Rebalance from "./Rebalance";
import { updateLoading } from "../../Redux/Reducers/todoReducers";
import { useDispatch } from "react-redux";
const SaveSignals = ({
  title,
  saveChange,
  setSaveChange,
  data,
  setpasted,
  strategyValObj,
  numberOfNotVerifiedObjects,
}) => {
  const theme = useTheme();
  const [saveRebalancer, setSaveRebalancer] = useState(false);
  const dispatch = useDispatch()
  const handleMe = () => {
    setSaveRebalancer(false);
    const updatedData = data?.map((item) => {
      const { ticker } = inputChecker(item?.name);
      const { symbol, currency, investment, country, stock } = ticker;
      if (stock) {
        item.stock = stock;
      }

      item.symbol = symbol;
      item.currency = currency;
      item.investment = investment;
      item.country = country;

      return item;
    });

    if (updatedData && updatedData.length > 0 && strategyValObj) {
      const strategy = strategyValObj ? strategyValObj : "";
      const signals = updatedData?.map((item) => {
        const signal = {
          key: {
            currency: hashCurrencyEnumString(item?.currency) || 7,
            exchange: hashExchangeEnumReverse(item?.country) || 4,
            investment: hashInvestmentReverse(item?.investment) || 4,
            symbol: item?.symbol,
          },
          value: parseFloat(item.value) / 100,
        };

        if (item?.stock) {
          signal.key.stock = item?.stock;
        }

        return signal;
      });

      window.clientSocks.send(
        JSON.stringify({
          action: "compare_signals",
          data: {
            strategy: strategy,
          },
        })
      );
      window.clientSocks.send(
        JSON.stringify({
          action: "new_signal",
          data: {
            strategy: strategy,
            signals: signals,
          },
        })
      );
      dispatch(updateLoading(true));
    } else {
      console.error(
        "Unable to send data to socket: Invalid pastedArray or strategyValObj"
      );
    }

    setpasted(updatedData);
  };
  return (
    <>
      <Dialog
        open={saveChange}
        maxWidth="xs"
        onClick={() => {
          setSaveChange(false);
        }}
      >
        <DialogTitle>
          <Typography
            variant="h6"
            component="div"
            align="center"
            style={{ flexGrow: 1, fontSize: "20px" }}
          >
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent dividers sx={{ px: 8 }}>
          <Typography
            variant="body"
            component="div"
            style={{ flexGrow: 1, textAlign: "center" }}
          >
            {numberOfNotVerifiedObjects > 0
              ? `Market data for ${numberOfNotVerifiedObjects} tickers isn't available. Do you still confirm to replace the signals?  `
              : `Do you want to save signals in 'Modified signals list'?`}
          </Typography>
          <Grid
            item
            xs={12}
            margin="20px 1px 20px 1px "
            display="flex"
            justifyContent="space-between"
          >
            <Button
              variant="contained"
              sx={{
                fontSize: "12px",
                fontWeight: "bold",
                height: "40px",
                width: "150px",
                background: "#B2BEB5",
                color: "#000",
                "&:hover": { background: "#808080" },
              }}
              onClick={() => {
                setSaveChange(false);
              }}
            >
              CANCEL CHANGES
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{
                fontSize: "12px",
                fontWeight: "bold",
                height: "40px",
                width: "150px",
              }}
              onClick={() => {
                handleMe();
              }}
            >
              SAVE CHANGES
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
      <Rebalance
        saveRebalancer={saveRebalancer}
        setSaveRebalancer={setSaveRebalancer}
        title="Rebalance"
      />
    </>
  );
};
export default SaveSignals;
