import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import { Button, InputLabel } from "@mui/material";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { BlacklistedTickers } from "../Schemas";

const EditBlacklistedTicker = ({
  recordForEdit,
  setOpenPopup,
}) => {
  const { funds, managers } = useSelector((state) => state?.todos);

  const initialValues = {
    name: recordForEdit?.ticker || '',
    funds: recordForEdit?.funds || [],
    managers: recordForEdit?.managers || [],
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: BlacklistedTickers,
    onSubmit: (values) => {
      setOpenPopup(false);
    },
  });

  const checkTestKeysFunds = () => {
    return funds || [];
  };

  const checkTestKeysManagers = () => {
    return managers || [];
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{ display: "flex", justifyContent: "space-between" }}
      onSubmit={handleSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} marginBottom="5px">
          <TextField
            fullWidth
            id="name"
            label="Ticker Name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="standard"
            error={touched.name && Boolean(errors.name)}
            helperText={touched.name && errors.name}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel variant="standard" sx={{ mt: "-10px" }}>
            Funds
          </InputLabel>
          <Grid container spacing={1}>
            <Grid item xs={12} sx={{ border: "1px solid #E0E0E0", mt: 1 }}>
              <Autocomplete
                multiple
                id="funds"
                ListboxProps={{ sx: { fontSize: 12 } }}
                sx={{
                  "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
                    fontSize: 12,
                  },
                }}
                onChange={(event, value) => setFieldValue("funds", value)}
                value={values.funds}
                options={checkTestKeysFunds()}
                getOptionLabel={(option) => option.abbreviation}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    sx={{ mb: 1 }}
                    error={Boolean(touched.funds && errors.funds)}
                    helperText={touched.funds && errors.funds}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <InputLabel variant="standard" sx={{ mt: "-10px" }}>
            Managers
          </InputLabel>
          <Grid container spacing={1}>
            <Grid item xs={12} sx={{ border: "1px solid #E0E0E0", mt: 1 }}>
              <Autocomplete
                multiple
                id="managers"
                ListboxProps={{ sx: { fontSize: 12 } }}
                sx={{
                  "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
                    fontSize: 12,
                  },
                }}
                onChange={(event, value) => setFieldValue("managers", value)}
                value={values.managers}
                options={checkTestKeysManagers()}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    sx={{ mb: 1 }}
                    error={Boolean(touched.managers && errors.managers)}
                    helperText={touched.managers && errors.managers}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          margin="10px"
          display="flex"
          justifyContent="space-between"
        >
          <Button variant="contained" sx={{ width: "30%" }} type="submit">
            SAVE
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "30%",
              background: "#B2BEB5",
              color: "#000",
              "&:hover": { background: "#808080" },
            }}
            onClick={() => setOpenPopup(false)}
          >
            CANCEL
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditBlacklistedTicker;
