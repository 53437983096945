import { Box } from "@mui/material";
import React from "react";

import { Circles } from  'react-loader-spinner'


export const Spinner = (props) => {
  

  return (
    <Box sx={{position:"fixed",width:"100%", height:"100vh", display:"flex", justifyContent:"center", alignItems:"center", backdropFilter: 'blur(3px)', zIndex:3 }}>



    <Circles
    height="80"
    width="80"
    color="#3f51b5"
    ariaLabel="circles-loading"
    wrapperStyle={{}}
    wrapperClass=""
    visible={true}
    />

    </Box>
    
  );
};

