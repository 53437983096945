import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Grid,
  useTheme,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { BoxHold } from "../Methods";

const TradeDivider = ({ setTradeRouting }) => {
  const theme = useTheme();
  return (
    <>
      <BoxHold>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography> Advance Trade Options</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={12}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Cash Trade/Margin Trade
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    defaultValue={0}
                    onChange={(e) => {
                      setTradeRouting(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value={0}
                      control={<Radio />}
                      label="Margin"
                    />
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="Cash"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </BoxHold>
    </>
  );
};
export default TradeDivider;
