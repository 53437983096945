import React, { useEffect, useState } from "react";
import "../App.css";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { LogIn } from "../component/Schemas";
import { useFormik } from "formik";
import theme from "../Theme";
import { useDispatch } from "react-redux";
import { updateLoading } from "../Redux/Reducers/todoReducers";
import { getUser } from "../Redux/Actions/UserActions";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Button, InputAdornment, IconButton } from "@mui/material";
import { toast } from "react-toastify";

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [apiError, setApiError] = useState(false);

  const handleSignUpClick = () => {
    navigate("/signup");
  };
  const handleReset = () => {
    navigate("/forgotPass");
  };

  const initialValues = {
    username: "",
    password: "",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: LogIn,
      onSubmit: ({ username, password }) => {
        dispatch(updateLoading(true));

        try {
          const response = dispatch(getUser({ username, password }));
          dispatch(updateLoading(false));
          if (
            response &&
            response.statusCode !== 401 &&
            response.statusCode !== 403
          ) {
            setApiError(false);
          } else {
            setApiError(true);
            toast.error("User not Authorized");
          }
        } catch (error) {
          setApiError(true);
          

        }
      },
    });
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Container component="main" maxWidth="sm" sx={{ my: 2 }}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          p: 2,
        }}
      >
        <Typography
          component="h1"
          variant="h4"
          fontWeight={900}
          color={theme.palette.primary.main}
        >
          Welcome
        </Typography>
        <Typography variant="body1">Please log in to your account.</Typography>

        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="username"
                type="text"
                label="Email"
                name="username"
                value={values.username}
                onBlur={handleBlur}
                onChange={handleChange}
                autoComplete="email"
              />
              {errors.username && touched.username ? (
                <p className="error">*{errors.username}</p>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={values.password}
                onBlur={handleBlur}
                onChange={handleChange}
                required
                fullWidth
                name="password"
                label="Password"
                id="password"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePasswordVisibility} edge="end">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                autoComplete="new-password"
              />
              {errors.password && touched.password ? (
                <p className="error">*{errors.password}</p>
              ) : null}
            </Grid>

            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Link
                color="primary"
                variant="h5"
                onClick={handleReset}
                sx={{ cursor: "pointer", height: "30px" }}
              >
                Forgot password?
              </Link>
            </Grid>

            <Grid container spacing={2} mx={1}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox value="allowExtraEmails" color="primary" />
                  }
                  label="Remember me"
                />
              </Grid>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button type="submit" variant="contained">
              Log In
            </Button>
            <Button
              variant="contained"
              sx={{ ml: 1 }}
              onClick={() => {
                handleSignUpClick();
              }}
            >
              Sign Up
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
export default SignIn;
