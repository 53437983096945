import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Grid,
  Box,
  Table,
  TableRow,
  TableBody,
  useTheme,
  styled,
  TableCell,
} from "@mui/material";
import { calculateAndFormat } from "../Methods";

const Tablecell = styled(TableCell)(({ theme }) => ({
  fontSize: "12px",
  padding: "1px 2px 1px 5px",
}));
const PerformanceFundPopUp = (props) => {
  const theme = useTheme();
  const {
    title,
    openPositionsPopup,
    setopenPositionsPopup,
    accFund,
    tablehead,
  } = props;

  const handleClose = () => {
    setopenPositionsPopup(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      open={openPositionsPopup}
      sx={{
        "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
          minWidth: "300px",
          marginLeft: "200px",
        },
      }}
    >
      <DialogTitle>
        <Typography
          variant="h6"
          component="div"
          align="left"
          style={{ flexGrow: 1, fontSize: "20px" }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Box
          sx={{
            border: `1px solid ${theme.palette.lightGrey.tableHeader}`,
            borderRadius: "4px",
          }}
        >
          <Grid item xs={6} md={6}>
            <Table aria-label="a dense table" size="small">
              <TableRow
                sx={{ backgroundColor: theme.palette.lightGrey.backgroundBody }}
              >
                {tablehead?.map((i, index) => {
                  return (
                    <Tablecell
                      key={index}
                      align="center"
                      sx={{
                        borderRight: `1px solid ${theme.palette.lightGrey.tableHeader}`,
                        color: theme.palette.lightGrey.textColor,
                        fontSize: theme.typography.bodyText12.fontSize,
                        fontWeight: theme.typography.bodyText12.fontWeight,
                      }}
                    >
                      {i}
                    </Tablecell>
                  );
                })}
              </TableRow>
              <TableBody>
                {accFund?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      background: theme.palette.grey[100],
                      "&:hover": {
                        background: theme.palette.grey[200],
                        cursor: "pointer",
                      },
                    }}
                  >
                    <Tablecell
                      align="left"
                      sx={{
                        borderRight: `1px solid ${theme.palette.lightGrey.tableHeader}`,
                      }}
                    >
                      {row?.name}
                    </Tablecell>
                    <Tablecell
                      sx={{
                        borderRight: `1px solid ${theme.palette.lightGrey.tableHeader}`,
                      }}
                      align="right"
                    >
                      {calculateAndFormat(row?.aum)}
                    </Tablecell>
                    <Tablecell
                      style={{
                        borderRight: `1px solid ${theme.palette.lightGrey.tableHeader}`,
                        color: row?.size >= 0 ? "green" : "red",
                      }}
                      align="right"
                    >
                      {row?.size >= 0
                        ? calculateAndFormat(row?.size)
                        : "(" + calculateAndFormat(row?.size * -1) + ")"}
                    </Tablecell>
                    <Tablecell
                      style={{
                        borderRight: `1px solid ${theme.palette.lightGrey.tableHeader}`,
                        color: row?.pf >= 0 ? "green" : "red",
                      }}
                      align="right"
                    >
                      {row?.pf >= 0
                        ? row?.pf.toFixed(2) + "%"
                        : "(" + (row?.pf * -1).toFixed(2) + "%)"}
                    </Tablecell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Box>

        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "5px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              width: "10%",
              background: "#B2BEB5",
              color: "#000",
              "&:hover": { background: "#808080" },
            }}
            onClick={() => {
              setopenPositionsPopup(false);
            }}
          >
            CLOSE
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PerformanceFundPopUp;
