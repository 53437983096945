import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  useTheme,
  IconButton,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Tabs from "@mui/material/Tabs";
import { useState } from "react";
import Csv from "../CustodianDetails/Csv";
import TradesConfirmation from "./TradesConfirmation";
import { StyledTab } from "../Methods";
import ManualLocates from "../CustodianDetails/CustodiansTable/ManualLocates";
import AutomatesLocates from "../CustodianDetails/CustodiansTable/AutomatesLocates";
const LocatesPopup = (props) => {
  const theme = useTheme();
  const { id, children, tradesPopup, setTradesOpenPopup, title } = props;
  const [confirm, setConfirm] = useState(false);

  const handleGenerateFile = () => {
    window.clientSocks.send('{"action": "get_eod"}');
    setTradesOpenPopup(true);
  };

  return (
    <>
      <Dialog
        fullWidth
        size="sm"
        open={tradesPopup}
        sx={{
          "& .MuiDialog-paper": {
            maxWidth: "550px",
          },
        }}
        onClose={() => {
          setConfirm(true);
        }}
      >
        <Box>
          <DialogTitle sx={{ py: 1 }}>
            <div style={{ display: "flex" }}>
              <Typography
                variant="h6"
                component="div"
                style={{ flexGrow: 1, fontSize: "20px" }}
              >
                {title}
              </Typography>
              <IconButton
                onClick={() => {
                  setConfirm(true);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <Divider />
          <Box
            sx={{
              px: 3,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          ></Box>
          <DialogContent sx={{ py: 1 }}>
            <ManualLocates />
          </DialogContent>
        </Box>
      </Dialog>
      <TradesConfirmation
        confirm={confirm}
        setConfirm={setConfirm}
        tradesPopup={tradesPopup}
        setTradesOpenPopup={setTradesOpenPopup}
        title="Close Custodians Locates"
      />
      {/* <ConfirmRegenerate
        title="REGENERATE FILES FOR CUSTODIANS?"
        openRegenerate={openRegenerate}
        setOpenRegenerate={setOpenRegenerate}
      /> */}
    </>
  );
};

export default LocatesPopup;
