import * as React from "react";
import Box from "@mui/material/Box";
import "../../App.css";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Button, FormControl, Autocomplete } from "@mui/material";
import { useFormik } from "formik";
import { Source } from "../../component/Schemas";
import { useSelector } from "react-redux";

const AddSource = ({ setOpenPopup }) => {
  const initialValues = {
    source: "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: Source,

      onSubmit: (values) => {
        setOpenPopup(false);
      },
    });

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
      onSubmit={handleSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} marginBottom="5px">
          <TextField
            fullWidth
            id="source"
            label="Source Name"
            value={values.source}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="standard"
          />
          {errors.source && touched.source ? (
            <p className="error">*{errors.source}</p>
          ) : null}
        </Grid>
        <Grid
          item
          xs={12}
          margin="10px 10px 10px 0px"
          display="flex"
          justifyContent="space-between"
        >
          <Button variant="contained" sx={{ width: "30%" }} type="submit">
            ADD SOURCE
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "30%",
              background: "#B2BEB5",
              color: "#000",
              "&:hover": { background: "#808080" },
            }}
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            CANCEL
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
export default AddSource;
