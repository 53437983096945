import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Topbar from "../component/NavComponents/Topbar";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Box, IconButton, styled, Typography, useTheme } from "@mui/material";
import { Helmet } from "react-helmet";

const Testing = () => {
  const [data, setData] = useState(
    '{"action": "market_data","data":{"threshold":120,"request":true,"fields":[9,10],"tickers":[{"symbol": "MSFT","investment": 4,"exchange": 4,"currency": 7}]}}'
  );
  const { RawMarketData } = useSelector((state) => state?.todos);
  const handleChange = (e) => {
    setData(e.target.value);
  };
  return (
    <>
      <Helmet>
        <title>Testing</title>
      </Helmet>
      <Topbar title="Testing" />
      <Grid container spacing={2} sx={{ padding: 2 }}>
        <Grid item xs={6} marginBottom="5px">
          <TextField
            fullWidth
            multiline
            rows={2}
            id="name"
            label="Socket Request"
            variant="standard"
            defaultValue={data}
            onChange={handleChange}
          />

          <Button
            variant="contained"
            sx={{
              width: "30%",
              marginTop: 2,
              background: "#000",
              color: "#fff",
              "&:hover": { background: "#808080" },
            }}
            onClick={() => {
              window.clientSocks.send(data);
            }}
          >
            SUBMIT REQUEST
          </Button>

          <Typography fontWeight={"bold"}>OTHER REQUESTS</Typography>

          <Typography>
            {
              '{"action": "market_data","data":{"threshold":120,"request":true,"fields":[3],"tickers":[{"symbol": "spy","investment": 9,"currency": 7,"exchange": 4,"underlying": {"investment": 4,"symbol": "spy","currency": 7,"exchange": 4},"strikePrice": 414,"expirationDate": 1697137310073,"option": 1}]}}'
            }
          </Typography>

          <Typography sx={{ marginTop: 3 }}>
            {
              '{"action": "market_data","data":{"threshold":120,"request":true,"fields":[9,10],"tickers":[{"symbol": "AAPL","investment": 4,"exchange": 4,"currency": 7}]}}'
            }
          </Typography>
        </Grid>

        <Grid item xs={6} marginBottom="5px">
          {JSON.stringify(RawMarketData)}
        </Grid>
      </Grid>
    </>
  );
};
export default Testing;
