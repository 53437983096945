import React, { useState, useEffect } from "react";
import {
  Table,
  TableRow,
  TableBody,
  useTheme,
  Box,
  Checkbox,
  Typography,
  TableCell,
  TextField,
} from "@mui/material";
import { Tablecell, formatValue } from "../../../../Methods";

const Target = ({
  totals,
  oldData,
  fundAum,
  editableValue,
  setEditableValue,
}) => {
  const theme = useTheme();
  const [editableData, setEditableData] = useState(totals);
  const [myVal, setVal] = useState(true);

  useEffect(() => {
    setEditableData(totals);
    setEditableValue(formatValue(fundAum));
  }, [totals, oldData, fundAum]);

  return (
    <>
      <Box
        sx={{
          border: `1px ${theme.palette.lightGrey.tableHeader}`,
          borderRadius: "4px",
          marginTop: "10px",
          width: "32%",
          height: "120px",
          marginRight: 1,
        }}
      >
        <Table
          size="small"
          stickyHeader
          aria-label="a dense table"
          sx={{
            height: "50%",
            borderCllapse: "collapse",
            border: "solid 1px #eee",
            marginTop: "-1px",
          }}
        >
          <TableBody>
            <TableRow
              sx={{
                backgroundColor: "#B5D0D9",
              }}
            >
              <Tablecell
                colSpan={2}
                sx={{
                  fontSize: theme.typography.bodyText12.fontSize,
                  fontWeight: theme.typography.bodyText12.fontWeight,
                  backgroundColor: "#E4C0C6",
                  textAlign: "center",
                }}
              >
                Target
              </Tablecell>
            </TableRow>
          </TableBody>

          <TableRow
            sx={{
              backgroundColor: "#B5D0D9",
            }}
          >
            <Tablecell
              sx={{
                fontSize: theme.typography.bodyText12.fontSize,
                fontWeight: theme.typography.bodyText12.fontWeight,
                backgroundColor: "#F2F2F2",
                textAlign: "center",
                width: "100px",
              }}
            >
              Percent
            </Tablecell>
            <Tablecell
              sx={{
                fontSize: theme.typography.bodyText12.fontSize,
                fontWeight: theme.typography.bodyText12.fontWeight,
                backgroundColor: "#F2F2F2",
                textAlign: "center",
                width: "100px",
              }}
            >
              Amount
            </Tablecell>
          </TableRow>

          <TableBody>
            {editableData?.map((row, index) => (
              <TableRow
                sx={{
                  "&:hover": {
                    background: theme.palette.grey[200],
                    cursor: "pointer",
                  },
                }}
              >
                <Tablecell
                  align="left"
                  sx={{
                    fontWeight: "900",
                    fontSize: "12px",
                    paddingLeft: "5px",
                    borderRight: "none",
                  }}
                >
                  {row?.changeValue}%
                </Tablecell>
                <Tablecell
                  align="center"
                  sx={{
                    fontWeight: "500",
                    fontSize: "12px",
                    paddingLeft: "5px",
                    borderRight: "none",
                  }}
                >
                  ${row?.changeAllocation}
                </Tablecell>
              </TableRow>
            ))}

            <TableRow
              sx={{
                backgroundColor: "#B5D0D9",
              }}
            >
              <TableCell
                rowSpan={2}
                colSpan={2}
                sx={{
                  fontSize: theme.typography.bodyText12.fontSize,
                  fontWeight: theme.typography.bodyText12.fontWeight,
                  backgroundColor: "#E4C0C6",
                  textAlign: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: theme.typography.bodyText12.fontSize,
                    fontWeight: theme.typography.bodyText12.fontWeight,
                  }}
                >
                  Target
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    defaultChecked
                    checked={myVal}
                    disabled={fundAum ? false : true}
                    size="small"
                    onChange={(e) => setVal(e.target.checked)}
                    sx={{
                      width: "10px",
                      height: "10px",
                      "& .MuiSvgIcon-root": { fontSize: 18 },
                      marginLeft: "60px",
                    }}
                  />
                  <Box
                    sx={{
                      fontWeight: "bold",
                      width: "10px",
                      height: "10px",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "11px",
                      mt: "1.5px",
                    }}
                  >
                    $
                  </Box>
                  <TextField
                    value={editableValue}
                    disabled={myVal ? true : false}
                    onChange={(e) => setEditableValue(e.target.value)}
                    onBlur={(e) => {
                      setEditableValue(formatValue(e.target.value));
                    }}
                    size="small"
                    variant="standard"
                    sx={{ mb: "1px" }}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        fontSize: "12px",
                        fontWeight: 900,
                        paddingTop: "0px",
                        paddingBottom: "0px",
                        width: "100%",
                      },
                    }}
                  />
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </>
  );
};
export default Target;
