import React, { useState } from "react";
import {
  Tooltip,
  IconButton,
  InputAdornment,
  Box,
  Menu,
  MenuItem,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import Popup from "../../Popup";
import { StyledRoot, StyledSearch } from "../../Methods";

const ListToolbar = ({
  filterName,
  onFilterName,
  setOrderBy,
  setOrder,
  setData,
  data,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openSyncPopup, setOpenSyncPopup] = useState(false);
  const theme = useTheme();

  const handleSyncClick = () => {
    setOpenSyncPopup(true);
  };
  const currentDate = new Date();
  return (
    <>
      <StyledRoot>
        <Box>
          <StyledSearch
            value={filterName}
            onChange={onFilterName}
            placeholder="Search..."
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon
                  sx={{ color: "text.disabled", width: 15, height: 15 }}
                />
              </InputAdornment>
            }
          />

          <Tooltip title="Filter list">
            <IconButton
              id="demo-positioned-button"
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              sx={{ marginLeft: "20px" }}
            >
              <FilterListIcon
                sx={{ width: "20px", height: "20px", color: "#000" }}
              />
            </IconButton>
          </Tooltip>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Typography
            sx={{
              fontSize: theme.typography.bodyText12,
              color: theme.palette.text.secondary,
              mt: 2,
            }}
          >
            LAST SYNCED:{" "}
            {currentDate.toLocaleString("en-US", {
              day: "numeric",
              month: "short",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })}
          </Typography>
          <Button
            variant="contained"
            sx={{
              height: 40,

              fontSize: theme.typography.bodyText17,
              marginLeft: "20px",
            }}
            onClick={handleSyncClick}
          >
            Quick Sync
          </Button>

          <Button
            variant="contained"
            sx={{
              height: 40,

              fontSize: theme.typography.bodyText17,
              marginLeft: "10px",
            }}
            onClick={handleSyncClick}
          >
            Deep Sync
          </Button>
        </Box>
      </StyledRoot>

      <Popup openPopup={openSyncPopup} setOpenPopup={setOpenSyncPopup}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={30} sx={{ marginRight: 2 }} />{" "}
          <Typography
            sx={{ fontSize: "18px", fontWeight: 500, letterSpacing: "1px" }}
          >
            Syncing... Please Wait.
          </Typography>
        </Box>
        <Box>
          {" "}
          <Typography sx={{ fontSize: "14px" }}>
            Receiving a response from server. We recommend that to wait to avoid
            data loss.
          </Typography>
        </Box>
      </Popup>
    </>
  );
};

export default ListToolbar;
