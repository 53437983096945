import React, { useState } from "react";
import {
  Grid,
  Button,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  Box,
  FormControl,
  Autocomplete,
  IconButton,
  useTheme,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { criteriaOptions, operationOptions, unitOptions } from "../../Methods";

const RulesForm = ({ Strategies, rules, setRules }) => {
  const StrategyList = (Strategies?.map((i) => i?.name) || [])?.sort();

  const handleSetStrategyChange = (index, event, newValue) => {
    const updatedRules = [...rules];
    updatedRules[index].selectedStrategies = newValue;
    setRules(updatedRules);
  };

  const handleRuleChange = (index, ruleIndex, field, value) => {
    const updatedRules = [...rules];
    updatedRules[index].rules[ruleIndex][field] = value;
    setRules(updatedRules);
  };

  const handleRemoveRule = (index, ruleIndex) => {
    const updatedRules = [...rules];
    updatedRules[index].rules = updatedRules[index].rules.filter(
      (_, i) => i !== ruleIndex
    );
    setRules(updatedRules);
  };

  const addRule = (index) => {
    const updatedRules = [...rules];
    if (updatedRules[index].selectedStrategies.length > 0) {
      updatedRules[index].rules.push({
        criteria: "",
        operation: "",
        value: "",
        unit: "$",
      });
      setRules(updatedRules);
    }
  };

  const createNewSet = () => {
    setRules([...rules, { selectedStrategies: [], rules: [] }]);
  };

  const handleUnitChange = (index, ruleIndex, event) => {
    const updatedRules = [...rules];
    updatedRules[index].rules[ruleIndex].unit = event.target.value;
    setRules(updatedRules);
  };

  const handleValueChange = (index, ruleIndex, event) => {
    const updatedRules = [...rules];
    const value = event.target.value;

    if (!isNaN(value) || value === "") {
      updatedRules[index].rules[ruleIndex].value = value;
      setRules(updatedRules);
    }
  };

  const handleRemoveSet = (index) => {
    const updatedRules = rules.filter((_, i) => i !== index);
    setRules(updatedRules);
  };

  const theme = useTheme();

  return (
    <Box sx={{ marginTop: "20px", width: "100%" }}>
      {rules.map((ruleSet, index) => (
        <Box
          key={index}
          sx={{
            marginBottom: 3,
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            p: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 1,
            }}
          >
            <IconButton
              color="secondary"
              size="small"
              sx={{
                color: theme.palette.error.main,
              }}
              onClick={() => handleRemoveSet(index)}
            >
              <DeleteIcon
                sx={{
                  height: "20px",
                  width: "20px",
                }}
              />
            </IconButton>
          </Box>

          <Box sx={{ marginBottom: 3 }}>
            <FormControl sx={{ width: "100%" }}>
              <Autocomplete
                multiple
                options={StrategyList}
                getOptionLabel={(option) => option}
                value={ruleSet.selectedStrategies || []}
                onChange={(event, newValue) =>
                  handleSetStrategyChange(index, event, newValue)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Strategies"
                    variant="standard"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Box>

          <Box
            sx={{
              maxHeight: "240px",
              overflowY: "auto",
              width: "100%",
            }}
          >
            <Grid container spacing={1}>
              {ruleSet.rules.map((rule, ruleIndex) => (
                <Grid container spacing={1} alignItems="center" key={ruleIndex}>
                  <Grid item xs={4}>
                    <Select
                      fullWidth
                      size="small"
                      value={rule.criteria}
                      onChange={(e) =>
                        handleRuleChange(
                          index,
                          ruleIndex,
                          "criteria",
                          e.target.value
                        )
                      }
                      displayEmpty
                    >
                      {criteriaOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid item xs={2}>
                    <Select
                      fullWidth
                      size="small"
                      value={rule.operation}
                      onChange={(e) =>
                        handleRuleChange(
                          index,
                          ruleIndex,
                          "operation",
                          e.target.value
                        )
                      }
                      disabled={rule.criteria === "NotAllowed"}
                    >
                      {operationOptions.map((operation) => (
                        <MenuItem key={operation} value={operation}>
                          {operation}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      size="small"
                      value={rule.value}
                      onChange={(e) => handleValueChange(index, ruleIndex, e)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {rule.unit}
                          </InputAdornment>
                        ),
                      }}
                      disabled={rule.criteria === "NotAllowed"}
                    />
                  </Grid>

                  <Grid item xs={1}>
                    <Select
                      value={rule.unit}
                      onChange={(e) => handleUnitChange(index, ruleIndex, e)}
                      size="small"
                      fullWidth
                      disabled={rule.criteria === "NotAllowed"}
                    >
                      {unitOptions.map((unit) => (
                        <MenuItem key={unit} value={unit}>
                          {unit}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid item xs={1}>
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={() => handleRemoveRule(index, ruleIndex)}
                    >
                      <DeleteIcon
                        sx={{ height: "15px", width: "15px", color: "black" }}
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              size="small"
              sx={{
                background: "#1976d2",
                "&:hover": { background: "#1565c0" },
              }}
              onClick={() => addRule(index)}
              disabled={ruleSet.selectedStrategies.length === 0}
            >
              Add Rule
            </Button>
          </Box>
        </Box>
      ))}

      <Box sx={{ marginTop: 3, display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          size="small"
          sx={{
            background: "#1976d2",
            "&:hover": { background: "#1565c0" },
            width: "100%",
          }}
          onClick={createNewSet}
          disabled={Strategies?.length === 0}
        >
          Add New Set of Rules
        </Button>
      </Box>
    </Box>
  );
};

export default RulesForm;
