import Header from "../Header";
import SmaPerformance from "../SmaPerformance";
const SmaLayout = () => {
  return (
    <>
      <Header>
        <SmaPerformance />
      </Header>
    </>
  );
};
export default SmaLayout;
