
import Header from "../Header";
import UserTable from "../UserDetails/UserTable";
const TradeUser = () => {
  return (
    <>
      <Header>
        <UserTable />
      </Header>
    </>
  );
};
export default TradeUser;
