import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import { useFormik } from "formik";
import { UserSma } from "../../component/Schemas";
import { FormControl, useTheme, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const EditUserSma = ({ recordForEdit, setOpenPopup }) => {
  const initialValues = {
    email: recordForEdit?.email || "",
    accounts: recordForEdit?.accounts || "",
  };

  const [showInfo,setShowInfo] = React.useState(false)

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: UserSma,

      onSubmit: (values) => {
        setOpenPopup(false);
      },
    });

  const smas = [{ value: "Charles Schwab", label: "Charles Schwab" }];
  const theme = useTheme();
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              disableClearable
              value={values.role}
              ListboxProps={{
                sx: { fontSize: 15 },
              }}
              
              onChange={(e)=>{
                setShowInfo(true)
                handleChange(e)
              }
              }
              options={smas}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Brokerage Account"
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} margin="5px 0px">
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Email"
            value={values.email}
            onChange={handleChange}
            variant="standard"
          />
          {errors.email && touched.email ? (
            <p className="error">*{errors.email}</p>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="accounts"
            name="accounts"
            label="Account Number"
            value={values.accounts}
            onChange={handleChange}
            variant="standard"
          />
          {errors.accounts && touched.accounts ? (
            <p className="error">*{errors.accounts}</p>
          ) : null}
        </Grid>

        {showInfo ? <>

        <Grid
          item
          xs={12}
          style={{
            background: "#f6f6f6",
            padding: "20px",
            marginTop: "28px",
          }}
        >
          <Grid container style={{ marginTop: "10px" }}>
            <InfoOutlinedIcon />

            <p style={{ marginTop: "4px", marginLeft: "8px" }}>
              {" "}
              <b>Instruction to integrate Brokerage Account</b>
            </p>
          </Grid>

          <ol style={{ marginLeft: "20px", marginTop: "10px" }}>
            <li style={{ padding: "8px 0px" }}>
              Fill the form above with your <b>Charles Shwab</b> details.
            </li>
            <li style={{ padding: "8px 0px" }}>
              Send an email at <b>contact@charlesshwab.com</b> asking them to
              allow <br />
              <b>9823 Capital</b> to be your advisor.
            </li>
            <li style={{ padding: "8px 0px" }}>
              Once you get confirmation, let us know by sending us an email at{" "}
              <b>contact@precept.com</b>.
            </li>
          </ol>
        </Grid>

        </> : null}

        <Grid
          item
          xs={12}
          margin="10px"
          display="flex"
          alignContent="flex-end"
          flexDirection={"row-reverse"}
          justifyContent="space-between"
        >
          <Button variant="contained" sx={{ width: "30%" }} type="submit">
            SAVE
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
export default EditUserSma;
