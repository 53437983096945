import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Grid,
  Box,
  TableContainer,
  Table,
  TableBody,
  useTheme,
} from "@mui/material";
import {
  TABLE_HEAD_PORTFOLIO_LONG,
  applySortFilter,
  getComparator,
} from "../Methods";
import { useState, useEffect } from "react";
import LongTable from "../Tables/Portfolio/AssetPositions/LongTable";
import VantageListHead from "../ListHead/VantageListHead";

const LongPopUp = (props) => {
  const theme = useTheme();
  const { title, openLongPopup, setopenLongPopup, long } = props;
  const [filterName, setFilterName] = useState("");
  const [orderBy, setOrderBy] = useState("name");
  const [order, setOrder] = useState("asc");
  const [data, setData] = useState([]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  useEffect(() => {
    if (long) {
      const filteredUsersLong = applySortFilter(
        long,
        getComparator(order, orderBy),
        filterName
      );

      setData(filteredUsersLong);
    } else {
      setData([]);
    }
  }, [long, order, orderBy, filterName]);
  const handleClose = () => {
    setData([]);
    setopenLongPopup(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      open={openLongPopup}
      sx={{
        "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
          minWidth: "500px",
          marginLeft: "200px",
        },
      }}
    >
      <DialogTitle>
        <Typography
          variant="h6"
          component="div"
          align="left"
          style={{ flexGrow: 1, fontSize: "20px" }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Box
          sx={{
            border: `1px solid ${theme.palette.lightGrey.tableHeader}`,
            borderRadius: "4px",
          }}
        >
          <Grid item xs={6} md={6}>
            <TableContainer>
              <Table
                size="small"
                stickyHeader
                aria-label="a dense table"
                sx={{
                  height: "max-content",
                  maxHeight: "55px",
                  borderCllapse: "collapse",
                }}
              >
                <VantageListHead
                  headLabel={TABLE_HEAD_PORTFOLIO_LONG}
                  noStatus={true}
                  title={true}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />

                <TableBody>
                  {data?.map((row, i) => (
                    <LongTable row={row} key={i} visible={true} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Box>

        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "5px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              width: "10%",
              background: "#B2BEB5",
              color: "#000",
              "&:hover": { background: "#808080" },
            }}
            onClick={() => {
              setopenLongPopup(false);
            }}
          >
            CLOSE
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default LongPopUp;
