export const createData = (
  ticker,
  quantity,
  price,
  change,
  deltaChange,
  profit,
  exposure,
  deltaExposure,
  frTgt,
  options,
  tgt
) => {
  return {
    ticker,
    quantity,
    price,
    change,
    deltaChange,
    profit,
    exposure,
    deltaExposure,
    frTgt,
    options,
    tgt,
    history: [
      {
        asset: "BTC",
        date: "2020-10-04",
        days: 20,
        duration: "Long",
        position: 12,
        gross: 19000,
        market: 128000,
        loss: 1200,
      },
      {
        asset: "BTC",
        date: "2020-10-04",
        days: 20,
        duration: "Short",
        position: 12,
        gross: 19000,
        market: 128000,
        loss: 72200,
      },
      {
        asset: "BTC",
        date: "2020-10-04",
        days: 20,
        duration: "Long",
        position: 12,
        gross: 19000,
        market: 128000,
        loss: 890000,
      },
      {
        asset: "BTC",
        date: "2020-10-04",
        days: 20,
        duration: "Long",
        position: 12,
        gross: 19000,
        market: 128000,
        loss: 1200,
      },
      {
        asset: "BTC",
        date: "2020-10-04",
        days: 20,
        duration: "Short",
        position: 12,
        gross: 19000,
        market: 128000,
        loss: 1200,
      },
      {
        asset: "BTC",
        date: "2020-10-04",
        days: 20,
        duration: "Long",
        position: 12,
        gross: 19000,
        market: 128000,
        loss: 20000,
      },
    ],
  };
};

export const rows = [
  createData("BTC", 12, 20000, 20, 0, 50, 240000, 40, null, null, null),
  createData("ETH", 12, 20000, 20, 2, 50, 164450, 40, null, null, null),
  createData("SOL", 12, 20000, 20, 0, 50, 140000, 40, null, null, null),
  createData("BNB", 12, 20000, 20, 2, 50, 100000, 40, null, null, null),
  createData("MATIC", 12, 20000, 20, 0, 50, 90000, 40, null, null, null),
  createData("DOT", 12, 20000, 20, 2, 50, 60000, 40, null, null, null),
  createData("DOT", 12, 20000, 20, 2, 50, 25000, 40, null, null, null),
  createData("DOT", 12, 20000, 20, 2, 50, 38000, 40, null, null, null),
  createData("DOT", 12, 20000, 20, 2, 50, 43000, 40, null, null, null),
  createData("DOT", 12, 20000, 20, 2, 50, 54000, 40, null, null, null),
  createData("DOT", 12, 20000, 20, 2, 50, 56000, 40, null, null, null),
  createData("BTC", 12, 20000, 20, 0, 50, 240000, 40, null, null, null),
  createData("ETH", 12, 20000, 20, 2, 50, 164450, 40, null, null, null),
  createData("SOL", 12, 20000, 20, 0, 50, 140000, 40, null, null, null),
  createData("BNB", 12, 20000, 20, 2, 50, 100000, 40, null, null, null),
  createData("MATIC", 12, 20000, 20, 0, 50, 90000, 40, null, null, null),
  createData("DOT", 12, 20000, 20, 2, 50, 60000, 40, null, null, null),
  createData("DOT", 12, 20000, 20, 2, 50, 25000, 40, null, null, null),
  createData("DOT", 12, 20000, 20, 2, 50, 38000, 40, null, null, null),
  createData("DOT", 12, 20000, 20, 2, 50, 43000, 40, null, null, null),
  createData("DOT", 12, 20000, 20, 2, 50, 54000, 40, null, null, null),
  createData("DOT", 12, 20000, 20, 2, 50, 56000, 40, null, null, null),
];

//AUM TABLE

export const createAumData = (pf, val1, val2, str, empcol, percent) => {
  return {
    pf,
    val1,
    val2,
    str,
    empcol,
    percent,
    history: [
      {
        asset: "BTC",
        quantity: 12,
        lastPrice: "20,000",
        exp: "240,000",
        expPer: "20%",
      },
    ],
  };
};

export const aum = [
  createAumData("NASDAQ", "nqa", 12279, 0.17, "qqq", 0.65),
  createAumData("S&P 500", "esa", 3982, 1.82, "spy", 0.82),
  createAumData("RUSSELL 2000", "rtya", 1827, 0.93, "iwm", 0.91),
  createAumData("DOW JONES", "dma", 31614, 0.84, "dia", 0.65),
  createAumData("RUSSELL 3000", "rus", 31614, 0.44, "iky", 0.23),
];

//NET POSITION TABLE
function createNetData(
  key,
  aum,
  aumDecimal,
  aumTodays,
  pcm,
  trades,
  TodaysTrades,
  cmn,
  options,
  daily
) {
  return {
    key,
    aum,
    aumDecimal,
    aumTodays,
    pcm,
    trades,
    TodaysTrades,
    cmn,
    options,
    daily,
  };
}

export const net = [
  createNetData(
    "LONG",
    3,
    0.83,
    2.3,
    271.409,
    30761,
    "20,90",
    248748,
    8100,
    0.6
  ),
  createNetData(
    "SHORT",
    16,
    2.08,
    2.3,
    341827,
    42370,
    "90,00",
    null,
    277505,
    2.32
  ),
  createNetData("OPTIONS", null, null, null, null, 0, null, null, 285605, 8),
  createNetData("TOTAL", 15, 0.1, 2.3, 70, null, "20,90", null, null, 1.62),
];
//Broke Table

function createBrokerData(
  id,
  fname,
  dname,
  accSystem1,
  accSystem2,
  accSystem3,
  custodianCode,
  bloombergCode
) {
  return {
    id,
    fname,
    dname,
    accSystem1,
    accSystem2,
    accSystem3,
    custodianCode,
    bloombergCode,
  };
}
//vantage

function createVantageFund(name, val, profit, percent) {
  return {
    name,
    val,
    profit,
    percent,
  };
}

export const vantageFund = [
  createVantageFund("PRECEPT FUND", 72448705, 34126, 0.05),
  createVantageFund("98|23 FUND", 72448705, 34126, 0.05),
  createVantageFund("Profit (loss) today", 72448705, 34126, 0.05),
];

//--
function createPortfolioLong(name, val, profit, percent) {
  return {
    name,
    val,
    profit,
    percent,
  };
}
export const PortfolioLong = [
  createPortfolioLong("LONG", 72448705, 34126, 0.05),
  createPortfolioLong("SHORTS", 72448705, 34126, 0.05),
  createPortfolioLong("OPTIONS", 72448705, 34126, 0.05),
  createPortfolioLong("TOTAL", 72448705, 34126, 0.05),
];
export const rebalanceData = [
  { name: "Fund AUM For Rebalancer", value: 60526043 },
  { name: "Strategey AUM For Rebalancer", value: 7256836 },
  { name: "Target Long Percentage", value: 109.0 },
  { name: "Target Short Percentage", value: 109.0 },
];
export const locatesData = [
  { id: 1, ticker: "TSLA", locates: 10, available: 0, reason: "" },
  { id: 2, ticker: "APPL", locates: 20, available: 0, reason: "" },
  { id: 3, ticker: "PEP", locates: 9, available: 0, reason: "" },
  { id: 4, ticker: "MSFT", locates: 10, available: 0, reason: "" },
];
export const signalsData = [
  { id: 1, ticker: "TSLA", type: "Equity", marketData: true },
  { id: 2, ticker: "APPL", type: "Options", marketData: false },
  { id: 3, ticker: "PEP", type: "Equity Options", marketData: true },
  { id: 4, ticker: "MSFT", type: "Options", marketData: true },
];
// export const dummyData = [
//   { ticker: "MSFT", weight: 10, shareCount: 100 },
//   { ticker: "AMD", weight: 5, shareCount: 50 },
//   { ticker: "TSLA", weight: 9, shareCount: 90 },
//   { ticker: "AAPL", weight: 8, shareCount: 80 },
//   { ticker: "GOOGL", weight: 4, shareCount: 40 },
//   { ticker: "NFLX", weight: 6, shareCount: 60 },
//   { ticker: "AMZN", weight: 7, shareCount: 70 },
//   { ticker: "INTC", weight: -3, shareCount: 30 }, // negative weight
//   { ticker: "NVDA", weight: 11, shareCount: 110 },
//   { ticker: "FB", weight: -5, shareCount: 50 }, // negative weight
//   { ticker: "PYPL", weight: 7, shareCount: 70 },
//   { ticker: "CSCO", weight: 8, shareCount: 80 },
//   { ticker: "ADBE", weight: 4, shareCount: 40 },
//   { ticker: "CRM", weight: 6, shareCount: 60 },
//   { ticker: "QCOM", weight: 9, shareCount: 90 },
//   { ticker: "BABA", weight: 12, shareCount: 120 },
//   { ticker: "IBM", weight: -2, shareCount: 20 }, // negative weight
//   { ticker: "SPOT", weight: 7, shareCount: 70 },
// ];
export const dummyData = [
  {
    ticker: "MSFT",
    weight: 10,
    shareCount: 100,
    type: "Buy Long",
    marketPrice: 47,
  },
  {
    ticker: "AMD",
    weight: 5,
    shareCount: 50,
    type: "Buy Long",
    marketPrice: 58,
  },
  {
    ticker: "TSLA",
    weight: 9,
    shareCount: 90,
    type: "Sell Short",
    marketPrice: 560,
  },
  { ticker: "AAPL", weight: 8, shareCount: 80, type: "Cover", marketPrice: 10 },
  {
    ticker: "GOOGL",
    weight: 4,
    shareCount: 40,
    type: "Sell Long",
    marketPrice: 10,
  },
  { ticker: "NFLX", weight: 6, shareCount: 60, type: "Cover", marketPrice: 10 },
  {
    ticker: "AMZN",
    weight: 7,
    shareCount: 70,
    type: "Sell Long",
    marketPrice: 10,
  },
  {
    ticker: "INTC",
    weight: -3,
    shareCount: 30,
    type: "Buy Long",
    marketPrice: 10,
  }, // negative weight
  {
    ticker: "NVDA",
    weight: 11,
    shareCount: 110,
    type: "Buy Long",
    marketPrice: 10,
  },
  { ticker: "FB", weight: -5, shareCount: 50, type: "Cover", marketPrice: 10 }, // negative weight
  { ticker: "PYPL", weight: 7, shareCount: 70, type: "Cover", marketPrice: 10 },
  { ticker: "CSCO", weight: 8, shareCount: 80, type: "Cover", marketPrice: 10 },
  {
    ticker: "ADBE",
    weight: 4,
    shareCount: 40,
    type: "Sell Short",
    marketPrice: 10,
  },
  {
    ticker: "CRM",
    weight: 6,
    shareCount: 60,
    type: "Buy Long",
    marketPrice: 10,
  },
  {
    ticker: "QCOM",
    weight: 9,
    shareCount: 90,
    type: "Sell Short",
    marketPrice: 1,
  },
];

export const signals_Data = [
  { id: 1, name: "TSLA", original: 0.1, new: 3.2, type: "Manual" },
  { id: 2, name: "APPL", original: 0.5, new: 2.2, type: "Auto" },
  { id: 3, name: "PEP", original: 3.1, new: 0.2, type: "Manual" },
  { id: 4, name: "MSFT", original: 5.1, new: 9.2, type: "Auto" },
];
export const Reb_Data = [
  { id: 1, name: "TSLA", Side: "Long", value: 2.3 },
  { id: 2, name: "MSFT", Side: "Short", value: 1.23 },
  { id: 3, name: "AAPL", Side: "Long", value: 1.1 },
  { id: 4, name: "AMD", Side: "Short", value: 0.87 },
  { id: 4, name: "NVDA", Side: "Long", value: 0.55 },
];

export const unallowedlist = [
  { id: 1, name: "TSLA", value: 0.1, sharecount: 1000, assign: "Pershing" },
  { id: 2, name: "APPL", value: 0.12, sharecount: 2500, assign: "BofA" },
  { id: 3, name: "PEP", value: -0.4, sharecount: 2000, assign: "Pershing" },
  { id: 5, name: "AMD", value: -0.07, sharecount: 500, assign: "BofA" },
];

export const blacklist = [
  { id: 1, name: "TSLA", value: 0.1 },
  { id: 2, name: "APPL", value: 0.12 },
  { id: 3, name: "PEP", value: -0.4 },
  { id: 5, name: "AMD", value: -0.07 },
];
export const currentSignal = [
  { id: 1, name: "Number of Signals in list", value: 182 },
  { id: 2, name: "Sum of weight in list", value: 0.0 },
  { id: 3, name: "Long", value: 99.9 },
  { id: 4, name: "Short", value: 100.1 },
];
export const rebalancerData = [
  { id: 1, name: "Share Count", value: 171 },
  { id: 2, name: "Sum of weight in list", value: 2.1 },
  { id: 3, name: "Long", value: 99.7 },
  { id: 4, name: "Short", value: 100.1 },
];
export const existing = [
  {
    id: 1,
    allocation: 7547,
    amount: 10,
    value: 96,
    percent: 23,
    allocationStra: false,
  },
  {
    id: 2,
    allocation: 7547,
    amount: 89,
    value: 94,
    percent: 43,
    allocationStra: true,
  },
  {
    id: 3,
    allocation: 7547,
    amount: 78,
    percent: 2,
    value: 190,
    allocationStra: false,
  },
  {
    id: 5,
    allocation: 7547,
    percent: 73,
    amount: 20,
    value: 2,
    allocationStra: true,
  },
];
export const existingBrokerCustodian = [
  {
    id: 1,
    abbreviation: "mlop",
    custodian: "bofa",
  },
  {
    id: 2,
    abbreviation: "cant",
    custodian: "hdwy",
  },
  {
    id: 3,
    abbreviation: "mlop",
    custodian: "jpms",
  },
  { id: 5, abbreviation: "fgb", custodian: "gsco" },
];

export const brokerStrategies = [
  { id: 1, name: "Dynamic val1" },
  { id: 2, name: "Dynamic val2" },
  { id: 3, name: "Dynamic val3" },
  { id: 4, name: "Dynamic val4" },
];
export const total_fund = [
  {
    id: 1,
    name: "Total for Long",
    Tamount: 58143985,
    Tvalue: 96,
    Eamount: 58143985,
    Evalue: 96,
  },
  {
    id: 2,
    name: "Total for Short",
    Tamount: 56763555,
    Tvalue: 94,
    Eamount: 56763555,
    Evalue: 94,
  },
  {
    id: 3,
    name: "Total Gross",
    Tamount: 114907541,
    Tvalue: 190,
    Eamount: 114907541,
    Evalue: 190,
  },
  {
    id: 5,
    name: "Total Net",
    Tamount: 1380480,
    Tvalue: 2,
    Eamount: 1380480,
    Evalue: 2,
  },
];

export const AmmendedList = [
  {
    id: 1,
    ticker: "TSLA",
    Value1: 0.1,
    Value2: 0.1,
    Value3: "0.10",
    Shares: "25,000",
  },
  {
    id: 2,
    ticker: "APPL",
    Value1: 0.12,
    Value2: 0.12,
    Value3: "0.12",
    Shares: "120",
  },
  {
    id: 3,
    ticker: "PEP",
    Value1: -0.4,
    Value2: -0.4,
    Value3: "-0.40",
    Shares: "14,848",
  },
  {
    id: 4,
    ticker: "MSFT",
    Value1: 0.05,
    Value2: 0.05,
    Value3: "0.05",
    Shares: "1,546",
  },
  {
    id: 5,
    ticker: "AMD",
    Value1: -0.07,
    Value2: -0.07,
    Value3: "-0.07",
    Shares: "264",
  },
  {
    id: 6,
    ticker: "ALGT",
    Value1: 0.23,
    Value2: 0.23,
    Value3: "0.23",
    Shares: "980",
  },
  {
    id: 7,
    ticker: "EMT",
    Value1: 0.08,
    Value2: 0.08,
    Value3: "0.08",
    Shares: "45,000",
  },
];

export const smaData = [
  {
    title: "Charles Schwab",
    account: "99383283292",
    strategies: ["AI Factor", "CP Factor"],
  },
];

export const dummySecurityData = [
  {
    ticker_symbol: "AAPL",
    ibkr_symbol: "AAPL",
    emsx_symbol: "AAPL",
  },
  {
    ticker_symbol: "MSFT",
    ibkr_symbol: "MSFT",
    emsx_symbol: "MSFT",
  },
  {
    ticker_symbol: "GOOGL",
    ibkr_symbol: "GOOGL",
    emsx_symbol: "GOOGL",
  },
  {
    ticker_symbol: "AMZN",
    ibkr_symbol: "AMZN",
    emsx_symbol: "AMZN",
  },
  {
    ticker_symbol: "TSLA",
    ibkr_symbol: "TSLA",
    emsx_symbol: "TSLA",
  },
];
export const dummyBlacklistedTickers = [
  {
    ticker: "TSLA",
    funds: [
      {
        name: "9823 Fund, LP",
        abbreviation: "wsf",
        fundId: "927a4bd37ff00177aac2b4f92d9076f7",
      },
    ],
    managers: [
      {
        name: "9823 Capital, LP",
        managerId: "1ed22746f7ec8b1f513fd2762f60326",
      },
    ],
  },
  {
    ticker: "AAPL",
    funds: [
      {
        name: "9823 Fund, LP",
        abbreviation: "wsf",
        fundId: "927a4bd37ff00177aac2b4f92d9076f7",
      },
    ],
    managers: [
      {
        name: "9823 Capital, LP",
        managerId: "1ed22746f7ec8b1f513fd2762f60326",
      },
    ],
  },
  {
    ticker: "MSFT",
    funds: [
      {
        name: "9823 Fund, LP",
        abbreviation: "wsf",
        fundId: "927a4bd37ff00177aac2b4f92d9076f7",
      },
    ],
    managers: [
      {
        name: "9823 Capital, LP",
        managerId: "1ed22746f7ec8b1f513fd2762f60326",
      },
    ],
  },
];

//strategies
export const strategies = [
  {
    date: 1719440001006,
    name: "AIAdvance",
    owner: {
      type: 0,
      value: "51ed22746f7ec8b1f513fd2762f60326",
    },
    permissions: {
      default: 1,
    },
    aum: -0.00009000167600792253,
    strategyId: "68afa71285e39df9f75caa12e302e663",
  },
  {
    date: 1719596982463,
    name: "AIFactor",
    owner: {
      type: 0,
      value: "51ed22746f7ec8b1f513fd2762f60326",
    },
    permissions: {
      default: 1,
    },
    signals: {
      date: 1728310310838,
      strategy: "7eb942cfde1052167cb3cc5fb6b9a570",
      user: "marshall@9823capital.com",
      weights_keys: {
        "270b39c7639848c4daf9c4b9e10d6fb2": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "jll",
        },
        eaefcfab18b522a3ffd64a8c8b869dbb: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ttd",
        },
        d4ae0f108f35de495241e61346ed23f3: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mkc",
        },
        "53af808d43d695b8a5462d778d87f658": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mtdr",
        },
        d313a7e438129f2555e52d5c3d54d6f0: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "qcom",
        },
        eabed427f6cd5e134a94b439a4029592: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gmre",
        },
        f683ed3911ff32157562d4de1ea075ff: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "chx",
        },
        df64245b30c622ca8131e3b6b0bdd916: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pr",
        },
        "44ed392a0a81da8e81a9fa1daf78c5dc": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "valu",
        },
        c60f803b2c75f803f84fd84547baa5cd: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "xper",
        },
        "4451aa7790482cf257d0178604fdb719": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ivr",
        },
        "15def38dab1dc4ae99376d7754c63882": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "knsa",
        },
        f23f0ca4db72d2396a143a3715bae535: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cubi",
        },
        "29f09d5695bb5c2e4a3e40f889cc451e": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cvs",
        },
        "95312fbb95ab036db7a5fc9117cdfbe3": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "petq",
        },
        "22ad7e3025b33dfb79031bb1fbf1e31c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "th",
        },
        fed87f005e587b2d04b965aad7aa7e44: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sig",
        },
        "00e2e572504b2c108c9bc2600a89dd69": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cfr",
        },
        "239701df36f9776af7730b41bca6807c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pack",
        },
        d01b1a997a07977d1182862e27ad9b5c: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mdb",
        },
        fce9583334f4798a38545aa8dfd38780: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vrns",
        },
        "1c09ef9a2b5959e91a5acd68eb01241e": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "alhc",
        },
        df800436a5fa872d8e7b0ca0dea8884e: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "twlo",
        },
        b8df18fc7e00bcacb14ff3d112442433: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "boh",
        },
        d233094ee33894d30e6adb39a4a0c8b4: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "jblu",
        },
        "57e315ecdc80ae62a4b434552b0629ac": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lrcx",
        },
        "082f2ab41c1c51ac3f563825c1eb383a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dltr",
        },
        e6fc417cc6e111336e2df114d6dc5a15: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "voya",
        },
        df61e41bfa154536216862d9fc9a1c9c: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cto",
        },
        fa4f09faba486ca4701eeecdac9d5ec2: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fdbc",
        },
        "08b31ea26c5a8f34d61130ffb357de23": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "flr",
        },
        "605fd3518ad3eaa4815e2d1b38e8cf95": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gnk",
        },
        f9a32bce413db3e3ccdf806ef55f050f: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "baly",
        },
        "1421b52081f233415b9fb5e689dab7eb": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "masi",
        },
        "133d45549a6575daa389987a513eaf6f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fcnca",
        },
        ac5fe9aa93d4fc2b32770405ee3051f8: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wsbf",
        },
        "1d6941b9b7ecc737ffa7dca99e72adc4": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bipc",
        },
        "3c40460e8f4ff8826897e08f6efb2069": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "psx",
        },
        "4a28369e603a65223da2fce02a168012": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "celh",
        },
        "72cb039fa0cbd156680a2dacafd1185f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "acre",
        },
        "60345e9cae3999bb7a01385b4847ce4f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bcml",
        },
        efa47c1c995eebe7c301de9d31e7a801: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "amba",
        },
        b7dc088ba838f5f4f2ec41b519f87567: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "calm",
        },
        c0fb84ace6a04f150b0fe7b65e10378b: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vktx",
        },
        "94a7891f2fa068203befab2a6779658c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ua",
        },
        "219e317e0d4d2626f38ca04ec09c94ad": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "thrm",
        },
        "82e6b4cddb4ddbc1dca4e1dc31734c63": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mlab",
        },
        b6a35675f8b6790fc5200a6d75f54d7e: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "erii",
        },
        d29d65d83cd2f569b2103b5b37c5815f: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ocul",
        },
        d6cffa108bc762f9b9e75f8ce1091109: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "swi",
        },
        a8004b3de9b58cdd6732f69beb67ecbe: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dow",
        },
        "22df8c22e98b8a75474d16b30ed7642f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pltk",
        },
        a5b1b4a982c57351b727b0f4d6d63736: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "domo",
        },
        acf701e0a7e20f4ea958d307914d2840: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ed",
        },
        a92b1fbe52b27553dd4b7165e8ea5674: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wst",
        },
        "66d0ed8904c5ee3956596baf5aa7a3d2": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "plse",
        },
        bd32ae21559e7e127d0f34f5e61888b3: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cmp",
        },
        d7aa8c3ad006f6ef2bf67d33d9427478: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dx",
        },
        c16585282c0c3813f24f7ff7e26f4b0a: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "land",
        },
        "99f4fccd3ea1a23f6a26e57db0d7ccc9": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fnb",
        },
        d1a2e13d7a04c82a1417cf41925a6ba2: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "icfi",
        },
        "7c99dae4e843e8031e768413a005ea80": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "genc",
        },
        ac697c2c36f3114bacd06162ed6ef742: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "avd",
        },
        "658880694332d76f433c95b13fe1f446": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ff",
        },
        ee0ce4b88478d019dbddb1a9395721b1: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "unit",
        },
        "6844c78c44750bbc4a14ff12119da4cb": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "jmsb",
        },
        "592f49ceace04aed4fad93202db01d80": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sasr",
        },
        "53f2d7595579a565f6bca17ac281ed04": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "arl",
        },
        "99d6b89383ed6b7e8e80c64fcca0cf33": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "meta",
        },
        f392df17956bf89ac30e5420831b5c94: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "roic",
        },
        "0f570b312b676ebfce500f717f4a35fd": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "owl",
        },
        e398dcd60d8a5ace26c528451816fe02: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rblx",
        },
        "032c265dc41def20b2a183246dabfdb0": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fc",
        },
        "524d29e7f3de1f12c25a4a9559f868bd": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "revg",
        },
        "06e9aab9820aa6955897b4f9e1cee5d1": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "efsc",
        },
        "4363481c7872f81f7e96d1580c87ac80": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "idt",
        },
        e3ea2e16afcafcea80b5fd4754d9a60e: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "crnx",
        },
        "77b0f28899b669bc7ba8733bcaac3335": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "msgs",
        },
        d4753a3471cc6bf9961cd868b4c5536b: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mdxg",
        },
        "222888ffc7d196a4a832cf9ba935a4ca": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "alrm",
        },
        "3a56a581ec8ff60aba1514e106120b0b": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "comm",
        },
        b4a583ae4d78fab82a23ea9b8e158e10: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "acmr",
        },
        cf988c654a7add0e3518290d9f7c42c9: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bgs",
        },
        db9405ab63045f793b5b4d2744857e41: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "jbl",
        },
        e3f9ba97357b0e6fbdb1628e0aebc25e: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "msbi",
        },
        da253b59aab9e25dd2a5b00aa8e31b61: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rvnc",
        },
        c221ba2413849dace65c2be8a64294ee: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ibtx",
        },
        a919db72e55af944365228da036081d0: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vcel",
        },
        "61c9696e981e6316fdf71f83f5a9984e": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rna",
        },
        a3e3471bcf0af34af1c43e2678087509: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "uvv",
        },
        f95e9fbd861c9c0780c119b6f165991d: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vc",
        },
        a8c7ec5aeb196e743d3e8e768764d531: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "avy",
        },
        "8a427f2a823951d79d4dc7ee0f4938e0": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "krys",
        },
        a7f711a816c95967d990d29c7c0e54df: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "unf",
        },
        "153aba3535ccb77dbc483bffe224bb5b": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sage",
        },
        "24b32419b90fef44e959579f022795a5": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "has",
        },
        "14b852975a86bd96e31449e530264888": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "eqt",
        },
        e323e89a90564032ae4ba81428962d07: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "utmd",
        },
        "3e719cc4944ba276bd13764547f90c63": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "leg",
        },
        "66a0347c6774885c1509ff8c95d5c8c9": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bdn",
        },
        "2fe210845544a0ee8220540c3dc35e82": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "jbgs",
        },
        "58da6c2223c7726b03873988939bc1ea": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vz",
        },
        "2e21a72c81d1aa2dcef0c2c63e7cdb17": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mtch",
        },
        bbd6f72eff7f7a67f801bb3ea1795ef8: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "atsg",
        },
        "1720d519a1ca3e88871b45b2dc46cdea": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "agx",
        },
        "5a1017486ec59af5978bc737c9f5089d": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nc",
        },
        "7fb7fd1bfcb3e7920528eabd749577cd": {
          currency: 7,
          exchange: 4,
          investment: 4,
          stock: "b",
          symbol: "brk",
        },
        ec2ae0d50d2e28684c1c1d523b0b286e: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "spb",
        },
        "66ce4cc3efa83aaf850bbf1bdb7d0cc0": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dht",
        },
        caa60f19a8ef6294130cde25b1d6699a: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "amd",
        },
        "7bbe2240f0576863d9af686b41c5269c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "swbi",
        },
        e11878df977c3d29a3720997558a18af: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tvtx",
        },
        "1b757ec750d8cdad4e92685e45ac147f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ait",
        },
        c437a7bf5bf25c07347fa065e5afe313: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "alit",
        },
        "36665e3fb8c99a78ae21dfabcc2b7dee": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ftnt",
        },
        "64543d6e3ac3b8ebccb8be37400be66c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nrc",
        },
        d543af9937245c1ac9ddd365cb540e5e: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mmi",
        },
        "1eab31e335f1cdf473b8f353ad68c483": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "clw",
        },
        eaa31c7595db6dd930d8c14223cd57ca: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ths",
        },
        e55d91c28c6015643137f30420262d7d: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "verx",
        },
        d31c67e68c9a6721af8fb2bd9b76f1f1: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "go",
        },
        "612d51b2c1a8b16d0da837ef288f87fe": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cim",
        },
        "8c3c680487acc2ece1a873699d7ce61f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "efx",
        },
        b4ba5362afcdd568f01a418252a9e424: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gshd",
        },
        "59504cb772d2beeeb163de6b34141010": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rgnx",
        },
        "66f694635aa41b99144421f01dafd107": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sei",
        },
        "6436b1796a4fb35d6b31e82d5185feca": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "aosl",
        },
        cf01292977ad8f0916d2dd9357144193: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "luv",
        },
        f365950daba33f2d440107c10bbf658d: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fmc",
        },
        a8b22feb7dd1673084369bccaf871ef9: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "insm",
        },
        "937e80059365592f66aff952cf40dc7d": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "buse",
        },
        af7c6c63b0ddba5c6d6126f220431f4b: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "achv",
        },
        "2c49f10ec3eb172730eba1d020c28493": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cat",
        },
        "02ee1036bd0375a4e49acb59fcf1622c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cvi",
        },
        "882bcd10345b1bb6b030f9acf752e32c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vrex",
        },
        "673ad972b507c219ca1861b741b6b557": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tsla",
        },
        "4bf5966db9acea7685c225b7ed537b1b": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "saic",
        },
        "98033aa9da2bdab94ad13734962d8852": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sem",
        },
        "26b439452e2b8fdccea8825ea5da3535": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "aspn",
        },
        "3e29374683e5f3a99a64b82430508802": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "amsf",
        },
        "3f41af21b0bbf0c2e3aedc957859dbf9": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ffwm",
        },
        "99931586ab21a6c62b0ae81cf6043798": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cac",
        },
        "8f1ec72bb09894ff61dbf508ea6cd8ac": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lyft",
        },
        b0a682b87ff797615cf6bfc8a4f7b611: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cwk",
        },
        ffae12e8e3f68345117d98d6f44d8c36: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cost",
        },
        "9fbce7b99c7b0a472769c59fac496298": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ttmi",
        },
        f7262b2d46667ffde65a5d4c3206ebe3: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "msci",
        },
        ce1635b74ee327eed0f41f7b5e776cd4: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wina",
        },
        a48c6524e574549c2f401ee65d823efb: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "byrn",
        },
        "1a3572f704b8b5a110e59d3283c1eb27": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gcbc",
        },
        "223afb98e6906b3330c4adf692363f3d": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "band",
        },
        "3b22b3677c46ec8e01f0addba8cde525": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "eypt",
        },
        "6eef21ad08f7ff08aaed6ca51216ae83": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "coty",
        },
        "40a2cfd90d4c7e27685e2feb2b3f5e00": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "htbi",
        },
        a649870ce3ea103534626810fe7b6057: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "byon",
        },
        fcd0efb987287c5727074597daf644a2: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mgee",
        },
        "1dad91fc583564343c25f64b0a8d26df": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nmih",
        },
        "8930ce3a699232fb066426d18dccb0f6": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lnc",
        },
        "0072bb5f15de2ce40df7da4cde34fe55": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bld",
        },
        "5877608c3791bb463bb5ce723a4bab6b": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vhi",
        },
        e97d457df13eb052cc2b0729446ad44d: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mrvi",
        },
        "3115204d5c55f69f74d61bd3ab3bfb40": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "el",
        },
        "716af6a8ef1a02e56aae2235a516966a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hlne",
        },
        bc2492c3be94425e937d83b561dfd285: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "seic",
        },
        "7eed1391c52006f3ff53d0d14dcd333e": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "eqr",
        },
        "6935ff14ca607ca782dc6bc337aeeef6": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "burl",
        },
        f606db763627d8125e3d8fe424da81e4: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ip",
        },
        a8d94a70c4c8a2ccaef064b5470d2539: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "novt",
        },
        "0a17dce9abdaedc959fa0119170c97c4": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "zts",
        },
        "86494e4771a4d28035587745aef210fa": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "egp",
        },
        "9f5b63aa4e719daaeb31e752b0ca1a6a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "evh",
        },
        c224aac3e6c5d297b0f6f7e15a856391: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gbci",
        },
        "615eab2c87c0628789e6468a742563bc": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fox",
        },
        "257bad9417fad19e96a3e828bcd003f1": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "flng",
        },
        "0ddc725a2a977164916e88c4a6e17c0a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "clbk",
        },
        "42c499b7551f82c47ea4b8289cee1a16": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cour",
        },
        d779003300e4d9415c0b0cbeac3cc430: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lway",
        },
        "2a87727e34254106fcfd5e6f437e3b54": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "kdp",
        },
        "5557e0a6f83ec903f5f1179a0b487baa": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cdp",
        },
        "63c719289ba439f1b501c16a51cdb4f3": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vrtx",
        },
        "53b73d75d3d86dbdc6620d8520f3e27c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "banf",
        },
        f4f2003581a9ed30eb4eaa9adfd0de66: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gis",
        },
        e9d71351016840acadd5e920065451e4: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "crs",
        },
        "431936da6b6ecbed81bb8f05e74490d7": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lcnb",
        },
        b953fb45749a2cdd621224d59427d397: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "chct",
        },
        "04f3ea1493a699e5a0ab01f035c28f1f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hrl",
        },
        "5cfdcc2dac1e607d212ca28d4bf99998": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ese",
        },
        "01f5759c2ba9fc50d8abcd94059db372": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "uht",
        },
        ffcab38981985f5a39f0b5b5ca6d6762: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "five",
        },
        be12fa1a3c6164748fb837f362153ed2: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "oflx",
        },
        d7b06dbd7794a49e37d1837aeab9dbc7: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lea",
        },
        "7cf86e8cf03b20bb0023ebfba9df5be9": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ati",
        },
        "3dfa947a8e9a632b47418f064e9e2c89": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nycb",
        },
        aed3b42a6f54a1d5814372d499915dd8: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tnk",
        },
        "6f9c136035ff5b69639b233a71dabebf": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tex",
        },
        "74ddca19144d3960021449c17926b15e": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gpn",
        },
        "5df4d5ce423de6c463bee335993f7440": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lpx",
        },
        "63635e1ce4b15375107a46d142e80476": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tci",
        },
        b49bf82750ad8c0f1433e5c44faafc14: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gcmg",
        },
        "63c80cfcae86d508a31b5f19046c37a5": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lulu",
        },
        ba6c927df4c73d250718133518225500: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "smci",
        },
        "89838585b72616e9110ba3a602834222": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nws",
        },
        "2142058b733cc0d37c940b7154ce57ef": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "aep",
        },
        c90747e8de67a4263e42e2eae3f77f7d: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lbph",
        },
        e7cdeb0d6f09a16d0ed6935b1c1207a1: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "smlr",
        },
        "4d4f789356bbbd0db075e5e52479e67c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "kfy",
        },
        ab923fbaa6f6228e5e40e50222f05939: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "glre",
        },
        "4c5d238fe6f2f3e38dfdb4a2f1e2fdf0": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ngvc",
        },
        c665b623d9db48b6ddfc3f1613a48e48: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ame",
        },
        "70c191898d63302e1bed7536558e2d80": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sldb",
        },
        "859bae643507a12de6e12b82552eaadc": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wts",
        },
        be9132b7571a5a5d06b7d4703b28cf2f: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "now",
        },
        a7bfff00203bd33d6782efaeca7cf809: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "low",
        },
        "0bc03c9f0cc040785b838eefa917b42c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mlp",
        },
        cadac7d839348e79040bfc14ba3a082e: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hes",
        },
        "8c89f8b0d5391ff55921bdd4139e8f97": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ensg",
        },
        eadf6cf6d8e70d3e1cb58cd5eaa05fdd: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "krny",
        },
        "583568034dad521031423f172113c48b": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mlr",
        },
        "4f183a904837fb811f3ddf63d655b9cb": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gs",
        },
        "392b17448c0ba2c8eb2f1323ad309ae1": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "alle",
        },
        "3577053a8e8178d075b5e37e25887c6b": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "jnpr",
        },
        d89f93ea8eaaf8cd9f1254bdfb4a50c9: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "roku",
        },
        b862ed76b959a21b8558e99f456091c9: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "etd",
        },
        "55d3a08745cfd7c9104f832f07989996": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tdw",
        },
        "1f2842ce87d236557e3040d804f6407f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dg",
        },
        "9396af8d340b65cf315eaf6cc4f1edeb": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "arr",
        },
        "613d3311e4f9df22063b9973217731b3": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "boom",
        },
        "35e594205009eb0b9eeeef24a7b5e63e": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "he",
        },
        "7101f13c31eea40d4567a77ca3b0a545": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pinc",
        },
        "6b633729cbeff351388e77c02a3c37ad": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "zi",
        },
        e1a9c112c66bc6ab85fc34c7aa4af235: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ares",
        },
        "3ce4a00ee5c4f0fa45f2f99677528933": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hlt",
        },
        aaca2d2809c87a89fe3f16013c4112bc: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "infu",
        },
        "44ecc61b2c3ac8767304969c24c256ae": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "qtrx",
        },
        "7093c036d022cf17122b7a407ee2100c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bx",
        },
        "778f49dfee83e2ab7506f70db0bc00ca": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mtb",
        },
        ca7510787f113e4fd3b47fc198f61ea4: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cdna",
        },
        "525a37600b3b0f7cadccbceed362031d": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ulta",
        },
        "15e26c2242ef3227f4e6dc843bea2e6c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cbrl",
        },
        "94bd1a085971bfbc3d8c22685922f204": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "irdm",
        },
        "42cca12f67e6192da4050ec2c64d8b52": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cnne",
        },
        "44e14af2caa664294c65d3cb37227acf": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mxl",
        },
        adc6b93104ee290e75701fba8e0529e2: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cffn",
        },
        d9896278d850b0c3e0e8816470b414cf: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "msft",
        },
        f12280576e6f9923556bc3bf951393c5: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dea",
        },
        "53c09c2496323f211a52d72e135a0c13": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "u",
        },
        "266f47b4d0839d204d1cd9b07c4613cc": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cdns",
        },
        b6948bc7abbf0f6f0547cd6549380a22: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "irbt",
        },
        "155069fa276d74dcaa8af9643ba03b7b": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "atxs",
        },
        "902a12931321c352103e2331d66b8f3d": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "obk",
        },
        cf149048e610e3f611147828a0de6599: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "celc",
        },
        "166af74faec0f896025597f77f58d970": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "evri",
        },
        "0da1106f3251a1a0e0865493c370fb1d": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "adma",
        },
        "5dc203bf1946a77b402f24bd2e453d12": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "kalv",
        },
        c7159623a40c0824075040a0ff8775c4: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cnh",
        },
        "2a5a6a07f3a4902276f5fdd36a80f8e0": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "trgp",
        },
        "13d510beb3f8dd39ce980c4c57ddd4f0": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "csx",
        },
        c18c385772370c8d0e8c45f92bc0ee41: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "otlk",
        },
        d743af545eb45dedc16a446a5fb33d1f: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lrmr",
        },
        "22d839946072103d7d027f5d45956f67": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "trtx",
        },
        "51279c9e948d3935c5a5374eb8701235": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "c",
        },
        "85754cbeda8e8431c32bd39f8c921734": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "anet",
        },
        "5daa03f1eb0a8fc6bd44f04b6d2b8f26": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "trv",
        },
        cd3c3a20964a2d56fbf479a13bd41074: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vygr",
        },
        "738d5e4b9305baf028e7d93c517b761c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mdwd",
        },
        "7d8865f3798e3f5b8cbb1c8535b26a83": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hci",
        },
        a725d2e4a872fc6b2be8b5f4c1d25f1c: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "armk",
        },
        ab1b7c5788c5cbe90413b2c5f6ea0884: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "aapl",
        },
        b4ac8d7bd4bedc2176d130064f47369b: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "apls",
        },
        "3abef08528a663e6080f6fe592168607": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mlnk",
        },
        "09db6910022c782e84bcccfb221d3677": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "glob",
        },
        a26ffdebae1aa5306c6435e6fac63581: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cmcsa",
        },
        "8e1fc05f9e09b1c87cc604dbe56a8f34": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "acm",
        },
        "5645082bbd5dc22ac0f84f5551c404f7": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cag",
        },
        ace5c8ca2428dbef317de6e6013128c3: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pltr",
        },
        "8bfd0cf0ce618f8cf130ae9402ab7d14": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "slm",
        },
        "00efb9f97ee17468b2c8b7bea204bd28": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ibkr",
        },
        "02105debda59362f47ef6283bb9235d2": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cvx",
        },
        "573063379aafb0fed92ac0df1661765e": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fico",
        },
        "20c4387199e2a550c37871a2bc9b9a65": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mpwr",
        },
        fa1d98bcdb7442ab12330ce98608228c: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rmr",
        },
        "709f801fde0275203805542eb189de6d": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "icui",
        },
        e0eea899de9e3a6f79050fade597c1ac: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "imxi",
        },
        "4af41ab741361cdc0cfc55e9dec9151a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "drvn",
        },
        cbd0129cf062f5a587107c8a1900f97a: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tjx",
        },
        c98be7721843df66549c60ecf8d43b00: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ssti",
        },
        "14b051b74b14467b3788878139ef5e46": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "egbn",
        },
        "97f95a56c11c4be7c9b3130ed0af9d48": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bokf",
        },
        a1a0cdb55f521883f689f77901032b45: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tph",
        },
        e84e59c1b1a6cb61956b8e60bcc1470c: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cprx",
        },
        "6090b4ed55594a248ba494321ded3bbd": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tsbk",
        },
        "67ada9d7eed92b4efd61719cd03739e8": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "srdx",
        },
        d19a0f803069ffb1e73755d41cceafcd: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "arq",
        },
        fab7ce1905bf2ea433c150d84e973aea: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "djco",
        },
        "8d3632f1dcca379a743c0667968085db": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mktx",
        },
        efda5418d1137278a8887866e1017deb: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dole",
        },
        ffb6c6a2ddc0bf890594ad32b7025b4b: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ambc",
        },
        fd44c78aa96641123e8bbbd4169f597e: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "evr",
        },
        "0611e36b3738d092075012abac686f51": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ogs",
        },
        "19f5736a20d666e262e092f03a146b56": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "smid",
        },
        "65187607a4787f16012a62f21483bf48": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ari",
        },
        f502ec0b465c310947beda177282e0ef: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "syf",
        },
        "6247da701a3e63561bacb77efdb52414": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nsa",
        },
        "35d7c76c3969b58e7c3ddccad4b19df5": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lng",
        },
        "90c084ba683dbf3bfae4c7a0d0b1300c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rcus",
        },
        "9d6dd26f252b5c27d38ca43e51266df6": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tbph",
        },
        "93778943bba67e89ed9e4b01708a2e00": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "itw",
        },
        "66cd921a8c8bc01c593698a125563f33": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tree",
        },
        "03cd42b1c5f339e8e1ade3b51dcf1053": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "kfrc",
        },
        "0efa13b9014e7c005f6f96cff50af731": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mac",
        },
        "335750587326eb4b9a0d8df9a3a1304d": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nfbk",
        },
        b67d545aedbedc272517cf68aef48454: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "uber",
        },
        "914d0ef14702fbaf72b2cabb7cc43769": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "uis",
        },
        "0893ac903ae956400ebb4912507496a9": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "atlo",
        },
        e2bfb3e75cc2ea40a238d1c8ba67093c: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lmat",
        },
        "3304328227ee04a32b793c3eeda3b042": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "metc",
        },
        "75e923c1f30c287310fb0543d9f88a86": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "air",
        },
        "7143d9f4e5cc6cbd2e66e1831bee186b": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "itic",
        },
        "0bcf9774d52d7410a91ffa9fe2646dde": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "snps",
        },
        bef61864b88a584ed571253d8890ebe5: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "eols",
        },
        "5eaccae44f4a985fd5ac979f103f2bad": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sndx",
        },
        "98c0b1b56363326e963bdac31464b72d": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wash",
        },
        f2470cd00db3d9bbb122200a5d98c043: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dk",
        },
        "3f965775906262be5c4632043022fd96": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nwsa",
        },
        d19dcf5af93227c1889458bbd64e8e9d: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "oec",
        },
        d8b78d7ff9c9647550324e0272a8b543: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "loco",
        },
        "0321a7b59d938def419e8a974bdb53da": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "aaon",
        },
        efc0e8475d15ad53da956f8d2592b37d: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "eqbk",
        },
        e27b7fd2ecaaafed5ca1e0a8f961b6fe: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "esgr",
        },
        ca0944bf13ea9e519a046c71828db8ca: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "iqv",
        },
        "26ebac3cd8cfdc8ecee8ddc75d9df518": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "esnt",
        },
        "726195a1b364734b5f9ad48b0c43923e": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tbi",
        },
        dc174cff7b1597c770529a7e9cdf2db3: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dei",
        },
        "697c377033e77b95336c5cb624e360b6": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "inst",
        },
        "794ed05dc7ad777634eb61879f232bb9": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "xbit",
        },
        "693ee0c2c3bf41ca66671cc7f2894161": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sevn",
        },
        "3dd71cc1a77d84f01c93218a5652e97b": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "unfi",
        },
        "430cf73c069386e21ac88481cd78c7c1": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rdus",
        },
        f81705315af721c1d493b97a66f82007: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cash",
        },
        "107b741f839b1b9573aebc16dcf2f5d5": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "maa",
        },
        "8e2987e5a8ddfca614844fe7b55e1d3d": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "by",
        },
        "6a594127f5dfcaf168679fcb8491eb9c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bv",
        },
        c3236aecc30af56c68d4726af7a15aa0: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dxc",
        },
        "4bc17f9f8fe437e1f1fac47b724d579c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "slno",
        },
        "2cd95100c82c922f99aaf5f82a7516f2": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "chd",
        },
        "461a2f9af16d0cc6143748261accc8f8": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tg",
        },
        "328804b47d4c9188e568500925ce56cf": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "avgo",
        },
        "4aef686aefd9fb4c3c29dd27a9feeb8c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "chef",
        },
        ee4b1871899a272601adf1e474f3de11: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "clpr",
        },
        dbfdf9cb2ebd4cf0191340bde4529d4b: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dmrc",
        },
        "1fc75f0aced982e4f9b5ef6a72cf2b97": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lbrdk",
        },
        "85af551c636599c994e2101e301cbd36": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vyx",
        },
        e8f261b2f2e8c8fb4b7372903b151897: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "flgt",
        },
        "04e1c3dea5a9743dbfefbbe710d8d609": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "navi",
        },
        "3cfb306bfd533648af7341a04acf0dc1": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "zumz",
        },
        "9c0869a43c599e4b9d24a35fc30c883f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "qrvo",
        },
        "979af07e8e98ee3563ba3565841cf408": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pen",
        },
        "298a5b0370bcd3b43354138bf406886a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "adus",
        },
        "1780417ab56408685105d7594a7d51c5": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "osk",
        },
        "802a8338991981bd08c6e0b4f3cc5570": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hele",
        },
        dee6facb42776aaa6b883264b4d74f26: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "so",
        },
        "5b888429b468ee7cd5973278863772dc": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sd",
        },
        "4023459a90cc0572fcc2f00d710ad8c4": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "imax",
        },
        "8b9dcfe1c97977112ff09df780077a49": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "avt",
        },
        "311502de95ee9386759a6ad7cb956bba": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hlf",
        },
        "86fff57386ded2772ea688737628f796": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ptct",
        },
        d40ba63a689a9c432a96240d96357d3f: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fcx",
        },
        de2a5e995037e0fa2c9ba325d6ea4acf: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ge",
        },
        c8513cd2501f65721d456c6c1957b8f0: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rkt",
        },
        "2c8b5d6e44131c99e672ba4de0c59271": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mstr",
        },
        e479384e85296d8d8540b8a7504104d3: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ccl",
        },
        "798391375d273a52b15b3c728af15439": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dnli",
        },
        "4ba6b35a134963e875a4ab5334d8a6de": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tmhc",
        },
        cdce272879caf5e4cbb1145134175928: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "intu",
        },
        "16d1ecce2aad410b3eef9b2d9bf15139": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "agnc",
        },
        eb28336464dc4a985cbc00c94e9053f7: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "duol",
        },
        faf2e0749d7ca2e6cc25eebadbe08db1: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sfbs",
        },
        "4ee287d0758375445714b28452f88a59": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hrow",
        },
        ac1b771229a181f98a9c95066a502963: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "kref",
        },
        "23bd5c31f08b3144c1c3569544fc6dd1": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tdg",
        },
        cbf0a1289e021c056032025248b99ca7: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "kbr",
        },
        "76d414cb96e01d269d35a8b9f82a2982": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bepc",
        },
        b11d79d3d778c79c6bb9faafd0baf4f6: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "apd",
        },
        "1cc8d5e1bb8222fad569579b4a502e47": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pnrg",
        },
        "4d7c49758eb2315d3ae6bf45b1942a8e": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "spsc",
        },
        a9bccdae956ecdce860b7a76176089ab: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nvst",
        },
        "3334da97c16e401d5458c2f191df3a65": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "acnb",
        },
        "34381b71c6bfbe773dc91e9c7dec6a74": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "a",
        },
        "921a7396860a158bf569ea2b44f992cb": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gne",
        },
        c9d722b04c10d1b00cbb6a431a7411dd: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gh",
        },
        "61732916688e77b56e8d0883a3a54951": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fsly",
        },
        f3defe9118001f2ef254f05c3c1b338a: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "irm",
        },
        cc9d886f25cc1f612267144f93b6ab99: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hmst",
        },
        "2ae9d376b941fa634182ec5b878c7fc5": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bmi",
        },
        "0616f6df065077bf81cebbbe04de3a44": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dhil",
        },
        "8d595a60cc95e6e9f660b0a0d074d8ff": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "azek",
        },
        d8a85cd65fb6142a7016239b638f8b88: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "powl",
        },
        b232ef6236360193730825678ad332a7: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "srcl",
        },
        "9cd0587020686999338f44d6e217b629": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hov",
        },
        "1a7a051cefc976c925f82e5a2d6f5196": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "apld",
        },
        "593af62d66294a4f3871bf8475a3c9f9": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "amt",
        },
        cb8cab2fde22b28c4529125d740cf236: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hasi",
        },
        a25d3c81f6de8d7a25e1a7cd50530c79: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pcyo",
        },
        "0ac0b5c4a3572c2f0bb7b404f2c33a86": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "iiiv",
        },
        e481bf83f0ac4279e7450c72596d1cf4: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gntx",
        },
        ec17ba4dc3e96a26ccb2c3f3d1191e60: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "clvt",
        },
        "6b9911e659a34cae75aafb9631cb15ef": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mei",
        },
        cbd52cc79a2b99551cd1f5e130994cdf: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "adi",
        },
        a7885b1184160aff386d2c93b57efb9c: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bhf",
        },
        "5c5bfe844c48c34e846899808f98ca80": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "j",
        },
        "22c19fe6c42107bb7b66bc08bbbab35b": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pjt",
        },
        ac74d68997d65cf1964e2c0e8ebc2e51: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "amal",
        },
        e435a3b577d9edd770200ef4e5657de6: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "imvt",
        },
        "2f7b1fbeb949825de374c26233f31863": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "reyn",
        },
        "7b2d80b92c0d6499f2367b7335cbc093": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "uaa",
        },
        a9c2278065797083117acea36610d5c9: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "z",
        },
        "5929dc46cbd50a3d5f0e00742a2d1e34": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "avb",
        },
        e7ac6e63ef7735a178df15eca910ed6a: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "usfd",
        },
        a34f941ebf87b8cd7d185e0f7b724ad2: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wdc",
        },
        "5700c9a6d0d092bfbb764d63c46f0902": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ccb",
        },
        "6d0dc2fe2752803b6cb5f617ed2e4495": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hayw",
        },
        "568ce8bc712a36d1015c45a7eedde394": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fang",
        },
        b25f951d66f5f250402c9aadc052458a: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nvec",
        },
        "412171513af976f9d9d65871fa2db9e1": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "aplt",
        },
        "561dca8c7bb657b3aa1cf4a6d3c3aa34": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "leco",
        },
        f05886fe787fac12d4c8b6d356dc0f87: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gnl",
        },
        "8c6a080317eda0bfd8c74c5146dbf4c7": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fmbh",
        },
        "57bb970b26c7a9a6b2e48ebea57d779c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "titn",
        },
        a34379cde328e05e99e2ae63a93e468c: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "banr",
        },
        ae8f96740139e41e24fe9d8012803868: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hon",
        },
        "24c8107adfa2739dbf27ce23f77ea2d6": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "stba",
        },
        "84cc37a41c79f4b2ed9d73f2679b9307": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "uec",
        },
        bb84206b52c9fa6151b6883f9a1f032b: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "aiv",
        },
        "8b56df27aca4d53f8f95e0ee4fb8c6e5": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bill",
        },
        bc553886ae7cb59211f530f3e8206df8: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "aldx",
        },
        "48c9629baca31e7294b02d5b8b2a693a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bbsi",
        },
        "97875a948d3c098407e00edfb0fd371b": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ccoi",
        },
        ff8f0bc3176907b39c88a1a73d282e4c: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cme",
        },
        "8edeece7942fffdda618535121dbd617": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "zip",
        },
        a2a1207573477407848148d4a67fc1da: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ipi",
        },
        "511656f8cc51171717695466bb350179": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "clx",
        },
        "8c03a43c88cf0e59df9f237ef3ab0377": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ceix",
        },
        c6db48ea6874c8bb231a2e50168effaa: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "enr",
        },
        "904f394bad20cf1bec53f974aa5e1e6a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bwxt",
        },
        "22cb3673887c02a544885f183abc5402": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "exas",
        },
        f4e27715f21b487a9b519437d62d5661: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mfa",
        },
        "83406e95193eed53a005e4f223431033": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wlfc",
        },
        cd67cd23eca709dfd3442c7754708b63: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "alx",
        },
        f5594b45cc57da2e4e15adf01f6ed5dd: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dfin",
        },
        "009931a03006b1eca401881ee58a774e": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mnmd",
        },
        "1b36c6d2588e12962b39d7a93794f36a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "jrvr",
        },
        "7dacf270dc8042dbdf004b8ed077e6b0": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ever",
        },
        fd45cd4b47d2637e74793444af03ad1a: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "virt",
        },
        b8c82c83a39a897762b6bd5448400c4f: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "qlys",
        },
        "51b9c1fcff60ff6d2adb9a61657027b7": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "yelp",
        },
        "6e3343f89e71d6d8af52a874760ae1d3": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sitc",
        },
        df2ab3c3a0d3183192c1907210764e5b: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ptgx",
        },
        "4bffaf645cf7ebf9b2f8476ac68777c5": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "soc",
        },
        "774698e122fe7441fc4606388252f999": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ande",
        },
        "9d077274bd2fbeeb7a6ef5867c30fb2c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ffin",
        },
        c4443efd30526dd0c89a5cb04cb63ac9: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cpri",
        },
        "628aa3c79396c107232eaa0cd6757ebd": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "snda",
        },
        "7abb9f405c4ec4907f48a52729f807f5": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cinf",
        },
        bc60f3560dbbd70e76111e3cd542be4f: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "thff",
        },
        "67e84edc314b7807cefb4c67701857aa": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mco",
        },
        "49f25e8b36d765b1dafa8ee1abf96317": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "kmi",
        },
        "4c2771d32052e5f876f9487d1d3343aa": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "chdn",
        },
        eabaa2c092045d904b97bb6923d10710: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lw",
        },
        "02b57c4f0e27812710dec63a3004e3fe": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "advm",
        },
        "0f6f020e1a1a2274767b23cbac85a148": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cldx",
        },
        "83e00048ee3ce56bfb9328025b73ae9a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hmn",
        },
        "36196b99a6814999b49eb88577465c52": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cznc",
        },
        f77b0809120c176c33d2e95a6adb01e1: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lvs",
        },
        e9a4f8baf2958f6191f21a7c986273c4: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gme",
        },
        a103b4208e319ab83a965620ed5a8286: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ibp",
        },
        d9f0e7099d90e04b53cbca8d30016685: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "repl",
        },
        "6060349625555354de1bcfc3ea91092e": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "qtwo",
        },
        "578d5d643a32441550a0141d93569164": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lumn",
        },
        "54c60f32fa8a9e916bd15ac134cd14b4": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lscc",
        },
        ac8c81c21f527ff98a51303bdd0101c6: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "afl",
        },
        a694ab0f9247be9c0c2d905bb928a3cf: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "aos",
        },
        b5e3773e3250b95d81b71e644930cea8: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hckt",
        },
        "2bf9099a451722fa3746050c6c59a488": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tfin",
        },
        "5b1b0ce780f6dd663e0d64a9ae6da64f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "biib",
        },
        "0c1423a9e7310f9355c6541fd87871f0": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "step",
        },
        "538a9072ab806d91b957aa0217c09257": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ryam",
        },
        "6fd8589d4d1f4e2317db467accd869fe": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cvgw",
        },
        "274c44e452ceada7cbe1516d530eaa38": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "len",
        },
        "8ec05158cc93e2c25a112d69fdecc9a4": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wafd",
        },
        ff9cb86998273b7dacc1ae4344c2ae7c: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hd",
        },
        "9b092744d2cd23bc159e6b8d2023cd2a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "expe",
        },
        "0e86d0d895d145347f8f4039a99d3feb": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dal",
        },
        "43caa4a43c77de9be7009c0c5450dde2": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fet",
        },
        "7ed6b2d858eb958c7d5a0e2760ed2b4f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mos",
        },
        e846b4dfa87ee732fb5d43f35bc8f16e: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cfg",
        },
        c6ebee7917b7f577098d79fc88133a5b: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mchp",
        },
        ea8e39a00fbfde90c5f30f7a966eae35: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "flic",
        },
        b82f9791a8796936f79d39efc5584952: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lope",
        },
        "856bf814ff1160f9a4821cb5e0b2fb95": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bac",
        },
        e00bdf64a6fb68876dda4f153c7bf315: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "amkr",
        },
        "75b7ecf2348c65fb53466e59597cb732": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "eqc",
        },
        "4c5d8121f9b5b2afccd7425948eea666": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sq",
        },
        be6a8e00ae654d399ecde7324308e4a7: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ingr",
        },
        "621f2469c196501bc5ab43d7b615150c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fr",
        },
        "6d7d58e09019d06555be0b075f9cce75": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "itt",
        },
        "843b49a2de429b92aef6aaf71ad873bb": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "shoo",
        },
        b3409abb47aa1555973929f110e24a5a: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "irmd",
        },
        f52a21b84c4adddbb9b9b621c44b300d: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dds",
        },
        "5e04329c2446915accd3f28d0b300270": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "zyme",
        },
        ee6e41a8a90493dc14c16bfeb77262e7: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "prax",
        },
        d06f69d6f96683725c6b5bfa05eb8bdd: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "acls",
        },
        b89cd9bcad08b79fd0eae1a506b9d8b3: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hlio",
        },
        e40f42641dfaeaaf2fc9630ed36a6aa4: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "md",
        },
        "57ad6ce812a1313c8b698841b31e3c4a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hbi",
        },
        e187a63e28ef7afe9e1d527284d1b3d9: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "intc",
        },
        "454c442478d7c3ff0d1f5c3a9cf98cdf": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mtx",
        },
        "1f1fa3f5ab282234948a2f82c72640c9": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "atec",
        },
        edf23383162ae7195778dc0ae6d5575c: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "immr",
        },
        c356014da2f888aa2bb7e71409859b6b: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cbre",
        },
        "52dd96a6cfff1a8fe24ecb073f9a5c8d": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ctgo",
        },
        dbe181af1c8fd9ddb26404f739351836: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "oc",
        },
        "49f5b4cc37066f9a7cfe44e44f276ac7": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "epac",
        },
        "24b3d2705b2427f1bf0320f8337076ef": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "seb",
        },
        "780ac1c8aff290b02a1975e59cd51dda": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "urgn",
        },
        aebc4edf672b6993f469be71c938dd00: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ep",
        },
        "6521a1e5037584c868d4b1b6bd0b0281": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "uuuu",
        },
        "0b9887f2e62680cb05562452b9b2cbe3": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wow",
        },
        "81c9ce0c7bca1a2f07162e430be2b652": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "crct",
        },
        "5f9e525d34c2f3530539948d5679338b": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "chuy",
        },
        "1984ebc6139edea5a4c5bfd7fbe26378": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rnr",
        },
        "3b58f056200e4994f135fb4b7b86451f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rbb",
        },
        "041fe2e0e075a0da56478ce9d210d3a4": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mpx",
        },
        abacfae3ae0d9573297d314e074a7f91: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "asc",
        },
        "99be4f8484485c48488baf11da55992d": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "geos",
        },
        "39c0ae528bab8f3eb87585dfc3803e7c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nx",
        },
        "1b27011fd0cf955f0f8b6892c34b9dd6": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "kkr",
        },
        "24f5db0f9b6c5609401a16be3aeb2f0a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sitm",
        },
        f0cdc01ef4311582540472ac93600f06: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "altg",
        },
        "91bed22de0bc1cd58c3424cb32685441": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hst",
        },
        f8cd890684a0b6dd21988f2561b7205e: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cio",
        },
        "4942994ffac2ba315e0b4a3087ee7d74": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bkr",
        },
        "73e6a15ab685d585b15ec117a9ff85ce": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vvx",
        },
        "552aa10792d50998a9cbff01c39f0ecf": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tipt",
        },
        "2b0ba91e5ecbbdb93bed852940eae200": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "spr",
        },
        "3278aa2945d1b0c992d291e0b4d83dfa": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cwen",
        },
        "9e2eb1d19c772385b11252dc5bee5000": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "brx",
        },
        "8f0f04f07b95c83a5d41b757e70c8c4b": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fhb",
        },
        "6d6dd09bfbdc81c6935699966e38f548": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tfsl",
        },
        c7fba965f4ec4eb4d022cc76884290e3: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pi",
        },
        b26a2d3d9975e365266fbc103932f001: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fold",
        },
        c43587c7a2f8d82f866a781d8b731a9f: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cps",
        },
        "89e11235b45939afe0387e7046e80d96": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wve",
        },
        a7f51c6230ced6b04ebede4638575c2c: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "inod",
        },
        f628178963050975fc46dee7b6298105: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dell",
        },
        "3a03a53e608821c144020b947ec8cca4": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "btu",
        },
        "4ff1853b32095ebec496e0c637dbb6ef": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "avav",
        },
        be00e37b4334b6e02f22e3d09d8ccc0a: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "onto",
        },
        "78695a8c705d6355c30dd3e4f98dfe1d": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lbrda",
        },
        b947a750b6a97e64567c7eef96f03b82: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "spxc",
        },
        "623508065dbe6dbec05534d3933fde1d": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ango",
        },
        d78f5b6a4405822fcbc33895b717875e: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "epm",
        },
        "38a2d1cdeff40c65fb9ac392dcab82ad": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cstm",
        },
        "621a9503f758629469f9377f4efde14c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cg",
        },
        "181fc65fb2325c1a4f97201c2ec8e0ee": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "catx",
        },
        aa3c773e0e02baef54ac4b911b7392b6: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hnrg",
        },
        "893e9b96436202c3fd42f925a190c212": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cpb",
        },
        bca5d280f4cc8aa98ff7fd977d2ff7b8: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cogt",
        },
        "30a6a89d5e6c9c8a76cac9f9b425112f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "foxf",
        },
        "5ca66759dcca70bee14c3865657c6566": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "altr",
        },
        "9f87a54d22194fdde97fa64141d34b9c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "app",
        },
        "7fec29c9181b459679c5508a105ae218": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "atge",
        },
        fc672cad5943bcaa1d731962b71d623d: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "d",
        },
        f41cfd9e4c02ac475e606f9b5eac2f34: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "kw",
        },
        "2d8ea31fcb0a0d686e2abdb8cdf5b717": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ceco",
        },
        bb888125c0a1d94e23a2232e1d37a829: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tw",
        },
        "26ba231e5fe4252ff499e8ac63958303": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cmc",
        },
        "5851cc76429db2cbf3e0f182772b6da5": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vtsi",
        },
        e5a7b4182a209a52def270817452a2fb: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "enta",
        },
        "4b0dde839136f6321c4a03d814afd71a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gff",
        },
        "9f045cc059837d08f2b3c29f5ea65b03": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "avnw",
        },
        "1d33260b223aa1aa35a08710f387f964": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gm",
        },
        "532e02117cb8a13dacec53edaddd8fbe": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cof",
        },
        d9ac1addfcf17dcea5d5c67546abffa4: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hei",
        },
        c383df0e2e57dddbd41669affe2938ce: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ibm",
        },
        "7273c4e0f28cb65033a7f302f185c62f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "kr",
        },
        "8e047ce278ca5d0bc1eadc0becde1bad": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nbn",
        },
        "5de02365cafbd99ebb1f60ba9ebaaa20": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "day",
        },
        "73e8a0f2e918ec1581ea6e5e89a92780": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "xoma",
        },
        "6cd83834d61d71889fff00f5ad3a3222": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "apog",
        },
        d4cc4cb59103504a79fc6043887578f2: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "anss",
        },
        "50edb94b05f5f8617d5be4b305de8e45": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "axta",
        },
        b55e967d340a400c45a6ecdced677bd8: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lc",
        },
        "2dff8d4d3e2206df807c76d11eeffef3": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nvee",
        },
        "1ba78b64aa96df4002bd02b995696d1f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "amat",
        },
        a304025e2c0d2111020af42787d77801: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "syre",
        },
        "87088aa87f54ae44be0ec47462cbfaa2": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vabk",
        },
        b567985fa965ceb7e22a2cd4cf4bf621: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nref",
        },
        "31272812b26b9179bec08034b3cc009c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dnb",
        },
        "11c55fc082241caf02c2691e652ebf46": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "kfs",
        },
        fe7f7ac9af72a6e28a05c68f1936a644: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nvcr",
        },
        "39c50b23365b865537766bd43ae17114": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sfm",
        },
        "68162af9495aa63810c7df8c63d49c55": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "appf",
        },
        "8ece5c00fb70ff5b53388740bf734ae5": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "thc",
        },
        "701e570692220a5398a763028e029de2": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "amrx",
        },
        "9ddb838e6d885e1ea9d3b5ad639f4a01": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pfgc",
        },
        "4bf5b47076b385fedeb5b4772c2005a4": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cix",
        },
        "5f7a731dcb64ecad83b7b235a0ad0602": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "eog",
        },
        "8724c96b587b1d86211350de08b0e962": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ivz",
        },
        "4db183e0cae672717b6a13969317f499": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "siga",
        },
        "71155a4e3d966f68ca8ef8d0806fbb98": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "jpm",
        },
        "6b40537fa42a589a9e2b232dd3059f89": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "veco",
        },
        "2c8d089224ae59530024fa02ec62c38f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pll",
        },
        d5e06d796f4e149831c24f459e769730: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "orc",
        },
        e2c7f7d3817f5d0ab2d14729c976607b: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lyv",
        },
        "5ac5cf5676cb41f8da45fe596b692a85": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "an",
        },
        "834df74eca1f287e4621a23403a3c854": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tsco",
        },
        cb0d69a8dd4b8098cc7664bc9dde364d: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pmt",
        },
        "2048267b0cfd4584d654877b5d6136dc": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "stng",
        },
        "6a5b84c5779a1e5b8de49847879f7045": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cma",
        },
        "7aa7b05da74e64f3e6aebb85d065cbc9": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "anip",
        },
        b8e0ddeb84fe745d7752107771646688: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ovly",
        },
        d6863df6cdc31a37ed23a54609fd913a: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ritm",
        },
        "8adfea466d0c13ee34780d054b19fe6c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "modv",
        },
        "94ec7fbac7af783a0a3f0c50bab93746": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sats",
        },
        "0070d982df9a60708e80d1bc8a97e938": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pags",
        },
        e8859458bafea389b8af1cb326f56edb: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "kntk",
        },
        af557456d16ec7db08b921e863a52729: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tpb",
        },
        "68fb230150f6c02b9ae283eb3f72b000": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "b",
        },
        e06d87d444d320d9db5f7728a830f1c9: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hiw",
        },
        d6560ce0699be459a9c4c154d44cbb39: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "scco",
        },
        "5d98ae63afe32a107b59b1a98538d472": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "adp",
        },
        e98a87d7e88aded5f6db07f83beb184a: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "xom",
        },
        d97e3e73fb2bab741611cc650892a9c2: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sono",
        },
        f124f7980a0ccb423f4ed5d83e8138d9: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bxmt",
        },
        bc653d2de8ef1585f7d9109e03c2d8a9: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "payc",
        },
        ef27e1a44c9628398d4cf9c8eee371ed: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "idxx",
        },
        cc5c345494767267f3fe1171c43c9584: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "alb",
        },
        f826e217a69b67b678c84485c0447962: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "osw",
        },
        "4b540b9233b1425858293518a977fe0c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cvlt",
        },
        "9b456c369d859fcea865b8f0a5c5fc13": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lfcr",
        },
        a6031fa79eeb9a593a8d88930dc3bacf: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wtm",
        },
        e6d8ffca07f18b447e786378a34f45cc: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "prta",
        },
        "6993604cf4549d5775b43cb787951100": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gap",
        },
        a9542506e5448993f627a6cf119776f8: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fcn",
        },
        "9ef90b1ea8c08e2622dd0e247edbe6fb": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vsco",
        },
        "4336050ca1e942a2ffb37a240d3c29ec": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "de",
        },
        ba39c59471de86ad2141f706f35e6423: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "usb",
        },
        b972138108e48f17bbbe63a5167de880: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bio",
        },
        daf8fe077f15280617857641207d3f9c: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "msm",
        },
        "40fccf97f9a8ddfabe6ff96fa078c3a4": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "arw",
        },
        "17d460b24d8f649cb915fb617d94e237": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cacc",
        },
        "9e33b5225f83307638e41e49496ffcbd": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "xrx",
        },
        ad155cca9059025747a03f278e8d5e68: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "crmd",
        },
        "49a207cd9b43f81dda696fb6157c7fc4": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "esq",
        },
        fb9a27911d166f1011b1141e7288da7f: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "clsk",
        },
        "41a89c69bec01bf63058aaf645de13f5": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hifs",
        },
        "134c231e4b2ddf6454e0ead67fd74a71": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mbcn",
        },
        "1bd9a0bb068a08d33d4bf609b4ed7fdd": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "insp",
        },
        "37c931d4d7e3faef9cdfcc1b66962383": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ir",
        },
        b9eaed9220cd519fe885bf6b822b0ad5: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mrcy",
        },
        bc105abb3ea11190aeab7672ccd4143f: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "alco",
        },
        "6b1d3fa31d3190754b62b6edf4b17f26": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mc",
        },
        "18c57ba43038f700ac4923faa49812d7": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bfh",
        },
        d10bfa45a219bbd418cd567234b09a19: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "aat",
        },
        b5d2e936bcec666cdeb81d569db03c2d: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ingn",
        },
        bba14061500319f6074ba3adaf071ca3: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hbb",
        },
        ae545794271ef76b1ec90b46a83ab88d: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "amzn",
        },
        adb720e5f8a44a7d5abd95c5efa8b663: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "alks",
        },
        "0605df39d52ed7d15e4111cdd9d7cf0b": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "uri",
        },
      },
      weights_values: {
        "270b39c7639848c4daf9c4b9e10d6fb2": 0.0029,
        eaefcfab18b522a3ffd64a8c8b869dbb: 0.0033,
        d4ae0f108f35de495241e61346ed23f3: -0.0012,
        "53af808d43d695b8a5462d778d87f658": 0.0033,
        d313a7e438129f2555e52d5c3d54d6f0: 0.0033,
        eabed427f6cd5e134a94b439a4029592: -0.0012,
        f683ed3911ff32157562d4de1ea075ff: 0.0036,
        df64245b30c622ca8131e3b6b0bdd916: 0.0036,
        "44ed392a0a81da8e81a9fa1daf78c5dc": -0.0005,
        c60f803b2c75f803f84fd84547baa5cd: -0.002,
        "4451aa7790482cf257d0178604fdb719": -0.0012,
        "15def38dab1dc4ae99376d7754c63882": 0.0033,
        f23f0ca4db72d2396a143a3715bae535: 0.0033,
        "29f09d5695bb5c2e4a3e40f889cc451e": -0.0016,
        "95312fbb95ab036db7a5fc9117cdfbe3": -0.0012,
        "22ad7e3025b33dfb79031bb1fbf1e31c": -0.0024,
        fed87f005e587b2d04b965aad7aa7e44: -0.0017,
        "00e2e572504b2c108c9bc2600a89dd69": -0.0016,
        "239701df36f9776af7730b41bca6807c": -0.0027,
        d01b1a997a07977d1182862e27ad9b5c: -0.0012,
        fce9583334f4798a38545aa8dfd38780: 0.0033,
        "1c09ef9a2b5959e91a5acd68eb01241e": -0.002,
        df800436a5fa872d8e7b0ca0dea8884e: -0.0016,
        b8df18fc7e00bcacb14ff3d112442433: -0.0049,
        d233094ee33894d30e6adb39a4a0c8b4: -0.002,
        "57e315ecdc80ae62a4b434552b0629ac": 0.0066,
        "082f2ab41c1c51ac3f563825c1eb383a": -0.0017,
        e6fc417cc6e111336e2df114d6dc5a15: 0.0016,
        df61e41bfa154536216862d9fc9a1c9c: -0.0029,
        fa4f09faba486ca4701eeecdac9d5ec2: -0.0005,
        "08b31ea26c5a8f34d61130ffb357de23": 0.0034,
        "605fd3518ad3eaa4815e2d1b38e8cf95": 0.0051,
        f9a32bce413db3e3ccdf806ef55f050f: -0.0029,
        "1421b52081f233415b9fb5e689dab7eb": -0.0031,
        "133d45549a6575daa389987a513eaf6f": 0.0016,
        ac5fe9aa93d4fc2b32770405ee3051f8: -0.0007,
        "1d6941b9b7ecc737ffa7dca99e72adc4": -0.0016,
        "3c40460e8f4ff8826897e08f6efb2069": 0.0016,
        "4a28369e603a65223da2fce02a168012": -0.0015,
        "72cb039fa0cbd156680a2dacafd1185f": -0.0033,
        "60345e9cae3999bb7a01385b4847ce4f": 0.0005,
        efa47c1c995eebe7c301de9d31e7a801: -0.0012,
        b7dc088ba838f5f4f2ec41b519f87567: -0.0051,
        c0fb84ace6a04f150b0fe7b65e10378b: -0.0021,
        "94a7891f2fa068203befab2a6779658c": -0.0018,
        "219e317e0d4d2626f38ca04ec09c94ad": 0.0066,
        "82e6b4cddb4ddbc1dca4e1dc31734c63": -0.002,
        b6a35675f8b6790fc5200a6d75f54d7e: -0.0012,
        d29d65d83cd2f569b2103b5b37c5815f: -0.0021,
        d6cffa108bc762f9b9e75f8ce1091109: -0.0012,
        a8004b3de9b58cdd6732f69beb67ecbe: -0.0016,
        "22df8c22e98b8a75474d16b30ed7642f": 0.0098,
        a5b1b4a982c57351b727b0f4d6d63736: -0.0012,
        acf701e0a7e20f4ea958d307914d2840: -0.0043,
        a92b1fbe52b27553dd4b7165e8ea5674: -0.0014,
        "66d0ed8904c5ee3956596baf5aa7a3d2": -0.002,
        bd32ae21559e7e127d0f34f5e61888b3: -0.002,
        d7aa8c3ad006f6ef2bf67d33d9427478: -0.002,
        c16585282c0c3813f24f7ff7e26f4b0a: -0.0016,
        "99f4fccd3ea1a23f6a26e57db0d7ccc9": 0.0034,
        d1a2e13d7a04c82a1417cf41925a6ba2: -0.003,
        "7c99dae4e843e8031e768413a005ea80": -0.0005,
        ac697c2c36f3114bacd06162ed6ef742: -0.0013,
        "658880694332d76f433c95b13fe1f446": -0.0017,
        ee0ce4b88478d019dbddb1a9395721b1: -0.003,
        "6844c78c44750bbc4a14ff12119da4cb": -0.0004,
        "592f49ceace04aed4fad93202db01d80": -0.0016,
        "53f2d7595579a565f6bca17ac281ed04": -0.0001,
        "99d6b89383ed6b7e8e80c64fcca0cf33": 0.0033,
        f392df17956bf89ac30e5420831b5c94: -0.0016,
        "0f570b312b676ebfce500f717f4a35fd": -0.0016,
        e398dcd60d8a5ace26c528451816fe02: -0.0015,
        "032c265dc41def20b2a183246dabfdb0": 0.0033,
        "524d29e7f3de1f12c25a4a9559f868bd": -0.0034,
        "06e9aab9820aa6955897b4f9e1cee5d1": 0.0019,
        "4363481c7872f81f7e96d1580c87ac80": 0.0035,
        e3ea2e16afcafcea80b5fd4754d9a60e: -0.0012,
        "77b0f28899b669bc7ba8733bcaac3335": 0.0036,
        d4753a3471cc6bf9961cd868b4c5536b: 0.0032,
        "222888ffc7d196a4a832cf9ba935a4ca": 0.0033,
        "3a56a581ec8ff60aba1514e106120b0b": -0.0033,
        b4a583ae4d78fab82a23ea9b8e158e10: -0.0033,
        cf988c654a7add0e3518290d9f7c42c9: -0.0012,
        db9405ab63045f793b5b4d2744857e41: -0.0017,
        e3f9ba97357b0e6fbdb1628e0aebc25e: 0.0032,
        da253b59aab9e25dd2a5b00aa8e31b61: -0.0033,
        c221ba2413849dace65c2be8a64294ee: -0.0019,
        a919db72e55af944365228da036081d0: 0.0025,
        "61c9696e981e6316fdf71f83f5a9984e": -0.002,
        a3e3471bcf0af34af1c43e2678087509: -0.002,
        f95e9fbd861c9c0780c119b6f165991d: 0.0116,
        a8c7ec5aeb196e743d3e8e768764d531: 0.005,
        "8a427f2a823951d79d4dc7ee0f4938e0": 0.0033,
        a7f711a816c95967d990d29c7c0e54df: -0.0016,
        "153aba3535ccb77dbc483bffe224bb5b": -0.0033,
        "24b32419b90fef44e959579f022795a5": 0.0066,
        "14b852975a86bd96e31449e530264888": -0.0017,
        e323e89a90564032ae4ba81428962d07: -0.0017,
        "3e719cc4944ba276bd13764547f90c63": -0.0064,
        "66a0347c6774885c1509ff8c95d5c8c9": -0.0012,
        "2fe210845544a0ee8220540c3dc35e82": -0.0012,
        "58da6c2223c7726b03873988939bc1ea": -0.0012,
        "2e21a72c81d1aa2dcef0c2c63e7cdb17": 0.0066,
        bbd6f72eff7f7a67f801bb3ea1795ef8: -0.0021,
        "1720d519a1ca3e88871b45b2dc46cdea": -0.0037,
        "5a1017486ec59af5978bc737c9f5089d": -0.0006,
        "7fb7fd1bfcb3e7920528eabd749577cd": 0.0033,
        ec2ae0d50d2e28684c1c1d523b0b286e: -0.0016,
        "66ce4cc3efa83aaf850bbf1bdb7d0cc0": -0.002,
        caa60f19a8ef6294130cde25b1d6699a: -0.0029,
        "7bbe2240f0576863d9af686b41c5269c": -0.0029,
        e11878df977c3d29a3720997558a18af: -0.0033,
        "1b757ec750d8cdad4e92685e45ac147f": 0.0033,
        c437a7bf5bf25c07347fa065e5afe313: -0.0016,
        "36665e3fb8c99a78ae21dfabcc2b7dee": -0.0012,
        "64543d6e3ac3b8ebccb8be37400be66c": -0.0017,
        d543af9937245c1ac9ddd365cb540e5e: -0.0028,
        "1eab31e335f1cdf473b8f353ad68c483": -0.0048,
        eaa31c7595db6dd930d8c14223cd57ca: -0.0029,
        e55d91c28c6015643137f30420262d7d: -0.0016,
        d31c67e68c9a6721af8fb2bd9b76f1f1: -0.0044,
        "612d51b2c1a8b16d0da837ef288f87fe": -0.0012,
        "8c3c680487acc2ece1a873699d7ce61f": 0.0033,
        b4ba5362afcdd568f01a418252a9e424: 0.0033,
        "59504cb772d2beeeb163de6b34141010": -0.0021,
        "66f694635aa41b99144421f01dafd107": -0.0016,
        "6436b1796a4fb35d6b31e82d5185feca": -0.0012,
        cf01292977ad8f0916d2dd9357144193: -0.0016,
        f365950daba33f2d440107c10bbf658d: -0.0021,
        a8b22feb7dd1673084369bccaf871ef9: -0.0052,
        "937e80059365592f66aff952cf40dc7d": 0.0033,
        af7c6c63b0ddba5c6d6126f220431f4b: -0.0006,
        "2c49f10ec3eb172730eba1d020c28493": 0.0016,
        "02ee1036bd0375a4e49acb59fcf1622c": -0.0066,
        "882bcd10345b1bb6b030f9acf752e32c": 0.0036,
        "673ad972b507c219ca1861b741b6b557": -0.0021,
        "4bf5966db9acea7685c225b7ed537b1b": -0.003,
        "98033aa9da2bdab94ad13734962d8852": 0.0033,
        "26b439452e2b8fdccea8825ea5da3535": -0.0012,
        "3e29374683e5f3a99a64b82430508802": -0.0049,
        "3f41af21b0bbf0c2e3aedc957859dbf9": -0.0012,
        "99931586ab21a6c62b0ae81cf6043798": -0.0016,
        "8f1ec72bb09894ff61dbf508ea6cd8ac": -0.0016,
        b0a682b87ff797615cf6bfc8a4f7b611: 0.0066,
        ffae12e8e3f68345117d98d6f44d8c36: 0.0033,
        "9fbce7b99c7b0a472769c59fac496298": 0.0033,
        f7262b2d46667ffde65a5d4c3206ebe3: 0.0033,
        ce1635b74ee327eed0f41f7b5e776cd4: -0.002,
        a48c6524e574549c2f401ee65d823efb: -0.0021,
        "1a3572f704b8b5a110e59d3283c1eb27": -0.0005,
        "223afb98e6906b3330c4adf692363f3d": -0.0012,
        "3b22b3677c46ec8e01f0addba8cde525": -0.0021,
        "6eef21ad08f7ff08aaed6ca51216ae83": 0.002,
        "40a2cfd90d4c7e27685e2feb2b3f5e00": 0.0033,
        a649870ce3ea103534626810fe7b6057: -0.0021,
        fcd0efb987287c5727074597daf644a2: -0.0051,
        "1dad91fc583564343c25f64b0a8d26df": 0.0055,
        "8930ce3a699232fb066426d18dccb0f6": -0.002,
        "0072bb5f15de2ce40df7da4cde34fe55": 0.0076,
        "5877608c3791bb463bb5ce723a4bab6b": -0.0008,
        e97d457df13eb052cc2b0729446ad44d: -0.0014,
        "3115204d5c55f69f74d61bd3ab3bfb40": -0.0018,
        "716af6a8ef1a02e56aae2235a516966a": 0.0033,
        bc2492c3be94425e937d83b561dfd285: 0.0033,
        "7eed1391c52006f3ff53d0d14dcd333e": 0.0036,
        "6935ff14ca607ca782dc6bc337aeeef6": 0.0033,
        f606db763627d8125e3d8fe424da81e4: -0.0019,
        a8d94a70c4c8a2ccaef064b5470d2539: 0.0033,
        "0a17dce9abdaedc959fa0119170c97c4": 0.0033,
        "86494e4771a4d28035587745aef210fa": 0.0066,
        "9f5b63aa4e719daaeb31e752b0ca1a6a": -0.0046,
        c224aac3e6c5d297b0f6f7e15a856391: -0.003,
        "615eab2c87c0628789e6468a742563bc": 0.0017,
        "257bad9417fad19e96a3e828bcd003f1": -0.005,
        "0ddc725a2a977164916e88c4a6e17c0a": -0.0029,
        "42c499b7551f82c47ea4b8289cee1a16": -0.0017,
        d779003300e4d9415c0b0cbeac3cc430: -0.002,
        "2a87727e34254106fcfd5e6f437e3b54": -0.0016,
        "5557e0a6f83ec903f5f1179a0b487baa": 0.0036,
        "63c719289ba439f1b501c16a51cdb4f3": 0.0021,
        "53b73d75d3d86dbdc6620d8520f3e27c": -0.0017,
        f4f2003581a9ed30eb4eaa9adfd0de66: -0.0033,
        e9d71351016840acadd5e920065451e4: 0.0033,
        "431936da6b6ecbed81bb8f05e74490d7": -0.0003,
        b953fb45749a2cdd621224d59427d397: -0.002,
        "04f3ea1493a699e5a0ab01f035c28f1f": -0.0029,
        "5cfdcc2dac1e607d212ca28d4bf99998": 0.0033,
        "01f5759c2ba9fc50d8abcd94059db372": -0.002,
        ffcab38981985f5a39f0b5b5ca6d6762: -0.0051,
        be12fa1a3c6164748fb837f362153ed2: -0.0013,
        d7b06dbd7794a49e37d1837aeab9dbc7: 0.0016,
        "7cf86e8cf03b20bb0023ebfba9df5be9": 0.0034,
        "3dfa947a8e9a632b47418f064e9e2c89": -0.0037,
        aed3b42a6f54a1d5814372d499915dd8: 0.0037,
        "6f9c136035ff5b69639b233a71dabebf": 0.0033,
        "74ddca19144d3960021449c17926b15e": 0.0033,
        "5df4d5ce423de6c463bee335993f7440": 0.0033,
        "63635e1ce4b15375107a46d142e80476": -0.0001,
        b49bf82750ad8c0f1433e5c44faafc14: 0.0099,
        "63c80cfcae86d508a31b5f19046c37a5": 0.0037,
        ba6c927df4c73d250718133518225500: -0.002,
        "89838585b72616e9110ba3a602834222": -0.005,
        "2142058b733cc0d37c940b7154ce57ef": 0.0033,
        c90747e8de67a4263e42e2eae3f77f7d: -0.0017,
        e7cdeb0d6f09a16d0ed6935b1c1207a1: -0.0021,
        "4d4f789356bbbd0db075e5e52479e67c": -0.0016,
        ab923fbaa6f6228e5e40e50222f05939: -0.0012,
        "4c5d238fe6f2f3e38dfdb4a2f1e2fdf0": -0.002,
        c665b623d9db48b6ddfc3f1613a48e48: 0.0033,
        "70c191898d63302e1bed7536558e2d80": -0.0018,
        "859bae643507a12de6e12b82552eaadc": 0.0033,
        be9132b7571a5a5d06b7d4703b28cf2f: -0.0012,
        a7bfff00203bd33d6782efaeca7cf809: 0.0051,
        "0bc03c9f0cc040785b838eefa917b42c": -0.0004,
        cadac7d839348e79040bfc14ba3a082e: 0.0016,
        "8c89f8b0d5391ff55921bdd4139e8f97": 0.0016,
        eadf6cf6d8e70d3e1cb58cd5eaa05fdd: -0.0012,
        "583568034dad521031423f172113c48b": 0.005,
        "4f183a904837fb811f3ddf63d655b9cb": 0.005,
        "392b17448c0ba2c8eb2f1323ad309ae1": 0.0033,
        "3577053a8e8178d075b5e37e25887c6b": -0.0012,
        d89f93ea8eaaf8cd9f1254bdfb4a50c9: -0.0018,
        b862ed76b959a21b8558e99f456091c9: -0.0017,
        "55d3a08745cfd7c9104f832f07989996": 0.0033,
        "1f2842ce87d236557e3040d804f6407f": -0.0024,
        "9396af8d340b65cf315eaf6cc4f1edeb": -0.0033,
        "613d3311e4f9df22063b9973217731b3": -0.002,
        "35e594205009eb0b9eeeef24a7b5e63e": -0.0045,
        "7101f13c31eea40d4567a77ca3b0a545": -0.002,
        "6b633729cbeff351388e77c02a3c37ad": -0.0017,
        e1a9c112c66bc6ab85fc34c7aa4af235: 0.0034,
        "3ce4a00ee5c4f0fa45f2f99677528933": 0.0016,
        aaca2d2809c87a89fe3f16013c4112bc: -0.0009,
        "44ecc61b2c3ac8767304969c24c256ae": -0.0012,
        "7093c036d022cf17122b7a407ee2100c": -0.0016,
        "778f49dfee83e2ab7506f70db0bc00ca": -0.0016,
        ca7510787f113e4fd3b47fc198f61ea4: 0.0009,
        "525a37600b3b0f7cadccbceed362031d": 0.0066,
        "15e26c2242ef3227f4e6dc843bea2e6c": -0.0038,
        "94bd1a085971bfbc3d8c22685922f204": -0.002,
        "42cca12f67e6192da4050ec2c64d8b52": -0.0029,
        "44e14af2caa664294c65d3cb37227acf": -0.0012,
        adc6b93104ee290e75701fba8e0529e2: -0.0017,
        d9896278d850b0c3e0e8816470b414cf: 0.0033,
        f12280576e6f9923556bc3bf951393c5: -0.0012,
        "53c09c2496323f211a52d72e135a0c13": -0.0017,
        "266f47b4d0839d204d1cd9b07c4613cc": 0.0027,
        b6948bc7abbf0f6f0547cd6549380a22: -0.002,
        "155069fa276d74dcaa8af9643ba03b7b": -0.0033,
        "902a12931321c352103e2331d66b8f3d": 0.0033,
        cf149048e610e3f611147828a0de6599: -0.0033,
        "166af74faec0f896025597f77f58d970": -0.0016,
        "0da1106f3251a1a0e0865493c370fb1d": 0.0035,
        "5dc203bf1946a77b402f24bd2e453d12": -0.002,
        c7159623a40c0824075040a0ff8775c4: -0.0017,
        "2a5a6a07f3a4902276f5fdd36a80f8e0": 0.0033,
        "13d510beb3f8dd39ce980c4c57ddd4f0": 0.0016,
        c18c385772370c8d0e8c45f92bc0ee41: -0.002,
        d743af545eb45dedc16a446a5fb33d1f: -0.0021,
        "22d839946072103d7d027f5d45956f67": -0.0012,
        "51279c9e948d3935c5a5374eb8701235": 0.0081,
        "85754cbeda8e8431c32bd39f8c921734": 0.0073,
        "5daa03f1eb0a8fc6bd44f04b6d2b8f26": -0.0016,
        cd3c3a20964a2d56fbf479a13bd41074: -0.0021,
        "738d5e4b9305baf028e7d93c517b761c": -0.0008,
        "7d8865f3798e3f5b8cbb1c8535b26a83": 0.0027,
        a725d2e4a872fc6b2be8b5f4c1d25f1c: -0.0051,
        ab1b7c5788c5cbe90413b2c5f6ea0884: 0.0033,
        b4ac8d7bd4bedc2176d130064f47369b: -0.0012,
        "3abef08528a663e6080f6fe592168607": -0.0016,
        "09db6910022c782e84bcccfb221d3677": 0.0033,
        a26ffdebae1aa5306c6435e6fac63581: 0.0063,
        "8e1fc05f9e09b1c87cc604dbe56a8f34": 0.0033,
        "5645082bbd5dc22ac0f84f5551c404f7": -0.0012,
        ace5c8ca2428dbef317de6e6013128c3: -0.003,
        "8bfd0cf0ce618f8cf130ae9402ab7d14": 0.0034,
        "00efb9f97ee17468b2c8b7bea204bd28": 0.0016,
        "02105debda59362f47ef6283bb9235d2": 0.0016,
        "573063379aafb0fed92ac0df1661765e": 0.0017,
        "20c4387199e2a550c37871a2bc9b9a65": -0.0012,
        fa1d98bcdb7442ab12330ce98608228c: 0.0032,
        "709f801fde0275203805542eb189de6d": -0.0019,
        e0eea899de9e3a6f79050fade597c1ac: 0.0033,
        "4af41ab741361cdc0cfc55e9dec9151a": -0.0017,
        cbd0129cf062f5a587107c8a1900f97a: 0.0016,
        c98be7721843df66549c60ecf8d43b00: -0.0007,
        "14b051b74b14467b3788878139ef5e46": -0.0017,
        "97f95a56c11c4be7c9b3130ed0af9d48": 0.0016,
        a1a0cdb55f521883f689f77901032b45: 0.0033,
        e84e59c1b1a6cb61956b8e60bcc1470c: 0.0065,
        "6090b4ed55594a248ba494321ded3bbd": -0.0005,
        "67ada9d7eed92b4efd61719cd03739e8": -0.0016,
        d19a0f803069ffb1e73755d41cceafcd: -0.002,
        fab7ce1905bf2ea433c150d84e973aea: -0.0033,
        "8d3632f1dcca379a743c0667968085db": -0.0029,
        efda5418d1137278a8887866e1017deb: 0.0033,
        ffb6c6a2ddc0bf890594ad32b7025b4b: -0.0033,
        fd44c78aa96641123e8bbbd4169f597e: 0.0033,
        "0611e36b3738d092075012abac686f51": -0.0031,
        "19f5736a20d666e262e092f03a146b56": -0.0012,
        "65187607a4787f16012a62f21483bf48": -0.0026,
        f502ec0b465c310947beda177282e0ef: 0.0034,
        "6247da701a3e63561bacb77efdb52414": -0.0015,
        "35d7c76c3969b58e7c3ddccad4b19df5": 0.0017,
        "90c084ba683dbf3bfae4c7a0d0b1300c": -0.0012,
        "9d6dd26f252b5c27d38ca43e51266df6": -0.0032,
        "93778943bba67e89ed9e4b01708a2e00": 0.0016,
        "66cd921a8c8bc01c593698a125563f33": -0.0012,
        "03cd42b1c5f339e8e1ade3b51dcf1053": 0.0028,
        "0efa13b9014e7c005f6f96cff50af731": -0.0018,
        "335750587326eb4b9a0d8df9a3a1304d": -0.0012,
        b67d545aedbedc272517cf68aef48454: 0.0065,
        "914d0ef14702fbaf72b2cabb7cc43769": -0.0016,
        "0893ac903ae956400ebb4912507496a9": -0.0003,
        e2bfb3e75cc2ea40a238d1c8ba67093c: 0.0036,
        "3304328227ee04a32b793c3eeda3b042": -0.0033,
        "75e923c1f30c287310fb0543d9f88a86": -0.0049,
        "7143d9f4e5cc6cbd2e66e1831bee186b": -0.002,
        "0bcf9774d52d7410a91ffa9fe2646dde": 0.0021,
        bef61864b88a584ed571253d8890ebe5: 0.0037,
        "5eaccae44f4a985fd5ac979f103f2bad": -0.002,
        "98c0b1b56363326e963bdac31464b72d": -0.002,
        f2470cd00db3d9bbb122200a5d98c043: -0.0047,
        "3f965775906262be5c4632043022fd96": 0.0094,
        d19dcf5af93227c1889458bbd64e8e9d: 0.0034,
        d8b78d7ff9c9647550324e0272a8b543: -0.0012,
        "0321a7b59d938def419e8a974bdb53da": 0.0016,
        efc0e8475d15ad53da956f8d2592b37d: 0.0031,
        e27b7fd2ecaaafed5ca1e0a8f961b6fe: -0.0066,
        ca0944bf13ea9e519a046c71828db8ca: 0.0033,
        "26ebac3cd8cfdc8ecee8ddc75d9df518": 0.0016,
        "726195a1b364734b5f9ad48b0c43923e": -0.0016,
        dc174cff7b1597c770529a7e9cdf2db3: -0.0033,
        "697c377033e77b95336c5cb624e360b6": -0.0029,
        "794ed05dc7ad777634eb61879f232bb9": -0.0004,
        "693ee0c2c3bf41ca66671cc7f2894161": -0.0011,
        "3dd71cc1a77d84f01c93218a5652e97b": -0.0016,
        "430cf73c069386e21ac88481cd78c7c1": -0.0016,
        f81705315af721c1d493b97a66f82007: 0.0048,
        "107b741f839b1b9573aebc16dcf2f5d5": 0.0066,
        "8e2987e5a8ddfca614844fe7b55e1d3d": 0.0099,
        "6a594127f5dfcaf168679fcb8491eb9c": -0.0012,
        c3236aecc30af56c68d4726af7a15aa0: -0.0016,
        "4bc17f9f8fe437e1f1fac47b724d579c": -0.002,
        "2cd95100c82c922f99aaf5f82a7516f2": -0.0033,
        "461a2f9af16d0cc6143748261accc8f8": -0.0006,
        "328804b47d4c9188e568500925ce56cf": -0.005,
        "4aef686aefd9fb4c3c29dd27a9feeb8c": 0.0033,
        ee4b1871899a272601adf1e474f3de11: -0.0005,
        dbfdf9cb2ebd4cf0191340bde4529d4b: -0.0012,
        "1fc75f0aced982e4f9b5ef6a72cf2b97": -0.0029,
        "85af551c636599c994e2101e301cbd36": -0.0048,
        e8f261b2f2e8c8fb4b7372903b151897: -0.0014,
        "04e1c3dea5a9743dbfefbbe710d8d609": -0.002,
        "3cfb306bfd533648af7341a04acf0dc1": -0.0012,
        "9c0869a43c599e4b9d24a35fc30c883f": 0.0033,
        "979af07e8e98ee3563ba3565841cf408": -0.0029,
        "298a5b0370bcd3b43354138bf406886a": 0.0032,
        "1780417ab56408685105d7594a7d51c5": 0.0033,
        "802a8338991981bd08c6e0b4f3cc5570": -0.002,
        dee6facb42776aaa6b883264b4d74f26: -0.0012,
        "5b888429b468ee7cd5973278863772dc": -0.0033,
        "4023459a90cc0572fcc2f00d710ad8c4": 0.0048,
        "8b9dcfe1c97977112ff09df780077a49": 0.0066,
        "311502de95ee9386759a6ad7cb956bba": 0.0032,
        "86fff57386ded2772ea688737628f796": -0.0012,
        d40ba63a689a9c432a96240d96357d3f: 0.0041,
        de2a5e995037e0fa2c9ba325d6ea4acf: -0.005,
        c8513cd2501f65721d456c6c1957b8f0: -0.0016,
        "2c8b5d6e44131c99e672ba4de0c59271": -0.0029,
        e479384e85296d8d8540b8a7504104d3: 0.0049,
        "798391375d273a52b15b3c728af15439": -0.0012,
        "4ba6b35a134963e875a4ab5334d8a6de": 0.0084,
        cdce272879caf5e4cbb1145134175928: 0.0033,
        "16d1ecce2aad410b3eef9b2d9bf15139": -0.0012,
        eb28336464dc4a985cbc00c94e9053f7: -0.0029,
        faf2e0749d7ca2e6cc25eebadbe08db1: -0.0034,
        "4ee287d0758375445714b28452f88a59": -0.002,
        ac1b771229a181f98a9c95066a502963: -0.0033,
        "23bd5c31f08b3144c1c3569544fc6dd1": 0.0069,
        cbf0a1289e021c056032025248b99ca7: 0.0034,
        "76d414cb96e01d269d35a8b9f82a2982": -0.0017,
        b11d79d3d778c79c6bb9faafd0baf4f6: -0.0049,
        "1cc8d5e1bb8222fad569579b4a502e47": -0.0014,
        "4d7c49758eb2315d3ae6bf45b1942a8e": 0.0066,
        a9bccdae956ecdce860b7a76176089ab: -0.003,
        "3334da97c16e401d5458c2f191df3a65": -0.0009,
        "34381b71c6bfbe773dc91e9c7dec6a74": 0.0033,
        "921a7396860a158bf569ea2b44f992cb": -0.0013,
        c9d722b04c10d1b00cbb6a431a7411dd: -0.0015,
        "61732916688e77b56e8d0883a3a54951": -0.0012,
        f3defe9118001f2ef254f05c3c1b338a: 0.0016,
        cc9d886f25cc1f612267144f93b6ab99: -0.0016,
        "2ae9d376b941fa634182ec5b878c7fc5": 0.0033,
        "0616f6df065077bf81cebbbe04de3a44": 0.0013,
        "8d595a60cc95e6e9f660b0a0d074d8ff": 0.0033,
        d8a85cd65fb6142a7016239b638f8b88: -0.002,
        b232ef6236360193730825678ad332a7: -0.0016,
        "9cd0587020686999338f44d6e217b629": -0.0012,
        "1a7a051cefc976c925f82e5a2d6f5196": -0.002,
        "593af62d66294a4f3871bf8475a3c9f9": 0.0066,
        cb8cab2fde22b28c4529125d740cf236: 0.0034,
        a25d3c81f6de8d7a25e1a7cd50530c79: -0.0005,
        "0ac0b5c4a3572c2f0bb7b404f2c33a86": -0.0029,
        e481bf83f0ac4279e7450c72596d1cf4: 0.0016,
        ec17ba4dc3e96a26ccb2c3f3d1191e60: -0.0026,
        "6b9911e659a34cae75aafb9631cb15ef": -0.0034,
        cbd52cc79a2b99551cd1f5e130994cdf: -0.0049,
        a7885b1184160aff386d2c93b57efb9c: 0.0036,
        "5c5bfe844c48c34e846899808f98ca80": 0.0017,
        "22c19fe6c42107bb7b66bc08bbbab35b": -0.0017,
        ac74d68997d65cf1964e2c0e8ebc2e51: 0.0031,
        e435a3b577d9edd770200ef4e5657de6: -0.0021,
        "2f7b1fbeb949825de374c26233f31863": -0.0031,
        "7b2d80b92c0d6499f2367b7335cbc093": -0.0018,
        a9c2278065797083117acea36610d5c9: -0.002,
        "5929dc46cbd50a3d5f0e00742a2d1e34": 0.0066,
        e7ac6e63ef7735a178df15eca910ed6a: 0.0033,
        a34f941ebf87b8cd7d185e0f7b724ad2: 0.0033,
        "5700c9a6d0d092bfbb764d63c46f0902": 0.0102,
        "6d0dc2fe2752803b6cb5f617ed2e4495": -0.0017,
        "568ce8bc712a36d1015c45a7eedde394": 0.0016,
        b25f951d66f5f250402c9aadc052458a: -0.0017,
        "412171513af976f9d9d65871fa2db9e1": -0.0033,
        "561dca8c7bb657b3aa1cf4a6d3c3aa34": 0.0033,
        f05886fe787fac12d4c8b6d356dc0f87: -0.0028,
        "8c6a080317eda0bfd8c74c5146dbf4c7": 0.0062,
        "57bb970b26c7a9a6b2e48ebea57d779c": -0.002,
        a34379cde328e05e99e2ae63a93e468c: -0.0016,
        ae8f96740139e41e24fe9d8012803868: 0.0033,
        "24c8107adfa2739dbf27ce23f77ea2d6": -0.0016,
        "84cc37a41c79f4b2ed9d73f2679b9307": -0.0035,
        bb84206b52c9fa6151b6883f9a1f032b: -0.0048,
        "8b56df27aca4d53f8f95e0ee4fb8c6e5": -0.0016,
        bc553886ae7cb59211f530f3e8206df8: -0.0033,
        "48c9629baca31e7294b02d5b8b2a693a": 0.01,
        "97875a948d3c098407e00edfb0fd371b": -0.0012,
        ff8f0bc3176907b39c88a1a73d282e4c: -0.0033,
        "8edeece7942fffdda618535121dbd617": -0.0016,
        a2a1207573477407848148d4a67fc1da: -0.0012,
        "511656f8cc51171717695466bb350179": -0.0033,
        "8c03a43c88cf0e59df9f237ef3ab0377": -0.0012,
        c6db48ea6874c8bb231a2e50168effaa: -0.0017,
        "904f394bad20cf1bec53f974aa5e1e6a": 0.0016,
        "22cb3673887c02a544885f183abc5402": -0.0012,
        f4e27715f21b487a9b519437d62d5661: -0.0012,
        "83406e95193eed53a005e4f223431033": -0.0021,
        cd67cd23eca709dfd3442c7754708b63: -0.0073,
        f5594b45cc57da2e4e15adf01f6ed5dd: 0.0034,
        "009931a03006b1eca401881ee58a774e": -0.002,
        "1b36c6d2588e12962b39d7a93794f36a": 0.005,
        "7dacf270dc8042dbdf004b8ed077e6b0": 0.0033,
        fd45cd4b47d2637e74793444af03ad1a: -0.005,
        b8c82c83a39a897762b6bd5448400c4f: 0.0062,
        "51b9c1fcff60ff6d2adb9a61657027b7": 0.0064,
        "6e3343f89e71d6d8af52a874760ae1d3": -0.0016,
        df2ab3c3a0d3183192c1907210764e5b: -0.0033,
        "4bffaf645cf7ebf9b2f8476ac68777c5": -0.0045,
        "774698e122fe7441fc4606388252f999": 0.0034,
        "9d077274bd2fbeeb7a6ef5867c30fb2c": -0.0033,
        c4443efd30526dd0c89a5cb04cb63ac9: -0.0038,
        "628aa3c79396c107232eaa0cd6757ebd": -0.002,
        "7abb9f405c4ec4907f48a52729f807f5": -0.0016,
        bc60f3560dbbd70e76111e3cd542be4f: 0.0099,
        "67e84edc314b7807cefb4c67701857aa": 0.0033,
        "49f25e8b36d765b1dafa8ee1abf96317": -0.0017,
        "4c2771d32052e5f876f9487d1d3343aa": 0.0034,
        eabaa2c092045d904b97bb6923d10710: -0.0054,
        "02b57c4f0e27812710dec63a3004e3fe": -0.0018,
        "0f6f020e1a1a2274767b23cbac85a148": -0.0021,
        "83e00048ee3ce56bfb9328025b73ae9a": -0.0013,
        "36196b99a6814999b49eb88577465c52": -0.0005,
        f77b0809120c176c33d2e95a6adb01e1: -0.0017,
        e9a4f8baf2958f6191f21a7c986273c4: -0.0027,
        a103b4208e319ab83a965620ed5a8286: 0.0048,
        d9f0e7099d90e04b53cbca8d30016685: -0.0033,
        "6060349625555354de1bcfc3ea91092e": 0.0033,
        "578d5d643a32441550a0141d93569164": -0.0051,
        "54c60f32fa8a9e916bd15ac134cd14b4": -0.0017,
        ac8c81c21f527ff98a51303bdd0101c6: -0.0054,
        a694ab0f9247be9c0c2d905bb928a3cf: 0.0016,
        b5e3773e3250b95d81b71e644930cea8: 0.0035,
        "2bf9099a451722fa3746050c6c59a488": -0.0041,
        "5b1b0ce780f6dd663e0d64a9ae6da64f": 0.0033,
        "0c1423a9e7310f9355c6541fd87871f0": -0.0016,
        "538a9072ab806d91b957aa0217c09257": -0.0012,
        "6fd8589d4d1f4e2317db467accd869fe": -0.0012,
        "274c44e452ceada7cbe1516d530eaa38": 0.0016,
        "8ec05158cc93e2c25a112d69fdecc9a4": 0.0033,
        ff9cb86998273b7dacc1ae4344c2ae7c: 0.0018,
        "9b092744d2cd23bc159e6b8d2023cd2a": -0.0033,
        "0e86d0d895d145347f8f4039a99d3feb": 0.0049,
        "43caa4a43c77de9be7009c0c5450dde2": -0.0007,
        "7ed6b2d858eb958c7d5a0e2760ed2b4f": 0.0066,
        e846b4dfa87ee732fb5d43f35bc8f16e: 0.0033,
        c6ebee7917b7f577098d79fc88133a5b: 0.0033,
        ea8e39a00fbfde90c5f30f7a966eae35: -0.0017,
        b82f9791a8796936f79d39efc5584952: 0.0031,
        "856bf814ff1160f9a4821cb5e0b2fb95": 0.0036,
        e00bdf64a6fb68876dda4f153c7bf315: 0.0033,
        "75b7ecf2348c65fb53466e59597cb732": -0.0049,
        "4c5d8121f9b5b2afccd7425948eea666": 0.0034,
        be6a8e00ae654d399ecde7324308e4a7: 0.0016,
        "621f2469c196501bc5ab43d7b615150c": 0.0066,
        "6d7d58e09019d06555be0b075f9cce75": 0.005,
        "843b49a2de429b92aef6aaf71ad873bb": 0.0066,
        b3409abb47aa1555973929f110e24a5a: 0.0102,
        f52a21b84c4adddbb9b9b621c44b300d: -0.0017,
        "5e04329c2446915accd3f28d0b300270": -0.0012,
        ee6e41a8a90493dc14c16bfeb77262e7: -0.0018,
        d06f69d6f96683725c6b5bfa05eb8bdd: 0.0087,
        b89cd9bcad08b79fd0eae1a506b9d8b3: -0.0016,
        e40f42641dfaeaaf2fc9630ed36a6aa4: -0.0016,
        "57ad6ce812a1313c8b698841b31e3c4a": -0.0032,
        e187a63e28ef7afe9e1d527284d1b3d9: -0.0041,
        "454c442478d7c3ff0d1f5c3a9cf98cdf": 0.0066,
        "1f1fa3f5ab282234948a2f82c72640c9": -0.0021,
        edf23383162ae7195778dc0ae6d5575c: 0.0092,
        c356014da2f888aa2bb7e71409859b6b: 0.0066,
        "52dd96a6cfff1a8fe24ecb073f9a5c8d": -0.002,
        dbe181af1c8fd9ddb26404f739351836: 0.0033,
        "49f5b4cc37066f9a7cfe44e44f276ac7": 0.005,
        "24b3d2705b2427f1bf0320f8337076ef": -0.0019,
        "780ac1c8aff290b02a1975e59cd51dda": -0.002,
        aebc4edf672b6993f469be71c938dd00: -0.0002,
        "6521a1e5037584c868d4b1b6bd0b0281": -0.005,
        "0b9887f2e62680cb05562452b9b2cbe3": -0.0033,
        "81c9ce0c7bca1a2f07162e430be2b652": -0.0019,
        "5f9e525d34c2f3530539948d5679338b": -0.0016,
        "1984ebc6139edea5a4c5bfd7fbe26378": -0.0033,
        "3b58f056200e4994f135fb4b7b86451f": -0.0016,
        "041fe2e0e075a0da56478ce9d210d3a4": -0.0006,
        abacfae3ae0d9573297d314e074a7f91: 0.0045,
        "99be4f8484485c48488baf11da55992d": -0.0006,
        "39c0ae528bab8f3eb87585dfc3803e7c": -0.0016,
        "1b27011fd0cf955f0f8b6892c34b9dd6": 0.0033,
        "24f5db0f9b6c5609401a16be3aeb2f0a": -0.0038,
        f0cdc01ef4311582540472ac93600f06: -0.0019,
        "91bed22de0bc1cd58c3424cb32685441": 0.0037,
        f8cd890684a0b6dd21988f2561b7205e: -0.0016,
        "4942994ffac2ba315e0b4a3087ee7d74": 0.0016,
        "73e6a15ab685d585b15ec117a9ff85ce": -0.0017,
        "552aa10792d50998a9cbff01c39f0ecf": -0.0012,
        "2b0ba91e5ecbbdb93bed852940eae200": -0.0015,
        "3278aa2945d1b0c992d291e0b4d83dfa": -0.002,
        "9e2eb1d19c772385b11252dc5bee5000": 0.0016,
        "8f0f04f07b95c83a5d41b757e70c8c4b": -0.0016,
        "6d6dd09bfbdc81c6935699966e38f548": -0.0016,
        c7fba965f4ec4eb4d022cc76884290e3: -0.0019,
        b26a2d3d9975e365266fbc103932f001: 0.0062,
        c43587c7a2f8d82f866a781d8b731a9f: -0.0014,
        "89e11235b45939afe0387e7046e80d96": -0.0033,
        a7f51c6230ced6b04ebede4638575c2c: -0.0032,
        f628178963050975fc46dee7b6298105: -0.0012,
        "3a03a53e608821c144020b947ec8cca4": -0.0051,
        "4ff1853b32095ebec496e0c637dbb6ef": -0.003,
        be00e37b4334b6e02f22e3d09d8ccc0a: -0.0013,
        "78695a8c705d6355c30dd3e4f98dfe1d": -0.0016,
        b947a750b6a97e64567c7eef96f03b82: 0.0033,
        "623508065dbe6dbec05534d3933fde1d": -0.0021,
        d78f5b6a4405822fcbc33895b717875e: -0.0012,
        "38a2d1cdeff40c65fb9ac392dcab82ad": 0.0028,
        "621a9503f758629469f9377f4efde14c": -0.0016,
        "181fc65fb2325c1a4f97201c2ec8e0ee": -0.0033,
        aa3c773e0e02baef54ac4b911b7392b6: -0.0051,
        "893e9b96436202c3fd42f925a190c212": -0.0033,
        bca5d280f4cc8aa98ff7fd977d2ff7b8: -0.0012,
        "30a6a89d5e6c9c8a76cac9f9b425112f": -0.0025,
        "5ca66759dcca70bee14c3865657c6566": -0.0012,
        "9f87a54d22194fdde97fa64141d34b9c": -0.0012,
        "7fec29c9181b459679c5508a105ae218": 0.0021,
        fc672cad5943bcaa1d731962b71d623d: -0.0012,
        f41cfd9e4c02ac475e606f9b5eac2f34: -0.0041,
        "2d8ea31fcb0a0d686e2abdb8cdf5b717": -0.0012,
        bb888125c0a1d94e23a2232e1d37a829: -0.0049,
        "26ba231e5fe4252ff499e8ac63958303": 0.003,
        "5851cc76429db2cbf3e0f182772b6da5": -0.0005,
        e5a7b4182a209a52def270817452a2fb: -0.0033,
        "4b0dde839136f6321c4a03d814afd71a": 0.0033,
        "9f045cc059837d08f2b3c29f5ea65b03": 0.0029,
        "1d33260b223aa1aa35a08710f387f964": 0.0054,
        "532e02117cb8a13dacec53edaddd8fbe": 0.005,
        d9ac1addfcf17dcea5d5c67546abffa4: 0.0032,
        c383df0e2e57dddbd41669affe2938ce: -0.0052,
        "7273c4e0f28cb65033a7f302f185c62f": -0.0029,
        "8e047ce278ca5d0bc1eadc0becde1bad": 0.0102,
        "5de02365cafbd99ebb1f60ba9ebaaa20": -0.0028,
        "73e8a0f2e918ec1581ea6e5e89a92780": -0.0008,
        "6cd83834d61d71889fff00f5ad3a3222": -0.0021,
        d4cc4cb59103504a79fc6043887578f2: 0.0033,
        "50edb94b05f5f8617d5be4b305de8e45": 0.0067,
        b55e967d340a400c45a6ecdced677bd8: -0.0012,
        "2dff8d4d3e2206df807c76d11eeffef3": 0.0033,
        "1ba78b64aa96df4002bd02b995696d1f": 0.0086,
        a304025e2c0d2111020af42787d77801: -0.002,
        "87088aa87f54ae44be0ec47462cbfaa2": -0.0004,
        b567985fa965ceb7e22a2cd4cf4bf621: -0.001,
        "31272812b26b9179bec08034b3cc009c": -0.0016,
        "11c55fc082241caf02c2691e652ebf46": -0.0003,
        fe7f7ac9af72a6e28a05c68f1936a644: -0.0012,
        "39c50b23365b865537766bd43ae17114": -0.0046,
        "68162af9495aa63810c7df8c63d49c55": 0.003,
        "8ece5c00fb70ff5b53388740bf734ae5": 0.0033,
        "701e570692220a5398a763028e029de2": 0.0093,
        "9ddb838e6d885e1ea9d3b5ad639f4a01": 0.0033,
        "4bf5b47076b385fedeb5b4772c2005a4": -0.0003,
        "5f7a731dcb64ecad83b7b235a0ad0602": 0.0017,
        "8724c96b587b1d86211350de08b0e962": -0.0017,
        "4db183e0cae672717b6a13969317f499": -0.0033,
        "71155a4e3d966f68ca8ef8d0806fbb98": 0.0034,
        "6b40537fa42a589a9e2b232dd3059f89": 0.0033,
        "2c8d089224ae59530024fa02ec62c38f": -0.0021,
        d5e06d796f4e149831c24f459e769730: -0.0033,
        e2c7f7d3817f5d0ab2d14729c976607b: 0.0018,
        "5ac5cf5676cb41f8da45fe596b692a85": 0.0016,
        "834df74eca1f287e4621a23403a3c854": 0.0033,
        cb0d69a8dd4b8098cc7664bc9dde364d: -0.0033,
        "2048267b0cfd4584d654877b5d6136dc": 0.0049,
        "6a5b84c5779a1e5b8de49847879f7045": -0.0018,
        "7aa7b05da74e64f3e6aebb85d065cbc9": 0.0029,
        b8e0ddeb84fe745d7752107771646688: -0.0004,
        d6863df6cdc31a37ed23a54609fd913a: 0.003,
        "8adfea466d0c13ee34780d054b19fe6c": -0.002,
        "94ec7fbac7af783a0a3f0c50bab93746": -0.0051,
        "0070d982df9a60708e80d1bc8a97e938": 0.0033,
        e8859458bafea389b8af1cb326f56edb: -0.0052,
        af557456d16ec7db08b921e863a52729: 0.0039,
        "68fb230150f6c02b9ae283eb3f72b000": -0.0016,
        e06d87d444d320d9db5f7728a830f1c9: -0.0012,
        d6560ce0699be459a9c4c154d44cbb39: -0.0017,
        "5d98ae63afe32a107b59b1a98538d472": 0.0033,
        e98a87d7e88aded5f6db07f83beb184a: 0.0016,
        d97e3e73fb2bab741611cc650892a9c2: 0.0033,
        f124f7980a0ccb423f4ed5d83e8138d9: -0.0033,
        bc653d2de8ef1585f7d9109e03c2d8a9: -0.0044,
        ef27e1a44c9628398d4cf9c8eee371ed: 0.0028,
        cc5c345494767267f3fe1171c43c9584: -0.0029,
        f826e217a69b67b678c84485c0447962: 0.0059,
        "4b540b9233b1425858293518a977fe0c": 0.0035,
        "9b456c369d859fcea865b8f0a5c5fc13": -0.001,
        a6031fa79eeb9a593a8d88930dc3bacf: -0.002,
        e6d8ffca07f18b447e786378a34f45cc: -0.0012,
        "6993604cf4549d5775b43cb787951100": 0.0033,
        a9542506e5448993f627a6cf119776f8: -0.0016,
        "9ef90b1ea8c08e2622dd0e247edbe6fb": -0.0016,
        "4336050ca1e942a2ffb37a240d3c29ec": 0.0016,
        ba39c59471de86ad2141f706f35e6423: 0.0033,
        b972138108e48f17bbbe63a5167de880: -0.002,
        daf8fe077f15280617857641207d3f9c: 0.0016,
        "40fccf97f9a8ddfabe6ff96fa078c3a4": 0.0016,
        "17d460b24d8f649cb915fb617d94e237": -0.0046,
        "9e33b5225f83307638e41e49496ffcbd": -0.0017,
        ad155cca9059025747a03f278e8d5e68: -0.0021,
        "49a207cd9b43f81dda696fb6157c7fc4": 0.0033,
        fb9a27911d166f1011b1141e7288da7f: -0.0021,
        "41a89c69bec01bf63058aaf645de13f5": -0.002,
        "134c231e4b2ddf6454e0ead67fd74a71": -0.0004,
        "1bd9a0bb068a08d33d4bf609b4ed7fdd": -0.003,
        "37c931d4d7e3faef9cdfcc1b66962383": 0.0034,
        b9eaed9220cd519fe885bf6b822b0ad5: -0.002,
        bc105abb3ea11190aeab7672ccd4143f: -0.0007,
        "6b1d3fa31d3190754b62b6edf4b17f26": -0.0029,
        "18c57ba43038f700ac4923faa49812d7": 0.0034,
        d10bfa45a219bbd418cd567234b09a19: -0.0031,
        b5d2e936bcec666cdeb81d569db03c2d: -0.0033,
        bba14061500319f6074ba3adaf071ca3: -0.002,
        ae545794271ef76b1ec90b46a83ab88d: 0.0067,
        adb720e5f8a44a7d5abd95c5efa8b663: 0.0035,
        "0605df39d52ed7d15e4111cdd9d7cf0b": 0.0033,
      },
    },
    aum: 22945813.664905243,
    strategyId: "7eb942cfde1052167cb3cc5fb6b9a570",
  },
  {
    date: 1720540121245,
    name: "AIFactorGT5",
    owner: {
      type: 3,
      value: "marshall@9823capital.com",
    },
    permissions: {
      default: 1,
    },
    signals: {
      date: 1728310388098,
      strategy: "c79a684b68379f3d07d291dc500f400d",
      user: "marshall@9823capital.com",
      weights_keys: {
        eaefcfab18b522a3ffd64a8c8b869dbb: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ttd",
        },
        e1dd8c42f34b62cf5b8bce6c66c122f0: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "imkta",
        },
        "7f08832a59c22a50db6366fb692ff5b3": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ghc",
        },
        "4bc17f9f8fe437e1f1fac47b724d579c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "slno",
        },
        "77b4c0cc412a915f647a8e70173f5648": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tdoc",
        },
        d313a7e438129f2555e52d5c3d54d6f0: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "qcom",
        },
        "59e45b3764ea8e159e146057fc14643e": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ardx",
        },
        "4aef686aefd9fb4c3c29dd27a9feeb8c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "chef",
        },
        dbfdf9cb2ebd4cf0191340bde4529d4b: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dmrc",
        },
        "85af551c636599c994e2101e301cbd36": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vyx",
        },
        eabed427f6cd5e134a94b439a4029592: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gmre",
        },
        ef62d63378b5769cf6311e3ced02a7e9: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "frph",
        },
        "04e1c3dea5a9743dbfefbbe710d8d609": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "navi",
        },
        c60f803b2c75f803f84fd84547baa5cd: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "xper",
        },
        f60efea748206dcb190d94fe40ec6713: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tgls",
        },
        "9c0869a43c599e4b9d24a35fc30c883f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "qrvo",
        },
        f23f0ca4db72d2396a143a3715bae535: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cubi",
        },
        "1780417ab56408685105d7594a7d51c5": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "osk",
        },
        "802a8338991981bd08c6e0b4f3cc5570": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hele",
        },
        d8d1a8ce1f7e5f56e0d5f12707620fa7: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "neu",
        },
        "239701df36f9776af7730b41bca6807c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pack",
        },
        fce9583334f4798a38545aa8dfd38780: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vrns",
        },
        "1ee677c199730b92adb59687a4a3d414": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "beam",
        },
        d233094ee33894d30e6adb39a4a0c8b4: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "jblu",
        },
        "798391375d273a52b15b3c728af15439": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dnli",
        },
        "57e315ecdc80ae62a4b434552b0629ac": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lrcx",
        },
        e663a76612e4e434c81ea87d4e0afd75: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "car",
        },
        "85321998b82dadcdf711ded3e5f32b20": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mgni",
        },
        bbf7e022826ad0143b0b9d76bf3718cd: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "axsm",
        },
        "08b31ea26c5a8f34d61130ffb357de23": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "flr",
        },
        "4ee287d0758375445714b28452f88a59": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hrow",
        },
        ac1b771229a181f98a9c95066a502963: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "kref",
        },
        c7fcd95f2fbf9751f6f36347b7fe0962: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rh",
        },
        cbf0a1289e021c056032025248b99ca7: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "kbr",
        },
        "659cb3cf45568dd4ece8d980ae1274a0": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wmk",
        },
        "4d7c49758eb2315d3ae6bf45b1942a8e": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "spsc",
        },
        "34381b71c6bfbe773dc91e9c7dec6a74": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "a",
        },
        c0fb84ace6a04f150b0fe7b65e10378b: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vktx",
        },
        "219e317e0d4d2626f38ca04ec09c94ad": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "thrm",
        },
        "82e6b4cddb4ddbc1dca4e1dc31734c63": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mlab",
        },
        c9d722b04c10d1b00cbb6a431a7411dd: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gh",
        },
        d29d65d83cd2f569b2103b5b37c5815f: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ocul",
        },
        "58963e1391a67722b042ca752682d3b3": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "leu",
        },
        "61732916688e77b56e8d0883a3a54951": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fsly",
        },
        "1d4de01bb7c0d523030b158623435c3d": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "play",
        },
        "8d595a60cc95e6e9f660b0a0d074d8ff": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "azek",
        },
        "66d0ed8904c5ee3956596baf5aa7a3d2": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "plse",
        },
        d8a85cd65fb6142a7016239b638f8b88: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "powl",
        },
        d7aa8c3ad006f6ef2bf67d33d9427478: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dx",
        },
        "9cd0587020686999338f44d6e217b629": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hov",
        },
        "1a7a051cefc976c925f82e5a2d6f5196": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "apld",
        },
        "31e8a691bc5882a2f844f31da9f4d607": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "saft",
        },
        cb8cab2fde22b28c4529125d740cf236: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hasi",
        },
        "83f251c32a379136b6db2ba0c15d3270": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "brsp",
        },
        ee0ce4b88478d019dbddb1a9395721b1: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "unit",
        },
        "4c8f85974f52a59a85ed546787e7562a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "amr",
        },
        daa5bdd9080f23b8056815eeed87ba05: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nymt",
        },
        e2327fb5323507963f1ae34da374b2b1: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bxp",
        },
        "99d6b89383ed6b7e8e80c64fcca0cf33": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "meta",
        },
        e29bb8ffb46548fbb0fa82c8794decb8: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "arlo",
        },
        "5c5bfe844c48c34e846899808f98ca80": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "j",
        },
        e435a3b577d9edd770200ef4e5657de6: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "imvt",
        },
        e7ac6e63ef7735a178df15eca910ed6a: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "usfd",
        },
        a34f941ebf87b8cd7d185e0f7b724ad2: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wdc",
        },
        c68a46334760ac43d75edefde8778499: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sdgr",
        },
        cd321d2a90aff1da3d1e6bae0883d7d8: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lila.k",
        },
        "412171513af976f9d9d65871fa2db9e1": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "aplt",
        },
        "561dca8c7bb657b3aa1cf4a6d3c3aa34": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "leco",
        },
        f05886fe787fac12d4c8b6d356dc0f87: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gnl",
        },
        "0ef0e9eac8c136f003037f7b7e49a655": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vsat",
        },
        ae8f96740139e41e24fe9d8012803868: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hon",
        },
        bb84206b52c9fa6151b6883f9a1f032b: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "aiv",
        },
        "3a56a581ec8ff60aba1514e106120b0b": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "comm",
        },
        "1c7f71919db049439d22c0fc91cdd4a0": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bhlb",
        },
        b4a583ae4d78fab82a23ea9b8e158e10: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "acmr",
        },
        da253b59aab9e25dd2a5b00aa8e31b61: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rvnc",
        },
        "61c9696e981e6316fdf71f83f5a9984e": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rna",
        },
        dc24791ff5497300a7d69e1b137bd523: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cass",
        },
        a3e3471bcf0af34af1c43e2678087509: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "uvv",
        },
        "7e008430c3f7f594b2874e9cb255bcc0": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "umpq",
        },
        a8c7ec5aeb196e743d3e8e768764d531: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "avy",
        },
        f95e9fbd861c9c0780c119b6f165991d: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vc",
        },
        "8a427f2a823951d79d4dc7ee0f4938e0": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "krys",
        },
        f4e27715f21b487a9b519437d62d5661: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mfa",
        },
        "63699450b2ff14ba56d67815018d293f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ilmn",
        },
        cd67cd23eca709dfd3442c7754708b63: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "alx",
        },
        "83406e95193eed53a005e4f223431033": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wlfc",
        },
        e26ffd42912661c49ff0dd7fee43bcf1: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hwm",
        },
        "3e719cc4944ba276bd13764547f90c63": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "leg",
        },
        "66a0347c6774885c1509ff8c95d5c8c9": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bdn",
        },
        "25e3ca39d7b1d353883429e3d1c3d5f4": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mho",
        },
        df2ab3c3a0d3183192c1907210764e5b: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ptgx",
        },
        "8f3a7cf316fa65861758cb29d9fb8c55": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "xncr",
        },
        c4443efd30526dd0c89a5cb04cb63ac9: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cpri",
        },
        bbd6f72eff7f7a67f801bb3ea1795ef8: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "atsg",
        },
        "1720d519a1ca3e88871b45b2dc46cdea": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "agx",
        },
        "7fb7fd1bfcb3e7920528eabd749577cd": {
          currency: 7,
          exchange: 4,
          investment: 4,
          stock: "b",
          symbol: "brk",
        },
        "66ce4cc3efa83aaf850bbf1bdb7d0cc0": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dht",
        },
        e11878df977c3d29a3720997558a18af: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tvtx",
        },
        "1b757ec750d8cdad4e92685e45ac147f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ait",
        },
        "67e84edc314b7807cefb4c67701857aa": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mco",
        },
        "64543d6e3ac3b8ebccb8be37400be66c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nrc",
        },
        "7da94372b1b85e83085ea66b6977c8e3": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vicr",
        },
        e3452f3fd4f7c30caeb565db7058e446: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rckt",
        },
        eabaa2c092045d904b97bb6923d10710: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lw",
        },
        "0f6f020e1a1a2274767b23cbac85a148": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cldx",
        },
        "612d51b2c1a8b16d0da837ef288f87fe": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cim",
        },
        "8c3c680487acc2ece1a873699d7ce61f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "efx",
        },
        b4ba5362afcdd568f01a418252a9e424: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gshd",
        },
        "1905c30543f84e1f77f4ce6fe41eae1f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "inn",
        },
        "62c200ff944294fc55c4752fd40e38e1": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vrdn",
        },
        "59504cb772d2beeeb163de6b34141010": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rgnx",
        },
        d9f0e7099d90e04b53cbca8d30016685: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "repl",
        },
        eb7cf111d7a197e0fb0f5d9f0f62fc1f: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ppta",
        },
        "6060349625555354de1bcfc3ea91092e": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "qtwo",
        },
        "59ece00da7a021e564f12af5ca7ea0d1": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mnro",
        },
        a8b22feb7dd1673084369bccaf871ef9: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "insm",
        },
        "578d5d643a32441550a0141d93569164": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lumn",
        },
        "02ee1036bd0375a4e49acb59fcf1622c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cvi",
        },
        "673ad972b507c219ca1861b741b6b557": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tsla",
        },
        "0e79c921aebdb9f739a34bcb6592ba66": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lite",
        },
        "98033aa9da2bdab94ad13734962d8852": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sem",
        },
        "26b439452e2b8fdccea8825ea5da3535": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "aspn",
        },
        "3e29374683e5f3a99a64b82430508802": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "amsf",
        },
        "99931586ab21a6c62b0ae81cf6043798": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cac",
        },
        "44015ea1edaf177fcbb366f91605987f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "arwr",
        },
        "7eae3b0b234d99d32596bcd48405acff": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "asix",
        },
        b0a682b87ff797615cf6bfc8a4f7b611: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cwk",
        },
        "47fdf36b83cbf9a4efe7b0d2fb856daf": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hain",
        },
        "9fbce7b99c7b0a472769c59fac496298": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ttmi",
        },
        "8ec05158cc93e2c25a112d69fdecc9a4": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wafd",
        },
        "2ac83aea6b882e65ab0480fdded86bc8": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vici",
        },
        ce1635b74ee327eed0f41f7b5e776cd4: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wina",
        },
        "1a3572f704b8b5a110e59d3283c1eb27": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gcbc",
        },
        "55b3740133e746b19e4f2a196eca12e9": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lbty.k",
        },
        e846b4dfa87ee732fb5d43f35bc8f16e: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cfg",
        },
        "0276b8c6704ede73e72471b160ec1567": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fcpt",
        },
        "4c5d8121f9b5b2afccd7425948eea666": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sq",
        },
        "8930ce3a699232fb066426d18dccb0f6": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lnc",
        },
        "6d7d58e09019d06555be0b075f9cce75": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "itt",
        },
        "0072bb5f15de2ce40df7da4cde34fe55": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bld",
        },
        "5877608c3791bb463bb5ce723a4bab6b": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vhi",
        },
        "716af6a8ef1a02e56aae2235a516966a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hlne",
        },
        d8158ae94ef57023122ca7f139d294ac: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tmp",
        },
        "5e04329c2446915accd3f28d0b300270": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "zyme",
        },
        "27e2f6c070d8d85be859096722247fac": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "plrx",
        },
        "6935ff14ca607ca782dc6bc337aeeef6": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "burl",
        },
        "0a17dce9abdaedc959fa0119170c97c4": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "zts",
        },
        a8d94a70c4c8a2ccaef064b5470d2539: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "novt",
        },
        "92342cb62d0f11496aaf701a8c90baff": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "auph",
        },
        "9f5b63aa4e719daaeb31e752b0ca1a6a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "evh",
        },
        e93c82374da035c68b54f894b538a36a: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "four",
        },
        e40f42641dfaeaaf2fc9630ed36a6aa4: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "md",
        },
        "192dc30a57eb7d4089161c5c8d1338fb": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lbrd.k",
        },
        "454c442478d7c3ff0d1f5c3a9cf98cdf": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mtx",
        },
        bf559cc4b98bba928a30913b51000258: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bke",
        },
        "1f1fa3f5ab282234948a2f82c72640c9": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "atec",
        },
        c897f9a848c5431ebe14f206ea8e3d0b: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nfe",
        },
        e9d71351016840acadd5e920065451e4: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "crs",
        },
        "5cfdcc2dac1e607d212ca28d4bf99998": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ese",
        },
        "24b3d2705b2427f1bf0320f8337076ef": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "seb",
        },
        "01f5759c2ba9fc50d8abcd94059db372": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "uht",
        },
        "780ac1c8aff290b02a1975e59cd51dda": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "urgn",
        },
        ffcab38981985f5a39f0b5b5ca6d6762: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "five",
        },
        "6521a1e5037584c868d4b1b6bd0b0281": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "uuuu",
        },
        "2056abe8f7a03f3938e74eb29825c30e": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rwt",
        },
        be12fa1a3c6164748fb837f362153ed2: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "oflx",
        },
        "02aff1935313d0ec202e2ffb679f5c02": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pbf",
        },
        e733c0d6ab6477fcc0e7b2a5c13f3bed: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ntla",
        },
        "3dfa947a8e9a632b47418f064e9e2c89": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nycb",
        },
        "1b27011fd0cf955f0f8b6892c34b9dd6": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "kkr",
        },
        "24f5db0f9b6c5609401a16be3aeb2f0a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sitm",
        },
        "74ddca19144d3960021449c17926b15e": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gpn",
        },
        "6f9c136035ff5b69639b233a71dabebf": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tex",
        },
        ba6c927df4c73d250718133518225500: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "smci",
        },
        "00a84f32d488cf040e5b68324062cd4f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pcrx",
        },
        "2142058b733cc0d37c940b7154ce57ef": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "aep",
        },
        "83a2626a73d127747e041e5b0e0eeb5c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "algt",
        },
        "3278aa2945d1b0c992d291e0b4d83dfa": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cwen",
        },
        "4c5d238fe6f2f3e38dfdb4a2f1e2fdf0": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ngvc",
        },
        b26a2d3d9975e365266fbc103932f001: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fold",
        },
        c665b623d9db48b6ddfc3f1613a48e48: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ame",
        },
        dc6bd74cfa1425a4041e2ea0977475d2: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ymab",
        },
        c33b80ec81e22838afc1b6d62afc47d0: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vst",
        },
        "89e11235b45939afe0387e7046e80d96": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wve",
        },
        d90b8b866714d3f89a5ce0bd543891d4: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "amsc",
        },
        "3a03a53e608821c144020b947ec8cca4": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "btu",
        },
        dbac2b4453f7a37e66a8300564234654: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "okta",
        },
        ac17bd9eaff5e469df45c65d53a774a3: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sibn",
        },
        b947a750b6a97e64567c7eef96f03b82: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "spxc",
        },
        "4f183a904837fb811f3ddf63d655b9cb": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gs",
        },
        "181fc65fb2325c1a4f97201c2ec8e0ee": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "catx",
        },
        "55d3a08745cfd7c9104f832f07989996": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tdw",
        },
        "9396af8d340b65cf315eaf6cc4f1edeb": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "arr",
        },
        "35e594205009eb0b9eeeef24a7b5e63e": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "he",
        },
        "7101f13c31eea40d4567a77ca3b0a545": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pinc",
        },
        e1a9c112c66bc6ab85fc34c7aa4af235: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ares",
        },
        "37b2bcf55d1195c888e8409d03713ca0": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "xp",
        },
        "7e98d239b86cd731026b26cb0f8e0e8a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gldd",
        },
        bca5d280f4cc8aa98ff7fd977d2ff7b8: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cogt",
        },
        "30a6a89d5e6c9c8a76cac9f9b425112f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "foxf",
        },
        ca7510787f113e4fd3b47fc198f61ea4: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cdna",
        },
        "6a0853f6567ec5b14eb47abdbb0f6919": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rdfn",
        },
        "7fec29c9181b459679c5508a105ae218": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "atge",
        },
        "2afbf2e39ecdeaf02583d55b764e1e37": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cde",
        },
        "15e26c2242ef3227f4e6dc843bea2e6c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cbrl",
        },
        f41cfd9e4c02ac475e606f9b5eac2f34: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "kw",
        },
        "94bd1a085971bfbc3d8c22685922f204": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "irdm",
        },
        "44e14af2caa664294c65d3cb37227acf": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mxl",
        },
        adc6b93104ee290e75701fba8e0529e2: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cffn",
        },
        d9896278d850b0c3e0e8816470b414cf: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "msft",
        },
        "3da67de89b435bccfc503115c8bea935": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "plpc",
        },
        f12280576e6f9923556bc3bf951393c5: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dea",
        },
        "11e41f1eeea64f1a38d8204a3861b387": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ufcs",
        },
        "4b0dde839136f6321c4a03d814afd71a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gff",
        },
        c9ce0a6b02c878c571903b051f383c51: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "giii",
        },
        "155069fa276d74dcaa8af9643ba03b7b": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "atxs",
        },
        "4b71bdb39456afc65202a07d6abf80cd": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "eeft",
        },
        cf149048e610e3f611147828a0de6599: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "celc",
        },
        "532e02117cb8a13dacec53edaddd8fbe": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cof",
        },
        "1d33260b223aa1aa35a08710f387f964": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gm",
        },
        "52bfc42e737aded8e5d435c77fd31145": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bvs",
        },
        "61365a6c095fdb9d8447157a0ead02b5": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ui",
        },
        "9a3675468b5c690c5dc5e221cdd5f97c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lxu",
        },
        "0318d8aeda45bf43a5d6f988a11e051f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pdm",
        },
        "2a5a6a07f3a4902276f5fdd36a80f8e0": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "trgp",
        },
        "6cd83834d61d71889fff00f5ad3a3222": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "apog",
        },
        d4cc4cb59103504a79fc6043887578f2: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "anss",
        },
        "50edb94b05f5f8617d5be4b305de8e45": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "axta",
        },
        "85754cbeda8e8431c32bd39f8c921734": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "anet",
        },
        "51279c9e948d3935c5a5374eb8701235": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "c",
        },
        "2dff8d4d3e2206df807c76d11eeffef3": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nvee",
        },
        "1ba78b64aa96df4002bd02b995696d1f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "amat",
        },
        "467d9d8acd631280fa89f56b8682ada7": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mmsi",
        },
        a304025e2c0d2111020af42787d77801: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "syre",
        },
        b8fc97e4c186d3f1980ed841268b62b2: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rc",
        },
        a725d2e4a872fc6b2be8b5f4c1d25f1c: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "armk",
        },
        ab1b7c5788c5cbe90413b2c5f6ea0884: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "aapl",
        },
        b4ac8d7bd4bedc2176d130064f47369b: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "apls",
        },
        "09db6910022c782e84bcccfb221d3677": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "glob",
        },
        "8e1fc05f9e09b1c87cc604dbe56a8f34": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "acm",
        },
        e5c8bb4286dfed0eaa7310707a3ab26b: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gpre",
        },
        "8bfd0cf0ce618f8cf130ae9402ab7d14": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "slm",
        },
        "8ece5c00fb70ff5b53388740bf734ae5": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "thc",
        },
        "9ddb838e6d885e1ea9d3b5ad639f4a01": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pfgc",
        },
        cfb21f3fa435b9db361ae8e4917ab4ba: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vfc",
        },
        "4db183e0cae672717b6a13969317f499": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "siga",
        },
        "71155a4e3d966f68ca8ef8d0806fbb98": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "jpm",
        },
        d5e06d796f4e149831c24f459e769730: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "orc",
        },
        "52f87c8fe162843d3403abd3bfabc34a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "parr",
        },
        "14b051b74b14467b3788878139ef5e46": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "egbn",
        },
        "29c091278371ea9d6e430223a7072167": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ltc",
        },
        "79deb77d122b5ffafe7ef07cc1959dcc": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dbrg",
        },
        e84e59c1b1a6cb61956b8e60bcc1470c: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cprx",
        },
        cfab72b099bc6d40edc3a393162f9699: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "blbd",
        },
        cb0d69a8dd4b8098cc7664bc9dde364d: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pmt",
        },
        a153fc80169d2810afe8324b58ebd28c: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lnth",
        },
        fab7ce1905bf2ea433c150d84e973aea: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "djco",
        },
        "8b640ada1b52c315e2883764640aa4a4": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "kros",
        },
        "0611e36b3738d092075012abac686f51": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ogs",
        },
        "65187607a4787f16012a62f21483bf48": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ari",
        },
        "94ec7fbac7af783a0a3f0c50bab93746": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sats",
        },
        f502ec0b465c310947beda177282e0ef: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "syf",
        },
        "90c084ba683dbf3bfae4c7a0d0b1300c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rcus",
        },
        "68fb230150f6c02b9ae283eb3f72b000": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "b",
        },
        e06d87d444d320d9db5f7728a830f1c9: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hiw",
        },
        "5d98ae63afe32a107b59b1a98538d472": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "adp",
        },
        f124f7980a0ccb423f4ed5d83e8138d9: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bxmt",
        },
        cc5c345494767267f3fe1171c43c9584: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "alb",
        },
        "3304328227ee04a32b793c3eeda3b042": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "metc",
        },
        a6031fa79eeb9a593a8d88930dc3bacf: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wtm",
        },
        e6d8ffca07f18b447e786378a34f45cc: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "prta",
        },
        "5eaccae44f4a985fd5ac979f103f2bad": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sndx",
        },
        ba39c59471de86ad2141f706f35e6423: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "usb",
        },
        "98c0b1b56363326e963bdac31464b72d": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wash",
        },
        "60aa72d171b6a7d93694f3aa41e2410b": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mrna",
        },
        b972138108e48f17bbbe63a5167de880: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bio",
        },
        e27b7fd2ecaaafed5ca1e0a8f961b6fe: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "esgr",
        },
        ca0944bf13ea9e519a046c71828db8ca: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "iqv",
        },
        "3dc1bf4453454043a44f024f49f5f9c1": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "spnt",
        },
        dc174cff7b1597c770529a7e9cdf2db3: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dei",
        },
        fb9a27911d166f1011b1141e7288da7f: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "clsk",
        },
        "41a89c69bec01bf63058aaf645de13f5": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hifs",
        },
        "3dd71cc1a77d84f01c93218a5652e97b": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "unfi",
        },
        "37c931d4d7e3faef9cdfcc1b66962383": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ir",
        },
        e93ec1f3ab2013e1e9f9280417a4bbbf: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vre",
        },
        b9eaed9220cd519fe885bf6b822b0ad5: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mrcy",
        },
        "18c57ba43038f700ac4923faa49812d7": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bfh",
        },
        d10bfa45a219bbd418cd567234b09a19: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "aat",
        },
        ae545794271ef76b1ec90b46a83ab88d: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "amzn",
        },
        "0605df39d52ed7d15e4111cdd9d7cf0b": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "uri",
        },
        "15e615a220f8af1ad8717e2019418a9e": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dan",
        },
      },
      weights_values: {
        eaefcfab18b522a3ffd64a8c8b869dbb: 0.0103,
        e1dd8c42f34b62cf5b8bce6c66c122f0: -0.0051,
        "7f08832a59c22a50db6366fb692ff5b3": -0.0051,
        "4bc17f9f8fe437e1f1fac47b724d579c": -0.0052,
        "77b4c0cc412a915f647a8e70173f5648": -0.0052,
        d313a7e438129f2555e52d5c3d54d6f0: 0.0103,
        "59e45b3764ea8e159e146057fc14643e": -0.0052,
        "4aef686aefd9fb4c3c29dd27a9feeb8c": 0.0103,
        dbfdf9cb2ebd4cf0191340bde4529d4b: -0.0051,
        "85af551c636599c994e2101e301cbd36": -0.0051,
        eabed427f6cd5e134a94b439a4029592: -0.0052,
        ef62d63378b5769cf6311e3ced02a7e9: -0.0039,
        "04e1c3dea5a9743dbfefbbe710d8d609": -0.0052,
        c60f803b2c75f803f84fd84547baa5cd: -0.0052,
        f60efea748206dcb190d94fe40ec6713: 0.0103,
        "9c0869a43c599e4b9d24a35fc30c883f": 0.0103,
        f23f0ca4db72d2396a143a3715bae535: 0.0103,
        "1780417ab56408685105d7594a7d51c5": 0.0104,
        "802a8338991981bd08c6e0b4f3cc5570": -0.0052,
        d8d1a8ce1f7e5f56e0d5f12707620fa7: -0.0051,
        "239701df36f9776af7730b41bca6807c": -0.0052,
        fce9583334f4798a38545aa8dfd38780: 0.0104,
        "1ee677c199730b92adb59687a4a3d414": -0.0052,
        d233094ee33894d30e6adb39a4a0c8b4: -0.0051,
        "798391375d273a52b15b3c728af15439": -0.0052,
        "57e315ecdc80ae62a4b434552b0629ac": 0.0103,
        e663a76612e4e434c81ea87d4e0afd75: -0.0052,
        "85321998b82dadcdf711ded3e5f32b20": 0.0103,
        bbf7e022826ad0143b0b9d76bf3718cd: -0.0051,
        "08b31ea26c5a8f34d61130ffb357de23": 0.0104,
        "4ee287d0758375445714b28452f88a59": -0.0051,
        ac1b771229a181f98a9c95066a502963: -0.0052,
        c7fcd95f2fbf9751f6f36347b7fe0962: -0.0051,
        cbf0a1289e021c056032025248b99ca7: 0.0104,
        "659cb3cf45568dd4ece8d980ae1274a0": -0.0051,
        "4d7c49758eb2315d3ae6bf45b1942a8e": 0.0104,
        "34381b71c6bfbe773dc91e9c7dec6a74": 0.0103,
        c0fb84ace6a04f150b0fe7b65e10378b: -0.0052,
        "219e317e0d4d2626f38ca04ec09c94ad": 0.0103,
        "82e6b4cddb4ddbc1dca4e1dc31734c63": -0.0052,
        c9d722b04c10d1b00cbb6a431a7411dd: -0.0052,
        d29d65d83cd2f569b2103b5b37c5815f: -0.0053,
        "58963e1391a67722b042ca752682d3b3": -0.0052,
        "61732916688e77b56e8d0883a3a54951": -0.0052,
        "1d4de01bb7c0d523030b158623435c3d": -0.0052,
        "8d595a60cc95e6e9f660b0a0d074d8ff": 0.0102,
        "66d0ed8904c5ee3956596baf5aa7a3d2": -0.0051,
        d8a85cd65fb6142a7016239b638f8b88: -0.0051,
        d7aa8c3ad006f6ef2bf67d33d9427478: -0.0052,
        "9cd0587020686999338f44d6e217b629": -0.0052,
        "1a7a051cefc976c925f82e5a2d6f5196": -0.005,
        "31e8a691bc5882a2f844f31da9f4d607": -0.0052,
        cb8cab2fde22b28c4529125d740cf236: 0.0104,
        "83f251c32a379136b6db2ba0c15d3270": -0.0052,
        ee0ce4b88478d019dbddb1a9395721b1: -0.0052,
        "4c8f85974f52a59a85ed546787e7562a": -0.0052,
        daa5bdd9080f23b8056815eeed87ba05: -0.0052,
        e2327fb5323507963f1ae34da374b2b1: -0.0052,
        "99d6b89383ed6b7e8e80c64fcca0cf33": 0.0104,
        e29bb8ffb46548fbb0fa82c8794decb8: 0.0104,
        "5c5bfe844c48c34e846899808f98ca80": 0.0104,
        e435a3b577d9edd770200ef4e5657de6: -0.0052,
        e7ac6e63ef7735a178df15eca910ed6a: 0.0104,
        a34f941ebf87b8cd7d185e0f7b724ad2: 0.0103,
        c68a46334760ac43d75edefde8778499: -0.0051,
        cd321d2a90aff1da3d1e6bae0883d7d8: -0.0052,
        "412171513af976f9d9d65871fa2db9e1": -0.0051,
        "561dca8c7bb657b3aa1cf4a6d3c3aa34": 0.0104,
        f05886fe787fac12d4c8b6d356dc0f87: -0.0052,
        "0ef0e9eac8c136f003037f7b7e49a655": -0.0052,
        ae8f96740139e41e24fe9d8012803868: 0.0103,
        bb84206b52c9fa6151b6883f9a1f032b: -0.0052,
        "3a56a581ec8ff60aba1514e106120b0b": -0.0052,
        "1c7f71919db049439d22c0fc91cdd4a0": 0.0103,
        b4a583ae4d78fab82a23ea9b8e158e10: -0.0053,
        da253b59aab9e25dd2a5b00aa8e31b61: -0.0052,
        "61c9696e981e6316fdf71f83f5a9984e": -0.0051,
        dc24791ff5497300a7d69e1b137bd523: -0.0052,
        a3e3471bcf0af34af1c43e2678087509: -0.0052,
        "7e008430c3f7f594b2874e9cb255bcc0": -0.0052,
        a8c7ec5aeb196e743d3e8e768764d531: 0.0103,
        f95e9fbd861c9c0780c119b6f165991d: 0.0103,
        "8a427f2a823951d79d4dc7ee0f4938e0": 0.0103,
        f4e27715f21b487a9b519437d62d5661: -0.0052,
        "63699450b2ff14ba56d67815018d293f": -0.0052,
        cd67cd23eca709dfd3442c7754708b63: -0.0051,
        "83406e95193eed53a005e4f223431033": -0.0052,
        e26ffd42912661c49ff0dd7fee43bcf1: 0.0104,
        "3e719cc4944ba276bd13764547f90c63": -0.0052,
        "66a0347c6774885c1509ff8c95d5c8c9": -0.0051,
        "25e3ca39d7b1d353883429e3d1c3d5f4": 0.0104,
        df2ab3c3a0d3183192c1907210764e5b: -0.0051,
        "8f3a7cf316fa65861758cb29d9fb8c55": -0.0052,
        c4443efd30526dd0c89a5cb04cb63ac9: -0.0051,
        bbd6f72eff7f7a67f801bb3ea1795ef8: -0.0052,
        "1720d519a1ca3e88871b45b2dc46cdea": -0.0052,
        "7fb7fd1bfcb3e7920528eabd749577cd": 0.0104,
        "66ce4cc3efa83aaf850bbf1bdb7d0cc0": -0.0051,
        e11878df977c3d29a3720997558a18af: -0.0053,
        "1b757ec750d8cdad4e92685e45ac147f": 0.0103,
        "67e84edc314b7807cefb4c67701857aa": 0.0103,
        "64543d6e3ac3b8ebccb8be37400be66c": -0.0051,
        "7da94372b1b85e83085ea66b6977c8e3": -0.0052,
        e3452f3fd4f7c30caeb565db7058e446: -0.0052,
        eabaa2c092045d904b97bb6923d10710: -0.0052,
        "0f6f020e1a1a2274767b23cbac85a148": -0.0052,
        "612d51b2c1a8b16d0da837ef288f87fe": -0.0052,
        "8c3c680487acc2ece1a873699d7ce61f": 0.0103,
        b4ba5362afcdd568f01a418252a9e424: 0.0102,
        "1905c30543f84e1f77f4ce6fe41eae1f": -0.0052,
        "62c200ff944294fc55c4752fd40e38e1": -0.0051,
        "59504cb772d2beeeb163de6b34141010": -0.0052,
        d9f0e7099d90e04b53cbca8d30016685: -0.0051,
        eb7cf111d7a197e0fb0f5d9f0f62fc1f: -0.0051,
        "6060349625555354de1bcfc3ea91092e": 0.0103,
        "59ece00da7a021e564f12af5ca7ea0d1": -0.0052,
        a8b22feb7dd1673084369bccaf871ef9: -0.0052,
        "578d5d643a32441550a0141d93569164": -0.0052,
        "02ee1036bd0375a4e49acb59fcf1622c": -0.0051,
        "673ad972b507c219ca1861b741b6b557": 0.0104,
        "0e79c921aebdb9f739a34bcb6592ba66": -0.0052,
        "98033aa9da2bdab94ad13734962d8852": 0.0103,
        "26b439452e2b8fdccea8825ea5da3535": -0.0053,
        "3e29374683e5f3a99a64b82430508802": -0.0052,
        "99931586ab21a6c62b0ae81cf6043798": -0.0052,
        "44015ea1edaf177fcbb366f91605987f": -0.0052,
        "7eae3b0b234d99d32596bcd48405acff": -0.0052,
        b0a682b87ff797615cf6bfc8a4f7b611: 0.0102,
        "47fdf36b83cbf9a4efe7b0d2fb856daf": -0.0052,
        "9fbce7b99c7b0a472769c59fac496298": 0.0103,
        "8ec05158cc93e2c25a112d69fdecc9a4": 0.0103,
        "2ac83aea6b882e65ab0480fdded86bc8": -0.0052,
        ce1635b74ee327eed0f41f7b5e776cd4: -0.0052,
        "1a3572f704b8b5a110e59d3283c1eb27": -0.0026,
        "55b3740133e746b19e4f2a196eca12e9": -0.0052,
        e846b4dfa87ee732fb5d43f35bc8f16e: 0.0104,
        "0276b8c6704ede73e72471b160ec1567": -0.0052,
        "4c5d8121f9b5b2afccd7425948eea666": 0.0104,
        "8930ce3a699232fb066426d18dccb0f6": -0.0052,
        "6d7d58e09019d06555be0b075f9cce75": 0.0103,
        "0072bb5f15de2ce40df7da4cde34fe55": 0.0103,
        "5877608c3791bb463bb5ce723a4bab6b": -0.0046,
        "716af6a8ef1a02e56aae2235a516966a": 0.0103,
        d8158ae94ef57023122ca7f139d294ac: -0.0051,
        "5e04329c2446915accd3f28d0b300270": -0.0052,
        "27e2f6c070d8d85be859096722247fac": -0.0052,
        "6935ff14ca607ca782dc6bc337aeeef6": 0.0104,
        "0a17dce9abdaedc959fa0119170c97c4": 0.0103,
        a8d94a70c4c8a2ccaef064b5470d2539: 0.0103,
        "92342cb62d0f11496aaf701a8c90baff": -0.0052,
        "9f5b63aa4e719daaeb31e752b0ca1a6a": -0.0051,
        e93c82374da035c68b54f894b538a36a: 0.0104,
        e40f42641dfaeaaf2fc9630ed36a6aa4: -0.0052,
        "192dc30a57eb7d4089161c5c8d1338fb": -0.0052,
        "454c442478d7c3ff0d1f5c3a9cf98cdf": 0.0103,
        bf559cc4b98bba928a30913b51000258: -0.0052,
        "1f1fa3f5ab282234948a2f82c72640c9": -0.0052,
        c897f9a848c5431ebe14f206ea8e3d0b: -0.0052,
        e9d71351016840acadd5e920065451e4: 0.0104,
        "5cfdcc2dac1e607d212ca28d4bf99998": 0.0103,
        "24b3d2705b2427f1bf0320f8337076ef": -0.005,
        "01f5759c2ba9fc50d8abcd94059db372": -0.0052,
        "780ac1c8aff290b02a1975e59cd51dda": -0.0052,
        ffcab38981985f5a39f0b5b5ca6d6762: -0.0052,
        "6521a1e5037584c868d4b1b6bd0b0281": -0.0052,
        "2056abe8f7a03f3938e74eb29825c30e": -0.0052,
        be12fa1a3c6164748fb837f362153ed2: -0.0052,
        "02aff1935313d0ec202e2ffb679f5c02": -0.0051,
        e733c0d6ab6477fcc0e7b2a5c13f3bed: -0.0052,
        "3dfa947a8e9a632b47418f064e9e2c89": -0.0052,
        "1b27011fd0cf955f0f8b6892c34b9dd6": 0.0104,
        "24f5db0f9b6c5609401a16be3aeb2f0a": -0.0052,
        "74ddca19144d3960021449c17926b15e": 0.0103,
        "6f9c136035ff5b69639b233a71dabebf": 0.0103,
        ba6c927df4c73d250718133518225500: -0.0051,
        "00a84f32d488cf040e5b68324062cd4f": -0.0052,
        "2142058b733cc0d37c940b7154ce57ef": 0.0103,
        "83a2626a73d127747e041e5b0e0eeb5c": -0.0052,
        "3278aa2945d1b0c992d291e0b4d83dfa": -0.0052,
        "4c5d238fe6f2f3e38dfdb4a2f1e2fdf0": -0.0051,
        b26a2d3d9975e365266fbc103932f001: 0.0104,
        c665b623d9db48b6ddfc3f1613a48e48: 0.0103,
        dc6bd74cfa1425a4041e2ea0977475d2: -0.0052,
        c33b80ec81e22838afc1b6d62afc47d0: -0.0052,
        "89e11235b45939afe0387e7046e80d96": -0.0052,
        d90b8b866714d3f89a5ce0bd543891d4: -0.0053,
        "3a03a53e608821c144020b947ec8cca4": -0.0052,
        dbac2b4453f7a37e66a8300564234654: 0.0103,
        ac17bd9eaff5e469df45c65d53a774a3: -0.0052,
        b947a750b6a97e64567c7eef96f03b82: 0.0104,
        "4f183a904837fb811f3ddf63d655b9cb": 0.0104,
        "181fc65fb2325c1a4f97201c2ec8e0ee": -0.0051,
        "55d3a08745cfd7c9104f832f07989996": 0.0103,
        "9396af8d340b65cf315eaf6cc4f1edeb": -0.0052,
        "35e594205009eb0b9eeeef24a7b5e63e": -0.0052,
        "7101f13c31eea40d4567a77ca3b0a545": -0.0052,
        e1a9c112c66bc6ab85fc34c7aa4af235: 0.0104,
        "37b2bcf55d1195c888e8409d03713ca0": -0.0052,
        "7e98d239b86cd731026b26cb0f8e0e8a": -0.0052,
        bca5d280f4cc8aa98ff7fd977d2ff7b8: -0.0052,
        "30a6a89d5e6c9c8a76cac9f9b425112f": -0.0052,
        ca7510787f113e4fd3b47fc198f61ea4: -0.0051,
        "6a0853f6567ec5b14eb47abdbb0f6919": -0.0052,
        "7fec29c9181b459679c5508a105ae218": 0.0103,
        "2afbf2e39ecdeaf02583d55b764e1e37": 0.0102,
        "15e26c2242ef3227f4e6dc843bea2e6c": -0.0052,
        f41cfd9e4c02ac475e606f9b5eac2f34: -0.0052,
        "94bd1a085971bfbc3d8c22685922f204": -0.0051,
        "44e14af2caa664294c65d3cb37227acf": -0.0051,
        adc6b93104ee290e75701fba8e0529e2: -0.0052,
        d9896278d850b0c3e0e8816470b414cf: 0.0103,
        "3da67de89b435bccfc503115c8bea935": -0.0051,
        f12280576e6f9923556bc3bf951393c5: -0.0052,
        "11e41f1eeea64f1a38d8204a3861b387": -0.0052,
        "4b0dde839136f6321c4a03d814afd71a": 0.0104,
        c9ce0a6b02c878c571903b051f383c51: -0.0052,
        "155069fa276d74dcaa8af9643ba03b7b": -0.0052,
        "4b71bdb39456afc65202a07d6abf80cd": 0.0103,
        cf149048e610e3f611147828a0de6599: -0.0052,
        "532e02117cb8a13dacec53edaddd8fbe": 0.0104,
        "1d33260b223aa1aa35a08710f387f964": 0.0103,
        "52bfc42e737aded8e5d435c77fd31145": -0.0051,
        "61365a6c095fdb9d8447157a0ead02b5": -0.0051,
        "9a3675468b5c690c5dc5e221cdd5f97c": -0.0051,
        "0318d8aeda45bf43a5d6f988a11e051f": -0.0052,
        "2a5a6a07f3a4902276f5fdd36a80f8e0": 0.0104,
        "6cd83834d61d71889fff00f5ad3a3222": -0.0052,
        d4cc4cb59103504a79fc6043887578f2: 0.0103,
        "50edb94b05f5f8617d5be4b305de8e45": 0.0104,
        "85754cbeda8e8431c32bd39f8c921734": 0.0104,
        "51279c9e948d3935c5a5374eb8701235": 0.0104,
        "2dff8d4d3e2206df807c76d11eeffef3": 0.0103,
        "1ba78b64aa96df4002bd02b995696d1f": 0.0103,
        "467d9d8acd631280fa89f56b8682ada7": 0.0103,
        a304025e2c0d2111020af42787d77801: -0.0052,
        b8fc97e4c186d3f1980ed841268b62b2: -0.0052,
        a725d2e4a872fc6b2be8b5f4c1d25f1c: -0.0052,
        ab1b7c5788c5cbe90413b2c5f6ea0884: 0.0104,
        b4ac8d7bd4bedc2176d130064f47369b: -0.0051,
        "09db6910022c782e84bcccfb221d3677": 0.0102,
        "8e1fc05f9e09b1c87cc604dbe56a8f34": 0.0103,
        e5c8bb4286dfed0eaa7310707a3ab26b: -0.0052,
        "8bfd0cf0ce618f8cf130ae9402ab7d14": 0.0104,
        "8ece5c00fb70ff5b53388740bf734ae5": 0.0103,
        "9ddb838e6d885e1ea9d3b5ad639f4a01": 0.0103,
        cfb21f3fa435b9db361ae8e4917ab4ba: -0.0053,
        "4db183e0cae672717b6a13969317f499": -0.0051,
        "71155a4e3d966f68ca8ef8d0806fbb98": 0.0104,
        d5e06d796f4e149831c24f459e769730: -0.0052,
        "52f87c8fe162843d3403abd3bfabc34a": -0.0052,
        "14b051b74b14467b3788878139ef5e46": -0.0051,
        "29c091278371ea9d6e430223a7072167": -0.0052,
        "79deb77d122b5ffafe7ef07cc1959dcc": -0.0052,
        e84e59c1b1a6cb61956b8e60bcc1470c: 0.0103,
        cfab72b099bc6d40edc3a393162f9699: 0.0103,
        cb0d69a8dd4b8098cc7664bc9dde364d: -0.0052,
        a153fc80169d2810afe8324b58ebd28c: 0.0103,
        fab7ce1905bf2ea433c150d84e973aea: -0.0051,
        "8b640ada1b52c315e2883764640aa4a4": -0.0052,
        "0611e36b3738d092075012abac686f51": -0.0052,
        "65187607a4787f16012a62f21483bf48": -0.0052,
        "94ec7fbac7af783a0a3f0c50bab93746": -0.0051,
        f502ec0b465c310947beda177282e0ef: 0.0104,
        "90c084ba683dbf3bfae4c7a0d0b1300c": -0.0053,
        "68fb230150f6c02b9ae283eb3f72b000": -0.0052,
        e06d87d444d320d9db5f7728a830f1c9: -0.0052,
        "5d98ae63afe32a107b59b1a98538d472": 0.0103,
        f124f7980a0ccb423f4ed5d83e8138d9: -0.0052,
        cc5c345494767267f3fe1171c43c9584: -0.0052,
        "3304328227ee04a32b793c3eeda3b042": -0.0052,
        a6031fa79eeb9a593a8d88930dc3bacf: -0.0051,
        e6d8ffca07f18b447e786378a34f45cc: -0.0051,
        "5eaccae44f4a985fd5ac979f103f2bad": -0.0052,
        ba39c59471de86ad2141f706f35e6423: 0.0104,
        "98c0b1b56363326e963bdac31464b72d": -0.0052,
        "60aa72d171b6a7d93694f3aa41e2410b": -0.0051,
        b972138108e48f17bbbe63a5167de880: -0.0052,
        e27b7fd2ecaaafed5ca1e0a8f961b6fe: -0.0052,
        ca0944bf13ea9e519a046c71828db8ca: 0.0103,
        "3dc1bf4453454043a44f024f49f5f9c1": -0.0052,
        dc174cff7b1597c770529a7e9cdf2db3: -0.0052,
        fb9a27911d166f1011b1141e7288da7f: -0.0053,
        "41a89c69bec01bf63058aaf645de13f5": -0.0051,
        "3dd71cc1a77d84f01c93218a5652e97b": -0.0051,
        "37c931d4d7e3faef9cdfcc1b66962383": 0.0104,
        e93ec1f3ab2013e1e9f9280417a4bbbf: -0.0052,
        b9eaed9220cd519fe885bf6b822b0ad5: -0.0051,
        "18c57ba43038f700ac4923faa49812d7": 0.0104,
        d10bfa45a219bbd418cd567234b09a19: -0.0052,
        ae545794271ef76b1ec90b46a83ab88d: 0.0104,
        "0605df39d52ed7d15e4111cdd9d7cf0b": 0.0103,
        "15e615a220f8af1ad8717e2019418a9e": -0.0052,
      },
    },
    aum: 3547126.9218738405,
    strategyId: "c79a684b68379f3d07d291dc500f400d",
  },
  {
    date: 1720716059426,
    name: "BetaHighLow",
    owner: {
      type: 3,
      value: "marshall@9823capital.com",
    },
    permissions: {
      default: 1,
    },
    description: "Long High Beta, Short Low Beta",
    aum: -0.0018640018159885585,
    strategyId: "2ee1410582b3da8563fc370afac81f7e",
  },
  {
    date: 1682750463035,
    name: "CPFactor",
    owner: {
      type: 0,
      value: "51ed22746f7ec8b1f513fd2762f60326",
    },
    permissions: {
      default: 1,
    },
    signals: {
      date: 1727879697833,
      strategy: "ae1cdc078091ccaa0dad1ad5896b1105",
      user: "blair@9823capital.com",
      weights_keys: {
        "270b39c7639848c4daf9c4b9e10d6fb2": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "jll",
        },
        "7f08832a59c22a50db6366fb692ff5b3": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ghc",
        },
        "53af808d43d695b8a5462d778d87f658": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mtdr",
        },
        "77b4c0cc412a915f647a8e70173f5648": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tdoc",
        },
        "86f83227bca6e4488f17e7b96587ef30": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mrvl",
        },
        aa97a66bffa0844d447240f3e87448aa: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "zuo",
        },
        "65c82989d69b0f1c6f17ba428b8495e7": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cxw",
        },
        fed87f005e587b2d04b965aad7aa7e44: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sig",
        },
        f1ca12117b9ae382741b915ae1d65243: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "next",
        },
        d40ba63a689a9c432a96240d96357d3f: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fcx",
        },
        "79c33140368d9618c532ccfd91a89b90": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tds",
        },
        d01b1a997a07977d1182862e27ad9b5c: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mdb",
        },
        "3ed169ac5f6eee562646fcd8066eb93c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "oxm",
        },
        e479384e85296d8d8540b8a7504104d3: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ccl",
        },
        "57e315ecdc80ae62a4b434552b0629ac": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lrcx",
        },
        "082f2ab41c1c51ac3f563825c1eb383a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dltr",
        },
        bbf7e022826ad0143b0b9d76bf3718cd: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "axsm",
        },
        "1421598e4e636414eb17aa77e7820d16": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "matx",
        },
        "1166e3156a9aeca8d35a29e581d3fdcc": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "amn",
        },
        "6c7a075c8e940fce23ab9344828bebae": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vlo",
        },
        "38c9ef7646107f09c66bb8303ac89469": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nfg",
        },
        "613f5312eb2acb33800600ee357488c7": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pfe",
        },
        "23bd5c31f08b3144c1c3569544fc6dd1": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tdg",
        },
        fdbb66a61889f4b636c5d22637c098b0: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cabo",
        },
        "8266a2b64e9f280ecad81ed0ebca6188": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "crgy",
        },
        "16b706204e38933cb9f81244d2c66e5c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rng",
        },
        "9adf6f996df77308180eb9d94e3fc329": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nvda",
        },
        "97eb81bc749859f424c55493cd377b05": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ctra",
        },
        "3c40460e8f4ff8826897e08f6efb2069": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "psx",
        },
        e05abe3fac5c033f70c4e3127b2ace51: {
          currency: 7,
          exchange: 4,
          investment: 4,
          stock: "a",
          symbol: "cwen",
        },
        efa47c1c995eebe7c301de9d31e7a801: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "amba",
        },
        "8d2545bdfb1174427c68692d9ba3e90f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "czr",
        },
        fce535e499938181ea7615bdc6cd4466: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hun",
        },
        cc01b299d2ad6e4266570a57a1d77b00: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sm",
        },
        a8004b3de9b58cdd6732f69beb67ecbe: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dow",
        },
        "1d4de01bb7c0d523030b158623435c3d": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "play",
        },
        "4fad61acd8517400936f93913ad87719": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vtr",
        },
        b154e95b33b1b970c0dc3ef044e4e481: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "azo",
        },
        "1bf9035c45a40331f92bb3553e91ea42": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pten",
        },
        "3132570ac32c474d965c54a9f7dd99d7": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gpor",
        },
        f635f0b2420c7919ea6869fd34b6e48e: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tsn",
        },
        "90fe9fa8121f86a1ff42d2f0604f75d9": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cc",
        },
        "83742812f0f2cb6d8b41528ab7ba72d2": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bgc",
        },
        c02331f10c3821c67c24486df71d70f9: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "iff",
        },
        "5a045610c62e29ac90dc77270e44492c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tln",
        },
        "33867ab349551276020f74f331f7ac84": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fg",
        },
        "264b8b87bc8ce791959a3d1c4841e005": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ntnx",
        },
        "4c8f85974f52a59a85ed546787e7562a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "amr",
        },
        e2327fb5323507963f1ae34da374b2b1: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bxp",
        },
        cbd52cc79a2b99551cd1f5e130994cdf: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "adi",
        },
        e82df189aa6948a74578e01b8f13b086: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hrb",
        },
        e398dcd60d8a5ace26c528451816fe02: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rblx",
        },
        e435a3b577d9edd770200ef4e5657de6: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "imvt",
        },
        "8e2486ab7d626b19209721cf9cfde2f5": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mrtn",
        },
        "524d29e7f3de1f12c25a4a9559f868bd": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "revg",
        },
        e2e6a272e724b7abf8ded1ee0b8ac76a: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dino",
        },
        d23ce7f1bb7ad2908890cdce52dbdac7: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "batra",
        },
        a2af189f363c23cc6498ecc083f35a1d: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "kro",
        },
        "568ce8bc712a36d1015c45a7eedde394": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fang",
        },
        "84cc37a41c79f4b2ed9d73f2679b9307": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "uec",
        },
        db9405ab63045f793b5b4d2744857e41: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "jbl",
        },
        bd13726e2f6ebe7bb266e6e2b999bfdf: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ba",
        },
        "7af084200d6cac135482d72b84a79867": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dvn",
        },
        "10e3e484b413d976d3ec62f189e1a1fb": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lxp",
        },
        "28f4d63d58603fb89e005d6afac3f509": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pd",
        },
        "027f17f5cf2afc6dd4b3f891c82234ed": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "elme",
        },
        d1f2e3aebd334e17044973533f9b9e10: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "trox",
        },
        "612a1d35c0c71f16188c824684336105": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pstg",
        },
        "8327aa60ac7b853fff1c1eba7b5b79ad": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "avtr",
        },
        "14b852975a86bd96e31449e530264888": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "eqt",
        },
        d98d23bfa1b4f1849cfc8666ce89fc3e: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wbd",
        },
        "25fec0624c29611e03ee981b2ca2ee2d": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mlkn",
        },
        af140b9239c49460623d0339eeb23511: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rrc",
        },
        "948ecd4dca4174f90ef30e4f7d188cff": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hr",
        },
        a392db2b55bc0cb2782ef68dfa4b1aea: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hcp",
        },
        "3e719cc4944ba276bd13764547f90c63": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "leg",
        },
        "2fe210845544a0ee8220540c3dc35e82": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "jbgs",
        },
        "6d05a928d29ba860b2f8ba58cc50d958": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "kmx",
        },
        "15979f1e2e4421e6eb246e383952d191": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cien",
        },
        "0d4958e9ef93759f7cc131cd1dc31af5": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gef",
        },
        "826d66d0295dbfa1103f9ba8a02fef11": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "slg",
        },
        "703b9b97df6ac6a3f99e1a753c0d7aa0": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lad",
        },
        e690f19770279d4080ab3ba62a245f3e: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "str",
        },
        e4c0120db7c0928b2f0d5885c819a081: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "crwd",
        },
        "9dbc31f54ff9b66e72750271ace73150": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ncno",
        },
        c427ae11bdbf41164ed548b6bd7f4423: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mtn",
        },
        "02ee1036bd0375a4e49acb59fcf1622c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cvi",
        },
        "31e00ace0a353147cf814bf803bb15eb": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cop",
        },
        d40234f3260aa9bdec169daa1039529c: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "arch",
        },
        d2ed207057821986ba40ad1955fcc512: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pnw",
        },
        "6cb834613d48735f110898f2c15af05f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hog",
        },
        ae9ce9749882cf4e6f063c8ae785a9a6: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "chtr",
        },
        ffae12e8e3f68345117d98d6f44d8c36: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cost",
        },
        c90526d4f2c3556c33ed571d002b8326: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ar",
        },
        ff9cb86998273b7dacc1ae4344c2ae7c: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hd",
        },
        c83e8140536ae7f6c5b68f9ca427e4c6: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "eqh",
        },
        "10733070dcb5e95e74ca276514d22691": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mq",
        },
        "1dad91fc583564343c25f64b0a8d26df": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nmih",
        },
        "3115204d5c55f69f74d61bd3ab3bfb40": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "el",
        },
        "7eed1391c52006f3ff53d0d14dcd333e": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "eqr",
        },
        "8f5a505eeb599b104b554d29c03685c2": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mro",
        },
        acac41a0f614e23bc40c5bf43ee3eab6: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dy",
        },
        "1094ea023b52ba20faaa2db6c477cc1c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ue",
        },
        bbebb04b5f5f166506c1a17c5adbca80: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wba",
        },
        e187a63e28ef7afe9e1d527284d1b3d9: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "intc",
        },
        "66c804816a416e9fa46e3716a1744310": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "apa",
        },
        "0981d43d72d4680b0e0adbcde38e0f56": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "snow",
        },
        e6804e038c9a54eec9d0a4fc68d1d630: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "smar",
        },
        "2f773d8cbb4d3334581a0245f09dbc1f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wly",
        },
        df586f5e4f91eed436999aa3962a8f37: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "stld",
        },
        e77ec035eab8522083311e4900b85902: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "entg",
        },
        "77dce863b17718f63aa56fbe9a01e916": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "crc",
        },
        "02aff1935313d0ec202e2ffb679f5c02": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pbf",
        },
        "6f9c136035ff5b69639b233a71dabebf": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tex",
        },
        "2b0ba91e5ecbbdb93bed852940eae200": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "spr",
        },
        "8d2533dc6c1c89f2563a5465892cb890": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gwre",
        },
        "5f114b9b70aaeb7009c7b5402574c478": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ce",
        },
        "94d17e08e8e9c47467f665996580ea3c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "iot",
        },
        e7d3f1e80cddf3efd61ade5a94f4637c: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mpc",
        },
        "915e586ad60a6a9b6d66579e49848201": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ttc",
        },
        c33b80ec81e22838afc1b6d62afc47d0: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vst",
        },
        b01d8964628eff46f9dcfe196f64af73: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tpl",
        },
        cadac7d839348e79040bfc14ba3a082e: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hes",
        },
        "35072bd6e2e911f5b169a69bc37b5618": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ttwo",
        },
        a78d49894a6841f703c3e57ec35f2fbb: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "brze",
        },
        fabe31dab7744f9caa68653e1536365e: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "m",
        },
        "4ff1853b32095ebec496e0c637dbb6ef": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "avav",
        },
        dbac2b4453f7a37e66a8300564234654: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "okta",
        },
        "78695a8c705d6355c30dd3e4f98dfe1d": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lbrda",
        },
        "1d8c0bd4b37b90c719b9c867e51cd0ad": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "chrd",
        },
        "4f183a904837fb811f3ddf63d655b9cb": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gs",
        },
        "5cde87804d02b791cf0438e1031ab093": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bowl",
        },
        f7271d22f302ba9f5c20d179e36efce2: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "aa",
        },
        "35f414b8b1282da4a7fa8146b3113dbc": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "swk",
        },
        "66e93a2570f845b7bff973c4bf728dca": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "etr",
        },
        "55d3a08745cfd7c9104f832f07989996": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tdw",
        },
        "1f2842ce87d236557e3040d804f6407f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dg",
        },
        "34f7f3192109258f7c173f316dd09f15": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ovv",
        },
        "55d5cd306070f0540fcca6b1149f78a7": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wor",
        },
        bddf48715cb0cb5eb295dc46359f449e: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "s",
        },
        a6a329d16a0833c74d7248bbd89b97b1: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sphr",
        },
        db6122dec43ab5600d3fc056059bcdf5: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "talo",
        },
        "778f49dfee83e2ab7506f70db0bc00ca": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mtb",
        },
        f7caf882ae355ddf8299d2d5e23b9023: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ayi",
        },
        ede87d2cfe38397b319c79c354ae8693: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "amwd",
        },
        "69eff7d7ecaa81436f1644f7398c100c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hqy",
        },
        fc672cad5943bcaa1d731962b71d623d: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "d",
        },
        f41cfd9e4c02ac475e606f9b5eac2f34: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "kw",
        },
        a9df25ca783337f929f7d8d1d1578df7: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "x",
        },
        "40b070af675671af67ed6eb379974ddb": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nari",
        },
        f12280576e6f9923556bc3bf951393c5: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dea",
        },
        "9e079dcad25337e281b4bdf297affd9f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ally",
        },
        d9ac1addfcf17dcea5d5c67546abffa4: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hei",
        },
        "2f4b9c2dfb9b0e983405921b7df6575e": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wy",
        },
        "7f2eca93267d6031d86b855a2c0ce111": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nxst",
        },
        "0318d8aeda45bf43a5d6f988a11e051f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pdm",
        },
        "2a5a6a07f3a4902276f5fdd36a80f8e0": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "trgp",
        },
        "9c45acd493a1d65ee4829a72b66ccc95": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "kn",
        },
        "74e4e9f1863f3890effd3734aba0283b": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bbio",
        },
        d85aae99819ec8116bf8331171dafa92: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pch",
        },
        "6fa61e6ea450876bb1d65e07ccda0f3d": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hcc",
        },
        b91304ede202c9848d62e44ab42689aa: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "inva",
        },
        "59029f4588903c11c1b7922e888adf2f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "trno",
        },
        b37f21819ff90a24663c16c2e938dc33: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "noc",
        },
        "519f9523bd5d47747bf0448eb6e0d55b": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "xpo",
        },
        eddb54cdb5eefd8cecadfc173f76df5d: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cxm",
        },
        cfb21f3fa435b9db361ae8e4917ab4ba: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vfc",
        },
        "5f7a731dcb64ecad83b7b235a0ad0602": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "eog",
        },
        aa06617ccd5734cdd57a2b9f4b1810b2: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ngvt",
        },
        "075aba8cd0cda1484757420d49ac398a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "zs",
        },
        "5d600188363a9013d5830e0d6f11dfc6": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nep",
        },
        "5b6c5d3de6857bda68fea0b326cabcf9": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mdu",
        },
        d77898a5798df72d413a9c22594f210a: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gnw",
        },
        "5e30f623c3449f5ebbd2d82fa0748e6b": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "clf",
        },
        "8b640ada1b52c315e2883764640aa4a4": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "kros",
        },
        "4fe5ba5bedf64b2ce5d742d950f079f6": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rely",
        },
        "40832b42a351e3e21e21cef0bdea5e81": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mp",
        },
        a03cafaafeda1d8e40b006ca1f603a2d: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "doc",
        },
        c0917043d471a5854ab5577ef23312ed: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "npwr",
        },
        d6863df6cdc31a37ed23a54609fd913a: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ritm",
        },
        "65187607a4787f16012a62f21483bf48": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ari",
        },
        "6247da701a3e63561bacb77efdb52414": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nsa",
        },
        "97108bd0f6b33a8a09fba6f9c4a0b821": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cnm",
        },
        "68affeb44f699a149cd36edfea724893": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "jwn",
        },
        "0efa13b9014e7c005f6f96cff50af731": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mac",
        },
        "45ebd69d4cd8db8746193c47f14f7333": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vrnt",
        },
        e98a87d7e88aded5f6db07f83beb184a: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "xom",
        },
        bcb3d46078ebca9fa7af3c68cf66a856: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "crk",
        },
        "3727f9549639fe2e871ea742be76d001": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "oxy",
        },
        a95ce6bd1912a04b84b5c805e081bdef: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "aap",
        },
        a6031fa79eeb9a593a8d88930dc3bacf: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wtm",
        },
        "0fcd3d322b1d7d8b87ff279601ef087d": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dlr",
        },
        "86758096421d6ba5abb4f29f6872c44b": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vno",
        },
        "282b61b565a3e1efa03e278e18ac8bcb": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ceg",
        },
        "4d3d07f83838669ad71e41063265e607": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "civi",
        },
        "0bcf9774d52d7410a91ffa9fe2646dde": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "snps",
        },
        ecf29fdb6e65310d6396539f32913d66: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fybr",
        },
        "5eaccae44f4a985fd5ac979f103f2bad": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sndx",
        },
        f2470cd00db3d9bbb122200a5d98c043: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dk",
        },
        "17d460b24d8f649cb915fb617d94e237": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cacc",
        },
        e4b5a796a413069bb3f17bf90fcf558d: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ions",
        },
        dc174cff7b1597c770529a7e9cdf2db3: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dei",
        },
        "0385b45d0ae6f8b601bed7e914bfb10d": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "are",
        },
        "3bb1327818e0c49fff5ac53c21902713": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mgy",
        },
        "06c89902cb968bd672c725600628f98a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "keys",
        },
        "443649807592ae98c83e935bc52881ad": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nue",
        },
        cba4cb95a6ccdb481a9a0382f69ad790: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mur",
        },
        "3dd71cc1a77d84f01c93218a5652e97b": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "unfi",
        },
        "9f6e997e36d883d0312613141dc86b16": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rlj",
        },
        "79f6daf430f5c96ed3d23390704aae1e": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fdx",
        },
        "18c57ba43038f700ac4923faa49812d7": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bfh",
        },
        "801dea6c86acd93d8e8dbfe1ceebc7d9": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dci",
        },
        "2c6dfc9b48b16b5217f56fdd0525c5d0": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "esrt",
        },
        ae545794271ef76b1ec90b46a83ab88d: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "amzn",
        },
        fb47cc6f488412cde9f8068343b83f2b: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dks",
        },
      },
      weights_values: {
        "270b39c7639848c4daf9c4b9e10d6fb2": -0.00571,
        "7f08832a59c22a50db6366fb692ff5b3": -0.00571,
        "53af808d43d695b8a5462d778d87f658": -0.00571,
        "77b4c0cc412a915f647a8e70173f5648": -0.00571,
        "86f83227bca6e4488f17e7b96587ef30": 0.025,
        aa97a66bffa0844d447240f3e87448aa: 0.025,
        "65c82989d69b0f1c6f17ba428b8495e7": -0.00571,
        fed87f005e587b2d04b965aad7aa7e44: -0.00571,
        f1ca12117b9ae382741b915ae1d65243: -0.00571,
        d40ba63a689a9c432a96240d96357d3f: -0.00571,
        "79c33140368d9618c532ccfd91a89b90": -0.00571,
        d01b1a997a07977d1182862e27ad9b5c: 0.025,
        "3ed169ac5f6eee562646fcd8066eb93c": -0.00571,
        e479384e85296d8d8540b8a7504104d3: -0.00571,
        "57e315ecdc80ae62a4b434552b0629ac": -0.00571,
        "082f2ab41c1c51ac3f563825c1eb383a": -0.00571,
        bbf7e022826ad0143b0b9d76bf3718cd: -0.00571,
        "1421598e4e636414eb17aa77e7820d16": -0.00571,
        "1166e3156a9aeca8d35a29e581d3fdcc": -0.00571,
        "6c7a075c8e940fce23ab9344828bebae": -0.00571,
        "38c9ef7646107f09c66bb8303ac89469": -0.00571,
        "613f5312eb2acb33800600ee357488c7": -0.00571,
        "23bd5c31f08b3144c1c3569544fc6dd1": -0.00571,
        fdbb66a61889f4b636c5d22637c098b0: -0.00571,
        "8266a2b64e9f280ecad81ed0ebca6188": -0.00571,
        "16b706204e38933cb9f81244d2c66e5c": 0.025,
        "9adf6f996df77308180eb9d94e3fc329": 0.025,
        "97eb81bc749859f424c55493cd377b05": -0.00571,
        "3c40460e8f4ff8826897e08f6efb2069": -0.00571,
        e05abe3fac5c033f70c4e3127b2ace51: -0.00571,
        efa47c1c995eebe7c301de9d31e7a801: 0.025,
        "8d2545bdfb1174427c68692d9ba3e90f": -0.00571,
        fce535e499938181ea7615bdc6cd4466: -0.00571,
        cc01b299d2ad6e4266570a57a1d77b00: -0.00571,
        a8004b3de9b58cdd6732f69beb67ecbe: -0.00571,
        "1d4de01bb7c0d523030b158623435c3d": -0.00571,
        "4fad61acd8517400936f93913ad87719": -0.00571,
        b154e95b33b1b970c0dc3ef044e4e481: -0.00571,
        "1bf9035c45a40331f92bb3553e91ea42": -0.00571,
        "3132570ac32c474d965c54a9f7dd99d7": -0.00571,
        f635f0b2420c7919ea6869fd34b6e48e: -0.00571,
        "90fe9fa8121f86a1ff42d2f0604f75d9": -0.00571,
        "83742812f0f2cb6d8b41528ab7ba72d2": 0.025,
        c02331f10c3821c67c24486df71d70f9: -0.00571,
        "5a045610c62e29ac90dc77270e44492c": -0.00571,
        "33867ab349551276020f74f331f7ac84": -0.00571,
        "264b8b87bc8ce791959a3d1c4841e005": 0.025,
        "4c8f85974f52a59a85ed546787e7562a": -0.00571,
        e2327fb5323507963f1ae34da374b2b1: -0.00571,
        cbd52cc79a2b99551cd1f5e130994cdf: 0.025,
        e82df189aa6948a74578e01b8f13b086: -0.00571,
        e398dcd60d8a5ace26c528451816fe02: -0.00571,
        e435a3b577d9edd770200ef4e5657de6: -0.00571,
        "8e2486ab7d626b19209721cf9cfde2f5": -0.00571,
        "524d29e7f3de1f12c25a4a9559f868bd": 0.025,
        e2e6a272e724b7abf8ded1ee0b8ac76a: -0.00571,
        d23ce7f1bb7ad2908890cdce52dbdac7: -0.00571,
        a2af189f363c23cc6498ecc083f35a1d: -0.00571,
        "568ce8bc712a36d1015c45a7eedde394": -0.00571,
        "84cc37a41c79f4b2ed9d73f2679b9307": 0.025,
        db9405ab63045f793b5b4d2744857e41: -0.00571,
        bd13726e2f6ebe7bb266e6e2b999bfdf: -0.00571,
        "7af084200d6cac135482d72b84a79867": -0.00571,
        "10e3e484b413d976d3ec62f189e1a1fb": -0.00571,
        "28f4d63d58603fb89e005d6afac3f509": 0.025,
        "027f17f5cf2afc6dd4b3f891c82234ed": -0.00571,
        d1f2e3aebd334e17044973533f9b9e10: -0.00571,
        "612a1d35c0c71f16188c824684336105": 0.025,
        "8327aa60ac7b853fff1c1eba7b5b79ad": -0.00571,
        "14b852975a86bd96e31449e530264888": -0.00571,
        d98d23bfa1b4f1849cfc8666ce89fc3e: -0.00571,
        "25fec0624c29611e03ee981b2ca2ee2d": -0.00571,
        af140b9239c49460623d0339eeb23511: -0.00571,
        "948ecd4dca4174f90ef30e4f7d188cff": -0.00571,
        a392db2b55bc0cb2782ef68dfa4b1aea: 0.025,
        "3e719cc4944ba276bd13764547f90c63": -0.00571,
        "2fe210845544a0ee8220540c3dc35e82": -0.00571,
        "6d05a928d29ba860b2f8ba58cc50d958": -0.00571,
        "15979f1e2e4421e6eb246e383952d191": 0.025,
        "0d4958e9ef93759f7cc131cd1dc31af5": 0.025,
        "826d66d0295dbfa1103f9ba8a02fef11": -0.00571,
        "703b9b97df6ac6a3f99e1a753c0d7aa0": -0.00571,
        e690f19770279d4080ab3ba62a245f3e: -0.00571,
        e4c0120db7c0928b2f0d5885c819a081: 0.025,
        "9dbc31f54ff9b66e72750271ace73150": 0.025,
        c427ae11bdbf41164ed548b6bd7f4423: -0.00571,
        "02ee1036bd0375a4e49acb59fcf1622c": -0.00571,
        "31e00ace0a353147cf814bf803bb15eb": -0.00571,
        d40234f3260aa9bdec169daa1039529c: -0.00571,
        d2ed207057821986ba40ad1955fcc512: -0.00571,
        "6cb834613d48735f110898f2c15af05f": -0.00571,
        ae9ce9749882cf4e6f063c8ae785a9a6: -0.00571,
        ffae12e8e3f68345117d98d6f44d8c36: -0.00571,
        c90526d4f2c3556c33ed571d002b8326: -0.00571,
        ff9cb86998273b7dacc1ae4344c2ae7c: -0.00571,
        c83e8140536ae7f6c5b68f9ca427e4c6: 0.025,
        "10733070dcb5e95e74ca276514d22691": -0.00571,
        "1dad91fc583564343c25f64b0a8d26df": 0.025,
        "3115204d5c55f69f74d61bd3ab3bfb40": -0.00571,
        "7eed1391c52006f3ff53d0d14dcd333e": -0.00571,
        "8f5a505eeb599b104b554d29c03685c2": -0.00571,
        acac41a0f614e23bc40c5bf43ee3eab6: 0.025,
        "1094ea023b52ba20faaa2db6c477cc1c": -0.00571,
        bbebb04b5f5f166506c1a17c5adbca80: -0.00571,
        e187a63e28ef7afe9e1d527284d1b3d9: -0.00571,
        "66c804816a416e9fa46e3716a1744310": -0.00571,
        "0981d43d72d4680b0e0adbcde38e0f56": 0.025,
        e6804e038c9a54eec9d0a4fc68d1d630: 0.025,
        "2f773d8cbb4d3334581a0245f09dbc1f": -0.00571,
        df586f5e4f91eed436999aa3962a8f37: -0.00571,
        e77ec035eab8522083311e4900b85902: -0.00571,
        "77dce863b17718f63aa56fbe9a01e916": -0.00571,
        "02aff1935313d0ec202e2ffb679f5c02": -0.00571,
        "6f9c136035ff5b69639b233a71dabebf": -0.00571,
        "2b0ba91e5ecbbdb93bed852940eae200": -0.00571,
        "8d2533dc6c1c89f2563a5465892cb890": 0.025,
        "5f114b9b70aaeb7009c7b5402574c478": -0.00571,
        "94d17e08e8e9c47467f665996580ea3c": 0.025,
        e7d3f1e80cddf3efd61ade5a94f4637c: -0.00571,
        "915e586ad60a6a9b6d66579e49848201": -0.00571,
        c33b80ec81e22838afc1b6d62afc47d0: -0.00571,
        b01d8964628eff46f9dcfe196f64af73: -0.00571,
        cadac7d839348e79040bfc14ba3a082e: -0.00571,
        "35072bd6e2e911f5b169a69bc37b5618": -0.00571,
        a78d49894a6841f703c3e57ec35f2fbb: 0.025,
        fabe31dab7744f9caa68653e1536365e: -0.00571,
        "4ff1853b32095ebec496e0c637dbb6ef": -0.00571,
        dbac2b4453f7a37e66a8300564234654: 0.025,
        "78695a8c705d6355c30dd3e4f98dfe1d": -0.00571,
        "1d8c0bd4b37b90c719b9c867e51cd0ad": -0.00571,
        "4f183a904837fb811f3ddf63d655b9cb": -0.00571,
        "5cde87804d02b791cf0438e1031ab093": -0.00571,
        f7271d22f302ba9f5c20d179e36efce2: -0.00571,
        "35f414b8b1282da4a7fa8146b3113dbc": -0.00571,
        "66e93a2570f845b7bff973c4bf728dca": -0.00571,
        "55d3a08745cfd7c9104f832f07989996": 0.025,
        "1f2842ce87d236557e3040d804f6407f": -0.00571,
        "34f7f3192109258f7c173f316dd09f15": -0.00571,
        "55d5cd306070f0540fcca6b1149f78a7": -0.00571,
        bddf48715cb0cb5eb295dc46359f449e: 0.025,
        a6a329d16a0833c74d7248bbd89b97b1: -0.00571,
        db6122dec43ab5600d3fc056059bcdf5: -0.00571,
        "778f49dfee83e2ab7506f70db0bc00ca": -0.00571,
        f7caf882ae355ddf8299d2d5e23b9023: -0.00571,
        ede87d2cfe38397b319c79c354ae8693: 0.025,
        "69eff7d7ecaa81436f1644f7398c100c": 0.025,
        fc672cad5943bcaa1d731962b71d623d: -0.00571,
        f41cfd9e4c02ac475e606f9b5eac2f34: -0.00571,
        a9df25ca783337f929f7d8d1d1578df7: -0.00571,
        "40b070af675671af67ed6eb379974ddb": -0.00571,
        f12280576e6f9923556bc3bf951393c5: -0.00571,
        "9e079dcad25337e281b4bdf297affd9f": -0.00571,
        d9ac1addfcf17dcea5d5c67546abffa4: 0.025,
        "2f4b9c2dfb9b0e983405921b7df6575e": -0.00571,
        "7f2eca93267d6031d86b855a2c0ce111": -0.00571,
        "0318d8aeda45bf43a5d6f988a11e051f": -0.00571,
        "2a5a6a07f3a4902276f5fdd36a80f8e0": -0.00571,
        "9c45acd493a1d65ee4829a72b66ccc95": -0.00571,
        "74e4e9f1863f3890effd3734aba0283b": -0.00571,
        d85aae99819ec8116bf8331171dafa92: -0.00571,
        "6fa61e6ea450876bb1d65e07ccda0f3d": -0.00571,
        b91304ede202c9848d62e44ab42689aa: -0.00571,
        "59029f4588903c11c1b7922e888adf2f": -0.00571,
        b37f21819ff90a24663c16c2e938dc33: -0.00571,
        "519f9523bd5d47747bf0448eb6e0d55b": -0.00571,
        eddb54cdb5eefd8cecadfc173f76df5d: 0.025,
        cfb21f3fa435b9db361ae8e4917ab4ba: -0.00571,
        "5f7a731dcb64ecad83b7b235a0ad0602": -0.00571,
        aa06617ccd5734cdd57a2b9f4b1810b2: -0.00571,
        "075aba8cd0cda1484757420d49ac398a": 0.025,
        "5d600188363a9013d5830e0d6f11dfc6": -0.00571,
        "5b6c5d3de6857bda68fea0b326cabcf9": -0.00571,
        d77898a5798df72d413a9c22594f210a: -0.00571,
        "5e30f623c3449f5ebbd2d82fa0748e6b": -0.00571,
        "8b640ada1b52c315e2883764640aa4a4": -0.00571,
        "4fe5ba5bedf64b2ce5d742d950f079f6": -0.00571,
        "40832b42a351e3e21e21cef0bdea5e81": -0.00571,
        a03cafaafeda1d8e40b006ca1f603a2d: -0.00571,
        c0917043d471a5854ab5577ef23312ed: -0.00571,
        d6863df6cdc31a37ed23a54609fd913a: 0.025,
        "65187607a4787f16012a62f21483bf48": -0.00571,
        "6247da701a3e63561bacb77efdb52414": -0.00571,
        "97108bd0f6b33a8a09fba6f9c4a0b821": -0.00571,
        "68affeb44f699a149cd36edfea724893": -0.00571,
        "0efa13b9014e7c005f6f96cff50af731": -0.00571,
        "45ebd69d4cd8db8746193c47f14f7333": 0.025,
        e98a87d7e88aded5f6db07f83beb184a: -0.00571,
        bcb3d46078ebca9fa7af3c68cf66a856: -0.00571,
        "3727f9549639fe2e871ea742be76d001": -0.00571,
        a95ce6bd1912a04b84b5c805e081bdef: -0.00571,
        a6031fa79eeb9a593a8d88930dc3bacf: -0.00571,
        "0fcd3d322b1d7d8b87ff279601ef087d": -0.00571,
        "86758096421d6ba5abb4f29f6872c44b": -0.00571,
        "282b61b565a3e1efa03e278e18ac8bcb": -0.00571,
        "4d3d07f83838669ad71e41063265e607": -0.00571,
        "0bcf9774d52d7410a91ffa9fe2646dde": 0.025,
        ecf29fdb6e65310d6396539f32913d66: -0.00571,
        "5eaccae44f4a985fd5ac979f103f2bad": -0.00571,
        f2470cd00db3d9bbb122200a5d98c043: -0.00571,
        "17d460b24d8f649cb915fb617d94e237": -0.00571,
        e4b5a796a413069bb3f17bf90fcf558d: -0.00571,
        dc174cff7b1597c770529a7e9cdf2db3: -0.00571,
        "0385b45d0ae6f8b601bed7e914bfb10d": -0.00571,
        "3bb1327818e0c49fff5ac53c21902713": -0.00571,
        "06c89902cb968bd672c725600628f98a": 0.025,
        "443649807592ae98c83e935bc52881ad": -0.00571,
        cba4cb95a6ccdb481a9a0382f69ad790: -0.00571,
        "3dd71cc1a77d84f01c93218a5652e97b": 0.025,
        "9f6e997e36d883d0312613141dc86b16": -0.00571,
        "79f6daf430f5c96ed3d23390704aae1e": -0.00571,
        "18c57ba43038f700ac4923faa49812d7": -0.00571,
        "801dea6c86acd93d8e8dbfe1ceebc7d9": 0.025,
        "2c6dfc9b48b16b5217f56fdd0525c5d0": -0.00571,
        ae545794271ef76b1ec90b46a83ab88d: -0.00571,
        fb47cc6f488412cde9f8068343b83f2b: -0.00571,
      },
    },
    aum: 10963690.682313759,
    strategyId: "ae1cdc078091ccaa0dad1ad5896b1105",
  },
  {
    date: 1719440183832,
    name: "CPFactor15",
    owner: {
      type: 0,
      value: "51ed22746f7ec8b1f513fd2762f60326",
    },
    permissions: {
      default: 1,
    },
    signals: {
      date: 1727101652316,
      strategy: "b3e1a234a66b8d211668139213731875",
      user: "marshall@9823capital.com",
      weights_keys: {
        "7f08832a59c22a50db6366fb692ff5b3": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ghc",
        },
        "53af808d43d695b8a5462d778d87f658": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mtdr",
        },
        "77b4c0cc412a915f647a8e70173f5648": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tdoc",
        },
        "254f582a717b4d2974e2f657e30d249b": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cwan",
        },
        "8f01de55f262b275d46127cbf9ed7f3e": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "exr",
        },
        abaf75ad6ab947208cafede49ffb07bc: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lyb",
        },
        "05e229880e25482fd8f089d3fffc15a3": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "expd",
        },
        fed87f005e587b2d04b965aad7aa7e44: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sig",
        },
        d3106aec1bd7ffa3adb9ad5ff74a4f3c: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "edr",
        },
        e0cae2f49029bfba03d3f6d4d6c4a298: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mu",
        },
        a981e42a55eaa4c3550976d585e0662a: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "prim",
        },
        "09b1c429fc0d03a2e30e7354264a5f3e": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "oge",
        },
        "3ed169ac5f6eee562646fcd8066eb93c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "oxm",
        },
        cdce272879caf5e4cbb1145134175928: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "intu",
        },
        "2914b64d13583fc71d660dcd288b1d6a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wd",
        },
        "082f2ab41c1c51ac3f563825c1eb383a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dltr",
        },
        e1918051011dbdb3f11cb56d6a278053: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "roiv",
        },
        decf9992d16b362047930197ced8acc2: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "supn",
        },
        "1421598e4e636414eb17aa77e7820d16": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "matx",
        },
        "54879bd15f1bceeb1079b286baf0ba2e": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ottr",
        },
        ae71577b197e1d15bc0c82c1e34e69c4: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "on",
        },
        "0987f00d16936144a0c9fb58063b1f72": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hii",
        },
        "613f5312eb2acb33800600ee357488c7": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pfe",
        },
        "23bd5c31f08b3144c1c3569544fc6dd1": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tdg",
        },
        fdbb66a61889f4b636c5d22637c098b0: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cabo",
        },
        df6edcb07ed68c9587260ab478d97069: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pay",
        },
        "4e2a4888127aa53f486189630ff32c44": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bsy",
        },
        e7a45262e599d4299e68fc4745523fd0: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gtlb",
        },
        cc8b4b4b8e0f87495d3bf1c979d3bb8e: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lzb",
        },
        "168a356aa48e0576951f95f7148659fb": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pool",
        },
        "34ec186bd35f33382bc07a3de2735b24": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mck",
        },
        d4e02411b859263e8facc3c2746f0367: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "peco",
        },
        "576413110c84e4a42451dc219e7e4add": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sqsp",
        },
        "1d4de01bb7c0d523030b158623435c3d": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "play",
        },
        "1d6d3eab68260df496783e256faa69bf": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hlx",
        },
        "5331835b1c467316c4a659c8c654589c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pvh",
        },
        "3a0be9547d48cb370a1c17ff9dfc0028": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "amh",
        },
        c02331f10c3821c67c24486df71d70f9: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "iff",
        },
        "641e2e68e371c3c04501e1bb11382a71": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "atmu",
        },
        "60553de7def104ac92eca65dc9cd6c8d": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "aroc",
        },
        e2327fb5323507963f1ae34da374b2b1: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bxp",
        },
        cbd52cc79a2b99551cd1f5e130994cdf: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "adi",
        },
        e398dcd60d8a5ace26c528451816fe02: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rblx",
        },
        e3ea2e16afcafcea80b5fd4754d9a60e: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "crnx",
        },
        a2af189f363c23cc6498ecc083f35a1d: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "kro",
        },
        "568ce8bc712a36d1015c45a7eedde394": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fang",
        },
        f05886fe787fac12d4c8b6d356dc0f87: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gnl",
        },
        "5ceceba2c96953b108a6613b201f3f93": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pk",
        },
        "84cc37a41c79f4b2ed9d73f2679b9307": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "uec",
        },
        b27aa80aafb310c826b287254613e308: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vrts",
        },
        ad82c909843ca41f21a33daf8cb78620: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dvax",
        },
        "1215bd5fedd05bae8782159b9f71ad77": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cpt",
        },
        f8aa52c52961340ae36c0374d1894cae: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dkng",
        },
        "7af084200d6cac135482d72b84a79867": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dvn",
        },
        "027f17f5cf2afc6dd4b3f891c82234ed": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "elme",
        },
        b51afc171c4c79cddacee0443ae4ffea: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "urbn",
        },
        "8327aa60ac7b853fff1c1eba7b5b79ad": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "avtr",
        },
        "948ecd4dca4174f90ef30e4f7d188cff": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hr",
        },
        "3e719cc4944ba276bd13764547f90c63": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "leg",
        },
        "6d05a928d29ba860b2f8ba58cc50d958": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "kmx",
        },
        "2fe210845544a0ee8220540c3dc35e82": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "jbgs",
        },
        "4bffaf645cf7ebf9b2f8476ac68777c5": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "soc",
        },
        "15979f1e2e4421e6eb246e383952d191": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cien",
        },
        "2e21a72c81d1aa2dcef0c2c63e7cdb17": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mtch",
        },
        "8f3a7cf316fa65861758cb29d9fb8c55": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "xncr",
        },
        "7793f234983e8351824716d037e612a2": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lh",
        },
        db738cfa75b237abe47de695ab13e406: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "orly",
        },
        "1d39be3dc90e377af5937412ac0df4b4": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fbin",
        },
        c437a7bf5bf25c07347fa065e5afe313: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "alit",
        },
        "1d88d2158d9456f0326453066045d536": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vnom",
        },
        e3452f3fd4f7c30caeb565db7058e446: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rckt",
        },
        "0d4958e9ef93759f7cc131cd1dc31af5": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gef",
        },
        "4c2771d32052e5f876f9487d1d3343aa": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "chdn",
        },
        e55d91c28c6015643137f30420262d7d: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "verx",
        },
        "677cf5f4770f6c67f5d9a2fd2cccddd9": {
          currency: 7,
          exchange: 4,
          investment: 4,
          stock: "a",
          symbol: "brk",
        },
        "9b925cd6bc516f60991e1513d32820b8": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gpi",
        },
        "0f6f020e1a1a2274767b23cbac85a148": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cldx",
        },
        b8b2b06c32e867ed8105c007b564cbed: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "insw",
        },
        b4ba5362afcdd568f01a418252a9e424: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gshd",
        },
        f77b0809120c176c33d2e95a6adb01e1: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lvs",
        },
        "512209e7055dcceb9e1bf3ab12fc65e4": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "chrw",
        },
        e690f19770279d4080ab3ba62a245f3e: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "str",
        },
        fb416515540a9b5e84858469424e0312: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "saia",
        },
        e4c0120db7c0928b2f0d5885c819a081: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "crwd",
        },
        "02ee1036bd0375a4e49acb59fcf1622c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cvi",
        },
        "58e69f55ebea390aa26b99c287e335a4": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "phin",
        },
        ab2298e84821ed6f3824c40e9b5b9b54: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "kss",
        },
        "98033aa9da2bdab94ad13734962d8852": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sem",
        },
        "31e00ace0a353147cf814bf803bb15eb": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cop",
        },
        "46a614f50e6429b5d005463da00c3053": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fdp",
        },
        "5b1b0ce780f6dd663e0d64a9ae6da64f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "biib",
        },
        "2a89fe3ba8241996e909ba3a14b3d99c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wrby",
        },
        ae9ce9749882cf4e6f063c8ae785a9a6: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "chtr",
        },
        "9d1a9e8a71164d4108842f965421f630": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pkg",
        },
        f922058b91619faa9be0013c76d9c63a: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ph",
        },
        ff9cb86998273b7dacc1ae4344c2ae7c: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hd",
        },
        "346ba4e94d5da46abdcbf62b38d5bd2a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "agio",
        },
        "10733070dcb5e95e74ca276514d22691": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mq",
        },
        d6855aa22b8c77d47d763b98178d04bc: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "deck",
        },
        "4c5d8121f9b5b2afccd7425948eea666": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sq",
        },
        "621f2469c196501bc5ab43d7b615150c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fr",
        },
        f52a21b84c4adddbb9b9b621c44b300d: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dds",
        },
        "4335e8c4d06de3ff3ed44eb72f3136a9": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tgna",
        },
        "7eed1391c52006f3ff53d0d14dcd333e": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "eqr",
        },
        "8f5a505eeb599b104b554d29c03685c2": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mro",
        },
        bbebb04b5f5f166506c1a17c5adbca80: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wba",
        },
        bc4dd3050913e62e92cc0a6ffe2d0457: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pacs",
        },
        "66c804816a416e9fa46e3716a1744310": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "apa",
        },
        bf559cc4b98bba928a30913b51000258: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bke",
        },
        "2f773d8cbb4d3334581a0245f09dbc1f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wly",
        },
        b1a0749a93a6b2c6151b1df80c022b4e: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "alex",
        },
        f4fdb9db779075b1f09c0733bc1ad452: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ahr",
        },
        ffcab38981985f5a39f0b5b5ca6d6762: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "five",
        },
        ed9a85a14ae2619d85482d3da1d66a43: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "zbra",
        },
        "77dce863b17718f63aa56fbe9a01e916": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "crc",
        },
        a6e676cb2581e728ab83fdd10f850dbb: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ufpt",
        },
        "9637f2c4e065a5505543990b813a37c2": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fcfs",
        },
        e99bfcfebad0bfe35d92f913cc9b237d: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rbc",
        },
        "39c0ae528bab8f3eb87585dfc3803e7c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nx",
        },
        "3fe700a78a36b1ee09eff906daefd2ac": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "docn",
        },
        "47f0e02bd82ef99f294b47845cbd5fb1": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "klac",
        },
        "91bed22de0bc1cd58c3424cb32685441": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hst",
        },
        e799c8a9d95c249e5f04c3767caec13f: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "well",
        },
        "8d2533dc6c1c89f2563a5465892cb890": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gwre",
        },
        "5f114b9b70aaeb7009c7b5402574c478": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ce",
        },
        "915e586ad60a6a9b6d66579e49848201": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ttc",
        },
        b01d8964628eff46f9dcfe196f64af73: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tpl",
        },
        a7bfff00203bd33d6782efaeca7cf809: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "low",
        },
        cadac7d839348e79040bfc14ba3a082e: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hes",
        },
        fabe31dab7744f9caa68653e1536365e: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "m",
        },
        "7ef6f85f90864f29c1a5f115ea23ef3b": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gbtg",
        },
        "1d8c0bd4b37b90c719b9c867e51cd0ad": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "chrd",
        },
        "78695a8c705d6355c30dd3e4f98dfe1d": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lbrda",
        },
        "4f183a904837fb811f3ddf63d655b9cb": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gs",
        },
        bce608391b8144fa5f4c7ba9f8ae0e07: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "extr",
        },
        "621a9503f758629469f9377f4efde14c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cg",
        },
        "2fb8e82bc1cc6f31c326c7f80046ee0f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tmo",
        },
        "55d3a08745cfd7c9104f832f07989996": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tdw",
        },
        "1f2842ce87d236557e3040d804f6407f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dg",
        },
        "34f7f3192109258f7c173f316dd09f15": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ovv",
        },
        "7101f13c31eea40d4567a77ca3b0a545": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pinc",
        },
        "07de43e8b0d730415b9a788fbe873383": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "crh",
        },
        c45a3615961ca53b37409648227e4f71: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "swx",
        },
        "7093c036d022cf17122b7a407ee2100c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bx",
        },
        db6122dec43ab5600d3fc056059bcdf5: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "talo",
        },
        b8349f53c26434d786ed204a04c2715c: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "regn",
        },
        f8381e8305e409ccb84f1dde918b8cd8: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rytm",
        },
        "525a37600b3b0f7cadccbceed362031d": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ulta",
        },
        e5d713bda82c7309c815f60c18350977: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "knf",
        },
        f12280576e6f9923556bc3bf951393c5: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dea",
        },
        "900ace6e3ac4c502cb9b9ade995700dc": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bax",
        },
        b4f11d3aeef8da86f9a0edd9a8076df7: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gddy",
        },
        "4b7cf88f2408d54e04861110d4ccf573": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fun",
        },
        a08b559680a7d9908807ab6fc9f05798: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "slvm",
        },
        "0831ea1fbd61a34bf8d43e566aa3908f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "apo",
        },
        d52dac34e09ae27b3130b7c19f5cd3a8: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "asan",
        },
        "7f2eca93267d6031d86b855a2c0ce111": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nxst",
        },
        e588553d5884f6044c2ef4280024d277: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sxt",
        },
        "7e11d707094ae5ed1f30d13b4ac21221": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "njr",
        },
        ac4315c6a45addca0f570da71ee4f515: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "prdo",
        },
        "02105debda59362f47ef6283bb9235d2": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cvx",
        },
        "20c4387199e2a550c37871a2bc9b9a65": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mpwr",
        },
        b37f21819ff90a24663c16c2e938dc33: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "noc",
        },
        "5f7a731dcb64ecad83b7b235a0ad0602": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "eog",
        },
        "99ff24f953baf4226b537b528daf2965": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mtd",
        },
        e7c8dcaa645f455fa91da016439e5f00: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mir",
        },
        "3f40c7ffab9255716ba3b6c8d1052db4": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cck",
        },
        "5ac5cf5676cb41f8da45fe596b692a85": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "an",
        },
        be8a8b19413d745de1b765caf70b4b05: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nwn",
        },
        "5b6c5d3de6857bda68fea0b326cabcf9": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mdu",
        },
        "79deb77d122b5ffafe7ef07cc1959dcc": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dbrg",
        },
        e75c2d9ac36d0987763f040160758197: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "musa",
        },
        d77898a5798df72d413a9c22594f210a: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gnw",
        },
        cfab72b099bc6d40edc3a393162f9699: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "blbd",
        },
        c0ff74b83e5b39db8331086901b09009: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "krg",
        },
        a03cafaafeda1d8e40b006ca1f603a2d: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "doc",
        },
        d6e3a70c587449f4a369c92b1b893570: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "azpn",
        },
        "6247da701a3e63561bacb77efdb52414": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nsa",
        },
        "97108bd0f6b33a8a09fba6f9c4a0b821": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cnm",
        },
        "422d25e6cf1f741d7870da09c8addf15": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ale",
        },
        "96f39443b82e4f85fa3fdbcab7e7ed39": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "csl",
        },
        "68affeb44f699a149cd36edfea724893": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "jwn",
        },
        e06d87d444d320d9db5f7728a830f1c9: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hiw",
        },
        fe450ee52cd3af5d58e8ba7037a2030c: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "zeta",
        },
        "39ebe3d43e18db664877e01428e55d10": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bbwi",
        },
        e98a87d7e88aded5f6db07f83beb184a: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "xom",
        },
        c9ed98aaf558ed0a7205154abc1a1b74: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "coop",
        },
        "3727f9549639fe2e871ea742be76d001": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "oxy",
        },
        a995761246a5627b435a4422337bbc67: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "whr",
        },
        ade96c0db87976053735f35879df92c9: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nuvl",
        },
        "8d6989f5a5b8a7a6470dd0218aad45af": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "osis",
        },
        a6031fa79eeb9a593a8d88930dc3bacf: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wtm",
        },
        "74d5be000f0aeaaefe19a1ebca783987": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "road",
        },
        "98a7d1566bf729d775d0edaefa571cb3": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pega",
        },
        "2988e10b84977bd05a43d790570756cc": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hurn",
        },
        "282b61b565a3e1efa03e278e18ac8bcb": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ceg",
        },
        c45fe8de03763d65b798ecd8a2c6ad96: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rprx",
        },
        "4d3d07f83838669ad71e41063265e607": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "civi",
        },
        "03659320b6aa43191441ca35faa880eb": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rare",
        },
        "4336050ca1e942a2ffb37a240d3c29ec": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "de",
        },
        "30594626a7c6e28acb710a27a65d0b77": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "aclx",
        },
        "17d460b24d8f649cb915fb617d94e237": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cacc",
        },
        e4b5a796a413069bb3f17bf90fcf558d: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ions",
        },
        e16e5f904cbce82533effeb619bfe1ce: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gww",
        },
        dc174cff7b1597c770529a7e9cdf2db3: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dei",
        },
        "8b99e3b796dc57a145b9ac90cb9c620f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lpg",
        },
        "0385b45d0ae6f8b601bed7e914bfb10d": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "are",
        },
        bbc0291634f56e4d165b15956cb5a77f: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wwd",
        },
        cba4cb95a6ccdb481a9a0382f69ad790: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mur",
        },
        c98afbca80579986d5f81ef6e5809065: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "krc",
        },
        "9f6e997e36d883d0312613141dc86b16": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rlj",
        },
        "79f6daf430f5c96ed3d23390704aae1e": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fdx",
        },
        fb47cc6f488412cde9f8068343b83f2b: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dks",
        },
        "7c7712c4e45ad6490ff01d66aabb24e6": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "brkr",
        },
      },
      weights_values: {
        "7f08832a59c22a50db6366fb692ff5b3": -0.00571,
        "53af808d43d695b8a5462d778d87f658": -0.00571,
        "77b4c0cc412a915f647a8e70173f5648": -0.00571,
        "254f582a717b4d2974e2f657e30d249b": 0.025,
        "8f01de55f262b275d46127cbf9ed7f3e": -0.00571,
        abaf75ad6ab947208cafede49ffb07bc: -0.00571,
        "05e229880e25482fd8f089d3fffc15a3": -0.00571,
        fed87f005e587b2d04b965aad7aa7e44: -0.00571,
        d3106aec1bd7ffa3adb9ad5ff74a4f3c: -0.00571,
        e0cae2f49029bfba03d3f6d4d6c4a298: -0.00571,
        a981e42a55eaa4c3550976d585e0662a: 0.025,
        "09b1c429fc0d03a2e30e7354264a5f3e": -0.00571,
        "3ed169ac5f6eee562646fcd8066eb93c": -0.00571,
        cdce272879caf5e4cbb1145134175928: -0.00571,
        "2914b64d13583fc71d660dcd288b1d6a": -0.00571,
        "082f2ab41c1c51ac3f563825c1eb383a": -0.00571,
        e1918051011dbdb3f11cb56d6a278053: -0.00571,
        decf9992d16b362047930197ced8acc2: -0.00571,
        "1421598e4e636414eb17aa77e7820d16": -0.00571,
        "54879bd15f1bceeb1079b286baf0ba2e": -0.00571,
        ae71577b197e1d15bc0c82c1e34e69c4: 0.025,
        "0987f00d16936144a0c9fb58063b1f72": -0.00571,
        "613f5312eb2acb33800600ee357488c7": -0.00571,
        "23bd5c31f08b3144c1c3569544fc6dd1": -0.00571,
        fdbb66a61889f4b636c5d22637c098b0: -0.00571,
        df6edcb07ed68c9587260ab478d97069: 0.025,
        "4e2a4888127aa53f486189630ff32c44": 0.025,
        e7a45262e599d4299e68fc4745523fd0: -0.00571,
        cc8b4b4b8e0f87495d3bf1c979d3bb8e: -0.00571,
        "168a356aa48e0576951f95f7148659fb": -0.00571,
        "34ec186bd35f33382bc07a3de2735b24": -0.00571,
        d4e02411b859263e8facc3c2746f0367: -0.00571,
        "576413110c84e4a42451dc219e7e4add": -0.00571,
        "1d4de01bb7c0d523030b158623435c3d": -0.00571,
        "1d6d3eab68260df496783e256faa69bf": 0.025,
        "5331835b1c467316c4a659c8c654589c": -0.00571,
        "3a0be9547d48cb370a1c17ff9dfc0028": -0.00571,
        c02331f10c3821c67c24486df71d70f9: -0.00571,
        "641e2e68e371c3c04501e1bb11382a71": -0.00571,
        "60553de7def104ac92eca65dc9cd6c8d": 0.025,
        e2327fb5323507963f1ae34da374b2b1: -0.00571,
        cbd52cc79a2b99551cd1f5e130994cdf: -0.00571,
        e398dcd60d8a5ace26c528451816fe02: -0.00571,
        e3ea2e16afcafcea80b5fd4754d9a60e: -0.00571,
        a2af189f363c23cc6498ecc083f35a1d: -0.00571,
        "568ce8bc712a36d1015c45a7eedde394": -0.00571,
        f05886fe787fac12d4c8b6d356dc0f87: -0.00571,
        "5ceceba2c96953b108a6613b201f3f93": -0.00571,
        "84cc37a41c79f4b2ed9d73f2679b9307": -0.00571,
        b27aa80aafb310c826b287254613e308: -0.00571,
        ad82c909843ca41f21a33daf8cb78620: -0.00571,
        "1215bd5fedd05bae8782159b9f71ad77": -0.00571,
        f8aa52c52961340ae36c0374d1894cae: 0.025,
        "7af084200d6cac135482d72b84a79867": -0.00571,
        "027f17f5cf2afc6dd4b3f891c82234ed": -0.00571,
        b51afc171c4c79cddacee0443ae4ffea: -0.00571,
        "8327aa60ac7b853fff1c1eba7b5b79ad": -0.00571,
        "948ecd4dca4174f90ef30e4f7d188cff": -0.00571,
        "3e719cc4944ba276bd13764547f90c63": -0.00571,
        "6d05a928d29ba860b2f8ba58cc50d958": -0.00571,
        "2fe210845544a0ee8220540c3dc35e82": -0.00571,
        "4bffaf645cf7ebf9b2f8476ac68777c5": 0.025,
        "15979f1e2e4421e6eb246e383952d191": -0.00571,
        "2e21a72c81d1aa2dcef0c2c63e7cdb17": 0.025,
        "8f3a7cf316fa65861758cb29d9fb8c55": -0.00571,
        "7793f234983e8351824716d037e612a2": -0.00571,
        db738cfa75b237abe47de695ab13e406: -0.00571,
        "1d39be3dc90e377af5937412ac0df4b4": -0.00571,
        c437a7bf5bf25c07347fa065e5afe313: -0.00571,
        "1d88d2158d9456f0326453066045d536": -0.00571,
        e3452f3fd4f7c30caeb565db7058e446: -0.00571,
        "0d4958e9ef93759f7cc131cd1dc31af5": -0.00571,
        "4c2771d32052e5f876f9487d1d3343aa": -0.00571,
        e55d91c28c6015643137f30420262d7d: 0.025,
        "677cf5f4770f6c67f5d9a2fd2cccddd9": -0.00571,
        "9b925cd6bc516f60991e1513d32820b8": -0.00571,
        "0f6f020e1a1a2274767b23cbac85a148": -0.00571,
        b8b2b06c32e867ed8105c007b564cbed: -0.00571,
        b4ba5362afcdd568f01a418252a9e424: 0.025,
        f77b0809120c176c33d2e95a6adb01e1: 0.025,
        "512209e7055dcceb9e1bf3ab12fc65e4": -0.00571,
        e690f19770279d4080ab3ba62a245f3e: -0.00571,
        fb416515540a9b5e84858469424e0312: 0.025,
        e4c0120db7c0928b2f0d5885c819a081: -0.00571,
        "02ee1036bd0375a4e49acb59fcf1622c": -0.00571,
        "58e69f55ebea390aa26b99c287e335a4": 0.025,
        ab2298e84821ed6f3824c40e9b5b9b54: -0.00571,
        "98033aa9da2bdab94ad13734962d8852": -0.00571,
        "31e00ace0a353147cf814bf803bb15eb": -0.00571,
        "46a614f50e6429b5d005463da00c3053": -0.00571,
        "5b1b0ce780f6dd663e0d64a9ae6da64f": -0.00571,
        "2a89fe3ba8241996e909ba3a14b3d99c": 0.025,
        ae9ce9749882cf4e6f063c8ae785a9a6: -0.00571,
        "9d1a9e8a71164d4108842f965421f630": -0.00571,
        f922058b91619faa9be0013c76d9c63a: -0.00571,
        ff9cb86998273b7dacc1ae4344c2ae7c: -0.00571,
        "346ba4e94d5da46abdcbf62b38d5bd2a": -0.00571,
        "10733070dcb5e95e74ca276514d22691": -0.00571,
        d6855aa22b8c77d47d763b98178d04bc: -0.00571,
        "4c5d8121f9b5b2afccd7425948eea666": 0.025,
        "621f2469c196501bc5ab43d7b615150c": -0.00571,
        f52a21b84c4adddbb9b9b621c44b300d: -0.00571,
        "4335e8c4d06de3ff3ed44eb72f3136a9": -0.00571,
        "7eed1391c52006f3ff53d0d14dcd333e": -0.00571,
        "8f5a505eeb599b104b554d29c03685c2": -0.00571,
        bbebb04b5f5f166506c1a17c5adbca80: -0.00571,
        bc4dd3050913e62e92cc0a6ffe2d0457: 0.025,
        "66c804816a416e9fa46e3716a1744310": -0.00571,
        bf559cc4b98bba928a30913b51000258: -0.00571,
        "2f773d8cbb4d3334581a0245f09dbc1f": -0.00571,
        b1a0749a93a6b2c6151b1df80c022b4e: -0.00571,
        f4fdb9db779075b1f09c0733bc1ad452: -0.00571,
        ffcab38981985f5a39f0b5b5ca6d6762: -0.00571,
        ed9a85a14ae2619d85482d3da1d66a43: -0.00571,
        "77dce863b17718f63aa56fbe9a01e916": -0.00571,
        a6e676cb2581e728ab83fdd10f850dbb: -0.00571,
        "9637f2c4e065a5505543990b813a37c2": 0.025,
        e99bfcfebad0bfe35d92f913cc9b237d: -0.00571,
        "39c0ae528bab8f3eb87585dfc3803e7c": -0.00571,
        "3fe700a78a36b1ee09eff906daefd2ac": -0.00571,
        "47f0e02bd82ef99f294b47845cbd5fb1": -0.00571,
        "91bed22de0bc1cd58c3424cb32685441": -0.00571,
        e799c8a9d95c249e5f04c3767caec13f: 0.025,
        "8d2533dc6c1c89f2563a5465892cb890": -0.00571,
        "5f114b9b70aaeb7009c7b5402574c478": -0.00571,
        "915e586ad60a6a9b6d66579e49848201": -0.00571,
        b01d8964628eff46f9dcfe196f64af73: -0.00571,
        a7bfff00203bd33d6782efaeca7cf809: -0.00571,
        cadac7d839348e79040bfc14ba3a082e: -0.00571,
        fabe31dab7744f9caa68653e1536365e: -0.00571,
        "7ef6f85f90864f29c1a5f115ea23ef3b": -0.00571,
        "1d8c0bd4b37b90c719b9c867e51cd0ad": -0.00571,
        "78695a8c705d6355c30dd3e4f98dfe1d": -0.00571,
        "4f183a904837fb811f3ddf63d655b9cb": -0.00571,
        bce608391b8144fa5f4c7ba9f8ae0e07: 0.025,
        "621a9503f758629469f9377f4efde14c": 0.025,
        "2fb8e82bc1cc6f31c326c7f80046ee0f": -0.00571,
        "55d3a08745cfd7c9104f832f07989996": 0.025,
        "1f2842ce87d236557e3040d804f6407f": -0.00571,
        "34f7f3192109258f7c173f316dd09f15": -0.00571,
        "7101f13c31eea40d4567a77ca3b0a545": -0.00571,
        "07de43e8b0d730415b9a788fbe873383": 0.025,
        c45a3615961ca53b37409648227e4f71: -0.00571,
        "7093c036d022cf17122b7a407ee2100c": 0.025,
        db6122dec43ab5600d3fc056059bcdf5: -0.00571,
        b8349f53c26434d786ed204a04c2715c: -0.00571,
        f8381e8305e409ccb84f1dde918b8cd8: -0.00571,
        "525a37600b3b0f7cadccbceed362031d": -0.00571,
        e5d713bda82c7309c815f60c18350977: 0.025,
        f12280576e6f9923556bc3bf951393c5: -0.00571,
        "900ace6e3ac4c502cb9b9ade995700dc": -0.00571,
        b4f11d3aeef8da86f9a0edd9a8076df7: 0.025,
        "4b7cf88f2408d54e04861110d4ccf573": 0.025,
        a08b559680a7d9908807ab6fc9f05798: -0.00571,
        "0831ea1fbd61a34bf8d43e566aa3908f": 0.025,
        d52dac34e09ae27b3130b7c19f5cd3a8: -0.00571,
        "7f2eca93267d6031d86b855a2c0ce111": -0.00571,
        e588553d5884f6044c2ef4280024d277: -0.00571,
        "7e11d707094ae5ed1f30d13b4ac21221": -0.00571,
        ac4315c6a45addca0f570da71ee4f515: -0.00571,
        "02105debda59362f47ef6283bb9235d2": -0.00571,
        "20c4387199e2a550c37871a2bc9b9a65": -0.00571,
        b37f21819ff90a24663c16c2e938dc33: -0.00571,
        "5f7a731dcb64ecad83b7b235a0ad0602": -0.00571,
        "99ff24f953baf4226b537b528daf2965": -0.00571,
        e7c8dcaa645f455fa91da016439e5f00: -0.00571,
        "3f40c7ffab9255716ba3b6c8d1052db4": -0.00571,
        "5ac5cf5676cb41f8da45fe596b692a85": -0.00571,
        be8a8b19413d745de1b765caf70b4b05: -0.00571,
        "5b6c5d3de6857bda68fea0b326cabcf9": -0.00571,
        "79deb77d122b5ffafe7ef07cc1959dcc": -0.00571,
        e75c2d9ac36d0987763f040160758197: -0.00571,
        d77898a5798df72d413a9c22594f210a: -0.00571,
        cfab72b099bc6d40edc3a393162f9699: 0.025,
        c0ff74b83e5b39db8331086901b09009: -0.00571,
        a03cafaafeda1d8e40b006ca1f603a2d: -0.00571,
        d6e3a70c587449f4a369c92b1b893570: 0.025,
        "6247da701a3e63561bacb77efdb52414": -0.00571,
        "97108bd0f6b33a8a09fba6f9c4a0b821": -0.00571,
        "422d25e6cf1f741d7870da09c8addf15": -0.00571,
        "96f39443b82e4f85fa3fdbcab7e7ed39": -0.00571,
        "68affeb44f699a149cd36edfea724893": -0.00571,
        e06d87d444d320d9db5f7728a830f1c9: -0.00571,
        fe450ee52cd3af5d58e8ba7037a2030c: 0.025,
        "39ebe3d43e18db664877e01428e55d10": -0.00571,
        e98a87d7e88aded5f6db07f83beb184a: -0.00571,
        c9ed98aaf558ed0a7205154abc1a1b74: 0.025,
        "3727f9549639fe2e871ea742be76d001": -0.00571,
        a995761246a5627b435a4422337bbc67: -0.00571,
        ade96c0db87976053735f35879df92c9: -0.00571,
        "8d6989f5a5b8a7a6470dd0218aad45af": -0.00571,
        a6031fa79eeb9a593a8d88930dc3bacf: -0.00571,
        "74d5be000f0aeaaefe19a1ebca783987": 0.025,
        "98a7d1566bf729d775d0edaefa571cb3": 0.025,
        "2988e10b84977bd05a43d790570756cc": 0.025,
        "282b61b565a3e1efa03e278e18ac8bcb": 0.025,
        c45fe8de03763d65b798ecd8a2c6ad96: -0.00571,
        "4d3d07f83838669ad71e41063265e607": -0.00571,
        "03659320b6aa43191441ca35faa880eb": -0.00571,
        "4336050ca1e942a2ffb37a240d3c29ec": -0.00571,
        "30594626a7c6e28acb710a27a65d0b77": -0.00571,
        "17d460b24d8f649cb915fb617d94e237": -0.00571,
        e4b5a796a413069bb3f17bf90fcf558d: -0.00571,
        e16e5f904cbce82533effeb619bfe1ce: -0.00571,
        dc174cff7b1597c770529a7e9cdf2db3: -0.00571,
        "8b99e3b796dc57a145b9ac90cb9c620f": 0.025,
        "0385b45d0ae6f8b601bed7e914bfb10d": -0.00571,
        bbc0291634f56e4d165b15956cb5a77f: 0.025,
        cba4cb95a6ccdb481a9a0382f69ad790: -0.00571,
        c98afbca80579986d5f81ef6e5809065: -0.00571,
        "9f6e997e36d883d0312613141dc86b16": -0.00571,
        "79f6daf430f5c96ed3d23390704aae1e": -0.00571,
        fb47cc6f488412cde9f8068343b83f2b: -0.00571,
        "7c7712c4e45ad6490ff01d66aabb24e6": 0.025,
      },
    },
    aum: 15639521.078613995,
    strategyId: "b3e1a234a66b8d211668139213731875",
  },
  {
    date: 1719435693499,
    name: "CPFactorBW",
    owner: {
      type: 0,
      value: "51ed22746f7ec8b1f513fd2762f60326",
    },
    permissions: {
      default: 1,
    },
    signals: {
      date: 1726452041941,
      strategy: "d16c4685f2e706f9ea6be3dc771b02e6",
      user: "blair@9823capital.com",
      weights_keys: {
        "7f08832a59c22a50db6366fb692ff5b3": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ghc",
        },
        "77b4c0cc412a915f647a8e70173f5648": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tdoc",
        },
        bdd2aa1348362113ce1ef584efb82900: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lstr",
        },
        "15def38dab1dc4ae99376d7754c63882": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "knsa",
        },
        "979af07e8e98ee3563ba3565841cf408": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pen",
        },
        "2597f9109b919d25f15e9de16a2decde": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dbx",
        },
        e0cae2f49029bfba03d3f6d4d6c4a298: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mu",
        },
        f1ca12117b9ae382741b915ae1d65243: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "next",
        },
        f25a643efcec88cc72e79a3664b6b026: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cmg",
        },
        "21edc9a0d754e740d5ad4e2fb06e6698": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dnut",
        },
        f0b9a7b463cbbbbe766430dd23d6d187: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nxrt",
        },
        fe0c7575d9b3224840dd2cf0b221ab04: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ppbi",
        },
        df800436a5fa872d8e7b0ca0dea8884e: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "twlo",
        },
        e6958eac9e9435769f68e8fe05371fc8: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vir",
        },
        e479384e85296d8d8540b8a7504104d3: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ccl",
        },
        e91b80e8dedf4430384f37f3028a8406: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wern",
        },
        "2914b64d13583fc71d660dcd288b1d6a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wd",
        },
        "57e315ecdc80ae62a4b434552b0629ac": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lrcx",
        },
        bc4a4d760874b23f9c3b6f0bc7991147: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "prg",
        },
        "309a21e38ea160347a4c2827a02bd345": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "peb",
        },
        ae71577b197e1d15bc0c82c1e34e69c4: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "on",
        },
        "6c7a075c8e940fce23ab9344828bebae": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vlo",
        },
        "38c9ef7646107f09c66bb8303ac89469": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nfg",
        },
        "23bd5c31f08b3144c1c3569544fc6dd1": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tdg",
        },
        "61b4ebd872f678977daa80e80eabd2c1": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ugi",
        },
        "8266a2b64e9f280ecad81ed0ebca6188": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "crgy",
        },
        "16b706204e38933cb9f81244d2c66e5c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rng",
        },
        c2ca3f0f47d980555a8959a5f2d2cf6b: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "elv",
        },
        e05abe3fac5c033f70c4e3127b2ace51: {
          currency: 7,
          exchange: 4,
          investment: 4,
          stock: "a",
          symbol: "cwen",
        },
        "4974a6969a62a8ee6d8b6675b567fc2e": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wgo",
        },
        b7dc088ba838f5f4f2ec41b519f87567: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "calm",
        },
        "8d2545bdfb1174427c68692d9ba3e90f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "czr",
        },
        "37328544f83ef3cc0e3ddbb8ce59f794": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "aci",
        },
        "168a356aa48e0576951f95f7148659fb": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pool",
        },
        a8004b3de9b58cdd6732f69beb67ecbe: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dow",
        },
        "4fad61acd8517400936f93913ad87719": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vtr",
        },
        b154e95b33b1b970c0dc3ef044e4e481: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "azo",
        },
        "27ce90bf3edec0cf5d281fe66c81a5ca": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mcy",
        },
        "1bf9035c45a40331f92bb3553e91ea42": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pten",
        },
        a92b1fbe52b27553dd4b7165e8ea5674: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wst",
        },
        e461be74fcd9dfa2dd3185036ebdca36: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lfus",
        },
        "0e8e70088b3cb4a1a378d676eb66d1e5": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "agr",
        },
        "4c8f85974f52a59a85ed546787e7562a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "amr",
        },
        "25d8033a7e4c2f9f9ccb26f3f843eb56": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rog",
        },
        "4616c84ff871d36cb9e82a05710f22cb": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rs",
        },
        e398dcd60d8a5ace26c528451816fe02: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rblx",
        },
        e2e6a272e724b7abf8ded1ee0b8ac76a: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dino",
        },
        "6d0dc2fe2752803b6cb5f617ed2e4495": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hayw",
        },
        e36076a79f8eeba331665c10462825f6: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rig",
        },
        bbe17920c9711f5a25a53ded56b5bdb1: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "aal",
        },
        "568ce8bc712a36d1015c45a7eedde394": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fang",
        },
        cc38d1bfeaa3e7f55e06a4cedd1015d1: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "kalu",
        },
        "77b0f28899b669bc7ba8733bcaac3335": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "msgs",
        },
        db9405ab63045f793b5b4d2744857e41: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "jbl",
        },
        "7fb36e094825204ce0c5da57553e3ddb": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ssnc",
        },
        "97875a948d3c098407e00edfb0fd371b": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ccoi",
        },
        bd13726e2f6ebe7bb266e6e2b999bfdf: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ba",
        },
        "10e3e484b413d976d3ec62f189e1a1fb": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lxp",
        },
        d8f4de4a2d32da236e2b85d60808ed31: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "schw",
        },
        b3e44db8b8a0f303a3745f5dceed9c3e: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vac",
        },
        b6756d7bbffabc97d5fe4973a40c823d: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tfx",
        },
        "22cb3673887c02a544885f183abc5402": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "exas",
        },
        "95876353d398e0ad93d0c5f401842209": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "jnj",
        },
        "066784b3833dc3e3bc1a8fa7b7785c92": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pii",
        },
        d98d23bfa1b4f1849cfc8666ce89fc3e: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wbd",
        },
        "25fec0624c29611e03ee981b2ca2ee2d": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mlkn",
        },
        "1d8218d612cdb0e0298c89347cb7f6bf": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "jef",
        },
        "948ecd4dca4174f90ef30e4f7d188cff": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hr",
        },
        "2fe210845544a0ee8220540c3dc35e82": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "jbgs",
        },
        "2e21a72c81d1aa2dcef0c2c63e7cdb17": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mtch",
        },
        "7793f234983e8351824716d037e612a2": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lh",
        },
        "1d39be3dc90e377af5937412ac0df4b4": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fbin",
        },
        "4c2771d32052e5f876f9487d1d3343aa": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "chdn",
        },
        eabaa2c092045d904b97bb6923d10710: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lw",
        },
        "677cf5f4770f6c67f5d9a2fd2cccddd9": {
          currency: 7,
          exchange: 4,
          investment: 4,
          stock: "a",
          symbol: "brk",
        },
        "0f6f020e1a1a2274767b23cbac85a148": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cldx",
        },
        "132464209010285f7a765858a52f39db": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "prk",
        },
        "826d66d0295dbfa1103f9ba8a02fef11": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "slg",
        },
        "83e00048ee3ce56bfb9328025b73ae9a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hmn",
        },
        "703b9b97df6ac6a3f99e1a753c0d7aa0": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lad",
        },
        "90d8e800eb6841d71651024ab23363be": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pcor",
        },
        "169a0860b644ffae02a4e8dc8ecacc7b": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "agco",
        },
        fb416515540a9b5e84858469424e0312: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "saia",
        },
        f365950daba33f2d440107c10bbf658d: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fmc",
        },
        cf01292977ad8f0916d2dd9357144193: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "luv",
        },
        f7705db24604a8808acfb389e1af3d83: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "prct",
        },
        "98033aa9da2bdab94ad13734962d8852": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sem",
        },
        "46a614f50e6429b5d005463da00c3053": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fdp",
        },
        d40234f3260aa9bdec169daa1039529c: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "arch",
        },
        "2bf9099a451722fa3746050c6c59a488": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tfin",
        },
        "274c44e452ceada7cbe1516d530eaa38": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "len",
        },
        ae9ce9749882cf4e6f063c8ae785a9a6: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "chtr",
        },
        c83e8140536ae7f6c5b68f9ca427e4c6: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "eqh",
        },
        "9e259969fa11a118f50130e03d96c1a4": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "val",
        },
        f547f25062c2f4723f20e12ae3f11796: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "laz",
        },
        e00bdf64a6fb68876dda4f153c7bf315: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "amkr",
        },
        "1dad91fc583564343c25f64b0a8d26df": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nmih",
        },
        e5a3b45ed4aa5c4b08ffbe66e1063bb0: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "frpt",
        },
        "7e12daa788bb9b64960f705f44a56daf": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hum",
        },
        "3115204d5c55f69f74d61bd3ab3bfb40": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "el",
        },
        f606db763627d8125e3d8fe424da81e4: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ip",
        },
        bbebb04b5f5f166506c1a17c5adbca80: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wba",
        },
        bfb73e47fd4e9400cce78b44ab921f05: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "kvue",
        },
        dd7aacb0af11cd0d3952aae9931f0e83: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "smg",
        },
        e187a63e28ef7afe9e1d527284d1b3d9: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "intc",
        },
        fb99081f45ec284cd0194b978fb4b8ad: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mcw",
        },
        "6e6b333c6ef930a327ed5faec65ae479": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pdco",
        },
        "1d8b0e986637ab115e4d936e2056154a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ktb",
        },
        c897f9a848c5431ebe14f206ea8e3d0b: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nfe",
        },
        df586f5e4f91eed436999aa3962a8f37: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "stld",
        },
        "77dce863b17718f63aa56fbe9a01e916": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "crc",
        },
        "0271790c3534797f16ee8d575e8a8b0a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "uthr",
        },
        c800add4d2020eb780de113408a574d9: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "neog",
        },
        "31c114f1c8b0eacfd000e0917505d4bf": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "aon",
        },
        "5df4d5ce423de6c463bee335993f7440": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lpx",
        },
        "47f0e02bd82ef99f294b47845cbd5fb1": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "klac",
        },
        cf09f08546998a0db4a069cd36e896a8: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cwh",
        },
        "91bed22de0bc1cd58c3424cb32685441": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hst",
        },
        "878217757685ea9cf3a2d7eb6abc4609": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "act",
        },
        af7041fdb10fb033c3abe36433b72f0c: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ppg",
        },
        "6d6dd09bfbdc81c6935699966e38f548": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tfsl",
        },
        e7d3f1e80cddf3efd61ade5a94f4637c: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mpc",
        },
        c33b80ec81e22838afc1b6d62afc47d0: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vst",
        },
        "9ac73d45f8fab57ceada18c5def4e869": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pcar",
        },
        "4b9d6d396db546b116d52bbe9147eaab": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ups",
        },
        "5a4fa0b51646a34fc3480bb04718bae7": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dxcm",
        },
        "1d8c0bd4b37b90c719b9c867e51cd0ad": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "chrd",
        },
        "4f183a904837fb811f3ddf63d655b9cb": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gs",
        },
        f7271d22f302ba9f5c20d179e36efce2: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "aa",
        },
        "9ce0ddee970cf21b47d7b53fe3610b24": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "two",
        },
        "55d3a08745cfd7c9104f832f07989996": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tdw",
        },
        "3726bbabf743b2b877a8609d242214bf": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "par",
        },
        "600ecf85b71d185c02541266c2935fc3": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "invh",
        },
        "07de43e8b0d730415b9a788fbe873383": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "crh",
        },
        a6a329d16a0833c74d7248bbd89b97b1: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sphr",
        },
        f0a4e42618fd4ba5abcbab87cd31792d: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "iart",
        },
        db6122dec43ab5600d3fc056059bcdf5: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "talo",
        },
        b8349f53c26434d786ed204a04c2715c: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "regn",
        },
        f6b948c145ef90f44a18bfe31e153e8f: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nke",
        },
        "4a7f16f5942fc243610a37fb8a21488e": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "f",
        },
        ede87d2cfe38397b319c79c354ae8693: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "amwd",
        },
        "525a37600b3b0f7cadccbceed362031d": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ulta",
        },
        f41cfd9e4c02ac475e606f9b5eac2f34: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "kw",
        },
        f12280576e6f9923556bc3bf951393c5: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dea",
        },
        a9df25ca783337f929f7d8d1d1578df7: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "x",
        },
        "26ba231e5fe4252ff499e8ac63958303": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cmc",
        },
        b0bada05b907e847660cb39cef1f4449: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "stc",
        },
        "5415f9681b1ba6e9602ba297a691f3ef": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "umh",
        },
        "0831ea1fbd61a34bf8d43e566aa3908f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "apo",
        },
        "2f4b9c2dfb9b0e983405921b7df6575e": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wy",
        },
        a0a4712f5867eb0c4426d190e7e7f4f8: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "jbi",
        },
        "23409ff039d549d38530723496f0cf68": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "site",
        },
        d85aae99819ec8116bf8331171dafa92: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pch",
        },
        "3abef08528a663e6080f6fe592168607": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mlnk",
        },
        efeeb22845b220fca3eeabcd4df0138b: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rhi",
        },
        "91cda8449bbd19176163264f7278c48f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hri",
        },
        e5c8bb4286dfed0eaa7310707a3ab26b: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gpre",
        },
        "8bfd0cf0ce618f8cf130ae9402ab7d14": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "slm",
        },
        "00efb9f97ee17468b2c8b7bea204bd28": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ibkr",
        },
        b37f21819ff90a24663c16c2e938dc33: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "noc",
        },
        "519f9523bd5d47747bf0448eb6e0d55b": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "xpo",
        },
        "71155a4e3d966f68ca8ef8d0806fbb98": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "jpm",
        },
        bc3bf34a0fafef29e4af2b8f6fd22e8f: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ax",
        },
        "8e5614ce3e0a176139a87926c5e5e3b8": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "al",
        },
        "3d2e0ac8a98897e52237039468c71ace": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "banc",
        },
        "5343e8e6511904b5473d3bb0ab49b6b5": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cuz",
        },
        "79deb77d122b5ffafe7ef07cc1959dcc": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dbrg",
        },
        e75c2d9ac36d0987763f040160758197: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "musa",
        },
        "5e30f623c3449f5ebbd2d82fa0748e6b": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "clf",
        },
        "40832b42a351e3e21e21cef0bdea5e81": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mp",
        },
        "4fe5ba5bedf64b2ce5d742d950f079f6": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rely",
        },
        "7c7cd46a3a5246916c5dcf50921a4bbd": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ess",
        },
        a03cafaafeda1d8e40b006ca1f603a2d: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "doc",
        },
        c27f088ff06650c95c8b6952decfe16e: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "oln",
        },
        d6863df6cdc31a37ed23a54609fd913a: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ritm",
        },
        "5ea07b12de0909d1a6da17ddf4e8868b": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "aig",
        },
        "72537f068fd4901e8e9dabb5a1672775": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dash",
        },
        f8ea51e307f9d2aa3fcaf6821aa5967a: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ter",
        },
        f6f4936fa49eb293592aeb99bdd7bc85: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rrr",
        },
        "41846d66148b522f743b066166cb08b8": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "grbk",
        },
        "03cd42b1c5f339e8e1ade3b51dcf1053": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "kfrc",
        },
        "0efa13b9014e7c005f6f96cff50af731": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mac",
        },
        "45ebd69d4cd8db8746193c47f14f7333": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vrnt",
        },
        e06d87d444d320d9db5f7728a830f1c9: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hiw",
        },
        bcb3d46078ebca9fa7af3c68cf66a856: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "crk",
        },
        f124f7980a0ccb423f4ed5d83e8138d9: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bxmt",
        },
        ade96c0db87976053735f35879df92c9: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nuvl",
        },
        a995761246a5627b435a4422337bbc67: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "whr",
        },
        cc5c345494767267f3fe1171c43c9584: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "alb",
        },
        "49f25355968d030f13d389327afd6d42": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "skx",
        },
        "0fcd3d322b1d7d8b87ff279601ef087d": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dlr",
        },
        d3de9cd64748cace71a3310b8174ade9: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ctva",
        },
        "86758096421d6ba5abb4f29f6872c44b": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vno",
        },
        ccd1905e9a5e96423b24aa1ea07986bb: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tbbk",
        },
        ecf29fdb6e65310d6396539f32913d66: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fybr",
        },
        "4f92ef18dd2f1d991f72a367acdda10c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "scs",
        },
        "2975e22ce222fa8493c8435663e502d1": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "scl",
        },
        "5eaccae44f4a985fd5ac979f103f2bad": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sndx",
        },
        f2470cd00db3d9bbb122200a5d98c043: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dk",
        },
        "318ead0d28dcab3136a0241fc4eef88a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ual",
        },
        daf8fe077f15280617857641207d3f9c: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "msm",
        },
        "17d460b24d8f649cb915fb617d94e237": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cacc",
        },
        e4b5a796a413069bb3f17bf90fcf558d: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ions",
        },
        ea328a0c15a89c9aeb01b0722633a1b9: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bc",
        },
        dc174cff7b1597c770529a7e9cdf2db3: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dei",
        },
        "697c377033e77b95336c5cb624e360b6": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "inst",
        },
        "443649807592ae98c83e935bc52881ad": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nue",
        },
        c98afbca80579986d5f81ef6e5809065: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "krc",
        },
        e93ec1f3ab2013e1e9f9280417a4bbbf: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vre",
        },
        af8abc4f5a5cc34f93f2b72d172195c2: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mth",
        },
        b9eaed9220cd519fe885bf6b822b0ad5: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mrcy",
        },
        "9f6e997e36d883d0312613141dc86b16": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rlj",
        },
        "79f6daf430f5c96ed3d23390704aae1e": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fdx",
        },
        "65fedfd9e8a381761f7e37e2bb137f3d": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "azta",
        },
        "942ee6f9b71b1413be83c8689e8e6c3e": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rgen",
        },
      },
      weights_values: {
        "7f08832a59c22a50db6366fb692ff5b3": -0.00571428571428571,
        "77b4c0cc412a915f647a8e70173f5648": -0.00571428571428571,
        bdd2aa1348362113ce1ef584efb82900: -0.00571428571428571,
        "15def38dab1dc4ae99376d7754c63882": -0.00571428571428571,
        "979af07e8e98ee3563ba3565841cf408": -0.00571428571428571,
        "2597f9109b919d25f15e9de16a2decde": 0.025,
        e0cae2f49029bfba03d3f6d4d6c4a298: 0.025,
        f1ca12117b9ae382741b915ae1d65243: -0.00571428571428571,
        f25a643efcec88cc72e79a3664b6b026: -0.00571428571428571,
        "21edc9a0d754e740d5ad4e2fb06e6698": -0.00571428571428571,
        f0b9a7b463cbbbbe766430dd23d6d187: -0.00571428571428571,
        fe0c7575d9b3224840dd2cf0b221ab04: -0.00571428571428571,
        df800436a5fa872d8e7b0ca0dea8884e: 0.025,
        e6958eac9e9435769f68e8fe05371fc8: -0.00571428571428571,
        e479384e85296d8d8540b8a7504104d3: 0.025,
        e91b80e8dedf4430384f37f3028a8406: -0.00571428571428571,
        "2914b64d13583fc71d660dcd288b1d6a": -0.00571428571428571,
        "57e315ecdc80ae62a4b434552b0629ac": -0.00571428571428571,
        bc4a4d760874b23f9c3b6f0bc7991147: 0.025,
        "309a21e38ea160347a4c2827a02bd345": -0.00571428571428571,
        ae71577b197e1d15bc0c82c1e34e69c4: 0.025,
        "6c7a075c8e940fce23ab9344828bebae": -0.00571428571428571,
        "38c9ef7646107f09c66bb8303ac89469": -0.00571428571428571,
        "23bd5c31f08b3144c1c3569544fc6dd1": -0.00571428571428571,
        "61b4ebd872f678977daa80e80eabd2c1": -0.00571428571428571,
        "8266a2b64e9f280ecad81ed0ebca6188": -0.00571428571428571,
        "16b706204e38933cb9f81244d2c66e5c": 0.025,
        c2ca3f0f47d980555a8959a5f2d2cf6b: -0.00571428571428571,
        e05abe3fac5c033f70c4e3127b2ace51: -0.00571428571428571,
        "4974a6969a62a8ee6d8b6675b567fc2e": 0.025,
        b7dc088ba838f5f4f2ec41b519f87567: -0.00571428571428571,
        "8d2545bdfb1174427c68692d9ba3e90f": -0.00571428571428571,
        "37328544f83ef3cc0e3ddbb8ce59f794": -0.00571428571428571,
        "168a356aa48e0576951f95f7148659fb": -0.00571428571428571,
        a8004b3de9b58cdd6732f69beb67ecbe: -0.00571428571428571,
        "4fad61acd8517400936f93913ad87719": -0.00571428571428571,
        b154e95b33b1b970c0dc3ef044e4e481: -0.00571428571428571,
        "27ce90bf3edec0cf5d281fe66c81a5ca": -0.00571428571428571,
        "1bf9035c45a40331f92bb3553e91ea42": -0.00571428571428571,
        a92b1fbe52b27553dd4b7165e8ea5674: -0.00571428571428571,
        e461be74fcd9dfa2dd3185036ebdca36: -0.00571428571428571,
        "0e8e70088b3cb4a1a378d676eb66d1e5": -0.00571428571428571,
        "4c8f85974f52a59a85ed546787e7562a": -0.00571428571428571,
        "25d8033a7e4c2f9f9ccb26f3f843eb56": -0.00571428571428571,
        "4616c84ff871d36cb9e82a05710f22cb": -0.00571428571428571,
        e398dcd60d8a5ace26c528451816fe02: -0.00571428571428571,
        e2e6a272e724b7abf8ded1ee0b8ac76a: -0.00571428571428571,
        "6d0dc2fe2752803b6cb5f617ed2e4495": -0.00571428571428571,
        e36076a79f8eeba331665c10462825f6: -0.00571428571428571,
        bbe17920c9711f5a25a53ded56b5bdb1: -0.00571428571428571,
        "568ce8bc712a36d1015c45a7eedde394": -0.00571428571428571,
        cc38d1bfeaa3e7f55e06a4cedd1015d1: -0.00571428571428571,
        "77b0f28899b669bc7ba8733bcaac3335": -0.00571428571428571,
        db9405ab63045f793b5b4d2744857e41: 0.025,
        "7fb36e094825204ce0c5da57553e3ddb": 0.025,
        "97875a948d3c098407e00edfb0fd371b": -0.00571428571428571,
        bd13726e2f6ebe7bb266e6e2b999bfdf: -0.00571428571428571,
        "10e3e484b413d976d3ec62f189e1a1fb": -0.00571428571428571,
        d8f4de4a2d32da236e2b85d60808ed31: -0.00571428571428571,
        b3e44db8b8a0f303a3745f5dceed9c3e: -0.00571428571428571,
        b6756d7bbffabc97d5fe4973a40c823d: 0.025,
        "22cb3673887c02a544885f183abc5402": -0.00571428571428571,
        "95876353d398e0ad93d0c5f401842209": -0.00571428571428571,
        "066784b3833dc3e3bc1a8fa7b7785c92": -0.00571428571428571,
        d98d23bfa1b4f1849cfc8666ce89fc3e: -0.00571428571428571,
        "25fec0624c29611e03ee981b2ca2ee2d": 0.025,
        "1d8218d612cdb0e0298c89347cb7f6bf": 0.025,
        "948ecd4dca4174f90ef30e4f7d188cff": -0.00571428571428571,
        "2fe210845544a0ee8220540c3dc35e82": -0.00571428571428571,
        "2e21a72c81d1aa2dcef0c2c63e7cdb17": -0.00571428571428571,
        "7793f234983e8351824716d037e612a2": -0.00571428571428571,
        "1d39be3dc90e377af5937412ac0df4b4": -0.00571428571428571,
        "4c2771d32052e5f876f9487d1d3343aa": -0.00571428571428571,
        eabaa2c092045d904b97bb6923d10710: 0.025,
        "677cf5f4770f6c67f5d9a2fd2cccddd9": -0.00571428571428571,
        "0f6f020e1a1a2274767b23cbac85a148": -0.00571428571428571,
        "132464209010285f7a765858a52f39db": -0.00571428571428571,
        "826d66d0295dbfa1103f9ba8a02fef11": -0.00571428571428571,
        "83e00048ee3ce56bfb9328025b73ae9a": -0.00571428571428571,
        "703b9b97df6ac6a3f99e1a753c0d7aa0": -0.00571428571428571,
        "90d8e800eb6841d71651024ab23363be": -0.00571428571428571,
        "169a0860b644ffae02a4e8dc8ecacc7b": -0.00571428571428571,
        fb416515540a9b5e84858469424e0312: -0.00571428571428571,
        f365950daba33f2d440107c10bbf658d: -0.00571428571428571,
        cf01292977ad8f0916d2dd9357144193: -0.00571428571428571,
        f7705db24604a8808acfb389e1af3d83: -0.00571428571428571,
        "98033aa9da2bdab94ad13734962d8852": -0.00571428571428571,
        "46a614f50e6429b5d005463da00c3053": -0.00571428571428571,
        d40234f3260aa9bdec169daa1039529c: -0.00571428571428571,
        "2bf9099a451722fa3746050c6c59a488": -0.00571428571428571,
        "274c44e452ceada7cbe1516d530eaa38": 0.025,
        ae9ce9749882cf4e6f063c8ae785a9a6: -0.00571428571428571,
        c83e8140536ae7f6c5b68f9ca427e4c6: 0.025,
        "9e259969fa11a118f50130e03d96c1a4": 0.025,
        f547f25062c2f4723f20e12ae3f11796: -0.00571428571428571,
        e00bdf64a6fb68876dda4f153c7bf315: -0.00571428571428571,
        "1dad91fc583564343c25f64b0a8d26df": 0.025,
        e5a3b45ed4aa5c4b08ffbe66e1063bb0: -0.00571428571428571,
        "7e12daa788bb9b64960f705f44a56daf": -0.00571428571428571,
        "3115204d5c55f69f74d61bd3ab3bfb40": -0.00571428571428571,
        f606db763627d8125e3d8fe424da81e4: -0.00571428571428571,
        bbebb04b5f5f166506c1a17c5adbca80: -0.00571428571428571,
        bfb73e47fd4e9400cce78b44ab921f05: 0.025,
        dd7aacb0af11cd0d3952aae9931f0e83: -0.00571428571428571,
        e187a63e28ef7afe9e1d527284d1b3d9: -0.00571428571428571,
        fb99081f45ec284cd0194b978fb4b8ad: -0.00571428571428571,
        "6e6b333c6ef930a327ed5faec65ae479": 0.025,
        "1d8b0e986637ab115e4d936e2056154a": 0.025,
        c897f9a848c5431ebe14f206ea8e3d0b: -0.00571428571428571,
        df586f5e4f91eed436999aa3962a8f37: -0.00571428571428571,
        "77dce863b17718f63aa56fbe9a01e916": -0.00571428571428571,
        "0271790c3534797f16ee8d575e8a8b0a": -0.00571428571428571,
        c800add4d2020eb780de113408a574d9: 0.025,
        "31c114f1c8b0eacfd000e0917505d4bf": -0.00571428571428571,
        "5df4d5ce423de6c463bee335993f7440": -0.00571428571428571,
        "47f0e02bd82ef99f294b47845cbd5fb1": -0.00571428571428571,
        cf09f08546998a0db4a069cd36e896a8: -0.00571428571428571,
        "91bed22de0bc1cd58c3424cb32685441": -0.00571428571428571,
        "878217757685ea9cf3a2d7eb6abc4609": 0.025,
        af7041fdb10fb033c3abe36433b72f0c: -0.00571428571428571,
        "6d6dd09bfbdc81c6935699966e38f548": -0.00571428571428571,
        e7d3f1e80cddf3efd61ade5a94f4637c: -0.00571428571428571,
        c33b80ec81e22838afc1b6d62afc47d0: -0.00571428571428571,
        "9ac73d45f8fab57ceada18c5def4e869": -0.00571428571428571,
        "4b9d6d396db546b116d52bbe9147eaab": -0.00571428571428571,
        "5a4fa0b51646a34fc3480bb04718bae7": -0.00571428571428571,
        "1d8c0bd4b37b90c719b9c867e51cd0ad": -0.00571428571428571,
        "4f183a904837fb811f3ddf63d655b9cb": -0.00571428571428571,
        f7271d22f302ba9f5c20d179e36efce2: -0.00571428571428571,
        "9ce0ddee970cf21b47d7b53fe3610b24": -0.00571428571428571,
        "55d3a08745cfd7c9104f832f07989996": 0.025,
        "3726bbabf743b2b877a8609d242214bf": -0.00571428571428571,
        "600ecf85b71d185c02541266c2935fc3": -0.00571428571428571,
        "07de43e8b0d730415b9a788fbe873383": 0.025,
        a6a329d16a0833c74d7248bbd89b97b1: -0.00571428571428571,
        f0a4e42618fd4ba5abcbab87cd31792d: -0.00571428571428571,
        db6122dec43ab5600d3fc056059bcdf5: -0.00571428571428571,
        b8349f53c26434d786ed204a04c2715c: -0.00571428571428571,
        f6b948c145ef90f44a18bfe31e153e8f: 0.025,
        "4a7f16f5942fc243610a37fb8a21488e": -0.00571428571428571,
        ede87d2cfe38397b319c79c354ae8693: 0.025,
        "525a37600b3b0f7cadccbceed362031d": -0.00571428571428571,
        f41cfd9e4c02ac475e606f9b5eac2f34: -0.00571428571428571,
        f12280576e6f9923556bc3bf951393c5: -0.00571428571428571,
        a9df25ca783337f929f7d8d1d1578df7: -0.00571428571428571,
        "26ba231e5fe4252ff499e8ac63958303": 0.025,
        b0bada05b907e847660cb39cef1f4449: -0.00571428571428571,
        "5415f9681b1ba6e9602ba297a691f3ef": -0.00571428571428571,
        "0831ea1fbd61a34bf8d43e566aa3908f": 0.025,
        "2f4b9c2dfb9b0e983405921b7df6575e": -0.00571428571428571,
        a0a4712f5867eb0c4426d190e7e7f4f8: 0.025,
        "23409ff039d549d38530723496f0cf68": -0.00571428571428571,
        d85aae99819ec8116bf8331171dafa92: -0.00571428571428571,
        "3abef08528a663e6080f6fe592168607": -0.00571428571428571,
        efeeb22845b220fca3eeabcd4df0138b: -0.00571428571428571,
        "91cda8449bbd19176163264f7278c48f": 0.025,
        e5c8bb4286dfed0eaa7310707a3ab26b: -0.00571428571428571,
        "8bfd0cf0ce618f8cf130ae9402ab7d14": -0.00571428571428571,
        "00efb9f97ee17468b2c8b7bea204bd28": 0.025,
        b37f21819ff90a24663c16c2e938dc33: -0.00571428571428571,
        "519f9523bd5d47747bf0448eb6e0d55b": -0.00571428571428571,
        "71155a4e3d966f68ca8ef8d0806fbb98": -0.00571428571428571,
        bc3bf34a0fafef29e4af2b8f6fd22e8f: 0.025,
        "8e5614ce3e0a176139a87926c5e5e3b8": -0.00571428571428571,
        "3d2e0ac8a98897e52237039468c71ace": -0.00571428571428571,
        "5343e8e6511904b5473d3bb0ab49b6b5": -0.00571428571428571,
        "79deb77d122b5ffafe7ef07cc1959dcc": -0.00571428571428571,
        e75c2d9ac36d0987763f040160758197: -0.00571428571428571,
        "5e30f623c3449f5ebbd2d82fa0748e6b": -0.00571428571428571,
        "40832b42a351e3e21e21cef0bdea5e81": -0.00571428571428571,
        "4fe5ba5bedf64b2ce5d742d950f079f6": -0.00571428571428571,
        "7c7cd46a3a5246916c5dcf50921a4bbd": -0.00571428571428571,
        a03cafaafeda1d8e40b006ca1f603a2d: -0.00571428571428571,
        c27f088ff06650c95c8b6952decfe16e: -0.00571428571428571,
        d6863df6cdc31a37ed23a54609fd913a: 0.025,
        "5ea07b12de0909d1a6da17ddf4e8868b": -0.00571428571428571,
        "72537f068fd4901e8e9dabb5a1672775": -0.00571428571428571,
        f8ea51e307f9d2aa3fcaf6821aa5967a: -0.00571428571428571,
        f6f4936fa49eb293592aeb99bdd7bc85: -0.00571428571428571,
        "41846d66148b522f743b066166cb08b8": 0.025,
        "03cd42b1c5f339e8e1ade3b51dcf1053": -0.00571428571428571,
        "0efa13b9014e7c005f6f96cff50af731": -0.00571428571428571,
        "45ebd69d4cd8db8746193c47f14f7333": 0.025,
        e06d87d444d320d9db5f7728a830f1c9: -0.00571428571428571,
        bcb3d46078ebca9fa7af3c68cf66a856: -0.00571428571428571,
        f124f7980a0ccb423f4ed5d83e8138d9: -0.00571428571428571,
        ade96c0db87976053735f35879df92c9: -0.00571428571428571,
        a995761246a5627b435a4422337bbc67: -0.00571428571428571,
        cc5c345494767267f3fe1171c43c9584: -0.00571428571428571,
        "49f25355968d030f13d389327afd6d42": 0.025,
        "0fcd3d322b1d7d8b87ff279601ef087d": -0.00571428571428571,
        d3de9cd64748cace71a3310b8174ade9: -0.00571428571428571,
        "86758096421d6ba5abb4f29f6872c44b": -0.00571428571428571,
        ccd1905e9a5e96423b24aa1ea07986bb: 0.025,
        ecf29fdb6e65310d6396539f32913d66: -0.00571428571428571,
        "4f92ef18dd2f1d991f72a367acdda10c": 0.025,
        "2975e22ce222fa8493c8435663e502d1": -0.00571428571428571,
        "5eaccae44f4a985fd5ac979f103f2bad": -0.00571428571428571,
        f2470cd00db3d9bbb122200a5d98c043: -0.00571428571428571,
        "318ead0d28dcab3136a0241fc4eef88a": -0.00571428571428571,
        daf8fe077f15280617857641207d3f9c: 0.025,
        "17d460b24d8f649cb915fb617d94e237": -0.00571428571428571,
        e4b5a796a413069bb3f17bf90fcf558d: -0.00571428571428571,
        ea328a0c15a89c9aeb01b0722633a1b9: -0.00571428571428571,
        dc174cff7b1597c770529a7e9cdf2db3: -0.00571428571428571,
        "697c377033e77b95336c5cb624e360b6": -0.00571428571428571,
        "443649807592ae98c83e935bc52881ad": -0.00571428571428571,
        c98afbca80579986d5f81ef6e5809065: -0.00571428571428571,
        e93ec1f3ab2013e1e9f9280417a4bbbf: -0.00571428571428571,
        af8abc4f5a5cc34f93f2b72d172195c2: -0.00571428571428571,
        b9eaed9220cd519fe885bf6b822b0ad5: -0.00571428571428571,
        "9f6e997e36d883d0312613141dc86b16": -0.00571428571428571,
        "79f6daf430f5c96ed3d23390704aae1e": -0.00571428571428571,
        "65fedfd9e8a381761f7e37e2bb137f3d": -0.00571428571428571,
        "942ee6f9b71b1413be83c8689e8e6c3e": -0.00571428571428571,
      },
    },
    aum: 0,
    strategyId: "d16c4685f2e706f9ea6be3dc771b02e6",
  },
  {
    date: 1716220059716,
    name: "CPFactorLO",
    owner: {
      type: 0,
      value: "51ed22746f7ec8b1f513fd2762f60326",
    },
    permissions: {
      default: 1,
    },
    aum: 0,
    strategyId: "e6dbb3fbf352ced659a78cf862956350",
  },
  {
    date: 1698869611451,
    name: "CPFactorSPX",
    owner: {
      type: 0,
      value: "51ed22746f7ec8b1f513fd2762f60326",
    },
    permissions: {
      default: 1,
    },
    aum: -0.0031860038387776513,
    strategyId: "462b717c949f2d82f0c98a3ff61adcc5",
  },
  {
    date: 1719440793016,
    name: "DuelMo30",
    owner: {
      type: 0,
      value: "51ed22746f7ec8b1f513fd2762f60326",
    },
    permissions: {
      default: 1,
    },
    aum: -0.0004889257252216339,
    strategyId: "9d166db55742ad53ea9e3842ecc49f10",
  },
  {
    date: 1719460853407,
    name: "Energize",
    owner: {
      type: 0,
      value: "51ed22746f7ec8b1f513fd2762f60326",
    },
    permissions: {
      default: 1,
    },
    aum: 7394980.474648006,
    strategyId: "4c4414cfe376eea8e3d148f964dc14da",
  },
  {
    date: 1719460989629,
    name: "Fletcher",
    owner: {
      type: 0,
      value: "51ed22746f7ec8b1f513fd2762f60326",
    },
    permissions: {
      default: 1,
    },
    aum: -0.001873998077935539,
    strategyId: "fdaa9ce51d461c9974d243cea1886d24",
  },
  {
    date: 1719461102068,
    name: "GSSTS",
    owner: {
      type: 0,
      value: "51ed22746f7ec8b1f513fd2762f60326",
    },
    permissions: {
      default: 1,
    },
    aum: 4927830.654000003,
    strategyId: "525de7b70d72b2370d7043cd8b2ad00b",
  },
  {
    date: 1719857775004,
    name: "ICR",
    owner: {
      type: 3,
      value: "bluter@me.com",
    },
    permissions: {
      default: 1,
    },
    description: "ICRprecept",
    signals: {
      date: 1728396911786,
      strategy: "819363253bb18f7dee3d656c8dfe44b9",
      user: "marshall@9823capital.com",
      weights_keys: {
        "1fd5dd065fa94a0a69a65261b5c27dfb": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vnce",
        },
        "9a3328c364ff240adc0d76e399e0cddd": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fnko",
        },
        "8e5ee7daf364ecf25c18b5afa28e8ab3": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bw",
        },
        "503ba0a88f25499476897dd6cde47332": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "usna",
        },
        ed1c02b29759516d40fa012805fb34c4: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "jack",
        },
        ffcab38981985f5a39f0b5b5ca6d6762: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "five",
        },
        "252fad39d25a7c44c8ece98c10adee18": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sabr",
        },
        "55a5a672e3cf35dd40edc7d39783daf0": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "open",
        },
        b3f5cdee49e42e96bae83c7342a6cfe3: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nine",
        },
        "8bfd0cf0ce618f8cf130ae9402ab7d14": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "slm",
        },
        "3f827bbc169748e8355840b0efc735ac": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cava",
        },
        "94333a63651f1837300e805149d5eda6": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "farm",
        },
        "6a4a205b992a014ca0535a3555086d11": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "krus",
        },
        "33d746cb489cabf7f3996bff90fdf8d6": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "shak",
        },
        "24ea959e2f5ac27a9ba95e17e5a965e2": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ewcz",
        },
        "4782fc80cd8529b2340c9e44dfd6e3da": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "anf",
        },
        "1829b4cde5ffb7e71ea0d9aee75d975e": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "xpof",
        },
        "119fd9174cf0dc8a9ed2e139ab3696ad": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "avo",
        },
        "5d19ffb5bb6f3b6a08cdae0d51e6d2c8": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "snd",
        },
        "47f72d01260c325eabf6ea4eacaa79b0": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pet",
        },
        f0b9a7b463cbbbbe766430dd23d6d187: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nxrt",
        },
        "2e21a72c81d1aa2dcef0c2c63e7cdb17": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mtch",
        },
        "3ed169ac5f6eee562646fcd8066eb93c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "oxm",
        },
        e799c8a9d95c249e5f04c3767caec13f: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "well",
        },
        df0b333bc2bcbd384a16268335821d09: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "woof",
        },
        "2914b64d13583fc71d660dcd288b1d6a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wd",
        },
        cc71b9d8be96bfa7f4b23ed20dfbe557: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "astlw",
        },
        f80840dd2988731acb7026cc92cf5b35: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rei",
        },
        c1d446fd038fdaf4f28ae36b4c21b65f: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hpp",
        },
        "980c81c9930abe1ad2c93d63c01ffb3d": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nus",
        },
        ab93ec2caec52070349abb05710cc882: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lfvn",
        },
        b4ba5362afcdd568f01a418252a9e424: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "gshd",
        },
        "64b20cf73dafc40b3b0ca6be94d97b7f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "chwy",
        },
        "8b91c8c0cf04cb69c511f578ea862753": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "opfi",
        },
        b1ddbc298d5a46342bb2d64bd0a33f45: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "yeti",
        },
        "65b42bcd5f99250a8963cf6f3dec3221": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wulf",
        },
        "4a28369e603a65223da2fce02a168012": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "celh",
        },
        "600ecf85b71d185c02541266c2935fc3": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "invh",
        },
        "18761e2a56cdf97bb37360dae5aa1270": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bmbl",
        },
        b69a5e48b94208912be9bbc1340c36c2: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "corz",
        },
        b6c5a421ea17fcb727caec483044cb07: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bros",
        },
        "488642d861c1f920b7e1720119ff8aaa": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "qrtea",
        },
        "383b22b55b859fb964ecbcef861165dc": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "riot",
        },
        cc3d8768d80d2b54e91daf341264497c: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ndls",
        },
        f826e217a69b67b678c84485c0447962: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "osw",
        },
        "6d6746cbed604797b01e71bbd749af8a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "astl",
        },
        ae73f98bc9f12b7f6d49f2aa48ee98e5: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mnst",
        },
        "6a0853f6567ec5b14eb47abdbb0f6919": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rdfn",
        },
        "6a03231a8fc375aedc098b4447235d5b": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "spru",
        },
        bef61864b88a584ed571253d8890ebe5: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "eols",
        },
        "9cd0587020686999338f44d6e217b629": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "hov",
        },
        d9896278d850b0c3e0e8816470b414cf: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "msft",
        },
        cd460d63c748b92dcca0e9da14327018: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "iren",
        },
        "952b13b4b8e3ec6197a2c188c5a9d023": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "stks",
        },
        "2cda8d0a32e2d10925e28ecb10553bb7": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "prts",
        },
        e5a3b45ed4aa5c4b08ffbe66e1063bb0: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "frpt",
        },
        d005c69482f157944ba6ba4e34492201: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "grnd",
        },
        dc174cff7b1597c770529a7e9cdf2db3: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dei",
        },
        "594cc479f3b051f38811b699f8aea67c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cco",
        },
        "49bd37cb5948c3a15a30d2f5528f9f09": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rekr",
        },
        "7eed1391c52006f3ff53d0d14dcd333e": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "eqr",
        },
        e93ec1f3ab2013e1e9f9280417a4bbbf: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vre",
        },
        a9c66ae6c21527b0a70cc24db15258d3: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cdxc",
        },
        "0ef0e9eac8c136f003037f7b7e49a655": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vsat",
        },
        "2dd2fe0112e5c618520dba9e35e28a43": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ptlo",
        },
        "4cd20d30717d193038efd7f2b20e4782": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fat",
        },
      },
      weights_values: {
        "1fd5dd065fa94a0a69a65261b5c27dfb": 0.002,
        "9a3328c364ff240adc0d76e399e0cddd": -0.021400000000000002,
        "8e5ee7daf364ecf25c18b5afa28e8ab3": 0.1333,
        "503ba0a88f25499476897dd6cde47332": -0.053899999999999997,
        ed1c02b29759516d40fa012805fb34c4: -0.0195,
        ffcab38981985f5a39f0b5b5ca6d6762: -0.06709999999999999,
        "252fad39d25a7c44c8ece98c10adee18": 0.09,
        "55a5a672e3cf35dd40edc7d39783daf0": -0.0189,
        b3f5cdee49e42e96bae83c7342a6cfe3: 0.0161,
        "8bfd0cf0ce618f8cf130ae9402ab7d14": -0.0347,
        "3f827bbc169748e8355840b0efc735ac": -0.10310000000000001,
        "94333a63651f1837300e805149d5eda6": 0.0037,
        "6a4a205b992a014ca0535a3555086d11": 0.05,
        "33d746cb489cabf7f3996bff90fdf8d6": 0.04,
        "24ea959e2f5ac27a9ba95e17e5a965e2": -0.0438,
        "4782fc80cd8529b2340c9e44dfd6e3da": 0.0346,
        "1829b4cde5ffb7e71ea0d9aee75d975e": 0.025,
        "119fd9174cf0dc8a9ed2e139ab3696ad": -0.025699999999999997,
        "5d19ffb5bb6f3b6a08cdae0d51e6d2c8": 0.0048,
        "47f72d01260c325eabf6ea4eacaa79b0": 0.0147,
        f0b9a7b463cbbbbe766430dd23d6d187: -0.0087,
        "2e21a72c81d1aa2dcef0c2c63e7cdb17": 0.0301,
        "3ed169ac5f6eee562646fcd8066eb93c": -0.02,
        e799c8a9d95c249e5f04c3767caec13f: -0.0206,
        df0b333bc2bcbd384a16268335821d09: 0.12,
        "2914b64d13583fc71d660dcd288b1d6a": -0.0121,
        cc71b9d8be96bfa7f4b23ed20dfbe557: 0.01,
        f80840dd2988731acb7026cc92cf5b35: 0.01,
        c1d446fd038fdaf4f28ae36b4c21b65f: -0.02,
        "980c81c9930abe1ad2c93d63c01ffb3d": -0.021,
        ab93ec2caec52070349abb05710cc882: -0.0054,
        b4ba5362afcdd568f01a418252a9e424: -0.0274,
        "64b20cf73dafc40b3b0ca6be94d97b7f": -0.0434,
        "8b91c8c0cf04cb69c511f578ea862753": -0.030600000000000002,
        b1ddbc298d5a46342bb2d64bd0a33f45: -0.0723,
        "65b42bcd5f99250a8963cf6f3dec3221": 0.0681,
        "4a28369e603a65223da2fce02a168012": -0.0768,
        "600ecf85b71d185c02541266c2935fc3": -0.0111,
        "18761e2a56cdf97bb37360dae5aa1270": 0.01,
        b69a5e48b94208912be9bbc1340c36c2: 0.0431,
        b6c5a421ea17fcb727caec483044cb07: -0.03,
        "488642d861c1f920b7e1720119ff8aaa": 0.0431,
        "383b22b55b859fb964ecbcef861165dc": 0.04769999999999999,
        cc3d8768d80d2b54e91daf341264497c: 0.01,
        f826e217a69b67b678c84485c0447962: -0.028399999999999998,
        "6d6746cbed604797b01e71bbd749af8a": 0.0361,
        ae73f98bc9f12b7f6d49f2aa48ee98e5: -0.0454,
        "6a0853f6567ec5b14eb47abdbb0f6919": -0.0363,
        "6a03231a8fc375aedc098b4447235d5b": 0.0028000000000000004,
        bef61864b88a584ed571253d8890ebe5: -0.006999999999999999,
        "9cd0587020686999338f44d6e217b629": 0.04769999999999999,
        d9896278d850b0c3e0e8816470b414cf: 0.01,
        cd460d63c748b92dcca0e9da14327018: 0.0756,
        "952b13b4b8e3ec6197a2c188c5a9d023": 0.0109,
        "2cda8d0a32e2d10925e28ecb10553bb7": 0.01,
        e5a3b45ed4aa5c4b08ffbe66e1063bb0: -0.024300000000000002,
        d005c69482f157944ba6ba4e34492201: -0.0337,
        dc174cff7b1597c770529a7e9cdf2db3: -0.0352,
        "594cc479f3b051f38811b699f8aea67c": 0.02,
        "49bd37cb5948c3a15a30d2f5528f9f09": 0.013000000000000001,
        "7eed1391c52006f3ff53d0d14dcd333e": -0.043,
        e93ec1f3ab2013e1e9f9280417a4bbbf: -0.011200000000000002,
        a9c66ae6c21527b0a70cc24db15258d3: -0.012,
        "0ef0e9eac8c136f003037f7b7e49a655": 0.06,
        "2dd2fe0112e5c618520dba9e35e28a43": -0.0206,
        "4cd20d30717d193038efd7f2b20e4782": 0.0029,
      },
    },
    aum: 7033596.577204003,
    strategyId: "819363253bb18f7dee3d656c8dfe44b9",
  },
  {
    date: 1719857975535,
    name: "ICRprecept",
    owner: {
      type: 3,
      value: "bluter@me.com",
    },
    permissions: {
      default: 1,
    },
    description: "ICRprecept",
    aum: 0,
    strategyId: "d39d53c6700e025e23ef15b4c26a697f",
  },
  {
    date: 1719858044183,
    name: "ICRsma",
    owner: {
      type: 3,
      value: "bluter@me.com",
    },
    permissions: {
      default: 1,
    },
    description: "ICRsma",
    aum: 0.055450000223270735,
    strategyId: "9cd81fad5104b3e62878471e0db0e331",
  },
  {
    date: 1719837608226,
    name: "JULY 1 SMA",
    owner: {
      type: 3,
      value: "bluter@me.com",
    },
    permissions: {
      default: 1,
    },
    description: "July 1 SMA 500K",
    aum: 0,
    strategyId: "9bd328bf40de07371b37c8023137e458",
  },
  {
    date: 1719802826703,
    name: "July 1",
    owner: {
      type: 3,
      value: "bluter@me.com",
    },
    permissions: {
      default: 1,
    },
    description: "Precept $7.5M Long/Short ",
    aum: 0,
    strategyId: "f45b8469c67e01af755342ea6460fba8",
  },
  {
    date: 1719461194871,
    name: "Reshoring",
    owner: {
      type: 0,
      value: "51ed22746f7ec8b1f513fd2762f60326",
    },
    permissions: {
      default: 1,
    },
    aum: -3406.398248006243,
    strategyId: "8adac03b8d3c8c493e10f81f24d3594b",
  },
  {
    date: 1724443985967,
    name: "Sardo",
    owner: {
      type: 3,
      value: "jsardo@maximgrp.com",
    },
    permissions: {
      default: 1,
    },
    description: "Event Driven Opportunistic Long Short",
    aum: 0,
    strategyId: "0e1d833611d4e75f80ea56b67b70ccf0",
  },
  {
    date: 1720716084388,
    name: "SizeLowHigh",
    owner: {
      type: 3,
      value: "marshall@9823capital.com",
    },
    permissions: {
      default: 1,
    },
    description: "Long Low Size, Short High Size",
    aum: 0.0060189998596567595,
    strategyId: "9abaaf3171dd6c6f337b44b1f6cd27c4",
  },
  {
    date: 1721940025354,
    name: "SwansonAlpha",
    owner: {
      type: 3,
      value: "bill.swanson@craig-hallum.com",
    },
    permissions: {
      default: 1,
    },
    description: "Bill Swanson - Craig Hallum",
    aum: 0,
    strategyId: "2c514e788702f878363c5708b54ddbb3",
  },
  {
    date: 1719461293298,
    name: "SysLstd",
    owner: {
      type: 0,
      value: "51ed22746f7ec8b1f513fd2762f60326",
    },
    permissions: {
      default: 1,
    },
    aum: -2.9801867640344426e-9,
    strategyId: "1154eb42d2952897a81c270486729cd3",
  },
  {
    date: 1727226309626,
    name: "Test",
    owner: {
      type: 3,
      value: "amir@novoassociates.com",
    },
    permissions: {
      default: 1,
    },
    description: "",
    aum: 0,
    strategyId: "83c52f4df64004545989717740ec1f8e",
  },
  {
    date: 1714419332809,
    name: "Trendli",
    owner: {
      type: 3,
      value: "david@skylicapital.com",
    },
    permissions: {
      default: 1,
    },
    signals: {
      date: 1728423282930,
      strategy: "b6941eba2527a678f28b477c728a2b66",
      user: "david@skylicapital.com",
      weights_keys: {
        c356014da2f888aa2bb7e71409859b6b: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cbre",
        },
        "7fb36e094825204ce0c5da57553e3ddb": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ssnc",
        },
        d636b3d8e3e774c87e13ff2f734a34c6: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dgx",
        },
        d313a7e438129f2555e52d5c3d54d6f0: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "QCOM",
        },
        "06fc0ec558bb79ca4c34e48587ace83d": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "LLY",
        },
        "0f29dcc01fd33708679e147459f54c8d": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mmc",
        },
        "4b3394107151da11755937ae51efba28": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "GOLD",
        },
        bd13726e2f6ebe7bb266e6e2b999bfdf: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "BA",
        },
        "00efb9f97ee17468b2c8b7bea204bd28": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "IBKR",
        },
        "1f92be7c2a34c8a0f0945ae6641a4aa6": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "adbe",
        },
        a1a2ab2ce516c758ef41952260eb3c33: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "XBI",
        },
        d6cd07c399d3705424f59211988e9026: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "LOGI",
        },
        "861ab02c265b617e5c4152553bf7ceac": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ABBV",
        },
        cbd0129cf062f5a587107c8a1900f97a: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tjx",
        },
        "2835174b9209ba70481fec8932da70fb": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "BNTX",
        },
        "381847d17266ac97f8c235ffd196793f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ntrs",
        },
        "58da6c2223c7726b03873988939bc1ea": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vz",
        },
        "2661cf546b9765aa04dbdc71345d7c6b": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "dhr",
        },
        "2f8d3a63d2d6ff73d3c1c68b45601133": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "adm",
        },
        cdce272879caf5e4cbb1145134175928: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "INTU",
        },
        "67e84edc314b7807cefb4c67701857aa": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mco",
        },
        afd4741758919a525292970a02f6ff5c: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "odfl",
        },
        "57e315ecdc80ae62a4b434552b0629ac": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "lrcx",
        },
        bbc9e0a7e33657eeecc6a4f2b8eddcc1: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "phm",
        },
        "0f1248efc60825054bac54b0c349aadd": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ko",
        },
        "56a5bdff8238d4862b14898b1809166b": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "syy",
        },
        "0664755c953fc031cf90d589c872b257": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tm",
        },
        "23bd5c31f08b3144c1c3569544fc6dd1": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "TDG",
        },
        "26b3fc8a24a29927d423defc7cdd51b7": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pg",
        },
        "9adf6f996df77308180eb9d94e3fc329": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "NVDA",
        },
        "2c49f10ec3eb172730eba1d020c28493": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "CAT",
        },
        c459d9ac26b06b315a4de1784e0ff67c: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ENB",
        },
        fc3e1e4dd8730f70e5d9be2a3df4c3f1: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "MLM",
        },
        b67d545aedbedc272517cf68aef48454: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "UBER",
        },
        "7a0342678deadb2339a084fa679d2c26": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "SPOT",
        },
        ffae12e8e3f68345117d98d6f44d8c36: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "COST",
        },
        "9d1a9e8a71164d4108842f965421f630": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "PKG",
        },
        f6b948c145ef90f44a18bfe31e153e8f: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "NKE",
        },
        cfce7063737bcb41336f58ac08f0cd6e: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "mo",
        },
        "4a7f16f5942fc243610a37fb8a21488e": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "f",
        },
        a92b1fbe52b27553dd4b7165e8ea5674: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "wst",
        },
        "26ef99ae8cfb26100371e0969adc6045": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "TMUS",
        },
        e42b8b77d9173325d5d3c421ba0d9ba3: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "googl",
        },
        "54a0067c1ba7d96b97634ca870cab6ca": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "nee",
        },
        "02934e1dd7de0419c0b7a392b20ccc7f": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "FSLR",
        },
        "44e1c22681c19ee1ddb824e10249b52c": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "EBAY",
        },
        "593af62d66294a4f3871bf8475a3c9f9": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "amt",
        },
        ca0944bf13ea9e519a046c71828db8ca: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "iqv",
        },
        fb7db6ddac9986956048e9cb59a95c35: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rsg",
        },
        "99d6b89383ed6b7e8e80c64fcca0cf33": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "META",
        },
        "79f6daf430f5c96ed3d23390704aae1e": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fdx",
        },
        fa7f3d86716c7feff51a68a62ec8ba1a: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bmrn",
        },
        "13d510beb3f8dd39ce980c4c57ddd4f0": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "csx",
        },
        dc706d6a6c53fcfec80c6a93b3d047a9: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cor",
        },
      },
      weights_values: {
        c356014da2f888aa2bb7e71409859b6b: -0.03,
        "7fb36e094825204ce0c5da57553e3ddb": 0.07,
        d636b3d8e3e774c87e13ff2f734a34c6: -0.03,
        d313a7e438129f2555e52d5c3d54d6f0: -0.03,
        "06fc0ec558bb79ca4c34e48587ace83d": 0.07,
        "0f29dcc01fd33708679e147459f54c8d": -0.03,
        "4b3394107151da11755937ae51efba28": 0.1,
        bd13726e2f6ebe7bb266e6e2b999bfdf: -0.03,
        "00efb9f97ee17468b2c8b7bea204bd28": 0.051,
        "1f92be7c2a34c8a0f0945ae6641a4aa6": -0.03,
        a1a2ab2ce516c758ef41952260eb3c33: -0.03,
        d6cd07c399d3705424f59211988e9026: -0.023,
        "861ab02c265b617e5c4152553bf7ceac": -0.03,
        cbd0129cf062f5a587107c8a1900f97a: -0.025,
        "2835174b9209ba70481fec8932da70fb": 0.06,
        "381847d17266ac97f8c235ffd196793f": 0.06,
        "58da6c2223c7726b03873988939bc1ea": -0.03,
        "2661cf546b9765aa04dbdc71345d7c6b": -0.025,
        "2f8d3a63d2d6ff73d3c1c68b45601133": -0.035,
        cdce272879caf5e4cbb1145134175928: -0.03,
        "67e84edc314b7807cefb4c67701857aa": -0.025,
        afd4741758919a525292970a02f6ff5c: -0.03,
        "57e315ecdc80ae62a4b434552b0629ac": -0.03,
        bbc9e0a7e33657eeecc6a4f2b8eddcc1: -0.03,
        "0f1248efc60825054bac54b0c349aadd": -0.03,
        "56a5bdff8238d4862b14898b1809166b": -0.03,
        "0664755c953fc031cf90d589c872b257": -0.03,
        "23bd5c31f08b3144c1c3569544fc6dd1": 0.052000000000000005,
        "26b3fc8a24a29927d423defc7cdd51b7": -0.025,
        "9adf6f996df77308180eb9d94e3fc329": 0.06,
        "2c49f10ec3eb172730eba1d020c28493": -0.03,
        c459d9ac26b06b315a4de1784e0ff67c: -0.03,
        fc3e1e4dd8730f70e5d9be2a3df4c3f1: -0.03,
        b67d545aedbedc272517cf68aef48454: 0.07,
        "7a0342678deadb2339a084fa679d2c26": 0.065,
        ffae12e8e3f68345117d98d6f44d8c36: 0.07,
        "9d1a9e8a71164d4108842f965421f630": 0.051,
        f6b948c145ef90f44a18bfe31e153e8f: -0.03,
        cfce7063737bcb41336f58ac08f0cd6e: -0.03,
        "4a7f16f5942fc243610a37fb8a21488e": -0.03,
        a92b1fbe52b27553dd4b7165e8ea5674: -0.03,
        "26ef99ae8cfb26100371e0969adc6045": 0.071,
        e42b8b77d9173325d5d3c421ba0d9ba3: 0.06,
        "54a0067c1ba7d96b97634ca870cab6ca": -0.03,
        "02934e1dd7de0419c0b7a392b20ccc7f": 0.07,
        "44e1c22681c19ee1ddb824e10249b52c": 0.049,
        "593af62d66294a4f3871bf8475a3c9f9": -0.03,
        ca0944bf13ea9e519a046c71828db8ca: -0.03,
        fb7db6ddac9986956048e9cb59a95c35: -0.03,
        "99d6b89383ed6b7e8e80c64fcca0cf33": 0.06,
        "79f6daf430f5c96ed3d23390704aae1e": -0.03,
        fa7f3d86716c7feff51a68a62ec8ba1a: -0.03,
        "13d510beb3f8dd39ce980c4c57ddd4f0": -0.03,
        dc706d6a6c53fcfec80c6a93b3d047a9: -0.03,
      },
    },
    aum: 11790362.986273957,
    strategyId: "b6941eba2527a678f28b477c728a2b66",
  },
  {
    date: 1719461383445,
    name: "Vision",
    owner: {
      type: 3,
      value: "rprather@visionro.com",
    },
    permissions: {
      default: 1,
    },
    signals: {
      date: 1726601708748,
      strategy: "e1f8bb020ae04dce7df358dac3ed9061",
      user: "marshall@9823capital.com",
      weights_keys: {
        "1d8b0e986637ab115e4d936e2056154a": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "ktb",
        },
        a734ac93a04f75cf5ba5a66b024fd90c: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "spy",
        },
        f8e30f1aac575d5491e9928a842e31b2: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "tenb",
        },
        "0321a7b59d938def419e8a974bdb53da": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "aaon",
        },
        f43bade9aac080ff664a10b096efb56d: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "onon",
        },
        ea328a0c15a89c9aeb01b0722633a1b9: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "bc",
        },
        "36228be2f742071c7e147cb8343adef9": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "vik",
        },
        "1c9ec52c2b92e5c530bb0f90a6268744": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "fnd",
        },
        a694ab0f9247be9c0c2d905bb928a3cf: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "aos",
        },
        cd1f85eca807d152cf8fcdf3a6615696: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "kai",
        },
        c6db48ea6874c8bb231a2e50168effaa: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "enr",
        },
        "168a356aa48e0576951f95f7148659fb": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "pool",
        },
        e2f9331b90910890447dc7dddcc1b864: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cri",
        },
        "165b5bc14c5533ead417118c352b3008": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "enph",
        },
        ae4cb554a5a2758a878eb7362d48460e: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "rusha",
        },
        "315ea1df5b14d40161375b9920f51c65": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "cvco",
        },
        fed87f005e587b2d04b965aad7aa7e44: {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "sig",
        },
        "6046a16cc8cc7557fa6814efae02ded6": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "abnb",
        },
        "168dfe07027b3508e0e02d2fdd5e68ea": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "trex",
        },
      },
      weights_values: {
        "1d8b0e986637ab115e4d936e2056154a": 0.05555555555555555,
        a734ac93a04f75cf5ba5a66b024fd90c: -1,
        f8e30f1aac575d5491e9928a842e31b2: 0.05555555555555555,
        "0321a7b59d938def419e8a974bdb53da": 0.05555555555555555,
        f43bade9aac080ff664a10b096efb56d: 0.05555555555555555,
        ea328a0c15a89c9aeb01b0722633a1b9: 0.05555555555555555,
        "36228be2f742071c7e147cb8343adef9": 0.05555555555555555,
        "1c9ec52c2b92e5c530bb0f90a6268744": 0.05555555555555555,
        a694ab0f9247be9c0c2d905bb928a3cf: 0.05555555555555555,
        cd1f85eca807d152cf8fcdf3a6615696: 0.05555555555555555,
        c6db48ea6874c8bb231a2e50168effaa: 0.05555555555555555,
        "168a356aa48e0576951f95f7148659fb": 0.05555555555555555,
        e2f9331b90910890447dc7dddcc1b864: 0.05555555555555555,
        "165b5bc14c5533ead417118c352b3008": 0.05555555555555555,
        ae4cb554a5a2758a878eb7362d48460e: 0.05555555555555555,
        "315ea1df5b14d40161375b9920f51c65": 0.05555555555555555,
        fed87f005e587b2d04b965aad7aa7e44: 0.05555555555555555,
        "6046a16cc8cc7557fa6814efae02ded6": 0.05555555555555555,
        "168dfe07027b3508e0e02d2fdd5e68ea": 0.05555555555555555,
      },
    },
    aum: 2260390.7447992587,
    strategyId: "e1f8bb020ae04dce7df358dac3ed9061",
  },
  {
    date: 1727273387035,
    name: "test-101",
    owner: {
      type: 3,
      value: "asfand@plutus21.com",
    },
    permissions: {
      default: 1,
    },
    description: "This is a strategy for dev testing",
    signals: {
      date: 1728390191217,
      strategy: "4dabb6bc9616b318d4cbcf5bbdde8bf7",
      user: "marshall@9823capital.com",
      weights_keys: {
        "50c0768fbeda9b5f22602ec6b100bab4": {
          currency: 7,
          exchange: 4,
          investment: 4,
          symbol: "TEST",
        },
      },
      weights_values: {
        "50c0768fbeda9b5f22602ec6b100bab4": 0.01,
      },
    },
    aum: 0,
    strategyId: "4dabb6bc9616b318d4cbcf5bbdde8bf7",
  },
];

export const accounting = [
  {
    custodians: [
      {
        custodian: "a741fe2a71f3fa10939541a44714fcdc",
        date: 1728393348281,
        identifier: 293,
        accountingId: "7b9c100bbd213c1f25c3cb00da497731",
        managerId: "51ed22746f7ec8b1f513fd2762f60326",
      },
      {
        custodian: "b409d30c6d7145a91be1ece0413f3c33",
        date: 1728393348281,
        identifier: 365,
        accountingId: "7b9c100bbd213c1f25c3cb00da497731",
        managerId: "51ed22746f7ec8b1f513fd2762f60326",
      },
      {
        custodian: "78dfbdec87d0b1a46e6d886711b513d9",
        date: 1728393348281,
        identifier: 369,
        accountingId: "7b9c100bbd213c1f25c3cb00da497731",
        managerId: "51ed22746f7ec8b1f513fd2762f60326",
      },
      {
        custodian: "ce83b33e270f752b19b048ebdecf33df",
        date: 1728393348281,
        identifier: 349,
        accountingId: "7b9c100bbd213c1f25c3cb00da497731",
        managerId: "51ed22746f7ec8b1f513fd2762f60326",
      },
      {
        custodian: "0a600a1e47a87b12123717e579ce02db",
        date: 1728393348281,
        identifier: 302,
        accountingId: "7b9c100bbd213c1f25c3cb00da497731",
        managerId: "51ed22746f7ec8b1f513fd2762f60326",
      },
      {
        custodian: "4b948dac7f5dcfe0f93d73525f2533f5",
        date: 1728393348281,
        identifier: 294,
        accountingId: "7b9c100bbd213c1f25c3cb00da497731",
        managerId: "51ed22746f7ec8b1f513fd2762f60326",
      },
      {
        custodian: "d5b708f0f2dc1cd982c9181c82c20000",
        date: 1728393348281,
        identifier: 298,
        accountingId: "7b9c100bbd213c1f25c3cb00da497731",
        managerId: "51ed22746f7ec8b1f513fd2762f60326",
      },
      {
        custodian: "c51a05a1d499d80aff77eb38b1c8f388",
        date: 1728393348281,
        identifier: 301,
        accountingId: "7b9c100bbd213c1f25c3cb00da497731",
        managerId: "51ed22746f7ec8b1f513fd2762f60326",
      },
      {
        custodian: "92facb38909fd2bf52c539828a75fff6",
        date: 1728393348281,
        identifier: 291,
        accountingId: "7b9c100bbd213c1f25c3cb00da497731",
        managerId: "51ed22746f7ec8b1f513fd2762f60326",
      },
    ],
    funds: [
      {
        date: 1728393348316,
        fund: "4c82572841abef2199de1e013a4e6b1e",
        identifier: 18,
        accountingId: "7b9c100bbd213c1f25c3cb00da497731",
        aum: 9820080.322886897,
        managerId: "51ed22746f7ec8b1f513fd2762f60326",
        strategyAUMs: {
          "4c4414cfe376eea8e3d148f964dc14da": -9.332090655789216e-12,
          ae1cdc078091ccaa0dad1ad5896b1105: 2023261.8010779957,
          b3e1a234a66b8d211668139213731875: 2774031.390329998,
          c79a684b68379f3d07d291dc500f400d: 3547126.9218738405,
          b6941eba2527a678f28b477c728a2b66: 1476936.2609896786,
          "7eb942cfde1052167cb3cc5fb6b9a570": 1.0550138540565968e-10,
          "8adac03b8d3c8c493e10f81f24d3594b": 0.002846998890287722,
          "9cd81fad5104b3e62878471e0db0e331": 0.055450000223270735,
          "2ee1410582b3da8563fc370afac81f7e": -0.003652001460864085,
          e1f8bb020ae04dce7df358dac3ed9061: -9.032063985614514e-11,
          "68afa71285e39df9f75caa12e302e663": 0.004305000183328467,
          "9abaaf3171dd6c6f337b44b1f6cd27c4": 0.0013239988358506594,
        },
      },
      {
        date: 1728393348317,
        fund: "e7fa5c61f1a4e1e6213be8c2dcf6cfb5",
        identifier: 8,
        accountingId: "7b9c100bbd213c1f25c3cb00da497731",
        aum: 985814.4343515368,
        managerId: "51ed22746f7ec8b1f513fd2762f60326",
      },
      {
        date: 1726453875943,
        fund: "9ba6544a1a9e4a6a3338531c58b27839",
        identifier: 11,
        accountingId: "7b9c100bbd213c1f25c3cb00da497731",
        aum: 0,
        managerId: "51ed22746f7ec8b1f513fd2762f60326",
      },
      {
        date: 1728393348317,
        fund: "cae216c85c678c75d28282ede177ac48",
        identifier: 17,
        accountingId: "7b9c100bbd213c1f25c3cb00da497731",
        aum: 844598.2967288912,
        managerId: "51ed22746f7ec8b1f513fd2762f60326",
      },
      {
        date: 1726453875943,
        fund: "140810bfe7350637dc092eac32405b08",
        identifier: 10,
        accountingId: "7b9c100bbd213c1f25c3cb00da497731",
        aum: 0,
        managerId: "51ed22746f7ec8b1f513fd2762f60326",
      },
      {
        date: 1728393348317,
        fund: "8d61436c18e68d23309d444a5e8060bf",
        identifier: 9,
        accountingId: "7b9c100bbd213c1f25c3cb00da497731",
        aum: 2587087.464904062,
        managerId: "51ed22746f7ec8b1f513fd2762f60326",
      },
      {
        date: 1726453875943,
        fund: "3234ba534cfa4f70d9ff5ad455acce17",
        identifier: 14,
        accountingId: "7b9c100bbd213c1f25c3cb00da497731",
        aum: 0.00015256659745290283,
        managerId: "51ed22746f7ec8b1f513fd2762f60326",
      },
      {
        date: 1728393348317,
        fund: "6084ac030540f4f084e60aa3d217bed5",
        identifier: 5,
        accountingId: "7b9c100bbd213c1f25c3cb00da497731",
        aum: 109064043.55442236,
        managerId: "51ed22746f7ec8b1f513fd2762f60326",
      },
      {
        date: 1728393348317,
        fund: "9c5d649ba5dc292afce119e5a66353a5",
        identifier: 6,
        accountingId: "7b9c100bbd213c1f25c3cb00da497731",
        aum: 9558064.072590752,
        managerId: "51ed22746f7ec8b1f513fd2762f60326",
      },
      {
        date: 1728393348317,
        fund: "0429a2eb5a327dea711f799af1408e7c",
        identifier: 13,
        accountingId: "7b9c100bbd213c1f25c3cb00da497731",
        aum: 0,
        managerId: "51ed22746f7ec8b1f513fd2762f60326",
      },
      {
        date: 1728393348317,
        fund: "28f0000b68a45291c16e1baa502131fd",
        identifier: 15,
        accountingId: "7b9c100bbd213c1f25c3cb00da497731",
        aum: 0,
        managerId: "51ed22746f7ec8b1f513fd2762f60326",
      },
      {
        date: 1728393348317,
        fund: "8b20ff74192fafff9b874dbcf679f3a2",
        identifier: 4,
        accountingId: "7b9c100bbd213c1f25c3cb00da497731",
        aum: 0.0024308279977278777,
        managerId: "51ed22746f7ec8b1f513fd2762f60326",
      },
      {
        date: 1726453875943,
        fund: "eb6abf0a56f35f5e4c7ada878dded3e1",
        identifier: 12,
        accountingId: "7b9c100bbd213c1f25c3cb00da497731",
        aum: 0,
        managerId: "51ed22746f7ec8b1f513fd2762f60326",
      },
      {
        date: 1728393348316,
        fund: "927a4bd37ff00177aac2b4f92d9076f7",
        identifier: 16,
        accountingId: "7b9c100bbd213c1f25c3cb00da497731",
        aum: 70509361.80370682,
        managerId: "51ed22746f7ec8b1f513fd2762f60326",
        strategyAUMs: {
          "4c4414cfe376eea8e3d148f964dc14da": 7394980.474648006,
          ae1cdc078091ccaa0dad1ad5896b1105: 8940428.881235763,
          "7eb942cfde1052167cb3cc5fb6b9a570": 22945813.664905243,
          b6941eba2527a678f28b477c728a2b66: 10313426.725284278,
          "2ee1410582b3da8563fc370afac81f7e": 0.0017879996448755264,
          "525de7b70d72b2370d7043cd8b2ad00b": 4927830.654000003,
          "9d166db55742ad53ea9e3842ecc49f10": -0.0004889257252216339,
          "68afa71285e39df9f75caa12e302e663": -0.004395001859336389,
          "9abaaf3171dd6c6f337b44b1f6cd27c4": 0.0046950010238061,
          "1154eb42d2952897a81c270486729cd3": -2.9801867640344426e-9,
          b3e1a234a66b8d211668139213731875: 12865489.688283997,
          "462b717c949f2d82f0c98a3ff61adcc5": -0.0031860038387776513,
          "8adac03b8d3c8c493e10f81f24d3594b": -3406.4010950051334,
          "819363253bb18f7dee3d656c8dfe44b9": 7033596.577204003,
          fdaa9ce51d461c9974d243cea1886d24: -0.001873998077935539,
          e1f8bb020ae04dce7df358dac3ed9061: 2260390.7447992587,
        },
      },
    ],
    managerId: "51ed22746f7ec8b1f513fd2762f60326",
    sync: {
      date: 1728410294933,
      duration: 1360.8656971999999,
      previousDate: 1728382126481,
      stage: 0,
      user: "",
      accountingId: "7b9c100bbd213c1f25c3cb00da497731",
      managerId: "51ed22746f7ec8b1f513fd2762f60326",
    },
    accountingId: "7b9c100bbd213c1f25c3cb00da497731",
  },
];
export const funds = [
  {
    date: 1682749875795,
    name: "9823 Fund, LP",
    fundId: "927a4bd37ff00177aac2b4f92d9076f7",
    permissions: {
      default: 1,
    },
    lots: [],
    abbreviation: "wsf",
    accounts: {
      "gs-wsf": {
        custodian: "4b948dac7f5dcfe0f93d73525f2533f5",
        number: "065448128",
        overrides: {
          swap: "066912965",
        },
      },
      "can-wsf": {
        custodian: "ce83b33e270f752b19b048ebdecf33df",
        number: "32500395",
        overrides: {},
      },
      "per-wsf": {
        custodian: "a741fe2a71f3fa10939541a44714fcdc",
        number: "BYF001006",
        overrides: {},
      },
    },
  },
];
export const allocation = {
  fundAllocation: {
    "819363253bb18f7dee3d656c8dfe44b9": {
      broker: "cddcd1db02dfdb4e7c0f18915f3125b4",
      account: "gs-wsf",
      allocation: {
        totalAllocation: 0.01,
        percentShort: 0.5,
        ignoreWhenRebalancing: false,
        percentLong: 0.5,
      },
    },
    b6941eba2527a678f28b477c728a2b66: {
      broker: "eb87a73294ac44b6a2410b3683c08aee",
      account: "can-wsf",
      allocation: {
        totalAllocation: 0.5,
        percentShort: 0.1,
        ignoreWhenRebalancing: false,
        percentLong: 0.9,
      },
    },
  },
  date: 1728390702456,
  user: "marshall@9823capital.com",
  fundId: "927a4bd37ff00177aac2b4f92d9076f7",
  managerId: "51ed22746f7ec8b1f513fd2762f60326",
  targetedAUM: 70543574,
};
export const notifications = [
  {
    content:
      "Some changes were made by david@skylicapital.com in a signal set Trendli",
    creationDate: 1728354856003,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b6941eba2527a678f28b477c728a2b66",
    },
    notificationId: "470dabe9beb7bb534ec4d8dcbadb3c94",
  },
  {
    content:
      "Some changes were made by david@skylicapital.com in a signal set Trendli",
    creationDate: 1728261473021,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b6941eba2527a678f28b477c728a2b66",
    },
    notificationId: "1a06d03f43a2b348f8ce589b5cdf438d",
  },
  {
    content:
      "Some changes were made by marshall@9823capital.com in a signal set AIFactorGT5",
    creationDate: 1727101389255,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "c79a684b68379f3d07d291dc500f400d",
    },
    notificationId: "33221d9fe4b50effd6843728a78648cf",
  },
  {
    content:
      "Some changes were made by blair@9823capital.com in a signal set CPFactor15",
    creationDate: 1726495014371,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b3e1a234a66b8d211668139213731875",
    },
    notificationId: "6eefc45cee3c14e22ac91f78cb4a0911",
  },
  {
    content:
      "Some changes were made by david@skylicapital.com in a signal set Trendli",
    creationDate: 1727728879014,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b6941eba2527a678f28b477c728a2b66",
    },
    notificationId: "5708d53d2496df07fd933056718f86c7",
  },
  {
    content:
      "Some changes were made by marshall@9823capital.com in a signal set AIFactor",
    creationDate: 1727705123102,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "7eb942cfde1052167cb3cc5fb6b9a570",
    },
    notificationId: "c2b2ce5db398c59e218f5e0afa1f269e",
  },
  {
    content:
      "Some changes were made by marshall@9823capital.com in a signal set ICR",
    creationDate: 1727880777318,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "819363253bb18f7dee3d656c8dfe44b9",
    },
    notificationId: "a41fd851a104082ed2199a87e732bd1a",
  },
  {
    content:
      "Some changes were made by david@skylicapital.com in a signal set Trendli",
    creationDate: 1728354844183,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b6941eba2527a678f28b477c728a2b66",
    },
    notificationId: "176a7ee3664c07015bceb3488ddc5f66",
  },
  {
    content:
      "Some changes were made by marshall@9823capital.com in a signal set AIFactor",
    creationDate: 1726499300182,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "7eb942cfde1052167cb3cc5fb6b9a570",
    },
    notificationId: "065d4c3b0678f10e12c4fe501dd68fd1",
  },
  {
    content:
      "Some changes were made by marshall@9823capital.com in a signal set Vision",
    creationDate: 1726601709108,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "e1f8bb020ae04dce7df358dac3ed9061",
    },
    notificationId: "b5403756f2c981a01878269657279388",
  },
  {
    content:
      "Some changes were made by marshall@9823capital.com in a signal set Trendli",
    creationDate: 1727359903730,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b6941eba2527a678f28b477c728a2b66",
    },
    notificationId: "60ec9ce83441d02587280abe3440920c",
  },
  {
    content:
      "Some changes were made by marshall@9823capital.com in a signal set Trendli",
    creationDate: 1727287377176,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b6941eba2527a678f28b477c728a2b66",
    },
    notificationId: "10d6cf927760561b20aaa379333c3a3a",
  },
  {
    content:
      "Some changes were made by david@skylicapital.com in a signal set Trendli",
    creationDate: 1727830698869,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b6941eba2527a678f28b477c728a2b66",
    },
    notificationId: "618e75c38765fc3f343f8c65cb0bc328",
  },
  {
    content:
      "Some changes were made by marshall@9823capital.com in a signal set Trendli",
    creationDate: 1728404018130,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b6941eba2527a678f28b477c728a2b66",
    },
    notificationId: "e658a9663bb04fd34529d6a8b6cbf979",
  },
  {
    content:
      "Some changes were made by marshall@9823capital.com in a signal set AIFactor",
    creationDate: 1727101109177,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "7eb942cfde1052167cb3cc5fb6b9a570",
    },
    notificationId: "da372061f2f80026c5e824118550be82",
  },
  {
    content:
      "Some changes were made by david@skylicapital.com in a signal set Trendli",
    creationDate: 1727914841673,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b6941eba2527a678f28b477c728a2b66",
    },
    notificationId: "02e023c2a0e8e4173f7e2342823fe199",
  },
  {
    content:
      "Some changes were made by marshall@9823capital.com in a signal set Vision",
    creationDate: 1726499561163,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "e1f8bb020ae04dce7df358dac3ed9061",
    },
    notificationId: "e3c34e5cbb0dc7af410fc3d6f1e7f207",
  },
  {
    content:
      "Some changes were made by david@skylicapital.com in a signal set Trendli",
    creationDate: 1728485029004,
    heading: "Signal Updated",
    isRead: false,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b6941eba2527a678f28b477c728a2b66",
    },
    notificationId: "1ded250e4680c593288d5b7d3e8e05f5",
  },
  {
    content:
      "Some changes were made by marshall@9823capital.com in a signal set AIFactorGT5",
    creationDate: 1726499392203,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "c79a684b68379f3d07d291dc500f400d",
    },
    notificationId: "7ffea32491684432371c199e907f051b",
  },
  {
    content:
      "Some changes were made by marshall@9823capital.com in a signal set ICR",
    creationDate: 1728391877386,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "819363253bb18f7dee3d656c8dfe44b9",
    },
    notificationId: "594942cd04f7750ea446bea972d6b4ca",
  },
  {
    content:
      "Some changes were made by marshall@9823capital.com in a signal set CPFactor15",
    creationDate: 1727101654175,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b3e1a234a66b8d211668139213731875",
    },
    notificationId: "ce227eb1523c225eda7f2f006c62abc9",
  },
  {
    content:
      "Some changes were made by blair@9823capital.com in a signal set CPFactor",
    creationDate: 1727879699494,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "ae1cdc078091ccaa0dad1ad5896b1105",
    },
    notificationId: "d166c8566798c1f838846293e9c7c843",
  },
  {
    content:
      "Some changes were made by marshall@9823capital.com in a signal set Trendli",
    creationDate: 1727287364416,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b6941eba2527a678f28b477c728a2b66",
    },
    notificationId: "63f14ae3b319e197a091f4f6e21e2341",
  },
  {
    content:
      "Some changes were made by marshall@9823capital.com in a signal set Trendli",
    creationDate: 1727101532417,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b6941eba2527a678f28b477c728a2b66",
    },
    notificationId: "ddda9cc856583c0df882bee6473055b8",
  },
  {
    content:
      "Some changes were made by marshall@9823capital.com in a signal set ICR",
    creationDate: 1728391665124,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "819363253bb18f7dee3d656c8dfe44b9",
    },
    notificationId: "9313db81b4ebea0bc491a63b877fd917",
  },
  {
    content:
      "Some changes were made by david@skylicapital.com in a signal set Trendli",
    creationDate: 1727470904814,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b6941eba2527a678f28b477c728a2b66",
    },
    notificationId: "5ed3a553f94502d1e67f9bc155e2b9ce",
  },
  {
    content:
      "Some changes were made by marshall@9823capital.com in a signal set test-101",
    creationDate: 1728372861740,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "4dabb6bc9616b318d4cbcf5bbdde8bf7",
    },
    notificationId: "85f19f6b37413249742afa4f49401da4",
  },
  {
    content:
      "Some changes were made by david@skylicapital.com in a signal set Trendli",
    creationDate: 1727470917514,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b6941eba2527a678f28b477c728a2b66",
    },
    notificationId: "8acc1bee37ae6512c49d9ebd3e924156",
  },
  {
    content:
      "Some changes were made by david@skylicapital.com in a signal set Trendli",
    creationDate: 1727728860254,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b6941eba2527a678f28b477c728a2b66",
    },
    notificationId: "f0a21a08081a1b8a17e9eeab18c7ae1a",
  },
  {
    content:
      "Some changes were made by marshall@9823capital.com in a signal set AIFactor",
    creationDate: 1728310315140,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "7eb942cfde1052167cb3cc5fb6b9a570",
    },
    notificationId: "2b64ae288137999d0c7df92faffe5cae",
  },
  {
    content:
      "Some changes were made by marshall@9823capital.com in a signal set ICR",
    creationDate: 1728391524022,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "819363253bb18f7dee3d656c8dfe44b9",
    },
    notificationId: "d01215d5f42b1a8db93c1ee6acb491b5",
  },
  {
    content:
      "Some changes were made by marshall@9823capital.com in a signal set test-101",
    creationDate: 1728372522421,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "4dabb6bc9616b318d4cbcf5bbdde8bf7",
    },
    notificationId: "9fa825c506c9e4a55bc998e39c476f55",
  },
  {
    content:
      "Some changes were made by david@skylicapital.com in a signal set Trendli",
    creationDate: 1728507947647,
    heading: "Signal Updated",
    isRead: false,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b6941eba2527a678f28b477c728a2b66",
    },
    notificationId: "e08c4667a154f5c87e993f984a49e642",
  },
  {
    content:
      "Some changes were made by david@skylicapital.com in a signal set Trendli",
    creationDate: 1727987700777,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b6941eba2527a678f28b477c728a2b66",
    },
    notificationId: "c36e195906e7730804344c7e7ae31ef9",
  },
  {
    content:
      "Some changes were made by blair@9823capital.com in a signal set CPFactor15",
    creationDate: 1726495672724,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b3e1a234a66b8d211668139213731875",
    },
    notificationId: "f9ef5efdb3e8506f10a6b3e46b5c40e4",
  },
  {
    content:
      "Some changes were made by marshall@9823capital.com in a signal set ICR",
    creationDate: 1728396913328,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "819363253bb18f7dee3d656c8dfe44b9",
    },
    notificationId: "c00b797af18f89e4d816a78f9d46c099",
  },
  {
    content:
      "Some changes were made by david@skylicapital.com in a signal set Trendli",
    creationDate: 1727397504337,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b6941eba2527a678f28b477c728a2b66",
    },
    notificationId: "63cd7bbb3ecf5d2e440163ba9320e349",
  },
  {
    content:
      "Some changes were made by marshall@9823capital.com in a signal set Vision",
    creationDate: 1726601691912,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "e1f8bb020ae04dce7df358dac3ed9061",
    },
    notificationId: "cbb2fe936f0384fc5b0de149e81ed1bb",
  },
  {
    content:
      "Some changes were made by marshall@9823capital.com in a signal set Trendli",
    creationDate: 1726669572996,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b6941eba2527a678f28b477c728a2b66",
    },
    notificationId: "fb8f42c1a25b7a74222c225263c45ca5",
  },
  {
    content:
      "Some changes were made by david@skylicapital.com in a signal set Trendli",
    creationDate: 1727397605236,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b6941eba2527a678f28b477c728a2b66",
    },
    notificationId: "193ffdb177982257f6287ad590995d7a",
  },
  {
    content:
      "Some changes were made by marshall@9823capital.com in a signal set Trendli",
    creationDate: 1726852434290,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b6941eba2527a678f28b477c728a2b66",
    },
    notificationId: "14e03bbe7c114cd9557028391c5c470c",
  },
  {
    content:
      "Some changes were made by david@skylicapital.com in a signal set Trendli",
    creationDate: 1727914827513,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b6941eba2527a678f28b477c728a2b66",
    },
    notificationId: "8b0540652c88eddc8053f976f53f76b3",
  },
  {
    content:
      "Some changes were made by marshall@9823capital.com in a signal set CPFactor",
    creationDate: 1727797029313,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "ae1cdc078091ccaa0dad1ad5896b1105",
    },
    notificationId: "26fae6290dd76cb2a17e200266f7ee89",
  },
  {
    content:
      "Some changes were made by david@skylicapital.com in a signal set Trendli",
    creationDate: 1728423285484,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b6941eba2527a678f28b477c728a2b66",
    },
    notificationId: "ea9f3877016406a69f7b3d291f037796",
  },
  {
    content:
      "Some changes were made by david@skylicapital.com in a signal set Trendli",
    creationDate: 1728261450041,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b6941eba2527a678f28b477c728a2b66",
    },
    notificationId: "dd8d021ead39d8d2eb911291f5435960",
  },
  {
    content:
      "Some changes were made by blair@9823capital.com in a signal set CPFactor15",
    creationDate: 1726495114789,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b3e1a234a66b8d211668139213731875",
    },
    notificationId: "7cb6ffe5c6cf13f6fe851e2c754cd7d8",
  },
  {
    content:
      "Some changes were made by marshall@9823capital.com in a signal set CPFactor",
    creationDate: 1727796907214,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "ae1cdc078091ccaa0dad1ad5896b1105",
    },
    notificationId: "40ae884f94a3139db3dd354580e0cd93",
  },
  {
    content:
      "Some changes were made by marshall@9823capital.com in a signal set AIFactorGT5",
    creationDate: 1728310389080,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "c79a684b68379f3d07d291dc500f400d",
    },
    notificationId: "ae03848f9389856e40073681df63d516",
  },
  {
    content:
      "Some changes were made by marshall@9823capital.com in a signal set Trendli",
    creationDate: 1726849972598,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b6941eba2527a678f28b477c728a2b66",
    },
    notificationId: "018db66882dae5b91b4af6f337737f2b",
  },
  {
    content:
      "Some changes were made by marshall@9823capital.com in a signal set Trendli",
    creationDate: 1726850027298,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b6941eba2527a678f28b477c728a2b66",
    },
    notificationId: "ff8084bede32e0c5fc42ef79a0fa65eb",
  },
  {
    content:
      "Some changes were made by marshall@9823capital.com in a signal set test-101",
    creationDate: 1728372434681,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "4dabb6bc9616b318d4cbcf5bbdde8bf7",
    },
    notificationId: "b87cac9b049bdb0ce3df603fc8a81a5a",
  },
  {
    content:
      "Some changes were made by marshall@9823capital.com in a signal set AIFactorGT5",
    creationDate: 1727705238381,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "c79a684b68379f3d07d291dc500f400d",
    },
    notificationId: "9a6c23e718d5f512e6f29929b5a26859",
  },
  {
    content:
      "Some changes were made by marshall@9823capital.com in a signal set ICR",
    creationDate: 1728390778852,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "819363253bb18f7dee3d656c8dfe44b9",
    },
    notificationId: "bc521252bd5b1a33156dabd8b1f4ddc3",
  },
  {
    content:
      "Some changes were made by blair@9823capital.com in a signal set CPFactor15",
    creationDate: 1726496226032,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b3e1a234a66b8d211668139213731875",
    },
    notificationId: "42e4164f6e1c7d8cbdda8c332a44fedd",
  },
  {
    content:
      "Some changes were made by david@skylicapital.com in a signal set Trendli",
    creationDate: 1728423272264,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b6941eba2527a678f28b477c728a2b66",
    },
    notificationId: "ec9099e58854c44a6acd3dd940ea8fd0",
  },
  {
    content:
      "Some changes were made by blair@9823capital.com in a signal set CPFactorBW",
    creationDate: 1726452043201,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "d16c4685f2e706f9ea6be3dc771b02e6",
    },
    notificationId: "54894971d1f9744b5a244dc7c97fa513",
  },
  {
    content:
      "Some changes were made by david@skylicapital.com in a signal set Trendli",
    creationDate: 1728354873663,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b6941eba2527a678f28b477c728a2b66",
    },
    notificationId: "fd17c5f96992cb9cfd48bc3b964292e6",
  },
  {
    content:
      "Some changes were made by marshall@9823capital.com in a signal set test-101",
    creationDate: 1728390191616,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "4dabb6bc9616b318d4cbcf5bbdde8bf7",
    },
    notificationId: "41bcee6c3d6206c9be95db1939e382a8",
  },
  {
    content:
      "Some changes were made by marshall@9823capital.com in a signal set Trendli",
    creationDate: 1726764497771,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b6941eba2527a678f28b477c728a2b66",
    },
    notificationId: "783c2d80b26a75dd3ae178dace0469c3",
  },
  {
    content:
      "Some changes were made by marshall@9823capital.com in a signal set AIFactor",
    creationDate: 1727101249077,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "7eb942cfde1052167cb3cc5fb6b9a570",
    },
    notificationId: "55e08cc848109eee1227c5b64b549c00",
  },
  {
    content:
      "Some changes were made by marshall@9823capital.com in a signal set ICR",
    creationDate: 1728391496681,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "819363253bb18f7dee3d656c8dfe44b9",
    },
    notificationId: "b830688d12cfb05ef7a4d63607694be6",
  },
  {
    content:
      "Some changes were made by blair@9823capital.com in a signal set CPFactor15",
    creationDate: 1726494880910,
    heading: "Signal Updated",
    isRead: true,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b3e1a234a66b8d211668139213731875",
    },
    notificationId: "588879911ad2aa3389d992e192a58738",
  },
  {
    content:
      "Some changes were made by david@skylicapital.com in a signal set Trendli",
    creationDate: 1728507934807,
    heading: "Signal Updated",
    isRead: false,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b6941eba2527a678f28b477c728a2b66",
    },
    notificationId: "264e67de349db55ecaefc36bdc4fe592",
  },
  {
    content:
      "Some changes were made by david@skylicapital.com in a signal set Trendli",
    creationDate: 1728484991464,
    heading: "Signal Updated",
    isRead: false,
    metadata: {
      type: "updated_signal_for_rebalance",
      strategy: "b6941eba2527a678f28b477c728a2b66",
    },
    notificationId: "88fe6082e14a10c08feed1a3c8ab0e4c",
  },
];
export const signals = [
  { status: "new", symbol: "msft", newValue: 0.01, oldValue: 2 },
];
export const sleeveDataStageOneData1 = {
  b6941eba2527a678f28b477c728a2b66: {
    rebalancer: {
      date: 1732699228720,
      strategy: "b6941eba2527a678f28b477c728a2b66",

      positions: [
        {
          ticker: "test1",
          currentPosition: 0.4,
          requiredPosition: 0.8,
          marketPrice: 1,
        },
        {
          ticker: "bmrn",
          currentPosition: 0.7,
          requiredPosition: 80,
          marketPrice: 1,
        },
      ],
      fund: "927a4bd37ff00177aac2b4f92d9076f7",
      rebalancerId: "0452f8cd9dc9a18bfff96e7a0fa04923",
      type: 0,
      existingTotalLong: 90.6,
      existingTotalShort: 0.8,
      newTotalLong: 5.4,
      newTotalShort: 0.9,
      existingAUM: 56000000,
      newAUM: 48464955,
      preferredCustodian: "can-wsf",
      preferredBroker: "eb87a73294ac44b6a2410b3683c08aee",
    },

    rebalancerItems: [
      {
        rebalancer: "319cb388af002e02134beb92ebfcf3e1",
        ticker: {
          currency: 7,
          exchange: 4,
          expirationDate: null,
          investment: 4,
          option: null,
          source: null,
          stock: null,
          strikePrice: null,
          symbol: "unh",
          underlying: null,
          tickerId: "5e3da2268e750039712e5e9ea3d4941f",
        },
        currentPosition: {
          weight: 0,
          shareCount: 0,
        },
        requiredPosition: {
          weight: 0,
          shareCount: 0,
        },
        marketPrice: 566.475,
        signalWeight: 0,
        strategyAUM: 10304865.83810054,
        date: 1732699159274,
        rebalancerItemId: "03bd0377ef1ad0dec7c29e8330a4434e",
      },
    ],
  },
};
export const sleeveDataStageOneData2 = {
  positions: {
    AAPL: {
      currentPosition: {
        weight: 10.5,
        shareCount: 150,
      },
      requiredPosition: {
        weight: 12.0,
        shareCount: 170,
      },
      marketPrice: 150.25,
    },
    GOOGL: {
      currentPosition: {
        weight: 8.0,
        shareCount: 100,
      },
      requiredPosition: {
        weight: 9.5,
        shareCount: 120,
      },
      marketPrice: 2800.5,
    },
  },
  existingTotalLong: 18.5,
  existingTotalShort: 0,
  newTotalLong: 20.4,
  newTotalShort: 0,
  existingAUM: 1000000,
  newAUM: 1050000,
  preferredCustodian: "CustodianName1",
  preferredBroker: "BrokerName1",
};
