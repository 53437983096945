import React from "react";
import ResetUserPassword from "../component/UserPassword/ResetUserPassword";

const ResetPassword = () => {
  return (
    <>
      <ResetUserPassword />
    </>
  );
};

export default ResetPassword;
