"use strict";
const axios = require("axios");

export const YahooFinance = async (ticker) => {
  const options = {
    method: "GET",
    url: "https://yahoo-finance15.p.rapidapi.com/api/yahoo/qu/quote/" + ticker,
    headers: {
      "X-RapidAPI-Key": "7ae75af16cmshc07f2d2a437876dp10b4c3jsn6953b5eced80",
      "X-RapidAPI-Host": "yahoo-finance15.p.rapidapi.com",
    },
  };

  try {
    const response = await axios.request(options);

    return response;
  } catch (error) {
    console.error(error);
  }
};
