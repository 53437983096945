'use strict';
const axios = require('axios');

export const setStrikePriceAndExpirationDate = async (ticker) => {
    const options = {
        method: 'GET',
        url: 'https://apidojo-yahoo-finance-v1.p.rapidapi.com/stock/v3/get-options',
        params: {
            symbol: ticker,
            region: 'US',
            lang: 'en-US',
        },
        headers: {
            'X-RapidAPI-Key': '7ae75af16cmshc07f2d2a437876dp10b4c3jsn6953b5eced80',
            'X-RapidAPI-Host': 'apidojo-yahoo-finance-v1.p.rapidapi.com'
        }
    };

    let optionsData = {
        strikePrice: [],
        expirationDate: [],
        error: undefined,
    }

    try {
        const response = await axios.request(options);
        optionsData.strikePrice = response.data.optionChain.result[0]?.strikes;
        optionsData.expirationDate = response.data.optionChain.result[0]?.expirationDates;
    } catch (error) {
        console.error(error);
        optionsData.error = error;
    }

    return optionsData;
}
