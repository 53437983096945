import { Helmet } from "react-helmet";
import RolesManagementLayout from "../component/Layouts/RolesManagementLayout";
import Topbar from "../component/NavComponents/Topbar";

const RolesManagement = () => {
  return (
    <>
      <Helmet>
        <title>Roles Management</title>
      </Helmet>
      <Topbar title="Roles Management" />
      <RolesManagementLayout />
    </>
  );
};
export default RolesManagement;
