import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import { Box, useTheme, ButtonBase } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { accountingCols as columns } from "../Methods";
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";
import ConfirmationPopUp from "../Popup/ConfirmationPopUp";
import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";
import moment from "moment/moment";
import Loader from "../Loader";

const FundCount = () => {
  const theme = useTheme();
  const date = moment().format("DD-MM-YYYY");
  const [openSave, setOpenSave] = useState(false);

  const [showLoader, setShowLoader] = useState(true);
  const { accountingFile } = useSelector((state) => state?.todos);
  const user = useSelector((state) => state?.users?.user?.username);
  const [data, setData] = useState();
  const [fileName, setfileName] = useState(`${date} fundCount`);
  const [filePath, setfilePath] = useState("");
  const [error, setError] = useState("");
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (accountingFile && accountingFile.fileContent) {
      setfilePath(accountingFile.filePath);
      setShowLoader(true);
      Papa.parse(accountingFile?.fileContent, {
        complete: (result) => {
          setData(result.data);
          setError("");
          setShowLoader(false);
        },
        error: (error) => {
          setAllEmpty();
        },
      });
    } else {
      setAllEmpty();
    }
  }, [accountingFile]);

  const setAllEmpty = () => {
    setData([]);
    setfilePath("");
    setError("Loading...");
    setShowLoader(false);
  };

  useEffect(() => {
    if (data) {
      setRows(alterData());
    }
  }, [data, accountingFile]);

  const alterData = () => {
    return data?.map((v, i) => {
      let tempv = v.map((n, j) => {
        return ([j] = n);
      });
      tempv.id = i;

      return { ...tempv };
    });
  };

  const EditRowsData = (obj) => {
    rows[obj.id][obj.field] = obj.value;
    let finalResult = rows.map((v, i) => {
      var farr = new Array(45);
      Object.keys(v).map((w, i) => {
        farr[Number(w)] = v[w];
      });
      return farr;
    });

    setRows([...rows]);
    setData(finalResult);
  };

  // const downloadCSVExcel = () => {

  //   if (data) {
  //     const fileExtension = ".xlsx";
  //     const fileType =
  //       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  //     const wx = XLSX.utils.json_to_sheet(data);
  //     const wb = { Sheets: { data: wx }, SheetNames: ["data"] };
  //     const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //     const dataB = new Blob([excelBuffer], { type: fileType });

  //     FileSaver.saveAs(dataB, fileName + fileExtension);
  //   }
  // };
  return (
    <Box>
      <div>
        {showLoader ? (
          <div style={{ height: 300, width: "100%" }}>
            <Loader noMin={true} mTrue={true} />
          </div>
        ) : (
          // ) : error ? (
          //   <div style={{ height: 300, width: "100%" }}>{error}</div>
          <div style={{ height: 300, width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={20}
              rowsPerPageOptions={[20]}
              rowHeight={20}
              sx={{
                "& .MuiDataGrid-cell": {
                  color: "#000",
                  fontSize: "12px",
                  border: "0.1px solid #dadada",
                },
                "& .MuiDataGrid-columnHeaders": {
                  background: theme.palette.grey[700],
                  color: theme.palette.grey[100],
                  fontSize: theme.typography.bodyText12.fontSize,
                },
              }}
              onCellEditCommit={(props, event) => {
                EditRowsData(props);
              }}
            />
          </div>
        )}
      </div>

      <Stack direction="row" justifyContent="flex-end" sx={{ mt: 1 }}>
        <div style={{ display: "inline-block" }} disabled={!data?.length > 0}>
          <ButtonBase
            variant="contained"
            disabled={!data?.length > 0}
            sx={{
              background: theme.palette.blue[200],
              width: 140,
              padding: 1,
              mr: 3,
              borderRadius: 1,
              "&:hover": { background: theme.palette.blue[300] },
            }}
          >
            <a
              href={filePath}
              target="_blank"
              style={{
                fontSize: theme.typography.bodyText14.fontSize,
                fontWeight: "bold",
                color: "#fff",
                textDecoration: "none",
              }}
            >
              Download CSV
            </a>
          </ButtonBase>
        </div>

        {/* <Button
          variant="contained"
          color="success"
          sx={{
            fontSize: theme.typography.bodyText12.fontSize,
            fontWeight: "bold",
          }}
          startIcon={<CheckCircleIcon />}
          onClick={() => {
            setOpenSave(true);
          }}
        >
          SAVE CHANGES
        </Button> */}
      </Stack>
      <ConfirmationPopUp
        title="YOUR CHANGES HAVE BEEN SAVED"
        openSave={openSave}
        setOpenSave={setOpenSave}
        fileData={data}
        fileName={fileName}
        user={user}
      />
    </Box>
  );
};

export default FundCount;
