import React from "react";
import Topbar from "../component/NavComponents/Topbar";
import { Helmet } from "react-helmet";
import SecurityMasterLayout from "../component/Layouts/SecurityMasterLayout";

const SecurityMaster = () => {
  return (
    <>
      <Helmet>
        <title>Security Master</title>
      </Helmet>
      <Topbar title="Security Master" />
      <SecurityMasterLayout />
    </>
  );
};
export default SecurityMaster;
