import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  TextField,
  Button,
  Autocomplete,
  Typography,
  FormControl,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { processData } from "../Methods";
import { useSelector } from "react-redux";
import SignalSummaryOne from "./SignalSummaryOne";
import SignalSummaryTwo from "./SignalSummaryTwo";
import SignalSummaryThree from "./SignalSummaryThree";
import SummarySectionOne from "./SignalSummaryOne/SummarySectionOne";
import SummarySectionTwo from "./SignalSummaryTwo/SummarySectionTwo";
import SummarySectionThree from "./SignalSummaryThree/SummarySectionThree";

const SignalDetails = () => {
  const [strategyVal, setstrategyVal] = useState("");
  const [strategyValObj, setstrategyValObj] = useState();
  const { strategies } = useSelector((state) => state?.todos);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  function strategySelected(id) {
    processData(strategies, id);
    setstrategyValObj(id);
  }

  const strategyList = strategies
    ?.map((i) => i?.name)
    .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0));

  return (
    <>
      <Container maxWidth="100%" sx={{ marginTop: 5, marginBottom: 3 }}>
        <Grid container spacing={2} alignItems="center">
          {/* <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "90%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "48%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: 900,
                    lineHeight: "32.89px",
                  }}
                >
                  Historical Performance
                </Typography>
              </div>
            </div>
          </Grid> */}
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                width: "20%",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <FormControl sx={{ mb: 1 }}>
                <Autocomplete
                  options={strategyList}
                  value={strategyVal}
                  autoSelect
                  onChange={(e, newValue) => {
                    setstrategyVal(newValue);
                    const strObj = strategies?.find((i) => {
                      return i?.name == newValue;
                    });
                    if (strObj && strObj?.strategyId) {
                      strategySelected(strObj?.strategyId);
                    } else {
                      setstrategyValObj();
                    }
                  }}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Strategy to Manage"
                      variant="standard"
                      fullWidth
                    />
                  )}
                />
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                  }}
                >
                  <DatePicker
                    value={startDate}
                    onChange={(newValue) => setStartDate(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Start date"
                        variant="standard"
                        sx={{ mr: 2 }}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "start-date",
                        }}
                      />
                    )}
                  />
                  <DatePicker
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="End date"
                        variant="standard"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "end-date",
                        }}
                      />
                    )}
                  />
                </div>
              </LocalizationProvider>
              <FormControl sx={{ mt: 1 }}>
                <Button variant="contained" color="primary">
                  Submit
                </Button>
              </FormControl>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "80%",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              <SummarySectionOne
                strategies={strategies}
                strategyVal={strategyValObj}
              />
              <SummarySectionTwo
                strategies={strategies}
                strategyVal={strategyValObj}
              />
              <SummarySectionThree
                strategies={strategies}
                strategyVal={strategyValObj}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "80%",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              <SignalSummaryOne
                strategies={strategies}
                strategyValObj={strategyValObj}
                strategyVal={strategyVal}
              />
              <SignalSummaryTwo
                strategies={strategies}
                strategyValObj={strategyValObj}
                strategyVal={strategyVal}
              />
              <SignalSummaryThree
                strategies={strategies}
                strategyValObj={strategyValObj}
                strategyVal={strategyVal}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default SignalDetails;
