import React, { useEffect, useState } from "react";
import { Grid, TextField, Button, Box, FormHelperText } from "@mui/material";
import { useFormik } from "formik";
import { FillsEditValidation } from "../../Schemas";
import { useSelector } from "react-redux";

function FillsEdit({ setOpenPopup, TradeForEdit, trade }) {
  const [shareCountCheck, setCheck] = useState();
  const [shareCountVal, setVal] = useState();
  useEffect(() => {
    if (
      TradeForEdit &&
      TradeForEdit?.orders &&
      TradeForEdit.orders.length > 0
    ) {
      const orderShareCount = TradeForEdit?.orders?.slice(-1)[0]?.amount;
      setVal(orderShareCount);
    }
  }, [TradeForEdit]);

  const user = useSelector((state) => state?.users?.user?.username);
  const showAccount = (TradeForEdit) => {
    if (TradeForEdit.manuals && TradeForEdit.manuals.length > 0) {
      return TradeForEdit.manuals.slice(-1).map((i) => i.shareCount);
    } else if (TradeForEdit.fill && TradeForEdit.fill.length > 0) {
      return TradeForEdit.fill.shareCount;
    } else return null;
  };
  const showSharePrice = (TradeForEdit) => {
    if (TradeForEdit.manuals && TradeForEdit.manuals.length > 0) {
      return TradeForEdit.manuals.slice(-1).map((i) => i.sharePrice);
    } else if (TradeForEdit.fill && TradeForEdit.fill.length > 0) {
      return TradeForEdit.fill.sharePrice;
    } else return null;
  };
  // const showCommission = (TradeForEdit) => {
  //   if (TradeForEdit.manuals && TradeForEdit.manuals.length > 0) {
  //     return TradeForEdit.manuals.slice(-1).map((i) => i.commission);
  //   } else if (TradeForEdit.fill && TradeForEdit.fill.length > 0) {
  //     return TradeForEdit.fill.commission;
  //   } else return null;
  // };

  const initialValues = {
    shareCount: showAccount(TradeForEdit),
    sharePrice: showSharePrice(TradeForEdit),
    // commission: showCommission(TradeForEdit),
  };

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: FillsEditValidation,

    onSubmit: ({ shareCount, sharePrice, commission }) => {
      if (shareCountVal && shareCountVal < shareCount) {
        setCheck(
          `Completed shares must be less than or equal to ${shareCountVal}`
        );
        return null;
      }
      setCheck();

      window.clientSocks.send(
        `{"action":"modify_trade_fills", "data": ${JSON.stringify([
          {
            sharecount: Number(shareCount),
            shareprice: Number(sharePrice),
            trade,
            user,
          },
        ])}}`
      );
      setOpenPopup(false);
    },
  });

  return (
    <Box component="form" autoComplete="off" onSubmit={handleSubmit}>
      <Grid item xs={12} marginBottom="25px">
        <TextField
          fullWidth
          name="shareCount"
          id="shareCount"
          label="Completed Shares"
          value={values.shareCount}
          onChange={(e) => {
            handleChange(e);
          }}
          variant="standard"
        />
        {errors.shareCount && touched.shareCount ? (
          <p className="error">*{errors.shareCount}</p>
        ) : null}
        {shareCountCheck && (
          <FormHelperText error>{shareCountCheck}</FormHelperText>
        )}
      </Grid>

      <Grid item xs={12} marginBottom="25px">
        <TextField
          fullWidth
          name="sharePrice"
          id="sharePrice"
          label="Completion Price"
          value={values.sharePrice}
          onChange={handleChange}
          variant="standard"
        />
        {errors.sharePrice && touched.sharePrice ? (
          <p className="error">*{errors.sharePrice}</p>
        ) : null}
      </Grid>

      {/* <Grid item xs={12} marginBottom="45px">
        <TextField
          fullWidth
          name="commission"
          id="commission"
          label="Total Commission"
          value={values.commission}
          onChange={(e) => {
            handleChange(e);
          }}
          variant="standard"
        />
        {errors.commission && touched.commission ? (
          <p className="error">*{errors.commission}</p>
        ) : null}
      </Grid> */}

      <Grid
        item
        xs={12}
        margin="10px"
        display="flex"
        justifyContent="space-between"
      >
        <Button variant="contained" sx={{ width: "30%" }} type="submit">
          SAVE
        </Button>
        <Button
          variant="contained"
          sx={{
            width: "30%",
            background: "#B2BEB5",
            color: "#000",
            "&:hover": { background: "#808080" },
          }}
          onClick={() => {
            setOpenPopup(false);
          }}
        >
          CANCEL
        </Button>
      </Grid>
    </Box>
  );
}

export default FillsEdit;
