import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Topbar from "../component/NavComponents/Topbar";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Box, IconButton, styled, Typography, useTheme } from "@mui/material";
import { Helmet } from "react-helmet";

const Initials = () => {
  const { lots, accountingData } = useSelector((state) => state?.todos);
  return (
    <>
      <Helmet>
        <title>Initials</title>
      </Helmet>
      <Topbar title="Initials" />

      <Grid container spacing={2} sx={{ padding: 2 }}>
        <Grid item xs={10} marginBottom="5px">
          <h2>LOTS</h2>
          <TextField
            value={JSON.stringify(lots)}
            rows={10}
            multiline
            sx={{ height: 100, width: "100%", marginBottom: 30 }}
          ></TextField>

          <h2>ACCOUNTING DATA</h2>
          <TextField
            value={JSON.stringify(accountingData)}
            rows={10}
            multiline
            sx={{ height: 100, width: "100%" }}
          ></TextField>
        </Grid>
      </Grid>
    </>
  );
};
export default Initials;
