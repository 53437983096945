import * as React from "react";
import Box from "@mui/material/Box";
import "../../App.css";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import {
  Button,
  InputLabel,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useFormik } from "formik";
import { StrategyName } from "../../component/Schemas";
import { useSelector } from "react-redux";

const AddStrategy = ({ setOpenPopup }) => {
  const { strategies, managers } = useSelector((state) => state?.todos);
  const [formats, setFormats] = React.useState(() => ["personal"]);

  const handleFormat = (e) => {
    setFormats([e.target.value]);
  };

  const initialValues = {
    name: "",
    description: "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: StrategyName,

      onSubmit: (values) => {
        

        if (strategies?.find((a) => a.name.toLowerCase() === values.name.toLowerCase())) {
          alert(`${values.name} already exists, try new one.`);
        } else {
          window.clientSocks.send(
            `{"action":"new_strategy", "data":${JSON.stringify({
              ...values,
            })}}`
          );
          setOpenPopup(false);
        }
      },
    });
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
      onSubmit={handleSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} marginBottom="5px">
          <TextField
            fullWidth
            id="name"
            label="Full Name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="standard"
          />
          {errors.name && touched.name ? (
            <p className="error">*{errors.name}</p>
          ) : null}
        </Grid>
        <Grid item xs={12} marginBottom="5px">
          <TextField
            fullWidth
            id="description"
            label="Description"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="standard"
          />
          {errors.description && touched.description ? (
            <p className="error">*{errors.description}</p>
          ) : null}
        </Grid>
        {managers && managers.length > 0 ? (
          <Grid
            item
            xs={12}
            marginTop="5px"
            display="flex"
            justifyContent="space-between"
          >
            {" "}
            <Grid container spacing={2}>
              <Grid item xs={2} marginTop="5px">
                <InputLabel>Owner</InputLabel>
              </Grid>
              <Grid item xs={10} marginBottom="5px">
                <ToggleButtonGroup
                  value={formats}
                  onChange={handleFormat}
                  aria-label="text formatting"
                  sx={{ width: "50%", marginLeft:'-30px',marginTop:'-5px' }}
                  
                >
                  <ToggleButton
                    value="personal"
                    aria-label="personal"
                    color="primary"
                    size="small"
                    sx={{ borderRadius: "20px", width: "100%" }}
                  >
                    Personal
                  </ToggleButton>
                  <ToggleButton
                    value="manager"
                    aria-label="manager"
                    color="primary"
                    size="small"
                    sx={{ borderRadius: "20px", width: "100%" }}
                  >
                    Manager
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        <Grid
          item
          xs={12}
          margin="10px"
          display="flex"
          justifyContent="space-between"
        >
          <Button variant="contained" sx={{ width: "30%" }} type="submit">
            SAVE
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "30%",
              background: "#B2BEB5",
              color: "#000",
              "&:hover": { background: "#808080" },
            }}
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            CANCEL
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
export default AddStrategy;
