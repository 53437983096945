import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

const LoaderPopup = ({ open, loadingItems, }) => {
  const allItemsCompleted = loadingItems.every(
    (item) => item.status !== "loading"
  );
  const anyItemFailed = loadingItems.some((item) => item.status === "failed");

  return (
    <Dialog open={open} aria-labelledby="loading-dialog-title">
      <DialogContent>
        {loadingItems.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: 1,
            }}
          >
            {item.status === "loading" && <CircularProgress size={20} />}
            {item.status === "completed" && <CheckCircleIcon color="success" />}
            {item.status === "failed" && <ErrorIcon color="error" />}
            <Typography
              sx={{
                marginLeft: 2,
                color: item.status === "failed" ? "red" : "inherit",
              }}
            >
              {item.name} -{" "}
              {item.status === "loading"
                ? "Loading"
                : item.status === "completed"
                ? "Completed"
                : "Failed"}
            </Typography>
          </Box>
        ))}
        {allItemsCompleted && (
          <Typography
            sx={{
              marginTop: 2,
              textAlign: "center",
              color: anyItemFailed ? "red" : "blue",
              fontWeight: "bold",
            }}
          >
            {anyItemFailed ? "Sync Failed!" : "Sync Completed!"}
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default LoaderPopup;
