import Header from "../Header";
import SignalsInputAllocation from "../SignalsInputDetails/SignalsInputAllocation";
const SignalsInputLayout = () => {
  return (
    <>
      <Header>
        <SignalsInputAllocation />
      </Header>
    </>
  );
};
export default SignalsInputLayout;
