import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  TextField,
  Button,
  Autocomplete,
  Typography,
  Box,
  useTheme,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { ButtonAction } from "../../../Redux/Actions/todoActions";
import ExistingAllocation from "./ExistingAllocation";
import TargetAllocation from "./TargetAllocation";
import TotalFund from "./TotalFund";
import Strategy from "./Strategy";
import Preference from "./Preference";
import {
  calculateAndFormatNoAbs,
  findStrategy,
  formatValue,
  twoDecimal,
} from "../../Methods";
import { toast } from "react-toastify";
import { GetAllocation } from "../../Layouts/dashboardCalculations";
import Existing from "./TotalFund/Existing";
import Target from "./TotalFund/Target";
import { updateLoading } from "../../../Redux/Reducers/todoReducers";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
// import { funds, accounting, strategies, allocation } from "../../dummyData";

const FundAllocation = () => {
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState(0);
  const [matchedAllocations, setMatchedAllocations] = useState([]);
  const [isAllocationValid, setIsAllocationValid] = useState(true);
  const [fundId, setFundId] = useState();
  const [oldData, setOldData] = useState([]);
  const [existing, setExisting] = useState([]);
  const [fundAum, setFundAum] = useState(0);
  const [totals, setTotals] = useState([]);
  const [fundVal, setFundVal] = useState();
  const [Strategies, setStrategy] = useState([]);
  const [StrategiesLowAum, setStrategyLowAum] = useState([]);
  const [StrategiesCombined, setStrategyCombined] = useState([]);
  const [autoCompletedValue, setautoCompletedValue] = useState([]);
  const [donutTwo, setdonutTwo] = useState([25, 25, 25, 25]);
  const [editableValue, setEditableValue] = useState(0);
  const [Piedata, setPieData] = useState([]);
  const [PiedataTarget, setPieDataTarget] = useState([]);
  const Buttonflag = useSelector((state) => state?.todos?.buttonflag);
  const [showRecalculate, setShowRecalculate] = useState(true);
  // useEffect(() => {
  //   setEditableData(totals);
  //   setEditableValue(formatValue(fundAum));
  // }, [totals, oldData, fundAum]);
  const {
    funds,
    strategies,
    accountingData,
    allocation,
    lots,
    marketData,
    data,
  } = useSelector((state) => state?.todos);
  // const accountingData = Object.values(accounting);
  const dispatch = useDispatch();
  const sortedFunds = funds
    ?.map((i) => i?.abbreviation)
    ?.filter((abbreviation) => abbreviation)
    ?.sort((a, b) => a.localeCompare(b));
  const sortedData = data?.slice().sort((a, b) => {
    if (a.abbreviation && b.abbreviation) {
      return a.abbreviation.localeCompare(b.abbreviation);
    } else if (a.abbreviation) {
      return -1;
    } else if (b.abbreviation) {
      return 1;
    } else {
      return 0;
    }
  });
  useEffect(() => {
    if (Strategies.length > 0) {
      const sortedStrategies = [...Strategies].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      const highAumStrategies = sortedStrategies.filter(
        (strategy) => strategy?.aum >= 10
      );
      const lowAumStrategies = sortedStrategies.filter(
        (strategy) => strategy?.aum < 10
      );
      setStrategyLowAum(lowAumStrategies);
      setStrategyCombined(highAumStrategies);
    }
  }, [Strategies]);
  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  useEffect(() => {
    dispatch(ButtonAction(false));
  }, [dispatch]);

  useEffect(() => {
    if (Buttonflag) {
      setShowRecalculate(true);
    }
  }, [Buttonflag, allocation]);

  useEffect(() => {
    const fundList = funds
      ?.map((i) => i?.abbreviation)
      ?.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: "base" }));
    setFundVal(fundList?.[0]);
  }, [funds]);

  const getFund = (fund) => {
    window.clientSocks.send(
      `{"action":"get_allocation", "data":${JSON.stringify({
        fund: fund,
      })}}`
    );
    dispatch(updateLoading(true));
  };

  const updateTotals = (updatedAllocations) => {
    const totalLong = updatedAllocations.reduce(
      (sum, strategy) => sum + (parseFloat(strategy.percentLong) || 0),
      0
    );
    const totalShort = updatedAllocations.reduce(
      (sum, strategy) => sum + (parseFloat(strategy.percentShort) || 0),
      0
    );
    const longFundAum = fundAum * (totalLong / 100);
    const shortFundAum = fundAum * (totalShort / 100);
    const totalforPercent = totalLong + totalShort;
    const totalAums = longFundAum + shortFundAum;
    const netPercent = totalLong - totalShort;
    const netFundAum = longFundAum - shortFundAum;

    const newTotals = [
      {
        changeValue: twoDecimal(totalLong),
        changeAllocation: calculateAndFormatNoAbs(longFundAum),
      },
      {
        changeValue: twoDecimal(totalShort),
        changeAllocation: calculateAndFormatNoAbs(shortFundAum),
      },
      {
        changeValue: twoDecimal(totalforPercent),
        changeAllocation: calculateAndFormatNoAbs(totalAums),
      },
      {
        changeValue: twoDecimal(netPercent),
        changeAllocation: calculateAndFormatNoAbs(netFundAum),
      },
    ];

    setTotals(newTotals);
  };
  const updateLongShort = (updatedAllocations) => {
    const totalLong = updatedAllocations.reduce(
      (sum, strategy) => sum + (parseFloat(strategy.long) || 0),
      0
    );
    const totalShort = updatedAllocations.reduce(
      (sum, strategy) => sum + (parseFloat(strategy.short) || 0),
      0
    );
    const longFundAum = fundAum * (totalLong / 100);
    const shortFundAum = fundAum * (totalShort / 100);
    const totalforPercent = totalLong + totalShort;
    const totalAums = longFundAum + shortFundAum;
    const netPercent = totalLong - totalShort;
    const netFundAum = longFundAum - shortFundAum;

    const newTotals = [
      {
        name: "Total for Long",
        changeValue: twoDecimal(totalLong),
        changeAllocation: calculateAndFormatNoAbs(longFundAum),
      },
      {
        name: "Total for Short",
        changeValue: twoDecimal(totalShort),
        changeAllocation: calculateAndFormatNoAbs(shortFundAum),
      },
      {
        name: "Total Gross",
        changeValue: twoDecimal(totalforPercent),
        changeAllocation: calculateAndFormatNoAbs(totalAums),
      },
      {
        name: "Total Net",
        changeValue: twoDecimal(netPercent),
        changeAllocation: calculateAndFormatNoAbs(netFundAum),
      },
    ];

    setOldData(newTotals);
  };

  useEffect(() => {
    if (
      allocation &&
      allocation.fundAllocation &&
      StrategiesCombined.length > 0
    ) {
      const fundAllocations = allocation.fundAllocation;
      const sortedStrategies = [...StrategiesCombined].sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      const newAllocations = sortedStrategies?.map((strategy) => {
        const allocationData = fundAllocations[strategy.strategyId];
        if (allocationData) {
          const { allocation: alloc, ...rest } = allocationData;
          const { long, short } = GetAllocation(
            fundId,
            strategy?.name,
            lots,
            accountingData,
            funds,
            strategies,
            marketData
          );
          const existingPercent = calculateAndFormatNoAbs(
            (strategy?.aum / fundAum) * 100
          );
          const formattedLong = twoDecimal((long / strategy?.aum) * 100);
          const formattedShort = twoDecimal(
            (Math.abs(short) / strategy?.aum) * 100
          );
          return {
            ...strategy,
            ...rest,
            ignoreWhenRebalancing: alloc?.ignoreWhenRebalancing,
            allocation: calculateAndFormatNoAbs(
              fundAum * alloc.totalAllocation
            ),
            totalAllocation: twoDecimal(alloc.totalAllocation || 0),
            percentShort: twoDecimal(alloc.percentShort || 0),
            percentLong: twoDecimal(alloc.percentLong || 0),
            existingPercent,
            long: formattedLong,
            short: formattedShort,
          };
        } else {
          const { long, short } = GetAllocation(
            fundId,
            strategy?.name,
            lots,
            accountingData,
            funds,
            strategies,
            marketData
          );
          const existingPercent = calculateAndFormatNoAbs(
            (strategy?.aum / fundAum) * 100
          );
          const formattedLong = twoDecimal((long / strategy?.aum) * 100);
          const formattedShort = twoDecimal(
            (Math.abs(short) / strategy?.aum) * 100
          );

          return {
            ...strategy,
            broker: 0,
            account: 0,
            totalAllocation: twoDecimal(0),
            percentShort: twoDecimal(0),
            ignoreWhenRebalancing: false,
            percentLong: twoDecimal(0),
            existingPercent,
            long: formattedLong,
            short: formattedShort,
          };
        }
      });
      const pieExisting = newAllocations?.map((strategy) => ({
        name: strategy?.name,
        value: Number(strategy?.existingPercent) || 0,
      }));
      const pieTarget = newAllocations?.map((strategy) => ({
        name: strategy?.name,
        value: Number(strategy?.totalAllocation) || 0,
      }));
      setPieData(pieExisting);
      setPieDataTarget(pieTarget);
      setMatchedAllocations(newAllocations);
      setExisting(newAllocations);
      updateTotals(newAllocations);
      updateLongShort(newAllocations);
    } else {
      const zeroedAllocations = Strategies.map((strategy) => ({
        ...strategy,
        broker: 0,
        account: 0,
        totalAllocation: twoDecimal(0),
        percentShort: twoDecimal(0),
        ignoreWhenRebalancing: false,
        percentLong: twoDecimal(0),
        existingPercent: 0,
        long: 0,
        short: 0,
      }));

      setMatchedAllocations(zeroedAllocations);
      setExisting(zeroedAllocations);
      setOldData([
        { name: "Total for Long", changeValue: 0, changeAllocation: 0 },
        { name: "Total for Short", changeValue: 0, changeAllocation: 0 },
        { name: "Total Gross", changeValue: 0, changeAllocation: 0 },
        { name: "Total Net", changeValue: 0, changeAllocation: 0 },
      ]);
      setTotals([
        { changeValue: 0, changeAllocation: 0 },
        { changeValue: 0, changeAllocation: 0 },
        { changeValue: 0, changeAllocation: 0 },
        { changeValue: 0, changeAllocation: 0 },
      ]);
      setPieData([]);
    }
  }, [
    allocation,
    Strategies,
    fundAum,
    fundId,
    lots,
    accountingData,
    funds,
    marketData,
    StrategiesCombined,
  ]);

  const handleFundChange = (newValue) => {
    setFundVal(newValue);

    const item = funds?.find((e) => e.abbreviation === newValue);
    if (item?.accounts && Object.keys(item?.accounts).length) {
      const transformedAccounts = Object.keys(item.accounts)
        .map((key) => ({
          name: key,
          custodian: item.accounts[key].custodian,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));

      setautoCompletedValue(transformedAccounts);
    } else {
      setautoCompletedValue([]);
    }

    if (item && item?.fundId) {
      setFundId(item?.fundId);
       getFund(item?.fundId);
      const foundFund = accountingData[0]?.funds?.find(
        (row) => row?.fund === item?.fundId
      );

      if (foundFund) {
        setFundAum(foundFund?.aum);
        setEditableValue(formatValue(fundAum?.aum));

        const strategyAUMsData = Object.keys(foundFund?.strategyAUMs || {})
          .filter((strategyId) =>
            strategies.some((strategy) => strategy?.strategyId === strategyId)
          )
          .map((strategyId) => {
            return {
              strategyId: strategyId,
              name: findStrategy(strategyId, strategies),
              aum: foundFund?.strategyAUMs[strategyId],
            };
          });
        if (strategyAUMsData?.length > 0) {
          setMatchedAllocations([]);
          setExisting([]);
          setStrategy(strategyAUMsData);
        } else {
          setStrategy([]);
          setMatchedAllocations([]);
          setExisting([]);
        }
      } else {
        setFundAum(0);
        setEditableValue(formatValue(0));
        setFundId();
      }
    }
  };

  const handleSave = () => {
    const totalAllocationSum = matchedAllocations.reduce((sum, strategy) => {
      return sum + parseFloat(strategy.totalAllocation) / 100;
    }, 0);

    if (totalAllocationSum > 1) {
      setIsAllocationValid(false);
      toast.error("Allocation must not exceed 100%");
      return;
    } else {
      setIsAllocationValid(true);

      const formattedAllocations = matchedAllocations.reduce(
        (acc, strategy) => {
          const {
            strategyId,
            account,
            totalAllocation,
            percentShort,
            percentLong,
            ignoreWhenRebalancing,
            broker,
          } = strategy;

          if (parseFloat(totalAllocation) !== 0) {
            acc[strategyId] = {
              account: account || autoCompletedValue[0]?.name,
              allocation: {
                totalAllocation: parseFloat(totalAllocation) / 100,
                percentShort: parseFloat(percentShort) / 100,
                percentLong: parseFloat(percentLong) / 100,
                ignoreWhenRebalancing,
              },
              broker: broker || sortedData[0]?.brokerId,
            };
          }

          return acc;
        },
        {}
      );

      const finalData = {
        action: "set_allocation",
        data: {
          fundAllocation: formattedAllocations,
          fund: fundId,
          targetedAUM: editableValue?.replace(/,/g, ""),
        },
      };

      window.clientSocks.send(JSON.stringify(finalData));
      dispatch(updateLoading(true));
    }
  };

  const COLORS = [
    theme.palette.grey[700],
    theme.palette.grey[800],
    theme.palette.grey[200],
  ];

  return (
    <>
      <Container maxWidth="100%" sx={{ marginTop: 5, marginBottom: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "90%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "48%",
                }}
              >
                <Typography sx={{ fontSize: "24px", fontWeight: 900 }}>
                  Use this Sheet to determine the allocations to strategies of
                  any fund you might wish to rebalance.
                </Typography>
                <Typography sx={{ fontSize: "16px", fontWeight: 400 }}>
                  Enter the target allocations for this Fund's strategies on
                  each row of the strategy under "Target Allocations", then
                  press make changes.
                </Typography>
              </div>
              <div>
                <Button variant="contained" onClick={handleSave}>
                  SAVE CHANGES
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              value={fundVal}
              options={sortedFunds}
              getOptionLabel={(option) => option}
              onChange={(e, newValue) => handleFundChange(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose Fund"
                  variant="standard"
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                height: "25vh",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <TotalFund totals={totals} oldData={oldData} fundAum={fundAum} />
              <Existing totals={totals} oldData={oldData} fundAum={fundAum} />
              <Target
                totals={totals}
                oldData={oldData}
                fundAum={fundAum}
                editableValue={editableValue}
                setEditableValue={setEditableValue}
              />

              {Piedata.length > 0 ? (
                <div
                  style={{
                    width: "35%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <Typography sx={{ fontSize: "24px", fontWeight: 900 }}>
                      Existing Allocation
                    </Typography>
                    <PieChart width={300} height={300}>
                      <Pie
                        data={Piedata}
                        cx={150}
                        cy={150}
                        innerRadius={60}
                        outerRadius={120}
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {Piedata.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </div>
                  <div>
                    <Typography sx={{ fontSize: "24px", fontWeight: 900 }}>
                      Target Allocation
                    </Typography>
                    <PieChart width={300} height={300}>
                      <Pie
                        data={PiedataTarget}
                        cx={150}
                        cy={150}
                        innerRadius={60}
                        outerRadius={120}
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {PiedataTarget.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </div>
                </div>
              ) : (
                <Typography>No data available</Typography>
              )}
            </div>
          </Grid>

          <Grid item xs={12}>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Typography sx={{ fontSize: "25px", fontWeight: 900 }}>
                Fund Allocation
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  height: "25vh",
                }}
              >
                <Strategy
                  matchedAllocations={matchedAllocations}
                  StrategiesLowAum={StrategiesLowAum}
                  setStrategyCombined={setStrategyCombined}
                />
                <ExistingAllocation existing={existing} />
                <TargetAllocation
                  matchedAllocations={matchedAllocations}
                  setMatchedAllocations={setMatchedAllocations}
                  updateTotals={updateTotals}
                  fundAum={fundAum}
                  setPieDataTarget={setPieDataTarget}
                />
                <Preference
                  matchedAllocations={matchedAllocations}
                  custodians={autoCompletedValue}
                  setMatchedAllocations={setMatchedAllocations}
                  sortedData={sortedData}
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default FundAllocation;
