import { Helmet } from "react-helmet";
import SmaLayout from "../component/Layouts/SmaLayout";
import Topbar from "../component/NavComponents/Topbar";

const Sma = () => {
  return (
    <>
      <Helmet>
        <title>SMA Performace</title>
      </Helmet>
      <Topbar title="SMA Performance" />
      <SmaLayout />
    </>
  );
};
export default Sma;
