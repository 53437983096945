import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Toolbar,
  Tooltip,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Box,
  Menu,
  MenuItem,
  Button,
  useTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import { StyledRoot, StyledSearch } from "../../Methods";
import Popup from "../../Popup";
import AddBlacklistedTicker from "../AddBlacklistedTicker";

const ListToolbar = ({
  filterName,
  onFilterName,
  setOrderBy,
  setOrder,
  setData,
  data,
}) => {
  const [anchorEl, setAnchorEl] = useState("");
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openPopup, setOpenPopup] = useState(false);
  const theme = useTheme();
  return (
    <>
      <StyledRoot>
        <Box>
          <StyledSearch
            value={filterName}
            onChange={onFilterName}
            placeholder="Search..."
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon
                  sx={{ color: "text.disabled", width: 15, height: 15 }}
                />
              </InputAdornment>
            }
          />

          <Tooltip title="Filter list">
            <IconButton
              id="demo-positioned-button"
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              sx={{ marginLeft: "20px" }}
            >
              <FilterListIcon
                sx={{ width: "20px", height: "20px", color: "#000" }}
              />
            </IconButton>
          </Tooltip>
        </Box>
        <Button
          text="ADD CUSTODIANS"
          variant="contained"
          sx={{ height: 40, width: 250, fontSize: theme.typography.bodyText17 }}
          onClick={() => {
            setOpenPopup(true);
          }}
        >
          <AddIcon sx={{ width: "20px", height: "20px", mr: 1 }} />
          ADD BLACKLISTED TICKER
        </Button>
      </StyledRoot>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ marginTop: 3 }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={() => {
            setOrderBy("ticker");
            setOrder("asc");
            handleClose();
          }}
        >
         ticker
        </MenuItem>
      </Menu>

      <Popup
        title="ADD BLACKLISTED TICKER"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <AddBlacklistedTicker
          setData={setData}
          setOpenPopup={setOpenPopup}
          data={data}
        />
      </Popup>
    </>
  );
};

export default ListToolbar;
