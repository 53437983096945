import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Grid,
  useTheme,
  Table,
  TableRow,
  TableBody,
  Box,
} from "@mui/material";
import {
  Tablecell,
  TABLE_HEAD_FUND_REBALANCE_BLACKLIST,
  applySortFilter,
  getComparator,
} from "../../Methods";
import VantageListHead from "../../ListHead/SignalList";

const BlacklistSignal = (props) => {
  const {
    title,
    openBlacklistPopup,
    setBlacklistOpenPopup,
    pastedArray,
    setpasted,
    blacklist,
  } = props;

  const theme = useTheme();
  const [data, setData] = useState(blacklist);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("ticker");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    setData(blacklist);
    //   }, [blacklist]);
  }, []);

  useEffect(() => {
    const filteredData = applySortFilter(data, getComparator(order, orderBy));
    setData(filteredData);
    // setBlacklistData(filteredData);
  }, [order, orderBy]);

  const transformedData = data.map((row) => ({
    ticker: row.ticker?.symbol,
    reason: row.reason,
  }));
  const deleteItem = () => {
    setpasted((prevArray) => {
      const filteredArray = prevArray.filter((item) => {
        return !data?.some(
          (entry) =>
            entry?.ticker?.symbol?.toLowerCase() === item?.name?.toLowerCase()
        );
      });

      return filteredArray;
    });
    setBlacklistOpenPopup(false);
  };

  return (
    <Dialog
      fullWidth
      open={openBlacklistPopup}
      sx={{
        "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
          minWidth: "1000px",
          height: "100vh",
        },
      }}
    >
      <DialogTitle>
        <Typography
          variant="h6"
          component="div"
          align="center"
          style={{ flexGrow: 1, fontSize: "20px" }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ height: "50vh", overflow: "auto" }} dividers>
        <Typography>
          Following tickers in signal set are blacklisted by manager.
        </Typography>
        <Box sx={{ width: "100%" }}>
          <Table
            size="small"
            stickyHeader
            aria-label="a dense table"
            sx={{
              borderCollapse: "collapse",
              border: "solid 1px #eee",
              marginTop: "-1px",
            }}
          >
            <VantageListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD_FUND_REBALANCE_BLACKLIST}
              noStatus={true}
              customizeFont={12}
              rowCount={transformedData.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {transformedData?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:hover": {
                      background: theme.palette.grey[200],
                      cursor: "pointer",
                    },
                  }}
                >
                  <Tablecell
                    align="left"
                    style={{
                      fontSize: "12px",
                      paddingLeft: "10px",
                      borderLeft: "none",
                      borderRight: "none",
                      width: "10px",
                    }}
                  >
                    {row.ticker}
                  </Tablecell>

                  <Tablecell
                    align="left"
                    style={{
                      fontSize: "12px",
                      paddingLeft: "10px",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  >
                    {row?.reason}
                  </Tablecell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </DialogContent>
      <Grid
        item
        xs={12}
        margin="20px 10px 20px 10px"
        display="flex"
        justifyContent="space-between"
        columnGap={1}
      >
        <Button
          variant="contained"
          size="small"
          sx={{
            width: "40%",
            background: theme.palette.red[200],
            "&:hover": { background: theme.palette.red[300] },
          }}
          onClick={deleteItem}
        >
          Remove All
        </Button>
        <Button
          variant="contained"
          sx={{
            width: "40%",
            background: "#B2BEB5",
            color: "#000",
            "&:hover": { background: "#808080" },
          }}
          onClick={() => {
            setBlacklistOpenPopup(false);
          }}
        >
          Ignore All
        </Button>
      </Grid>
    </Dialog>
  );
};

export default BlacklistSignal;
