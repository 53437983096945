import Header from "../Header";
import StrategyTable from "../StrategiesDetails/StrategyTable";
const TradeFundsStrategies = () => {
  return (
    <>
      <Header >
        <StrategyTable />
      </Header>
    </>
  );
};
export default TradeFundsStrategies;
