import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MenuItem } from "react-pro-sidebar";
import { Typography, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";

const Item = ({ title, to, icon, onClick, isCollapsed, setIsCollapsed }) => {
  const theme = useTheme();
  const location = useLocation();
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = () => {
    if (onClick) {
      onClick(title);
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const itemStyle = {
    color: theme.palette.grey[100],
    height: "35px",
    width: !isCollapsed ? "220px" : "",
    marginTop: "5px",
    borderRadius: "5px",
    backgroundColor: isHovered ? theme.palette.grey[600] : "transparent",
    transition: "background-color 0.3s ease",
  };

  const activeItemStyle = {
    backgroundColor: theme.palette.grey[600],
  };

  return (
    <MenuItem
      active={to === location.pathname}
      style={{ ...itemStyle, ...(to === location.pathname && activeItemStyle) }}
      icon={icon}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Typography sx={{ fontSize: "12px", ml: 1 }}>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

export default Item;
