import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Button,
  Box,
  Alert,
  Select,
  MenuItem,
  Autocomplete,
  FormControl,
  TextField,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  TABLE_HEAD_FUND_REBALANCE_LOCATES,
  findStrategy,
} from "../../../../../Methods";
import Locates from "./components/Locates";
import LocatesNotFoundPopup from "../../../../../Popup/LocatesNotFoundPopup";
import { useDispatch, useSelector } from "react-redux";
import { stageTwoOfRebalance } from "../../../../../../Redux/Actions/todoActions";

const FundRebalanceStep2 = ({ sleeve, setCurrentStep, onPrevious }) => {
  const dispatch = useDispatch();
  const { strategies, tickerTrades, identifier, custodians } = useSelector(
    (state) => state.todos
  );
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedCustodian, setSelectedCustodian] = useState(null);
  const [locatesData, setLocatesData] = useState([]);
  const [selectedStrategy, setSelectedStrategy] = useState("");
  const CustodianList = (custodians?.map((i) => i?.abbreviation) || [])?.sort();

  useEffect(() => {
    if (sleeve) {
      const strategyIds = Object.keys(tickerTrades);
      if (strategyIds.length > 0) {
        const defaultStrategyId = strategyIds[0];
        setSelectedStrategy(defaultStrategyId);
      }
    } else {
      setSelectedStrategy("");
    }
  }, [sleeve, tickerTrades]);

  useEffect(() => {
    if (selectedStrategy && tickerTrades) {
      const strategyTickerTrades = tickerTrades[selectedStrategy];
      if (strategyTickerTrades && strategyTickerTrades.length > 0) {
        const updatedData = strategyTickerTrades.map((trade) => ({
          ticker: trade.ticker.symbol,
          shareCount: trade.requiredLocates?.toFixed(2),
          available: trade?.availableLocates?.toFixed(2) || 0,
          tickerDetails: trade.ticker,
        }));
        setLocatesData(updatedData);
      }
    }
  }, [selectedStrategy, tickerTrades]);

  const handleFindLocatesClick = () => {
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };
  const onNext = () => {
    if (locatesData && locatesData.length > 0) {
      const updatedTickerTrades = Object.keys(tickerTrades).reduce(
        (acc, strategyId) => {
          if (strategyId === selectedStrategy) {
            const updatedTrades = locatesData.map((trade) => ({
              ...tickerTrades[strategyId].find(
                (t) => t.ticker.symbol === trade.ticker
              ),
              ticker: {
                ...trade.tickerDetails,
              },
              requiredLocates: parseFloat(trade.shareCount),
              availableLocates: parseFloat(trade.available),
            }));

            acc[strategyId] = updatedTrades;
          } else {
            acc[strategyId] = tickerTrades[strategyId];
          }

          return acc;
        },
        {}
      );
      const data = {
        data: updatedTickerTrades,
        identifier: identifier,
      };
      dispatch(stageTwoOfRebalance(data));
    }
    setCurrentStep((prev) => prev + 1);
  };

  const handleStrategyChange = (e) => {
    const newStrategyId = e.target.value;
    setSelectedStrategy(newStrategyId);
  };


  return (
    <Container disableGutters maxWidth="100%" sx={{ marginTop: 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box
              sx={{
                marginBottom: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between", // This ensures even spacing
                width: "40%", // Use full width to distribute evenly
              }}
            >
              {/* Find Locates Button */}
              <Button
                variant="contained"
                onClick={handleFindLocatesClick}
                sx={{
                  width: "170px",
                  height: "40px",
                  fontSize: "12px",
                  backgroundColor: "black",
                  color: "white",
                  "&:hover": { backgroundColor: "darkgray" },
                }}
              >
                Find Locates
              </Button>

              {/* Strategy Selection */}
              {sleeve && (
                <Box sx={{ width: "30%" }}>
                  <Select
                    value={selectedStrategy}
                    onChange={handleStrategyChange}
                    sx={{
                      width: "100%",
                      border: "none",
                      fontSize: "12px",
                      "& fieldset": { border: "none" },
                      "&:hover fieldset": { border: "none" },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                  >
                    {Object.keys(tickerTrades)?.map((strategyId) => (
                      <MenuItem key={strategyId} value={strategyId}>
                        {findStrategy(strategyId, strategies)}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              )}
            </Box>

            {Object.keys(tickerTrades) && Object.keys(tickerTrades).length > 0 ? (
              <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  color="grey"
                  startIcon={<ArrowBackIcon />}
                  onClick={onPrevious}
                  sx={{ width: "150px", height: "40px" }}
                >
                  BACK
                </Button>
                <Button
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                  onClick={onNext}
                  sx={{ width: "150px", height: "40px" }}
                >
                  Next
                </Button>
              </Box>
            ) : null}
          </div>
        </Grid>

        <Grid item xs={5}>
          <Alert severity="info">
            Locates Available column is editable for custodians which don't have
            direct API integration.
          </Alert>
        </Grid>
        {locatesData && locatesData.length > 0 ? (
          <>
            <Grid item xs={12}>
              <FormControl sx={{ width: "10%" }}>
                <Autocomplete
                  options={CustodianList}
                  getOptionLabel={(option) => option}
                  onChange={(event, newValue) => {
                    setSelectedCustodian(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Custodian"
                      variant="standard"
                      fullWidth
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "40%",
                  justifyContent: "space-between",
                }}
              >
                <Locates
                  tickerTrades={locatesData}
                  setLocatesData={setLocatesData}
                  tableHeader={TABLE_HEAD_FUND_REBALANCE_LOCATES}
                />
              </div>
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <p>No data available</p>{" "}
          </Grid>
        )}
      </Grid>

      <LocatesNotFoundPopup open={popupOpen} onClose={handleClosePopup} />
    </Container>
  );
};

export default FundRebalanceStep2;
