import React from "react";
import Topbar from "../component/NavComponents/Topbar";
import Settings from "../component/Settings";
import { Helmet } from "react-helmet";
const Setting = () => {
  return (
    <>
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <Topbar title="Settings" />
      <Settings />
    </>
  );
};
export default Setting;
