import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Grid,
  useTheme,
} from "@mui/material";
import Papa from "papaparse";
import Successprompt from "./Successprompt";
import Failedprompt from "./Failedprompt";
import { useSelector } from "react-redux";
const SendTradeForCustodian = (props) => {
  const theme = useTheme();

  const {
    title,
    openTradeForallCustodian,
    setOpenTradeForallCustodian,
    fileName,
    fileData,
    user,
    custodian,
  } = props;

  const [successprompt, setsuccessprompt] = useState(false);
  const [failprompt, setfailprompt] = useState(false);
  const { Ftp } = useSelector((state) => state?.todos);

  const SendBoFTrade = () => {
    let fileContent = Papa.unparse(fileData);
    window.clientSocks.send(
      `{"action":"send_eod", "data":${JSON.stringify(custodian)}}`
    );
    setOpenTradeForallCustodian(false);
    if (Ftp[0]?.status === "successful") setsuccessprompt(true);
    else setfailprompt(true);
  };
  return (
    <>
      <Dialog
        open={openTradeForallCustodian}
        sx={{ width: "100%", height: "100%" }}
      >
        <DialogTitle>
          <Typography
            variant="h6"
            component="div"
            align="center"
            style={{ flexGrow: 1, fontSize: "20px" }}
          >
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="body" component="div" style={{ flexGrow: 1 }}>
            Are you sure you want to Post Trade for this Custodian?
          </Typography>
          <Grid
            item
            xs={12}
            margin="20px 1px 20px 1px "
            display="flex"
            justifyContent="space-between"
          >
            <Button
              variant="contained"
              sx={{
                width: "40%",
              }}
              onClick={() => {
                SendBoFTrade();
              }}
            >
              POST TRADE
            </Button>
            <Button
              variant="contained"
              sx={{
                width: "40%",
                background: "#B2BEB5",
                color: "#000",
                "&:hover": { background: "#808080" },
              }}
              onClick={() => {
                setOpenTradeForallCustodian(false);
              }}
            >
              CANCEL TRADE
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
      <Successprompt
        title="Success"
        openTradeForCustodian={successprompt}
        setOpenTradeForCustodian={setsuccessprompt}
      />

      <Failedprompt
        title="Success"
        openTradeForCustodian={successprompt}
        setOpenTradeForCustodian={setsuccessprompt}
      />
    </>
  );
};

export default SendTradeForCustodian;
