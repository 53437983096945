import FundAllocation from "../FundAllocatorDetails/FundAllocation";
import Header from "../Header";
const FundAllocatorLayout = () => {
  return (
    <>
      <Header>
        <FundAllocation />
      </Header>
    </>
  );
};
export default FundAllocatorLayout;
