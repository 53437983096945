import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import {
  Button,
  FormHelperText,
  InputLabel,
  Typography,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useState } from "react";
import { SecurityMap } from "../Schemas";

const AddSecurity = ({ data, setOpenPopup, setData }) => {
  const { custodians } = useSelector((state) => state?.todos);
  const [autocompletedValue, setautoCompletedValue] = useState([]);
  const [account, setAccount] = useState({});
  const [accountValueError, setAccountValueError] = useState("");

  const MapArray = [
    { name: "IBKR", value: 1 },
    { name: "ESMX", value: 2 },
  ];

  const initialValues = {
    name: "",
    selection: [],
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: SecurityMap,
    onSubmit: (values) => {
      setOpenPopup(false);
    },
  });

  const checkTestKeys = () => {
    return MapArray.map((item) => item.name);
  };

  const handleSelectionChange = (event, value) => {
    setautoCompletedValue(value);
    setFieldValue(
      "selection",
      value.map((item) => ({
        name: item,
        symbol: account[item] || "",
      }))
    );
  };

  const theme = useTheme();

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{ display: "flex", justifyContent: "space-between" }}
      onSubmit={handleSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} marginBottom="5px">
          <TextField
            fullWidth
            id="name"
            label="Ticker Name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="standard"
            error={touched.name && Boolean(errors.name)}
            helperText={touched.name && errors.name}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel variant="standard" sx={{ mt: "-10px" }}>
            Map for platforms
          </InputLabel>
          <Grid container spacing={1}>
            <Grid item xs={6} sx={{ border: "1px solid #E0E0E0", mt: 1 }}>
              <Autocomplete
                multiple
                id="tags-standard"
                ListboxProps={{ sx: { fontSize: 12 } }}
                sx={{
                  "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
                    fontSize: 12,
                  },
                }}
                onChange={handleSelectionChange}
                value={autocompletedValue}
                options={checkTestKeys()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    sx={{ mb: 1 }}
                    error={!autocompletedValue.length}
                    helperText={
                      !autocompletedValue.length ? "Account required" : ""
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={6} sx={{ marginTop: "-5px" }}>
              {values.selection.map(({ name }) => (
                <React.Fragment key={name}>
                  <br />
                  <Typography
                    sx={{ fontSize: "10px", color: theme.palette.grey[300] }}
                  >
                    <span style={{ fontWeight: 700 }}>{name}</span> -{" "}
                    <span>{`${name}'s symbol`}</span>
                  </Typography>
                  <TextField
                    variant="standard"
                    type="text"
                    value={account[name] || ""}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setAccount({
                        ...account,
                        [name]: newValue,
                      });
                      setFieldValue(
                        "selection",
                        values.selection.map((item) =>
                          item.name === name
                            ? { ...item, symbol: newValue }
                            : item
                        )
                      );
                    }}
                    placeholder={`${name}'s Symbol`}
                  />
                </React.Fragment>
              ))}
              {accountValueError && (
                <FormHelperText error>{accountValueError}</FormHelperText>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          margin="10px"
          display="flex"
          justifyContent="space-between"
        >
          <Button variant="contained" sx={{ width: "30%" }} type="submit">
            SAVE
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "30%",
              background: "#B2BEB5",
              color: "#000",
              "&:hover": { background: "#808080" },
            }}
            onClick={() => setOpenPopup(false)}
          >
            CANCEL
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddSecurity;
