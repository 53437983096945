import React from "react";
import TradeCustodians from "../component/Layouts/TradeCustodians";
import Topbar from "../component/NavComponents/Topbar";
import { Helmet } from "react-helmet";

const Custodian = () => {
  return (
    <>
      <Helmet>
        <title>Custodians</title>
      </Helmet>
      <Topbar title="Custodians" />
      <TradeCustodians />
    </>
  );
};
export default Custodian;
