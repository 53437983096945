import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Box,
  useTheme,
  IconButton,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import Tabs from "@mui/material/Tabs";
import { useState } from "react";
import ConfirmRegenerate from "./ConfirmRegenerate";
import Csv from "../CustodianDetails/Csv";
import Bankofperssion from "../CustodianDetails/Bankofperssion";
import TradesConfirmation from "./TradesConfirmation";
import { StyledTab } from "../Methods";
import GoldmanSachs from "../CustodianDetails/GoldmanSachs";

const SendTrades = (props) => {
  const theme = useTheme();
  const { id, children, tradesPopup, setTradesOpenPopup, title } = props;
  const [openRegenerate, setOpenRegenerate] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [value, setValue] = React.useState(0);
  const [tabcustodains, setTabcustodians] = useState(true);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const GenerateConfirmation = () => {
    setOpenRegenerate(true);
    // window.clientSocks.send(JSON.stringify({"action": "generate_new"}))
  };

  const handleGenerateFile = () => {
    window.clientSocks.send('{"action": "get_eod"}');
    setTradesOpenPopup(true);
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={tradesPopup}
        sx={{
          "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
            minWidth: "1000px",
            //  maxWidth: "1000px",
            //   width: "1000px",
            marginLeft: "200px",
          },
        }}
        onClose={() => {
          setConfirm(true);
        }}
      >
        <Box>
          <DialogTitle sx={{ py: 1 }}>
            <div style={{ display: "flex" }}>
              <Typography
                variant="h6"
                component="div"
                style={{ flexGrow: 1, fontSize: "20px" }}
              >
                {title}
              </Typography>
              <IconButton
                onClick={() => {
                  setConfirm(true);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <Divider />
          <Box
            sx={{
              px: 3,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="scrollable force tabs example"
              sx={{ border: "none" }}
            >
              <StyledTab
                onClick={() => {
                  setTabcustodians(false);

                  handleGenerateFile();
                }}
                label="Bank Of America"
              />
              <StyledTab
                onClick={() => {
                  setTabcustodians(false);
                  handleGenerateFile();
                }}
                label="Pershing Bank"
              />
              <StyledTab
                onClick={() => {
                  setTabcustodians(false);
                  handleGenerateFile();
                }}
                label="Goldman Sachs"
              />
            </Tabs>
            <Button
              variant="contained"
              startIcon={<AutorenewIcon />}
              sx={{ height: 35, mt: 2 }}
              onClick={GenerateConfirmation}
            >
              REGENERATE
            </Button>
          </Box>
          <DialogContent sx={{ py: 1 }}>
            <Box height="100%" display="flex" flexDirection="column">
              {value === 0 ? (
                <Csv />
              ) : value === 1 ? (
                <Bankofperssion />
              ) : value === 2 ? (
                <GoldmanSachs />
              ) : null}
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
      <TradesConfirmation
        confirm={confirm}
        setConfirm={setConfirm}
        tradesPopup={tradesPopup}
        setTradesOpenPopup={setTradesOpenPopup}
        title="Close Custodians Trade"
      />
      <ConfirmRegenerate
        title="REGENERATE FILES FOR CUSTODIANS?"
        openRegenerate={openRegenerate}
        setOpenRegenerate={setOpenRegenerate}
      />
    </>
  );
};

export default SendTrades;
