import Header from "../Header";
import TickersTable from "../TickersDetails/TickersTable";
const TradeTickers = () => {
  return (
    <>
     <Header >
        <TickersTable/>
     </Header>
    </>
  );
};
export default TradeTickers;
