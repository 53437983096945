import AccountingTable from "../AccountingDetails/AccountingTable";
import Header from "../Header";
const TradeAccounting = () => {
  return (
    <>
      <Header>
        <AccountingTable />
      </Header>
    </>
  );
};
export default TradeAccounting;
