import React from "react";
import TradeFunds from "../component/Layouts/TradeFunds";
import Topbar from "../component/NavComponents/Topbar";
import { Helmet } from "react-helmet";
const Funds = () => {
  return (
    <>
     <Helmet>
        <title>Funds</title>
      </Helmet>
      <Topbar title="Funds" />
      <TradeFunds />
    </>
  );
};
export default Funds;
