import React, { useState, useEffect } from "react";
import {
  Table,
  TableRow,
  TableBody,
  useTheme,
  TableCell,
  TextField,
  NativeSelect,
  Box,
} from "@mui/material";
import { useSelector } from "react-redux";
import { Tablecell, order_Type } from "../../../Methods";

const RebalancingInfo = ({ fund }) => {
  const theme = useTheme();

  const [custodianVal, setCustodian] = useState("");
  const [brokerVal, setBroker] = useState("");
  const [postOms, setpostOms] = useState("");
  const [typeVal, setType] = useState("");
  const [pageLimit, setpageLimit] = useState("");
  const [orders, setorders] = useState("");

  const postOms_type = ["TRUE", "FALSE"];
  const { custodians, data } = useSelector((state) => state.todos);

  useEffect(() => {
    if (fund) {
      setCustodian(fund.custodianId || "");
      setBroker(fund.brokerId || "");
      setpostOms(fund.postOms || "TRUE");
      setType(fund.orderType || "Limit");
      setpageLimit(fund.pageLimit || "0.40%");
      setorders(fund.orders || "0.5 Basis Points");
    } else {
      setCustodian("");
      setBroker("");
      setpostOms("");
      setType("");
      setpageLimit("");
      setorders("");
    }
  }, [fund]);

  return (
    <Box
      sx={{
        border: `1px ${theme.palette.lightGrey.tableHeader}`,
        borderRadius: "4px",
        marginTop: "10px",
        width: "80%",
        marginRight: 1,
      }}
    >
      <Table
        size="small"
        stickyHeader
        aria-label="a dense table"
        sx={{
          height: "50%",
          borderCollapse: "collapse",
          border: "solid 1px #eee",
          marginTop: "-1px",
        }}
      >
        <TableRow sx={{ backgroundColor: "#B5D0D9" }}>
          <TableCell
            colSpan={2}
            sx={{
              fontSize: theme.typography.bodyText12.fontSize,
              fontWeight: theme.typography.bodyText12.fontWeight,
              backgroundColor: "#F2F2F2",
              textAlign: "left",
              width: "100px",
            }}
          >
            Rebalance Preferences
          </TableCell>
        </TableRow>

        <TableBody>
          {/* <TableRow>
            <Tablecell align="left" sx={{ paddingLeft: "10px" }}>
              Custodian Preference
            </Tablecell>
            <Tablecell align="left">
              <NativeSelect
                value={custodianVal}
                onChange={(e) => setCustodian(e.target.value)}
                disableUnderline
                sx={{ fontSize: "12px", paddingLeft: "10px" }}
                id="custodian"
                name="custodian"
              >
                <option value="">Select Custodian</option>
                {custodians?.map((val) => (
                  <option key={val.cutodianId} value={val.cutodianId}>
                    {val.abbreviation}
                  </option>
                ))}
              </NativeSelect>
            </Tablecell>
          </TableRow> */}

          {/* <TableRow>
            <Tablecell align="left" sx={{ paddingLeft: "10px" }}>
              Broker Preference
            </Tablecell>
            <Tablecell align="left">
              <NativeSelect
                value={brokerVal}
                onChange={(e) => setBroker(e.target.value)}
                disableUnderline
                sx={{ fontSize: "12px", paddingLeft: "10px" }}
                id="brokerVal"
                name="brokerVal"
              >
                <option value="">Select Broker</option>
                {data?.map((val) => (
                  <option key={val.brokerId} value={val.brokerId}>
                    {val.abbreviation}
                  </option>
                ))}
              </NativeSelect>
            </Tablecell>
          </TableRow> */}

          <TableRow>
            <Tablecell align="left" sx={{ paddingLeft: "10px" }}>
              Order Type
            </Tablecell>
            <Tablecell align="left">
              <NativeSelect
                value={typeVal}
                onChange={(e) => setType(e.target.value)}
                disableUnderline
                sx={{ fontSize: "12px", paddingLeft: "10px" }}
                id="typeVal"
                name="typeVal"
              >
                <option value="">Select Order Type</option>
                {order_Type?.map((val, index) => (
                  <option key={index} value={val}>
                    {val}
                  </option>
                ))}
              </NativeSelect>
            </Tablecell>
          </TableRow>

          <TableRow>
            <Tablecell align="left" sx={{ paddingLeft: "10px" }}>
              Slippage Limit %
            </Tablecell>
            <Tablecell align="center">
              <TextField
                fullWidth
                value={pageLimit}
                onChange={(e) => setpageLimit(e.target.value)}
                size="small"
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  style: {
                    fontSize: "12px",
                    fontWeight: "500",
                    paddingLeft: "10px",
                  },
                }}
              />
            </Tablecell>
          </TableRow>

          <TableRow>
            <Tablecell align="left" sx={{ paddingLeft: "10px" }}>
              Ignore orders below
            </Tablecell>
            <Tablecell align="center">
              <TextField
                fullWidth
                value={orders}
                onChange={(e) => setorders(e.target.value)}
                size="small"
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  style: {
                    fontSize: "12px",
                    fontWeight: "500",
                    paddingLeft: "10px",
                  },
                }}
              />
            </Tablecell>
          </TableRow>

          <TableRow>
            <Tablecell align="left" sx={{ paddingLeft: "10px" }}>
              Post to OMS?
            </Tablecell>
            <Tablecell align="left">
              <NativeSelect
                value={postOms}
                onChange={(e) => setpostOms(e.target.value)}
                disableUnderline
                sx={{ fontSize: "12px", paddingLeft: "10px" }}
                id="postOms"
                name="postOms"
              >
                {postOms_type?.map((val) => (
                  <option key={val} value={val}>
                    {val}
                  </option>
                ))}
              </NativeSelect>
            </Tablecell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default RebalancingInfo;
