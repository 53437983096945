import React, { useEffect, useState } from "react";
import { TableRow, useTheme, Typography } from "@mui/material";
import { styled } from "@mui/system";
import {
  Tablecell,
  calculateAndFormat,
  formatValue,
  calculateAndFormatNoAbs,
} from "../../../Methods";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import TradesPopUp from "../../../Popup/TradesPopUp";
import ClosePositionLong from "../../../Popup/ClosePosition/ClosePositionLong";
import { useSelector } from "react-redux";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#5A5A5A",
    color: "#fff",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const LongTable = (props) => {
  const { row, fundVal, strategyVal, visible } = props;
  const { funds, strategies } = useSelector((state) => state.todos);
  const [myFund, setMyFund] = useState({});
  const [myStrategy, setMyStrategy] = useState({});
  const [formattedSize, setFormattedSize] = useState("");
  const [isLong, setIsLong] = useState(false);
  const [closePos, setClosePos] = useState(false);
  const [
    formattedDeltaAdjustedShareCount,
    setFormattedDeltaAdjustedShareCount,
  ] = useState("");
  const [formattedLast, setFormattedLast] = useState("");
  const [ticker, setTicker] = useState("");
  const [formattedCurrent, setFormattedCurrent] = useState("");
  const [formattedCh, setFormattedCh] = useState("");
  const [formattedPL, setFormattedPL] = useState("");
  const [formattedChPercentage, setFormattedChPercentage] = useState("");
  const [formattedCost, setFormattedCost] = useState(0);
  const [formattedAvgCost, setformattedAvgCost] = useState(0);
  const [formattedPerAvgCost, setformattedPerAvgCost] = useState(0);
  const [tdg, setTdg] = useState(0);

  useEffect(() => {
    setFormattedSize(formatValue(row?.long_size));
    setFormattedDeltaAdjustedShareCount(
      calculateAndFormat(row?.long_deltaadjustedsharecount)
    );
    setTicker(row?.long_tickerSymbol);
    setFormattedLast(formatValue(row?.long_last));
    setFormattedCurrent(formatValue(row.long_current));
    setFormattedCh(row?.long_ch);
    setFormattedPL(row?.long_pL);
    setFormattedCost(row?.long_cost);
    setFormattedChPercentage(row?.long_chpercentage);
    setformattedAvgCost(row?.long_pl_avg_cost);
    setformattedPerAvgCost(row?.long_percentage_avg_cost);
    setTdg(row?.long_tdg);
  }, [row]);

  const handleLong = (obj) => {
    // setIsLong(true);
    if (
      fundVal &&
      strategyVal &&
      fundVal.toUpperCase() !== "ALL" &&
      strategyVal.toUpperCase() !== "ALL"
    ) {
      const fund = funds?.find((i) => i.abbreviation == fundVal);
      const strategy = strategies?.find((i) => i.name == strategyVal);
      setMyFund(fund);
      setMyStrategy(strategy);
      setClosePos(true);
    } else {
      setMyFund({});
      setMyStrategy({});
      setClosePos(false);
    }
  };
  const theme = useTheme();
  return (
    <React.Fragment>
      <TableRow
        sx={{
          backgroundColor: theme.palette.grey[100],
          "&:hover": {
            background: theme.palette.grey[200],
            cursor: "pointer",
          },
        }}
        onClick={() => {
          handleLong(row);
        }}
      >
        <Tablecell
          style={{ width: "50px" }}
          align="right"
          sx={{ paddingRight: "5px" }}
        >
          {formattedSize ? `${formattedSize}%` : "0.00%"}
        </Tablecell>
        <Tablecell align="right" sx={{ paddingRight: "5px" }}>
          {formattedDeltaAdjustedShareCount || ""}
        </Tablecell>

        <Tablecell
          style={{ width: "20px", paddingLeft: "5px", cursor: "pointer" }}
          align="left"
        >
          {ticker || ""}
        </Tablecell>
        <HtmlTooltip
          title={
            <React.Fragment>
              <Typography color="inherit" sx={{ fontSize: "12px" }}>
                Market:<b> {row?.dataSource} </b>
              </Typography>

              <Typography color="inherit" sx={{ fontSize: "12px" }}>
                Updated: <b>{row?.fetched}</b>
              </Typography>
            </React.Fragment>
          }
        >
          <Tablecell
            style={{
              width: "20px",
              paddingRight: "5px",
              backgroundColor: row?.valid ? "#baffc8" : "#ffdcdc",
            }}
            align="right"
          >
            {formattedLast || ""}
          </Tablecell>
        </HtmlTooltip>
        <HtmlTooltip
          title={
            <React.Fragment>
              <Typography color="inherit" sx={{ fontSize: "12px" }}>
                Market:<b> {row?.dataSource} </b>
              </Typography>

              <Typography color="inherit" sx={{ fontSize: "12px" }}>
                Updated: <b>{row?.fetched}</b>
              </Typography>
            </React.Fragment>
          }
        >
          <Tablecell
            style={{
              width: "20px",
              paddingRight: "5px",
              backgroundColor: row?.valid ? "#baffc8" : "#ffdcdc",
            }}
            align="right"
          >
            {formattedCurrent || ""}
          </Tablecell>
        </HtmlTooltip>
        {formattedCh ? (
          <Tablecell
            align="right"
            style={{
              color: formattedCh >= 0 ? "green" : "red",
              paddingRight: "2px",
            }}
          >
            {formattedCh >= 0
              ? parseFloat(formattedCh).toFixed(2)
              : `(${(formattedCh * -1).toFixed(2)})`}
            &nbsp;
          </Tablecell>
        ) : (
          <Tablecell align="right"></Tablecell>
        )}

        {formattedPL ? (
          <Tablecell
            align="right"
            style={{
              color: formattedPL >= 0 ? "green" : "red",
              paddingRight: "2px",
            }}
          >
            {formattedPL >= 0
              ? Math.round(formattedPL).toLocaleString()
              : `(${(Math.round(formattedPL) * -1).toLocaleString()})`}
            &nbsp;
          </Tablecell>
        ) : (
          <Tablecell align="right"></Tablecell>
        )}
        {formattedChPercentage ? (
          <Tablecell
            style={{
              color: formattedChPercentage >= 0 ? "green" : "red",
              width: "20px",
              paddingRight: "2px",
            }}
            align="right"
          >
            {formattedChPercentage >= 0
              ? `${formattedChPercentage?.toFixed(2)}%`
              : `(${Math.abs(formattedChPercentage).toFixed(2)}%)`}
          </Tablecell>
        ) : (
          <Tablecell align="right"></Tablecell>
        )}
        {visible ? (
          <>
            {" "}
            <Tablecell align="right" style={{ paddingRight: "5px" }}>
              {formattedAvgCost >= 0
                ? calculateAndFormatNoAbs(formattedAvgCost)
                : `(${calculateAndFormatNoAbs(Math.abs(formattedAvgCost))})`}
            </Tablecell>
            <Tablecell
              align="right"
              style={{
                paddingRight: "5px",
                // color: formattedPerAvgCost >= 0 ? "green" : "red",
              }}
            >
              {formattedPerAvgCost >= 0
                ? `${formattedPerAvgCost?.toFixed(2)}%`
                : `(${Math.abs(formattedPerAvgCost).toFixed(2)}%)`}
            </Tablecell>
          </>
        ) : null}

        <Tablecell align="right" style={{ paddingRight: "5px" }}>
          {formattedCost?.toFixed(2)}
        </Tablecell>
        <Tablecell
          align="right"
          style={{
            color: tdg >= 0 ? "green" : "red",
            width: "20px",
            paddingRight: "5px",
          }}
        >
          {tdg == 0
            ? ""
            : tdg >= 0
            ? Math.round(tdg).toLocaleString() + " "
            : `(${(Math.round(tdg) * -1).toLocaleString()})`}
        </Tablecell>
      </TableRow>
      {/* <TradesPopUp
        open={isLong}
        setOpen={setIsLong}
        ticker={ticker}
        shareCount={formattedDeltaAdjustedShareCount}
        marketPrice={formattedCurrent}
        yeserdaytPrice={formattedLast}
        title="Position"
      /> */}
      <ClosePositionLong
        closePos={closePos}
        setClosePos={setClosePos}
        title="CLOSE POSITION"
        row={row}
        myFund={myFund}
        myStrategy={myStrategy}
      />
    </React.Fragment>
  );
};

export default LongTable;
