import React, { useEffect, useState } from "react";
import {
  Table,
  TableRow,
  TableBody,
  useTheme,
  Box,
  Divider,
} from "@mui/material";
import { Tablecell } from "../../Methods";

const SummarySectionOne = ({ strategies, strategyVal }) => {
  const [CombinedArray, setCombinedArray] = useState([]);

  useEffect(() => {
    if (strategyVal && strategies) {
      const strategy = strategies.find(
        (strategy) => strategy.strategyId === strategyVal
      );
      if (strategy && strategy.signals) {
        const { weights_keys, weights_values } = strategy.signals;
        const keys = Object.keys(weights_keys);
        const matchedKeys = keys.filter((key) =>
          weights_values?.hasOwnProperty(key)
        );

        if (matchedKeys.length > 0) {
          const count = matchedKeys.length;
          const sumOfMatchedValues = matchedKeys.reduce(
            (sum, key) => sum + parseFloat(weights_values[key] * 100),
            0
          );

          const positiveValuesSum = matchedKeys.reduce(
            (sum, key) =>
              weights_values[key] > 0
                ? sum + parseFloat(weights_values[key] * 100)
                : sum,
            0
          );

          const negativeValuesSum = matchedKeys.reduce(
            (sum, key) =>
              weights_values[key] < 0
                ? sum + parseFloat(weights_values[key] * 100)
                : sum,
            0
          );

          function roundit(number) {
            return number.toFixed(2).replace(/\.?0+$/, "");
          }

          const transformedArray = [
            { name: "Number of Signals in list", value: count },
            { name: "Long", value: roundit(positiveValuesSum) },
            { name: "Short", value: roundit(negativeValuesSum) },
            {
              name: "Gross Sum of Weights",
              value: roundit(positiveValuesSum - negativeValuesSum),
            },
            {
              name: "Net Sum of Weights",
              value: roundit(sumOfMatchedValues),
            },
            {
              name: "Total Performance",
              value: roundit(sumOfMatchedValues),
            },
          ];

          setCombinedArray(transformedArray);
        } else {
          setCombinedArray([]);
        }
      } else {
        setCombinedArray([]);
      }
    }
  }, [strategies, strategyVal]);

  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          border: `100px ${theme.palette.lightGrey.tableHeader}`,
          borderRadius: "4px",
          marginTop: "10px",
          width: "80%",
          marginRight: 1,
        }}
      >
        <Table
          stickyHeader
          aria-label="a dense table"
          sx={{
            height: "max-content",
            borderCllapse: "collapse",
            border: "solid 1px #eee",
          }}
        >
          <TableRow
            sx={{
              "&:hover": {
                background: theme.palette.grey[200],
                cursor: "pointer",
              },
            }}
          >
            <Tablecell
              align="left"
              sx={{
                fontSize: theme.typography.bodyText12.fontSize,
                fontWeight: 900,
                backgroundColor: "#B2CBE299",
                paddingLeft: "5px",
                py: "5px",
                textAlign: "left",
              }}
            >
              Signal 01 Summary
            </Tablecell>
          </TableRow>
        </Table>
        <Table
          size="small"
          stickyHeader
          aria-label="a dense table"
          sx={{
            borderCllapse: "collapse",
            border: "solid 1px #eee",
            marginTop: "-1px",
          }}
        >
          <TableBody>
            {CombinedArray?.map((row, index) => (
              <TableRow
                sx={{
                  "&:hover": {
                    background: theme.palette.grey[200],
                    cursor: "pointer",
                  },

                  backgroundColor:
                    row.name === "Total Performance"
                      ? row.value > 0
                        ? theme.palette.green.longPositions
                        : theme.palette.red.shortPositions
                      : "transparent",
                }}
              >
                <Tablecell
                  align="left"
                  sx={{
                    fontWeight: "900",
                    fontSize: "12px",
                    paddingLeft: "5px",
                    borderRight: "none",
                    borderLeft: "none",
                    borderTop: "none",
                    borderBottom:
                      row.name == "Short"
                        ? `1px solid ${theme.palette.grey[90]}`
                        : "none",
                  }}
                >
                  {row?.name}
                </Tablecell>

                <Tablecell
                  align="right"
                  sx={{
                    fontWeight: "500",
                    fontSize: "12px",
                    paddingLeft: "5px",
                    borderRight: "none",
                    borderLeft: "none",
                    borderTop: "none",
                    borderBottom:
                      row.name == "Short"
                        ? `1px solid ${theme.palette.grey[90]}`
                        : "none",
                  }}
                >
                  {index === 0 ? row.value : `${row.value}%`}
                </Tablecell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};
export default SummarySectionOne;
