import { createAction, createAsyncThunk } from "@reduxjs/toolkit";


export const getUser = createAsyncThunk("getUser", async (payload) => {
  const result = payload;
  return result;
});

export const getInitials = createAsyncThunk("getInitials", async (payload) => {
  const result = payload;
  return result;
});

export const getUserInfo = createAsyncThunk("getUserInfo", async (payload) => {
  const result = payload;
  return result;
});

export const getApiInfo = createAsyncThunk("getApiInfo", async (payload) => {
  const result = payload;
  return result;
});

export const removeUser = createAsyncThunk("removeUser", async (payload) => {
    const result = payload;
    return result;
  });
