import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Grid,
  useTheme,
} from "@mui/material";

import Papa from "papaparse";
import Loader from "../../Loader";
import { findFundById, findStrategyById } from "../../Methods";
import { useDispatch, useSelector } from "react-redux";
import { updateLoading } from "../../../Redux/Reducers/todoReducers";
const CloseTrades = (props) => {
  const { selectedFund, myStrategy, signals, title, openSave, setOpenSave } =
    props;
  const { strategies, funds } = useSelector((state) => state.todos);
  const [isMarketData, setIsMarketData] = useState(false);
  const [dataFetching, setDataFetching] = useState(false);
  const dispatch = useDispatch();

  const handleMe = () => {
    setIsMarketData(true);
    setDataFetching(false);

    setTimeout(() => {
      if (!dataFetching) {
        setIsMarketData(false);
      }
    }, 20000);

    const tickersObject = signals.reduce((acc, signal) => {
      if (signal.symbol) {
        acc[signal.symbol] = signal.newValue != null ? signal.newValue : 0;
      }
      return acc;
    }, {});

    window.clientSocks.send(
      JSON.stringify({
        action: "click_to_trade",
        data: {
          fund: findFundById(selectedFund, funds),
          strategy: findStrategyById(myStrategy, strategies),
          tickers: tickersObject,
        },
      })
    );
    dispatch(updateLoading(true));
    setOpenSave(false);
  };

  const theme = useTheme();
  return (
    <>
      {isMarketData && (
        <>
          <Loader msg={true} marketLoader={false} noMin={true} mTrue={true} />
        </>
      )}
      <Dialog open={openSave} sx={{ width: "100%", height: "100%" }}>
        <DialogTitle>
          <Typography
            variant="h6"
            component="div"
            align="center"
            style={{ flexGrow: 1, fontSize: "20px" }}
          >
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="body" component="div" style={{ flexGrow: 1 }}>
            Are you sure you want to save these changes?
          </Typography>
          <Grid
            item
            xs={12}
            margin="20px 1px 20px 1px "
            display="flex"
            justifyContent="space-between"
          >
            <Button
              variant="contained"
              color="success"
              sx={{
                fontSize: "12px",
                fontWeight: "bold",
                height: "40px",
                width: "150px",
              }}
              onClick={() => {
                handleMe();
              }}
            >
              SAVE CHANGES
            </Button>
            <Button
              variant="contained"
              sx={{
                fontSize: "12px",
                fontWeight: "bold",
                height: "40px",
                width: "150px",
                background: "#B2BEB5",
                color: "#000",
                "&:hover": { background: "#808080" },
              }}
              onClick={() => {
                setOpenSave(false);
              }}
            >
              CANCEL CHANGES
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CloseTrades;
