import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Grid,
  CircularProgress,
  Box,
  useTheme,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const Response = ({ message }) => {
    const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        {message ? (
          <>
            <CheckCircleIcon
              sx={{ color: theme.palette.success.main, marginRight: 2 }}
            />
            <Typography
              sx={{ fontSize: "18px", fontWeight: 500, letterSpacing: "1px" }}
            >
              TRADE POSTED FOR CUSTODIAN.
            </Typography>
          </>
        ) : (
          <>
            <CircularProgress size={30} sx={{ marginRight: 2 }} />{" "}
            <Typography
              sx={{ fontSize: "18px", fontWeight: 500, letterSpacing: "1px" }}
            >
              Please Wait......
            </Typography>
          </>
        )}
      </Box>
    </>
  );
};
export default Response;
