import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      light: "#1976D2",
      main: "#3f50b5",
      dark: "#002884",
      contrastText: "#fff",
      contrastTextDark: "#000",
    },
    grey: {
      90:"#D3D3D3",
      100: "#FFFFFF",
      200: "#EAEAEA",
      300: "#3d3d3d",
      400: "#525252",
      500: "#666666",
      600: "#37404C",
      700: "#424E5F",
    },
    lightGrey: {
      textColor: "#424E5FC9",
      backgroundBody: "#F6F6F6",
      tableHeader: "#D8DCDF",
      signalsHeader:"#E4E4E4",
      signalsRow:"#F8F8F8"
    },
    red: {
      shortPositions: "#C3444429",
      100: "#F86868",
      200: "#D32F2F",
      300: "#880000",
    },
    blue: {
      100: "#DCE6F0",
      200: "#424E5F",
      300: "#0B0B45",
    },
    green: {
      longPositions: "#3DB4652B",
      Aquamarine: "#7FFFD4",
      ArmyGreen: "#454B1B",
      EmeraldGreen: "#50C878",
      FernGreen: "#4F7942",
      ForestGreen: "#228B22",
      GrassGreen: "#7CFC00",
      Green: "#008000",
      HunterGreen: "#355E3B",
      Jade: "#00A36C",
      JungleGreen: "#2AAA8A",
      KellyGreen: "#4CBB17",
      LightGreen: "#90EE90",
      LimeGreen: "#32CD32",
      SeaGreen: "#2E8B57",
      Malachite: "#0BDA51",
      MintGreen: "#98FB98",
      ShamrockGreen: "#009E60",
      ShamrockGreen: "#009E60",
    },
  },
  typography: {
    body11: {
      fontSize: "7px",
    },
    bodyText12: {
      fontSize: "12px",
      fontWeight: 500,
    },
    bodyText13: {
      fontSize: "13px",
      fontWeight: 500,
    },
    bodyText14: {
      fontSize: "14px",
    },

    bodyText15: {
      fontSize: "15px",
      fontWeight: 400,
    },
    bodyText15: {
      fontSize: "17px",
      fontWeight: 200,
    },
    bodyText16: {
      fontSize: "16px",
      fontWeight: 600,
    },
    bodyText18: {
      fontSize: "18px",
      fontWeight: 600,
    },
    bodyText20: {
      fontSize: "20px",
      fontWeight: 500,
    },
    h5: {
      fontSize: "0.8rem",
      fontWeight: 500,
    },

    h6: {
      fontSize: "2px",
    },
  },
});

export default theme;
