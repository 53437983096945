import React, { useState, useEffect } from "react";
import ListHead from "../../ListHead";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment/moment";
import {
  TABLE_HEAD_EQUITY as TABLE_HEAD,
  Tablecell,
  TradeInvestment,
  calculateAndFormatNoAbsInt,
  formatValueDecimal,
} from "../../Methods";
import { Table, TableBody, TableContainer, TableRow, Box } from "@mui/material";
import { useTheme } from "@emotion/react";
import { setGlobalContract } from "../../../Redux/Actions/todoActions";
const TableEqty = ({ tickers, check, marketPrice, fund, strategy }) => {
  const dispatch = useDispatch();
  const [sameLots, setSameLots] = useState([]);
  const { lots, custodians } = useSelector((state) => state?.todos);

  var costavg = 0,
    price = 0,
    totalsharecount = 0,
    count = 0;

  useEffect(() => {
    if (lots && tickers) {
      let tickerEquity = [];

      if (check === "Sell") {
        tickerEquity = lots?.filter((i) => {
          if (i.todaybuysell) {
            return (
              i?.ticker?.investment === TradeInvestment.Equity &&
              i.todayshortcover === undefined &&
              (!fund || i.fund === fund) &&
              (!strategy || i.strategy === strategy)
            );
          } else {
            return (
              i?.ticker?.investment === TradeInvestment.Equity &&
              i.shareCount > 0 &&
              i.todayshortcover === undefined &&
              (!fund || i.fund === fund) &&
              (!strategy || i.strategy === strategy)
            );
          }
        });
      } else if (check === "Cover") {
        tickerEquity = lots?.filter((i) => {
          if (i.todayshortcover) {
            return (
              i?.ticker?.investment === TradeInvestment.Equity &&
              i.todaybuysell === undefined &&
              (!fund || i.fund === fund) &&
              (!strategy || i.strategy === strategy)
            );
          } else {
            return (
              i?.ticker?.investment === TradeInvestment.Equity &&
              i.shareCount < 0 &&
              i.todaybuysell === undefined &&
              (!fund || i.fund === fund) &&
              (!strategy || i.strategy === strategy)
            );
          }
        });
      }

      const newLots = tickers?.ticker?.symbol;
      const temp = [];
      tickerEquity.forEach((equity) => {
        const { symbol } = equity.ticker;
        if (newLots === symbol) {
          temp.push(equity);
        }
      });

      const sorted = temp.sort((a, b) => {
        return a?.ticker?.symbol > b?.ticker?.symbol;
      });
      setSameLots(sorted);
    }
  }, [lots, tickers, check, fund, strategy]);

  useEffect(() => {
    dispatch(
      setGlobalContract({
        sharecounts: Math.abs(totalsharecount),
        prices: price,
        myLots: sameLots,
      })
    );
  }, [totalsharecount, price, dispatch, sameLots]);
  const theme = useTheme();

  return (
    <>
      <Box>
        <TableContainer>
          <Table
            size="small"
            stickyHeader
            aria-label="a dense table"
            sx={{
              height: "max-content",
              maxHeight: "55px",
              borderCllapse: "collapse",
            }}
          >
            <ListHead headLabel={TABLE_HEAD} noStatus={true} />

            <TableBody>
              {sameLots.map((val, index) => {
                price = price + val?.sharePrice;
                count = count + 1;
                totalsharecount = totalsharecount + val?.shareCount;
                costavg = costavg + val?.cost;
                let matchingCustodian = custodians.find((obj) => {
                  return obj.custodianId == val.custodian;
                });

                return (
                  <TableRow key={index} tabIndex={1} role="checkbox">
                    <Tablecell align="center">
                      {moment(val?.date).format("MMM DD, YYYY")}
                    </Tablecell>
                    <Tablecell align="center">
                      {matchingCustodian?.name}
                    </Tablecell>
                    <Tablecell align="center">{val?.ticker?.symbol}</Tablecell>
                    <Tablecell align="center">
                      {calculateAndFormatNoAbsInt(val?.shareCount)}
                    </Tablecell>
                    <Tablecell align="center">
                      {formatValueDecimal(val?.cost / val?.shareCount)}{" "}
                    </Tablecell>
                    <Tablecell align="center">
                      {formatValueDecimal(marketPrice)}
                    </Tablecell>
                  </TableRow>
                );
              })}

              <TableRow tabIndex={1} role="checkbox">
                <Tablecell sx={{ border: "none" }}></Tablecell>
                <Tablecell sx={{ border: "none" }}></Tablecell>
                <Tablecell sx={{ border: "none" }}></Tablecell>

                <Tablecell
                  align="center"
                  sx={{
                    border: "none",
                    color: theme.palette.primary.light,
                    fontWeight: "bold",
                  }}
                >
                  {calculateAndFormatNoAbsInt(totalsharecount)}
                </Tablecell>

                <Tablecell
                  align="center"
                  sx={{
                    border: "none",
                    color: theme.palette.primary.light,
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  {formatValueDecimal(costavg / totalsharecount)}
                </Tablecell>

                <Tablecell
                  align="center"
                  sx={{
                    border: "none",
                    color: theme.palette.primary.light,
                    fontWeight: "bold",
                  }}
                >
                  {formatValueDecimal(marketPrice)}
                </Tablecell>
              </TableRow>
            </TableBody>
          </Table>

          <br />
        </TableContainer>
      </Box>
    </>
  );
};
export default TableEqty;
