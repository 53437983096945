import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { getBrokers } from "../Redux/Actions/todoActions";
import { useFormik } from "formik";
import { BrokerName } from "../component/Schemas";

const EditBrokerDetails = ({ recordForEdit, data, setOpenPopup, setData }) => {
  const brokers = useSelector((state) => state?.todos?.data);
  const { routeList } = useSelector((state) => state?.todos);
  const flatProps = routeList.map((option) => ({
    ...option,
    title: option.name,
    id: option.routerId,
  }));

  const [autocompletedValue, setautoCompletedValue] = React.useState(
    Object.values(recordForEdit?.routers).map((item) => {
      let selectedRouter = routeList.find((a) => a.routerId === item.router);
      if (selectedRouter) {
        return {
          ...item,
          title: selectedRouter.name,
          id: selectedRouter.router,
          routerId: item?.router,
        };
      } else {
        return item;
      }
    })
  );
  const [extraInputsValues, setExtraInputsValues] = React.useState(
    autocompletedValue
      .map((v) => {
        return { [v.router]: v.name };
      })
      .reduce(function (result, item) {
        var key = Object.keys(item)[0];
        result[key] = item[key];
        return result;
      }, {})
  );

  const initialValues = {
    name: recordForEdit.name,
    abbreviation: recordForEdit.abbreviation,
    routers: recordForEdit.routers,
    ...recordForEdit,
  };

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: BrokerName,

    onSubmit: (values) => {
      values.abbreviation = values.abbreviation.toLowerCase();
      if (
        brokers.find((a) => {
          if (a.brokerId !== values.brokerId)
            return a.abbreviation === values.abbreviation;
        })
      ) {
        alert(`${values.abbreviation} already exists, try new one.`);
      } else {
        const inputFilteredValues = autocompletedValue.map((val, index) => {
          if (val.routerId === "e55e372946e923059944bc08d654d7e0") {
            return {
              router: val.routerId,
              name:
                extraInputsValues[val.routerId] === "" ||
                !extraInputsValues[val.routerId]
                  ? values.abbreviation
                  : extraInputsValues[val.routerId],

              creationDate: values.creationDate,
              brokerId: values.brokerId,
            };
          } else {
            return {
              router: val.routerId,
              text: extraInputsValues[val.routerId],
            };
          }
        });

        var objAltered = {};
        inputFilteredValues.forEach((v, i) => {
          objAltered[v.router] = v;
        });

        window.clientSocks.send(
          `{"action":"modify_broker", "data":{"broker":
        ${JSON.stringify({
          ...values,
          routers: objAltered,
        })}}}`
        );

        setOpenPopup(false);
      }
    },
  });

  const handleInputChange = (key, val) => {
    setExtraInputsValues((prev) => ({ ...prev, [key]: val }));
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} margin="5px">
          <TextField
            fullWidth
            disabled
            id="name"
            name="name"
            label="Full Name"
            value={values.name}
            onChange={handleChange}
            variant="standard"
          />
          {errors.name && touched.name ? (
            <p className="error">*{errors.name}</p>
          ) : null}
        </Grid>

        <Grid item xs={12} margin="5px">
          <TextField
            fullWidth
            id="abbreviation"
            name="abbreviation"
            label="Abbreviation"
            value={values.abbreviation}
            onChange={handleChange}
            variant="standard"
          />
          {errors.abbreviation && touched.abbreviation ? (
            <p className="error">*{errors.abbreviation}</p>
          ) : null}
        </Grid>

        <Grid item xs={6}>
          <Autocomplete
            multiple
            sx={{ width: "100%" }}
            id="tags-standard"
            onClose={(e, val) => {}}
            onChange={(e, val) => {
              let key = "routerId";
              var uniqueVal = [
                ...new Map(val.map((item) => [item[key], item])).values(),
              ];

              setautoCompletedValue(uniqueVal);
            }}
            value={autocompletedValue}
            options={flatProps}
            getOptionLabel={(option) => option.title}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Select OMS"
                placeholder="OMS"
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          {autocompletedValue?.length > 0 &&
            autocompletedValue.map((val, index) => {
              return (
                <TextField
                  variant="standard"
                  value={
                    extraInputsValues[val.router] ||
                    extraInputsValues[val.routerId]
                  }
                  onChange={(e) =>
                    handleInputChange(
                      val.routerId || val.routerId,
                      e.target.value
                    )
                  }
                  label="emsx"
                />
              );
            })}
        </Grid>

        <Grid
          item
          xs={12}
          margin="10px"
          display="flex"
          justifyContent="space-between"
        >
          <Button variant="contained" sx={{ width: "30%" }} type="submit">
            SAVE
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "30%",
              background: "#B2BEB5",
              color: "#000",
              "&:hover": { background: "#808080" },
            }}
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            CANCEL
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
export default EditBrokerDetails;
