import React, { useEffect, useState } from "react";
import {
  Table,
  TableRow,
  TableBody,
  useTheme,
  TableContainer,
} from "@mui/material";
import {
  Tablecell,
  TABLE_HEAD_SIGNALS_PERFORMANCE_MARKETCAP as TABLE_HEAD,
  applySortFilter,
  getComparator,
  formatValue,
} from "../../../../Methods";
import VantageListHead from "../../../../ListHead/SignalList";

const MarketCap = ({ marketCap }) => {
  const [data, setData] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("cut");

  useEffect(() => {
    if (marketCap && marketCap.length > 0) {
      const filteredUsers = applySortFilter(
        marketCap,
        getComparator(order, orderBy)
      );
      setData(filteredUsers);
      return;
    }

    setData([]);
  }, [marketCap, order, orderBy]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const theme = useTheme();

  return (
    <>
      <TableContainer>
        <Table
          size="small"
          stickyHeader
          aria-label="a dense table"
          sx={{
            borderCllapse: "collapse",
            border: "solid 1px #eee",
            marginTop: "-1px",
          }}
        >
          <VantageListHead
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            onRequestSort={handleRequestSort}
            noStatus={true}
            customizeFont={12}
          />
          <TableBody>
            {data?.map((row) => (
              <TableRow
                sx={{
                  "&:hover": {
                    background: theme.palette.grey[200],
                    cursor: "pointer",
                  },
                }}
              >
                <Tablecell
                  align="right"
                  title={formatValue(row?.myCap)}
                  style={{
                    fontSize: "12px",
                    paddingRight: "5px",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                >
                  {row?.myCap > 0
                    ? `${formatValue(row?.myCap / 1000000,true)}M`
                    : row?.myCap}
                </Tablecell>
                <Tablecell
                  align="left"
                  style={{
                    fontSize: "12px",
                    paddingLeft: "5px",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                >
                  {row?.cut}
                </Tablecell>
                <Tablecell
                  align="right"
                  style={{
                    fontSize: "12px",
                    paddingRight: "5px",
                    borderLeft: "none",
                    borderRight: "none",
                    color: row?.long >= 0 ? "green" : "red",
                  }}
                >
                  {row?.long < 0 ? (
                    `(${Math.abs(row?.long)?.toFixed(2)})`
                  ) : (
                    <>{row?.long?.toFixed(2)}</>
                  )}
                </Tablecell>
                <Tablecell
                  align="right"
                  style={{
                    fontSize: "12px",
                    paddingRight: "5px",
                    borderLeft: "none",
                    borderRight: "none",
                    color: row?.short >= 0 ? "green" : "red",
                  }}
                >
                  {row?.short < 0 ? (
                    `(${Math.abs(row?.short)?.toFixed(2)})`
                  ) : (
                    <>{row?.short?.toFixed(2)}</>
                  )}
                </Tablecell>
                <Tablecell
                  align="right"
                  style={{
                    fontSize: "12px",
                    paddingRight: "5px",
                    borderLeft: "none",
                    borderRight: "none",
                    color: row?.bias >= 0 ? "green" : "red",
                  }}
                >
                  {row?.bias < 0 ? (
                    `(${Math.abs(row?.bias)?.toFixed(2)})`
                  ) : (
                    <>{row?.bias?.toFixed(2)}</>
                  )}
                </Tablecell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default MarketCap;
