import Header from "../Header";
import UserProfileDetails from "../UserProfileDetails";
const UserProfileLayout= () => {
  return (
    <>
      <Header>
        <UserProfileDetails/>
      </Header>
    </>
  );
};
export default UserProfileLayout;
