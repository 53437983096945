import React, { useEffect, useState } from "react";
import { Card, Container, Stack, Typography, Avatar } from "@mui/material";
import { useTheme } from "@emotion/react";
import SouthEastIcon from "@mui/icons-material/SouthEast";
import { useSelector } from "react-redux";
import Link from "@mui/material/Link";
import Rebalance from "../../Popup/Rebalance";
import { findStrategy } from "../../Methods";
import { updateLoading } from "../../../Redux/Reducers/todoReducers";

const Notification = () => {
  const theme = useTheme();
  const { notifications, strategies } = useSelector((state) => state.todos);

  const [saveRebalancer, setSaveRebalancer] = useState(false);
  const [myStrategy, setStrategy] = useState();
  useEffect(() => {
    if (notifications && notifications.length > 0) {
      const strategy = findStrategy(
        notifications[0]?.metadata?.strategy,
        strategies
      );
      setStrategy(strategy);
    } else {
      setStrategy();
    }
  }, [notifications]);
  const compare = (strategy) => {
    const st = findStrategy(
      strategy,
      strategies
    );
    setStrategy(st);
    
    window.clientSocks.send(
      JSON.stringify({
        action: "compare_signals",
        data: {
          strategy: strategy,
        },
      })
    );

    setSaveRebalancer(true);
  };
  const latestNotificationMap = {};
  notifications?.forEach((notification) => {
    const strategy = notification?.metadata?.strategy;
    if (strategy) {
      if (
        !latestNotificationMap[strategy] ||
        new Date(notification?.creationDate) >
        new Date(latestNotificationMap[strategy]?.creationDate)
      ) {
        latestNotificationMap[strategy] = notification;
      }
    }
  });

  return (
    <>
      <Container
        maxWidth="100%"
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          flexWrap: "wrap",
        }}
      >
        <Stack direction="column" spacing={4} mt={4} sx={{ width: "100%" }}>
          {notifications?.slice() 
            .sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate)) 
            .map((notification, index) => {
            const isLatest =
              latestNotificationMap[notification?.metadata?.strategy] ===
              notification;
            return (
              <Card
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingBottom: 10,
                }}
              >
                <div style={{ flex: "0 0 auto", marginRight: "16px" }}>
                  <Avatar
                    sx={{
                      background: theme.palette.blue[200],
                      width: 56,
                      height: 56,
                      marginLeft: 2,
                    }}
                  >
                    <SouthEastIcon />
                  </Avatar>
                </div>
                <div style={{ flex: "1 1 auto" }}>
                  <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
                    {notification?.heading}
                  </Typography>
                  <Typography>{notification?.content}</Typography>
                  <Typography style={{ color: "#b6b1b1" }}>
                    {new Date(notification?.creationDate).toLocaleString()}
                  </Typography>

                  <div>
                    {notification?.metadata?.type ===
                      "updated_signal_for_rebalance" && (
                        <Link
                          color="primary"
                          onClick={() => {
                            if (isLatest) {
                              compare(notification?.metadata?.strategy);
                            }
                          }}
                          sx={{
                            cursor: isLatest ? "pointer" : "not-allowed",
                            display: "block",
                            marginTop: "8px",
                            pointerEvents: isLatest ? "auto" : "none",
                            color: isLatest ? "primary.main" : "grey.500",
                          }}
                        >
                          View Changes
                        </Link>
                      )}
                  </div>
                </div>
              </Card>
            );
          })}
        </Stack>
      </Container>
      <Rebalance
        saveRebalancer={saveRebalancer}
        setSaveRebalancer={setSaveRebalancer}
        title="Signal Changes"
        myStrategy={myStrategy}
      />
    </>
  );
};

export default Notification;
