import Header from "../Header";
import RoutersTable from "../RouterDetails/RoutersTable";
const TradeRouters = () => {
  return (
    <>
     <Header >
        <RoutersTable/>
     </Header>
    </>
  );
};
export default TradeRouters;
