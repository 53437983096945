import Header from "../Header";
import Notification from "../NotificationDetails/Notification";
const NotificationLayout = () => {
  return (
    <>
      <Header>
        <Notification />
      </Header>
    </>
  );
};
export default NotificationLayout;
