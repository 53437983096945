import React, { useState, useEffect } from "react";
import {
  Table,
  TableRow,
  TableBody,
  useTheme,
  Box,
  Typography,
  Alert,
} from "@mui/material";
import VantageListHead from "../../../../../../ListHead/SignalList";
import {
  Tablecell,
  applySortFilter,
  formatValue,
  getComparator,
  hashAction,
  hashPosition,
} from "../../../../../../Methods";

const RebalanceTrades = ({ orders, tableHeader }) => {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("ticker");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  useEffect(() => {
    const updatedOrders = orders?.map((order) => ({
      ticker: order.ticker ? order.ticker.symbol : order.ticker,
      weight: order?.weight?.toFixed(3),
      type:
        hashAction(order?.tradeAction) + hashPosition(order?.newPositionType),
      marketPrice: order?.marketPrice?.toFixed(3),
      shareCount: order?.count,
    }));

    const filteredUsers = applySortFilter(
      updatedOrders,
      getComparator(order, orderBy)
    );
    setData(filteredUsers);
  }, [order, orderBy, orders]);

  return (
    <>
      <Box
        sx={{
          border: `100px ${theme.palette.lightGrey.tableHeader}`,
          borderRadius: "4px",
          marginTop: "2px",
          width: "100%",
          marginRight: 1,
        }}
      >
        <Typography sx={{ mb: 1, fontWeight: "bold", fontSize: "20px" }}>
          All Rebalancer Trades
        </Typography>

        <Alert
          severity="info"
          sx={{
            fontSize: "12px",
            textAlign: "right",
            textDecoration: "none",
            my: 1,
          }}
        >
          Weights adjusted by Rebalancer
        </Alert>

        <Box
          sx={{
            maxHeight: "310px", // Set max height for the scrollable area
            overflowY: "auto", // Enable vertical scrolling
          }}
        >
          <Table
            size="small"
            stickyHeader
            aria-label="a dense table"
            sx={{
              borderCollapse: "collapse",
              border: "solid 1px #eee",
              marginTop: "-1px",
            }}
          >
            <VantageListHead
              order={order}
              orderBy={orderBy}
              headLabel={tableHeader}
              noStatus={true}
              customizeFont={12}
              rowCount={data.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {data?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:hover": {
                      background: theme.palette.grey[200],
                      cursor: "pointer",
                    },
                  }}
                >
                  <Tablecell
                    align="left"
                    style={{
                      fontSize: "12px",
                      paddingLeft: "5px",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  >
                    {row.ticker}
                  </Tablecell>
                  <Tablecell
                    align="right"
                    style={{
                      fontSize: "12px",
                      paddingLeft: "5px",
                      borderLeft: "none",
                      borderRight: "none",
                      color: row?.weight > 0 ? "green" : "red",
                    }}
                  >
                    {row?.weight !== undefined
                      ? row?.weight < 0
                        ? `(${Math.abs(row?.weight)})`
                        : `${row?.weight}%`
                      : ""}
                  </Tablecell>
                  <Tablecell
                    align="right"
                    style={{
                      fontSize: "12px",
                      paddingLeft: "5px",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  >
                    {row?.shareCount !== undefined && row?.shareCount != null
                      ? row?.shareCount < 0
                        ? `(${Math.abs(row?.shareCount)})`
                        : `${formatValue(row?.shareCount)}`
                      : ""}
                  </Tablecell>
                  <Tablecell
                    align="left"
                    style={{
                      fontSize: "12px",
                      paddingLeft: "10px",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  >
                    {row.type}
                  </Tablecell>
                  <Tablecell
                    align="right"
                    style={{
                      fontSize: "12px",
                      paddingLeft: "5px",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  >
                    {row?.marketPrice !== undefined && row?.marketPrice != null
                      ? row?.marketPrice < 0
                        ? `$(${Math.abs(formatValue(row?.marketPrice))})`
                        : `$${formatValue(row?.marketPrice)}`
                      : ""}
                  </Tablecell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </>
  );
};

export default RebalanceTrades;
