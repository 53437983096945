import Header from "../Header";
import BlacklistedTickersTable from "../BlacklistedTickersDetails/BlacklistedTickersTable";
const BlacklistedTickersLayout = () => {
  return (
    <>
      <Header>
        <BlacklistedTickersTable />
      </Header>
    </>
  );
};
export default BlacklistedTickersLayout;
