import Header from "../Header";
import SmaTable from "../SmaDetails/SmaTable";
const SmaIntegrationLayout = () => {
  return (
    <>
      <Header>
        <SmaTable />
      </Header>
    </>
  );
};
export default SmaIntegrationLayout;
