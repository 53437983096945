import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { getBrokers, getCustodians } from "../../Redux/Actions/todoActions";
import { useFormik } from "formik";
import { CustodianName } from "../Schemas";

const EditCustodian = ({ recordForEdit, data, setOpenPopup, setData }) => {
  const dispatch = useDispatch();
  const { custodians } = useSelector((state) => state?.todos);

  const initialValues = {
    name: recordForEdit.name,
    abbreviation: recordForEdit.abbreviation,

  };

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: CustodianName,

    onSubmit: (values) => {
      values.abbreviation = values.abbreviation.toLowerCase();
      if (
        custodians?.find((a) => {
          if (a.custodianId !== values.custodianId)
            return a.abbreviation === values.abbreviation;
        })
      ) {
        alert(`${values.abbreviation} already exists, try new one.`);
      } else {
      window.clientSocks.send(
        `{"action":"modify_custodian", "data":{"custodian":
      ${JSON.stringify({
        ...values,
        custodianId:recordForEdit?.custodianId,
        creationDate:recordForEdit?.creationDate,
      
      })}}}`
      );
        setOpenPopup(false);
      }
    },
  });



  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} margin="5px">
          <TextField
            fullWidth
            disabled
            id="name"
            name="name"
            label="Full Name"
            value={values.name}
            onChange={handleChange}
            variant="standard"
          />
          {errors.name && touched.name ? (
            <p className="error">*{errors.name}</p>
          ) : null}
        </Grid>

        <Grid item xs={12} margin="5px">
          <TextField
            fullWidth
            id="abbreviation"
            name="abbreviation"
            label="Abbreviation"
            value={values.abbreviation}
            onChange={handleChange}
            variant="standard"
          />
          {errors.abbreviation && touched.abbreviation ? (
            <p className="error">*{errors.abbreviation}</p>
          ) : null}
        </Grid>
        <Grid
          item
          xs={12}
          margin="10px"
          display="flex"
          justifyContent="space-between"
        >
          <Button variant="contained" sx={{ width: "30%" }} type="submit">
            SAVE
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "30%",
              background: "#B2BEB5",
              color: "#000",
              "&:hover": { background: "#808080" },
            }}
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            CANCEL
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
export default EditCustodian;
