import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import MonitorScreenCalculation from "../Tables/Portfolio/AssetPositions/MonitorScreenCalculation";
import {
  TradeInvestment,
  findStrategy,
  hashTradeType,
} from "../../component/Methods";

const findAum = (fundName, strategyName, accountingData, funds, strategies) => {
  const fundId = funds.find((fund) => fund.abbreviation === fundName)?.fundId;
  const strategyId = strategies.find(
    (strategy) => strategy.name === strategyName
  )?.strategyId;

  if (!fundId && !strategyId) {
    return 0;
  }

  const matchingFund = accountingData[0]?.funds.find(
    (obj) => obj.fund === fundId
  );

  if (!matchingFund) {
    return 0;
  }

  if (strategyName === "default") {
    return matchingFund.aum || 0;
  }

  if (strategyId) {
    const strategyAum = matchingFund.strategyAUMs?.[strategyId];
    return strategyAum || 0;
  }
  return 0;
};

const TodaysData = (trades) => {
  const currentDate = new Date();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const year = currentDate.getFullYear();
  const formattedDate = `${month}/${day}/${year}`;
  const startOfDay = new Date(formattedDate).getTime();
  const endOfDay = startOfDay + 86400000 - 60;
  let sharesToday = 0;
  let dollarsToday = 0;
  let commissionsToday = 0;
  let todaysTrades = [];
  let equityTrades = 0;
  let optionsTrades = 0;
  let buyLong = 0;
  let sellLong = 0;
  let sellShort = 0;
  let coverShort = 0;

  if (trades) {
    todaysTrades = trades?.filter((row) => {
      const lastOrder = row?.orders?.slice(-1)[0];
      return lastOrder?.date > startOfDay && lastOrder?.date < endOfDay;
    });

    todaysTrades = todaysTrades.filter((row) => {
      if (row?.manuals.length > 0 || row.hasOwnProperty("fills")) {
        return true;
      }
    });

    todaysTrades?.map((trade) => {
      let shr = 0;
      if (trade?.manuals.length > 0) {
        const lastManual = trade?.manuals.slice(-1)[0];
        sharesToday += Math.round(parseFloat(lastManual.shareCount));
        dollarsToday += Math.round(
          parseFloat(lastManual.sharePrice) * parseFloat(lastManual.shareCount)
        );
        shr = parseFloat(lastManual.shareCount);
      } else if (trade?.manuals.length == 0 && trade.hasOwnProperty("fills")) {
        sharesToday += Math.round(parseFloat(trade.fills?.shareCount));
        dollarsToday += Math.round(
          parseFloat(trade.fills?.sharePrice) *
            parseFloat(trade.fills?.shareCount)
        );
        shr = parseFloat(trade.fills?.shareCount);
      }

      if (trade.hasOwnProperty("commission")) {
        commissionsToday += parseFloat(trade.commission.commission) * shr;
      }

      const lo = trade?.orders?.slice(-1)[0];
      if (lo.ticker.investment === TradeInvestment.Equity) {
        equityTrades += 1;
      }
      if (lo.ticker.investment === TradeInvestment.Options) {
        optionsTrades += 1;
      }

      if (hashTradeType(lo.action, lo.position) == 0) buyLong += 1;
      if (hashTradeType(lo.action, lo.position) == 1) sellLong += 1;
      if (hashTradeType(lo.action, lo.position) == 2) sellShort += 1;
      if (hashTradeType(lo.action, lo.position) == 3) coverShort += 1;
    });
    return [
      sharesToday.toLocaleString(),
      dollarsToday.toLocaleString(),
      parseFloat(commissionsToday).toFixed(2).toLocaleString(),
      equityTrades,
      optionsTrades,
      buyLong,
      sellLong,
      sellShort,
      coverShort,
    ];
  }

  return ["0", "0", "0", 0, 0, 0, 0, 0, 0];
};

const StrategiesDashboard = () => {
  const { lots, accountingData, funds, strategies, marketData, customFunds } =
    useSelector((state) => state?.todos);

  let calculatedData = [];

  customFunds?.map((fund) => {
    fund?.strategies?.map((strategy) => {
      const stId = strategies?.find((i) => {
        return i.name == strategy.name;
      })?.strategyId;

      const mon = MonitorScreenCalculation({
        selectedFund: fund.fundId,
        selectedStrategy: strategy.name,
        selectedSide: "Both",
        lots: lots,
        accountingData: accountingData,
        funds: funds,
        strategies: strategies,
        marketData: marketData,
      });

      // const totalPL = mon.reduce((total, obj) => total + parseFloat(obj.pL), 0);

      const totalProfitLossWeek = mon.reduce(
        (total, obj) => total + parseFloat(obj.profitLossWeek),
        0
      );
      const totalProfitLossMonth = mon.reduce(
        (total, obj) => total + parseFloat(obj.profitLossMonth),
        0
      );
      const totalProfitLossQuarter = mon.reduce(
        (total, obj) => total + parseFloat(obj.profitLossQuarter),
        0
      );
      const totalProfitLossYear = mon.reduce(
        (total, obj) => total + parseFloat(obj.profitLossYear),
        0
      );

      const totalPLLong = mon.reduce((total, obj) => {
        return obj.deltaAdjustedShareCount > 0
          ? total + parseFloat(obj.pL)
          : total;
      }, 0);
      const totalPLShort = mon.reduce((total, obj) => {
        return obj.deltaAdjustedShareCount < 0
          ? total + parseFloat(obj.pL)
          : total;
      }, 0);

      const totalPL = totalPLLong + totalPLShort;
      const totalSizeLong = mon.reduce((total, obj) => {
        return obj.deltaAdjustedShareCount > 0
          ? total + parseFloat(obj.size)
          : total;
      }, 0);
      const totalSizeShort = mon.reduce((total, obj) => {
        return obj.deltaAdjustedShareCount < 0
          ? total + parseFloat(obj.size)
          : total;
      }, 0);

      const entryAUM = findAum(
        fund.abbreviation,
        strategy.name,
        accountingData,
        customFunds,
        strategies
      );

      // findAum(fund.abbreviation, strategy.name);

      const toPush = {
        fundName: fund.abbreviation,
        strategyName: strategy.name,
        aum: entryAUM,
        pLWeek: totalProfitLossWeek,
        pLMonth: totalProfitLossMonth,
        pLQuarter: totalProfitLossQuarter,
        pLYear: totalProfitLossYear,
        pL: totalPL,
        plLong: totalPLLong,
        plShort: totalPLShort,
        sizeLong: totalSizeLong,
        sizeShort: totalSizeShort,
        longCapital: parseFloat(entryAUM * (totalSizeLong * 0.01)),
        shortCapital: parseFloat(entryAUM * (totalSizeShort * 0.01)),
        longPerformance:
          totalSizeLong > 0
            ? parseFloat(totalPLLong / (entryAUM * (totalSizeLong * 0.01))) *
              100
            : 0,
        shortPerformance:
          totalSizeShort < 0
            ? parseFloat(totalPLShort / (entryAUM * (totalSizeShort * 0.01))) *
              100
            : 0,
        plPer: entryAUM > 0 ? parseFloat(totalPL / entryAUM) * 100 : 0,
      };

      calculatedData.push(toPush);
    });
  });

  return calculatedData;
  const calculatedValues = MonitorScreenCalculation({
    lots: lots,
    accountingData: accountingData,
    funds: funds,
    strategies: strategies,
    marketData: marketData,
  });

  const newArray = calculatedValues?.reduce((acc, curr) => {
    const { fundName, strategyName, pL, size, deltaAdjustedShareCount } = curr;
    const plValue = parseFloat(pL);
    const sizeValue = parseFloat(size);
    const existingIndex = acc.findIndex(
      (entry) =>
        entry.fundName === fundName && entry.strategyName === strategyName
    );

    if (existingIndex !== -1) {
      acc[existingIndex].pL += plValue;
      acc[existingIndex].size += sizeValue;
    } else {
      acc.push({
        date: moment().format("MMM DD, YYYY"),
        fundName,
        strategyName: findStrategy(strategyName, strategies) || "default",
        pL: plValue,
        deltaAdjustedShareCount: deltaAdjustedShareCount,
        size: sizeValue,
        aum: findAum(
          fundName,
          findStrategy(strategyName, strategies) || "default"
        ),
        positiveSize: deltaAdjustedShareCount > 0 ? sizeValue : 0,
        negativeSize: deltaAdjustedShareCount < 0 ? sizeValue : 0,
      });
    }

    return acc;
  }, []);

  const aggregatedArray = newArray.reduce((acc, curr) => {
    const existingIndex = acc.findIndex(
      (entry) =>
        entry.fundName === curr.fundName &&
        entry.strategyName === curr.strategyName
    );

    if (existingIndex !== -1) {
      acc[existingIndex].pL += curr.pL;
      acc[existingIndex].positiveSize += curr.positiveSize;
      acc[existingIndex].negativeSize += curr.negativeSize * -1;
    } else {
      acc.push(curr);
    }

    return acc;
  }, []);

  const resultArray = aggregatedArray.map((entry) => ({
    ...entry,

    plShort: entry.deltaAdjustedShareCount <= 0 ? entry.pL : 0,

    plLong: entry.deltaAdjustedShareCount > 0 ? entry.pL : 0,
    sizeShort: entry.negativeSize,
    sizeLong: entry.positiveSize,

    plPer: entry.pL / entry.aum,
    shortPerformance:
      entry.negativeSize > 0
        ? entry.pL / (entry.aum * (entry.negativeSize * 0.01))
        : 0,
    longPerformance:
      entry.positiveSize > 0
        ? entry.pL / (entry.aum * (entry.positiveSize * 0.01))
        : 0,

    aum: entry.aum,
    longCapital: entry.aum * (entry.positiveSize * 0.01),
    shortCapital: entry.aum * (entry.negativeSize * 0.01),
  }));

  return resultArray;
};
const GetAllocation = (fundId, strategyName, lots, accountingData, funds, strategies, marketData) => {
  const mon = MonitorScreenCalculation({
    selectedFund: fundId,
    selectedStrategy: strategyName,
    selectedSide: "Both",
    lots: lots,
    accountingData: accountingData,
    funds: funds,
    strategies: strategies,
    marketData: marketData,
  });

  const totalLongAUM = mon?.reduce((total, obj) => {
    return obj.deltaAdjustedShareCount > 0
      ? total + (parseFloat(obj.sharePrice) * parseFloat(obj.deltaAdjustedShareCount))
      : total;
  }, 0);

  const totalShortAUM = mon?.reduce((total, obj) => {
    return obj.deltaAdjustedShareCount < 0
      ? total + (parseFloat(obj.sharePrice) * parseFloat(obj.deltaAdjustedShareCount))
      : total;
  }, 0);

  return {
    long: totalLongAUM,
    short: totalShortAUM,
  };
};

export { TodaysData, StrategiesDashboard, GetAllocation };
