import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  TableRow,
  TableBody,
  useTheme,
  Box,
  IconButton,
  TableContainer,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  Tablecell,
  TABLE_HEAD_SIGNALS as TABLE_HEAD,
  applySortFilter,
  getComparator,
} from "../../../Methods";
import VantageListHead from "../../../ListHead/SignalList";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const CurrentList = ({ strategyValObj, strategies, strategyVal }) => {
  const [data, setData] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("value");
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const iconButtonRef = useRef(null);

  const handleClick = (event) => {
    setAnchorEl(iconButtonRef.current);
    setMenuOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const copyForWebsite = () => {
    handleClose();
    navigator.clipboard.writeText(JSON.stringify(data)).catch((err) => {
      console.error("Error copying data: ", err);
    });
  };

  const copyForExcel = () => {
    handleClose();
    let formattedData = data.map((item) => `${item.name}\t${item.value}%`).join("\n");
    navigator.clipboard.writeText(formattedData).catch((err) => {
      console.error("Error copying data: ", err);
    });
  };

  useEffect(() => {
    if (strategyValObj && strategies && strategyVal) {
      const strategy = strategies.find(
        (strategy) => strategy.strategyId === strategyValObj
      );

      if (strategy && strategy.signals) {
        const { weights_keys, weights_values } = strategy.signals;
        const keys = Object.keys(weights_keys);
        const combinedArray = keys.map((key) => {
          const symbol = weights_keys[key]?.symbol;
          const stock = weights_keys[key]?.stock;
          const name = stock ? `${symbol}/${stock}` : symbol;

          return {
            key,
            name,
            value: weights_values[key] * 100,
            stock: stock,
          };
        });

        const filteredUsers = applySortFilter(
          combinedArray,
          getComparator(order, orderBy)
        );

        setData(filteredUsers);
      } else {
        setData([]);
      }
    } else {
      setData([]);
    }
  }, [strategies, strategyValObj, strategyVal, order, orderBy]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          border: `100px ${theme.palette.lightGrey.tableHeader}`,
          borderRadius: "4px",
          marginTop: "10px",
          width: "100%",
          marginRight: 1,
          mt: 8,
        }}
      >
        <Table
          stickyHeader
          aria-label="a dense table"
          sx={{
            height: "max-content",
            borderCllapse: "collapse",
            border: "solid 1px #eee",
            overflow: "scroll",
          }}
        >
          <TableRow
            sx={{
              "&:hover": {
                background: theme.palette.grey[200],
                cursor: "pointer",
              },
            }}
          >
            <Tablecell
              align="left"
              sx={{
                fontSize: theme.typography.bodyText12.fontSize,
                fontWeight: 900,
                backgroundColor: "#B2CBE299",
                paddingLeft: "20px",
                textAlign: "left",
                py: "5px",
              }}
            >
              Current Signals List
            </Tablecell>
            <Tablecell
              align="left"
              onClick={handleClick}
              sx={{
                fontSize: theme.typography.bodyText12.fontSize,
                fontWeight: 900,
                backgroundColor: "#8AA9BD",
                paddingRight: "5px",
                textAlign: "right",
                color: "white",
                py: "5px",
                hover: {
                  cursor: "pointer",
                },
              }}
            >
              Copy Signals
              <IconButton
                ref={iconButtonRef}
                onClick={handleClick}
                aria-label="add"
                sx={{
                  height: "15px",
                  width: "15px",
                  marginLeft: "10px",
                }}
              >
                <ContentCopyIcon
                  sx={{ height: "15px", width: "15px", color: "white" }}
                />
              </IconButton>
            </Tablecell>
          </TableRow>
          <Menu
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={() => {
              setMenuOpen(false);
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={copyForWebsite}>Copy for Website</MenuItem>
            <MenuItem onClick={copyForExcel}>Copy for Excel</MenuItem>
          </Menu>
        </Table>
        <TableContainer sx={{ height: "45vh" }}>
          <Table
            size="small"
            stickyHeader
            aria-label="a dense table"
            sx={{
              borderCllapse: "collapse",
              border: "solid 1px #eee",
              marginTop: "-1px",
            }}
          >
            <VantageListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              onRequestSort={handleRequestSort}
              noStatus={true}
              customizeFont={12}
            />
            <TableBody>
              {data?.map((row, index) => (
                <TableRow
                  sx={{
                    "&:hover": {
                      background: theme.palette.grey[200],
                      cursor: "pointer",
                    },
                  }}
                >
                  <Tablecell
                    align="left"
                    sx={{
                      fontSize: "12px",
                      paddingLeft: "5px",
                      borderRight: "none",
                    }}
                  >
                    {row?.name}
                  </Tablecell>
                  <Tablecell
                    align="right"
                    sx={{
                      fontSize: "12px",
                      paddingLeft: "5px",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  >
                    {row?.value?.toFixed(2)}%
                  </Tablecell>
                  <Tablecell
                    align="left"
                    sx={{
                      fontSize: "12px",
                      paddingLeft: "10px",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  >
                    {row?.value >= 0 ? "L" : "S"}
                  </Tablecell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};
export default CurrentList;
