import React from "react";
import Topbar from "../component/NavComponents/Topbar";
import UserProfileLayout from "../component/Layouts/UserProfileLayout";
import { Helmet } from "react-helmet";
const UserProfile = () => {
  return (
    <>
      <Helmet>
        <title>User Profile</title>
      </Helmet>
      <Topbar title="User Profile" />
      <UserProfileLayout />
    </>
  );
};
export default UserProfile;
