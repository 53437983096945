import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import { useFormik } from "formik";
import {
  FormControl,
  useTheme,
  Typography,
  Alert,
  AlertTitle,
  Slider,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { UserSma, UserStrategy } from "../../Schemas";
import { useSelector } from "react-redux";

const FundAllocation = ({ recordForEdit, setOpenPopupFundAllocation }) => {
  const [sliderValue1, setSliderValue1] = useState(50);
  const [sliderValue2, setSliderValue2] = useState(50);
  const [sliderValue3, setSliderValue3] = useState(50);

  const handleSliderChange1 = (event, newValue) => {
    setSliderValue1(newValue);
  };

  const handleSliderChange2 = (event, newValue) => {
    setSliderValue2(newValue);
  };

  const handleSliderChange3 = (event, newValue) => {
    setSliderValue3(newValue);
  };

  const { strategies } = useSelector((state) => state?.todos);
  const initialValues = {
    strategy: "",
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    setFieldValue,
    handleChange: formikHandleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    // validationSchema: UserStrategy,

    onSubmit: (values) => {
      setOpenPopupFundAllocation(false);
    },
  });

  const strategyList = strategies
    ?.map((i) => i?.name)
    .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0));

  const theme = useTheme();

  function getNextMondayDate() {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const daysUntilNextMonday = 1 + (7 - dayOfWeek) % 7;
    const nextMonday = new Date(today.getTime() + daysUntilNextMonday * 24 * 60 * 60 * 1000);
    return nextMonday.toLocaleDateString("en-US", { month: "long", day: "numeric" });
  }  

  const nextMondayDate = getNextMondayDate();

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography id="slider-label1" gutterBottom>
            AX Factor Allocation
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Slider
              value={typeof sliderValue1 === "number" ? sliderValue1 : 0}
              onChange={handleSliderChange1}
              aria-labelledby="slider-label1"
              sx={{ marginRight: "16px", width: "100%" }}
            />
            <TextField
              value={sliderValue1}
              inputProps={{
                min: 0,
                max: 100,
                type: "number",
                "aria-labelledby": "slider-label1",
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Typography id="slider-label2" gutterBottom>
              AI Factor Allocation
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Slider
              value={typeof sliderValue2 === "number" ? sliderValue2 : 0}
              onChange={handleSliderChange2}
              aria-labelledby="slider-label2"
              sx={{ marginRight: "16px", width: "100%" }}
            />
            <TextField
              value={sliderValue2}
              inputProps={{
                min: 0,
                max: 100,
                type: "number",
                "aria-labelledby": "slider-label2",
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Typography id="slider-label3" gutterBottom>
            CP Factor Allocation
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Slider
              value={typeof sliderValue3 === "number" ? sliderValue3 : 0}
              onChange={handleSliderChange3}
              aria-labelledby="slider-label3"
              sx={{ marginRight: "16px", width: "100%" }}
            />
            <TextField
              value={sliderValue3}
              inputProps={{
                min: 0,
                max: 100,
                type: "number",
                "aria-labelledby": "slider-label3",
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          style={{  padding: "0px", marginTop: "10px" }}
        >
          <Grid sx={{ marginTop: "10px" }}>
            <Alert severity="warning" icon={<ErrorIcon fontSize="inherit" />}>
              <AlertTitle sx={{ color: "#000", fontWeight: "bold" }}>
                IMPORTANT NOTE
              </AlertTitle>
              <p style={{ color: "#000" }}>
                {" "}
                We rebalance strategies every Monday, so any changes made
                to Fund Allocation will reflect on <b>{nextMondayDate}</b>.
              </p>
            </Alert>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          margin="10px"
          display="flex"
          flexDirection={"column"}
          justifyContent="space-between"
        >
          <Button
            variant="contained"
            sx={{ width: "100%", mb: 2, fontSize: "17px" }}
            type="submit"
          >
            UPDATE ALLOCATION
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
export default FundAllocation;
