import TradeMonitor from "../component/Layouts/TradeMonitor";

const Monitor = () => {
  return (
    <>
      <TradeMonitor />
    </>
  );
};
export default Monitor;
