import React, { useState } from "react";
import {
  Grid,
  Button,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  Box,
  useTheme,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { criteriaOptions, operationOptions, unitOptions } from "../../Methods";

const SecurityForm = ({ securities, setSecurities }) => {
  const handleSetTickerChange = (index, event) => {
    const updatedSecurities = [...securities];
    const tickersInput = event.target.value;
    const tickers = (tickersInput || "")
      .toString()
      .split(",")
      .map((ticker) => ticker.trim());
    updatedSecurities[index].selectedTickers = tickers;
    setSecurities(updatedSecurities);
  };

  const handleRuleChange = (index, ruleIndex, field, value) => {
    const updatedSecurities = [...securities];
    updatedSecurities[index].rules[ruleIndex][field] = value;
    setSecurities(updatedSecurities);
  };

  const handleValueChange = (index, ruleIndex, event) => {
    const updatedSecurities = [...securities];
    const value = event.target.value;
    if (!isNaN(value) || value === "") {
      updatedSecurities[index].rules[ruleIndex].value = value;
      setSecurities(updatedSecurities);
    }
  };

  const handleUnitChange = (index, ruleIndex, event) => {
    const updatedSecurities = [...securities];
    updatedSecurities[index].rules[ruleIndex].unit = event.target.value;
    setSecurities(updatedSecurities);
  };

  const addRule = (index) => {
    const updatedSecurities = [...securities];
    if (updatedSecurities[index].selectedTickers.length > 0) {
      updatedSecurities[index].rules.push({
        criteria: "",
        operation: "",
        value: "",
        unit: "$",
      });
      setSecurities(updatedSecurities);
    }
  };

  const createNewSet = () => {
    setSecurities([...securities, { selectedTickers: [], rules: [] }]);
  };

  const handleRemoveSet = (index) => {
    const updatedSecurities = securities.filter((_, i) => i !== index);
    setSecurities(updatedSecurities);
  };

  const handleRemoveRule = (index, ruleIndex) => {
    const updatedSecurities = [...securities];
    updatedSecurities[index].rules = updatedSecurities[index].rules.filter(
      (_, i) => i !== ruleIndex
    );
    setSecurities(updatedSecurities);
  };

  const theme = useTheme();
  return (
    <Box sx={{ marginTop: "20px", width: "100%" }}>
      {securities.map((securitySet, index) => (
        <Box
          key={index}
          sx={{
            marginBottom: 3,
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            p: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 1,
            }}
          >
            <IconButton
              size="small"
              onClick={() => handleRemoveSet(index)}
              sx={{ color: theme.palette.error.main }}
            >
              <DeleteIcon sx={{ height: "20px", width: "20px" }} />
            </IconButton>
          </Box>

          <Box sx={{ marginBottom: 3 }}>
            <TextField
              label="Enter Tickers (comma separated)"
              variant="standard"
              fullWidth
              value={securitySet.selectedTickers.join(", ")}
              onChange={(event) => handleSetTickerChange(index, event)}
            />
          </Box>

          <Box sx={{ maxHeight: "240px", overflowY: "auto", width: "100%" }}>
            <Grid container spacing={1}>
              {securitySet.rules.map((rule, ruleIndex) => {
                const isNotAllowed = rule.criteria === "NotAllowed";
                return (
                  <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    key={ruleIndex}
                  >
                    <Grid item xs={4}>
                      <Select
                        fullWidth
                        size="small"
                        value={rule.criteria}
                        onChange={(e) =>
                          handleRuleChange(
                            index,
                            ruleIndex,
                            "criteria",
                            e.target.value
                          )
                        }
                        displayEmpty
                      >
                        {criteriaOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>

                    <Grid item xs={2}>
                      <Select
                        fullWidth
                        size="small"
                        value={rule.operation}
                        onChange={(e) =>
                          handleRuleChange(
                            index,
                            ruleIndex,
                            "operation",
                            e.target.value
                          )
                        }
                        disabled={isNotAllowed}
                      >
                        {operationOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        size="small"
                        value={rule.value}
                        onChange={(e) => handleValueChange(index, ruleIndex, e)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {rule.unit}
                            </InputAdornment>
                          ),
                        }}
                        disabled={isNotAllowed}
                      />
                    </Grid>

                    <Grid item xs={1}>
                      <Select
                        value={rule.unit}
                        onChange={(e) => handleUnitChange(index, ruleIndex, e)}
                        size="small"
                        fullWidth
                      >
                        {unitOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>

                    <Grid item xs={1}>
                      <IconButton
                        color="secondary"
                        size="small"
                        onClick={() => handleRemoveRule(index, ruleIndex)}
                      >
                        <DeleteIcon
                          sx={{ height: "15px", width: "15px", color: "black" }}
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              size="small"
              sx={{
                background: "#1976d2",
                "&:hover": { background: "#1565c0" },
              }}
              onClick={() => addRule(index)}
              disabled={securitySet.selectedTickers.length === 0}
            >
              Add Rule
            </Button>
          </Box>
        </Box>
      ))}

      <Box sx={{ marginTop: 3, display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          size="small"
          sx={{
            background: "#1976d2",
            "&:hover": { background: "#1565c0" },
            width: "100%",
          }}
          onClick={createNewSet}
        >
          Add New Set of Rules
        </Button>
      </Box>
    </Box>
  );
};

export default SecurityForm;
